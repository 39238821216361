import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationListComponent } from './notification-list/notification-list.component';
import { RouterModule, Routes } from '@angular/router';
import { NotificationListService } from './notification-list.service';
import { FormsModule } from '@angular/forms';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';

const routes: Routes = [
  {
    path: '',
    component: NotificationListComponent,
    resolve: {
      data: NotificationListService
    }
  }
]

@NgModule({
  declarations: [NotificationListComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatSelectModule,
    MatTableModule,
    FormsModule,
    MatPaginatorModule
  ]
})
export class NotificationListsModule { }
