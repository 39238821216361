import { filter } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { EventListService } from 'app/main/events/events-list/event-list.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AddTicketTypeDialogComponent } from '@fuse/Dialog/add-ticket-type-dialog/add-ticket-type-dialog.component';
import { TicketTypeService } from 'app/main/masters/ticket-type/ticket-type.service';
import { AuthService } from 'app/main/pages/auth.service';
import * as _ from 'lodash';
import { AddTemplateDialogComponent } from '../template/add-template-dialog/add-template-dialog.component';
import { capitalize, invert, transform } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreateTicketService implements Resolve<any> { 
  allCurrnecies: any = ''
  allEventTypes: any = ''
  allEvents: any = ''
  allTicketTypes
  allVenues: any[] = []
  ticketBackgroundFile: any = '';
  addSelectedItem  = new Subject();  
  newDynamicElement = {
    text: 'New Element',
    fontSize: 20,
    width: '',
    fontFamily: "Barlow",
    fontColor: '#000',
    display: true,
    bold: false,
    underline: false,
    italic: false,
    align: null,
    textTransform: "capitalize",
    opacity: 1,
    rotate: 0,
    position: {
      top: 32,
      left: 700
    }
  }
  ticketData:any;
  permissions: any;
  preTemplateData: any;
  isPublish: any; 
  ticketId: any;
  eventId: any;
  mode:string = undefined;
  preRoute: string;

  set ticketPrefix(val) {
    if (val == null && val == undefined)
      val = ''
    this.templateData.ticketNum.prefix = val
  }
  set ticketNumStart(val) {
    if (val == null && val == undefined)
      val = ''
    this.templateData.ticketNum.numStartFrom = val
  }
  
  // constructor here...
  constructor(
    private _http: HttpClient,
    private _matSnackBar: MatSnackBar,
    private _commonService: CommonService,
    private _eventListService: EventListService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _router: Router,
    private _matDialog: MatDialog,
    private _ticketTypeService: TicketTypeService,
    private _authService: AuthService
  ) {
   
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => { 
      console.log("route here", route);
      this.ticketId = route?.params?.id;
      console.log("this.ticketId",this.ticketId);
      // edit case
      if (route?.params?.id) {  
        Promise.all([
          this.getEventsForCreateTicket().then((result:any) => {
            if (result.status == 200)
              this.allEvents = result.data;
            else
              this.allEvents = [] 
          }),
          this.getTicketById(route.params.id).then((result: any) => {
            if (result.status == 200) {  
              this.ticketData = result.data;
              this.eventId = result.data.eventId;
              this.isPublish = result.data.isPublish;
            }else{
              this.ticketData = undefined;
            }
          }),
          
          this._commonService.getAllEventTypes().then((result) => {
            this.allEventTypes = result
          }),
          this._commonService.getAllVenues().then((result: any) => {
            if(result.status == 200){
              this.allVenues = result;
            }else{
              this.allVenues = [];
            }
          }), 
          this._ticketTypeService.getAllTicketType({pageSize:'0',pageIndex:'0'}).then((result: any) => {
            if(result.status == 200){
              this.allTicketTypes = result;
            }else{
              this.allTicketTypes = [];
            }
          }),
          
        ]).then(
          () => {
            this.mode = 'editTicket';
            this._fuseProgressBarService.hide();
            resolve('');
          },
          reject
        );
      }
      // add case
       else { 
        Promise.all([ 
          this._commonService.getAllEventTypes().then((result) => {
            this.allEventTypes = result;
          }),
          this.getEventsForCreateTicket().then((result:any) => {
            if (result.status == 200){
              this.allEvents = result.data; 
            }
            else
              this.allEvents = [] 
          }),
          this._commonService.getAllVenues().then((result: any[]) => {
            this.allVenues = result
          }),
          this._ticketTypeService.getAllTicketType({pageSize:'0',pageIndex:'0'}).then((result: any) => {    
            if(result.status == 200){
                this.allTicketTypes = result
            }else{
              this.allTicketTypes = [];
            }
          })
        ]).then(
          () => {
            console.log("we are in add ticket case.");
            if(route?.queryParams?.eventId){
              this.mode = 'createTicketFormEvent';
              this.preRoute = route.queryParams?.to;
              this.eventId = route?.queryParams?.eventId;
            }else{
              this.mode = 'createTicket'; 
              this.eventId = undefined;
            }
            console.log("this is are add mode", this.mode);
            
            // if(!this.mode){
              // this.mode = 'createTicket';
            // }
            this.ticketData = undefined;
            // this.isCreateEventFlow = false;
            this._fuseProgressBarService.hide();
            // this.eventId = undefined;
            resolve('');
          },
          reject
        );
      }
    });
  }



  getEventsForCreateTicket(){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/eventsForCreateTicket', {}, this._commonService.getHeader()).subscribe((result)=>{
        resolve(result);
      }, reject)
    })
  }


  numberToMonth(num) {
    switch (num) {
      case 1:
        return "January"
        break;
      case 2:
        return "February"
        break;
      case 3:
        return "March"
        break;
      case 4:
        return "April"
        break;
      case 5:
        return "May"
        break;
      case 6:
        return "June"
        break;
      case 7:
        return "July"
        break;
      case 8:
        return "August"
        break;
      case 9:
        return "September"
        break;
      case 10:
        return "October"
        break;
      case 11:
        return "November"
        break;
      case 12:
        return "December"
        break;
    }
  }

  formatDate(date) {
    return this.numberToMonth(date.getMonth() + 1) + ' ' + date.getDate() + ', ' + date.getFullYear() + ' @ ' + date?.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  }

  updateTicket(data, id, ticketTypeStyleId, currencyId) {
    
    console.log("payload data", data);
    console.log("payload id", id);
    console.log("payload tickettypestyleid", ticketTypeStyleId);
    console.log("payload currencyId", currencyId);
    
    let finalTemplateData = JSON.parse(JSON.stringify(this.templateData))

    // let formData = new FormData();
    // formData.append("ticketId", id);
    // formData.append("eventId", data?.selectedEvent);
    // formData.append("ticketTypeId", data?.ticketType);
    // formData.append("title", data?.ticketTitle);
    // formData.append("price", data?.price ? data.price : '0');
    // formData.append("ticketPreText", data?.ticketNumberPrefix);
    // formData.append("ticketStartNum", data?.ticketNumberFrom);
    // formData.append("guestLabel", data?.guestLabel?data.guestLabel:"");
    // formData.append('labelCode', data?.label_code_type);
    // formData.append("orgLabel", data?.orgLabel ? data.orgLabel: "");
    
    // formData.append("ticketTypeStyleId", ticketTypeStyleId);
    
    const payload = {
      ticketId: id,
      eventId: data?.selectedEvent,
      ticketTypeId: data?.ticketType,
      title: data?.ticketTitle,
      price: data?.price ? data.price : '0',
      ticketPreText: data?.ticketNumberPrefix,
      ticketStartNum: data?.ticketNumberFrom,
      guestLabel: data?.guestLabel?data.guestLabel:"",
      labelCode: data?.label_code_type,
      orgLabel: data?.orgLabel ? data.orgLabel: "",
      maxMemberPerTicket: data?.maxMember
    }
 
    // formData.append("totalCount", data.totalTickets)

    // formData.append("backgroundImage", this.ticketBackgroundFile ? this.ticketBackgroundFile : finalTemplateData.backgroundImage);
    // delete finalTemplateData.backgroundImage
    delete finalTemplateData.imagePreview

    // formData.append("layout", JSON.stringify(finalTemplateData));
    // formData.append("maxMemberPerTicket", data.maxMember);

    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.put(environment.apiURL + 'admin/tickets/updateticket', payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200){
            // this._router.navigate(['/tickets'])
            resolve(result)
            this._fuseProgressBarService.hide()
            this._matSnackBar.open(result.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
            })
          }
        }, reject)
    })
  }

  createTicket(data, ticketTypeStyleId, currencyId, eventId?) {
    console.log("data", data);
    console.log("currencyId", currencyId);
    
    let finalTemplateData = JSON.parse(JSON.stringify(this.templateData))

    const payload = {}
    payload['eventId'] =  data.selectedEvent ? data.selectedEvent : eventId;
    payload['ticketTypeId'] =  data.ticketType;
    payload['title'] =  data.ticketTitle;
    payload['price'] =  data.price ? data.price : '0';
    payload['ticketPreText'] =  data.ticketNumberPrefix;
    payload['ticketStartNum'] =  data.ticketNumberFrom;
    payload['totalCount'] =  data.totalTickets;
    payload['maxMemberPerTicket'] = data.maxMember;
    payload['labelCode'] = data.label_code_type;

    // payload['orgWebsite'] = data?.organizerWebsite == '' ? '' : data?.organizerWebsite == 'https://' ? '' : data?.organizerWebsite;
    payload['backgroundImage'] =  this.ticketBackgroundFile ? this.ticketBackgroundFile : finalTemplateData.backgroundImage;
    payload['guestLabel'] =  data?.guestLabel || '';
    payload['orgLabel'] =  data?.orgLabel || '';
    payload['ticketCodeLength'] = data.ticketNumberLength || 0;
    payload['currencyId'] =  data?.price != '0' ? currencyId : 0;
    payload['customQrCode'] = [];
    payload['customBarcode'] = [];

    payload['ticketCodeType'] = data?.ticketSeatFormat || '';
    payload['codeFormatType'] = data?.seatNumberGenrateType || '';

    console.log("this is your this._commonService.getCurrencySymbol()", this._commonService.getCurrencySymbol());
    
    payload['currencySymbol'] = data?.price != '0' ? this._commonService.getCurrencySymbol() : '';

    delete finalTemplateData.backgroundImage;
    delete finalTemplateData.imagePreview;

    payload['layout'] = finalTemplateData;

    console.log("payload", payload);
    
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/tickets/add', payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200)
          resolve(result)
          this._fuseProgressBarService.hide();
          this._matSnackBar.open(result.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
        }, reject)
    })
  }

  updateTicketLayout(data, id){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/tickets/updateTicketLayout',{layout:data,ticketId:id}, this._commonService.getHeader()).subscribe((result)=>{
        resolve(result);
      }, reject)
    })
    // tickets/updateTicketLayout
  }

  getAllEventTitle() {
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._http.get(environment.apiURL + 'admin/event/getall/tickettitle', this._commonService.getHeader()).subscribe((result: any) => {
        this._fuseProgressBarService.hide()
        resolve(result);
        if (result.status != 200)
          this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
      }, reject)
    })
  }

  getTicketById(id) {
    return new Promise((resolve, reject) => {
      this._http.post(environment.apiURL + 'admin/tickets/getticketbyid', { ticketId: id }, this._commonService.getHeader()).subscribe((result: any) => {
        console.log("get ticket by id -->", result.data);
        resolve(result);
      }, reject)
    })
  }


  addTicketType() {
    this._matDialog.open(AddTicketTypeDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '400px',
      data: {}
    }).afterClosed().subscribe((dialogData: any) => {
      if (dialogData) {
        this._ticketTypeService.getAllTicketType().then((result: any) => {
          this.allTicketTypes = result;
          this.addSelectedItem.next(result.data.ticketTypes[0].id);
        });
      }
    })
  }
 
  defaultTemplateFormat: any = {
    backgroundImage: 'https://event-project-gts.s3.amazonaws.com/ticket/backgroundImage/ticketBackground_1697615997776.png',
    imagePreview: 'https://event-project-gts.s3.amazonaws.com/ticket/backgroundImage/ticketBackground_1697615997776.png',
    height: '320',
    width: '940',
    fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
    eventName: {
      text: '',
      fontSize: 37,
      width: '550',
      align: 'left',
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '500',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "uppercase",
      opacity: 1,
      rotate: 0,
      position: {
        left : 14,
        top : 31.38
      }
    },
    orgName: {
      text: '',
      fontSize: 18,
      width: '',
      align: null,
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '500', 
      bold: false,
      underline: false,
      italic: false,
      opacity: 1,
      textTransform: "capitalize",
      rotate: 0,
      position: {
        top: 69.2,
        left: 6.5
      }
    },
    orgWebsite: {
      text: '',
      fontSize: 18,
      width: '',
      align: null,      
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '500',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",  
      opacity: 1,
      rotate: 0,
      position: {
        top: 69.9,
        left: 22.5
      }
    },
    helpLine: { 
      text : "",
      align :  null,
      bold :  false,
      display :  true,
      fontColor :  "#000",
      fontFamily :  "Barlow",
      fontSize :  18,
      fontWeight :  "500",
      italic :  false,
      opacity : 1,
      rotate : 0,
      textTransform : "capitalize",
      underline : false,
      width : "",
      position : {
        top: 69.9, 
        left: 45.5
      }
    },
    ticketNumber: { 
        text: "",
        fontSize: 18,
        width: "",
        align: null,
        fontFamily: "Barlow",
        fontColor: "#000",
        display: true,
        fontWeight: "500",
        bold: false,
        underline: false,
        italic: false, 
        opacity: 1,
        textTransform: "capitalize",
        rotate: 0,
        position: {
            top: 185,
            left: 50 
        }
    },
    customerName: {
      text: "Customer Name Here",
      fontSize: 23,
      width: "400",
      align: "left",
      fontFamily: "Barlow",
      fontColor: "#000",
      display: true,
      fontWeight: "400",
      bold: true,
      underline: false,
      italic: false,
      opacity: 1,
      textTransform: "capitalize",
      rotate: 0,
      position: {
          top: 14.8,
          left: 9
        }
    },
    commanDate: {
      text: '',
      fontSize: 18,
      width: '',
      align: 'left',
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '400',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",
      opacity: 1,
      rotate: 0,
      position: {
        top: 80.4,
        left: 6.5
      }
    },
    eveStartDate: {
      text: '',
      fontSize: 18,
      width: '',
      align: 'left',
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '400',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",
      opacity: 1,
      rotate: 0,
      position: {
        top: 80.4,
        left: 6.5
      }
    },
    eveEndDate: {
      text: '',
      fontSize: 18,
      width: '',
      align: 'left',
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '400',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",
      opacity: 1,
      rotate: 0,
      position: {
        top: 80.4,
        left: 12.5
      }
    },
    venue:{
        text: "",
        fontSize: 17,
        width: "450",
        align: null,
        fontFamily: "Barlow",
        fontColor: "#000",
        display: true,
        fontWeight: "500",
        bold: false,
        underline: false,
        italic: false,
        textTransform: "capitalize",
        opacity: 1,
        rotate: 0,
        position: {
            top: 51.59,
            left: 11
        }
    },
    scanId: {
      text: "XXXXXXXXXX",
      fontSize: 18,
      width: "",
      align: null,
      fontFamily: "Barlow",
      fontColor: "#000",
      display: true,
      fontWeight: "400",
      bold: false,
      underline: false,
      italic: false,
      opacity: 1,
      rotate: 0,
      position: {
          top: 83.2,
          left: 91
      }
    },
    price: {
      text: "",
      fontSize: 37,
      width: "",
      align: null,
      fontFamily: "Barlow",
      fontColor: "#000",
      display: true,
      fontWeight: "500",
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",
      opacity: 1,
      rotate: 0,
      position: {
          top: 31.19,
          left: 73.5
        }
    },
    guest: [],
    dynamicElements: [],
    ticketType: {
      text: '',
      fontSize: 18,
      width: '',
      align: null,
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '500',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",
      opacity: 1,
      rotate: 0,
      position: {
        top: 10.8,
        left: 88
      }
    },
    ticketNum: {
      prefix: '',
      numStartFrom: '',
      fontSize: 18,
      width: '',
      align: null,
      fontFamily: "Barlow",
      fontColor: '#000',
      display: true,
      fontWeight : '500',
      bold: false,
      underline: false,
      italic: false,
      textTransform: "capitalize",
      opacity: 1,
      rotate: 0,
      position: {
        top: 36,
        left: 900
      }
    },
    qr: {
      link: 'assets/images/qrSample.png',
      width: 100,
      display: true,
      opacity: 1,
      rotate: 0,
      position: {
        top: 31.99,
        left: 90.5
      }
    },
    barCode: {
      link: 'assets/images/barSample2x.png',
      background : '#fff',
      width: 180,
      display: true,
      opacity: 1,
      rotate: 0,
      position: {
        top: 68.4,
        left: 95
      }
    },
    numBar: {
      background: 'transparent',
      display: false,
      position: {
        bottom: 0,
        left: 0
      }
    }
    
  }

  //this will used
  templateData: any = JSON.parse(JSON.stringify(this.defaultTemplateFormat))

  initiateTemplateData() {
    this.templateData = JSON.parse(JSON.stringify(this.defaultTemplateFormat))
    console.log("this.templateData===->", this.templateData);
  }

  applyTicketFormat(layout) {
    console.log("layout design",layout);    
    for (const [key, value] of Object.entries(layout)) {
      if (typeof layout[key] == 'object') {
        if (Array.isArray(layout[key])) {
          if (layout[key].length > 0) {
            layout[key].forEach(element => {
              if (element.text) {
                delete element.text
              }
            });
          }
        } else {
          if (layout[key].text) {
            delete layout[key].text
          }
          if (layout[key].link) {
            delete layout[key].link
          }
          if (layout[key].prefix) {
            delete layout[key].prefix
          }
          if (layout[key].numStartFrom) {
            delete layout[key].numStartFrom
          }
        }
      }
    }
    this.templateData = _.merge(JSON.parse(JSON.stringify(this.templateData)), JSON.parse(JSON.stringify(layout)));
    return layout
  }


  addTemplate(data, layout) {
    // let formData = new FormData()
    // formData.append('layoutName', data.title)
    // formData.append('thumbNail', data.thumb)
    // formData.append('layout', JSON.stringify(layout))
    // if (this.ticketBackgroundFile)
    //   formData.append('backgroundImage', this.ticketBackgroundFile)
    const payload = { 
      layoutName : data.title,
      thumbNail : data.thumb,
      layout : JSON.stringify(layout),
      backgroundImage : ''
    }
    if (this.ticketBackgroundFile){
      payload.backgroundImage  = this.ticketBackgroundFile;
    }
    
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._http.post(environment.apiURL + 'admin/tickets/add/layout', payload, this._commonService.getHeader()).subscribe((result: any) => {
        this._fuseProgressBarService.hide()
        resolve(result);
        this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
      }, reject)
    })
  }

  publishEvent(data, isPublish){
    this._fuseProgressBarService.show();
    const payload = {
      "id": data,
      "isPublish": isPublish
    }
    
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/publishEvent', payload, this._commonService.getHeader()).subscribe((response:any)=>{
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this._matSnackBar.open(response.message, "OK", {
            duration: 2000
          })
        }else{
          this._matSnackBar.open(response.message, "OK", {
            duration: 2000
          })
        }
        resolve(response)
      }, reject)
    })
  }

}
