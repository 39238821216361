import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { AuthService } from 'app/main/pages/auth.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SponsorService implements Resolve<any>{
  allSponsors
  maxAvailableRecordsInTable;
  permissions: any;
  sponsorsGroups:[] = [];
  maxAvailableRecordsInTableSG;


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("route", route);
    // this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      if(route.routeConfig.path == 'sponsor-groups'){
        Promise.all([
          this.getAllSponsors(0,0,''),
          this.getAllSponsorsGroups({"search": "","pageIndex": 1,"pageSize": 10}),
        ]).then(
          () => {
            resolve('');
          },
          reject
        );
      }else{
        Promise.all([
          this.getAllSponsors(1,10,''),
        ]).then(
          () => {
            resolve('');
          },
          reject
        );
      }
    })
  }

  constructor(
    private _httpClient:HttpClient,
    private _fuseProgressBarService:FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _authService:AuthService,
    private _commonService: CommonService,
  ) { }

  getAllSponsors(pageIndex, pageSize, search){
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.get(environment.apiURL+`admin/sponsor/get?page=${pageIndex}&size=${pageSize}&searchtxt=${search}`, this._commonService.getHeader())
        .subscribe((result: any) => {
          this._fuseProgressBarService.hide()
          if (result.status == 200) {
            this.maxAvailableRecordsInTable = result.data.totalRecords;
            this.allSponsors = result.data.sponsorList;
            resolve(result)
          }
          else if(result.status == 201){
            this.maxAvailableRecordsInTable = 0;
            this.allSponsors = [];
            resolve(result)
          }
          else if(result.status == 400){
            this.maxAvailableRecordsInTable = 0;
            this.allSponsors = [];
            resolve(result)
          }
          else{
            reject(result)
          }
          // this._fuseProgressBarService.hide()
        }, reject)
    })
  }

  getSponsorById(id){
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.get(environment.apiURL+`admin/sponsor/get/${id}`, this._commonService.getHeader())
        .subscribe((result: any) => {
          this._fuseProgressBarService.hide()
          if (result.status == 200) { 
            resolve(result)
          }else if(result.status == 201){
            resolve(result)
          }
          else if(result.status == 400){ 
            resolve(result)
          }
          else{
            reject(result)
          }
          // this._fuseProgressBarService.hide()
        }, reject)
    })
  }

  getAllSponsorsGroups(body){
    return new Promise((resolve, reject) => {
    this._fuseProgressBarService.show();
    this._httpClient.post(environment.apiURL+`admin/getAllSponsorGroup`, body, this._commonService.getHeader()).subscribe((result: any) => {
        this._fuseProgressBarService.hide();
        if(result.status == 200){
          this.sponsorsGroups = result.data.getAllSponsorGroup;
          this.maxAvailableRecordsInTableSG = result.data.totalRecords;
        }else{
          this.sponsorsGroups = [];
          this.maxAvailableRecordsInTableSG = 0;
        }
        resolve(result)
      }, reject)
    })
  }
}
