import { Component, OnInit, ElementRef, ViewChild, Input, Renderer2, HostListener, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { CommonService } from '@fuse/services/common.service';
import { CreateTicketService } from '../create-ticket/create-ticket.service';
import { ChooseTemplateDialogComponent } from './choose-template-dialog/choose-template-dialog.component';
import { HttpClient } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { AddTemplateDialogComponent } from './add-template-dialog/add-template-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { LyDialog } from '@alyle/ui/dialog';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit {
  height = '150px'
  width = '700px'
  isElementClickAndHold = false
  ticketLayouts = [];
  clickTimer
  @Input() backgroundImage
  @Input() backgroundColor

  @Input() qrLink
  @Input() qrId
  @Input() ticketId

  @ViewChild('eventName') eventName: ElementRef<HTMLInputElement>;

  backgroundImages = [
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon1.png',
      image : 'assets/tickets/temp1.png'
    },
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon2.jpg',
      image : 'assets/tickets/temp2.jpg'
    },
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon3.jpg',
      image : 'assets/tickets/temp3.jpg'
    },
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon4.jpg',
      image : 'assets/tickets/temp4.jpg'
    },
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon5.jpg',
      image : 'assets/tickets/temp5.jpg'
    },
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon6.jpg',
      image : 'assets/tickets/temp6.jpg'
    },
    {
      isSelected : 0,
      icon : 'assets/tickets/tempIcon7.jpg',
      image : 'assets/tickets/temp7.jpg'
    },
  ]

  imgDroplabel = 'Drag & Drop image here or select image.'

  croppedImageFile: any = '';
  showHiddenElement = false;
  selectedEleId = null;

  parentElement: string = '';
  // preBuildTemplate: any;

  @Output() preBuildTemplate = new EventEmitter<any>();

  constructor(
    private _matDialog: MatDialog,
    private renderer: Renderer2,
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    public _commonService: CommonService,
    public _createTicketService: CreateTicketService,
    private _snackBar: MatSnackBar,
    private _httpClient: HttpClient,
    private _snackbar: MatSnackBar,
    private _fuseProgressBar: FuseProgressBarService,

  ) {
    this.getAllTicketsBackground();
    this.openTemplateDialog();

    // this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)];

    this.renderer.listen('window', 'click', (e) => {
      if (e.target.id == 'wrapper' || e.target.id == 'ticketTemplate' || e.target.id == 'ticketTemplateController' || e.target.id == 'template') {
        this.selectedEleId = null
        this.showHiddenElement = false
      }
    });
  }
  ngOnInit() {
  }

  @HostListener('document:keydown', ['$event']) onClickEvent(event: any) {
    let keys = event.keyCode;
    if (this.selectedEleId) {
      switch (keys) {
        case 37:
          this.leftClick()
          break;
        case 38:
          this.upClick()
          break;
        case 39:
          this.rightClick()
          break;
        case 40:
          this.downClick()
          break;
      }
    }
  }

  setFontColor(color) {
    this.parentElement == '' ? (this._createTicketService.templateData[this.selectedEleId].fontColor = color) : (this._createTicketService.templateData[this.parentElement][this.selectedEleId].fontColor = color)
  }

  onSelectBg(i){
    // this.backgroundImages[i].isSelected = 1;
    this.backgroundImages.forEach((item:any, j) => {
      if(j == i){
        item.isSelected = 1;
        this._createTicketService.templateData.backgroundImage = item.backgroundImage;
      }else{
        item.isSelected = 0; 
      }
    })
  }


  globalAlign(val) {
    let selectedElement
    if (this.parentElement == '') {
      selectedElement = this._createTicketService.templateData[this.selectedEleId]
    }
    else {
      selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)]
    }

    if (val == 'hl') {
      selectedElement.position.left = 0
    }
    else if (val == 'hc') {
      selectedElement.position.left = 50
    }
    else if (val == 'hr') {
      selectedElement.position.left = 100
    }

    else if (val == 'vt') {
      selectedElement.position.top = 0
    }
    else if (val == 'vc') {
      selectedElement.position.top = 50
    }
    else if (val == 'vb') {
      selectedElement.position.top = 100
    }
  }

  localAlign(value) {
    if (this.parentElement == '') {
      this._createTicketService.templateData[this.selectedEleId].align = value
    }
    else {
      this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)].align = value
    }
  }

  // onAlignChange(val) {
  //   let selectedElement

  //   if (this.parentElement == '') {
  //     selectedElement = this._createTicketService.templateData[this.selectedEleId]
  //   }
  //   else {
  //     selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)]
  //   }

  //   console.log('**', selectedElement)
  //   if (val == 'tl') {
  //     selectedElement.position.top = 0
  //     selectedElement.position.left = 0
  //     console.log(this._createTicketService.templateData[this.parentElement])
  //   }
  //   else if (val == 'tc') {
  //     selectedElement.position.top = 0
  //     selectedElement.position.left = 50
  //   }
  //   else if (val == 'tr') {
  //     selectedElement.position.top = 0
  //     selectedElement.position.left = 100
  //   }
  //   else if (val == 'cl') {
  //     selectedElement.position.top = 50
  //     selectedElement.position.left = 0
  //   }
  //   else if (val == 'cc') {
  //     selectedElement.position.top = 50
  //     selectedElement.position.left = 50
  //   }
  //   else if (val == 'cr') {
  //     selectedElement.position.top = 50
  //     selectedElement.position.left = 100
  //   }

  //   else if (val == 'bl') {
  //     selectedElement.position.top = 100
  //     selectedElement.position.left = 0
  //   }
  //   else if (val == 'bc') {
  //     selectedElement.position.top = 100
  //     selectedElement.position.left = 50
  //   }
  //   else if (val == 'br') {
  //     selectedElement.position.top = 100
  //     selectedElement.position.left = 100
  //   }
  // }

  onShowHideEle(isShow) {
    if (this.parentElement == '')
      this._createTicketService.templateData[this.selectedEleId].display = isShow
    else
      this._createTicketService.templateData[this.parentElement][this.selectedEleId].display = isShow
  }

  addDynamicElementBtn() {
    this._createTicketService.templateData.dynamicElements.push(
      JSON.parse(JSON.stringify(this._createTicketService.newDynamicElement))
    )
  }

  onBoldClick() {
    if (this.parentElement == '') {
      this._createTicketService.templateData[this.selectedEleId].bold = !this._createTicketService.templateData[this.selectedEleId].bold
    } else
      this._createTicketService.templateData[this.parentElement][this.selectedEleId].bold = !this._createTicketService.templateData[this.parentElement][this.selectedEleId].bold
  }

  onItalicClick() {
    if (this.parentElement == '') {
      this._createTicketService.templateData[this.selectedEleId].italic = !this._createTicketService.templateData[this.selectedEleId].italic
    } else
      this._createTicketService.templateData[this.parentElement][this.selectedEleId].italic = !this._createTicketService.templateData[this.parentElement][this.selectedEleId].italic
  }

  onUnderlineClick() {
    if (this.parentElement == '') {
      this._createTicketService.templateData[this.selectedEleId].underline = !this._createTicketService.templateData[this.selectedEleId].underline
    } else
      this._createTicketService.templateData[this.parentElement][this.selectedEleId].underline = !this._createTicketService.templateData[this.parentElement][this.selectedEleId].underline
  }

  onElementClick(event, eleType?) {
    console.log("event.target.id", event.target.id);
    if (eleType) {
      this.parentElement = eleType
    } else {
      this.parentElement = ''
    }
    this.selectedEleId = '';
    this.selectedEleId = event.target.id;
  }


  mousedown(key) {
    if (key == 'lc') {
      this.clickTimer = setInterval(() => {
        this.leftClick()
      }, 125)
    }
    else if (key == 'rc') {
      this.clickTimer = setInterval(() => {
        this.rightClick()
      }, 125)
    }
    else if (key == 'uc') {
      this.clickTimer = setInterval(() => {
        this.upClick()
      }, 125)
    }
    else if (key == 'dc') {
      this.clickTimer = setInterval(() => {
        this.downClick()
      }, 125)
    }
    else if (key == 'rr') {
      this.clickTimer = setInterval(() => {
        this.rotateRight()
      }, 125)
    }
    else if (key == 'rl') {
      this.clickTimer = setInterval(() => {
        this.rotateLeft()
      }, 125)
    }
  }

  mouseup() {
    clearInterval(this.clickTimer);
  }

  leftClick() {

    if (!(this.selectedEleId == 'ticket')) {
      let selectedElement
      if (this.parentElement == '') { selectedElement = this._createTicketService.templateData[this.selectedEleId] }
      else { selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
      if (selectedElement.position.left > 0) selectedElement.position.left -= 0.5;
    }
  }

  rightClick() {
    if (!(this.selectedEleId == 'ticket')) {
      let selectedElement
      if (this.parentElement == '') { selectedElement = this._createTicketService.templateData[this.selectedEleId] }
      else { selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
      if (selectedElement.position.left < Number(this._createTicketService.defaultTemplateFormat.width - selectedElement.width))
        selectedElement.position.left += 0.5;
    }
  }

  upClick() {
    if (!(this.selectedEleId == 'ticket')) {
      let selectedElement
      if (this.parentElement == '') { selectedElement = this._createTicketService.templateData[this.selectedEleId] }
      else { selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
      if (selectedElement.position.top > 0) selectedElement.position.top -= 0.4;
    }
  }

  downClick() {
    if (!(this.selectedEleId == 'ticket')) {
      let selectedElement
      if (this.parentElement == '') { selectedElement = this._createTicketService.templateData[this.selectedEleId] }
      else { selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
      if (selectedElement.position.top < 310)
        selectedElement.position.top += 0.4;
    }
  }

  rotateLeft() {

    this.clickTimer = setInterval(() => {
      let selectedElement
      if (this.parentElement == '') {
        selectedElement = this._createTicketService.templateData[this.selectedEleId]
      }
      else {
        selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)]
      }
      selectedElement.rotate--
    }, 1)

  }

  changeFont(opreators) {
    if (this._createTicketService.templateData[this.selectedEleId].fontSize > 8 && opreators === '-') {
      this.parentElement == '' ? this._createTicketService.templateData[this.selectedEleId].fontSize-- : this._createTicketService.templateData[this.parentElement][this.selectedEleId].fontSize--;
    } else if (this._createTicketService.templateData[this.selectedEleId].fontSize < 150 && opreators === '+') {
      this.parentElement == '' ? this._createTicketService.templateData[this.selectedEleId].fontSize++ : this._createTicketService.templateData[this.parentElement][this.selectedEleId].fontSize++;
    }
  }

  rotateRight() {
    this.clickTimer = setInterval(() => {
      let selectedElement
      if (this.parentElement == '') {
        selectedElement = this._createTicketService.templateData[this.selectedEleId]
      }
      else {
        selectedElement = this._createTicketService.templateData[this.parentElement][parseInt(this.selectedEleId)]
      }

      selectedElement.rotate++
    }, 1)
  }
  opacityLabel(value: number) {
    return value * 100;
  }

  public dropped(event) {
    // console.log(files)
    // this.files = files;
    // for (const droppedFile of event) {
    //   // Is it a file?
    //   if (droppedFile.fileEntry.isFile) {
    //     const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    //     fileEntry.file((file: File) => {
          
    //     });
    //   } else {
    //     // It was a directory (empty directories are added, otherwise only files)
    //     const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    //   }
    // }
  }


  openCropper(event) { 
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 320,
              height: 109,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                width: 940,
                height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this._cd.markForCheck();
          this.onFileUpload(result.file, result.blobFile)
      }
    });
  }
 
  removeImage() {
    this._createTicketService.templateData.backgroundImage = '',
      this._createTicketService.templateData.imagePreview = '',
      this.croppedImageFile = '',
      this._createTicketService.ticketBackgroundFile = ''
  }

  public fileOver(event) {
    this.imgDroplabel = "Drop your image"
  }

  public fileLeave(event) {
    this.imgDroplabel = "Drag & Drop image here or select image."
  }

  openTemplateDialog() {
    this._fuseProgressBar.show()
    this._httpClient.get(environment.apiURL + 'admin/tickets/getall/layout', this._commonService.getHeader()).subscribe((result: any) => {
      this._fuseProgressBar.hide()
      if(result.status == 200){
        this.ticketLayouts = result.data.layout;
      }
      else if(result.status == 400){
        this.ticketLayouts = [];
      }else{
        this.ticketLayouts = [];
      }
    })
  }

  addTemplate() {
    this._matDialog.open(AddTemplateDialogComponent, {
      panelClass: 'no-padding-dialog',
      data: {}
    }).afterClosed().subscribe((dialogData: any) => {
      if (dialogData) {
        const layout = this._createTicketService.applyTicketFormat(JSON.parse(JSON.stringify(this._createTicketService.templateData)));
        this._createTicketService.addTemplate(dialogData, JSON.parse(JSON.stringify(layout)));
      }
    })
  }


/* 
  ticket background 
*/

  getAllTicketsBackground(){
    this._commonService.getAllBackgroundImage().then((res:any)=>{
      if(res.status == 200){
        console.log("this is res", res);
        this.backgroundImages = res.data;
        this.backgroundImages.map((item)=>{
          item['isSelected'] = 0;
        })
        this._cd.detectChanges();
      }else{
        this.backgroundImages = [];
      }
    })
  }

  onDeleteBG(image){
      let dialog = this._matDialog.open(ConfirmationDialogComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        disableClose: false,
        data : {
            message : 'Are you sure you want to remove this ?',
            image : '/assets/ui/delete.png',
            focus : 'right',
            deleteItem : 'Remove Background?',
            confirmButtonText : 'Yes',
            cancelButtonText : 'No'
        }
      })
    // handle after close
      dialog.afterClosed().subscribe((res) => {
        if(res){
          this._commonService.deleteBgImage({id : image.id}).then((result:any)=>{
            if(result.status == 200){
              this.getAllTicketsBackground();
              // check if deleteable image is selected then change the selected image
              console.log("image", image);
              if(image.isSelected){
                this.onSelectBg(0)
              }
            }
            this._snackBar.open(result.message, "OKAY", {duration : 2000})
          })
        }
      })
  }




  


  onFileUpload(fileObj, blobFile) {
    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName" : 'ticketBackground_',
      "folderName":  `ticket/backgroundImage`, // it is fix set by backend.
      'mimeType': fileObj?.type
    }

    this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
      if (result.status == 200) {
          console.log("result.data", result.data); 
          // getFile Upload stauts 
          const payload = {
              'id': result.data.id,
              'status': 1 // fix by backend.
          }
          this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile).then((res)=>{
            if(res){ 
              this.onuploadImageInAPI(result?.data?.imageUploadUrl)
              this.getAllTicketsBackground();
            }
          })
          this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
              if (uploadStatusResponse.status == 200) { 
                  this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 }) 
              } else {
                  this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              }
          })
      } else {
          // this._snackbar.open(result.message, "OKAY", { duration: 2000 })
      }
    })
  }


  onuploadImageInAPI(link){
    this._commonService.uploadTicketBackground(link).then((res:any)=>{
      if(res.status){
        // need to update all images.
        console.log("res", res);
       
      }else{

      }
    })
  }

}
