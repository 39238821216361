import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddEditVerifiersLocationComponent } from '@fuse/Dialog/add-edit-verifiers-location/add-edit-verifiers-location.component';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { Subject } from 'rxjs';
import { VerifiersScanLocationService } from './verifiers-scan-location.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-verifiers-scan-location-list',
  templateUrl: './verifiers-scan-location-list.component.html',
  styleUrls: ['./verifiers-scan-location-list.component.scss'],
  animations : fuseAnimations
})
export class VerifiersScanLocationListComponent implements OnInit {

  dataSource;
  maxAvailableRecordsInTable;
  displayedColumns = ['locationType', 'locationNames'];
  filter = {
    pageSize: 10,
    pageIndex: 1
  }

  @ViewChild(MatPaginator, { static: true })paginator: MatPaginator;
  
  constructor(
    private _ToolbarService : ToolbarService,
    private _matsnakebar : MatSnackBar,
    private _verifiersScanLocationService: VerifiersScanLocationService, 
    private _matDialog : MatDialog,
    private _commonservice : CommonService
  ) {
    this.setToolBarTitle();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._verifiersScanLocationService.verifiersScanList);
    this.maxAvailableRecordsInTable = this._verifiersScanLocationService?.maxAvailableRecordsInTable;
  }


  setToolBarTitle(){ 
      // for title
      this._ToolbarService._ToolBarTitle.next('Verifiers Scan Location');
      // for btn
      this._ToolbarService._isBackBtnShow.next(false); 
  }


  onOpenLocationDialog(mode, locationData = null){
    const dialog = this._matDialog.open(AddEditVerifiersLocationComponent,{
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '420px',
      autoFocus : false,
      data : {
        mode : mode,
        createData : locationData
      } 
    })
    
      // dialog after close 
      dialog.afterClosed().subscribe((res) => {
        if(res)
        if(res?.mode == 'add'){
            this._commonservice.addNewVerifiersLocation(res.data).then((response:any) => {
              if(response.status == 200){
                this._matsnakebar.open(response.message, 'OKAY', {duration : 2000})
                this.filterData();
              }
            })
          }else if(res.mode == 'edit'){
            
          }else{
            
          }
      })
    
  }

  onPageChange(event){
    this.filter.pageIndex = event.pageIndex + 1;
    this.filter.pageSize = event.pageSize;
    this.filterData();
  }

  filterData() {
    let payload = {
        "pageIndex": this.filter.pageIndex,
        "pageSize": this.filter.pageSize,
    }
    this._verifiersScanLocationService.getAllVerifiersScanList(payload).then((result: any) => {
        if (result.status == 200) {
            console.log("result", result);
            this.dataSource.data = result.data;
            this.maxAvailableRecordsInTable = result.data.totalRecords;
        }else if (result.status == 400) {
            this.dataSource.data = [];
            this.maxAvailableRecordsInTable = 0;
        }
        else {
            this.dataSource.data = []
            this.maxAvailableRecordsInTable = 0
        }
    })
  }

  
}
