import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '@fuse/services/common.service';
import { PollService } from './poll.service';
import { MatDialog } from '@angular/material/dialog';
import { PublishPollComponent } from '@fuse/Dialog/publish-poll/publish-poll.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forEach } from 'lodash';

@Component({
  selector: 'app-poll',
  templateUrl: './poll.component.html',
  styleUrls: ['./poll.component.scss']
})
export class PollComponent implements OnInit, OnDestroy {
  form: FormGroup;
  interval: any;
  pollId: any;
  pageType: any;
  pollDetail: any;
  pollStatus = {
    draft : false,
    upcomming : false,
    ongoing : false,
    completed : false
  }
  constructor(public _commonService: CommonService,
    public _pollService: PollService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private _toolbarService: ToolbarService,
    private _snackBar: MatSnackBar,
    private _activateRoute: ActivatedRoute,
    private _router: Router) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this._activateRoute.params.subscribe((result: any) => {
      // console.log('this.paramId', result);
      if (result.type) {
        this.pollId = result.id;
        this.pageType = result.type;
      }
    });



    if (this.pageType === 'detail') {
      this.pollDetail = this._pollService.pollDetail;
      this.form = this.createFrom(this.pollDetail);
      this.updateStatus();
      this.pollDetail.options.forEach(element => {
        if((!this.pollStatus.draft && this.pollStatus.ongoing) || (!this.pollStatus.draft && this.pollStatus.completed)){
          const r = Math.floor(Math.random() * 156 + 100); // Red component (100-255)
          const g = Math.floor(Math.random() * 156 + 100); // Green component (100-255)
          const b = Math.floor(Math.random() * 156 + 100); // Blue component (100-255)
          const color = `rgb(${r},${g},${b})`;
          element.color = color;
        }else{
          const color = `rgb(177,177,177, 1)`;
          element.color = color;
        }
      });
      this.interval = setInterval(() => { this.getPollEndTime() }, 1000)

      // this.setToolBarTitle();
      this._toolbarService._ToolBarTitle.next("View a poll");
      this._toolbarService._isBackBtnShow.next(true);


    } else if (this.pageType === 'add') {
      this.pollDetail = {}
      this.form = this.createFrom()
      this.addRow();
      // this.setToolBarTitle();
      this._toolbarService._ToolBarTitle.next("Add a poll");
      this._toolbarService._isBackBtnShow.next(true);
    } else {
      this.pollDetail = this._pollService.pollDetail
      this.form = this.createFrom(this.pollDetail)
      this.addRow(this.pollDetail.options);
      // this.setToolBarTitle();
      this._toolbarService._ToolBarTitle.next("Edit a poll");
      this._toolbarService._isBackBtnShow.next(true);
    }

  }

  ngOnInit(): void {

  }

  updateStatus(){
    // check poll is draft or publish
      if(this.pollDetail.status){
        this.pollStatus.draft = false;
      }else{
        this.pollStatus.draft = true;
        this.pollStatus.completed = false;
        this.pollStatus.ongoing = false;
        this.pollStatus.upcomming = false;
      }
      
      // make status if poll publish
      if(!this.pollStatus.draft){
        console.log("get gap beetween start date", new Date(this.pollDetail?.startDate).getTime() - new Date().getTime() < 0);
        console.log("get gap beetween end date", new Date(this.pollDetail?.endDate).getTime() - new Date().getTime() < 0);

        // upcomming
        if(!(new Date(this.pollDetail?.startDate).getTime() - new Date().getTime() < 0)){
          this.pollStatus.completed = false;
          this.pollStatus.ongoing = false;
          this.pollStatus.upcomming = true;
        }

        // ongoing
        if((new Date(this.pollDetail?.startDate).getTime() - new Date().getTime() < 0) && !(new Date(this.pollDetail?.endDate).getTime() - new Date().getTime() < 0)){
          this.pollStatus.completed = false;
          this.pollStatus.ongoing = true;
          this.pollStatus.upcomming = false;
        }
        
        // completed
        if((new Date(this.pollDetail?.endDate).getTime() - new Date().getTime() < 0)){
          this.pollStatus.completed = true;
          this.pollStatus.ongoing = false;
          this.pollStatus.upcomming = false;
        } 
      }else{
        this.pollStatus.completed = false;
        this.pollStatus.ongoing = false;
        this.pollStatus.upcomming = false;
      }

      console.log("poll status", this.pollStatus);
  }


  ngOnDestroy(): void {
    console.log("ngOnDestroy ===>")
    clearInterval(this.interval)
  }


  public getPollEndTime(): void {
    // Create Date objects 
    // const startDate = (new Date(this.pollDetail.startDate).getTime() > new Date().getTime()) ? new Date(this.pollDetail.startDate).getTime() : new Date().getTime();
    const targetDate = new Date(this.pollDetail.endDate).getTime();
    // Calculate the time difference in milliseconds
    const timeDifference =  targetDate - new Date().getTime();
    // Calculate days, hours, minutes, and seconds
    this.pollDetail.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    this.pollDetail.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.pollDetail.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    this.pollDetail.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  }


  private createFrom(poll?): FormGroup {
    return this.fb.group({
      eventId: [poll?.eventId ?? '', [Validators.required]],
      subject : [poll?.subject ?? '', Validators.required],
      question: [poll?.question ?? '', [Validators.required]],
      description: [poll?.description ?? '', [Validators.required]],
      isUserVerified: [poll?.isUserVerified ?? ''],
      options: this.fb.array([]),
    })

  }



  get options(): FormArray {
    return this.form.controls["options"] as FormArray;
  }

  public addRow(options?): void {
    if (options && options.length) {
      options?.forEach(element => {
        console.log(element);
        (<FormArray>this.form.get("options")).push(
          this.fb.group({ option: [element?.pOption, Validators.required] })
        );
      });
    } else {
      (<FormArray>this.form.get("options")).push(
        this.fb.group({ option: ["", Validators.required] })
      );

    }
  }


  public removeRow(i): void {
    (<FormArray>this.form.get("options")).removeAt(i);
  }


  public saveToDraft(): void {
    console.log("saveToDraft ==>", this.form);
    if (this.form.valid) {
      const payload = this.form.value;
      payload.status = 0;
      payload.eventId = payload.eventId;
      payload.pollSchedule = 0;
      console.log("payload", payload);
      payload.options = payload.options.map((el) => el?.option || el);
      console.log(payload)
      if (this.pageType === 'add') {
        this.addPoll(payload);
      } else {
        payload.id = this.pollId
        console.log("edit case poll payload final ==>", payload)
        this.editPoll(payload);
      }
    } else {
      this.form.markAllAsTouched()
    }

  }

  public publish(): void {
    console.log("publish ==>", this.form)
    console.log(this.form.get('eventId').value)
    if (this.form.valid) {

      this._dialog
        .open(PublishPollComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width: '600px',
          data: {
            eventEndDate: this._pollService.eventList.find((el) => this.form.get('eventId').value === el.id).eventEndDate
          }
        }).afterClosed().subscribe((result: any) => {
          if (result) {

            const payload = { ...result, ...this.form.value }
            console.log("payload after dialog close ===>", payload)

            if (payload.publishType === '1') {
              payload.startDate = new Date().getTime();
            } else if (payload.publishType === '3') {
              payload.startDate = this._pollService.eventList.find((el) => this.form.get('eventId').value === el.id).eventEndDate;
            } else {
              payload.startDate = new Date(payload.startDate).getTime();
            }
            payload.pollSchedule = payload?.publishType == '1' ? '1': '2';
            payload.eventId = payload.eventId;
            payload.options = payload.options.map((el) => el.option);
            payload.endDate = this.convertToTimestamp(payload.days, payload.hours, payload.minutes) + payload.startDate;
            payload.status = 1;
            delete payload.publishType;
            delete payload.days;
            delete payload.hours;
            delete payload.minutes;
            console.log("payload final ===>", payload);


            if (this.pageType === 'add') {
              this.addPoll(payload);
            } else {
              payload.id = this.pollId
              console.log("edit case poll payload final publish ==>", payload)
              this.editPoll(payload);
            }
          }
        })
    } else {
      // this.form.markAsDirty()
      this.form.markAllAsTouched()
    }
  }

  convertToTimestamp(days, hours, minutes) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // 1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    const millisecondsPerHour = 60 * 60 * 1000; // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
    const millisecondsPerMinute = 60 * 1000; // 1 minute = 60 seconds * 1000 milliseconds
    
    const totalMilliseconds = days * millisecondsPerDay + hours * millisecondsPerHour + minutes * millisecondsPerMinute;

    const timestamp = totalMilliseconds;
    return timestamp;
  }

  refresh(){
    this._pollService.getPoll().then((res:any)=>{
      if(res.status == 200){
        this.pollDetail = res.data;
        this.updateStatus();
        this.pollDetail.options.forEach(element => {
          if(this.pollDetail.isPollEnd){
            const r = Math.floor(Math.random() * 156 + 100); // Red component (100-255)
            const g = Math.floor(Math.random() * 156 + 100); // Green component (100-255)
            const b = Math.floor(Math.random() * 156 + 100); // Blue component (100-255)
            const color = `rgb(${r},${g},${b})`;
            element.color = color;
          }else{
            const color = `rgb(177,177,177, 1)`;
            element.color = color;
          }
        });
        this.interval = setInterval(() => { this.getPollEndTime() }, 1000)
      }else{
        this._snackBar.open(res.message, 'OKAY', {duration : 2000}) 
      }
    })
  }


  public addPoll(payload): void {
    this._pollService.addPoll(payload).then((result: any) => {
      this._snackBar.open(result.message, 'Okay', { duration: 2000 })
      if (result.status === 200) {
        this._router.navigate(['/polls'])
      } else {
      }
    })
  }

  public editPoll(payload): void {
    this._pollService.editPoll(payload).then((result: any) => {
      this._snackBar.open(result.message, 'Okay', { duration: 2000 })
      if (result.status === 200) {
        this._router.navigate(['/polls'])
      } else {
      }
    })
  }

  public publishNow(): void {
    if (!this.pollDetail?.status) {
      this._pollService.publishPoll().then((result: any) => {
        this._snackBar.open(result.message, 'Okay', { duration: 2000 });
        if (result.status === 200) {
          this._router.navigate(['/polls']);
        } else {

        }
      })
    }
  }

  public pollEnd(): void {
    this._pollService.pollEnd().then((result: any) => {
      this._snackBar.open(result.message, 'Okay', { duration: 2000 });
      if (result.status === 200) {
        this._router.navigate(['/polls']);
      } else {

      }
    })
  }
}
