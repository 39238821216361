import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-invitation-message-detail',
  templateUrl: './invitation-message-detail.component.html',
  styleUrls: ['./invitation-message-detail.component.scss']
})
export class InvitationMessageDetailComponent implements OnInit {

  form:UntypedFormGroup;
  members = []
  content: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<InvitationMessageDetailComponent>,
    private _fb : UntypedFormBuilder
  ) { 
    console.log("log in dialog this is the data", _data);
    this.createForm(_data)
    this.members = _data.memberList
    this.content = _data.content
    console.log("this is the member listing ", this.members);
    
  }

  ngOnInit(): void {
  }

  createForm(data){
    this.form = this._fb.group({
      message : [data?.content || ''],
      //memberList  : [data?.memberNames || '']
    })
  }

}
