import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild, ViewEncapsulation, } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Enums, addRanking, darkTheme, sortByTimestamp } from "@fuse/utils/systemEnums";
import { AddEditEventService } from "../edit-event/add-edit-event.service";
import { AddGuestDialogComponent } from "@fuse/Dialog/add-guest-dialog/add-guest-dialog.component";
import { MatDialog } from "@angular/material/dialog"; 
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { CommonService } from "@fuse/services/common.service"; 
import { AddOrganiserDialogComponent } from "@fuse/Dialog/add-organiser-dialog/add-organiser-dialog.component";
import { MultipleDatepickerComponent } from "@fuse/Dialog/event-multiple-datepicker-dialog/multiple-datepicker/multiple-datepicker.component";
import { AddVenueComponent } from "@fuse/Dialog/add-venue/add-venue.component";
import { CropperComponent } from "@fuse/Dialog/cropper/cropper.component";
import { BuyerDetailsConfigurationComponent } from "../edit-event/buyer-details-configuration/buyer-details-configuration.component";
import { MatStepper } from "@angular/material/stepper";
import { AddActivityComponent } from "@fuse/Dialog/add-activity/add-activity.component";
import { SpeakerService } from "app/main/speaker/speaker.service";
import { AddTaxDialogComponent } from "@fuse/Dialog/add-tax-dialog/add-tax-dialog/add-tax-dialog.component";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";
import { AddEditFaqDialogComponent } from "@fuse/Dialog/add-edit-faq-dialog/add-edit-faq-dialog.component"; 
import moment = require("moment");
import { AddEditResourcesDialogComponent } from "@fuse/Dialog/add-edit-resources-dialog/add-edit-resources-dialog.component"; 
import { SponsorService } from "app/main/masters/sponsor/sponsor.service";
import { fuseAnimations } from "@fuse/animations";
import { LyDialog } from "@alyle/ui/dialog"; 
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { timezones } from "@fuse/utils/timezones";
import { ConfirmationDialogComponent } from "@fuse/Dialog/confirmation-dialog/confirmation-dialog.component";
import { BOOKING_TYPE, EVENT_CONVENIENCE_FEE, EVENT_PRICE, EVENT_RECURRING, EVENT_SCHEDULE, EVENT_STEPS, EVENT_TICKET_SALE_END_DATE, EVENT_TICKET_SALE_START_DATE, RECURRINGEND, WAY_OF_EVENT, WEEKDAYS_ENUMS } from "./createEventTypes";
import { AddEditSponsorGroupComponent } from "@fuse/Dialog/add-edit-sponsor-group/add-edit-sponsor-group.component";
import { AddEditHostGroupComponent } from "@fuse/Dialog/add-edit-host-group/add-edit-host-group.component";
import { AddEditCommonDataProviderService } from "./add-edit-common-data-provider.service";
import { Subscription } from "rxjs";
import { environment } from "environments/environment";

@Component({
    selector: "app-create-event",
    templateUrl: "./create-event.component.html",
    styleUrls: [
        "./create-event.component.scss",
        "../../../../../node_modules/quill/dist/quill.core.css",
        "../../../../../node_modules/quill/dist/quill.bubble.css",
        "../../../../../node_modules/quill/dist/quill.snow.css",
    ],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class CreateEventComponent implements OnInit, OnDestroy {

    quillConfig: any = {
        //toolbar: '.toolbar',
        toolbar: {
            container: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],

                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction

                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],

                ["clean"], // remove formatting button

                ["link"],
            ],
        },
    }
    // indiaTimeZoneObj = {
    //     "value": "India Standard Time",
    //     "abbr": "IST",
    //     "offset": 5.5,
    //     "isdst": false,
    //     "name": "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    //     "utc": [
    //         "Asia/Kolkata",
    //         "Asia/Calcutta"
    //     ]
    // }

    timeZones = timezones;
    selectedTimeZone: any = null;
    // ENUMS 
    // dark theme for 
    darkTheme = darkTheme;
    addRanking = addRanking;
    sorting = sortByTimestamp;
    EVENT_STEPS: EVENT_STEPS[] = JSON.parse(JSON.stringify(Enums.EVENT_STEPS));
    EVENT_SCHEDULE_TYPE: EVENT_SCHEDULE[] = Enums.EVENT_SCHEDULE;
    EVENT_PRICE: EVENT_PRICE[] = Enums.EVENT_PRICE;
    EVENT_RECURRING: EVENT_RECURRING[] = JSON.parse(JSON.stringify(Enums.EVENT_RECURRING));
    RECURRINGEND: RECURRINGEND[] = Enums.RECURRINGEND;
    EVENT_TICKET_SALE_START_DATE: EVENT_TICKET_SALE_START_DATE[] = Enums.EVENT_TICKET_SALE_START_DATE;
    EVENT_TICKET_SALE_END_DATE: EVENT_TICKET_SALE_END_DATE[] = Enums.EVENT_TICKET_SALE_END_DATE;
    EVENT_CONVENIENCE_FEE: EVENT_CONVENIENCE_FEE[] = Enums.EVENT_CONVENIENCE_FEE;
    SCANNINGBEFORE = Enums.SCANNINGBEFORE;
    EVENT_WAY: WAY_OF_EVENT = Enums.WAY_OF_EVENT;
    WEEKDAYS: WEEKDAYS_ENUMS[] = JSON.parse(JSON.stringify(Enums.WEEKDAYS_ENUMS));
    BOOKING_TYPE : BOOKING_TYPE[] = JSON.parse(JSON.stringify(Enums.BOOKING_TYPE));

    resourseList: [] = []; 
    taxList:[] = [];
    isEditable: boolean = false;
    eventInfo: UntypedFormGroup;
    eventSchedule: UntypedFormGroup;
    ticketSale: UntypedFormGroup;
    venueActivity: UntypedFormGroup;
    eventDetails: UntypedFormGroup;
    readyToPublish: UntypedFormGroup;
    selectedSchedule = Number(1);
    isAddEditActivityBtnShow: Number = 0; // 0 = hidden & 1 = add & 2 = edit;
    isOptional: boolean = environment.apiURL == 'https://api-stage.eventlocal.io/api/v3/' ? true : false;

    slotData = [];
    allSchedule: any = [];
    eventSaleStartDataType: Number = 1;
    eventSaleEndDataType: Number = 1;
    
    scheduleData: any = [];
    eventId: Number;
    currentDate = new Date();
    selectedDateForActivities;

    // for handle sale start and end date
    savedDatesInDefFormate: any = {};
    // globalPayload: any = {};

    croppedThumbBase64: any = null;
    croppedBannerBase64: any = null;
    matStepperLayout: string = 'horizontal';
    recurringEndType: string = '1';
    width: number = 0;

    @ViewChild('stepper', { static: false }) stepper: MatStepper;
    eventData: any;
    activityPayload = [];
    ticketData = [];
    allSpeakersList: any = [];
    public dynamicIndex = Number(0);
    custormerBookingInfo: any = {};
    isAddCase: boolean = false;
    performerSchedule: any = [];
    // selectedSpeakers:any = []
    selectedHostGroup: any = [];
    selectedSponsorsGroup: any = [];
    selectedIndex: any;
    // allSponsorsList:any = [];
    allSponsorsGroupList: any = [];
    allReageDates: any = [];
    allHostGroupList: any = [];
    eventScheduleDisable: boolean = false;
    // selectedTimeZoneOffsetForShowTime: string;
    currentCase: string;
    allHostList: [] = [];
    isIncompleteEvent: boolean = false;
    allGuest = [];
    allOrganisers = [];
    allVenues = [];
    faqListData = [];

    eventStartDate;
    eventEndDate;
    private subscriptions: Subscription[] = [];

    @HostListener('window:resize', ['$event'])
    changeMatStepperToVartical(event?) {
        if (event) {
            this.width = event.target.innerWidth;
            if (this.width <= 768) {
                this.matStepperLayout = 'vertical';
            } else {
                this.matStepperLayout = 'horizontal';
            }
        } else {
            let pageWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            if (pageWidth <= 768) {
                this.matStepperLayout = 'vertical';
            } else {
                this.matStepperLayout = 'horizontal';
            }
        }
    }

    constructor(
        private _fb: UntypedFormBuilder,
        private _cd: ChangeDetectorRef,
        private _dialog: LyDialog,
        public _addEditEventService: AddEditEventService,
        public _addeditcommondataproviderservice : AddEditCommonDataProviderService,
        private _sponsorService: SponsorService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog, 
        private _ToolbarService: ToolbarService,
        private _SpeakerService: SpeakerService,
        private _activatedRoute: ActivatedRoute,
        public _commonService: CommonService, 
        private _router: Router, 
        private _fuseProgressBarService: FuseProgressBarService,
    ) {

        // get current time and add 10 min in that. 
        this.currentDate = this.addAdditionalTimeInCurrent(this.currentDate);

        // get all listing data like (event type, categories, org, guests, etc.)
        this.getAllNecessaryData()
        
        // it's detect screen and change stepper layout. horizontal & vertical.
        this.changeMatStepperToVartical();
 
        // form creation.
        this.eventInfo = this.createForm("eventInfo");
        this.eventSchedule = this.createForm("eventSchedule");
        this.ticketSale = this.createForm("ticketSale");
        this.venueActivity = this.createForm("venueActivity");
        this.eventDetails = this.createForm('eventDetails');
        this.readyToPublish = this.createForm('readyToPublish');

        // handle validation for first time.
        this.handleSaleStartFormValidation();

        this.readyToPublish.get('eventListingType').valueChanges.subscribe((res) => {
            if(res == 3){
                this.readyToPublish.get('externalBookingUrl').setValidators(Validators.required)
            }else{this.readyToPublish.get('externalBookingUrl').setValidators(null)}
        })

        // check is add case or edit case
        this._addeditcommondataproviderservice._currentCase.subscribe((res: string) => {
            if (res) {
                console.log("this is your res case", res);
                this.currentCase = res;
                if (res == 'edit') {
                    
                    // get event all data for set in from.
                    this.eventId = this._addeditcommondataproviderservice?.eventData.id;
                    this.eventData = this._addeditcommondataproviderservice?.eventData;

                    // edit case
                    this.editEvent();

                    if(this.eventData?.timezone?.id){
                        console.log("this.eventData?.timezone===>", this.eventData?.timezone);
                        this.selectedTimeZone = this.eventData?.timezone;
                        this.eventSchedule.get('timeZone').setValue(String(this.selectedTimeZone?.id))
                    }
                } 
                else {
                    // add case
                    this.isAddCase = true;
                    
                    // this._router.navigate([], {
                    //     relativeTo : this._activatedRoute,
                    //     queryParams: {
                    //         step: 0
                    //     }
                    // });

                    // set title for add case.
                    this.setToolBarTitle(false);

                    // handle validation.
                    this.eventScheduleformValidation('oneDayEvent');

                    this.eventSchedule.get("eventDate").valueChanges.subscribe((value) => {
                        if (value) this.slotData[0] = value;
                    });

                    this.eventSchedule.get('recurringDate').valueChanges.subscribe((value) => {
                        if (value) {
                            this.addDayAndDateInWhenRecurringField(value);
                            if (this.eventSchedule.get('whenRecurring').value && value) {
                                this.preventWeekDayAccordingToSelectedDate();
                            }
                        }
                    })
                    this.eventSchedule.get('whenRecurring').valueChanges.subscribe((value) => {
                        if (value == 1) {
                            this.preventWeekDayAccordingToSelectedDate();
                        }
                    })

                    // handle validation in online & offline case. 
                    this.venueActivity.get('isOnline').valueChanges.subscribe((value) => {
                        if (value) {
                            if (value == 1) {
                                this.venueActivity.controls.eventVenue.setValue('')
                                this.venueActivity.controls.eventVenue.setErrors(null)
                                this.venueActivity.controls.eventVenue.setValidators(null)
                                this.venueActivity.controls.joinUrl.setValidators(Validators.required)
                                console.log("this.venueActivity -- form --->", this.venueActivity);
                            } else {
                                this.venueActivity.controls.joinUrl.setValue('')
                                this.venueActivity.controls.joinUrl.setErrors(null)
                                this.venueActivity.controls.joinUrl.setValidators(null)
                                this.venueActivity.controls.eventVenue.setValidators(Validators.required)
                                console.log("this.venueActivity -- form --->", this.venueActivity);
                            }
                        }
                    })

                }
            }
        })

        this.eventSchedule.get("eventDate").valueChanges.subscribe((value) => {
            if (value) this.slotData[0] = value;
        });
    }



    
    /*
        on in it.
    */
    ngOnInit(): void {
        this.removeHTTPS();
    }

    getAllNecessaryData(){
        
        const subscriptionGuest = this._addeditcommondataproviderservice._allGuest.subscribe((dataArr) => {
            if(dataArr?.length){
                this.allGuest = dataArr;
                console.log("_allGuest", dataArr);
            }else{
                this.allGuest = [];
            }
        })
        this.subscriptions.push(subscriptionGuest);


        const subscriptionOrg =  this._addeditcommondataproviderservice._allOrganisers.subscribe((dataArr) => {
            if(dataArr?.length){
                this.allOrganisers = dataArr;
                console.log("_allOrganisers", dataArr);
            }else{
                this.allOrganisers = [];
            }
        })
        this.subscriptions.push(subscriptionOrg);


        const subscriptionVenues =  this._addeditcommondataproviderservice._allVenues.subscribe((dataArr) => { 
            if(dataArr?.length){
                this.allVenues = dataArr;
                console.log("_allVenues", dataArr);
            }else{
                this.allVenues = [];
            }
        })
        this.subscriptions.push(subscriptionVenues);


        const subscriptionSpeaker = this._addeditcommondataproviderservice._speakersList.subscribe((dataArr) => {
            if(dataArr?.length){
                this.allSpeakersList = dataArr;
                console.log("_allSpeakersList", dataArr);
            }else{
                this.allSpeakersList = [];
            }
        })
        this.subscriptions.push(subscriptionSpeaker);


        const subscriptionSponsorsGroup = this._addeditcommondataproviderservice._allSponsorsGroup.subscribe((dataArr) => {
            if(dataArr?.length){
                this.allSponsorsGroupList = dataArr;
            }else{
                this.allSponsorsGroupList = [];
            }
        })
        this.subscriptions.push(subscriptionSponsorsGroup);


        const subscriptionHostGroup = this._addeditcommondataproviderservice._allHostGroupList.subscribe((dataArr) => {
            if(dataArr?.length){
                this.allHostGroupList = dataArr;
            }else{
                this.allHostGroupList = [];
            }
        })
        this.subscriptions.push(subscriptionHostGroup);


        const subscriptionFAQs = this._addeditcommondataproviderservice._faqListData.subscribe((dataArr) => {
            if(dataArr?.length){
                this.faqListData = dataArr;
            }else{
                this.faqListData = [];
            }
        })
        this.subscriptions.push(subscriptionFAQs);


        const subscriptionResourseList = this._addeditcommondataproviderservice._resourseList.subscribe((dataArr) => {
            if(dataArr?.length){
                this.resourseList = dataArr;
            }else{
                this.resourseList = [];
            }
        })
        this.subscriptions.push(subscriptionResourseList);

        
        const subscriptionTaxes = this._addeditcommondataproviderservice._TaxListData.subscribe((dataArr) => {
            if(dataArr?.length){
                this.taxList = dataArr;
                console.log("_this.taxList", this.taxList);
                
            }else{
                this.taxList = [];
            }
        })
        this.subscriptions.push(subscriptionTaxes);


    }
    

    editEvent() {

        this.isAddCase = false;
        this.isOptional = true;
 

        // this._activatedRoute.queryParams.pipe(take(1)).subscribe((res) => {
        //     if (res?.step) {
        //         console.log("res?.step", res?.step);
        //         if (Number(res.step) < 6 || Number(res.step) > 0) {
        //             this.changeStepperEnumsSetting(res?.step);
        //             this.selectedIndex = this.EVENT_STEPS.filter((step) => step.id == res?.step)[0];
        //         } else {
        //             this._router.navigate([], {
        //                 queryParams: {
        //                     step: 0
        //                 },
        //                 relativeTo: this._activatedRoute
        //             })
        //         }
        //     } else {
        //         this._router.navigate([], {
        //             queryParams: {
        //                 step: 0
        //             },
        //             relativeTo: this._activatedRoute
        //         })
        //     }
        // })

        this.isIncompleteEvent = this.eventData.eventStatus == 7 ? true : false;

        this.setToolBarTitle(true);

        if (this._addeditcommondataproviderservice?.eventData?.performerSchedule?.length)
            this.performerSchedule = this._addeditcommondataproviderservice.eventData?.performerSchedule;


        // set value in form for edit. 
        console.log("createStep", this.eventData.createStep);

        // step 1
        if (1 <= this.eventData.createStep) {
            this.setEventInForm('eventInfo', this.eventData);
            console.log("eventInfo");
        } else {
            // this.handleValidationAndDataStep(2,3,4,5,6)
        }


        // step 2
        if (2 <= this.eventData.createStep) {
            this.setEventInForm('eventSchedule', this.eventData);

            // check is event ticket generated and not. if generated then disable all field in edit case for step 2 only. 
            // if (this._addeditcommondataproviderservice?.eventData?.isTicketGenerated) {
            //     this.eventSchedule.disable();
            //     this.eventScheduleDisable = true;
            // }
            
            // this.eventSchedule.get('timeZone').setValue(())

            if (this._addeditcommondataproviderservice?.eventData?.isOrderPlace) {
                this.eventSchedule.disable();
                this.eventScheduleDisable = true;
            }
            
            console.log("eventSchedule", this.selectedSchedule);

        } else {
            this.handleValidationAndDataStep(2, null, null, null, null);
        }

        // step 3
        if (3 <= this.eventData.createStep) {
            this.setEventInForm('ticketSale', this.eventData);
            console.log("ticketSale");
        } else {
            this.handleValidationAndDataStep(null, 3, null, null, null);
        }

        // step 4
        if (4 <= this.eventData.createStep) {
            this.setEventInForm('venueActivity', this.eventData);
            // check if activies available then set activies into list.
            if (!this.performerSchedule?.length) {
                if (this._addeditcommondataproviderservice?.eventData?.performerSchedule?.length) {
                    this.performerSchedule = this._addeditcommondataproviderservice.eventData.performerSchedule;
                    this.performerSchedule.forEach((item) => {
                        if (item.activitesList.length)
                            item.activitesList.forEach((item) => {
                                item['startTimeFormat'] = new Date(Number(item.startTime)).getHours() + ':' + new Date(Number(item.startTime)).getMinutes();
                                item['endTimeFormat'] = new Date(Number(item.endTime)).getHours() + ':' + new Date(Number(item.endTime)).getMinutes();
                            })
                    })
                    // performer data sorting according to dates. 
                    this.performerSchedule = this.sorting(this.performerSchedule);
                    console.log("this.performerSchedule in edit case", this.performerSchedule);
                    // first date selected in event edit case. 
                    this.selectedDateForActivities = this._addeditcommondataproviderservice.eventData.performerSchedule[0].slotDate;
                }
            }
            console.log("venueActivity");

        } else {
            // this.handleValidationAndDataStep(null, null, null, 5, 6);
        }


        // step 5
        if (5 <= this.eventData.createStep) {
            this.setEventInForm('eventDetails', this.eventData);
            this.ticketData = this._addeditcommondataproviderservice?.ticketData;
            if (this.eventData.isTicketGenerated) {
                this._addeditcommondataproviderservice.getTicketByid({ "eventId": this.eventData.id }).then((res: any) => {
                    if (res.status == 200) {
                        this.ticketData = res.data.EventListing;
                    }
                })
            }
            console.log("eventDetails");

        } else {
            // this.handleValidationAndDataStep(null, null, null, null, 6);
        }

        // step 6
        if (6 <= this.eventData.createStep) {
            this.setEventInForm('readyToPublish', this.eventData);
            console.log("readyToPublish",  this.eventData);

        }

    }

    handleValidationAndDataStep(eventSchedule?, ticketSale?, venueActivity?, eventDetails?, readyToPublish?) {
        if (eventSchedule) {
            console.log("handleValidationAndDataStep");
            // handle validation.
            this.eventScheduleformValidation('oneDayEvent');

            this.eventSchedule.get("eventDate").valueChanges.subscribe((value) => {
                if (value) this.slotData[0] = value;
            });

            this.eventSchedule.get('recurringDate').valueChanges.subscribe((value) => {
                if (value) {
                    this.addDayAndDateInWhenRecurringField(value);
                    if (this.eventSchedule.get('whenRecurring').value && value) {
                        this.preventWeekDayAccordingToSelectedDate();
                    }
                }
            })
            this.eventSchedule.get('whenRecurring').valueChanges.subscribe((value) => {
                if (value == 1) {
                    this.preventWeekDayAccordingToSelectedDate();
                }
            })
        }

        console.log("ticketSale======>>>", ticketSale);

        if (ticketSale) {
            this.handleSaleStartFormValidation();
            this.handleSaleEndFormValidation();
        }
    }

    convertTimestampToTimezone(timestamp) {
        console.log("timestamp", timestamp);
        const localTimeZoneOffsetInMin = new Date().getTimezoneOffset();
        const timeZone = this.timeZones.filter((item) => item.id == this.eventSchedule.get('timeZone').value)[0]
        console.log("timeZone====>>", timeZone);
        
        if (timeZone) {
           
            const removeNagativeSimbleFormLocalOffsetMin = String(localTimeZoneOffsetInMin)[0] === '-' ? Number(String(localTimeZoneOffsetInMin).slice(1, String(localTimeZoneOffsetInMin).length)) : Number(localTimeZoneOffsetInMin)
            console.log("removeNagativeSimbleFormLocalOffsetMin", removeNagativeSimbleFormLocalOffsetMin);

            const zoneDef = Number(removeNagativeSimbleFormLocalOffsetMin) - Number(timeZone.offset * 60)

            console.log("selectedTimezoneOffsetInMin", zoneDef);

            if (String(zoneDef)[0] === '-') {
                console.log("new Date(timestamp).setMinutes(zoneDef)", new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() + zoneDef));
                return new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() + zoneDef);
            } else {
                console.log("new Date(timestamp).setMinutes(zoneDef)", new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() + zoneDef));
                return new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() + zoneDef);
            }

        } else {
            return timestamp;
        }
    }

    setToolBarTitle(isEdit) {
        if (isEdit) {
            // for title
            this._ToolbarService.routerURL = `events/view/${this.eventData?.id}/info`;
            this._ToolbarService._ToolBarTitle.next('Edit Event');
            // for btn
            this._ToolbarService._isBackBtnShow.next(true);
        } else {
            // for title
            this._ToolbarService._ToolBarTitle.next('Create An Event');
            // for btn
            this._ToolbarService._isBackBtnShow.next(false);
        }
    }

    addAdditionalTimeInCurrent(inputDate) {
        const updatedDate = new Date(inputDate);
        updatedDate.setMinutes(updatedDate.getMinutes() + 10);
        return updatedDate;
    }

    // getAllSponsorGroup() {
    //     this.allSponsorsGroupList = this._addEditEventService.allSponsorsGroup;
    // }

    createForm(isFor) {
        if (isFor == "eventInfo") {
            return this._fb.group({
                eventName: ["", Validators.required],
                eventType: ["", Validators.required],
                category: ["", Validators.required],
                eventPrice: ['', Validators.required],
                website: ["https://"],
                guest: [""],
                organiser: [""]
            });
        }
        else if (isFor == "eventSchedule") {
            return this._fb.group({
                timeZone: [],
                eventDate: ["", Validators.required],
                oneDaySlot: this._fb.array([
                    this._fb.group({
                        startTime: ["", Validators.required],
                        endTime: ["", Validators.required]
                    }),
                ]),
                recurringDate: ["", Validators.required],
                recurringTimeSlots: this._fb.array([
                    // this._fb.group({
                    //     startTime: ["", Validators.required],
                    //     endTime: ["", Validators.required]
                    // }),
                ]),

                // 2
                picker: this._fb.group({
                    eventStartDate: ["", Validators.required],
                    eventEndDate: ["", Validators.required],
                }),

                commonEventSlot: this._fb.array([
                    this._fb.group({
                        startTime: ["", Validators.required],
                        endTime: ["", Validators.required]
                    }),
                ]),

                whenRecurring: ['', Validators.required],
                recurringAdvanceBooking: [''],
                recurringEnd: ['', Validators.required],
                repeatNumberOfDays: ['', Validators.required],
                repeatNumberDate: ['', Validators.required],
            });
        }
        else if (isFor == "ticketSale") {
            return this._fb.group({
                tSaleStartDate: ['', Validators.required],
                tSaleEndDate: ['', Validators.required],
                isTicketShareable : [''],
                shareSecured : ['']
            })
        }
        else if (isFor == "venueActivity") {
            return this._fb.group({
                eventVenue: ['', Validators.required],
                joinUrl: [''],
                capacity: ['', Validators.required],
                noSeatEvent: [''],
                allowMutlipleScan : [''],
                isOnline: ['0', Validators.required],
                scanningTime: ['', Validators.required],
                sponsorsGroup: [''],
                sponsorsTitle: [''],
                // speakersId : [''],
                hostGroup: [''],
                speakersTitle: ['']
            })
        }
        else if (isFor == "eventDetails") {
            return this._fb.group({
                thumbNail: ['', Validators.required],
                banner: ['', Validators.required],
                eventDesc: ['', Validators.required],
                eventTnC: [''],
                faq: [''],
                resourseList: ['']
            })
        }
        else if (isFor == "readyToPublish") {
            return this._fb.group({
                eventListingType: ['', Validators.required],
                taxApplicable: [''],
                isDomeApplicable: ["1", Validators.required],
                allowMutliBooking : ["0", Validators.required],
                taxList: [''],
                convenienceFee: ['2'],
                bookingType : [''],
                externalBookingUrl : [],
                taxCharges: ['']
            })
        }
    }

    // set data in edit case.
    setEventInForm(isFor, data) {
        console.log("This is event details ==-->", data);
        if (isFor == 'eventInfo') {
            this.eventInfo.get('eventName').setValue(data.name);
            this.eventInfo.get('eventType').setValue(data.eventType);
            this.eventInfo.get('category').setValue(data?.eventCategory.split(",") || '');
            this.eventInfo.get('eventPrice').setValue(data?.isFree == 0 ? '1' : '2');
            this.eventInfo.get('website').setValue(data?.siteUrl || "https://");
            this.eventInfo.get('guest').setValue(data?.guestId?.length ? data?.guestId.split(",") : "");
            this.eventInfo.get('organiser').setValue(data?.eventOrganiser ? String(data.eventOrganiser) : "");
        }
        else if (isFor == 'eventSchedule') {
            this.selectedSchedule = data.scheduleType;
           
            

            if (data.scheduleType == 1) {
                // this.eventSchedule.get('eventDate').setValue(new Date(data.eventSchedule[0].eventStartDate))
                this.eventSchedule.get('eventDate').setValue(new Date(data.eventSchedule[0]?.eventStartDate))
                console.log("data.eventSchedule[0].eventStartDate", data.eventSchedule[0]?.eventStartDate);
                this.slotData[0] = data.eventSchedule[0]?.eventStartDate;
                this.eventScheduleformValidation('oneDayEvent');
                (<UntypedFormArray>this.eventSchedule.get("oneDaySlot")).removeAt(0);
                data.eventSchedule.forEach((slot) => {
                    slot.timeSlot.forEach((time) => {
                        this.onAddTimeRow(time);
                    })
                })
            }
            else if (data.scheduleType == 2) {
                console.log("data?.eventStartDate", data?.eventStartDate);
                this.eventSchedule.controls.picker.get('eventStartDate').setValue(moment(new Date(data?.eventStartDate)).toDate());
                this.eventSchedule.controls.picker.get('eventEndDate').setValue(moment(new Date(data?.eventEndDate)).toDate());
                this.eventScheduleformValidation('commonEvent');
                (<UntypedFormArray>this.eventSchedule.get("commonEventSlot")).removeAt(0);
                data.eventSchedule.forEach((slot) => {
                    slot.timeSlot.forEach((time) => {
                        this.onAddTimeRowForCommonEvent(time);
                    })
                })
            }
            else if (data.scheduleType == 3) {
                this.allSchedule = data.eventSchedule;
                this.eventScheduleformValidation('multipleDaysEvent');
            }
            else if (data.scheduleType == 4) {
                this.eventScheduleformValidation('recurringEvent')
                console.log("data.scheduleType ===>", data);

                // date
                this.eventSchedule.get('recurringDate').setValue(new Date(data?.eventStartDate));
                this.addDayAndDateInWhenRecurringField(data?.eventStartDate);

                // when rec.
                this.eventSchedule.get('whenRecurring').setValue(String(data?.recRepeatType));

                // recurringEnd
                this.eventSchedule.get('recurringEnd').setValue(data?.recEndDateType == 1 ? '1' : '2');
                this.recurringEndType = data?.recEndDateType == 1 ? '1' : '2';

                this.eventSchedule.get('recurringAdvanceBooking').setValue(data.recAdvanceSchedule)


                if(this.recurringEndType == '1'){
                    this.eventSchedule.get('repeatNumberOfDays').setValue(data.recRepeatCount);
                    this.changeRecurringEndType(1);
                }

                if(this.recurringEndType == '2'){
                    this.eventSchedule.get('repeatNumberDate').setValue(new Date(data.recurringEndDate));
                    this.changeRecurringEndType(2);
                }

                data.eventSchedule[0].timeSlot.forEach((time) => {
                    this.onAddRecurringTimeSlotsTimeRow(time);
                })

                
            }
        }
        else if (isFor == 'ticketSale') {
            this.eventSaleStartDataType = 1;
            this.handleSaleStartFormValidation();
            this.ticketSale.get('tSaleStartDate').setValue(new Date(data?.saleStartDate));

            // console.log("data?.isTicketShareable", this.eventData);
            // console.log("data?.isTicketShareable", Boolean(this.eventData.isTicketShareable));
            // console.log("data?.isTicketShareable", this.eventData['isTicketShareable']);
            // console.log("data?.isTicketShareable", data.isTicketShareable);
            // console.log("data?.isTicketShareable", Boolean(data.isTicketShareable));
            // console.log("data?.isTicketShareable", data['isTicketShareable']);

            this.ticketSale.get('isTicketShareable').setValue(Boolean(data.isTicketShareable));
            this.ticketSale.get('shareSecured').setValue(String(data.shareSecured));

            if (data.scheduleType != 3) {
                this.ticketSale.get('tSaleStartDate').setValue(new Date(data?.saleStartDate));
                this.ticketSale.get('tSaleStartDate').clearValidators();
                this.ticketSale.get('tSaleStartDate').setErrors({});
                this.ticketSale.get('tSaleStartDate').updateValueAndValidity();
            }
            // disable Immediately option in edit case, for both field.
            // this.EVENT_TICKET_SALE_START_DATE.map(item => item.id == 1 ? item.isdisabled = true: item.isdisabled = false);

            // end date
            this.eventSaleEndDataType = 3;
            this.handleSaleEndFormValidation();
            if (data.scheduleType != 4) {
                this.ticketSale.get('tSaleEndDate').setValue(moment(new Date(this.eventData?.eventStartDate)).toDate());
                this.ticketSale.get('tSaleEndDate').clearValidators();
                this.ticketSale.get('tSaleEndDate').setErrors({});
                this.ticketSale.get('tSaleEndDate').updateValueAndValidity();
            }
     
            // disable Immediately option in edit case, for both field.
            // this.EVENT_TICKET_SALE_END_DATE.map(item => item.id != 3 ? item.isdisabled = true: item.isdisabled = false);

            // console.log("after set form value for ticketSale", this.ticketSale);

        }
        else if (isFor == 'venueActivity') {
            this.venueActivity.get('isOnline').setValue(String(data?.isOnline));
            this.venueActivity.get('eventVenue').setValue(!data?.isOnline ? String(data?.venueId) : '')
            this.venueActivity.get('joinUrl').setValue(data?.isOnline ? data?.eventURL : '')
            this.venueActivity.get('capacity').setValue(data?.totalSeats);
            this.venueActivity.get('noSeatEvent').setValue(data?.noSeatEvent == 1 ? false : true);
            this.venueActivity.get('allowMutlipleScan').setValue(data?.allowMutlipleScan == 1 ? false : true);
            // this.venueActivity.get('sponsors').setValue(data?.sponsors?.length ? data?.sponsors.split(",") : [])
            this.venueActivity.get('scanningTime').setValue(data?.scanningTime?.toString())
            this.venueActivity.get('sponsorsTitle').setValue(data?.sponsorsTitle?.toString())
            const sponsors: any = [];
            if (data?.sponsorGroup?.length) {
                data.sponsorGroup.forEach((item) => sponsors.push(String(item.sponsorGroupId)))
                this.selectedSponsorsGroup = data.sponsorGroup;
                this.selectedSponsorsGroup.forEach((item) => {
                    item['rank'] = item.ranking || 0
                })
            }
            this.venueActivity.get('sponsorsGroup').setValue(data?.sponsorGroup?.length ? sponsors : [])

            this.venueActivity.get('speakersTitle').setValue(data?.speakersTitle?.toString())
            const hostGroupsIds: any = [];
            if (data?.speakerGroup?.length) {
                data.speakerGroup.forEach((item) => hostGroupsIds.push(String(item.hostGroupId)))
                this.selectedHostGroup = data.speakerGroup;
                this.selectedHostGroup.forEach((item) => {
                    item['rank'] = item.ranking || 0
                })
            }
            this.venueActivity.get('hostGroup').setValue(data?.speakerGroup?.length ? hostGroupsIds : [])

        }
        else if (isFor == 'eventDetails') {
            const faqLists = data?.faqList?.length ? data?.faqList.map((item) => String(item.faqId)) : '';
            const resourseList = data?.resourseList?.length ? data?.resourseList.map((item) => String(item.resourseId)) : '';
            console.log("data===========================>", data);
            this.croppedThumbBase64 = data?.thumbNailURL;
            this.eventDetails.get('thumbNail').setValue(data?.thumbNailURL);
            this.croppedBannerBase64 = data?.bannerUrl;
            this.eventDetails.get('banner').setValue(data?.bannerUrl);
            this.eventDetails.get('eventDesc').setValue(data?.description);
            this.eventDetails.get('eventTnC').setValue(data?.terms);
            this.eventDetails.get('faq').setValue(faqLists);
            this.eventDetails.get('resourseList').setValue(resourseList);
        }
        else if (isFor == 'readyToPublish') {
            console.log("readyToPublish called", data);
            this.readyToPublish.get('eventListingType').setValue(data?.isEventPublic == 1 ? '1' : '0');
            this.readyToPublish.get('taxCharges').setValue(data?.taxInclude == 1 ? '1' : '0')
            this.readyToPublish.get('allowMutliBooking').setValue(String(data?.allowMutliBooking))
            const taxesIds = [];
            data.taxList.forEach((item) => taxesIds.push(String(item.taxId)));
            console.log("taxesIds", taxesIds);
            this.readyToPublish.get('taxList').setValue(taxesIds);
            this.readyToPublish.get('externalBookingUrl').setValue(data?.externalBookingUrl || "");
            this.readyToPublish.get('convenienceFee').setValue(data.convenienceFee);
            this.readyToPublish.get('bookingType').setValue(String(data.bookingType));
            this.readyToPublish.get('taxApplicable').setValue(data.taxApplicable == 1 ? true : false)
            console.log("find tax", data);
            
            // this.readyToPublish.get('taxList')
            this.custormerBookingInfo['data'] = data?.customerFieldList;
            this.custormerBookingInfo['type'] = data?.customerInfoType;
            console.log("after set form value for readyToPublish", this.readyToPublish);
        }
    }

    removeHTTPS() {
        this.eventInfo.controls.website.valueChanges.subscribe((res) => {
            if (res)
                if (String(res).startsWith('https://https://')) {
                    let trimStr = String(this.eventInfo.controls.website.value).slice(8, String(this.eventInfo.controls.website.value)?.length);
                    this.eventInfo.controls.website.setValue(trimStr);
                }
        })

        // this.readyToPublish.controls.externalBookingUrl.valueChanges.subscribe((res) => {
        //     if (res)
        //         if (String(res).startsWith('https://https://')) {
        //             let trimStr = String(this.readyToPublish.controls.externalBookingUrl.value).slice(8, String(this.readyToPublish.controls.externalBookingUrl.value)?.length);
        //             this.readyToPublish.controls.externalBookingUrl.setValue(trimStr);
        //         }
        // })
    }

    /* MAKE FORM CONTROLS */
    get oneDaySlot(): UntypedFormArray {
        return this.eventSchedule.controls["oneDaySlot"] as UntypedFormArray;
    }

    get commonEventSlot(): UntypedFormArray {
        return this.eventSchedule.controls["commonEventSlot"] as UntypedFormArray;
    }

    /* MAKE FORM CONTROLS */
    get recurringTimeSlots(): any {
        return this.eventSchedule.controls["recurringTimeSlots"] as UntypedFormArray;
    }


    onAddTimeRow(time?) {
        console.log("timeonAddTimeRow", time);        
        /* ONCLICK TO PUSH TIME SLOT INTO TIMESLOT IN EDIT/REUSE CASE*/
        (<UntypedFormArray>this.eventSchedule.get("oneDaySlot")).push(
            this._fb.group({
                startTime: [time ? (new Date(time.startTime).getHours() + ':' + new Date(time.startTime).getMinutes()) : "", Validators.required],
                endTime: [time ? (new Date(time.endTime).getHours() + ':' + new Date(time.startTime).getMinutes()) : "", Validators.required],
            })
        );
    }



    onAddRecurringTimeSlotsTimeRow(time?) {
        /* console.log("time here", time); */
        /* ONCLICK TO PUSH TIME SLOT INTO TIMESLOT IN EDIT/REUSE CASE*/
        (<UntypedFormArray>this.eventSchedule.get("recurringTimeSlots")).push(
            this._fb.group({
                startTime: [time ? (new Date(time.startTime).getHours() + ':' + new Date(time.startTime).getMinutes()) : "", Validators.required],
                endTime: [time ? (new Date(time.endTime).getHours() + ':' + new Date(time.endTime).getMinutes()) : "", Validators.required],
            })
        );
    }

    /* REMOVE TIME SLOTE ROW */
    deleteTimeRow(i) {
        if (this.oneDaySlot.controls?.length) {
            if (this.oneDaySlot.controls?.length == 1) {
                this.oneDaySlot.controls[0].get('startTime').setValue(null)
                this.oneDaySlot.controls[0].get('endTime').setValue(null)
            } else {
                (<UntypedFormArray>this.eventSchedule.get("oneDaySlot")).removeAt(i);
            }
        }
    }

    /* REMOVE TIME SLOTE ROW */
    deleteRecurringTimeSlotsTimeRow(i) {
        if (this.recurringTimeSlots?.controls?.length) {
            if (this.recurringTimeSlots?.controls?.length == 1) {
                this.recurringTimeSlots.controls[0].get('startTime').setValue(null)
                this.recurringTimeSlots.controls[0].get('endTime').setValue(null)
            } else {
                (<UntypedFormArray>this.eventSchedule.get("recurringTimeSlots")).removeAt(i);
            }
        }
    }

    // common Event
    onAddTimeRowForCommonEvent(time?) {
        console.log("this is the time",);
        /* ONCLICK TO PUSH TIME SLOT INTO TIMESLOT IN EDIT/REUSE CASE*/
        (<UntypedFormArray>this.eventSchedule.get("commonEventSlot")).push(
            this._fb.group({
                startTime: [time ? (new Date(time.startTime).getHours() + ':' + new Date(time.startTime).getMinutes()) : "", Validators.required],
                endTime: [time ? (new Date(time.endTime).getHours() + ':' + new Date(time.endTime).getMinutes()) : "", Validators.required]
            })
        );
    }

    /* REMOVE TIME SLOTE ROW */
    deleteTimeRowForCommonEvent(i) {
        if (this.commonEventSlot.controls?.length) {
            if (this.commonEventSlot.controls?.length == 1) {
                this.commonEventSlot.controls[0].get('startTime').setValue(null)
                this.commonEventSlot.controls[0].get('endTime').setValue(null)
            } else {
                (<UntypedFormArray>this.eventSchedule.get("commonEventSlot")).removeAt(i);
            }
        }
    }

    removeEventCategory(index) {
        this.eventInfo.value.category.splice(index, 1);
        this.eventInfo.get("category").setValue(this.eventInfo.value.category);
    }

    getEventCategoryImage(id) {
        const arr = this._addeditcommondataproviderservice?.eventCategory.filter(item => item?.id == id);
        return arr?.length > 0 ? arr[0].icon : "";
    }

    getEventCategoryTitle(id) {
        const arr = this._addeditcommondataproviderservice.eventCategory.filter(item => item.id == id);
        return arr?.length > 0 ? arr[0].title : "";
    }

    getGuestName(id) {
        const arr = this.allGuest.filter(item => item.id == id);
        return arr?.length > 0 ? arr[0].name : "";
    }

    getGuestIcon(id) {
        const arr = this.allGuest.filter(item => item.id == id);
        return arr?.length > 0 ? arr[0]?.image ? arr[0]?.image : "assets/icons/dist-profile-blank.png" : "";
    }

    removeGuest(index) {
        this.eventInfo.value.guest.splice(index, 1);
        this.eventInfo.get("guest").patchValue(this.eventInfo.value.guest);
        this.eventInfo.updateValueAndValidity();
    }

    addEventGuest() {
        if (this.eventInfo.status != "DISABLED") {
            const dialog = this._matDialog.open(AddGuestDialogComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                width: '500px',
                autoFocus: false,
                data: {},
            })
            dialog.afterClosed().subscribe((response) => {
                if (response) {
                    this._addeditcommondataproviderservice.getAllGuest();
                    // this._guestListService.getAllGuest({ pageIndex: "0", pageSize: "0" }).then((result: any) => {
                    //     this._addEditEventService.allGuest = result.data.data;
                    // });
                }
            });
        }
    }

    addNewFaqList() {
        const dialog = this._matDialog.open(AddEditFaqDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: '420px',
            autoFocus: false,
            data: {
                isAddCase: true
            }
        })

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this._addeditcommondataproviderservice.getAllFAQs();
            }
        })
    }

    getTaxName(id){
        const selectedItem:any = this.taxList.filter((item:any) => String(item.id) === id); 
        return selectedItem?.length ? (selectedItem[0].title) : '';
    }

    removeTax(index) {
        this.readyToPublish.value.taxList.splice(index, 1);
        this.readyToPublish.get("taxList").patchValue(this.readyToPublish.value.taxList);
        this.readyToPublish.updateValueAndValidity();
    }

    // addEventCategory() {
    //     if (this.eventInfo.status != "DISABLED") {
    //         const dialog = this._matDialog.open(AddEventCategoryDialogComponent, {
    //             panelClass: ['commonDialogWrapper'],
    //             backdropClass: 'dialogBackdrop',
    //             width: '600px',
    //             data: {
    //                 heading: "Add new event type",
    //             },
    //         })
    //         dialog.afterClosed().subscribe((response) => {
    //             if (response) {
    //                 this._addeditcommondataproviderservice.getEventCategories();
    //             }
    //         });
    //     }
    // }

    addOrganiser() {
        if (this.eventInfo.status != "DISABLED") {
            const dialog = this._matDialog.open(AddOrganiserDialogComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                autoFocus: false,
                width: '600px',
                data: {},
            });

            dialog.afterClosed().subscribe((response) => {
                if (response) {
                    this._addeditcommondataproviderservice.getAllOrg();
                }
            });
        }
    }

    /* 
        TO OPEN MULTIPLE DATE PICKER AND SELETE TIME SLOT FOR STEP 2 (EVENT SCHEDULE)
    */
    getTimeSlot() {
        const dialog = this._matDialog.open(MultipleDatepickerComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: "600px",
            disableClose: true,
            data: {
                edit: this.allSchedule?.length ? true : false,
                editCaseData: this.allSchedule?.length ? this.allSchedule : [],
            },
        })

        dialog.afterClosed().subscribe((allData: any) => {
            if (allData?.length > 0) {
                this.allSchedule = allData;
                this.handleScheduleFormValidation();
            }
        });

    }

 
    addVenue() {
        if (this.venueActivity.status != "DISABLED") {
            const dialog = this._matDialog.open(AddVenueComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                width: '1100px',
                autoFocus: false,
            });
            dialog.afterClosed().subscribe((dialogData: any) => {
                if (dialogData) {
                    this._addeditcommondataproviderservice.getAllVenues();
                }
            });
        }
    }


    addTax() {
        const dialog = this._matDialog.open(AddTaxDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: '400px'
        });
        dialog.afterClosed().subscribe((result) => {
            if (result?.length) {
                this._addeditcommondataproviderservice.getAllTaxes()
            }
        })
    }

    // banner and image upload module.
    //file drop
    // public dropped(files: NgxFileDropEntry[], dropperFor: "thumb" | "banner") {
    //     // this.files = files;
    //     for (const droppedFile of files) {
    //         // Is it a file?
    //         if (droppedFile.fileEntry.isFile) {
    //             const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    //             console.log("fileEntry", fileEntry);
    //             fileEntry.file((file: File) => {
    //                 // Here you can access the real file
    //                 if (dropperFor == "thumb") this.openCropperForThumbnail(file);
    //                 else if (dropperFor == "banner") this.openCropperForBanner(file);
    //             });
    //         } else {
    //             // It was a directory (empty directories are added, otherwise only files)
    //             const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    //         }
    //     }
    // }


    openCropperForThumbnail(event) {
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 250,
                    height: 250,
                    round: false,
                    type: 'image/png',
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 320,
                        height: 320
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height: 520,
            disableClose: true
        }).afterClosed.subscribe((result: any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event);
                this.croppedThumbBase64 = result.file.dataURL;
                this._cd.markForCheck();
                this.uploadThumbnailOnPresignedURL(result.file, result.blobFile)
            }
        });
    }

    openCropperForBanner(event) {
        console.log("openCropperForBanner", event);
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 700,
                    height: 300,
                    round: false,
                    type: 'image/png',
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 1280,
                        height: 550
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height: 520,
            disableClose: true
        }).afterClosed.subscribe((result: any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event);
                this.croppedBannerBase64 = result.file.dataURL;
                this._cd.markForCheck();
                this.uploadBannerOnPresignedURL(result.file, result.blobFile)
            }
        });
    }

    uploadBannerOnPresignedURL(fileObj, blobFile) {
        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName": 'banner_',
            "folderName": 'event/banner', // it is fix set by backend.
            'mimeType': fileObj?.type
        }
        this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
            if (result.status == 200) {
                this.eventDetails.get('banner').setValue(result.data.imageUploadUrl);
                // payload for get Image upload status
                const payload = {
                    'id': result.data.id,
                    'status': 1 // fix by backend. 
                }
                this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile);
                this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                    if (uploadStatusResponse.status == 200) {
                        this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    } else {
                        this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    }
                })
            }
        })
    }

    uploadThumbnailOnPresignedURL(fileObj, blobFile) {
        console.log("fileObj", fileObj);
        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName": 'thumbNail_',
            "folderName": 'event/thumbNail', // it is fix set by backend.
            'mimeType': fileObj?.type
        }

        this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
            if (result.status == 200) {
                this.eventDetails.get('thumbNail').setValue(result.data.imageUploadUrl);
                // payload for get Image upload status
                const payload = {
                    'id': result.data.id,
                    'status': 1 // fix by backend.
                }
                this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile);
                this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                    if (uploadStatusResponse.status == 200) {
                        this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    } else {
                        this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    }
                })
            }
        })
    }

    onRemoveThumb() {
        this.eventDetails.get("thumbNail").setValue("");
        this.croppedThumbBase64 = null;
    }

    onRemoveBanner() {
        this.eventDetails.get("banner").setValue("");
        this.croppedBannerBase64 = null;
    }

    // getTaxListTitle(id) {
    //     const arr = this._addEditEventService.taxesList.filter(
    //         (item) => item.id == id
    //     );
    //     return arr?.length > 0 ? arr[0].title : "";
    // }

    // openCustormerDailog
    openCustormerDailog(isRecall?) {
        this._matDialog.open(BuyerDetailsConfigurationComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: "450px",
            autoFocus: false,
            disableClose: true,
            data: {
                isForView: false,
                custormerInfo: this.custormerBookingInfo['data']?.length ? this.custormerBookingInfo : [],
            },
        }).afterClosed().subscribe((response) => {
            if (response) {
                console.log("after close response", response);
                this.custormerBookingInfo = response;
                if (isRecall) {
                    this.onSubmit('readyToPublish', false)
                }
            }
        });
    }






    /* 
        FOR GET TIMESTAMP FORMATE 
    */
    getTimeStamp(strTime, date?) {
        console.log("this is the setData", date);
        console.log("strTime", strTime);
        if (String(strTime).includes(":")) {
            // Input time in "4:23 AM" format
            const timeString = strTime;

            // Create a new Date object with the current date
            const currentDate = new Date();

            // Extract the hour and minute values from the time string
            const [time, period] = timeString.split(' ');
            const [hours, minutes] = time.split(':').map(Number);

            // Adjust the hour based on the period (AM/PM)
            let adjustedHours = hours;
            if (period === "PM" && hours !== 12) {
                adjustedHours += 12;
            } else if (period === "AM" && hours === 12) {
                adjustedHours = 0;
            }

            // Set the hour and minute values to the current date object
            currentDate.setHours(adjustedHours);
            currentDate.setMinutes(minutes);
            currentDate.setSeconds(0)

            // Set Date 
            if (date) {
                currentDate.setDate(new Date(date).getDate());
                currentDate.setMonth(new Date(date).getMonth());
                currentDate.setFullYear(new Date(date).getFullYear());
            }

            // Get the timestamp in milliseconds
            const timestamp = currentDate.getTime();

            return timestamp
        } else {
            return strTime;
        }

    }

    /* 
        FOR SET PARAM IN URL BY STEPS
    */
    // setStepIntoParam(step) {
    //     this._router.navigate([], {
    //         relativeTo: this._activatedRoute,
    //         queryParams: {
    //             currentStep: step
    //         }
    //     })
    // }

    /* 
        FOR SUBMIT (EVENT)STAP DATA INTO API. 
    */
    callSaveApi(data, goOnCreateTicket?) {
        this.showPrograssiveBar(true);
        this._addEditEventService.onSubmitEventData(data).then((result: any) => {
            if (result.status === 200) {
                if (data.createStep == 6) { 
                    if(goOnCreateTicket){
                        this._router.navigate(["/tickets/create"], { queryParams: { eventId: this.eventData?.id, to: 'addevent' } });
                    }else{
                        this._router.navigate(['/events/view/' + this.eventData?.id + '/info']);
                    }
                    this._matSnackBar.open(result.message, 'OKAY', { duration: 3000 })
                }
                else if (data.createStep == 2) {  
                    this.getEventData();
                    if (this.eventData.eventStartDate && this.eventData.eventEndDate) {
                        this.savedDatesInDefFormate['eventStartDate'] = new Date(this.eventData.eventStartDate);
                        this.savedDatesInDefFormate['eventEndDate'] = new Date(this.eventData.eventEndDate);
                        console.log("savedDatesInDefFormate", this.savedDatesInDefFormate);
                    } 
                    
                    console.log("event data", this.eventData);                    
                    

                    this.changeStepperEnumsSetting(data.createStep);
                    this.onNextStep();
                }
                else {
                    // this.eventData = result.data;
                    if(data.createStep == 1){
                        this.eventData = result.data;
                    }
                    
                    if (data.createStep == 4) {
                        this.updateStepsData();
                    }else{
                        this.getEventData();
                    }

                    this.changeStepperEnumsSetting(data.createStep);
                    this.onNextStep();
                }
                this._matSnackBar.open(result.message, "OKAY", { duration: 3000 })
            }
            // step already added cases.
            else if (result.status === 201) {
                this._matSnackBar.open(result.message, 'OKAY', { duration: 4000 }).afterDismissed().subscribe((res) => {
                    if (res) this.onNextStep();
                })
            } else {
                this._matSnackBar.open(result.message, 'OKAY', { duration: 4000 })
            }
            this.showPrograssiveBar(false);
        })
    }

    /* 
        FOR (SHOW/HIDE) PROGRASSIVE BAR 
    */
    showPrograssiveBar(status) {
        if (status) {
            this._fuseProgressBarService.show();
        } else {
            this._fuseProgressBarService.hide();
        }
    }

    /* 
        FOR PROCIDE TO NEXT STEP
    */
    onNextStep() {
        console.log("onNextStep called");
        console.log("this is the event info form. step 1", this.eventInfo);
        console.log("this is the event Schedule form. step 2", this.eventSchedule);
        console.log("this is the Ticket Sale form. step 2", this.ticketSale);
        console.log("this is the Venue Activity form. step 3", this.venueActivity);
        console.log("this is the eventDetails form. step 4", this.eventDetails);
        console.log("this is the readyToPublish form. step 5", this.readyToPublish);
        this.stepper.next();
    }

    /* 
        FOR CHANGE DONE STEP.
        * CHANGE STEPPER HEADER IMAGE,
        * ADD DONE MARK ON STEPPER HEADER,
    */
    changeStepperEnumsSetting(step) {
        if(!this.EVENT_STEPS?.length) return;
            this.EVENT_STEPS.map(item => {
                if ((item.id <= step)) {
                    item.isDone = true;
                    item.inProgress = false;
                } else {
                    if (item.id === step + 1) {
                        item.inProgress = true;
                    } else {
                        item.inProgress = false;
                    }
                }
            })
    }

    /* 
        GET PRE. STEP DATA IN CURRENT STEPS.
    */
    getEventSavedData(id) {
        this._addeditcommondataproviderservice.getEventDetail(id).then((savedResult: any) => {
            if (savedResult.status == 200) {
                this.eventData = savedResult.data;
                console.log("this is the eventData", this.eventData);
            } else {
                this.eventData = undefined;
            }
        })
    }

    /* 
        FOR HANDLE VALIDATIONS.
    */
    handleScheduleFormValidation() {
        if (this.selectedSchedule === 1) {
            this.eventScheduleformValidation('oneDayEvent');
            this._cd.detectChanges();
        }
        else if (this.selectedSchedule === 2) {
            this.eventScheduleformValidation('commonEvent');
            this._cd.detectChanges();
        }
        else if (this.selectedSchedule === 3) {
            if (!this.allSchedule?.length) {
                this.eventScheduleformValidation('multipleDaysEvent')
                this._cd.detectChanges();
            } else {
                this.eventSchedule.setErrors(null)
                this._cd.detectChanges();
            }
        }
        else if (this.selectedSchedule === 4) {
            this.eventScheduleformValidation('recurringEvent')
            this._cd.detectChanges();
        }

        if (!this.isAddCase) {
            if (this.selectedSchedule == 1) {
                // this.setEventInForm('eventSchedule', this._addEditEventService.eventData);
                // (<UntypedFormArray>this.eventSchedule.get("oneDaySlot")).removeAt(0);
            }
        }
    }

    eventScheduleformValidation(type) {
        if (type == 'oneDayEvent') {
            /* 
                remove error's
            */
            // this.eventSchedule.controls.recurringDate.setValue(null);
            this.eventSchedule.controls.recurringDate.setValidators(null);
            this.eventSchedule.controls.recurringDate.setErrors(null);

            // this.eventSchedule.controls.repeatNumberDate.setValue(null);
            this.eventSchedule.controls.repeatNumberDate.setValidators(null);
            this.eventSchedule.controls.repeatNumberDate.setErrors(null);

            // this.eventSchedule.controls.repeatNumberOfDays.setValue(null);
            this.eventSchedule.controls.repeatNumberOfDays.setValidators(null);
            this.eventSchedule.controls.repeatNumberOfDays.setErrors(null);

            // this.eventSchedule.controls.whenRecurring.setValue(null);
            this.eventSchedule.controls.whenRecurring.setValidators(null);
            this.eventSchedule.controls.whenRecurring.setErrors(null);

            // this.eventSchedule.controls.recurringEnd.setValue(null);
            this.eventSchedule.controls.recurringEnd.setValidators(null);
            this.eventSchedule.controls.recurringEnd.setErrors(null);

            this.recurringTimeSlots.controls.forEach((item) => {
                item.controls['startTime'].setValidators(null)
                // item.controls['startTime'].setValue(null)
                item.controls['startTime'].setErrors(null)
                item.controls['endTime'].setValidators(null)
                // item.controls['endTime'].setValue(null)
                item.controls['endTime'].setErrors(null)
            })
            // this.allSchedule = [];
            // this.slotData = [];

            /* 
                add error
            */
            this.eventSchedule.controls.eventDate.setValidators(Validators.required);
            this.oneDaySlot.controls.forEach((item) => {
                item['controls'].startTime.setValidators(Validators.required);
                item['controls'].endTime.setValidators(Validators.required);
            })

            // picker 
            this.eventSchedule.controls.picker.get('eventStartDate').setValidators(null)
            this.eventSchedule.controls.picker.get('eventEndDate').setValidators(null)

            if (this.commonEventSlot.controls?.length) {
                this.commonEventSlot.controls.forEach(item => {
                    item['controls'].startTime.clearValidators();
                    item['controls'].endTime.clearValidators();
                    item['controls'].startTime.updateValueAndValidity();
                    item['controls'].endTime.updateValueAndValidity();
                })
            }

        }
        else if (type == 'commonEvent') {
           
            console.log("this is your form", this.eventSchedule);
            this.eventSchedule.controls.eventDate.setValidators(null);
            this.eventSchedule.controls.eventDate.setErrors(null);

            this.eventSchedule.controls.recurringDate.setValidators(null);
            this.eventSchedule.controls.recurringDate.setErrors(null);

            this.eventSchedule.controls.whenRecurring.setValidators(null);
            this.eventSchedule.controls.whenRecurring.setErrors(null);

            this.eventSchedule.controls.recurringEnd.setValidators(null);
            this.eventSchedule.controls.recurringEnd.setErrors(null);

            this.eventSchedule.controls.repeatNumberOfDays.setValidators(null);
            this.eventSchedule.controls.repeatNumberOfDays.setErrors(null);

            this.eventSchedule.controls.repeatNumberDate.setValidators(null);
            this.eventSchedule.controls.repeatNumberDate.setErrors(null);

            if (this.oneDaySlot.controls?.length) {
                this.oneDaySlot.controls.forEach(item => {
                    // item['controls'].startTime.setValue(null);
                    item['controls'].startTime.setErrors(null);
                    item['controls'].startTime.setValidators(null);
                    // item['controls'].endTime.setValue(null);
                    item['controls'].endTime.setErrors(null);
                    item['controls'].endTime.setValidators(null);
                })
            }

            this.recurringTimeSlots.controls.forEach((item) => {
                item.controls['startTime'].setValidators(null)
                // item.controls['startTime'].setValue(null)
                item.controls['startTime'].setErrors(null)
                item.controls['endTime'].setValidators(null)
                // item.controls['endTime'].setValue(null)
                item.controls['endTime'].setErrors(null)
            })

            // picker 
            this.eventSchedule.controls.picker.get('eventStartDate').setValidators(Validators.required)
            this.eventSchedule.controls.picker.get('eventEndDate').setValidators(Validators.required)

            if (this.commonEventSlot.controls?.length) {
                this.commonEventSlot.controls.forEach(item => {
                    item['controls'].startTime.setValidators(Validators.required);
                    item['controls'].startTime.updateValueAndValidity();
                    item['controls'].endTime.setValidators(Validators.required);
                    item['controls'].endTime.updateValueAndValidity();
                })
            }

        }
        else if (type == 'multipleDaysEvent') {

            // this.eventSchedule.controls.eventDate.setValue(null);
            this.eventSchedule.controls.eventDate.setValidators(null);
            this.eventSchedule.controls.eventDate.setErrors(null);

            // this.eventSchedule.controls.recurringDate.setValue(null);
            this.eventSchedule.controls.recurringDate.setValidators(null);
            this.eventSchedule.controls.recurringDate.setErrors(null);

            // this.eventSchedule.controls.whenRecurring.setValue(null);
            this.eventSchedule.controls.whenRecurring.setValidators(null);
            this.eventSchedule.controls.whenRecurring.setErrors(null);

            // this.eventSchedule.controls.recurringEnd.setValue(null);
            this.eventSchedule.controls.recurringEnd.setValidators(null);
            this.eventSchedule.controls.recurringEnd.setErrors(null);

            // this.eventSchedule.controls.repeatNumberOfDays.setValue(null);
            this.eventSchedule.controls.repeatNumberOfDays.setValidators(null);
            this.eventSchedule.controls.repeatNumberOfDays.setErrors(null);

            // this.eventSchedule.controls.repeatNumberDate.setValue(null);
            this.eventSchedule.controls.repeatNumberDate.setValidators(null);
            this.eventSchedule.controls.repeatNumberDate.setErrors(null);

            if (this.oneDaySlot.controls?.length) {
                this.oneDaySlot.controls.forEach(item => {
                    // item['controls'].startTime.setValue(null);
                    item['controls'].startTime.setErrors(null);
                    item['controls'].startTime.setValidators(null);
                    // item['controls'].endTime.setValue(null);
                    item['controls'].endTime.setErrors(null);
                    item['controls'].endTime.setValidators(null);
                })
            }

            if (this.recurringTimeSlots.controls?.length) {
                this.recurringTimeSlots.controls.forEach((item) => {
                    item.controls['startTime'].setValidators(null)
                    // item.controls['startTime'].setValue(null)
                    item.controls['startTime'].setErrors(null)
                    item.controls['endTime'].setValidators(null)
                    // item.controls['endTime'].setValue(null)
                    item.controls['endTime'].setErrors(null)
                })
            }

            this.eventSchedule.setErrors({ dataNeeded: true })

            // picker 
            this.eventSchedule.controls.picker.get('eventStartDate').setValidators(null)
            this.eventSchedule.controls.picker.get('eventEndDate').setValidators(null)

            if (this.commonEventSlot.controls?.length) {
                this.commonEventSlot.controls.forEach(item => {
                    item['controls'].startTime.clearValidators();
                    item['controls'].endTime.clearValidators();
                    item['controls'].startTime.updateValueAndValidity();
                    item['controls'].endTime.updateValueAndValidity();
                })
            }

        } 
        else if (type == 'recurringEvent') {
            // this.allSchedule = []; 
            if(!this.eventSchedule.get('recurringDate').value){
                this.onAddRecurringTimeSlotsTimeRow();
            }
            // this.eventSchedule.controls.eventDate.setValue(null);
            this.eventSchedule.controls.eventDate.setValidators(null);
            this.eventSchedule.controls.eventDate.setErrors(null);

            // this.eventSchedule.controls.recurringDate.setValue('');
            this.eventSchedule.controls.recurringDate.setValidators(Validators.required);

            // this.eventSchedule.controls.whenRecurring.setValue('');
            this.eventSchedule.controls.whenRecurring.setValidators(Validators.required);

            // this.eventSchedule.controls.recurringEnd.setValue('');
            this.eventSchedule.controls.recurringEnd.setValidators(Validators.required);

            if (this.eventSchedule.controls.recurringEnd.value == 2) {
                this.eventSchedule.controls.repeatNumberOfDays.setValidators(null);
                // this.eventSchedule.controls.repeatNumberDate.setValue('');
                this.eventSchedule.controls.repeatNumberDate.setValidators(Validators.required);
            } else {
                this.eventSchedule.controls.repeatNumberOfDays.setValidators(Validators.required);
                // this.eventSchedule.controls.repeatNumberDate.setValue('');
                this.eventSchedule.controls.repeatNumberDate.setValidators(null);
            }

            // this.eventSchedule.controls.repeatNumberOfDays.setValue('');

            if (this.oneDaySlot.controls?.length) {
                this.oneDaySlot.controls.forEach(item => {
                    // item['controls'].startTime.setValue(null);
                    item['controls'].startTime.setErrors(null);
                    item['controls'].startTime.setValidators(null);
                    // item['controls'].endTime.setValue(null);
                    item['controls'].endTime.setErrors(null);
                    item['controls'].endTime.setValidators(null);
                })
            }

            this.recurringTimeSlots.controls.forEach((item) => {
                item.controls['startTime'].setValidators(Validators.required)
                // item.controls['startTime'].setValue('')
                item.controls['endTime'].setValidators(Validators.required)
                // item.controls['endTime'].setValue('')
            })

            this.eventSchedule.setErrors({ dataNeeded: true })

            // need to edit here...
            this.ticketSale.get('tSaleEndDate').setValue(null);
            this.ticketSale.get('tSaleEndDate').setValidators(null);
            this.ticketSale.get('tSaleEndDate').setErrors(null);

            // picker 
            this.eventSchedule.controls.picker.get('eventStartDate').setValidators(null)
            this.eventSchedule.controls.picker.get('eventEndDate').setValidators(null)

            if (this.commonEventSlot.controls?.length) {
                this.commonEventSlot.controls.forEach(item => {
                    item['controls'].startTime.clearValidators();
                    item['controls'].endTime.clearValidators();
                    item['controls'].startTime.updateValueAndValidity();
                    item['controls'].endTime.updateValueAndValidity();
                })
            }
        }
    }

    handleSaleStartFormValidation() {
        if (this.eventSaleStartDataType == 1) {
            this.ticketSale.controls.tSaleStartDate.setErrors(null);
            this.ticketSale.controls.tSaleStartDate.setValidators(null);
            this.ticketSale.controls.tSaleStartDate.setValue(this.currentDate);
            this.ticketSale.updateValueAndValidity();
        } else {
            this.ticketSale.controls.tSaleStartDate.setValidators(Validators.required);
            this.ticketSale.controls.tSaleStartDate.setValue(''); 
            this.ticketSale.updateValueAndValidity();
        }
        console.log("this.currentDate", this.currentDate);
        console.log("this.eventStartDate", this.eventStartDate);
    }

    handleSaleEndFormValidation() {
        if (this.eventSaleEndDataType == 1) {
            this.ticketSale.controls.tSaleEndDate.setValue(moment(new Date(this.eventData?.eventStartDate)).toDate());
            this.ticketSale.controls.tSaleEndDate.setErrors(null);
            this.ticketSale.controls.tSaleEndDate.setValidators(null);
            this.ticketSale.controls.tSaleEndDate.updateValueAndValidity();
        } else if (this.eventSaleEndDataType == 2) {
            this.ticketSale.controls.tSaleEndDate.setValue(moment(new Date(this.eventData?.eventEndDate)).toDate());
            this.ticketSale.controls.tSaleEndDate.setErrors(null);
            this.ticketSale.controls.tSaleEndDate.setValidators(null);
            this.ticketSale.controls.tSaleEndDate.updateValueAndValidity();
        } else {
            this.ticketSale.controls.tSaleEndDate.setValue('');
            this.ticketSale.controls.tSaleEndDate.setErrors(null);
            this.ticketSale.controls.tSaleEndDate.setValidators(Validators.required);
            this.ticketSale.controls.tSaleEndDate.updateValueAndValidity();
        }
    }


    /* 
        ON MAT SELECT CHANGE Activities Date
    */

    changeBtnStatus() {
        if (this.performerSchedule.length) {
            let currentItem = this.performerSchedule.filter((item) => item.slotDate == this.selectedDateForActivities)
            if (currentItem?.length) {
                if (currentItem[0].activitesList.length) {
                    return false;
                } else {
                    return true;
                }
            }
        }
    }

  
    // getAllHostGroupList() {
    //     this._HostGroupService.getAllHostGroup({ "pageIndex": 0, "pageSize": 0, 'search': '' }).then((result: any) => {
    //         if (result.status == 200) {
    //             this.allHostGroupList = result.data.getAllHostGroup;
    //             console.log("this.allHostGroupList", this.allHostGroupList);
    //         } else {
    //             this.allHostGroupList = [];
    //         }
    //     })
    // }

    /* 
        SET PERFORMER ACCORDING TO ID.
    */
    setPerformerById(ids) {
        console.log("ids", ids);
        let performer = []
        console.log("this.allSpeakersList", this.allSpeakersList);
        if (this.allSpeakersList?.length) {
            ids.forEach((id) => {
                performer.push(this.allSpeakersList.filter((item) => Number(item.id) === Number(id))[0]);
            })
        }
        console.log("this is the performer", performer);
        return performer;
    }

    /* 
        CHANGE RECURRING END TYPE
    */
    changeRecurringEndType(value) {
        // console.log("value", value);
        if (value == 1) {
            this.eventSchedule.controls.repeatNumberOfDays.setValidators(Validators.required);
            this.eventSchedule.controls.repeatNumberDate.setValidators(null);
            this.eventSchedule.controls.repeatNumberDate.setErrors(null);
            this.eventSchedule.controls.repeatNumberDate.updateValueAndValidity();
            // this._cd.detectChanges();
        } else {
            this.eventSchedule.controls.repeatNumberDate.setValidators(Validators.required);
            this.eventSchedule.controls.repeatNumberOfDays.setValidators(null);
            this.eventSchedule.controls.repeatNumberOfDays.setErrors(null);
            this.eventSchedule.controls.repeatNumberDate.updateValueAndValidity();
            // this._cd.detectChanges(); //repeatNumberDate
        }
        // console.log("this.eventSchedule", this.eventSchedule);
    }


    /* 
        Add days and dates in When Recurring dynamicly.
    */
    addDayAndDateInWhenRecurringField(value) {
        this.EVENT_RECURRING = JSON.parse(JSON.stringify(Enums.EVENT_RECURRING));
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        this.EVENT_RECURRING[1].types.map((item) => {
            item['type'] = item.type.replace('{day}', String(days[new Date(value).getDay()]));
        })

        this.EVENT_RECURRING[2].types.map((item) => {
            item.id == 7 ? item['type'] = item.type.replace('{date}', String(new Date(value).getDate())) : item['type'] = item.type.replace('{date}', String(new Date(value).getDate()) + ' ' + months[new Date(value).getMonth()]);
        })
        // this._cd.detectChanges();
    }

    addRequiredKeyInWEEKENDEnums() {
        this.WEEKDAYS.map((item) => {
            item['isDisable'] = 0;
            item['isSelected'] = 0;
        })
    }

    preventWeekDayAccordingToSelectedDate() {
        if (this.eventSchedule.controls.recurringDate.value) {
            // get week day according to selected date.
            const weekDay = new Date(this.eventSchedule.controls.recurringDate.value).getDay();
            for (let i = 0; i < this.WEEKDAYS?.length; i++) {
                if (i == weekDay) {
                    this.WEEKDAYS[i].isSelected = 1;
                    this.WEEKDAYS[i].isDisable = 0;
                }
                else if (i < weekDay) {
                    this.WEEKDAYS[i].isDisable = 0;
                    this.WEEKDAYS[i].isSelected = 0;
                }
                else {
                    this.WEEKDAYS[i].isSelected = 0;
                    this.WEEKDAYS[i].isDisable = 0;
                }
            }
        }
    }

    onSelectCustomWeekDay(day) {
        if (new Date(this.eventSchedule.controls.recurringDate.value).getDay() != day.id) {
            this.WEEKDAYS.map((item) => {
                if (item.id === day.id) {
                    if (!item['isSelected']) {
                        item['isSelected'] = 1;
                    } else {
                        item['isSelected'] = 0;
                    }
                }
            });
        }
    }

    deleteSlotFromMultipleDates(i, e, value) {
        console.log("deleteSlotFromMultipleDates", this.allSchedule);
        if (this.allSchedule[i].timeSlot?.length) {
            if (this.allSchedule[i].timeSlot?.length != 1)
                this.allSchedule[i].timeSlot.splice(e, 1);
            else
                this.dateCloseBtn(i)
        }

        if (this.allSchedule[i].timeSlot?.length == 1) {
            this.eventScheduleformValidation('multipleDaysEvent');
        }
    }

    dateCloseBtn(i) {
        this.allSchedule.splice(i, 1);
    }

    onPrint(){
        const timeResolved = new Intl.DateTimeFormat()
        console.log("timeResolved", timeResolved);  
        
        // const timeZone = this.timeZones.filter((item) => item.id == this.eventSchedule.get('timeZone').value)[0];
        // console.log("timeZone", timeZone);

            const timestamp = new Date().getTime();
        
            console.log("timestamp", timestamp);
            const localTimeZoneOffsetInMin = new Date().getTimezoneOffset();
            const timeZone = this.timeZones.filter((item) => item.id == this.eventSchedule.get('timeZone').value)[0]
            console.log("timeZone====>>", timeZone);
            
            if (timeZone) {
               
                const removeNagativeSimbleFormLocalOffsetMin = String(localTimeZoneOffsetInMin)[0] === '-' ? Number(String(localTimeZoneOffsetInMin).slice(1, String(localTimeZoneOffsetInMin).length)) : Number(localTimeZoneOffsetInMin)
                console.log("removeNagativeSimbleFormLocalOffsetMin", removeNagativeSimbleFormLocalOffsetMin);
    
                const zoneDef = Number(removeNagativeSimbleFormLocalOffsetMin) - Number(timeZone.offset * 60)
    
                console.log("selectedTimezoneOffsetInMin", zoneDef);
    
                console.log("converted time zone", new Date(timestamp).setMinutes(new Date(timestamp).getMinutes() + zoneDef));
                
            } else {
                return timestamp;
            }
        
    } 
  


    /* 
        MAIN FUNCTION FOR SUBMIT DATA
    */
    onSubmit(type, isPublish?, goOnCreateTicket?) {
        // CHECK FORM TYPE. AND PROCIDE. 
        // FOR STEP 1 (EVENT INFO)`
        if (type === 'eventInfo') {
            // IF FORM VALID THEN MOVE TO CREATE PAYLOAD.
            if (this.eventInfo.valid) {
                // CREATING PAYLOAD. 
                const payload = {}
                payload['createStep'] = 1,
                payload['name'] = this.eventInfo.controls.eventName.value;
                payload['eventType'] = this.eventInfo.controls.eventType?.value ? this.eventInfo.controls.eventType.value.toString() : '';
                payload['guestId'] = this.eventInfo.controls.guest?.value ? this.eventInfo.controls.guest.value.join(",") : '';
                payload['organiserId'] = this.eventInfo.controls.organiser?.value ? this.eventInfo.controls.organiser?.value.toString() : '';
                payload['eventWebsite'] = this.eventInfo.controls.website?.value == '' ? '' : this.eventInfo.controls.website?.value == 'https://' ? '' : this.eventInfo.controls.website?.value;
                payload['category'] = this.eventInfo.controls.category?.value ? this.eventInfo.controls.category?.value.join(",") : '';
                payload['isFree'] = this.eventInfo.controls.eventPrice?.value ? this.eventInfo.controls.eventPrice.value == 1 ? 0 : 1 : '';

                console.log("this is the payload", payload)
                // CALL API TO SAVE PAYLOAD DATA...
                // check update or add case according. 
                if (this.isAddCase) {
                    if (this.eventData?.createStep == 1) {
                        console.log("UPDATE CASE");
                        payload['eventId'] = this.eventData?.id;
                        this.updateEventSaveInApi(payload);
                    } else {
                        console.log("ADD CASE", payload);
                        this.callSaveApi(payload);
                    }
                } else {
                    console.log("this is  this.isAddCase = true", this.isAddCase);
                    if (!this.eventInfo.pristine) {
                        payload['eventId'] = this.eventData?.id;
                        this.updateEventSaveInApi(payload);
                    } else {
                        this.onNextStep();
                        this.changeStepperEnumsSetting(1);
                    }
                }
            }
        }
        // FOR STEP 2 (EVENT SCHEDULE)
        else if (type === 'eventSchedule') {
            this.performerSchedule = [];
            // IF FORM VALID THEN MOVE TO CREATE PAYLOAD.
            // FOR (ONE DAY EVENT, RECURRING EVENT)
            if (this.eventSchedule.valid) {
                // CHECK REDIO BUTTON VALUE TO FIND CHECKED TAB.
                if (this.selectedSchedule == 1) {
                    // CREATING PAYLOAD. 
                    const payload = {};
                    const schedule = [];
                    const timeSlot = [];
                    const scheduleObj = {};

                    payload['createStep'] = 2;
                    payload['eventId'] = this.eventData?.id;
                    payload["scheduleType"] = this.selectedSchedule,

                    payload['eventStartDate'] = this.eventSchedule.controls.eventDate?.value ? this.convertTimestampToTimezone(new Date(this.eventSchedule.controls.eventDate.value).getTime()) : '';
                    payload['eventEndDate'] = this.eventSchedule.controls.eventDate?.value ? this.convertTimestampToTimezone(new Date(this.eventSchedule.controls.eventDate.value).getTime()) : '';

                    payload['isMultiple'] = this.selectedSchedule;
                    scheduleObj['scheduleStartDate'] = this.eventSchedule.controls.eventDate?.value ? new Date(this.eventSchedule.controls.eventDate.value).getTime() : '';
                    scheduleObj['scheduleEndDate'] = this.eventSchedule.controls.eventDate?.value ? new Date(this.eventSchedule.controls.eventDate.value).getTime() : '';
                    

                    // FOR ADD TIMESLOT. 
                    this.oneDaySlot.value.forEach((element) => {
                        const temp = { startTime: this.getTimeStamp(element['startTime'], scheduleObj['scheduleStartDate']), endTime: this.getTimeStamp(element['endTime'], scheduleObj['scheduleStartDate']) }
                        // console.log("this timestamp before convert", temp);
                        temp['startTime'] = this.convertTimestampToTimezone(temp['startTime']);
                        temp['endTime'] = this.convertTimestampToTimezone(temp['endTime']);
                        console.log("this timestamp after convert", temp);
                        timeSlot.push(temp)
                        schedule[0] = scheduleObj;
                    });
                    schedule[0]['timeSlot'] = timeSlot;

                    let maxTime, minTime;
                    minTime = new Date(
                        Math.min(...schedule[0]['timeSlot'].map((element) => { return new Date(element.startTime) }))
                    );
                    maxTime = new Date(
                        Math.max(...schedule[0]['timeSlot'].map((element) => { return new Date(element.endTime) }))
                    );

                    payload['eventStartDate'] = new Date(payload['eventStartDate']).setTime(minTime);
                    payload['eventEndDate'] = new Date(payload['eventEndDate']).setTime(maxTime);


                    payload['schedule'] = schedule;
                    // this.performerSchedule = [
                    //     {
                    //         slotDate: payload['eventStartDate'],
                    //         activitesList: []
                    //     }
                    // ]
                    payload['performerSchedule'] = [];
                    const timeZone = this.timeZones.filter((item) => item.id == this.eventSchedule.get('timeZone').value)[0];
                    console.log("timeZone", timeZone);
                    payload['timezone'] = timeZone;

                    console.log("this.performerSchedule", this.performerSchedule);

                    // CALL API TO SAVE PAYLOAD DATA...
                    // check update or add case according. 
                    if (this.isAddCase) {
                        if (this.eventData?.createStep == 2) {
                            console.log("UPDATE CASE");
                            payload['eventId'] = this.eventData?.id;
                            this.updateEventSaveInApi(payload);
                        } else {
                            console.log("ADD CASE", payload);
                            this.callSaveApi(payload);
                        }
                    } else {
                        if (!this.eventSchedule.pristine) {
                            console.log("pristine case");
                            payload['eventId'] = this.eventData?.id;
                            this.updateEventSaveInApi(payload);
                        } else {
                            this.onNextStep();
                            this.changeStepperEnumsSetting(2);
                        }
                    }
                }
                else if (this.selectedSchedule == 2) {
                    // CREATING PAYLOAD. 
                    // CREATING PAYLOAD. 
                    const payload = {}
                    const schedule = [];
                    const timeSlot = [];
                    const scheduleObj = {};

                    payload['createStep'] = 2;
                    payload["scheduleType"] = this.selectedSchedule;
                    payload['eventId'] = this.eventData.id;
                    payload['eventStartDate'] = this.eventSchedule.controls.picker.get('eventStartDate')?.value ? this.convertTimestampToTimezone(new Date(this.eventSchedule.controls.picker.get('eventStartDate')?.value).getTime()) : '';
                    payload['eventEndDate'] = this.eventSchedule.controls.picker.get('eventEndDate')?.value ? this.convertTimestampToTimezone(new Date(this.eventSchedule.controls.picker.get('eventEndDate')?.value).getTime()) : '';
                    payload['isMultiple'] = this.selectedSchedule;
                    scheduleObj['scheduleStartDate'] = this.eventSchedule.controls.picker.get('eventStartDate')?.value ? new Date(this.eventSchedule.controls.picker.get('eventStartDate')?.value).getTime() : '';
                    scheduleObj['scheduleEndDate'] = this.eventSchedule.controls.picker.get('eventEndDate')?.value ? new Date(this.eventSchedule.controls.picker.get('eventEndDate')?.value).getTime() : '';

                    // FOR ADD TIMESLOT. 
                    this.commonEventSlot.value.forEach((element) => {
                        // i set eventEndDate to all time slot because backend has some functionality on this (this.selectedSchedule == 2) case.
                        const temp = { startTime: this.convertTimestampToTimezone(this.getTimeStamp(element['startTime'], new Date(this.eventSchedule.controls.picker.get('eventStartDate')?.value).getTime())), endTime: this.convertTimestampToTimezone(this.getTimeStamp(element['endTime'], new Date(this.eventSchedule.controls.picker.get('eventEndDate')?.value).getTime())) }
                        timeSlot.push(temp);
                        schedule[0] = scheduleObj;
                    });

                    let dateRange = this.getDatesBetween(payload['eventStartDate'], payload['eventEndDate'])
                    dateRange.forEach((item) => {
                        let temp = {
                            slotDate: item,
                            activitesList: []
                        }
                        this.performerSchedule.push(temp);
                    })

                    schedule[0]['timeSlot'] = timeSlot;

                    payload['schedule'] = schedule;
                    const timeZone = this.timeZones.filter((item) => item.id == this.eventSchedule.get('timeZone').value)[0]
                    console.log("timeZone", timeZone);
                    payload['timezone'] = timeZone;

                    payload['performerSchedule'] = this.performerSchedule;
                    
                    if (this.isAddCase) {
                        if (this.eventData?.createStep == 2) {
                            console.log("UPDATE CASE");
                            payload['eventId'] = this.eventData?.id;
                            this.updateEventSaveInApi(payload);
                        } else {
                            console.log("ADD CASE", payload);
                            this.callSaveApi(payload);
                        }
                    } else {
                        if (!this.eventSchedule.pristine) {
                            payload['eventId'] = this.eventData?.id;
                            this.updateEventSaveInApi(payload);
                        } else {
                            this.onNextStep();
                            this.changeStepperEnumsSetting(2);
                        }
                    }

                }
                else if (this.selectedSchedule == 4) {
                    console.log("log in submit", this.selectedSchedule);
                    let payload: any = {}
                    payload['createStep'] = 2;
                    payload['eventId'] = this.eventData?.id;
                    payload['eventStartDate'] = this.eventSchedule.controls.recurringDate.value ? this.convertTimestampToTimezone(new Date(this.eventSchedule.controls.recurringDate.value).getTime()) : '';
                    payload['recurringEndDate'] = this.eventSchedule.controls.repeatNumberDate.value ? this.convertTimestampToTimezone(new Date(this.eventSchedule.controls.repeatNumberDate.value).getTime()) : '';
                    payload['isMultiple'] = 2;
                    payload['recAdvanceSchedule'] = this.eventSchedule.controls.recurringAdvanceBooking?.value || '';
                    payload['recRepeatCount'] = this.eventSchedule.controls.repeatNumberOfDays?.value || '';
                    payload['scheduleType'] = this.selectedSchedule;
                    payload['recRepeatType'] = this.eventSchedule.controls.whenRecurring.value;
                    payload['recEndDateType'] = this.recurringEndType;
                    payload['timeSlot'] = [];
                    // this.addActivitiesLabelInRecurringCase(payload);
                    console.log("this.WEEKDAYS", this.WEEKDAYS);

                    const weekDayIds = [];
                    
                    if (this.WEEKDAYS?.length) {
                        this.WEEKDAYS.filter(item => item.isSelected ? weekDayIds.push(item.id) : null);
                        if(weekDayIds?.length){
                            payload['dayList'] =weekDayIds;
                        }else{
                            payload['dayList'] = [new Date(this.eventSchedule.controls.recurringDate.value).getDay()];
                        }
                    }
                    
                    console.log("this.eventSchedule.controls.recurringDate.value", this.eventSchedule.controls.recurringDate.value);

                    if (this.recurringTimeSlots.controls?.length) {
                        this.recurringTimeSlots.controls.forEach((item) => {
                            const slot = { startTime: this.convertTimestampToTimezone(this.getTimeStamp(item.controls['startTime'].value, payload['eventStartDate'])), endTime: this.convertTimestampToTimezone(this.getTimeStamp(item.controls['endTime'].value, payload['eventStartDate'])) }
                            payload['timeSlot'].push(slot);
                        })
                    }
                    payload['recRepeatTypeStr'] = '';
                    payload['performerSchedule'] = [];
                    const timeZone = this.timeZones.filter((item) => item.id ==this.eventSchedule.get('timeZone').value)[0]
                    console.log("timeZone", timeZone);
                    payload['timezone'] = timeZone;

                    console.log("CASE", payload);
                    // check update or add case according. 
                    if (this.isAddCase) {
                        if (this.eventData?.createStep == 2) {
                            console.log("UPDATE CASE");
                            payload['eventId'] = this.eventData?.id;
                            this.updateEventSaveInApi(payload);
                        } else {
                            console.log("ADD CASE", payload);
                            this.callSaveApi(payload);
                        }
                    } else {
                        if (!this.eventSchedule.pristine) {
                            payload['eventId'] = this.eventData?.id;
                            this.updateEventSaveInApi(payload);
                        } else {
                            this.onNextStep();
                            this.changeStepperEnumsSetting(2);
                        }
                    }
                }
            }
            // THIS IS FOR (MULTIPLE DAYS EVENT)
            if (this.allSchedule?.length && this.selectedSchedule == 3) {

                const payload = {}
                payload['createStep'] = 2;
                payload['eventId'] = this.eventData.id;
                payload['eventStartDate'] = this.convertTimestampToTimezone(this.allSchedule[0].minDate);
                payload['eventEndDate'] = this.convertTimestampToTimezone(this.allSchedule[0].maxDate);
                payload['isMultiple'] = 2;
                payload["scheduleType"] = this.selectedSchedule;
                // this.allSchedule.forEach((item) => {
                //     console.log("allSchedule item -->", item);
                //     let temp = {
                //         slotDate: this.convertTimestampToTimezone(item['scheduleStartDate']),
                //         activitesList: []
                //     }
                //     this.performerSchedule.push(temp);
                // })

                payload['performerSchedule'] = [];
                payload['schedule'] = [...this.allSchedule];
                payload['schedule'].forEach(item => {
                    delete item.maxDate;
                    delete item.minDate;
                })
                const timeZone = this.timeZones.filter((item) => item.id == this.eventSchedule.get('timeZone').value)[0];
                console.log("timeZone", timeZone);
                
                payload['timezone'] = timeZone;
                // CALL API TO SAVE PAYLOAD DATA...
                // check update or add case according. 
                if (this.isAddCase) {
                    if (this.eventData?.createStep == 2) {
                        console.log("UPDATE CASE");
                        payload['eventId'] = this.eventData?.id;
                        this.updateEventSaveInApi(payload);
                    } else {
                        console.log("ADD CASE 2 ", payload);
                        this.callSaveApi(payload);
                    }
                } else {
                    // if(!this.eventInfo.pristine){
                    payload['eventId'] = this.eventData?.id;
                    this.updateEventSaveInApi(payload);
                    // }else{

                    // }
                }
            }
        }
        // FOR STEP 3. (TICKET SALE)
        else if (type === 'ticketSale') {
            // CREATING PAYLOAD
            const payload = {}
            payload["createStep"] = 3;
            payload['eventId'] = this.eventData.id;
            payload["saleStartDate"] = this.eventSaleStartDataType == 1 ? new Date(this.currentDate).getTime() : this.convertTimestampToTimezone(new Date(this.ticketSale.controls.tSaleStartDate?.value).getTime());
            payload["saleEndDate"] = this.selectedSchedule != 4 ? getSaleEndDate(this.eventSaleEndDataType, this.eventData, this.ticketSale.get('tSaleEndDate')?.value) : '';
            payload['isTicketShareable'] = this.ticketSale.get('isTicketShareable').value == true ? 1 : 0;
            payload['shareSecured'] =  this.ticketSale.get('isTicketShareable').value == true ? this.ticketSale.get('shareSecured').value ? this.ticketSale.get('shareSecured').value : '' : '';
            
            // FOR CREATE SALE END DATE //
            function getSaleEndDate(type, data, tSaleEndDate) {
                if (type == 1) {
                    return data?.eventStartDate;
                } else if (type == 2) {
                    return data?.eventEndDate;
                } else if (type == 3) {
                    return moment.isMoment(tSaleEndDate) ? new Date(moment(tSaleEndDate).toDate()).getTime() : new Date(tSaleEndDate).getTime();
                }
            }

            // CALL API TO SAVE PAYLOAD DATA...
            // check update or add case according. 
            if (this.isAddCase) {
                if (this.eventData?.createStep == 3) {
                    console.log("UPDATE CASE");
                    payload['eventId'] = this.eventData?.id;
                    this.updateEventSaveInApi(payload);
                } else {
                    console.log("ADD CASE");
                    this.callSaveApi(payload);
                }
            } else {
                // if (!this.ticketSale.pristine) {
                    payload['eventId'] = this.eventData?.id;
                    this.updateEventSaveInApi(payload);
                // } else {
                //     this.onNextStep();
                //     this.changeStepperEnumsSetting(3);
                // }
            }
        }
        // FOR STEP 4. (Venue Activity)
        else if (type === 'venueActivity') {
            console.log("this is venue activity", this.venueActivity);
            // console.log("global Payload", this.globalPayload);
            console.log("all Speakers List", this.activityPayload);
            const payload = {}
            payload['createStep'] = 4;
            payload['eventId'] = this.eventData?.id;
            payload['venueId'] = this.venueActivity.controls?.eventVenue?.value ? this.venueActivity.controls.eventVenue.value : '';
            payload['isOnline'] = this.venueActivity.controls.isOnline?.value;
            payload['joinUrl'] = this.venueActivity.controls?.joinUrl?.value ? this.venueActivity.controls.joinUrl.value : '';
            payload['videoURL'] = '';
            payload['noSeatEvent'] = this.venueActivity.controls.noSeatEvent?.value ? 0 : 1;
            payload['allowMutlipleScan'] = this.venueActivity.controls.allowMutlipleScan?.value ? 0 : 1;
            payload['totalSeats'] = this.venueActivity.controls?.capacity?.value ? this.venueActivity.controls?.capacity?.value : '';
            payload['scanningTime'] = this.venueActivity.controls?.scanningTime?.value ? this.venueActivity.controls?.scanningTime.value : '';

            // sponsor group and title.
            const selectedGroups = this.selectedSponsorsGroup;
            selectedGroups.map(item => {
                // item.name = item.sponsorType;
                if (item?.sponsorGroupId) item.id = item.sponsorGroupId;
                if (item?.sponsors) item.sponsors = [];
            })
            console.log("selectedGroups", selectedGroups);
            payload['sponsorsGroup'] = this.venueActivity.controls?.sponsorsGroup?.value?.length ? selectedGroups : [];
            payload['sponsorsTitle'] = this.venueActivity.controls?.sponsorsGroup?.value?.length ? (this.venueActivity.controls.sponsorsTitle.value || '') : '';
            // host group and title.
            const selectedHostGroups = this.selectedHostGroup;
            selectedHostGroups.map(item => {
                // item.name = item.groupName;
                if (item?.hostGroupId) item.id = item.hostGroupId;
                if (item?.speakers) item.speakers = [];
            })
            console.log("selectedHostGroups", selectedHostGroups);
            payload['speakersGroup'] = this.venueActivity.controls.hostGroup.value.length ? selectedHostGroups : [];
            payload['speakersTitle'] = this.venueActivity.controls.hostGroup.value.length ? (this.venueActivity.controls.speakersTitle.value || '') : '';

            if (this.performerSchedule.length) {
                this.performerSchedule.forEach((activityData) => {
                    activityData.slotDate = new Date(activityData.slotDate).getTime();
                    activityData.activitesList.forEach((activity) => {
                        console.log("this is the activity", activity);
                        activity['startTime'] = activity?.startTime ? Number(activity['startTime']) : activity['startTime'];
                        activity['endTime'] = activity?.startTime ? Number(activity['endTime']) : activity['startTime'];
                        activity['performerList'] = !activity['performerList']?.length ? [] : activity['performerList'];
                        if (!activity['performerList']?.length) {
                            activity['performers'] = [];
                        }
                    })
                })

                // convert Activites Time according to zone.
                if (this.selectedTimeZone) {
                    this.performerSchedule.forEach((item) => {
                        item['slotDate'] = item['slotDate']
                        if (item.activitesList.length) {
                            item.activitesList.forEach((activity) => {
                                activity['startTime'] = this.convertTimestampToTimezone(activity['startTime'])
                                activity['endTime'] = this.convertTimestampToTimezone(activity['endTime'])
                            })
                        }
                    })
                }

                payload['performerSchedule'] = this.performerSchedule;
            } else {
                payload['performerSchedule'] = [];
            }

            // check update or add case according. 
            if (this.isAddCase) {
                if (this.eventData?.createStep == 4) {
                    payload['eventId'] = this.eventData?.id;
                    this.updateEventSaveInApi(payload);
                } else {
                    console.log("ADD CASE", payload);
                    this.callSaveApi(payload);
                }
            } else {
                // if(!this.venueActivity.pristine){
                payload['eventId'] = this.eventData?.id;
                console.log("EDIT CASE", payload);
                this.updateEventSaveInApi(payload);
                // }else{
                //     this.onNextStep();
                //     this.changeStepperEnumsSetting(4);
                // }
            }

        }
        // FOR STEP 5. (Event Details)
        else if (type === 'eventDetails') {
            const payload = {};
            payload['createStep'] = 5;
            payload['eventId'] = this.eventData?.id;
            payload['description'] = this.eventDetails.controls.eventDesc?.value ?? '';
            payload['terms'] = this.eventDetails.controls.eventTnC?.value ?? '';
            payload['thumbNail'] = this.eventDetails.controls.thumbNail?.value ?? '';
            payload['banner'] = this.eventDetails.controls.banner?.value ?? '';
            payload['faqList'] = this.eventDetails.controls.faq.value ? this.eventDetails.controls.faq.value : [];
            payload['resourseList'] = this.eventDetails.controls.resourseList.value ? this.eventDetails.controls.resourseList.value : [];

            console.log("this payload", payload);
            // check update or add case according. 
            if (this.isAddCase) {
                if (this.eventData?.createStep == 5) {
                    console.log("UPDATE CASE");
                    payload['eventId'] = this.eventData?.id;
                    this.updateEventSaveInApi(payload);
                } else {
                    console.log("ADD CASE");
                    this.callSaveApi(payload);
                }
            } else {
                // if (!this.eventDetails.pristine) {
                payload['eventId'] = this.eventData?.id;
                this.updateEventSaveInApi(payload);
                // } else {
                //     this.onNextStep();
                //     this.changeStepperEnumsSetting(5);
                // }
            }
        }
        // FOR STEP 6. (Ready to Publish)
        else if (type === 'readyToPublish') {
                
            if (this.custormerBookingInfo['data']?.length) {
                this.custormerBookingInfo['data']?.map((item) => { delete item?.isDisabled; delete item?.checkMarkDisabled });
                const payload = {};
                payload['createStep'] = 6;
                payload['eventId'] = this.eventData?.id;
                payload['isDomeApplicable'] = this.readyToPublish.controls.isDomeApplicable?.value;
                payload['isPublic'] = this.readyToPublish.controls.eventListingType?.value;
                payload['isPublish'] = isPublish ? 1 : 0;
                payload['convenienceFee'] = this.readyToPublish.controls.convenienceFee?.value || '2';
                payload['customerInfoType'] = this.custormerBookingInfo.type ?? 2;
                payload['allowMutliBooking'] = this.readyToPublish.get('allowMutliBooking').value;
                payload['externalBookingUrl'] = this.readyToPublish.controls.externalBookingUrl?.value == '' ? '' : this.readyToPublish.controls.externalBookingUrl?.value == '' ? '' : this.readyToPublish.controls.externalBookingUrl?.value;
                payload['onlinePlatformName'] = '';
                payload['taxApplicable'] = this.readyToPublish.controls.taxApplicable?.value ? 1 : 0;
                payload['taxInclude'] = this.readyToPublish.controls.taxCharges?.value == '1' ? 1 : 0;
                payload['taxList'] = this.readyToPublish.controls.taxList.value ? this.readyToPublish.controls.taxList.value.join(',') : '';
                payload['customerFieldList'] = this.custormerBookingInfo['data']?.length ? this.custormerBookingInfo['data'] : this._addEditEventService.dynamicFields;
                payload['bookingType'] = this.readyToPublish.get('bookingType').value;

                if (this.isAddCase) {
                    if (this.eventData?.createStep == 6) {
                        console.log("UPDATE CASE");
                        payload['eventId'] = this.eventData?.id;
                        this.updateEventSaveInApi(payload, goOnCreateTicket);
                    } else {
                        console.log("ADD CASE", payload); 
                        this.callSaveApi(payload, goOnCreateTicket);
                    }
                } else {
                    payload['eventId'] = this.eventData?.id;
                    this.updateEventSaveInApi(payload, goOnCreateTicket);
                }

                console.log("final payload", payload);                
            } else {
                this.openCustormerDailog('recall');
                this._matSnackBar.open('Please Select Customer info on Booking', "OKAY", { duration: 2000 });
            }
        }
    }

   
    onWhenRecChange(value){
        if(value != 1){
            this.WEEKDAYS.map(item => item.isSelected = 0)
        }
    }

    getDatesBetween(timestamp1, timestamp2) {
        const startDate = new Date(timestamp1);
        const endDate = new Date(timestamp2);
        const timestamps = [];

        // Set time to midnight (00:00:00) for both start and end dates
        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        // Loop through the dates and add them to the array
        while (startDate <= endDate) {
            timestamps.push(startDate.getTime());
            startDate.setDate(startDate.getDate() + 1);
        }

        return timestamps;
    }


    // addActivitiesLabelInRecurringCase(payload) {
    //     if (this.selectedSchedule == 4) {
    //         if (this.eventSchedule.controls.whenRecurring.value == 9) {
    //             this.performerSchedule = [{ recTitle: 'Daily', slotDate: payload['eventStartDate'], activitesList: [] }];
    //             console.log("check 9 ", this.performerSchedule);
    //         }
    //         else if (this.eventSchedule.controls.whenRecurring.value == 2 || this.eventSchedule.controls.whenRecurring.value == 3 || this.eventSchedule.controls.whenRecurring.value == 4 || this.eventSchedule.controls.whenRecurring.value == 5 || this.eventSchedule.controls.whenRecurring.value == 6 || this.eventSchedule.controls.whenRecurring.value == 7 || this.eventSchedule.controls.whenRecurring.value == 8) {
    //             let tempTitle;
    //             this.EVENT_RECURRING.forEach((element) => {
    //                 element.types.forEach((item) => {
    //                     if (item.id == this.eventSchedule.controls.whenRecurring.value) {
    //                         tempTitle = `For ${item.type}`;
    //                     }
    //                 })
    //             })
    //             this.performerSchedule = [{ recTitle: tempTitle, slotDate: payload['eventStartDate'], activitesList: [] }];
    //             console.log("check other ", this.performerSchedule);

    //         }
    //         else if (this.eventSchedule.controls.whenRecurring.value == 1) {
    //             let tempTitle;
    //             this.EVENT_RECURRING.forEach((element) => {
    //                 element.types.forEach((item) => {
    //                     if (item.id == this.eventSchedule.controls.whenRecurring.value) {
    //                         tempTitle = `For All Selected Day`;
    //                     }
    //                 })
    //             })
    //             this.performerSchedule = [{ recTitle: tempTitle, slotDate: payload['eventStartDate'], activitesList: [] }];
    //             console.log("check 1 ", this.performerSchedule);

    //         }
    //     }
    // }

    updateEventSaveInApi(data, goOnCreateTicket?) {
        this.showPrograssiveBar(true);
        console.log("update data case", data);
        if (data['createStep'] == 1) {
            this._addEditEventService.updateEventFirstStep(data).then((result: any) => {
                if (result.status == 200) {
                    this.onNextStep();
                    this.showPrograssiveBar(false);
                    this.changeStepperEnumsSetting(data.createStep);

                    this.getEventData();
                    console.log("your updateEventFirstStep data", this.eventData);
                }
                this._matSnackBar.open(result.message, "OKAY", { duration: 3000 });
            })
        }
        else if (data['createStep'] == 2) {
            this._addEditEventService.updateEventSecondStep(data).then((result: any) => {
                if (result.status == 200) {
                    this.onNextStep();
                    this.changeStepperEnumsSetting(data.createStep);
                    this.showPrograssiveBar(false);
                    this.getEventData();
                    this.eventStartDate = new Date(this.eventData.eventStartDate);
                    console.log("your updateEventFirstStep data", this.eventData);
                }
                this._matSnackBar.open(result.message, "OKAY", { duration: 3000 });
            })
        }
        else if (data['createStep'] == 3) {
            this._addEditEventService.updateEventThirdStep(data).then((result: any) => {
                if (result.status == 200) { 
                    this.getEventData();
                    console.log("your updateEventFirstStep data", this.eventData); 
                    this.onNextStep();
                    this.changeStepperEnumsSetting(data.createStep);
                    this.showPrograssiveBar(false);
                }
                this._matSnackBar.open(result.message, "OKAY", { duration: 3000 });
            })
        }
        else if (data['createStep'] == 4) {
            this._addEditEventService.updateEventForthStep(data).then((result: any) => {
                if (result.status == 200) {
                    // if (result?.data?.createStep == 4 && result?.data?.eventStatus == 7) {
                        // }
                        this.onNextStep();
                        this.changeStepperEnumsSetting(data.createStep);
                        this.showPrograssiveBar(false); 
                        this.updateStepsData();
                }
                this._matSnackBar.open(result.message, "OKAY", { duration: 3000 });
            })
        }
        else if (data['createStep'] == 5) {
            console.log("this is the edit payload -->", data);
            this._addEditEventService.updateEventFiveStep(data).then((result: any) => {
                if (result.status == 200) {
                    this.getEventData();
                    console.log("your updateEventFirstStep data", this.eventData);
                    this.onNextStep();
                    this.changeStepperEnumsSetting(data.createStep);
                    this.showPrograssiveBar(false);
                }
                this._matSnackBar.open(result.message, "OKAY", { duration: 3000 });
            })
        }
        else if (data['createStep'] == 6) {
            this._addEditEventService.updateEventSixStep(data).then((result: any) => {
                if (result.status == 200) {
                    this._matSnackBar.open(result.message, 'OKAY', { duration: 3000 })
                    if(goOnCreateTicket){
                        this._router.navigate(["/tickets/create"], { queryParams: { eventId: this.eventData?.id, to: 'addevent' } });
                    }else{
                        this._router.navigate(['/events/view/' + this.eventData?.id + '/info']);
                    }
                }
            })
        }
    }

    onCreateTicket() {
        this.onSubmit('readyToPublish', false, true); 
    }

    /* 
        OPEN DIALOG FOR ADD NEW SCHEDULE ACCORDING TO DATE.
    */
    onScheduleDialog() { 
            this._matDialog.open(AddActivityComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                width: '800px',
                autoFocus: false,
                disableClose: true,
                data: {
                    isEdit: false,
                    selectedDate: this.selectedDateForActivities,
                    // performerList: this.allSpeakersList?.length ? this.allSpeakersList : []
                }
            }).afterClosed().subscribe(activityPayload => {
                console.log("activityPayload ===> ", activityPayload);
                if (activityPayload) {
                    this.performerSchedule.forEach((item) => {
                        if (item.slotDate == activityPayload.slotDate) {
                            item['activitesList'] = activityPayload.activitesList;
                        }
                    })
                    this.performerSchedule.forEach((slotDate) => {
                        slotDate.activitesList.forEach((activity) => {
                            if (activity.performerList?.length) {
                                activity['performers'] = this.setPerformerById(activity.performerList);
                                this.addRanking(activity['performers']);
                                console.log("activity['performers']", activity['performers']);
                            }
                        })
                    })
                    console.log("this is activity payload", this.performerSchedule);
                }
            }) 
    }



    onEditactivity(date) {
        console.log("allSpeakersList", this.allSpeakersList);
        
        this._matDialog.open(AddActivityComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: '800px',
            autoFocus: false,
            disableClose: true,
            data: {
                selectedDate: date,
                isEdit: true,
                preActivities: this.performerSchedule,
                // performerList: this.allSpeakersList
            }
        }).afterClosed().subscribe(activityPayload => {
            if (activityPayload) {

                this.performerSchedule.forEach(item => {
                    if (item.slotDate == date) {
                        console.log("this is item", item);
                        if (item.activitesList?.length) {
                            item.activitesList.forEach((activity) => {
                                if (activity.performerList?.length) {
                                    activity['performers'] = this.setPerformerById(activity.performerList);
                                    this.addRanking(activity['performers']);
                                }
                            })
                        }
                    }
                })
            }
        })
    }

    addNewResourse() {
        const dialog = this._matDialog.open(AddEditResourcesDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: '450px',
            autoFocus: false,
            data: {
                case: 'add',
            }
        })
        dialog.afterClosed().subscribe((res) => {
            if (res) {
                this._addeditcommondataproviderservice.getAllResources();
            }
        })
    }

    onChangeSteps(event) {
        this._router.navigate([], {
            relativeTo: this._activatedRoute,
            queryParams: {
                step: event.selectedIndex
            }
        })

        // EVENT INFO
        if (event.selectedIndex == 0) {

        }
        // EVENT SCHEDULE
        else if (event.selectedIndex == 1) {

        }
        // TICKET SALE
        else if (event.selectedIndex == 2) {
            console.log("this.eventData--->", this.eventData);
            this.ticketSale.controls.tSaleEndDate.setValue(moment(new Date(this.eventData?.eventStartDate)).toDate());
        }
        // VENUE & ACTIVITY
        else if (event.selectedIndex == 3) {
            if (!this.performerSchedule?.length) {
                if (this._addeditcommondataproviderservice?.eventData?.performerSchedule?.length)
                    this.performerSchedule = this._addeditcommondataproviderservice?.eventData?.performerSchedule
            }
            // if(this.globalPayload['2']?.schedule){
            //     this.isAddEditActivityBtnShow = true;
            // }
        }
        // EVENT DETAILS
        else if (event.selectedIndex == 4) {

        }
        // READY TO PUBLISH
        else if (event.selectedIndex == 5) {

        }
    }


    // addEventSponsor() {
    //     // dialog
    //     let dialog = this._matDialog.open(AddEditSponsorComponent, {
    //         panelClass: ['commonDialogWrapper'],
    //         backdropClass: 'dialogBackdrop',
    //         width: '400px',
    //         autoFocus: false,
    //         data: {
    //             case: 'add',
    //         }
    //     })
    //     // get all sponsors
    //     dialog.afterClosed().subscribe((res) => {
    //         if(res){
    //             this._sponsorService.getAllSponsors(1, 1000, '').then((result: any) => {
    //                 if (result.status == 200) {
    //                     this.allSponsorsList = result.data.sponsorList;
    //                 } else {
    //                     this.allSponsorsList = [];
    //                 }
    //             });
    //         }
    //     })
    // }



    checkSelectedDateLength() {
        const selectedDateForAct = this.performerSchedule.filter((item) => item.slotDate == this.selectedDateForActivities);
        if (selectedDateForAct.length) {
            if (selectedDateForAct[0].activitesList?.length) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }

    deleteActives(activies, i) {
        console.log("activies", activies);

        const dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data: {
                message: `Are you sure you want to delete this activity?`,
                image: '',
                focus: 'right',
                deleteItem: 'delete activity ?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }
        })

        dialog.afterClosed().subscribe((res) => {
            if (res) {
                if (activies[i].id) {
                    const payload = {
                        eventId: activies[i].eventId,
                        activityId: activies[i].id
                    }
                    this._commonService.onDeleteActivity(payload).then((res: any) => {
                        if (res.status == 200) {
                            // this._addEditEventService.eventData
                            // activies.splice(i, 1);
                            this.updateStepsData();
                            this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
                        } else {
                            this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
                        }
                    })
                } else {
                    activies.splice(i, 1);
                }
            }
        })

    }

    updateStepsData() {
        this._fuseProgressBarService.show();
        this._addeditcommondataproviderservice.getEventDetail(this.eventData.id, true).then((res: any) => {
            if (res.status == 200) {
                this.performerSchedule = res.data.performerSchedule;
                console.log("this.performerSchedule after update", this.performerSchedule);
                this.performerSchedule = this.sorting(this.performerSchedule);
                this._cd.detectChanges();
                this._fuseProgressBarService.hide();
            }
        });
    }

    getEventData(){
        this._addeditcommondataproviderservice.getEventDetail(this.eventData.id, true).then((res: any) => {
            if (res.status == 200) {
                this.eventData = res.data;

                console.log("new Date()--->",this.eventData.eventStartDate, new Date(this.eventData.eventStartDate));
                this.eventStartDate = new Date(this.eventData.eventStartDate);
                this.eventEndDate = new Date(this.eventData.eventEndDate);

                if (this.eventData?.performerSchedule?.length)
                this.performerSchedule = this._addeditcommondataproviderservice.eventData.performerSchedule;
            
            }
        })
    }

    // on Selection Change for speakers
    onSelectHostGroup(event) {
        this.selectedHostGroup = [];
        if (this.venueActivity.get('hostGroup').value)
            this.allHostGroupList.forEach(item => {
                this.venueActivity.get('hostGroup').value.forEach((hId) => {
                    if (Number(item.id) === Number(hId)) {
                        this.selectedHostGroup.push(item)
                    }
                })
            })
        console.log("this.selectedSpeakers ===-->", this.selectedHostGroup);
        this.addRanking(this.selectedHostGroup)
    }

    // on selection change for sponsors
    onSelectSponsors(evnet) {
        this.selectedSponsorsGroup = [];
        if (this.venueActivity.get('sponsorsGroup').value)
            this.allSponsorsGroupList.forEach(item => {
                this.venueActivity.get('sponsorsGroup').value.forEach((spId) => {
                    if (Number(item.id) === Number(spId)) {
                        this.selectedSponsorsGroup.push(item)
                    }
                })
            })
        this.addRanking(this.selectedSponsorsGroup)
        console.log("this.selectedSpeakers ===-->", this.selectedSponsorsGroup);
    }


    dropSpeaker(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedHostGroup, event.previousIndex, event.currentIndex);
        this.addRanking(this.selectedHostGroup);
    }

    dropSponsor(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.selectedSponsorsGroup, event.previousIndex, event.currentIndex);
        this.addRanking(this.selectedSponsorsGroup);
        console.log("this.selectedSponsors", this.selectedSponsorsGroup);
    }

    dropActSpeaker(event: CdkDragDrop<string[]>, e, a) {
        moveItemInArray(this.performerSchedule[e].activitesList[a].performers, event.previousIndex, event.currentIndex);
        this.addRanking(this.performerSchedule[e].activitesList[a].performers);
        console.log("performers====>", this.performerSchedule[e].activitesList[a].performers);
    }


    onAddSponsorGroup() {
        console.log("this._sponsorService.allSponsors", this._sponsorService.allSponsors);
        // check first is sponsor group list asset or not.
        if (!this._sponsorService.allSponsors?.length) {
            this._sponsorService.getAllSponsors(0, 0, '').then((apiRes: any) => {
                if (apiRes.status == 200) {
                    this.openAddSponsorGroupDialog(apiRes.data.sponsorList)
                } else {
                    this._matSnackBar.open(apiRes, 'OKAY', { duration: 2000 })
                }
            })
        } else {
            this.openAddSponsorGroupDialog(this._sponsorService.allSponsors)
        }
    }

    openAddSponsorGroupDialog(list) {
        const dialog = this._matDialog.open(AddEditSponsorGroupComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: '500px',
            autoFocus: false,
            data: {
                mode: 'add',
                hostType: undefined,
                sponsors: list
            }
        })
        dialog.afterClosed().subscribe((res) => {
            if (res?.formData) {
                if (res?.mode == 'add') {
                    const payload = {
                        name: res?.formData?.title,
                        sponsorList: []
                    }
                    res.selectedSponsors.forEach((item) => {
                        payload.sponsorList.push({ sponsorId: item.id, sponsorRanking: item.rank })
                    })
                    // adding new sponsor group
                    this._commonService.addSponsorGroup(payload).then((result: any) => {
                        if (result.status == 200) {
                            this._matSnackBar.open(result.message, 'OKAY', { duration: 2000 })
                            // getting list 
                            this._addeditcommondataproviderservice.getAllSponsorGroup();
                        }
                    })
                }
            }
        })
    }


    onAddHostGroup() {
        if (!this.allHostList?.length) {
            this.getAllHostList()
        } else {
            const dialog = this._matDialog.open(AddEditHostGroupComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                width: '500px',
                autoFocus: false,
                data: {
                    mode: 'add',
                    hostType: undefined,
                    hosts: this.allHostList
                }
            })

            dialog.afterClosed().subscribe((res) => {
                if (res?.formData) {
                    if (res?.mode == 'add') {
                        const payload = {
                            name: res?.formData?.title,
                            speakerList: []
                        }
                        res.selectedSponsors.forEach((item) => {
                            payload.speakerList.push({ speakerId: item.id, speakerRanking: item.rank })
                        })
                        this._commonService.addHostGroup(payload).then((result: any) => {
                            if (result.status == 200) {
                                this._matSnackBar.open(result.message, 'OKAY', { duration: 2000 })
                                this._addeditcommondataproviderservice.getAllHostGroup();
                            } else {
                                this._matSnackBar.open(result.message, 'OKAY', { duration: 2000 })
                            }
                        })
                    }
                }
            })
        }
    }

    getAllHostList() {
        this._SpeakerService.getSpeakersListData({ pageIndex: 0, pageSize: 0 }).then((res: any) => {
            if (res.status == 200) {
                this.allHostList = res.data.data;
                this.onAddHostGroup();
            } else {
                this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
            }
        })
    }

    formatDateToTime(date) {
        // Get hours and minutes
        let hours = date.getHours();
        const minutes = date.getMinutes();
        // Convert to 12-hour format
        let period = 'am';
        if (hours >= 12) {
            period = 'pm';
            if (hours > 12) {
                hours -= 12;
            }
        }
        return String(`${hours}:${minutes} ${period}`)
    }

    compareDates(currentDate, date) {
        // Extract year, month, and day from the dates
        const year1 = currentDate.getFullYear();
        const month1 = currentDate.getMonth();
        const day1 = currentDate.getDate();
        
        const year2 = moment(new Date(date)).toDate().getFullYear();
        const month2 = moment(new Date(date)).toDate().getMonth();
        const day2 = moment(new Date(date)).toDate().getDate();
        // Compare year, month, and day
        return year1 === year2 && month1 === month2 && day1 === day2;
    }

    

    // Destroy
    ngOnDestroy(): void {
        console.log("ngOnDestroy called");

        this.allSchedule = [];
        this.activityPayload = [];
        this.eventId = null;
        this.custormerBookingInfo = {};
        this.eventData = null;
        this.performerSchedule = [];
        this.eventStartDate = null;
        this.eventEndDate = null;

        this.EVENT_STEPS = null;
        this.EVENT_SCHEDULE_TYPE = null;
        this.EVENT_PRICE = null;
        this.EVENT_RECURRING = null;
        this.RECURRINGEND = null;
        this.EVENT_TICKET_SALE_START_DATE = null;
        this.EVENT_TICKET_SALE_END_DATE = null;
        this.EVENT_CONVENIENCE_FEE = null;
        this.SCANNINGBEFORE = null;
        this.EVENT_WAY = null;
        this.WEEKDAYS = null;

        this.eventInfo.reset();
        this.eventSchedule.reset();
        this.ticketSale.reset();
        this.venueActivity.reset();
        this.eventDetails.reset();
        this.readyToPublish.reset();

        this._addeditcommondataproviderservice.eventTypes = [];
        this._addeditcommondataproviderservice.eventCategory = []; 

        this._addeditcommondataproviderservice._allGuest.next([]);
        this._addeditcommondataproviderservice._allOrganisers.next([]);
        this._addeditcommondataproviderservice._allVenues.next([]);
        this._addeditcommondataproviderservice._speakersList.next([]);
        this._addeditcommondataproviderservice._currentCase.next('');
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this._addeditcommondataproviderservice.eventData = null;
        this._addeditcommondataproviderservice.eventId = null;
        this._addeditcommondataproviderservice.ticketData = null;
        this._addeditcommondataproviderservice.permissions = null;
        this.allVenues = [];

    }

}