import { Component, ElementRef, Inject, OnInit, Query, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-ticket-type-bg-color',
  templateUrl: './ticket-type-bg-color.component.html',
  styleUrls: ['./ticket-type-bg-color.component.scss']
})
export class TicketTypeBgColorComponent implements OnInit {
    typeName:any;
  
    @ViewChildren("pickBg") pickBgColor: QueryList<ElementRef>;

    ticketTypeStyles:any = [];
    selectedColor: any;

    constructor(
      @Inject(MAT_DIALOG_DATA) private data: any,
      private dialogRef: MatDialogRef<TicketTypeBgColorComponent>,
      private _commonService:CommonService,
      private _fuseProgressBarService: FuseProgressBarService,
    ) { 

      this.getColorPalette();
      this.typeName = data.EventType
      this.selectedColor = data.selectedId? data.selectedId: ''; 
      
     
      // this.typeName = data.type[0].title
    
  }

  ngOnInit() {
 
  }

  getColorPalette(){
    this._fuseProgressBarService.show()
    this._commonService.getTicketTypeColorPalette().then((result:any) =>{
      if (result.status == 200) {
        this._fuseProgressBarService.hide()
        this.ticketTypeStyles = result.data;
        this.ticketTypeStyles.map(item => item.selected = false);
        console.log("color palatte", this.ticketTypeStyles);
        if (this.ticketTypeStyles && this.selectedColor) {
          this.seletedColor();
        }else{

        }
      }
    })
  }

  seletedColor(){
   const selected = this.ticketTypeStyles.filter(item => item.id == this.selectedColor)
   selected.map(item => item.selected = true);
  }


  onPickBg(i){
    /* Default False Value For Unseleted */
    this.ticketTypeStyles.forEach(element => {
        element.selected = false;
    });
    /* click on selected item */
    const selectedStyle = this.ticketTypeStyles.filter(item => item.id == i+1);
    /* seleted item value set true */
    selectedStyle[0].selected = true;

    this.dialogRef.close({selectedId:selectedStyle, message:'Color Picked successfully'});
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
