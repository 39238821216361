import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
// import { ApiEndPointUrl, ReqHeader } from '../../../../@fuse/utils/systemEnums';
import { DistributorsListService } from '../distributors-list/distributors-list.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

import { AuthService } from 'app/main/pages/auth.service';
import { CommonService } from '@fuse/services/common.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
  })

export class AddUserService implements Resolve<any>
{
    public dataArr = [];
    familyMember: any[];
    onFamilyMemberChanged: BehaviorSubject<any>;
    onUserChanged: BehaviorSubject<any>;
    maxAvailableRecordsInTable
    distributor
    allCountries: any = '';
    distributorId;

    states = []
    assignedEvents

    AllTranList;
    maxAvailableTranInTable;

    userId: any;
    permissions: any;
    allTransactionForDistributorList: any;
    maxAvailableRecordsInTableForTransaction: any;
    allEventsOfDistributer: any;
    distributorCommission: any;
    toSeparatCode:Number = 91;
    
    fullnumber;
    _OnTransitionChange: BehaviorSubject<any[]>;
    allTicketType: any;

    constructor(
        private _httpClient: HttpClient,
        private _distributorsListService: DistributorsListService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _authService: AuthService,
        private _matDialog: MatDialog,
        private _commonService: CommonService
    ) {
        this._OnTransitionChange = new BehaviorSubject([]);
    }
   
    //resolve only runs for edit mode
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.permissions = this._authService.getPermissionsForCurrentPage(route)
        console.log("route", route);
        this.distributorId = route.params.id;
        return new Promise((resolve, reject) => {
            if (this.distributorId) {
                Promise.all([
                    this.getDistributor(this.distributorId),
                    this.getAssignedEvents({ "distributorId": this.distributorId }),
                    this.getAllTransactionForDistributor(),
                    this.getAllEventList({ "distributorId": this.distributorId }),
                    this.getCountries(),
                    this.getTicketType(),
                    // this.getAllTranList()
                ]).then(
                    () => {
                        resolve('');
                    },
                    reject
                );
            } else {
                Promise.all([
                    this.getCountries(),
                ]).then(
                    () => {
                        this.distributorId = ''
                        resolve('');
                    },
                    reject
                );
            }
        });
    }

    getAssignedEventDetails(eventId) {
        this._fuseProgressBarService.show()

        const payload = {
            eventId: eventId,
            distributorId: this.distributorId
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getbyid/assignedevent', payload, this._commonService.getHeader())
                .subscribe((result: any) => {
                    this._fuseProgressBarService.hide()
                    resolve(result);
                }, reject)
        })
    }

    getTicketType(){
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/tickets/getall/type', {}, this._commonService.getHeader()).subscribe((response:any) =>{
                if(response.status == 200){
                    this.allTicketType = response.data.ticketTypes;
                    console.log("this.allTicketType", this.allTicketType);
                }
                resolve(response)
            }, reject)
        })
    }

    getAssignedEvents(payload) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getall/events', payload, this._commonService.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        console.log("result result result === >", result.data);
                        
                        this.assignedEvents = result.data.data
                        this.maxAvailableRecordsInTable = result.data.totalrecords;
                    } else{
                        this.assignedEvents = [];
                        this.maxAvailableRecordsInTable = 0;
                    }
                    resolve(result);
                }, reject)
        })
    }

    /* api for get all transaction list */
    // getAllTranList(transpayload?){
    //     return new Promise((resolve, reject)=>{
    //         this._httpClient.post(environment.apiURL + 'admin/getAllDistributorTransactions',transpayload, this._commonService.getHeader())
    //         .subscribe((result: any) =>{
    //         if(result.status == 200){
    //             this.AllTranList = result.data
    //             // console.log("this.AllTranList in service",this.AllTranList);
    //             this.maxAvailableTranInTable = result.data.totalRecords
    //             resolve(result)
    //         }else{
    //         this.AllTranList = []

    //         }
    //         }, reject)
    //     })
    //     }


    getCountries() {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/getall/countries', this._commonService.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        this.allCountries = result.data.countries
                    }
                    resolve('');
                }, reject)
        })
    }


    changeDistributorStatus(status) {
        if (status) {
            let dialog = this._matDialog.open(ConfirmationDialogComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                disableClose: false,
                data : {
                    message : `Are you sure you want to disable this Distributor?`,
                    image : '',
                    focus : 'right',
                    deleteItem : 'Disable Distributor?',
                    confirmButtonText : 'Yes',
                    cancelButtonText : 'No'
                }
            })

            dialog.afterClosed().subscribe((row: any) => {
                if (row) {
                    return new Promise((resolve, reject) => {
                        this._fuseProgressBarService.show()
                        this._httpClient.post(environment.apiURL + 'admin/disable/distributor', { distributorId: this.distributorId, status: status }, this._commonService.getHeader())
                        .subscribe((result: any) => {
                            if (result.status == 200)
                                this._router.navigate(['/distributors'])
                            resolve('')
                            this._fuseProgressBarService.hide()
                            this._matSnackBar.open(result.message, 'OK', {
                                duration: 2000,
                                verticalPosition: "bottom"
                            })
                        }, reject)
                    })
                }
            })

        }else{
            return new Promise((resolve, reject) => {
            this._fuseProgressBarService.show()
            this._httpClient.post(environment.apiURL + 'admin/disable/distributor', { distributorId: this.distributorId, status: status }, this._commonService.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200)
                        this._router.navigate(['/distributors'])
                    resolve('')
                    this._fuseProgressBarService.hide()
                    this._matSnackBar.open(result.message, 'OK', {
                        duration: 2000,
                        verticalPosition: "bottom"
                    })
                }, reject)
            })
        }
    }

    addDistributor(data): Promise<any> { 

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/add', data, this._commonService.getHeader())
                .subscribe((response: any) => {
                    console.log('response :', response);
                    resolve(response);
                }, reject);
        });

    }

    editDistributor(data): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.apiURL + 'admin/distributor/updatedistributor', data, this._commonService.getHeader())
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    getDistributor(id?) {
        console.log("this is id", id);
        
        const payload = {
            "distributorId": id || this.distributorId
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getdistributorbyid', payload ? payload : id, this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.distributor = response.data;
                        this.distributorCommission = response.data.commision;
                        // to find phone number flied
                        this.fullnumber = response.data.mobile
                        response.data.mobile = response.data.mobile?.slice(-10);
                        
                        this.toSeparatCode =  parseInt(this.fullnumber?.slice(0,-10));
                        
                        this._commonService.getStates(response.data.country).then((result: any) => {
                            this.states = result.data;
                            console.log("this.states --->", result.data);
                        })
                        resolve(response);
                    }
                    else if(response.status == 400){
                        this.distributor = [];
                        this.distributorCommission = [];
                    }
                    else{
                        this.distributor = [];
                        this.distributorCommission = [];
                    }
                }, reject);
        });


    }

    getAllTransactionForDistributor(filter?) {
        const payload = {
            "distributorId": this.distributorId,
            "transactionType": 0,
            "pageIndex": 1,
            "pageSize": 10
        }
        
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getAllTransactionForDistributor', filter ? filter : payload, this._commonService.getHeader()).subscribe((response: any) => {
                if (response.status == 200) {
                    this.allTransactionForDistributorList = response.data.transactionsList;
                    this._OnTransitionChange.next(this.allTransactionForDistributorList);
                    this.maxAvailableTranInTable = response.data.totalRecords;
                    resolve(response);
                } else {
                    this.allTransactionForDistributorList = [];
                    this._OnTransitionChange.next(this.allTransactionForDistributorList);
                    this.maxAvailableTranInTable = 0
                    resolve(response);
                }
                resolve(response)
            }, reject)
        })
    }

    getAllEventList(payload){
        return new Promise((resolve, reject) =>{
          this._httpClient.post(environment.apiURL + 'admin/distributor/getAllEvents', payload, this._commonService.getHeader())
          .subscribe((result:any)=>{
            if(result.status == 200){
                this.allEventsOfDistributer = result.data;
                resolve(result);
            }else if(result.status == 400){
                this.allEventsOfDistributer = [];
                resolve(result);
            }
            else{
                this.allEventsOfDistributer = []
              reject(result)
            }
          }, reject)
        })
    }


    onCheckDistributorNumber(data): Promise<any> {  
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/checkMobileNumber', data, this._commonService.getHeader()).subscribe((response: any) => { 
                resolve(response);
            }, reject);
        });
    }



}