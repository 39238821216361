import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl } from '../../../@fuse/utils/systemEnums';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from '../pages/auth.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class VerifiersListService implements Resolve<any>{

  public verifiersList;
  public verifier;
  maxAvailableRecordsInTable
  permissions: any;

  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _authService:AuthService,
    private _commonService: CommonService,
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      
      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllVerifiers({pageSize:10, pageIndex:1}).then((result) => {
          resolve(result);
        })
      ]).then(() => {
        this._fuseProgressBarService.hide();
        resolve('');
      })

    });

    // return new Promise((resolve, reject) => {

    //     Promise.all([
    //         this.getAllVerifiers()
    //     ]).then(
    //         () => {
    //             resolve(this.verifiersList);
    //         },
    //         reject
    //     );
    // });
  }

  getAllVerifiers(filter) {
    // this._fuseProgressBarService.show();

    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/verifier/getallverifiers',filter, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          if(response.hasOwnProperty('data')){
            this.verifiersList = response.data.data;
            this.maxAvailableRecordsInTable = response.data.totalRecords
            resolve(response);
          }else if(response.status == 400){
            this.verifiersList = [];
            this.maxAvailableRecordsInTable = 0;
            resolve(response)
          }
          else{
            this.verifiersList = '';
            resolve(response);
          }
        }, reject);
    });
  }

}
