import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Enums } from '@fuse/utils/systemEnums';
import * as _ from 'lodash';
@Component({
  selector: 'app-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls: ['./survey-question.component.scss']
})
export class SurveyQuestionComponent implements OnInit {
  form: FormGroup;
  hours = Array.from({ length: 25 }, (_, index) => index);
  days = Array.from({ length: 31 }, (_, index) => index);
  minutes = Array.from({ length: 61 }, (_, index) => index);
  showEventEndDate: boolean;
  isDateTimeSelect: boolean;
  enums = Enums
  // private _unsubscribeAll: Subject<unknown>;
  constructor(public matDialogRef: MatDialogRef<SurveyQuestionComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any, private fb: FormBuilder) {

    console.log("_data , _.isEmpty(_data) ===>", _data, _.isEmpty(_data))
    if (!_.isEmpty(_data)) {

      this.form = this.createForm(_data);
      if (_data.type === '0' || _data.type === '1')
        this.addRow(_data.options)
    }
    else {
      this.form = this.createForm()
      this.addRow()
    }





  }

  ngOnInit(): void {
    this.form.get('type').valueChanges.subscribe((val) => {

      if (val === '2' || val === '3') {
        (this.form.controls['options'] as FormArray).clear();
      } else {
        if (!(this.form.controls['options'] as FormArray).length) {
          this.addRow();
        }
      }

    })
  }

  private createForm(_data?): FormGroup {
    return this.form = this.fb.group({
      question: [_data?.question ?? '', Validators.required],
      type: [_data?.type ?? '0', Validators.required],
      options: this.fb.array([]),
    })
  }

  get options(): FormArray {
    return this.form.controls["options"] as FormArray;
  }


  public addRow(options?): void {
    if (options && options.length) {
      options?.forEach(element => {
        console.log(element);
        (<FormArray>this.form.get("options")).push(
          this.fb.group({ option: [element?.option, Validators.required] })
        );
      });
    } else {
      (<FormArray>this.form.get("options")).push(
        this.fb.group({ option: ["", Validators.required] })
      );

    }
  }

  public removeRow(i): void {
    (<FormArray>this.form.get("options")).removeAt(i);
  }





  public onSubmit(): void {
    if (this.form.valid) {
      this.matDialogRef.close(this.form.value);
    } else {
      this.form.markAllAsTouched()
    }
  }


}
