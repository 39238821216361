import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-add-update-webhook',
  templateUrl: './add-update-webhook.component.html',
  styleUrls: ['./add-update-webhook.component.scss'],
  animations : fuseAnimations
})
export class AddUpdateWebhookComponent implements OnInit {

  form : FormGroup;
  constructor(
    private _ToolbarService : ToolbarService,
    private _fb : FormBuilder
  ) { 
    this.setToolBarTitle();
    this.createForm();
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Add Webhook');
    // for btn
    this._ToolbarService._isBackBtnShow.next(true);
  }

  ngOnInit(): void {
  }

  createForm(){
    this.form = this._fb.group({
      url : ['', Validators.required],
      description : ['', Validators.required],
      trigger : ['', Validators.required]
    })
  }
}
