import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ViewChild, ViewEncapsulation, OnChanges, DoCheck } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { AddVenueService } from 'app/main/masters/venue/add/add-venue.service';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-add-venue',
  templateUrl: './add-venue.component.html',
  styleUrls: ['./add-venue.component.scss']
})

export class AddVenueComponent implements OnInit {
  venueForm: UntypedFormGroup;

  latitude: number;
  longitude: number;
  zoom: number = 8;
  address: string;
  mapLoadingTimes = 0
  private geoCoder;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AddVenueComponent>,
    private _fb: UntypedFormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _matSnackBar: MatSnackBar,
    private _addVenueService: AddVenueService,
    private _fuseProgressBarService: FuseProgressBarService,
  ) { }

  ngOnInit() {
    this.setCurrentLocation();
    this.loadPlaceAutoComplete();
    this.venueForm = this._fb.group({
      title: ['', Validators.required],
      // locality: [''],
      landMark: [''],
      city: ['',Validators.required],
      state: ['',Validators.required],
      country: ['',Validators.required],
      zipcode: ['',Validators.required],
      lat: [''],
      long: [''],
      completeAdd: ['', Validators.required]
    })
  } 

  onAddVenue() {
    console.log("this.venueForm", this.venueForm);
    if (this.venueForm.valid) {
      this._fuseProgressBarService.show()
      this._addVenueService.addVenue(this.venueForm.value).then((result: any) => {
        this._fuseProgressBarService.hide()
        if (result.status == 200) {
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 2000
          })
          this.dialogRef.close({ 'success': true });
        }
      }, err => {
        this._matSnackBar.open(err.message, 'OK', {
          verticalPosition: 'bottom',
          duration: 2000
        })
      })
    }
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder?.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address; 
          this.handleAddressChange(results[0])
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      } 
    });
  }

  public handleAddressChange(address) {
    console.log("handleAddressChange called");
    
    const addCompLength = address.address_components.length
    console.log(address)

    const addressParts = {
      // landmark: address.address_components.filter(item => {
      //   if (item.types[0] == 'landmark')
      //     return item.long_name
      // })[0],

      // locality: address.address_components.filter(item => {
      //   if (item.types[2] == 'sublocality_level_2')
      //     return item.long_name
      // })[0],

      landMark: address.address_components.filter(item => {
        if (item.types[2] == 'sublocality_level_1')
          return item.long_name
      })[0],

      city: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_2')
          return item.long_name
      })[0],

      state: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_1')
          return item.long_name
      })[0],

      country: address.address_components.filter(item => {
        if (item.types[0] == "country")
          return item.long_name
      })[0],

      zipcode: address.address_components.filter(item => {
        if (item.types[0] == "postal_code")
          return item.long_name
      })[0],
    }

    console.log(addressParts)

    // if (addressParts.locality)
    //   this.venueForm.get('locality').setValue(addressParts.locality.long_name)
    if (addressParts.landMark)
      this.venueForm.get('landMark').setValue(addressParts.landMark.long_name)
    if (addressParts.city)
      this.venueForm.get('city').setValue(addressParts.city.long_name)
    if (addressParts.state)
      this.venueForm.get('state').setValue(addressParts.state.long_name)

    if (addressParts.country)
      this.venueForm.get('country').setValue(addressParts.country.long_name)

    if (addressParts.zipcode)
      console.log("addressParts.zipcode",addressParts.zipcode);
      this.venueForm.get('zipcode').setValue(addressParts.zipcode.long_name);

    this.venueForm.get('lat').setValue(address.geometry.location.lat())
    this.venueForm.get('long').setValue(address.geometry.location.lng())
    this.venueForm.get('completeAdd').setValue(address.formatted_address)
  }

  loadPlaceAutoComplete() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)
        });
      });
    });
  }

}
