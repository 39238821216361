import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { Enums } from '@fuse/utils/systemEnums';
import { DistributorsListService } from '../../distributors-list/distributors-list.service';
import { AddUserService } from '../add-distributor.service';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {

  tranDisplayedColumns: string[] = ['eventName', 'amount', 'paidOn', 'transactionType', 'paymentMode', 'entityType'];
  dataSource;
  maxAvailableRecordsInTable;
  @ViewChild('search', { static: false }) search;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  transfilter = {
    transactionType: 0,
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1,
    eventId: 0,
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
  }
  Enums = Enums
  isReadMode: boolean;
  dateRangeFilterVal

  constructor(public _addUserService: AddUserService,
    private fb: UntypedFormBuilder,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    private _distributorsListService: DistributorsListService,
    public _commonService: CommonService
    ) {

    this.dataSource = new MatTableDataSource(this._addUserService.allTransactionForDistributorList);
    this.maxAvailableRecordsInTable = this._addUserService.maxAvailableTranInTable;
    /* GET DATA ON TRANSACTION UPDATE */
    this._addUserService._OnTransitionChange.subscribe((result:any) =>{
      this.dataSource.data = result;
    })

    this._activatedRoute.params.subscribe((params) => {
      this.isReadMode = params['id'] ? true : false;
    })
  }

  ngOnInit() {
  }

  onPageChange(event) {
    this.transfilter.pageIndex = event.pageIndex + 1
    this.transfilter.pageSize = event.pageSize
    this.fetchDataInTable()
  }
  fetchDataInTable() {
    this._fuseProgressBarService.show()
    let transpayload = {
      "pageIndex": this.transfilter.pageIndex,
      "pageSize": this.transfilter.pageSize,
      "eventId": this.transfilter.eventId ? this.transfilter.eventId : '',
      "startDate": this.transfilter.dateRangeFilter.startDate ? this.transfilter.dateRangeFilter.startDate : 0,
      "endDate": this.transfilter.dateRangeFilter.endDate ? this.transfilter.dateRangeFilter.endDate : 0,
      "transactionType": this.transfilter.transactionType,
      "distributorId": this._addUserService.distributorId
    }
    this._addUserService.getAllTransactionForDistributor(transpayload).then((result: any) => {
      if (result.status == 200) {
        this._fuseProgressBarService.hide()
        this.dataSource.data = result.data.transactionsList;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else if(result.status == 400){
        this._fuseProgressBarService.hide();
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      } 
      else {
        this._fuseProgressBarService.hide();
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }

    })

  }
  onDateFilterChanges(e) {
    if (e.endDate != null && e.startDate != null) {
      this.transfilter.dateRangeFilter.endDate = +e.endDate
      this.transfilter.dateRangeFilter.startDate = +e.startDate
      this.fetchDataInTable()
      this.paginator.pageIndex = 0;
    }
  }

  clearFilter() {
    this.transfilter.dateRangeFilter = {
      startDate: 0,
      endDate: 0
    };
    this.transfilter.searchFilter = ''
    this.transfilter.eventId = 0
    this.dateRangeFilterVal = ''
    this.transfilter.transactionType = 0
    this.fetchDataInTable()
    this.paginator.pageIndex = 0;
  }
  clearSearch() {
    this.transfilter.searchFilter = ''
    this.search.nativeElement.focus()
    this.fetchDataInTable()
  }
  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.transfilter.pageIndex = 1
    this.transfilter.searchFilter = val
    this.fetchDataInTable();
  }
  onSelectionTranChange(event) {
    console.log("transtion type here -> ",event);
    this.paginator.pageIndex = 0;
    this.transfilter.pageIndex = 1
    this.transfilter.transactionType = event.value;
    this.fetchDataInTable()
  }

  onEventChangeFilter(event) {
    console.log(event.value);
    this.paginator.pageIndex = 0;
    this.transfilter.pageIndex = 1
    this.transfilter.eventId = event.value;
    this.fetchDataInTable()
  }
}