import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketListComponent } from './ticket-list/ticket-list.component';
import { CreateTicketComponent } from './create-ticket/create-ticket.component';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { QRCodeModule } from 'angularx-qrcode'
import {MatMenuModule} from '@angular/material/menu';

import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import { TemplateComponent } from './template/template.component';
import { CreateTicketService } from './create-ticket/create-ticket.service';
import { TicketListService } from './ticket-list/ticket-list.service';
import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { ChooseTicketComponent } from './create-ticket/choose-ticket/choose-ticket.component';
import { ChooseTemplateDialogComponent } from './template/choose-template-dialog/choose-template-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';

import {MatToolbarModule} from '@angular/material/toolbar';
import { AddTemplateDialogComponent } from './template/add-template-dialog/add-template-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SeatChartLayoutComponent } from './seat-chart-layout/seat-chart-layout.component'; 
import { LyDialogModule } from '@alyle/ui/dialog';


const routes: Routes = [
  {
    path: '', component: TicketListComponent,
    resolve: {
      data: TicketListService
    }
  },
  {
    path: 'create', component: CreateTicketComponent,
    resolve: {
      data: CreateTicketService
    },
    canActivate:[RoleAuthGuardService]
  },
  {
    path: 'edit/:id', component: CreateTicketComponent,
    resolve: {
      data: CreateTicketService
    }
  }, 
  
  // {
  //   path: 'seat-chart', component: SeatChartLayoutComponent,
  // },
]


@NgModule({
    declarations: [TicketListComponent, CreateTicketComponent, 
      TemplateComponent, ChooseTicketComponent, ChooseTemplateDialogComponent,
      AddTemplateDialogComponent, SeatChartLayoutComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatTabsModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatSelectModule,
        MatDatepickerModule,
        MatTooltipModule,
        FuseSharedModule,
        LyDialogModule,
        FuseWidgetModule,
        MatGridListModule,
        NgxMaterialTimepickerModule,
        NgxFileDropModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        MatCheckboxModule,
        ColorPickerModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatSliderModule,
        QRCodeModule,
        MatMenuModule,
        MatDialogModule,
        MatToolbarModule
    ],
    exports: [AddTemplateDialogComponent]
})
export class TicketsModule { }  
