import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingLocationService implements Resolve<any> {
  meetingLocations = new BehaviorSubject<any>([])
  maxAvailableRecordsInTable: number;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _CommonService : CommonService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
            this.getAllMeetingLocations({pageSize : 10, pageIndex : 1, search : ''}),
          ]).then(
            () => {
              resolve('');
            },
          reject
        );
    })
  }

  getAllMeetingLocations(body){
    this._fuseProgressBarService.show(); 
    return new Promise((resolve, reject)=>{
      this._httpClient.post(environment.apiURL + 'admin/meeting/getAllMeetingLocations', body, this._CommonService.getHeader()).subscribe((response:any) =>{
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this.meetingLocations.next(response.data.meetingLocations);
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }else{
          this.meetingLocations.next([]);
          this.maxAvailableRecordsInTable = 0;
        }
        resolve(response)
      }, reject)
    })
  }
}
