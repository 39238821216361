import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { FaqService } from 'app/main/faq/faq.service';
import { Validators } from 'ngx-editor';

@Component({
    selector: 'app-add-edit-faq-dialog',
    templateUrl: './add-edit-faq-dialog.component.html',
    styleUrls: ['./add-edit-faq-dialog.component.scss']
})
export class AddEditFaqDialogComponent implements OnInit {
    form: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data,
        public _matDialogRef: MatDialogRef<AddEditFaqDialogComponent>,
        private _fb: UntypedFormBuilder,
        public _FaqService: FaqService,
        private _router: Router,
        public _commonService: CommonService
    ) {
        console.log("_data", _data);


    }

    ngOnInit(): void {
        if (this._data?.isAddCase) {
            this.form = this.createForm();
        } else {
            this.form = this.createForm(this._data?.faqData);
        }
    }

    createForm(data?) {
        console.log("this is data in response ", data);
        return this._fb.group({
            question: [data ? data?.question : '', Validators.required],
            answer: [data ? data?.answer : '', Validators.required]
        })
    }

    onSubmit() {
        console.log("this is form value", this.form);
        console.log("this._data",this._data);
        
        if (this.form.valid) {
            if (this._data?.isAddCase) {
                // this is add case
                const payload = {
                    "question": this.form.get('question').value,
                    "answer": this.form.get('answer').value
                }
                this._FaqService.onAddFAQ(payload).then((result) => {
                    if (result) {
                        this._matDialogRef.close(true);
                    }
                })
            } else {
                console.log("edit case");
                // this is edit case
                const payload = {
                    "question": this.form.get('question').value,
                    "answer": this.form.get('answer').value,
                    "faqId": this._data?.faqData?.id,
                }
                this._FaqService.onUpdateFAQDetails(payload).then((result) => {
                    if (result) {
                        this._matDialogRef.close(true);
                    }
                })
            }
        }
    }
}
