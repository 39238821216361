import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BadgeTemplatesService implements Resolve<any> {
  allCertificate:[] = []; 
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _MatSnackBar : MatSnackBar,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.onGetAllCertificateList({})
      ]).then((res)=>{
        resolve(res)
      }, reject)
    })
  }


  // certificate/getAllCertificateList
  onGetAllCertificateList(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/badge/getAllBadgeList', body, this._commonService.getHeader()).subscribe((res:any)=>{
        this._fuseProgressBarService.hide();
        if (res.status == 200) {
          resolve(res);
          this.allCertificate = res.data;
        }else{
          resolve(res);
          this.allCertificate = [];
          this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000});
        }
      }, reject)
    })
  }
  
}
