import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationComponent } from './notification/notification.component';
import { RouterModule, Routes } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { MatChipsModule } from '@angular/material/chips';
import { NotificationService } from './notification/notification.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { SentNotificationListComponent } from './sent-notification-list/sent-notification-list.component';
import { SentNotificationsListService } from './sent-notification-list/sent-notifications-list.service';

const routes: Routes = [
  {
    path: '',
    component: NotificationComponent,
    resolve: {
      data: NotificationService
    }
  }, 
  {
    path :'sent-list',
    component : SentNotificationListComponent,
    resolve: {
      data: SentNotificationsListService
    }
  }
]

@NgModule({
  declarations: [
    NotificationComponent,
    SentNotificationListComponent, 
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    NgxMatDatetimePickerModule,
    MatCheckboxModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTooltipModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatDialogModule,
    MatMenuModule,
    MatGridListModule,
    MatChipsModule,
    NgxFileDropModule
  ]
})
export class NotificationModule { }
