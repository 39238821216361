import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { AddEditEventService } from '../add-edit-event.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-event-list-dialog',
  templateUrl: './verifier-list-dialog.component.html',
  styleUrls: ['./verifier-list-dialog.component.scss']
})
export class VerifierListDialogComponent implements OnInit {
  dataSource
  displayedColumns: string[] = ['name', 'action'];
  maxAvailableRecordsInTable: any;

  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    public matDialogRef: MatDialogRef<VerifierListDialogComponent>,
    private matSnackBar: MatSnackBar,
    private _commonService: CommonService,
    private addEditEventService:AddEditEventService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    console.log("_data", _data);
    
    this.addEditEventService.getUnassignedVerifier(this._data.eventId).then((result: any) => {
      if (result.status == 200) { 
        this.maxAvailableRecordsInTable = result.data.totalRecords;
        this.dataSource = new MatTableDataSource(result.data.data);
      } else {
        this.maxAvailableRecordsInTable = 0;
        this.dataSource = new MatTableDataSource([]);
      }
    })
  }

  ngOnInit() {
    // console.log(this._data.events)
  }

  onRedirectCreateVerifier(){
    this.matDialogRef.close(false);
    this._router.navigate(["/verifiers/add"]);
  }

  changeAssignStatus(id,status){
    if(status){
      this.unassignEvent(id)
    }else{
      this.onAssignVerifier(id)
    }
  }

  onAssignVerifier(verifierId) {

    const payload = {
      eventId: this._data.eventId,
      verifierId: verifierId
    }

    this._httpClient.post(environment.apiURL + 'admin/event/assign/verifier', payload, this._commonService.getHeader()).subscribe((result: any) => {
      this.matSnackBar.open(result.message, 'OK', {
        verticalPosition: 'bottom',
        duration: 4000
      })
      if (result.status == 200) {
        this.matDialogRef.close(true)
      }
    })
  }

  unassignEvent(verifierId) {
    let payload = {
      verifierId: verifierId,
      eventId: this._data.eventId,
      isAssign:0
    }
   
      this._httpClient.post(environment.apiURL + 'admin/verifier/getbyid/editassignedevent', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this.matSnackBar.open(response.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 4000
          })
          if (response.status == 200)
           this.matDialogRef.close(true)
          
    
        })
  }


  onPageChange(event){
    console.log(event);
    
    this.fetchDataInDialog(this._data.eventId,event.pageIndex+1)
  }

  fetchDataInDialog(eventId?,pageIndex?){

    this.addEditEventService.getUnassignedVerifier(eventId,pageIndex).then((result:any)=>{
        if(result.status == 200){
          this.dataSource.data = result.data.data;
          this.maxAvailableRecordsInTable = result.data.totalRecords;
        }
        else if(result.status == 400){
          this.dataSource.data = []
          this.maxAvailableRecordsInTable = 0;
        }
        else{
          this.dataSource.data = []
          this.maxAvailableRecordsInTable = 0;
        }
    })
  }
}
