import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { AddUserService } from '../add-distributors/add-distributor.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AssignEventService implements Resolve<any>{
  distributorId
  eventsList;
  maxAvailableRecordsInTable=0
  distributor: any;
  constructor(
    private _httpClient: HttpClient,
    private _commonService:CommonService,
    private _AddUserService : AddUserService
  ) { }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("this is route", route.params.id);
    
    this.distributorId = route.params.id
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getDistributor(this.distributorId),
        this.getUpcommingEvent()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );

    });
  }

  getDistributor(id?) {
    const payload = {
        "distributorId": id || this.distributorId
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/distributor/getdistributorbyid', payload ? payload : id, this._commonService.getHeader()).subscribe((response: any) => {
        if (response.status == 200) {
          this.distributor = response.data;
          resolve(response.data)
        }
      }, reject);
    })  
  };

  getUpcommingEvent(filter?) {
    return new Promise((resolve, reject) => {
      const payload = {
        "distributorId": this.distributorId,
      }
      this._httpClient.post(environment.apiURL + 'admin/event/assign/getallevents', filter?filter:payload, this._commonService.getHeader(  )).subscribe((result: any) => {
        if (result.status == 200){
          this.eventsList = result.data.data
          console.log("getUpcommingEvent", result);
          
          this.maxAvailableRecordsInTable = result.data.totalRecords
          console.log("this.maxAvailableRecordsInTable",this.maxAvailableRecordsInTable);
        }else if(result.status == 400){
          this.eventsList = []
          this.maxAvailableRecordsInTable = 0
        }else{
          this.eventsList = []
          this.maxAvailableRecordsInTable = 0
        }
        resolve(result)
      }, reject)
    })
  }

  

}
