import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { ChangePasswordDialogComponent } from '@fuse/Dialog/change-password-dialog/change-password-dialog.component';
import { AuthQrDialog } from '@fuse/Dialog/auth-qr-dialog/auth-qr-dialog';
import { MatDialog } from '@angular/material/dialog';
// import * as CryptoJS from 'crypto-js';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timezones } from '@fuse/utils/timezones';
import { CommonService } from '@fuse/services/common.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    isPassVisible = false;
    showError: boolean = false;
    errorMessage: string = '';
    timeZones = timezones 


    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private _router: Router,
        private _ActivatedRoute: ActivatedRoute,
        private _CommonService : CommonService,
        private _authService: AuthService,
        private _MatSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
    ) {
        localStorage.clear()
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
            password: ['', Validators.required]
        });

        this._ActivatedRoute.queryParams.subscribe((result) => {
            console.log("resultresult", result);
            if (Object.keys(result).length != 0) {
                const tempAdminId = this.decryptData(result['0aoZtInKg4L6c0vRAkGKfA']);
                const tempAdminP = this.decryptData(result['srrV40Vc3bPmf1tS0xX9CQ']);
                if (tempAdminId && tempAdminP) {
                    this.autoConnectIntoDashboard(tempAdminId, tempAdminP);
                }
            }
        })



        if (localStorage.getItem('userLoggedIn') == 'true') {
            this._router.navigate(['/home'])
        }

        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // To reset states
        // localStorage.clear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

    }

    decryptData(string) {
        // let decrypt = CryptoJS.AES.decrypt(String(string).trim(),''.trim()).toString(CryptoJS.enc.Utf8);
        return atob(string);
    }

    /**
   * login user
   * 
   * 
   *  */

    autoConnectIntoDashboard(email, pass) {
        this.loginForm.get('email').setValue(email)
        this.loginForm.get('password').setValue(pass)
        if (this.loginForm.valid)
            this.userLogin();
    }

    userLogin(): void {
        if(this.loginForm.valid){
            this.showError = false;
            const cred = this.loginForm.getRawValue();
            this.loginForm.markAsPending();
    
            // Disable the form
            this.loginForm.disable();
     
            this._authService.userLogin(cred).then((data) => {
                //user name pass correct
                console.log("this is login response", data);
                if (data.status == 200) {
                    this.loginConfirm(data)
                    if (data.data.admin.isPasswordChanged) {
                        console.log("data.data.admin.isAuthRequired", data.data.admin.isAuthRequired);
                        if (data.data.admin.isAuthRequired) {
                            this.openQrDialog(data.data.admin.isAuthySetUp)
                        } else {
                        this._router.navigate(['/home']);
                        }  
                    } else {
                        this.openChangePasswordDialog()
                    }
                }
                else {
                    this.showError = true;
                    this.errorMessage = data.hasOwnProperty('message') ? data.message : 'Wrong Email or Password';
                    this.loginForm.reset();
                    // Disable the form
                    this.loginForm.enable();
                }
                // this._router.navigate(['/dashboard']);
            }).catch((err) => {
                console.log(err);
                this.showError = true;
                this.errorMessage = 'Failed to connect';
                // Disable the form
                this.loginForm.enable(); 
            });
        }
    }

    loginConfirm(data) {
        localStorage.setItem('userToken', data.data.admin.token);
        localStorage.setItem('userLoggedIn', 'true');
        localStorage.setItem('userEmail', data.data.admin.email);
        localStorage.setItem('userName', data.data.admin.name);
        localStorage.setItem('id', data.data.admin.id);
        localStorage.setItem('company', data.data.mySettings.company);
        localStorage.setItem('country', data.data.mySettings.country);
        localStorage.setItem('symbol', data.data.mySettings.symbol);
        localStorage.setItem('isPasswordChanged', data.data.admin.isPasswordChanged);
        localStorage.setItem('isAuthRequired', data.data.admin.isAuthRequired);
        localStorage.setItem('isAuthySetUp', data.data.admin.isAuthySetUp);

        this._authService.setPermissions(JSON.parse(data.data.admin.permission))

        const timeZone:any = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        const locationTimeZone:any = this.timeZones.filter((zone) => zone.utc.find((item) => item == String(timeZone)))[0];
        this._CommonService._selectedTimeZone.next(locationTimeZone)

    }

    openChangePasswordDialog() {

        this._matDialog.open(ChangePasswordDialogComponent, {
            disableClose: true
        }).afterClosed().subscribe((dialogData: any) => {
            if (!dialogData) {
                return false
            } else {
                this.openQrDialog(false)
            }
        });
    }

    openQrDialog(isAuthySetUp) {
        console.log("-----isAuthySetUp->", isAuthySetUp);

        //only pin
        if (isAuthySetUp) {
            console.log("only pin log");
            this._matDialog.open(AuthQrDialog, {
                disableClose: true,
                width: '300px',
                data: {
                    isAuthySetUp: isAuthySetUp
                }
            }).afterClosed().subscribe((dialogData: any) => {
                if (dialogData) {

                }
            });
        } else {
            //pin with qr
            this._authService.register2fa().then((result: any) => {
                if (result.status == 200) {
                    this._matDialog.open(AuthQrDialog, {
                        disableClose: true,
                        data: {
                            qr: result.data,
                            // qr:'https://event-project.s3.amazonaws.com/qrcode/QrCode_1631704233456.png',
                        }
                    }).afterClosed().subscribe((dialogData: any) => {
                        if (dialogData) {
                        }
                    });
                }
            })
        }
    }


}
