import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Validators } from 'ngx-editor';

@Component({
  selector: 'app-snippet-iframe-code',
  templateUrl: './snippet-iframe-code.component.html',
  styleUrls: ['./snippet-iframe-code.component.scss']
})
export class SnippetIframeCodeComponent implements OnInit {

  form : UntypedFormGroup;
  link;
  iframeTag
  constructor(
    private _fb : UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<SnippetIframeCodeComponent>,
    public _matSnakeBar : MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public _data,
  ){
  }
    
  ngOnInit(): void {


    this.form = this.createForm();
    this.setLink()
   

    this.form.get('color1').valueChanges.subscribe((result) => {
      if(result){
        this.setLink()
      }
    })

    this.form.get('color2').valueChanges.subscribe((result) => {
      if(result){
        this.setLink()
      }
    })

  }

  createForm(){
    return this._fb.group({
      link : ['', [Validators.required]],
      color1 : ['',[Validators.required]],
      color2 : ['',[Validators.required]]
    })
  }

  onSubmit(){
    console.log("on form value", this.form);
  }

  setLink(){
    this.link = `https://eventcustomer.gtsinfosoft.com/#/eventDetail?id=${this._data.id}&color1=${this.form.get('color1')?.value ? this.removeHasTag(this.form.get('color1')?.value) : '000000'}&color2=${this.form.get('color2')?.value ? this.removeHasTag(this.form.get('color2')?.value) : '000000'}`
    this.iframeTag = `<iframe style="width: 100%; height: 70vh; border: 0;" src="${this.link}" title="Event"></iframe>`;
    this.form.get('link').setValue(this.iframeTag)
  }

  onRedirect(){
    window.open(this.form.get('link').value);
  }

  removeHasTag(link){
    return link.slice(1, link.length)
  }

}
