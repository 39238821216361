import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { AuthService } from '../pages/auth.service';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class FaqService {

    faqDataList:[];
    permissions;
    maxAvailableRecordsInTable: Number;
    faqId:Number
    faqDetail: any;
    constructor(
        private _commanService: CommonService,
        private _authService: AuthService,
        private _httpClient: HttpClient,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
    ) { }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.permissions = this._authService.getPermissionsForCurrentPage(route);
        return new Promise((resolve, reject) => {
            if(route?.params?.id){
                Promise.all([
                    this.faqId = Number(route.params.id),
                    // this.getFAQDetails({ "faqId": route?.params?.id })
                ]).then(() => {
                    resolve('')
                },
                    reject
                )
            }else{
                Promise.all([
                    this._fuseProgressBarService.show(),
                    this.getFAQListData({pageSize : 10, pageIndex : 1})
                ]).then(() => {
                    resolve('')
                },
                    reject
                )
            }
        })
    }


    getFAQListData(body){
        return new Promise((resolve, reject)=>{
            this._httpClient.post(environment.apiURL + 'admin/getAllFaqQuestion',body, this._commanService.getHeader()).subscribe((result:any) => {
                if(result.status == 200){
                    this.faqDataList = result.data.getFaqList;
                    this.maxAvailableRecordsInTable = result.data.totalRecords;
                    resolve(result)
                }else if(result.status == 201){
                    this.faqDataList = [];
                    this.maxAvailableRecordsInTable = 0;
                    resolve(result)
                }
                else if(result.status == 400){
                    this.faqDataList = [];
                    this.maxAvailableRecordsInTable = 0;
                    resolve(result)
                }else{
                    this.faqDataList = [];
                    this.maxAvailableRecordsInTable = 0;
                    resolve(result)
                }
                this._fuseProgressBarService.hide();
            }, reject)
        })
    }


    onUpdateFAQDetails(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/updateFaqQuestion', body, this._commanService.getHeader()).subscribe((response: any) => {
            this._fuseProgressBarService.hide()
            if(response.status == 200){
                resolve(response);
            }
            this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            resolve(response);
            }, reject);
        });
    }


    onAddFAQ(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/addFaqQuestion', body, this._commanService.getHeader()).subscribe((response: any) => {
            this._fuseProgressBarService.hide()
            if(response.status == 200){
                resolve(response);
            }
            this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            resolve(response);
            }, reject);
        });
    }
    

    getFAQDetails(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/getFaqQuestionById', body, this._commanService.getHeader()).subscribe((response: any) => {
            this._fuseProgressBarService.hide()
            if(response.status == 200){
                console.log("this is faq details according to id", response);
                this.faqDetail = response.data
                resolve(response);
            }else{
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            }
            }, reject);
        });
    }

    ondeleteFaqQuestion(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/deleteFaqQuestion', body, this._commanService.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide()
                if(response.status == 200){
                    resolve(response)
                }
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            }, reject);
        })
    }
}
