import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupsComponent } from './groups/groups.component';
import { RouterModule, Routes } from '@angular/router';
import { GroupsService } from './groups.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { MatGridListModule } from '@angular/material/grid-list';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { AllContactsComponent } from './all-contacts/all-contacts.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { GroupDetailsComponent } from './group-details/group-details.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { FormsModule, ReactiveFormsModule } from '@angular/forms';



const routes: Routes = [
  {
      path: '',
      component: GroupsComponent,
      resolve: {
        data: GroupsService
      },
  },
  {
      path: 'all-contacts',
      component: AllContactsComponent,
      resolve: {
        data: GroupsService
      },
  },
  {
      path: 'add-contacts',
      component: AddContactComponent,
  },
  {
    path: 'edit-contacts/:editId',
    component: AddContactComponent,
    resolve: {
      data: GroupsService
    },
  },
  {
    path: 'group-details/:groupId',
    component: GroupDetailsComponent,
    resolve: {
      data: GroupsService
    },
  },
];



@NgModule({
  declarations: [GroupsComponent, AllContactsComponent, AddContactComponent, GroupDetailsComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatGridListModule,
    GooglePlaceModule,
    MatDialogModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatSelectSearchModule,
		NgxIntlTelInputModule
  ],
  providers: [
    // RouterModule.forChild(routes),
    GroupsService,
],
})
export class GroupsModule { }
