import { Injectable } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'app/main/pages/auth.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class BulkOrderService implements Resolve<any>{
  tickets: any = [];
  permissions: any;
  allSchedule: any = [];
  allTimeSlot: any;
  ticketType: any;
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    public _commonService: CommonService,
    private _router: Router,
    private _matSnackBar: MatSnackBar,
    private _authService: AuthService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllEvents()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );

    });
  }

  /* FOR GETTING ALL EVENT'S */
  getAllEvents() {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallpublishedevents', {}, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          resolve(result)
        } else if (result.status == 400) {
            this._matSnackBar.open(result.message, "OKAY", {duration : 2000})
            resolve(result)
            this._router.navigate(['/orders'])
        }
         else {           
            this._matSnackBar.open(result.message, "OKAY", {duration : 2000})
            reject(result)
            this._router.navigate(['/orders'])
        }
        // this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
      }, reject)
    })
  }

  /* AFTER SELECTE EVENT GETTING TICKET ACCORDING TO EVENT */
  changeEvent(e) {
    console.log("change Event funcation called");
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/tickets/getalltickets', { eventId: e.value, pageSize : 0, pageInde: 0 }, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          resolve(result)
        }
        else if(result.status == 400){
          resolve(result)
        }
         else {
          this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
        }
      }, reject)
    })
  }


  /* CREATE ORDER */
  createBulkOrder(payload) {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/createBulkOrder', payload, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          this._fuseProgressBarService.hide();
          resolve(result)
          this._router.navigate(['/orders'])
        } else {
          reject(result)
        }
        this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
      }, reject)
    })
  }

}
