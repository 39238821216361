import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { OrganiserListService } from 'app/main/masters/organiser/organiser-list/organiser-list.service';
import { SpeakerService } from 'app/main/speaker/speaker.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CropperComponent } from '../cropper/cropper.component';
import { LyDialog } from '@alyle/ui/dialog';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-add-performer',
  templateUrl: './add-performer.component.html',
  styleUrls: ['./add-performer.component.scss']
})
export class AddPerformerComponent implements OnInit {
  form: UntypedFormGroup;
  croppedProfileBase64: any = null;
  hostTypesList:[] = []

  separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    checkNumberStatus = 1; // 1 == not checked, 2 == checking...,  3 == checked but not found, 4 == checked and found.
    preferredCountries: CountryISO[] = [
      CountryISO.UnitedStates,
      CountryISO.UnitedKingdom,
    ];
    defCountryCode:any = 'IN';

    
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _cd : ChangeDetectorRef,
    public _commonService: CommonService,
    private _fb: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<AddPerformerComponent>,
    private _matDialog: MatDialog,
    private _matSnackBar: MatSnackBar,
    private _dialog: LyDialog,
    public _SpeakerService: SpeakerService,
    private _OrganiserListService : OrganiserListService,
  ) { 

    this._SpeakerService.getHostTypes().then((res:any)=>{
      if(res.status == 200){
          this.hostTypesList = res.data.getAllHost;
      }else{
        this.hostTypesList = [];
      }
    })

  }

  ngOnInit(): void {
    this.form = this.createForm();
    this.form.get('mobile').valueChanges.subscribe((result)=> {
      console.log("result", result);

      if(result){
          // allow china 11 digit 
          if((result.countryCode === 'CN')){
              if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
                  this.form.get('mobile').setErrors(null);
              }else{
                  this.form.get('mobile').setErrors({validatePhoneNumber : {valid : false}});
              }
          }

          if(this.checkNumberStatus == 4 || this.checkNumberStatus == 3){
              this.checkNumberStatus = 1;
              this._cd.detectChanges();
          }
      }
  })
  }

  createForm() {
    const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
    return this._fb.group({   
      name: ['', Validators.required],
      image: ['', Validators.required],
      position: [''],
      orgName: [''],
      isCustomer : [''],
      email : ['',[Validators.required, Validators.email]],
      mobile : ['', Validators.required],
      aboutSp: [''],
      hostTypeId : ['', Validators.required],
      instaLink: ['', Validators.pattern(reg)],
      fbLink: ['', Validators.pattern(reg)],
      linkedinLink: ['', Validators.pattern(reg)],
      xLink : ['', Validators.pattern(reg)] 
    })
  }

  onSubmit(){
      const payload = {
        "name": this.form.get('name').value,
        "image":this.form.get('image').value,
        "position":this.form.get('position').value,
        "orgName":this.form.get('orgName').value,
        "hostTypeId" : this.form.get('hostTypeId').value,
        "about":this.form.get('aboutSp').value,
        "instaLink":this.form.get('instaLink').value,
        "fbLink":this.form.get('fbLink').value,
        "xLink" : this.form.get('xLink').value,
        "linkedinLink":this.form.get('linkedinLink').value,
        "isCustomer" : this.form.get('isCustomer').value ? 1 : 0,
        "email" : this.form.get('email').value,
        "mobile": this.form.get('mobile').value?.number.split(" ").join(""),
        "phoneCode" : this.form.get('mobile').value?.dialCode,
      }

      this._SpeakerService.onAddSpeaker(payload).then((result:any) => {
        if(result.status == 200){
          this._matDialogRef.close(true);
          this._matSnackBar.open(result.message, 'OKAY', {duration : 3000});
        }
      })
      
    }
 
    openCropperForProfile(file) {
      this._dialog.open<CropperComponent, any>(CropperComponent, {
        data: {
            fileEvent: event,
            myConfig: {
                width: 250,
                height: 250,
                round : false,
                type: 'image/png',
                keepAspectRatio: false,
                responsiveArea: true,
                output: {
                    // width: 320,
                    // height: 320
                },
                resizableArea: true
            }
        },
        width: 520,
        height : 520,
        disableClose: true
      }).afterClosed.subscribe((result:any) => {
        if (result) {
            console.log("result", result);
            console.log("this is pre event", event); 
            this.croppedProfileBase64 = result.file.dataURL;
            this._cd.markForCheck();
            this.onUploadImage(result.file, result.blobFile)
        }
      });
    }

    // on upload Image presignedURL API
    onUploadImage(fileObj, blobFile) {
        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName": 'profile_',
            "folderName": 'speakerMedia', // it is fix set by backend.
            'mimeType': fileObj?.type
        }
        return new Promise((resolve, reject) => {
            this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
                if (result.status == 200) {
                    this.form.get('image').setValue(result.data.imageUploadUrl);
                    // payload for get Image upload status
                    const payload = {
                        'id': result.data.id,
                        'status': 1 // fix by backend.
                    }
                    this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
                    this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                        if (uploadStatusResponse.status == 200) {
                            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            resolve(true);
                        } else {
                            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            reject();
                        }
                    })
                }
            })
        })
    }

    onRemoveProfile(){
      this.form.get('image').setValue('')
      this.croppedProfileBase64 = null;
    }

}
