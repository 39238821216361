import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { CreateOrderService } from './create-order.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { CommonService } from '@fuse/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { AddBookingCustomerInfoComponent } from '@fuse/Dialog/add-booking-customer-info/add-booking-customer-info.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { ImportContactDialogComponent } from '@fuse/Dialog/import-contact-dialog/import-contact-dialog.component';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { CommonUserSearchSelectDialogComponent } from '@fuse/Dialog/common-user-search-select-dialog/common-user-search-select-dialog.component';
import { CommonInfoDialogComponent } from '@fuse/Dialog/common-info-dialog/common-info-dialog.component';
@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations : fuseAnimations
})
export class CreateOrderComponent implements OnInit { 
  showDetails:boolean = false;
  isBulkOrder:boolean = false;
  form : FormGroup;
  categoriesList:any = [];
  totalAmountWithTaxes:number = 0;
  selectedEvent;
  timeSlot: any;
  allTicketsTypeList = [];
  selectedTicketType: any = undefined;
  duplicateData:any = []
  seatCount = 1;
  dialog;
  paidAs = [
    {id : 0, title : 'Free Pass'},
    {id : 1, title : 'Paid'}
  ]
  membersDetails = [];
  isOrderCreated: boolean = false;
  totalAmount: number;
  ticketPrice: any; 
  ticketLayoutType = 1;
  ticketLayouts = []
  public bulkOrderDataType = [
    {id : 0, title:'Anonymous Ticket'},
    {id : 1, title:'Import Members'},
    // {id : 2, title: 'book for hosts'}
  ]
  base64Image: any;
  combinedPdfData: any = [];

  slotTicket:any = []
  allCountries:any = []
  allStates:any = []
  detectAmount: number;
  allHostMembers :[] = [];
  isHostMemberOrder: boolean = false;
  designationList:[] = [];

  constructor(
    private _activatedRoute : ActivatedRoute,
    private _ToolbarService : ToolbarService,
    private _fuseProgressBarService: FuseProgressBarService,
    public _commonService : CommonService,
    private _AddEditEventService: AddEditEventService, 
    private _matDialog : MatDialog,
    public _CreateOrderService : CreateOrderService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _fb : FormBuilder
  ) { 
    // this.showOrderMessage();

    this.form = this.createForm();

    this._commonService.getAllCountries().then((result:any) => {
      if(result.length)
        this.allCountries = result
        else
        this.allCountries = result
    });
    this._commonService.getStates(0).then((result:any) => {
      this.allStates = result.data;
    })

    // this.setToolBarTitle();
    this._activatedRoute.url.subscribe((res) => {
        if(res[0].path == 'create-order'){
          this.isBulkOrder = false;
          this.setToolBarTitle(false);
        }else{
          this.isBulkOrder = true;
          this.setToolBarTitle(false);
          this.form.get('isBulkMembers').setValidators(Validators.required);
          // this.form.get('isBulkMembers').setValue('1')
        }
    })
    

    // handle form Value and validation
    if(!this.isBulkOrder){
      this.form.controls.totalTickets.clearValidators();
      this.form.controls.totalTickets.updateValueAndValidity();
    }else{
      this.form.controls.totalTickets.setValidators(Validators.required);
      this.form.controls.totalTickets.updateValueAndValidity();
    }

    this._activatedRoute.queryParams.subscribe((res) => {
      if(res?.eventId){
        this.form.get('selectedEvent').setValue(res.eventId);
        this.form.get('selectedEvent').disable();
        this.onEventSelect(res?.eventId);
        if(this.isBulkOrder){
          this.setToolBarTitle(true);
        }
      }
    })

  }

  // In your component.ts
  isValidEmail(email: string): boolean {
    // const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    const emailRegex = /^[^A-Za-z0-9._%-]+@[^A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    // console.log("emailRegex ---> ", !emailRegex.test(email));
    return !emailRegex.test(email);
  }

  setToolBarTitle(isBtnShow){
    if(this.isBulkOrder){
      // for title
      this._ToolbarService._ToolBarTitle.next('Create Bulk Sales Order');
      // for btn
      this._ToolbarService._isBackBtnShow.next(isBtnShow);
    }else{
      // for title
      this._ToolbarService._ToolBarTitle.next('Create Sales Order');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    }
  }

  

  ngOnInit(): void {
    this.getAmountByTotalTicket();
    
    this.form.get('ticketType').valueChanges.subscribe((res)=> {
      if(res){
        console.log("res", res);
        console.log("this.allTicketsTypeList", this.allTicketsTypeList);
        
        this.allTicketsTypeList.forEach((type) => {
          if(type.id == Number(res)){
            console.log("type", type); 
              this.ticketPrice = type.price;
              this.totalAmount = this.ticketPrice;
              this.selectedTicketType = type;
              if(!this.selectedTicketType.totalAvailableSeats){
                this._matSnackBar.open('There are no available seats for ordering in the selected event type and time slot.', 'OKAY', {duration : 5000})
                this.selectedTicketType = null;
                this.form.get('ticketType').setValue('');
              }
              // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
              //   if(this.selectedEvent?.taxInclude){
              //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
              //   }else{
              //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
              //   }
              // }
          }
        });

      }
    })
  }

  // for pdf and shows tickets
  onTicketTypeChange(){
    
  }

  ImagePrint(url) {
    // window.o
  }

  openSnackBar(message, action){
    this._matSnackBar.open(message, action, {duration : 2000})
  }
  
  createForm(){
    return this._fb.group({
      selectedEvent : ['', Validators.required],
      selectedDate  : ['', Validators.required],
      timeSlot : ['', Validators.required],
      ticketType : ['', Validators.required],
      isFreePass : ['', Validators.required],
      totalTickets : ['', Validators.required],
      isBulkMembers : [''],
      canCreateCustomer : [''],
      anonymous : ['']
    })
  }

  downloadImage(data) {  
  }
 

  downloadSampleFile(){
    window.open('../../../assets/sample-file/example-members.xlsx');
  }
  

  // EVENT SELECTION
  onEventSelect(value){
    this.selectedTicketType = null;
    this.timeSlot = null;
    this.seatCount = 1;
    this._fuseProgressBarService.show();
    this.membersDetails = [];
    this.totalAmount = 0;
    this.ticketPrice = 0;
    this._AddEditEventService.getEvent(value).then((res: any) => {
      if (res.status == 200) {
        this._fuseProgressBarService.hide(); 
        this.selectedEvent = res.data;  
        this.showDetails = true;
        let eventCate = [];
        this.selectedEvent.categoriesList.forEach((item) => eventCate.push(item.title));
        this.categoriesList = eventCate.join(', ');
        this.getAllTicketByEventId();
        if(this.selectedEvent.isFree){
          this.form.get('isFreePass').setValue('0')
          this.form.get('isFreePass').disable();
        }else{
          this.form.get('isFreePass').setValue('1')
          this.form.get('isFreePass').enable();
        }
      }
    });
  }
 
  onSelectEventDate(value){
    let selectedDate =  this.selectedEvent?.eventSchedule.filter(item => item.eventStartDate === value)[0]
    this.form.controls.selectedDate.setValue(selectedDate.eventStartDate)
    this.form.updateValueAndValidity();
    this.timeSlot = selectedDate.timeSlot;
  }

  // calculateTotalAmount(price, taxPercentages) {
  //   this.totalAmountWithTaxes = 0;
  //   console.log("this.totalAmount", this.totalAmount);
  //   console.log("taxPercentages", taxPercentages);
  //   // Calculate total tax amount for each tax percentage
  //   taxPercentages.forEach(item => {
  //     const taxDecimal = item.taxRate / 100;
  //     const taxAmount = price * taxDecimal;
  //     this.totalAmountWithTaxes += taxAmount;
  //   });
  
  //   // Calculate the total amount including all taxes
  //   this.totalAmountWithTaxes = price + this.totalAmountWithTaxes;
  //   this.totalAmountWithTaxes = this.membersDetails.length ? Math.round(this.membersDetails.length * this.totalAmountWithTaxes) : this.totalAmountWithTaxes;
  //   console.log("totalAmountWithTaxes", this.totalAmountWithTaxes);
    
  // }

  getTotalAmount(cases) {
    if(cases == 'ticketAmount'){
      // console.log("for ticket");
      if(this.selectedEvent.taxInclude == 1){
        // include
        let price = this.membersDetails.length * this.ticketPrice;
        this.selectedEvent.taxList.forEach(item => {
          const taxAmount = price * (item.taxRate / 100);
          price -= taxAmount;
        });

        return Math.round(price).toFixed(2);
      }else{
        return this.membersDetails.length * this.ticketPrice; 
      }
    }else{
      // console.log("for total amount");
      if(this.selectedEvent.taxInclude == 1){ 
        // include
        return this.membersDetails.length * this.ticketPrice; 
      }else{ 
        // not include
        let totalTaxAmount = 0;
        let price = this.membersDetails.length * this.ticketPrice;

        // Calculate total tax amount for each tax percentage
        this.selectedEvent.taxList.forEach(item => {
          const taxDecimal = item.taxRate / 100;
          const taxAmount = price * taxDecimal;
          totalTaxAmount += taxAmount;
        });

        // Calculate the total amount including all taxes
        const totalAmount = price + totalTaxAmount;
        // console.log("totalAmount", totalAmount);
        return totalAmount; 
      }
    }
  }


  // eventTypeSalect(event){
  //   console.log("type value",event.value);
  //   console.log("this.allTicketsTypeList", this.allTicketsTypeList);
  //   // this.selectedTicketType = this.allTicketsTypeList.filter((type) => type.id === event.value)[0];
  //   // this.ticketPrice = this.selectedTicketType.price;
  //   // this.totalAmount = this.selectedTicketType.price;
  // }

  getAllTicketByEventId(){
    console.log("getAllTicketByEventId called");
    const payload = {
      "eventId": this.selectedEvent.id,
      "pageSize": 0,
      "pageIndex": 0
    }
    this._AddEditEventService.getAllTickets(payload).then((res:any)=> {
      if(res.status == 200){
        console.log("this is all ticket for this event", res.data.data);
        this.allTicketsTypeList = res.data.data;
      }else{
        this.allTicketsTypeList = [];
      }
    })
  }

  removeSeatCount(){
    if(this.membersDetails.length === this.seatCount){
      this._matSnackBar.open('After adding members, you cannot decrease the member count. You can only reduce the count by removing members.',"Got it", {duration : 4000})
    }else{
      if(1 < this.seatCount){
        this.totalAmount -= this.ticketPrice;
        // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
        //   if(this.selectedEvent?.taxInclude){
        //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
        //   }else{
        //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
        //   }
        // }
        this.seatCount -= 1;
      }
    }
  }
  
  addSeatCount(){
    if(this.selectedTicketType.memberPerTicket > this.seatCount){
      this.totalAmount += this.ticketPrice;
      // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
      //   if(this.selectedEvent?.taxInclude){
      //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
      //   }else{
      //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
      //   }
      // }
      this.seatCount += 1;
    }
  }
  

  onAddCustomerDetails(edit?){
    console.log("this is selected event", this.selectedEvent);
    console.log("this is selected Ticket Type", this.selectedTicketType);
    console.log("this.selectedTicketType.customerInfoType", this.selectedTicketType.customerInfoType);
    console.log("this.seatCount", this.seatCount);
    
    this.dialog = this._matDialog.open(AddBookingCustomerInfoComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose : true,
      width : '682px',
      data : {
        seatCount : edit ? this.membersDetails.length : this.seatCount - this.membersDetails.length,
        customerInfoType : this.selectedTicketType.customerInfoType,
        dynamicNames : this.selectedEvent.customerFieldList,
        countries : this.allCountries,
        allStates : this.allStates,
        members :  edit ? this.membersDetails : [],
        isBulkOrder : this.isBulkOrder,
        isHostMemberOrder : this.isHostMemberOrder
      }
    })

    this.dialog.afterClosed().subscribe((res)=> {
      if(res?.length){
        if(this.isBulkOrder){
          this.membersDetails = res; 
          // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
          //   if(this.selectedEvent?.taxInclude){
          //     this.calculatePriceFromTotal()
          //   }else{
          //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
          //   }
          // }
        }else{
          if(edit){
            this.membersDetails = res;
          }else{
            if(this.membersDetails.length){
              this.membersDetails.push(...res);
              // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
              //   if(this.selectedEvent?.taxInclude){
              //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
              //   }else{
              //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
              //   }
              // }
            }else{
              this.membersDetails = res;
              // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
              //   if(this.selectedEvent?.taxInclude){
              //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
              //   }else{
              //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
              //   }
              // }
            }
          }

        }
      }
    })
    
  }
  

  getAmountByTotalTicket(){
    if(this.form.controls.isFreePass.value == 1){
      this.form.controls.totalTickets.valueChanges.subscribe((res)=>{
        if(res){
          this.ticketPrice = this.ticketPrice * Number(res);
          console.log("this.ticketPrice * Number(res);", this.ticketPrice * Number(res));
        }
      })
    }
  }

  placeOrder(){
    console.log("form", this.form);

    this.form.disable();
    this._fuseProgressBarService.show();
    const payload = {};
    payload['eventId'] = this.selectedEvent.id,
    payload['totalAmount'] = this.membersDetails.length ? this.ticketPrice * this.membersDetails.length : this.totalAmount,
    payload['paymentMethod'] = 1,
    payload['isHostOrder'] = this.isHostMemberOrder ? 1 : 0,
    payload['canCreateCustomer'] = this.form.get('canCreateCustomer').value ? 1 : 0,
    payload['isAnonymous'] = !this.isBulkOrder ? this.form.controls.anonymous.value ? 1 : 0 :this.form.controls.isBulkMembers.value == 0 ? 1 : 0,
    payload["isBulkOrder"] = this.isBulkOrder ? 1 : 0,
    payload['isBulkMembers'] = this.isBulkOrder && this.form.get('isBulkMembers').value && this.membersDetails.length ? 1 : 0,
    payload['isFreePass'] = this.form.get('isFreePass').value == 0 ? 1 : 0,
    payload['memberCount'] = this.isBulkOrder ? this.form.controls.isBulkMembers.value == 1 ? this.membersDetails.length: this.form.controls.totalTickets.value : this.form.controls.anonymous.value ? this.seatCount : this.membersDetails.length;
   
    if(this.membersDetails.length){
      this.membersDetails.map((item) => {
        if(item?.mobile?.number){
          item['mobile'] = item.mobile.number.split(" ").join("");
        }
      })
      console.log("after change member details", this.membersDetails);
    } 
    payload['ticketDetail'] = [
      {
        "ticketId" : this.selectedTicketType.id,
        "ticketAmount" : this.ticketPrice,
        "slotId" : this.form.controls.timeSlot.value,
        "members" : this.form.controls.anonymous.value ? [{}] : this.membersDetails.length ? this.membersDetails : [{}],
      }
    ]
    console.log("this is the final payload", payload)
    this._CreateOrderService.createOrder(payload).then((res:any) => {
      if(res.status == 200){
        this.setToolBarTitle(true)
        console.log("this is create order api res", res);
        this.isOrderCreated = true; 

        // for Normal
        if(!this.isBulkOrder){
 
          if(res.data?.splitedPdfData?.length){
            res.data?.splitedPdfData?.forEach((item) => {
              this.ticketLayouts.push(item.pdfData)
            })
          }else{
            this.ticketLayouts.push(res.data.combinedPdfData)
          } 

        }
        // for bulk
        else{ 
          this.showOrderMessage();
          // if(res.data?.anonymousData?.length){
          //   res.data?.anonymousData?.forEach((item) => {
          //     this.ticketLayouts.push(item.splitedPdfData[0]?.pdfData);
          //   })
          // }

          // if(res.data?.orderBookedMembers?.length){
          //   res.data?.orderBookedMembers.forEach((item) => {
          //     this.ticketLayouts.push(item.splitedPdfData[0]?.pdfData);
          //   })
          // }

          // if(res.data?.duplicateData?.length){
          //   this.duplicateData = res.data.duplicateData;
          // }



        }
        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
      }else{
        this.form.enable();
        this.isOrderCreated = false;
        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
      }
      this._fuseProgressBarService.hide();
    })
  }

  showOrderMessage(){
    const dialog = this._matDialog.open(CommonInfoDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      data : {
        heading : 'Order placed',
        message : `Your order has been placed. The PDF tickets for the members will be created within 5 to 10 minutes. You will be able to access the members' ticket PDFs under the 'All Bookings' tab.`,
        action : 'OKAY'
      }
    })

    dialog.afterClosed().subscribe((res)=>{
        this._router.navigate(['/orders'])
    })
  }

  onSelectEventSlot(value){
    const allTickets = this._AddEditEventService.allTickets;
    this.slotTicket = allTickets.filter((item) => item.slotId == value)
    console.log("--->", this.slotTicket);
  }


  onPrint(pdfLink) {
    if(pdfLink){
    let pdfLinkGen = pdfLink;
      const printWindow = window.open('', '', 'height="100vh",width="100%"');
      printWindow.document.write('<body style="margin:0; padding:0;"><iframe src="' + pdfLinkGen +'" height="100%" width="100%"  type="pdf"></iframe></body>')
    }
  }

  onDownload(link: any) {
    const imageURL = link
    fetch(imageURL).then(response => response.blob()).then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'image.jpg'; // You can change the file name here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(error => console.error('Error downloading image:', error));
  }
  

 
  onDeleteMember(i){
    this.membersDetails.splice(i, 1);
    this.totalAmount = 0;
    this.totalAmount = this.ticketPrice;
    this.totalAmount = this.membersDetails.length ? Math.round(this.membersDetails.length * this.totalAmount) : this.totalAmount;
    // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
    //   if(this.selectedEvent?.taxInclude){
    //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
    //   }else{
    //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
    //   }
    // }
  }

  openImportContectDialog(){
    let mapFieldsNames = [];
    if(!mapFieldsNames.length){
      mapFieldsNames = JSON.parse(JSON.stringify(this.selectedEvent.customerFieldList.filter((item) => item.isAdded)));
      const uniqueField = {
        id : 1,
        isAdded : 1,
        isDelete : 0,
        isDisable : false,
        isRequired : 1,
        title : "unique Id",
        createField : "udId",
        name : "udId",
      }
      console.log("uniqueField", uniqueField);
      mapFieldsNames.unshift(uniqueField);
      console.log("mapFieldsNames ===>", mapFieldsNames);
      mapFieldsNames.map((item) => {
        item['name'] = item['name'] ? item['name'] : item.title;
        item['createField'] = item['createField'] ? item['createField'] : item.title;
      })
    }
    this._matDialog.open(ImportContactDialogComponent, {
      panelClass: ['addGroup', 'commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '68%',
      // hasBackdrop : false,
      minHeight: '230px',
      autoFocus : false,
      data : {
        feilds : mapFieldsNames,
        for : 'isForOrder',
        maxIndex : this.selectedTicketType.totalAvailableSeats,
      }
    }).afterClosed().subscribe((uploadStatus) => {
      if(uploadStatus){
        this.membersDetails = [];
        // email validation checked, if some are not validate then put dose email listing on top
        const isEmailRequired = mapFieldsNames.filter((item) => item.name == 'email' ? item.isAdded : null);
        if(isEmailRequired.length){
          uploadStatus = this.mapEmailsToObjects(uploadStatus);
          this.membersDetails = uploadStatus.sort((a, b) => (a.isValid === b.isValid ? 0 : a.isValid ? 1 : -1));
        }else{
          this.membersDetails = uploadStatus;
        }

        this.getCountryId();
        this.getStateId();
        this.getPhoneCodeAndCountryCode();
        this.getGenderId();

        console.log("after country id added -->", this.membersDetails);
        this.totalAmount = 0;
        this.totalAmount = this.ticketPrice;
        this.totalAmount = this.membersDetails.length ? Math.round(this.membersDetails.length * this.totalAmount) : this.totalAmount;
        // if(this.selectedEvent?.taxApplicable && this.selectedEvent.taxList?.length){
        //   if(this.selectedEvent?.taxInclude){
        //     this.calculatePriceFromTotal(this.totalAmount, this.selectedEvent.taxList)
        //   }else{
        //     this.calculateTotalAmount(this.totalAmount, this.selectedEvent.taxList)
        //   }
        // }
      }
    })
  }

  
  getCountryId(){ 
    this.membersDetails.forEach((item) => { 
      if(item.country){
        const countryName = String(item.country).toLowerCase();  
        const allCountries = this.allCountries;
        let filledCountry = allCountries.filter((country) => String(country.name).toLowerCase() == countryName);
        if(filledCountry.length){
          item['countryTitle'] = filledCountry[0].name;
          item['country'] = filledCountry[0].id;
        } 
      }
    })
  }

  
  getStateId(){ 
    this.membersDetails.forEach((item) => { 
      if(item.state){
        const stateName = String(item.state).toLowerCase();  
        const allState = this.allStates;
        let filledState = allState.filter((country) => String(country.name).toLowerCase() == stateName);
        if(filledState.length){
          item['stateTitle'] = filledState[0].name;
          item['state'] = filledState[0].id;
        } 
      }
    })
  }
  
  getPhoneCodeAndCountryCode(){
    this.membersDetails.forEach((item) => {
      if(item.mobile){
        const isNumberStartWithPlus = !String(item.mobile).startsWith('+') && String(item.mobile).includes(' ');
        const parsedNumber = parsePhoneNumberFromString(String(isNumberStartWithPlus ? '+' + item.mobile : item.mobile));
        console.log("parsedNumber", parsedNumber);        
        if(parsedNumber){
          item['phoneCode'] =  parsedNumber.countryCallingCode;
          item['countryCode'] = parsedNumber.country;
          item['mobile'] = parsedNumber.nationalNumber;
        }else{
          console.log('Invalid phone number');
        }
      }
    })
  }

  getGenderId(){
    this.membersDetails.forEach((member)=> {
      if(member.gender){
        member['gender'] = String(member['gender']).toLowerCase() == 'male' ? '1':'2';
      }
    })
  }

  mapEmailsToObjects(uploadStatus) {
    return uploadStatus.map(item => ({ 
        ...item, isValid: this.isValidEmail(item.email)
     }));
  }

  onChangePreference(event){
    console.log("event", event.value);
    if(!this.designationList?.length && event.value == '2'){
        this.getAllHostDesignation();
    }
  }

  getAllHostDesignation(){
    const payload = {
      pageSize :0,
      pageIndex : 0
    };
    this._commonService.getAllDesignation(payload).then((res:any)=>{
      if(res.status == 200){
        this.designationList = res.data.getAllHost;
      }else{
        this.designationList = [];
      }
    })
  }

  onChooseHost(){
    if(!this.allHostMembers.length){
      this.getAllHostMembers()
    }else{
      const dialog = this._matDialog.open(CommonUserSearchSelectDialogComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        width: '400px',
        data: {
          data: this.allHostMembers,
          designationList : this.designationList.length ? this.designationList : [],
          title: `Select Host for place order`,
          btn_primary: 'Continue',
        },
      })

      dialog.afterClosed().subscribe((res)=>{
        if(res?.length){
          this.isHostMemberOrder = true;
          this.membersDetails = res;
        }
      })
    }
  }

  getAllHostMembers(){
    this._fuseProgressBarService.show();
    this._commonService.getAllSpeaker({pageSize : 0, pageIndex : 0}).then((res:any)=>{
      if(res.status == 200){
        this.allHostMembers = res.data.data;  
        this.onChooseHost();   
      }else{
        this.allHostMembers = [];
        this._matSnackBar.open(res.message, 'OKAY', {duration: 2000})
      }
    })
  }

} 

