import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SponsorListComponent } from './sponsor-list/sponsor-list.component';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { SponsorService } from './sponsor.service';
import { SponsorGroupsComponent } from './sponsor-group/sponsor-groups/sponsor-groups.component';

const routes: Routes = [
  {
    path: '', 
    component: SponsorListComponent,
    resolve: {
      data: SponsorService
    }
  },

  {
    path: 'sponsor-groups', 
    component: SponsorGroupsComponent,
    resolve: {
      data: SponsorService
    }
  },
]


@NgModule({
  declarations: [
    SponsorListComponent,
    SponsorGroupsComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
    FuseSharedModule,
    MatDialogModule,
    MatToolbarModule,
  ]
})
export class SponsorModule { }
