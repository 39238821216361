import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-certificate-dialog',
  templateUrl: './choose-certificate-dialog.component.html',
  styleUrls: ['./choose-certificate-dialog.component.scss']
})
export class ChooseCertificateDialogComponent implements OnInit {

  certificates:[] = []
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<ChooseCertificateDialogComponent>,
  ) { 
    this.certificates = this._data.certificates;

    if(this.certificates.length)
    this.certificates.map((item:any) => item['isSelected'] = item?.isSelected == 1 ? 1 : 0)


    console.log("this.certificates", this.certificates);
    
  }

  ngOnInit(): void {
  }

  onSelect(templateId){
    this.certificates.map((item:any)=> item.id == templateId ? item.isSelected = 1 : item.isSelected = 0)

    console.log("this.certificates", this.certificates);
  }

  itemUnSelected(){
    return !(this.certificates.some((item:any) => item.isSelected == 1))
  }
  
  onSave(){
    let selectedItem = this.certificates.filter((item:any) => item.isSelected == 1)
    this._matDialogRef.close(selectedItem[0])
  }

}
