import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router, RouterLinkActive, Routes } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";
import { AddEditEventService } from "../edit-event/add-edit-event.service";
import { take } from "rxjs/operators";

@Component({
    selector: "app-event-view-tabs",
    templateUrl: "./event-view-tabs.component.html",
    styleUrls: ["./event-view-tabs.component.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class EventViewTabsComponent implements OnInit, OnDestroy {
    tabsData = [{ title: "Event Info", selector: "app-view-event" }];
    selectedIndex: number;
    eventData;
    navLinks = [
        {
            label: "Event Info",
            link: "./info",
            index: 0,
        },
        {
            label: "Registration",
            link: "./registration",
            index: 1,
        },
        {
            label: "Tickets",
            link: "./tickets",
            index: 2,
        },
        {
            label : 'Tracks',
            link : './tracks',
            index : 3
        },
        {
            label: "Polls & Surveys",
            link: "./polls&surveys",
            index: 4,
        },
        {
            label: "Networking",
            link: "./network",
            index: 5,
        },
        {
            label: "Social Share",
            link : "./social-share",
            index: 6
        },
        {
            label: "QR Access Report",
            link: "./ticket-scanning-report",
            index: 7,
        },
    ];

    constructor(
        public _ToolbarService: ToolbarService,
        private _activatedRoute: ActivatedRoute,
        public _addEditEventService: AddEditEventService, 
        private _router: Router
    ) {
        this.setToolBarTitle();
    }
   

    ngOnInit(): void {
        console.log("_addEditEventService");
        this.eventData = this._addEditEventService.eventData;
        console.log("====> this.eventData", this.eventData);
        // this._router.events.subscribe((res) => {
        //     this.selectedIndex = this.navLinks.indexOf(
        //         this.navLinks.find((tab) => tab.link === "." + this._router.url)
        //     );
        // }); 
    }
  
    setToolBarTitle() {
        this._ToolbarService._ToolBarTitle.next("Event Details"); 
        this._ToolbarService.routerURL = '/events';
        this._ToolbarService._isBackBtnShow.next(true);
    }

    ngOnDestroy(): void { 
        console.log("event tab destroyed");
        // this._addEditEventService.eventData = null;
        // this._addEditEventService.eventId = null;
        // this._addEditEventService.isRegistrationData = [];
        // this._addEditEventService.allVenues = null;
        // this._addEditEventService.eventTypes = null;
        // this._addEditEventService.totalEvents = null;
        // this._addEditEventService.allOrganisers = null;
        // this._addEditEventService.eventCategory = null;
        // this._addEditEventService.allGuest = null;
        // this._addEditEventService.ticketData = null;
        // this._addEditEventService.totalticket = null;
        // this._addEditEventService.maxAvailableRecordsInTable = null;
    }
    
}
//
