import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-invitation-template-dialog',
  templateUrl: './invitation-template-dialog.component.html',
  styleUrls: ['./invitation-template-dialog.component.scss']
})
export class InvitationTemplateDialogComponent implements OnInit {

templateSalected: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<InvitationTemplateDialogComponent>,
  ){
  }

  ngOnInit(): void {
  }

  onSelectTemplate(id){
    this.templateSalected = true;
    this.data.templates.map((template:any) => template.isShow  = template.id  == id ? true : false)
  }

  
  selectionSubmit(){
    this.dialogRef.close(this.data.templates);
  }

}
