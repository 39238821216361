import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { NotificationService } from './notification.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit, OnDestroy {

  form: FormGroup;
  eventList = [];
  filtered = {
    pageIndex: 1,
    pageSize: 10,
    search: "",
    eventId: ""
  }

  dataSource;
  displayedColumns = ["select", "fullName", "email", "mobileNo", "city"];
  maxAvailableRecordsInTable;
  // selection = new SelectionModel<any>(true, []);
  locationData: any[] = [];
  selectedUsers: any[] = [];
  selection = new SelectionModel(true, this.selectedUsers);
  userList: any[] = [];
  categories: any[] = [];
  checkboxes: any = {};
  checked: boolean;
  isIndeterminate: boolean;
  hasSelectedUsers: boolean;
  schedule

  currentDate = new Date();


   // Private
   private _unsubscribeAll: Subject<any>;


  constructor(
    private _fb: FormBuilder,
    private _CommonService : CommonService,
    private _matSnackBar : MatSnackBar,
    private _ToolbarService: ToolbarService,
    private _router : Router,
    public _notificationService: NotificationService,
  ) {
    this.schedule = [{'name': 'Send Immediately','value':'1'},{'name': 'Specify Date and Time','value':'2'}];
    // this.schedule = [{'name': 'Send Immediately','value':'1'}];



    this.setToolBarTitle();


    this.eventList = this._notificationService.eventList;

    // console.log("_notificationService.eventList =---> ", this._notificationService.eventList);

    if (this.eventList?.length) {
      this.eventList = this.eventList.filter((item: any) => item.isPublish && item.eventStatus != 7 && item.totalBooked > 0);
      console.log("this.eventList", this.eventList);
    }

    this.createForm();

    this._unsubscribeAll = new Subject();

  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._notificationService.userList);
    this.maxAvailableRecordsInTable = this._notificationService.maxAvailableRecordsInTable;

    this._notificationService.onUserListChanged
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe(users => {
                this.userList = users;
                this.selectedUsers = this.getSelectedUser();
                this.hasSelectedUsers = false;

                /** check if selected  user set in local storage */
                if(this.selectedUsers.length==0)
                {
                    this.checkboxes = {};
                    users.map(contact => {
                        this.checkboxes[contact.id] = false;
                    });
                }
              })
              
  }

       /**
     * On destroy
     */
       ngOnDestroy(): void
       {
           // Unsubscribe from all subscriptions
           this._unsubscribeAll.next();
           this._unsubscribeAll.complete();
           this.removeSelectedUsers();
       }

  setToolBarTitle() {
    // for title
    this._ToolbarService._ToolBarTitle.next('Create Notifications');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  createForm() {
    this.form = this._fb.group({
      title: ['', Validators.required],
      msg: ['', Validators.required],

      schedule : ['', Validators.required],
      scheduleDateTime : [''],

      eventBasedNotification: [''],
      eventId: [''],
      
      search: [''],
    })
  }

  /**
   *  get selected user from localstorage
   * **/
  getSelectedUser() {
    if (localStorage.getItem('selectedUsers') != null) {
      JSON.parse(localStorage.getItem('selectedUsers')).map(contact => {
        this.checkboxes[contact] = true;
      });
    }
    return localStorage.getItem('selectedUsers') == null ? [] : JSON.parse(localStorage.getItem('selectedUsers'));
  }

  clearSearch() {
    this.form.get('search').setValue('');
    this.filtered.search = '';
    this.filterData();
  }

  onSearchUser(value) {
    this.filtered.search = value;
    this.filterData();
  }

  onSelectEvent(event) {
    this.filtered.eventId = event.value;
    this.filterData();
  }

  onPageChange(event) {
    this.filtered.pageIndex = event.pageIndex + 1;
    this.filtered.pageSize = event.pageSize;
    console.log("selected --===-----==>", this.selection.selected);
    console.log("isAllSelected", this.isAllSelected());
    this.filterData();
    if (this.isAllSelected()) {

    }
  }

  isEventBased() {
    if (this.form.get('eventBasedNotification').value) {
      this.form.get('eventId').setValidators(null);
      this.form.get('eventId').setValue('');
      this.form.get('eventId').updateValueAndValidity()
      if (this.filtered.eventId) {
        this.filtered.eventId = '';
        this.filterData(); 
      }
    }else{
      this.form.get('eventId').setValidators(Validators.required);
      this.form.get('eventId').setValue('');
      this.form.get('eventId').updateValueAndValidity();
    }
  }

  filterData() {
    this._notificationService.getUserList(this.filtered).then((res: any) => {
      if (res.status == 200) {
        this.dataSource.data = this.addNewKey(res.data.userList);
        this.maxAvailableRecordsInTable = res.data.totalRecords;
      } else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }


  addNewKey(array) {
    array.forEach((obj) => {
      obj['checked'] = false;
    });
    return array;
  }

  onPrint(){
    console.log("form", this.form);
    
  }

  /**
      * Selects or deselects all users
      * @param e 
      * 
      */
  eventALL(e: MatCheckboxChange) {
    console.log('e :', e);
    console.log(this.selectedUsers)
    if (this.selectedUsers.length > 0 && !e.checked) {

      this.deselectAllUsers();
    }
    else if (this.selectedUsers.length > 0 && e.checked) {

      this.selectAllUsers();

    } else {

      this.selectAllUsers();
    }

  }


  /**
* Deselect all users
*/
  deselectAllUsers(): void {
    this.selectedUsers = [];

    for (let id in this.checkboxes) {

      this.checkboxes[id] = false;

    }
    this.selectAllCheckBoxState();
    console.log('this.selectedUsers ALLDelselect :', this.selectedUsers);


  }


  /**
* Selects ALL Users
*/

  selectAllUsers(): void {
    this.selectedUsers = [];
    this.selectAllCheckBoxState();

    //Change all Check Boxes

    for (let id in this.checkboxes) {

      this.checkboxes[id] = true;

    }

    // Push all to selected Users List
    this.userList.map(user => {
      this.selectedUsers.push(user.id);
    });

    console.log('this.selectedUsers AllSelect :', this.selectedUsers);


  }



  /**
   * Toggle selected contact by id
   *
   * @param id
   */
  selectionChanged(user): void {
    // debugger;
    // First, check if we already have that contact as selected...
    if (this.selectedUsers.length > 0) {
      const index = this.selectedUsers.indexOf(user);

      if (index !== -1) {
        this.selectedUsers.splice(index, 1);

        // Trigger the next event
        this.checkBoxStuff(user);
        this.selectAllCheckBoxState();
        this.storeInlocalStorage(this.selectedUsers);
        console.log('this.selectedUsers SelectionRemoved :', this.selectedUsers);


        // Return
        return;
      }
    }



    // If we don't have it, push as selected
    this.selectedUsers.push(user);

    this.checkBoxStuff(user);
    this.selectAllCheckBoxState();

    this.storeInlocalStorage(this.selectedUsers)

    console.log('this.selectedUsers SelectionADDED :', this.selectedUsers);

  }


  checkBoxStuff(user) {
    // for ( let id in this.checkboxes )
    // {
    //   if ( !this.checkboxes.hasOwnProperty(id) )
    //   {
    //     continue;
    //   }

    // }
    this.checkboxes[user] = this.selectedUsers.includes(user);


    console.log('this.checkboxes :', this.checkboxes);

  }


  /** 
   * Maintains State of Select All CheckBox
   */

  selectAllCheckBoxState() {
    this.hasSelectedUsers = this.userList.length == this.selectedUsers.length
    this.isIndeterminate = (this.selectedUsers.length !== this.userList.length && this.selectedUsers.length > 0);
  }

  /****
 *  store in localstorage
 * **/
  storeInlocalStorage(selectedUsers) {
    this.removeSelectedUsers();
    localStorage.setItem('selectedUsers', JSON.stringify(selectedUsers));
  }


  removeSelectedUsers() {
    localStorage.removeItem('selectedUsers');
  }


  sendNotification(type){
    this.form.markAsPending();
    const payload = {
      "notificationType": 1, // 1 == push notification
      "userType": 5, // 5 == customer
      "eventId": this.form.get('eventId')?.value ? this.form.get('eventId')?.value : 0,
      "search": this.form.get('search').value,
      "title": this.form.get('title').value,
      "msg": this.form.get('msg').value,
      "sendNotiTo": type,
      "isSchedule": this.form.get('schedule').value,
      "scheduleDate": this.form.get('schedule').value == 2 ? new Date(this.form.get('scheduleDateTime').value).getTime(): new Date(this.currentDate).getTime(),
      "userIdsArray": []
    }
    
    if(type == '3'){
      this.selectedUsers.forEach((item) => payload.userIdsArray.push(item));
    }

    this._CommonService.sendNotification(payload).then((res:any)=>{
      if(res.status == 200){
        this.form.markAsPending();
        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000});
        this.form.reset();
        this._router.navigate(['/notification/sent-list']);
      }else{
        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000});
      }
    })
  }

  onChangeScheduleType(event){
    console.log("value", event.value);
    if(event.value == 1){
      this.form.get('scheduleDateTime').setValidators(null);
    }else{
      this.form.get('scheduleDateTime').setValidators(Validators.required);
    }
  }


}
