import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { CommonService } from '@fuse/services/common.service';
import { AddUserService } from 'app/main/distributors/add-distributors/add-distributor.service'; 
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-change-profile-dialog',
  templateUrl: './change-profile-dialog.component.html',
  styleUrls: ['./change-profile-dialog.component.scss']
})

export class ChangeProfileDialogComponent implements OnInit {
  changeProfileForm: UntypedFormGroup;
  todayDate  = new Date();
  eightteenYearAgoFormToday  = this.todayDate.setFullYear(this.todayDate.getFullYear() -18);
  profile;
  defCountry: any;
  defState: any;
  showEdit:boolean = true;
  ipAddress: any;
  stripeConnectedId:any;
  croppedProfileBase64; 
  countryCode;
  mobileNumberCountryCode:any = 'IN';
  isStripeConnecting:boolean = false;
  /* second Table Transaction list table*/
  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  selectedCountry:any = '';
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  checkNumberStatus = 1; // 1 == not checked, 2 == checking...,  3 == checked but not found, 4 == checked and found.
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  
  constructor(
    public dialogRef: MatDialogRef<ChangeProfileDialogComponent>,
    private fb: UntypedFormBuilder,
    public _dialog: MatDialog,
    private _snackbar: MatSnackBar,
    public _commonService: CommonService,
    public _addUserService: AddUserService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {

    //get all countrie list
    this._addUserService.getCountries();
    this.getcountryCode()

    //setting up default data
    if(data){
      this.profile = data['user'];
      // setting up default country 
      this.defCountry = this.profile.country ? this.profile.country:'';

      // get state list
      if(this.defCountry){
        this._commonService.getStates(this.defCountry).then((result: any) => {
          this._addUserService.states = result.data;
        })
      }
      // setting up default state.
      this.defState = this.profile.state ? this.profile.state:'';      
      this.stripeConnectedId = this.profile.stripeConnectedId?this.profile.stripeConnectedId:'';
    }
  }
  

  ngOnInit() {
    this.createForm();
    // DESABLE FROM DEFAULT
    this.changeProfileForm.disable();
    this.changeProfileForm.get('mobileNo')?.valueChanges.subscribe((result) => {
      if ((result?.countryCode === 'CN')) {
          if ((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)) {
              this.changeProfileForm.get('mobileNo').setErrors(null);
          } else {
              this.changeProfileForm.get('mobileNo').setErrors({ validatePhoneNumber: { valid: false } });
        }}
    })
  }

  // country code
  getcountryCode(){
    this._commonService.getAllCountries().then((result: any) => {
        if(result.length){
          this.countryCode = result;   
        }
        else{
          this.countryCode = [];
        }
    })
  }

  // create form
  createForm(){
    console.log("this.profile data", this.profile);
    if(this.profile?.mobileNumberCountryCode){
      this.mobileNumberCountryCode = this.profile.mobileNumberCountryCode;
    }
    // this.croppedProfileBase64 = this.profile?.profileImage ?? ''
    // create form
    this.changeProfileForm = this.fb.group({
      // profileImage : [this.profile?.profileImage ?? ''], // added
      name : [this.profile.name ? this.profile.name:'', Validators.required], // added
      // last_name : [this.profile.lastName ? this.profile.lastName:'', Validators.required], // added
      email : [this.profile?.email ?? '', [Validators.required, Validators.email]], // added
      gender : [this.profile?.gender ? String(this.profile?.gender) : ''],
      // mobile : [String(this.profile?.mobile).slice(2, this.profile?.mobile.length) ?? '', [Validators.required, Validators.pattern]], // added
      mobile : [String(this.profile?.mobile) ? this.profile?.mobile: '', [Validators.required, Validators.pattern]], // added
      dob : [this.profile.dob? this.profile.dob: ''], // added
      address : [this.profile.addressLine1 ? this.profile.addressLine1:''], // added
      
      country : [this.profile.country ? this.profile.country:'', [Validators.required]], // added
      // country : ['', [Validators.required]], // added
      state : [this.profile.state ? String(this.profile.state):''], // added
      city : [this.profile.city ? this.profile.city:''], // added
      
      postal_code : [this.profile.postalCode ? this.profile.postalCode:''], // added
      website : [this.profile.website ?? ''], // added
      description : [this.profile.description ?? ''], // added
      
      GSTNo : [this.profile.GSTNo ?? ''], // added
      PANNo : [this.profile.PANNo ?? ''], // added
      // regs : [''], // added
      
      isShareSecurePdf : [this.profile.isShareSecurePdf ? true : false,  ''], // added
      TaxBehaviour : [this.profile.TaxBehaviour ? true : false,''], // added
      FeeBehaviour : [this.profile.FeeBehaviour ? true : false,''], // added
      
      id_number : [this.profile.idNumber ? this.profile.idNumber:''],
    })
  }

  
  // on remove logo
  onRemoveProfile() {
    this.croppedProfileBase64 = '';
    // this.changeProfileForm.get('profileImage').setValue(null);
  }

  // get state list according to country id
  onSelectCountry(e) {
    this._commonService.getStates(e.value).then((result: any) => {
        this._addUserService.states = result.data;
    })
  }

  // select country code
  selectcountryCode(e){
    this.selectedCountry = parseInt(e.value);
    console.log("the selected value", this.selectedCountry);
  }

  // edit profile 
  editProfile(){
    this.showEdit = false;
    this.changeProfileForm.enable();
  }
  
  // close dialog
  closeDialog(){
    this.dialogRef.close();
  }

  // strip connect
  openStripeDialog(){
    this.isStripeConnecting = true;
    if(!this.changeProfileForm.get('country').value){
      this._snackbar.open('Country is required to connect with Stripe.', 'OKAY', {duration : 3000});
      this.editProfile();
      this.changeProfileForm.get('country').markAsTouched();
      this.isStripeConnecting = false;
    }else{
      const payload = {
        email : this.profile.email,
        firstName : this.profile.name,
        countryCode : this._addUserService.allCountries.filter((item)=> item.id == this.changeProfileForm.get('country').value)[0].sortname
      }
      console.log("payload", payload);
      this._commonService.connectStripeAccount(payload).then((res:any)=>{
        if(res.status == 200){
          console.log("this is the res", res.data.account.url);
          window.open(res.data.account.url, '_blank');
          this.startCountdown(res.data.account.expires_at);
        }else{
          this.isStripeConnecting = false;
        }
      })
    }

    // this._commonService.connectStripeAccount()
    // let stripeFormDialog = this._dialog.open(StripeFormDialogComponent, {
    //   panelClass: ['commonDialogWrapper'],
    //   backdropClass: 'dialogBackdrop',
    //   width: '400px', 
    //   disableClose: true, 
    // })
    // stripeFormDialog.afterClosed().subscribe((data:any)=>{})
  }

  
  startCountdown(timestamp) { 
    const targetTime = parseInt(timestamp); // Convert the timestamp to a number
    const countdownElement = document.getElementById('countdown'); // Assuming you have an element with id 'countdown' to display the countdown
    
    function updateCountdown() {
      const currentTime = Math.floor(Date.now() / 1000); // Get current time in seconds
      const timeDifference = targetTime - currentTime;
  
      if (timeDifference <= 0) {
        clearInterval(timer);
        countdownElement.textContent = 'Link has expired. Please try to connect again.';
        countdownElement.style.color = '#ff0000';
        this.isStripeConnecting = false;
        return;
      }

      const days = Math.floor(timeDifference / (24 * 60 * 60));
      const hours = Math.floor((timeDifference % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((timeDifference % (60 * 60)) / 60);
      const seconds = Math.floor(timeDifference % 60);
  
      countdownElement.textContent = `The Stripe link will expire in: ${minutes} minutes, ${seconds} seconds.`;
      countdownElement.style.color = '#000';
      
    }
  
    // Update the countdown immediately to avoid initial delay
    updateCountdown();
  
    // Update the countdown every second
    const timer = setInterval(updateCountdown, 1000);
  }

  

  // convert timestape to date
  convert(str) {
    const date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  // on submit
  onSubmit(){
    if(this.changeProfileForm.valid){
      let findDate = this.changeProfileForm.get('dob').value;
      console.log("dob  -->", this.convert(findDate));
      findDate = new Date(findDate);
      let newFindDate = this.convert(this.changeProfileForm.get('dob').value);
      console.log("this.changeProfileForm.get-->", this.changeProfileForm.get("mobile").value);
      
      let updateFrom = {
        "name": this.changeProfileForm.get("name").value,
        // "mobile" : String(this.changeProfileForm.get("mobile").value?.e164Number).startsWith('+') ? this.changeProfileForm.get("mobile").value?.e164Number.slice(1, String(this.changeProfileForm.get("mobile").value?.e164Number).length) : this.changeProfileForm.get("mobile").value?.e164Number,
        // "mobile" : String(this.changeProfileForm.get("mobile").value?.e164Number).startsWith('+') ? this.changeProfileForm.get("mobile").value?.e164Number.slice(1, String(this.changeProfileForm.get("mobile").value?.e164Number).length) : this.changeProfileForm.get("mobile").value?.e164Number,
        "mobile": this.changeProfileForm.get('mobile').value?.number.split(" ").join(""),
        "phoneCode" : this.changeProfileForm.get('mobile').value?.dialCode,
        "email" : this.changeProfileForm.get("email").value,
        "gender" : this.changeProfileForm.get('gender').value,
        "GSTNo" : this.changeProfileForm.get("GSTNo").value,
        "PANNo" : this.changeProfileForm.get("PANNo").value,
        // "profileImage" : this.changeProfileForm.get('profileImage').value,
        "isShareSecurePdf" : this.changeProfileForm.get("isShareSecurePdf").value ? 1 : 0,
        "TaxBehaviour" : this.changeProfileForm.get("TaxBehaviour").value ? 1 : 0,
        "FeeBehaviour" : this.changeProfileForm.get("FeeBehaviour").value ? 1 : 0,
        "website" : this.changeProfileForm.get("website").value,
        "description" : this.changeProfileForm.get("description").value,
        "dob": newFindDate,
        "address": this.changeProfileForm.get("address").value,
        "city": this.changeProfileForm.get("city").value,
        "country": this.changeProfileForm.get("country").value,
        "id_number": this.changeProfileForm.get("id_number").value,
        "postal_code": this.changeProfileForm.get("postal_code").value,
        "state": this.changeProfileForm.get("state").value,
      }

      this._commonService.editProfile(updateFrom).then((result:any) => {
        if(result.status == 200){
            this._snackbar.open(result.message, 'OK',{
                verticalPosition: 'bottom',
                duration: 2000
            })
            this.dialogRef.close(true);
        }else{
            this._snackbar.open(result.message, 'OK',{
                verticalPosition: 'bottom',
                duration: 4000
            })
        }
      })
      // if(updateFrom){
      //   this.dialogRef.close([updateFrom]);
      // }else{
      //   this.dialogRef.close([]);
      // }
    }
  } 

}
