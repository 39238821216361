import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { BadgeTemplatesService } from './badge-templates.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { fuseAnimations } from '@fuse/animations';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-badge-templates',
  templateUrl: './badge-templates.component.html',
  styleUrls: ['./badge-templates.component.scss'],
  animations : fuseAnimations
})
export class BadgeTemplatesComponent implements OnInit {
  searchFilter:string = '';
  @ViewChild('search', { static: true }) search;
  allTemplates : [] = [];

  constructor(
    private _ToolbarService: ToolbarService,
    private _MatDialog : MatDialog,
    private _MatSnackBar : MatSnackBar,
    private _BadgeTemplatesService : BadgeTemplatesService,
    private _fuseProgressBarService : FuseProgressBarService,
    private _CommonService : CommonService
  ) { 
    this.setToolBarTitle(false);
    this.allTemplates = this._BadgeTemplatesService.allCertificate; 
  }

  ngOnInit(): void {
  }

  setToolBarTitle(edit) {
    if (edit) {
        // for title
        this._ToolbarService._ToolBarTitle.next("Label Templates");
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    } else {
        // for title
        this._ToolbarService._ToolBarTitle.next("Label Templates");
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
      }
  }
 
  clearSearch() {
    this.searchFilter = '';
    this.filterData()
  }

  filterData(){
    const payload = {
      search : this.searchFilter,
    }
    this._BadgeTemplatesService.onGetAllCertificateList(payload).then((res: any) => {
      if (res.status == 200) {
        this.allTemplates = res.data;
      }  
      else {
        this.allTemplates = []
      }
    })
  }

  onDelete(id){
    const dialog = this._MatDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this label Template?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete label Template?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this._CommonService.deleteBadge({id : String(id)}).then((res: any) => {
          if (res.status == 200) {
            this._MatSnackBar.open(res.message, 'OKAY', { duration: 2000 })
            this.filterData();
          }else{
            this._MatSnackBar.open(res.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

}
