import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';


import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { AuthService } from '../../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { ChangePasswordDialogComponent } from '@fuse/Dialog/change-password-dialog/change-password-dialog.component';
import { LyDialog } from '@alyle/ui/dialog'; 
import { environment } from 'environments/environment';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})


export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;
    isPassVisible = false;
    isConPassVisible = false;
    showError: boolean = false;
    errorMessage: string = '';
    countries: any;
    cropped = null;

    countryCode: any;
    selectedCountryCode: number;

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    preferredCountries: CountryISO[] = [
        CountryISO.UnitedStates,
        CountryISO.UnitedKingdom,
    ];
    defCountryCode: any = 'IN';
    defCountryCodewh: any = 'IN';
    states: any;
    croppedProfileBase64: any = '';
    currencies: any;

    token: string|undefined;
    isAccountCreated:boolean = false;
    accountCreatedMessage : string ;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _router: Router,
        private _cd: ChangeDetectorRef,
        private _ActivatedRoute: ActivatedRoute,
        private _dialog: LyDialog,
        private _authService: AuthService,
        private _MatSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _commonService: CommonService
    ) {
        this.token = undefined;
        if (localStorage.getItem('userLoggedIn') == 'true') {
            this._router.navigate(['/home'])
        }
        this.getAllCurrencies();
        this._commonService.getAllCountries().then((result: any) => {
            if(result.length)
                this.countries = result;
                else
                this.countries = [];
        })
        

        this.registerForm = this._formBuilder.group({
            businessName: ['', Validators.required],
            recapcha : ['', Validators.required],
            ownerName: ['', Validators.required],
            country: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            mobileNo: ['', Validators.required],
            currency: ['', Validators.required],
            registerNumber: [''],
            profileImg: [''],
            password: ['', Validators.required],
            confirmPassword: ['', [Validators.required, confirmPasswordValidator]],
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        });

        this.registerForm.valueChanges.subscribe((result) => {
            console.log("result ==>", this.registerForm)
        })



        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        // To reset states
        // localStorage.clear();
    }
    ngOnDestroy(): void {
        // throw new Error('Method not implemented.');
    }

    // public send(form: NgForm): void {
    //     console.log("form", form);
    //     if (form.invalid) {
    //       for (const control of Object.keys(form.controls)) {
    //         form.controls[control].markAsTouched();
    //       }
    //       return;
    //     }
    //     console.log("this.token", this.token);
    //     console.debug(`Token [${this.token}] generated`);
    //   }
      
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.registerForm.get('country').valueChanges.subscribe((result) => {
            if (result) {
                // const selectedCountry = this.countries.filter((item) => item.id === result)[0];
                this.getStates(result)
            }
        })

        this.registerForm.get('mobileNo').valueChanges.subscribe((result) => {
            console.log("result", result);
            if ((result?.countryCode === 'CN')) {
                if ((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)) {
                    this.registerForm.get('mobileNo').setErrors(null);
                } else {
                    this.registerForm.get('mobileNo').setErrors({ validatePhoneNumber: { valid: false } });
                }
            }
        })
        
        this.registerForm.get('recapcha').valueChanges.subscribe((res)=>{
            console.log("token", res);
            if(res){
                this.checkTokenValidation(res)
            }
        })

    }
    

    checkTokenValidation(token){
        const params = {
            secret : environment.recaptcha.secretKey,
            response : token
        }
        this._commonService.validateRecapchaToken(params).then((res:any)=>{
            if(!res?.data?.success){
                this.registerForm.get('recapcha').reset();
                this._MatSnackBar.open('reCAPTCHA token expired or is invalid. please try again.', 'OKAY', {duration : 5000})
            }
        })
    }

    openCropperDialog(event: any) {
        console.log("event", event);
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 250,
                    height: 250,
                    round: true,
                    type: 'image/png',
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 320,
                        height: 320
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height: 520,
            disableClose: true
        }).afterClosed.subscribe((result: any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event);
                this.cropped = result.file.dataURL;
                this._cd.markForCheck();
                this.onUploadImage(result.file, result.blobFile)
            }
        });
    }

    // on upload Image presignedURL API
    onUploadImage(fileObj, blobFile) {
        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName": 'profile_',
            "folderName": 'speakerMedia', // it is fix set by backend.
            'mimeType': fileObj?.type
        }
        return new Promise((resolve, reject) => {
            this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
                if (result.status == 200) {
                    console.log(result)
                    this.registerForm.get('profileImg').setValue(result.data.imageUploadUrl);
                    // payload for get Image upload status
                    const payload = {
                        'id': result.data.id,
                        'status': 1 // fix by backend.
                    }
                    this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
                    this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                        if (uploadStatusResponse.status == 200) {
                            this._MatSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            resolve(true);
                        } else {
                            this._MatSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            reject();
                        }
                    })
                }
            })
        })
    }

    onRemoveProfile() {
        this.registerForm.get('profileImg').setValue('')
        this.cropped = null;
    }


    /**
   * login user
   * 
   * 
   *  */

    // autoConnectIntoDashboard(email, pass) {
    //     this.registerForm.get('email').setValue(email)
    //     this.registerForm.get('password').setValue(pass)
    //     if (this.registerForm.valid)
    //         this.userLogin();
    // }

    public getAllCurrencies(): void {
        this._commonService.getAllCurrencies().then((result: any) => {
            if (result) {
                this.currencies = result;
            } else {
                this._MatSnackBar.open(result.message, 'Okay', { duration: 2000 })
            }
        })
    }
    public getStates(countryId): void {
        this._commonService.getStates(countryId).then((result: any) => {
            if (result.status === 200) {
                this.states = result.data;
            } else {
                this._MatSnackBar.open(result.message, 'Okay', { duration: 2000 })
            }
        })
    }
    userLogin(): void {
        console.log("form", this.registerForm);
        if (this.registerForm.invalid) {
            for (const control of Object.keys(this.registerForm.controls)) {
                this.registerForm.controls[control].markAsTouched();
            }
            return;
        }else{
            console.log("valid", this.token);
            // this.showError = false;
            const payload = this.registerForm.getRawValue();
            this.registerForm.markAsPending();
            payload['mobile'] = this.registerForm.get('mobileNo').value?.number.split(" ").join(""),
            payload['phoneCode'] = this.registerForm.get('mobileNo').value?.dialCode,
            payload['mobileNumberCountryCode'] = this.registerForm.get('mobileNo').value?.countryCode,
            delete payload.mobileNo;
            delete payload.confirmPassword
            console.log("payload", payload);
            
            this._authService.adminSignUp(payload).then((data) => {
                //user name pass correct
                if (data.status == 200) {
                    this.loginConfirm(data)
                    // if (data.data.admin.isPasswordChanged) {
                    console.log("data.data.admin.isAuthRequired", data.data.admin.isAuthRequired);
                    // if (data.data.admin.isAuthRequired) {
                    //     this.openQrDialog(data.data.admin.isAuthySetUp)
                    // } else {
                    this._router.navigate(['/home']);
                    // }
                    // } else {
                    //     this.openChangePasswordDialog()
                    // }
                }
                else if(data.status == 401){
                    this.registerForm.reset();
                    this.isAccountCreated = true;
                    this.accountCreatedMessage = data.message;
                    this.accountCreatedMessage = `Thank you for registering! We're reviewing your account, and we'll send you an email as soon as the verification is complete.`;
                }
                else {
                    this.showError = true;
                    this.errorMessage = data.hasOwnProperty('message') ? data.message : 'Wrong Email or Password';
                    this.registerForm.reset();
                }
            //     // this._router.navigate(['/dashboard']);
            }).catch((err) => {
                this.showError = true;
                this.errorMessage = 'Failed to connect';  
            });
        }
    }


    loginConfirm(data) {
        localStorage.setItem('userToken', data.data.admin.token);
        localStorage.setItem('userLoggedIn', 'true');
        localStorage.setItem('userEmail', data.data.admin.email);
        localStorage.setItem('userName', data.data.admin.name);
        localStorage.setItem('id', data.data.admin.id);
        localStorage.setItem('company', data.data.mySettings.company);
        localStorage.setItem('country', data.data.mySettings.country);
        localStorage.setItem('symbol', data.data.mySettings.symbol);
        localStorage.setItem('isPasswordChanged', data.data.admin.isPasswordChanged);
        localStorage.setItem('isAuthRequired', data.data.admin.isAuthRequired);
        localStorage.setItem('isAuthySetUp', data.data.admin.isAuthySetUp);
        this._authService.setPermissions(JSON.parse(data.data.admin.permission))
    }

    openChangePasswordDialog() {

        this._matDialog.open(ChangePasswordDialogComponent, {
            disableClose: true
        }).afterClosed().subscribe((dialogData: any) => {
            if (!dialogData) {
                return false
            } else {
                this.openQrDialog(false)
            }
        });
    }

    openQrDialog(isAuthySetUp) {
        console.log("-----isAuthySetUp->", isAuthySetUp);

        //only pin
        // if (isAuthySetUp) {
        //     console.log("only pin log");
        //     this._matDialog.open(AuthQrDialog, {
        //         disableClose: true,
        //         width: '300px',
        //         data: {
        //             isAuthySetUp: isAuthySetUp
        //         }
        //     }).afterClosed().subscribe((dialogData: any) => {
        //         if (dialogData) {

        //         }
        //     });
        // } else {
        //     //pin with qr
        //     this._authService.register2fa().then((result: any) => {
        //         if (result.status == 200) {
        //             this._matDialog.open(AuthQrDialog, {
        //                 disableClose: true,
        //                 data: {
        //                     qr: result.data,
        //                     // qr:'https://event-project.s3.amazonaws.com/qrcode/QrCode_1631704233456.png',
        //                 }
        //             }).afterClosed().subscribe((dialogData: any) => {
        //                 if (dialogData) {
        //                 }
        //             });
        //         }
        //     })
        // }
    }


    // confirmPass(): void {
    //     console.log("this.registerForm ===>", this.registerForm)
    // }
}

/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

    if (!control.parent || !control) {
        return null;
    }
    console.log("control ===+>", control)
    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('confirmPassword');

    if (!password || !passwordConfirm) {
        return null;
    }

    if (passwordConfirm.value === '') {
        return null;
    }

    if (password.value === passwordConfirm.value) {
        return null;
    }

    return { passwordsNotMatching: true };
};
