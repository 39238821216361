import { Component, OnInit, ViewChild } from '@angular/core';
import { SentNotificationsListService } from './sent-notifications-list.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sent-notification-list',
  templateUrl: './sent-notification-list.component.html',
  styleUrls: ['./sent-notification-list.component.scss'],
  animations : fuseAnimations
})
export class SentNotificationListComponent implements OnInit {

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild('search', { static: true })search;
    
    maxAvailableRecordsInTable; 
    dataSource;
    displayedColumns = ['title', 'scheduleDate', 'notificationSendTo', 'message', 'status', 'action'];
    dateRangeFilterVal;

    filter = {
      searchFilter: '',
      pageSize: 10,
      pageIndex: 1,
    };

    range = new UntypedFormGroup({
      start: new UntypedFormControl(null),
      end: new UntypedFormControl(null),
    });


  constructor(
    private _SentNotificationsListService : SentNotificationsListService,
    private _ToolbarService: ToolbarService,
    private _commonservice: CommonService,
    private _snackBar : MatSnackBar,
    private _matdialog: MatDialog,
  ) { 

    this.setToolBarTitle(); 
    this.dataSource = new MatTableDataSource(this._SentNotificationsListService.notificationList);
    this.maxAvailableRecordsInTable = this._SentNotificationsListService.maxAvailableRecordsInTable;

  }


  ngOnInit(): void {

  }


  setToolBarTitle() {
    this._ToolbarService._ToolBarTitle.next("Sent Notifications");
    this._ToolbarService._isBackBtnShow.next(false);
  }


  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filter.searchFilter = val;
    this.filterData();
  }


  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData();
  }


  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value)
    this.filterData();
  }


  clearFilter() {
    this.paginator.pageIndex = 0;
    this.range.get('start').reset();
    this.range.get('end').reset();
    this.filter.searchFilter = ''
    this.dateRangeFilterVal = ''
    this.filterData();
  }


  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData();
  }


  onCancelNotification(id){
    const dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to cancel this notification?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Cancel Notification',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this._commonservice.cancelNotification({ "id": id }).then((res: any) => {
          if (res.status == 200) {
            this._snackBar.open(res.message, 'OKAY', { duration: 2000 })
            this.filterData();
          }
        })
      }
    })
  }


  filterData(){

    const payload = {
      pageIndex : this.filter.pageIndex,
      pageSize : this.filter.pageSize,
      search : this.filter.searchFilter,
      startDate: this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      endDate: this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
    }

    this._SentNotificationsListService.getAllSentNotification(payload).then((res:any) => {
      if (res.status == 200) {
        this.dataSource.data = res.data.notificationList;
        this.maxAvailableRecordsInTable = res.data.totalRecords;
      } else if (res.status == 400) {
        this.dataSource = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })

  }
  
}
