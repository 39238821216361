import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { EventListService } from '../../events-list/event-list.service';
import { SlotBookingService } from '../slot-booking.service';

@Component({
  selector: 'app-slot-booking',
  templateUrl: './slot-booking.component.html',
  styleUrls: ['./slot-booking.component.scss']
})
export class SlotBookingComponent implements OnInit {

  tableData = [];
  maxAvailableRecordsInTable;
  dataSource;
  displayedColumns = ['name','mobile','email','price','seatNumber','ticketTypeName','pdfLink'];
  slotId: any;
  allTicketType: any;
  ticketTypeinfo: any;
  maxAvailableBooked: any;
  eventId: any;
  eventTicketType: any;

  @ViewChild('search', { static: true }) search;

  constructor(
    public _commonService: CommonService,
    private _addEditEventService: AddEditEventService,
    private _activatedRoute: ActivatedRoute,
    public _eventListService: EventListService,
    private _slotBookingService: SlotBookingService,
    private _fuseProgressBarService: FuseProgressBarService
  ) {
    // this.getTicketTypeForFilter()
   
  }

  ngOnInit() {
    
    this._activatedRoute.queryParams.subscribe((param: Params) => {
      this.slotId = param.id
      this.eventId = param.eventId
    });
    this.getEventForHistory();
    
    
    /* get id into params */
    // this._activatedRoute.queryParams.subscribe((param: Params) => {
    //   console.log("this is the param data", param);
    // })

   

    // console.log("this.slotId", this._addEditEventService.totalEvents);
    this.getslotBookedTickets();

    this.tableData = this._addEditEventService.allBookedTickets;
    this.maxAvailableBooked = this._addEditEventService.maxAvailableBooked;
    console.log("this._addEditEventService.allBookedTickets", this._addEditEventService.allBookedTickets);
    
    this.dataSource = new MatTableDataSource(this.tableData);
    console.log("all table data is here", this.dataSource);
    console.log("filtered data", this.dataSource);
    

  }

  filter = {
    ticketTypeId: '',
    search: '',
    pageIndex: 1,
    pageSize:10
  }
  onTicketTypeFilterSelect() {
    this.getslotBookedTickets();
  }

  getEventForHistory(){
    this._addEditEventService.getEvent(this.eventId).then((result:any) => {
      if(result.status == 200){
        this.eventTicketType = result.data.ticketType;
      }else{
        this.eventTicketType = [];
      }
    })
  }

  clearFilter() {
    this.filter.ticketTypeId = ''
    this.filter.pageIndex = 1,
    this.filter.pageSize = 10,
    this.filter.search = ''
    this.getslotBookedTickets()
  }

  onSearch(val) {
    this.filter.search = val
    this.getslotBookedTickets();
  }

  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.getslotBookedTickets();
  }

  /* FOR FILTER */
  // getTicketTypeForFilter() {
  //   const payload = {
  //     pageIndex: 0,
  //     pageSize: 0,
  //   }
  //   this._slotBookingService.getAllTicketTypeSlot(payload).then(((result: any) => {
  //     if(result.status == 200){
  //       this.allTicketType = result.data.ticketTypes;
  //     }
  //     else{
  //       this.allTicketType = [];
  //     }
  //   })
  //   )
  // }


  /* FOR GET DATA */
  getslotBookedTickets() {
    this._fuseProgressBarService.show()
    const payload = {
      "slotId": Number(this.slotId),
      "ticketTypeId": this.filter.ticketTypeId ? this.filter.ticketTypeId : 0,
      "search": this.filter.search,
      "pageIndex": this.filter.pageIndex,
      "totalPages": this.filter.pageSize
    }
    this._addEditEventService.allGetSlotBookedTickets(payload).then(((result: any) => {
      if(result.status == 200){
        this.dataSource.data = result.data.tickets;
        this.ticketTypeinfo = result.data.ticketType;
        this.maxAvailableBooked = result.data.totalRecords;
        this._fuseProgressBarService.hide()
        console.log("filtered data", result.data.tickets);
      }else{
        this.dataSource.data = [];
        this.ticketTypeinfo = [];
        this.maxAvailableBooked = 0;
        this._fuseProgressBarService.hide()
      }
      // this.maxAvailableRecordsInTable = result.data
    })
    )
  }
  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.getslotBookedTickets()
  }

  

  onPrint(pdfLink){
      if(pdfLink){
      let pdfLinkGen = pdfLink;
        const printWindow = window.open('', '', 'height="100vh",width="100%"');
        printWindow.document.write('<body style="margin:0; padding:0;"><iframe src="' + pdfLinkGen +'" height="100%" width="100%"  type="pdf"></iframe></body>')
      }
  }

}
