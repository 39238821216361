import { CommonService } from "@fuse/services/common.service";
import { AddOrderService } from "./add-order.service";
import { OrderService } from "../order.service";
import { Component, OnInit, ViewChild, ChangeDetectorRef } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray,Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { ConfirmationDialogComponent } from "@fuse/Dialog/confirmation-dialog/confirmation-dialog.component";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";
@Component({
  selector: "app-add-order",
  templateUrl: "./add-order.component.html",
  styleUrls: ["./add-order.component.scss"],
})
export class AddOrderComponent implements OnInit {
  form: UntypedFormGroup;
  eventTypeform: UntypedFormGroup;
  selectedEventData: any;
  dynamicFields: any = {};
  customerInfoType;
  dataSource;
  displayedColumns = [ "ticketType", "price", "availableTickets","maxMember", "qty", "total" ];
  isGroupTicket = false;
  grandTotal = 0;
  eventType;
  eventTypeId;
  maxMember;

  eventArr;
  eventTypeChange;
  availableSeat;
  availableTickets;
  onEventTypeSalect;
  ticketPrice;
  ticketTypeId;
  allSlotDates: any = [];
  allSlotTime: any = [];
  slotForCreateOrder: any = [];

  @ViewChild("selectType", { static: false }) eventStatus;
  
  slotId: any;
  eventTypeVali: any;
  fitleredData: any;
  isSubmiting: boolean = false;

  constructor(
    public _commonService: CommonService,
    private _fb: UntypedFormBuilder,
    public _addOrderService: AddOrderService,
    public _orderService: OrderService,
    private _router: Router,
    private _ToolbarService : ToolbarService,
    private cdRef: ChangeDetectorRef,
    private _matDialog: MatDialog
  ) {
    // _orderService.selectedEventData.customerInfoType
    this.setToolBarTitle();
 
    this.customerInfoType = _orderService.selectedEventData.customerInfoType;
    if (!this._orderService.selectedEventData)
      this._router.navigate(["/orders"]);
    else {
      this.selectedEventData = this._orderService.selectedEventData;
      this.dynamicFields = JSON.parse(this.selectedEventData.dynamicNames);
    }

    // console.log("event data", this.selectedEventData);
    this.eventType = this._addOrderService.allTickets;
    /* finding selected dates */
    /* to change timestap to date */
    // const toTimestamp = (strDate) => {
    //   const dt = Date.parse(strDate);
    //   return dt / 1000;
    // };

    // this.eventType[0].schedule.forEach(item => this.allSlotDates.push(this.eventType[0].schedule));
    if (this.eventType.length < 0) {
      console.log("data available");
    }else{
      if(this.eventType[0].schedule.length){
        this.allSlotDates = this.eventType[0].schedule.filter((item) => item.timeSlot.length);
        console.log("this.allSlotDates ----->", this.allSlotDates);
      }
    }
    
  }

  setToolBarTitle(){
     // for title
     this._ToolbarService._ToolBarTitle.next('Create Booking For');
     // for btn
     this._ToolbarService._isBackBtnShow.next(true);
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }


  ngOnInit() {
    // this.dataSource = new MatTableDataSource(this._addOrderService.allTickets)
    this.createForm();
    this.eventTypeform = this._fb.group({
      ticketTypes: ['', Validators.required],
      salecteDate: ['', Validators.required],
      timeSlot: ['', Validators.required]
    });
  }

  isFieldRequired(index) {
    if (this.customerInfoType == 1) {
      if (index == 0) {
        return true;
      } else return false;
    } else if (this.customerInfoType == 2) {
      return true;
    } else if (this.customerInfoType == 3) {
      return false;
    }
  }

  removeMember(id, index) {
    let findDeleteItem = <UntypedFormArray>this.form.get("members")["controls"][index];

    if (findDeleteItem.dirty) {
      let dialog =  this._matDialog.open(ConfirmationDialogComponent, { 
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          disableClose: false,
          data : {
            message : 'Are you sure you want to remove this?',
            image : '',
            focus : 'right',
            deleteItem : '',
            confirmButtonText : 'Yes',
            cancelButtonText : 'No'
          }
        })

        dialog.afterClosed().subscribe((result: any) => {
          if (result.delete) {
            (<UntypedFormArray>this.form.get("members")).removeAt(index);
            this.grandTotal -= this.getTicketTypeById(id).price;
          }
        });
    } else {
      (<UntypedFormArray>this.form.get("members")).removeAt(index);
      this.grandTotal -= this.getTicketTypeById(id).price;
    }
  }

  decreaseMember(id) {
    console.log((<UntypedFormArray>this.form.get("members")).controls);
    let index = (<UntypedFormArray>this.form.get("members")).controls
      .reverse()
      .findIndex((control) => control.value.ticketTypeId == id);
    if (index != -1) {
      this.grandTotal -= this.getTicketTypeById(id).price;
      (<UntypedFormArray>this.form.get("members")).removeAt(index);
      (<UntypedFormArray>this.form.get("members")).controls.reverse();
    }
  }

  getTicketTypeById(id) {
    const index = this._addOrderService.allTickets.findIndex(
      (item) => item.id == id
    );
    return this._addOrderService.allTickets[index];
  }

  get members(): UntypedFormArray {
    return this.form.controls["members"] as UntypedFormArray;
  }

  ticketCount(typeId) {
    return this.form.value.members.filter((item) => item.ticketTypeId == typeId).length;
  }

  validateSelectType() {

    let findFormFill = this.form.get("members");
    let memberLenght = findFormFill['controls'].length;

    if (memberLenght > 0 && findFormFill.dirty) {
      this.eventStatus.close();
      this._matDialog.open(ConfirmationDialogComponent,{
          data: {
            message: "You lost all data. You want to Continue.",
          },
        }).afterClosed()
        .subscribe((result: any) => {
          if (result.delete) {
            this.eventStatus.open();
          }
        });
      }
    }


  createForm() {
    this.form = this._fb.group({
      totalAmount: this.grandTotal,
      members: this._fb.array([]),
    });
  }

  onSelectDate(e) {

    this.allSlotTime = this.allSlotDates.filter(item => item.eventStartDate == e.value);
    console.log("on date selecte time slot - >", this.allSlotTime);
    this.allSlotTime = this.allSlotTime[0].timeSlot;

    this.eventTypeform.get('timeSlot').setValue('');
    this.eventTypeform.get('timeSlot').reset();
    this.eventTypeform.get('ticketTypes').setValue('');
    this.eventTypeform.get('ticketTypes').reset();
    this.eventTypeVali = '';
  }

  onSlotSelect(slot){
    this.slotId = slot.value;
    console.log("slot.value===>", slot.value);
    
    this.slotForCreateOrder = this.allSlotTime.filter(item => item.slotId == slot.value);
    this.fitleredData = this._addOrderService.allTickets.filter(item => item.slotId == slot.value)
    this.maxMember = this.fitleredData[0].memberPerTicket;
    
    /* to get data according to ticket type */
    const latestArr = this._addOrderService.allTickets.filter((element) => this.eventTypeId == element.ticketTypeId);
    this.eventArr = latestArr;

    this.eventTypeform.get('ticketTypes').setValue('');
    this.eventTypeform.get('ticketTypes').reset();
    this.eventTypeVali = '';
  }

  eventTypeSalect($event) {

    /* for reset form & value */
    this.grandTotal = 0;

    /* to get event type id */
    this.eventTypeId = $event.value;
    console.log("event id is -->", $event.value);
    
    /* to get data according to ticket type */
    const latestArr = this.fitleredData.filter((element) => this.eventTypeId == element.ticketTypeId);
    console.log("all latest array ---->", latestArr);
    
    
    this.eventArr = latestArr;
    this.ticketPrice = this.eventArr[0].price;
    this.ticketTypeId = this.eventArr[0].id;

    /* to get max member */
    const maxMemberArr = latestArr.map((item) => item.memberPerTicket);

    /* to calculate available seats  */
    this.maxMember = maxMemberArr[0];
    const isFree = this.eventArr[0].isFree;
    if(isFree){
      this.availableTickets = this.eventArr.map((item) => item.totalAvailableSeats);
    }else{
      this.availableTickets = this.eventArr.map((item) => item.totalAvailableSeats);

      // this.availableTickets = this.eventArr.map((item) => item.availableSeat);
    }
    /* send data into table */
    this.dataSource = new MatTableDataSource(latestArr);
    console.log("data sourec", this.dataSource.filteredData.length);
    this.eventTypeVali = this.dataSource.filteredData.length;

    console.log("this.eventTypeVali", this.eventTypeVali);
    

  }


  checkValidation;
  addMember(typeId) {
    (<UntypedFormArray>this.form.get("members")).push(
      this._fb.group({
        name: ["",],
        mobile: [""],
        email: ["", Validators.email],
        gender: [""],
        age: [""],
        country: [""],
        state: [""],
        city: [""],
        ticketTypeId: [typeId],
        // otherMembers: this._fb.array([]),
      })
    );

    console.log("++", this.form.value);
    // console.log(this.form.value.members.length);
    this.grandTotal += this.getTicketTypeById(typeId).price;
  }

  // addOtherMembers(i){
  //   (<FormArray>this.form.get('members')['controls'][i].get('otherMembers')).push(
  //     this._fb.group({
  //       otherName: ['',Validators.required],
  //       otherMobile: ['', Validators.required],
  //       otherEmail: ['', Validators.email],
  //     })
  //   )
  //   console.log("New Finding Length ->", <FormArray>this.form.get('members')['controls'][i].get('otherMembers')['controls']);
  //   console.log("this is our i value",  i);
  // }

  // removeOtherMember(i,a){

  //   (<FormArray>this.form.get('members')['controls'][i].get('otherMembers')).removeAt(a)

  // }


  placeOrder(data, grandTotal?, ticketPrice?, ticketTypeId?, slotId?) {
    if(this.form.valid){
      this.isSubmiting = true;
      // this._fuseProgressBarService.show();
  
      // data.adminId = parseInt(localStorage.getItem('id'))
      const members = data['members'] 
      const memIndex = members.length;
  
      for (let i = 0; i < memIndex; i++) {
        const element = members[i].ticketTypeId;
        delete members[i].ticketTypeId; 
      }
  
      const payload = {
      eventId : this._orderService.selectedEventData.id,
      totalAmount : grandTotal, 
      ticketDetail :[ 
        {
          ticketId: ticketTypeId,
          ticketAmount: ticketPrice,
          slotId: slotId,
          members
          }
        ]
      } 
    // form reset here.
    this.form.disable();

      this._addOrderService.placeOrder(payload).then((result:any)=> {
        if(result.status == 200){
          this._router.navigate(['/orders'])
        }else{
            this.isSubmiting = false;
            this.form.reset();
            this.form.enable();
        }
      })
    }

    }

}
