import { AddEventTypeDialogComponent } from '@fuse/Dialog/add-event-type-dialog/add-event-type-dialog.component'
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventTypeService } from '../event-type.service';

import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations : fuseAnimations
})

export class ListComponent implements OnInit {

  displayedColumns: string[] = ['type','icon','backgroundImage','status'];
  dataSource;
  dialogRef
  maxAvailableRecordsInTable

  
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('search', { static: true }) search;

  constructor(
    private _matDialog: MatDialog,
    public _eventTypeService: EventTypeService,
    private _ToolbarService : ToolbarService,
  ) {
    this.setToolBarTitle();
    console.log(this._eventTypeService.permissions.delete);
    this.dataSource = new MatTableDataSource(this._eventTypeService.eventTypes);
    if (this._eventTypeService.permissions.delete)
      // this.displayedColumns.push('action')  
    this.maxAvailableRecordsInTable = this._eventTypeService.maxAvailableRecordsInTable
  }

  ngOnInit() {
    
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Event Type');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  updateTable() {
    this._eventTypeService.getAllEventTypes().then(() => {
      this.dataSource.data = this._eventTypeService.eventTypes
      this.maxAvailableRecordsInTable = this._eventTypeService.maxAvailableRecordsInTable
    })
  }
  openAddEventTypeDialog(id?): void {
    let selectedEventType = []
    if (id) {
      selectedEventType = this._eventTypeService.eventTypes.filter((type) => {
        return type.id == id
      })
    }

    this.dialogRef = this._matDialog.open(AddEventTypeDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '600px',
      autoFocus : false,
      data: {
        eventType: selectedEventType[0],
        heading:'Request for new event type'
      }
    });

    this.dialogRef.afterClosed().subscribe(response => {
      if (response)
          this.updateTable()
    });
  }

  onDelete(id) {
    let dailog =  this._matDialog.open(ConfirmationDialogComponent, {
      // height: '145px',
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Type?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Type?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    });
    
    dailog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._eventTypeService.deleteEventType(id).then(() => {
          let lastDigitOfPage = this.maxAvailableRecordsInTable.toString().slice(-1);
          if (lastDigitOfPage == 1){
            if(this.filter.pageIndex!=1){
            this.filter.pageIndex = this.filter.pageIndex - 1;
            this.paginator.pageIndex = this.filter.pageIndex - 1;
            }
          } 
          this.filterData() 
        })
      }
    });
  }

  // ----------------------- filters section -----------------------

  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.paginator.pageIndex = 0;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    this._eventTypeService.getAllEventTypes(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords;
        console.log("maxAvailableRecordsInTable",this.maxAvailableRecordsInTable);
      }else if (result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
      }
    }).catch(() => {
      this.dataSource.data = []
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

}
