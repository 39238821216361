import { LyDialog } from '@alyle/ui/dialog';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AddActivityComponent } from '@fuse/Dialog/add-activity/add-activity.component';
import { AddVenueComponent } from '@fuse/Dialog/add-venue/add-venue.component';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { AddEditCommonDataProviderService } from 'app/main/events/create-event/add-edit-common-data-provider.service';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { VenueListService } from 'app/main/masters/venue/list/venue-list.service';
import { Subscription } from 'rxjs';
import { AddUpdateTrackService } from './add-update-track.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-add-edit-track',
  templateUrl: './add-edit-track.component.html',
  styleUrls: ['./add-edit-track.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddEditTrackComponent implements OnInit, OnDestroy {

  mode = 'add';
  eventData;
  croppedBannerBase64;
  form: FormGroup;
  allVenues: [] = [];
  performerSchedule;
  selectedDateForActivities: any;
  allSpeakersList: [] = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private _fb: FormBuilder,
    public _addeditcommondataproviderservice: AddEditCommonDataProviderService,
    public _addEditEventService: AddEditEventService,
    private _AddUpdateTrackService: AddUpdateTrackService,
    private _venueService: VenueListService,
    private _dialog: LyDialog,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _commonService: CommonService,
    private _matDialog: MatDialog,

  ) {

    this.eventData = this._addEditEventService.eventData;


    if (this._addEditEventService.eventData.performerSchedule.length) {

      this.performerSchedule = this._addEditEventService.eventData.performerSchedule;
      this.performerSchedule.map((element) => {
        element.activitesList = [];
        element.id = null;
        element.eventId = this.eventData.id;
      })

    } else {
      this.performerSchedule = [];
    }

    if (this.eventData?.isOnline == 0) {
      this.getVenueList();
    }

    this.getAllSpeacker()

    const subscriptionSpeaker = this._addeditcommondataproviderservice._speakersList.subscribe((dataArr) => {
      if (dataArr?.length) {
        this.allSpeakersList = dataArr;
        console.log("_allSpeakersList", dataArr);
      } else {
        this.allSpeakersList = [];
      }
    })
    this.subscriptions.push(subscriptionSpeaker);

    console.log("this.eventData",);

    if (this._AddUpdateTrackService?.mode == 'update') {
      this.mode = 'update';
      console.log("this._AddUpdateTrackService", this._AddUpdateTrackService.trackDetails);
      this.createForm(this._AddUpdateTrackService.trackDetails)
    } else {
      this.mode = 'add';
      this.createForm()
    }
  }


  ngOnInit(): void {
    this.removeHTTPS();
  }


  createForm(data = null) {
    console.log("data", data);
    if (data) {
      if (data.bannerImage) this.croppedBannerBase64 = data.bannerImage;
      if (data.performerSchedule?.length) this.performerSchedule = data.performerSchedule;
    }

    this.form = this._fb.group({
      bannerImage: [data?.bannerImage || '', Validators.required],
      bannerSameAsEvent: [''],
      title: [data?.title || '', Validators.required],
      eventId: [this.eventData.id, Validators.required],
      description: [data?.description || '', Validators.required],
      ranking: [data?.ranking || '', Validators.required],
      venueSameAsEvent: [''],
      venueId: [this.eventData.isOnline ? 0 : data?.venueId ? data?.venueId.toString() : '', Validators.required],
      participateUrl: [data?.participateUrl || "https://"],
    })

  }

  handleBannerCase() {
    if (!this.form.get('bannerSameAsEvent').value) {
      this.form.get('bannerImage').setValue(this.eventData.bannerUrl)
      this.croppedBannerBase64 = this.eventData.bannerUrl;
    } else {
      this.form.get('bannerImage').setValue('')
      this.croppedBannerBase64 = '';
    }
  }

  onRemoveBanner() {
    this.form.get("bannerImage").setValue('');
    this.croppedBannerBase64 = '';
  }

  getVenueList() {
    this._venueService.getAllVenues({ pageIndex: 0, pageSize: 0 }).then((res: any) => {
      if (res.status === 200) {
        this.allVenues = res.data.data;
      } else {
        this.allVenues = [];
      }
    })
  }


  addVenue() {
    if (this.form.status != "DISABLED") {
      this._matDialog.open(AddVenueComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        width: '1100px',
        autoFocus: false,
      }).afterClosed().subscribe((dialogData: any) => {
        if (dialogData) {
          this.getVenueList();
        }
      });
    }
  }


  removeHTTPS() {
    this.form.controls.participateUrl.valueChanges.subscribe((res) => {
      if (res)
        if (String(res).startsWith('https://https://')) {
          let trimStr = String(this.form.controls.participateUrl.value).slice(8, String(this.form.controls.participateUrl.value)?.length);
          this.form.controls.participateUrl.setValue(trimStr);
        }
    })
  }


  checkSelectedDateLength() {
    const selectedDateForAct = this.performerSchedule.filter((item) => item.slotDate == this.selectedDateForActivities);
    if (selectedDateForAct.length) {
      if (selectedDateForAct[0].activitesList?.length) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getAllSpeacker() {
    this._addeditcommondataproviderservice.getAllSpeaker()
    // this._commonService.getAllSpeaker({pageIndex : 0, pageSize : 0}).then((result:any) => {
    //   if(result.status == 200){
    //     this.allSpeakersList = result.data.data;
    //   }else{
    //     this.allSpeakersList = [];
    //   }
    // })
  }

  onScheduleDialog() {
    const dialog = this._matDialog.open(AddActivityComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '800px',
      autoFocus: false,
      disableClose: true,
      data: {
        isEdit: false,
        selectedDate: this.selectedDateForActivities,
        // performerList: this.allSpeakersList?.length ? this.allSpeakersList : []
      }
    })

    dialog.afterClosed().subscribe((activityPayload) => {
      console.log("activityPayload ===> ", activityPayload);
      if (activityPayload) {
        this.performerSchedule.forEach((item) => {
          if (item.slotDate == activityPayload.slotDate) {
            item['activitesList'] = activityPayload.activitesList;
          }
        })
        this.performerSchedule.forEach((slotDate) => {
          slotDate.activitesList.forEach((activity) => {
            if (activity.performerList?.length) {
              activity['performers'] = this.setPerformerById(activity.performerList);
              this.addRanking(activity['performers']);
              console.log("activity['performers']", activity['performers']);
            }
          })
        })
        console.log("this is activity payload", this.performerSchedule);
      }
    })

  }

  addRanking(arr) {
    // change ranking.
    arr.map((item: any, i) => {
      if (item)
        item.ranking = (i + 1)
    })
  }


  /* 
       SET PERFORMER ACCORDING TO ID.
   */
  setPerformerById(ids) {
    let performer = []
    console.log("this.allSpeakersList", this.allSpeakersList);
    if (this.allSpeakersList?.length) {
      ids.forEach((id) => {
        performer.push(this.allSpeakersList.filter((item: any) => Number(item.id) === Number(id))[0]);
      })
    }
    return performer;
  }


  dropActSpeaker(event: CdkDragDrop<string[]>, e, a) {
    moveItemInArray(this.performerSchedule[e].activitesList[a].performers, event.previousIndex, event.currentIndex);
    this.addRanking(this.performerSchedule[e].activitesList[a].performers);
    console.log("performers====>", this.performerSchedule[e].activitesList[a].performers);
  }



  openCropperForBanner(event) {
    const dialog = this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
        fileEvent: event,
        myConfig: {
          width: 700,
          height: 300,
          round: false,
          type: 'image/png',
          keepAspectRatio: true,
          responsiveArea: true,
          output: {
            width: 1280,
            height: 550
          },
          resizableArea: true
        }
      },
      width: 520,
      height: 520,
      disableClose: true
    }).afterClosed.subscribe((result: any) => {
      if (result) {
        console.log("result", result);
        console.log("this is pre event", event);
        this.croppedBannerBase64 = result.file.dataURL;
        // this._cd.markForCheck();
        this.uploadBannerOnPresignedURL(result.file, result.blobFile)
      }
    });
  }


  uploadBannerOnPresignedURL(fileObj, blobFile) {
    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": 'banner_',
      "folderName": 'event/track/banner/', // it is fix set by backend.
      'mimeType': fileObj?.type
    }
    this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
      if (result.status == 200) {
        this.form.get('bannerImage').setValue(result.data.imageUploadUrl);
        // payload for get Image upload status
        const payload = {
          'id': result.data.id,
          'status': 1 // fix by backend. 
        }
        this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile);
        this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
          if (uploadStatusResponse.status == 200) {
            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
          } else {
            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

  handleVenueCase() {
    if (!this.form.get('venueSameAsEvent').value) {
      this.form.get('venueId').setValue(this.eventData.venueId);
    } else {
      this.form.get('venueId').setValue(null);
    }
  }


  /* 
    add update case
  */
  onSubmit() {
    console.log("clicked", this.form);
    if (this.form.invalid) {
      this.form.markAllAsTouched();
    } else {
      if (this.performerSchedule.some(ele => ele?.activitesList?.length)) {
        this.form.disable();
        // add case
        if (this.mode == 'add') {
          const payload = { ...this.form.value, performerSchedule: this.performerSchedule };
          payload['participateUrl'] = 'https://' ? '' : payload['participateUrl'];

          payload.performerSchedule.forEach((activityData) => {
            activityData.activitesList.forEach((activity) => {
              if (!activity['performerList']?.length) {
                activity['performers'] = [];
              }
            })
          })

          console.log("payload", payload);
          
          this._commonService.addEventTrack(payload).then((res: any) => {
            if (res.status == 200) {
              this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 })
              this._router.navigate([`/events/view/${this.eventData.id}/tracks`], {queryParams : {
                updateApi : 1
              }})
            } else {
              this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 })
              this.form.enable();
            }
          })
        }

        // update case
        else {
          const payload = { ...this.form.value, performerSchedule: this.performerSchedule, trackId: this._AddUpdateTrackService?.trackDetails?.id };
          payload['participateUrl'] = 'https://' ? '' : payload['participateUrl'];
          
          payload.performerSchedule.forEach((activityData) => {
            activityData.activitesList.forEach((activity) => {
              if (!activity['performerList']?.length) {
                activity['performers'] = [];
                activity['performerList'] = [];
              }
            })
          })

          console.log("payload", payload);
          this._commonService.updateEventTrack(payload).then((res: any) => {
            if (res.status == 200) {
              this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 })
              this._router.navigate([`/events/view/${this.eventData.id}/tracks`], {queryParams : {
                updateApi : 1
              }})
            } else {
              this._matSnackBar.open(res.message, 'OKAY', { duration: 3000 })
              this.form.enable();
            }
          })
        }

      } else {
        this._matSnackBar.open('Please add Track Activity', "OKAY", { duration: 3000 })
      }
    }
  }


  onEditactivity(date) {
    const dialog = this._matDialog.open(AddActivityComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '800px',
      autoFocus: false,
      disableClose: true,
      data: {
        selectedDate: date,
        isEdit: true,
        preActivities: this.performerSchedule,
      }
    })

    dialog.afterClosed().subscribe(activityPayload => {
      if (activityPayload) {
        this.performerSchedule.forEach(item => {
          if (item.slotDate == date) {
            console.log("this is item", item);
            if (item.activitesList?.length) {
              item.activitesList.forEach((activity) => {
                if (activity.performerList?.length) {
                  activity['performers'] = this.setPerformerById(activity.performerList);
                  this.addRanking(activity['performers']);
                }
              })
            }
          }
        })
      }
    })

  }


  ngOnDestroy(): void {
    this._AddUpdateTrackService.trackDetails = null;
    this._AddUpdateTrackService.mode = null;
    this.mode = null;
    // this._AddUpdateTrackService.eventData = null;
  }


  deleteActives(activies, i) {
    console.log("activies", activies);

    const dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data: {
        message: `Are you sure you want to delete this activity?`,
        image: '',
        focus: 'right',
        deleteItem: 'delete activity ?',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      }
    })

    dialog.afterClosed().subscribe((res) => {
      if (res) {
        if (activies[i].id) {
          const payload = {
            eventId: activies[i].eventId,
            activityId: activies[i].id
          }
          this._commonService.onDeleteActivity(payload).then((res: any) => {
            if (res.status == 200) {
              // this._addEditEventService.eventData
              // activies.splice(i, 1);
              // this.updateStepsData();
              this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
            } else {
              this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
            }
          })
        } else {
          activies.splice(i, 1);
        }
      }
    })

  }


}
