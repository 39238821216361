import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';


@Injectable({
  providedIn: 'root'
})


export class DomeUserProfileService { 
  customerId:number; 
  customerData = new BehaviorSubject(null); 
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService : CommonService,
    private _activatedRoute : ActivatedRoute,
    private location: Location,
  ) { }
 
  getDomeUserInfo(customerId){
    return new Promise((resovle, reject) => {
      this._commonService.domeUserInfo({customerId:customerId}).then((res:any)=>{
        if(res.status == 200){
          this.customerData.next(res.data);
        }else{
          this.customerData.next(null);
        }
        resovle(res);
      }, reject)
    })
  }

}
