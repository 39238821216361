import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { EventDomUsersService } from './event-dome-users.service';
import { fuseAnimations } from '@fuse/animations';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { MatTableDataSource } from '@angular/material/table';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { DomeCustomerConnectionsComponent } from '@fuse/Dialog/dome-customer-connections/dome-customer-connections.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseProgressBarModule } from '@fuse/components';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-event-dome-users-list',
  templateUrl: './event-dome-users-list.component.html',
  styleUrls: ['./event-dome-users-list.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations : fuseAnimations
})
export class EventDomUsersListComponent implements OnInit {

  maxAvailableRecordsInTable;
  dataSource;
  eventData;
  apiDataStatus = {
    isDataLoading : false,
  }

  private _unsubscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true })paginator: MatPaginator;
  @ViewChild('search', { static: true })search;

  filterDome = {
    searchFilter : '',
    // pageSize: 10, 
    itemsPerPage : 12,
    // pageIndex: 1,
    currentPage : 1, 
    totalItems:0,
  }

  defaultImages = [
    'assets/images/profileImage/01.png',
    'assets/images/profileImage/02.png',
    'assets/images/profileImage/03.png',
    'assets/images/profileImage/04.png',
    'assets/images/profileImage/05.png',
    'assets/images/profileImage/06.png',
    'assets/images/profileImage/07.png',
    'assets/images/profileImage/08.png',
    'assets/images/profileImage/01.png',
    'assets/images/profileImage/02.png',
    'assets/images/profileImage/03.png',
    'assets/images/profileImage/04.png',
    'assets/images/profileImage/05.png',
  ]

  constructor(
    private _EventDomUsersService : EventDomUsersService,
    private _CommonService : CommonService,
    public _ToolbarService : ToolbarService,
    private _MatSnackBar: MatSnackBar,
    public _addEditEventService: AddEditEventService,
    private _progressBar : FuseProgressBarService,
    private _matDialog : MatDialog,
    private _activatedRoute : ActivatedRoute,
    private _router : Router
  ) { 
    // this.maxAvailableRecordsInTable = this._EventDomUsersService.totalRecords;
    // this.dataSource = new MatTableDataSource(this._EventDomUsersService.domUsers);
  }

  ngOnInit(): void { 
    this.eventData = this._addEditEventService.eventData;
    if(this.eventData.isDomeUser){
      this.apiDataStatus.isDataLoading = true;
      this.filterData(); 
    }

    this._activatedRoute.queryParams.subscribe((res)=>{
      console.log("this is queryParams --- - >", res);
    })
  }

  onSearch(val) {
    this.filterDome.itemsPerPage = 12;
    this.filterDome.currentPage = 1;
    this.filterDome.searchFilter = val;
    this.filterData();
  }

  clearSearch() {
    this.filterDome.searchFilter = '';
    this.filterData();
  }


  filterData() { 
    this._progressBar.show();
    const payload = {
        "eventId" : this.eventData.id,
        "pageIndex": this.filterDome.currentPage,
        "pageSize": this.filterDome.itemsPerPage,
        "search" : this.filterDome?.searchFilter || ''
    } 
    this._EventDomUsersService.getDomUsers(payload).then((result: any) => {
      this.apiDataStatus.isDataLoading = false;
      this._progressBar.hide();
        if (result.status == 200) { 
            this.dataSource = result.data.data;
            this.filterDome.currentPage = result.data.pageIndex;
            this.filterDome.totalItems = result.data.totalRecords;
        }else if (result.status == 400) {
            this.dataSource = [];
            this.filterDome.totalItems = 0;
            // this.dataSource.data = [];
            // this.maxAvailableRecordsInTable = 0;
        }
        else {
            this.dataSource = [];
            this.filterDome.totalItems = 0;
            // this.dataSource.data = []
            // this.maxAvailableRecordsInTable = 0
        }
    })
  }

  onPageChange(event){
    this.filterDome.currentPage = event;
    this.filterData();
  }

  onConnection(customer){
    console.log("customer ", customer.id);
    this._router.navigate(['profile'])
    // this._CommonService.connectedUserList({customerId : customer.id}).then((res:any) =>{
    //   if(res.status == 200){
    //     console.log("this is rs", res);
    //     this.openConnectionDialog(res.data, customer)
    //   }else if(res.status == 400){
    //     this._MatSnackBar.open(res.message, 'OKAY', {duration : 2000})
    //   }
    // })
  }

  navigateToProfile(customer){
    this._router.navigate(['../profile'], { relativeTo: this._activatedRoute , queryParams : {customer : customer?.id}, queryParamsHandling: 'merge'});
  }

  openConnectionDialog(list, customer){
    
    // this._matDialog.open(DomeCustomerConnectionsComponent, {
    //   panelClass: ['commonDialogWrapper'],
    //   backdropClass: 'dialogBackdrop',
    //   width : '450px',
    //   disableClose: false,
    //   data : {connectionList: list, customer : customer}
    // })
    
  }

}
