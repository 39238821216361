import { Directive , Input} from '@angular/core'; 
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';
@Directive({
  selector: '[appEmailValidation]'
})
export class EmailValidationDirective {
  validate(control: FormControl) {
    const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i;
    const valid = emailRegex.test(control.value);
    return valid ? null : { invalidEmail: true };
  }
}
