import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-common-user-search-select-dialog',
  templateUrl: './common-user-search-select-dialog.component.html',
  styleUrls: ['./common-user-search-select-dialog.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class CommonUserSearchSelectDialogComponent implements OnInit {

  // courses: Course[];
  filteredData: any;
  filters: { query$: BehaviorSubject<string>; } = { query$: new BehaviorSubject('') };
  selectedData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public defaults: any,
    private cd: ChangeDetectorRef,
    public _dialogRef: MatDialogRef<CommonUserSearchSelectDialogComponent>,
  ) { 
    console.log("default data in common search ",this.defaults)

    let temp: any = this.defaults.data
    temp = temp.map((element) => ({ ...element, checked: false }))
    this.defaults.data = temp;
  } 

  ngOnInit(): void {
    
    this.filteredData = this.defaults.data
    console.log("this default data in on it  ", this.filteredData)


    // Filter by search query
    combineLatest([this.filters.query$]).subscribe(([query]) => {
      this.filteredData = this.defaults.data

      // for other type
      // if (query !== '' && this.defaults.type != 'commonSearch' && this.defaults.type != 'teamSelect') {
        this.filteredData = this.filteredData.filter(data => data.name?.toLowerCase().includes(query?.toLowerCase()));
      // }

      // for teamSelect Type 
      // else if (this.defaults.type == 'teamSelect') {            
        // this.filteredData = this.filteredData.filter(data => data.title ? data.title :data.name?.toLowerCase().includes(query?.toLowerCase()));
        // console.log(" in combine latest data in team select   ", this.filteredData)
      // }

      // for commonSearch
      // else {
      //   this.filteredData = this.filteredData.filter(data => data.title?.toLowerCase().includes(query?.toLowerCase()));
      // }
    });
  }
  
  selectedValue(value) {

    let selectedData = value;
    let temp = this.filteredData
    temp.forEach(element => {
      if (element.id == selectedData.id) {
        // this.selectedData = element
        return element
      } else {
        return element.checked = false
      }
    });

    this.selectedData = value
    this.cd.detectChanges();
  }

  selectedTeam(value) {
    console.log("filterd data", this.filteredData);
  }


  filterByQuery(query: string): void {
    this.filters.query$.next(query);
  }

  byfilter(query:string): void{
    this.filteredData = this.defaults.data;
    if(query){
      this.filteredData = this.filteredData.filter((item) =>  item.position.toLowerCase() == query.toLowerCase())
    }
  }
  
  checkIsDisable(){
    return !this.filteredData.some((item) => item.checked === true);
  }

  onSubmit(){
    const selectedMember = this.filteredData.filter((item)=> item.checked)
    this._dialogRef.close(selectedMember);
  }
}
