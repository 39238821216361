import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { GeneratedLabelsService } from './generated-labels.service';
import { MatTableDataSource } from '@angular/material/table';
import { CommonImageViewerDialogComponent } from '@fuse/Dialog/common-image-viewer-dialog/common-image-viewer-dialog.component';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-generated-labels',
  templateUrl: './generated-labels.component.html',
  styleUrls: ['./generated-labels.component.scss'],
  animations : fuseAnimations
})
export class GeneratedLabelsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'email', 'phoneNumber', 'eventName', 'action'];
  dataSource;
  maxAvailableRecordsInTable;
  filter = {
    eventId: 0,
    search: '',
    pageSize: 10,
    pageIndex: 1
  }

  constructor(
    private _ToolbarService: ToolbarService,
    private _fuseProgressBarService: FuseProgressBarService,
    public _GeneratedLabelsService : GeneratedLabelsService,
    public _commonService : CommonService,
    private _matDialog: MatDialog,
  ) {
    this._GeneratedLabelsService.getAllEvents();
    this.setToolBarTitle(false);
   }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._GeneratedLabelsService.badgeList);
    this.maxAvailableRecordsInTable = this._GeneratedLabelsService.totalRecords;
  }

  setToolBarTitle(edit) {
    if (edit) {
        // for title
        this._ToolbarService._ToolBarTitle.next("Select Attendees");
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    } else {
        // for title
        this._ToolbarService._ToolBarTitle.next("Generated Lable's");
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.search = val
    this.filterData();
  }

  
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.search = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

  onEventFilterSelect(val){
    this.filter.eventId = val.value;
    this.filterData()
  }

  clearFilter() {
    this.filter.eventId = 0;
    this.filter.search = '';
    this.filterData();
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  filterData() {
    this._fuseProgressBarService.show()
    this._GeneratedLabelsService.getAllCertificateUserList(this.filter).then((result: any) => {
      this._fuseProgressBarService.hide()
      if (result.status == 200) {
        this.dataSource.data = result.data.badgeList;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }
      else if (result.status == 400) {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0
      }
    })
  }

  onViewCertificate(image){
    const dialog = this._matDialog.open(CommonImageViewerDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '568px',
      autoFocus : false,
      data : {
        image : image,
        imageWidthSize : '100%',
        message : null,
        buttonText : 'Download',
        title : 'View Label'
      }
    })
  }

}
