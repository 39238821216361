import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SpeakerService } from '../speaker.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-speaker-list',
  templateUrl: './speaker-list.component.html',
  styleUrls: ['./speaker-list.component.scss']
})
export class SpeakerListComponent implements OnInit {

  displayedColumns: string[] = ['image', 'name', 'hostType', 'position', 'orgName',  'about', 'socialLink', 'action'];
  dataSource;
  maxAvailableRecordsInTable = 0;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  constructor(
    private _matDialog: MatDialog,
    public _SpeakerService : SpeakerService,
    private _ToolbarService : ToolbarService, 
  ) { 
    this.setToolBarTitle(); 
    this.dataSource = new MatTableDataSource(this._SpeakerService.speakersList);
    this.maxAvailableRecordsInTable = this._SpeakerService.maxAvailableRecordsInTable;
  }


  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  ngOnInit(): void {
   
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next(`Hosts`);
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.updateListing(this.filter.pageIndex,  this.filter.pageSize);
  }

  onDelete(item){ 
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to remove this speaker?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Speaker?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    }) 
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        if(item?.isDeletable){
          this.callDeleteApi(item)
        }else{
          this.showWarningMessage(item)
        }
      }
    })
    
  }

  showWarningMessage(item){
    const dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      disableClose: false,
      data : {
        message : ` This host is part of an event. Deleting it will also remove the host from those events. Are you sure you want to delete?`,
        image : '/assets/animated_icon/alarm.gif',
        imageSize : '150px',
        focus : 'right',
        deleteItem : 'Warning',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        this.callDeleteApi(item)
      }
    }) 
  }

  callDeleteApi(item){
    this._SpeakerService.ondeleteFaqQuestion({ "speakerId": item.id }).then((result: any) => {
      if (result) {
        this.updateListing(this.filter.pageIndex,  this.filter.pageSize);
      }
    })
  }


  updateListing(pageIndex, pageSize){
    this._SpeakerService.getSpeakersListData({"pageIndex":pageIndex,"pageSize":pageSize}).then((result:any) => {
      if(result.status == 200){
        this.dataSource.data = result.data.data;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else if(result.status == 400){
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }
}
