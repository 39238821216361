import {
  Component,
  OnInit,
  Inject,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { CommonService } from "@fuse/services/common.service";
import { AddGuestService } from "./add-guest.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import {
  NgxFileDropEntry,
  FileSystemFileEntry,
  FileSystemDirectoryEntry,
} from "ngx-file-drop";
import { CropperComponent } from "../cropper/cropper.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LyDialog } from "@alyle/ui/dialog";

@Component({
  selector: "app-add-dialog",
  templateUrl: "./add-guest-dialog.component.html",
  styleUrls: ["./add-guest-dialog.component.scss"],
})
export class AddGuestDialogComponent implements OnInit {
  previewUrl;
  imageFile;
  isImageValid = true;
  croppedProfileImgBase64 = null;
  form: UntypedFormGroup;
  @ViewChild("fileUpload", { static: true }) fileUpload: ElementRef;

  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddGuestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _snackbar: MatSnackBar,
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private _matDialog: MatDialog,
    private _commonService: CommonService,
    private _addGuestService: AddGuestService
  ) { }
  ngOnInit() {
    console.log("_data", this._data.guest);
    
    if (this._data.guest) this.createForm(this._data.guest);
    else this.createForm();
  } 


  openCropperFormProfile(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 250,
              height: 250,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 320,
                  height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedProfileImgBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.onUploadImage(result.file, result.blobFile)
      }
    });
  }
  

  // on upload Image presignedURL API
  onUploadImage(fileObj, blobFile) {

    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": 'guest_timestamp',
      "folderName": 'event/guest', // it is fix set by backend.
      'mimeType': fileObj?.type
    }
 
    return new Promise((resolve, reject) => {
      this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) { 
          this.form.get('image').setValue(result.data.imageUploadUrl); 
          // payload for get Image upload status
          const payload = {
            'id': result.data.id,
            'status': 1 // fix by backend.
          }
          this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
          this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
            if (uploadStatusResponse.status == 200) {
              console.log("uploadStatusResponse", uploadStatusResponse);
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              resolve(result.data);
            } else {
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              reject();
            }
          })
        }
      })
    })

  }

  createForm(data?) {
    if(data?.image){
      this.croppedProfileImgBase64 = data?.image;
    }
    this.form = this._fb.group({
      name: [data ? data.name : "", Validators.required],
      position: [data ? data.position : ""],
      image: [data ? data.image : ""],
    });
    this.previewUrl = this.form.value.image;
  }

  onSubmit() {
    if (this._data.guest)
      this._addGuestService
        .editGuest(this.form.value, this._data.guest.id)
        .then(() => {
          this.matDialogRef.close(true);
        });
    else
      this._addGuestService.addGuest(this.form.value).then(() => {
        this.matDialogRef.close(true);
      });
  }

  removeImage() {
    this.fileUpload.nativeElement.value = "";
    this.previewUrl = "";
    this.form.get("image").setValue("");
  }

  readUrl(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log("image type is ", file.type);
      if (
        file.type == "image/png" ||
        file.type == "image/jpeg" ||
        file.type == "image/jpg"
      ) {
        this.isImageValid = true;
      } else {
        this.isImageValid = false;
      }
      if (this.isImageValid) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrl =
            this.sanitizer.bypassSecurityTrustResourceUrl(
              reader.result.toString()
            );
          console.log(this.previewUrl);
        };
        this.imageFile = file;
        this.form.get("image").setValue(file);
      }
    }
  }

  onRemoveThumb(type){
    if(type == 'profile')
      this.croppedProfileImgBase64 = null;
      this.form.controls.image.setValue('');
  }
}
