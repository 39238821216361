import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CommonService } from '@fuse/services/common.service';
import { OrganiserListService } from 'app/main/masters/organiser/organiser-list/organiser-list.service';
import { EventCategoryService } from 'app/main/masters/event-category/event-category.service';
import { GuestListService } from 'app/main/masters/guest/guest-list/guest-list.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/main/pages/auth.service';
import { TaxesService } from 'app/main/tax/taxes.service';
import { EventListService } from '../events-list/event-list.service';
import { FaqService } from 'app/main/faq/faq.service';
import { ResourcesService } from 'app/main/resources/resources.service';
import { SponsorService } from 'app/main/masters/sponsor/sponsor.service';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class AddEditEventService implements Resolve<any> {
  isRegistrationData : [] = [];
  eventData
  allVenues
  eventTypes
  totalEvents
  allOrganisers: any;
  eventCategory;
  allGuest;
  permissions: any;
  // get event by id
  ticketData;
  totalticket;
  eventId;
  maxAvailableRecordsInTable;
  
  public dynamicFields = [
    {
      "title": "name", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 1,
      "isAdded": 1
    },
    
    {
      "title": "mobile", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 1,
      "isAdded": 1
    },

    {
      "title": "email", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 1,
      "isAdded": 1
    },

    // {
    //   "title": "age", 
    //   "isDisabled" : false,
    //   "checkMarkDisabled" : false,
    //   "isRequired": 0,
    //   "isAdded": 1
    // },

    // {
    //   "title": "gender", 
    //   "isDisabled" : false,
    //   "checkMarkDisabled" : false,
    //   "isRequired": 0,
    //   "isAdded": 1
    // },

    {
      "title": "city", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 1
    },

    {
      "title": "state", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 1
    },

    {
      "title": "country", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 1
    },

    {
      "title": "companyName", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
    
    {
      "title": "jobTitle", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
    {
      "title": "industry", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
    
    {
      "title": "directNo", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
    {
      "title": "certificateStartDate", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
    {
      "title": "certificateExpiryDate", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
      {
      "title": "regitrationNo", 
      "isDisabled" : false,
      "checkMarkDisabled" : false,
      "isRequired": 0,
      "isAdded": 0
    },
  ]    

  _speakersList: BehaviorSubject<any>;

  allSlot: any = [];
  maxAvailableRecordsInTableAllSlot: any;
  allBookedTickets: any = [];
  maxAvailableBooked: any;
  taxesList: any;
  addAndSeletedTax:boolean = false;
  selectedTax: any = [];
  faqListData: any = [];
  allSponsorsGroup :any = []; 
  resourseList: any;
  allTickets: any;
  allSponsors = []
  

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService, 
    private _resourcesService :  ResourcesService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _authService: AuthService, 
    private _sponsorService : SponsorService,
    private _faqService : FaqService, 
  ) {
    console.log("server init"); 
    this._speakersList =  new BehaviorSubject([]);
   }



  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    this.eventId = route.params.id;

    return new Promise((resolve, reject) => { 
      // this is view case
      if(route.url[0].path == 'view' && route.params.id){
        console.log("event view case call");
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getEvent(route.params.id),
          // this.getSpeakersListData({pageIndex : 0, pageSize : 0}),
        ]).then(() => {
            console.log("this.eventData isTicketGenerated ", this.eventData);
            this.ticketData = [];
            if(this.eventData.isTicketGenerated){
              this.getTicketByid()
            }
            resolve(''); 
            this._fuseProgressBarService.hide();
          },
          reject
        )
      } 

      // assign Distributor case
      else if(route.url[0].path == 'distributor'){
        Promise.all([
          this.getEvent(route.params.id),
        ]).then(
          () => {
            resolve('');
            this._fuseProgressBarService.hide();
          },
          reject
        );
      } 
 
    });
  }

  getSponsorsGroups(){
    return new Promise((resolve, reject)=>{
      this._sponsorService.getAllSponsorsGroups({"search": "","pageIndex": 0,"pageSize": 0}).then((result:any) => {
        if(result.status == 200){
          this.allSponsorsGroup = result.data.getAllSponsorGroup;
        }else{
          this.allSponsorsGroup = [];
        }
        resolve(result)
      }, reject)
    })
  }

  getAllResources(){
    this._resourcesService.getAllResources({pageSize : 0, pageIndex: 0, search: ''}).then((res:any)=>{
      if(res.status == 200){
        this.resourseList =  res.data.resourseList;
      }else{
        this.resourseList = []
      }
    })
  }

  getFAQList(){
    return new Promise((resolve, reject) => {
      this._faqService.getFAQListData({pageIndex : '0', pageSize : '0'}).then((result:any)=>{
        if(result.status == 200){
          this.faqListData = result.data.getFaqList;
          resolve(result);
        }else{
          this.faqListData = [];
          resolve('');
        }
      }, reject)
    }) 
  }


  // getSpeakersListData(body){
  //   return new Promise((resolve, reject)=>{
  //       this._httpClient.post(environment.apiURL + 'admin/getAllSpeaker',body, this._commonService.getHeader()).subscribe((result:any) => {
  //           if(result.status == 200){ 
  //               this._speakersList.next(result.data.data);
  //               resolve(result)
  //           }else if(result.status == 400){ 
  //               this._speakersList.next([]);
  //               resolve(result)
  //           }
  //           this._fuseProgressBarService.hide();
  //       }, reject)
  //   })
  // }

  getEvent(id, isCallTicket?) {
    const payload = {
      "eventId": id
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/geteventbyid', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          console.log("response====1>", response);
          if(response.status == 200){
            this.eventData = response.data;
            if(isCallTicket && response.data.isTicketGenerated){
              // this.getAllTickets();
            } 
          }else{
            this.eventData = [];
            this.totalEvents = 0;
          }
          resolve(response);
        }, reject);
    });
  }

  getAllTickets(data?) {
    return new Promise((resolve, reject) => {
      const payload = {
        eventId: Number(this.eventId),
        pageSize : 0,
        pageIndex : 0
      }
      this._httpClient.post(environment.apiURL + 'admin/tickets/getalltickets', data ? data: payload, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          this.allTickets = result.data.data;
          console.log("by service called");
          resolve(result);
        }
        else if(result.status == 400){
          this.allTickets = [];
          resolve(result);
        }
         else {
          this.allTickets = [];
        }
      }, reject)
    })
  } 

  getUnassignedVerifier(eventId, pageIndex?) {
    const payload = {
      "eventId": eventId,
      "pageIndex": pageIndex,
      "forAssign": 1
    }

    return new Promise((resolve, reject) => {
      // this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/verifier/getallverifiers', payload, this._commonService.getHeader()).subscribe((response: any) => {
          // this._fuseProgressBarService.hide()
          resolve(response);
        }, reject);
    });
  }

  getTicketByid(payload?) {
    // this._fuseProgressBarService.show();
    const data = {
      "eventId": this.eventId,
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/getall/eventTickets', payload ? payload : data, this._commonService.getHeader()).subscribe((response: any) => {
          if(response.status == 200){
            // this._fuseProgressBarService.hide();
            this.ticketData = response.data.EventListing;
            console.log("this.ticketData  -->> ", this.ticketData);
            this.maxAvailableRecordsInTable = response.data.totalRecords;
          }else{
            this.ticketData = [];
            this.maxAvailableRecordsInTable = 0;
          }
          resolve(response);
        }, reject);
    });
  }

  getSlotByEvent(payload?){
     const data = {
      "eventId": this.eventId,
      "pageIndex":1,
      "pageSize":10
     }
    return new Promise((resolve, reject) =>{
      this._httpClient.post(environment.apiURL + 'admin/event/getall/eventSlots', payload ? payload : data, this._commonService.getHeader())
      .subscribe((response: any) =>{
        if (response.status == 200) {
          this.allSlot = response.data.data;
          // console.log("result form service file", response.data.data);
          this.maxAvailableRecordsInTableAllSlot = response.data.totalRecords;
          resolve(response)
        }else if(response.status == 400){
          this.allSlot = [];
          this.maxAvailableRecordsInTableAllSlot = 0;
          resolve(response)
        }resolve(response);
      }, reject);
    })
  }

  allGetSlotBookedTickets(payload?){
    return new Promise((resolve, reject) =>{
      this._httpClient.post(environment.apiURL + 'admin/event/getall/slotBookedTickets', payload, this._commonService.getHeader())
      .subscribe((response:any) =>{
        if(response.status == 200){
          this.allBookedTickets = response.data.tickets;
          this.maxAvailableBooked = response.data.totalRecords;
          console.log("all data is helpers",  response);
          resolve(response);
        }
        else if(response.status == 201){
          this.allBookedTickets = []
          this.maxAvailableBooked = 0;
          console.log("all data is helpers", response.data)
          resolve(response);
        }
        else if(response.status == 400){
          this.allBookedTickets = []
          this.maxAvailableBooked = 0;
          console.log("all data is helpers", response.data)
          resolve(response);
        }else{
          this.allBookedTickets = []
          this.maxAvailableBooked = 0;
          resolve(response);
        }
      })
    })
  }

  onCancelEvent(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/cancelEvent', body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }
  
  // create event data submit in stepper ui style. 
  onSubmitEventData(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/addV1', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })
  }


  updateEventFirstStep(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/updateEventFirstStep', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })
  }

  updateEventSecondStep(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/updateEventSecondStep', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })
  }
 
  updateEventThirdStep(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/updateEventThirdStep', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })
  }

  updateEventForthStep(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/updateEventFourStep', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })
  }

  updateEventFiveStep(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/updateEventFiveStep', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })
  }

  updateEventSixStep(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'admin/events/updateEventSixStep', data, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })  
  }


  getTicketScanningReport(body){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL +'event/getEventScannedData', body, this._commonService.getHeader()).subscribe(response => {
          resolve(response)
      }, reject)
    })  
  }

}
