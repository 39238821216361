import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  pageType: any;
  eventList: any;
  surveyId: any;
  surveyDetail: any;

  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
    private _httpClient: HttpClient
  ) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.surveyId = route.params.id;
    this.pageType = route.params.type;

    console.log("this.surveyId,this.pageType ===>", this.surveyId, this.pageType)
    return new Promise((resolve, reject) => {


      Promise.all([
        this._fuseProgressBarService.show(),
        this.getallpublishedevents()

      ]).then(
        () => {
          console.log("this.pollDetail,this.eventList ===>", this.eventList)
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );

    });
  }


  getallpublishedevents() {
    return new Promise((resolve, reject) => {
      this._commonService.getUpcommingPublishedEvents({}).then((result: any) => {
        if (result.status == 200) {
          this.eventList = result.data.data;
          console.log("this.eventList===>", this.eventList)
          if (this.pageType === 'edit' || this.pageType === 'detail') {
            this.getSurvey().then((result) => { resolve('') })
          } else {
            resolve('')
          }

        } else {
          this.eventList = []
          resolve('')
        }
      }, reject)
    })

  }


  addSurvey(payload) {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/survey/add', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  editSurvey(payload) {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + 'admin/survey/edit', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  publishSurvey() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + `admin/survey/publish/${this.surveyId}`, {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }


  surveyEnd() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + `admin/survey/end/${this.surveyId}`, {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  getSurvey() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + `admin/survey/get/${this.surveyId}`, this._commonService.getHeader())
        .subscribe((response: any) => {
          this.surveyDetail = response.data;
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }


}
