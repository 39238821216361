import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-info-dialog',
  templateUrl: './common-info-dialog.component.html',
  styleUrls: ['./common-info-dialog.component.scss']
})
export class CommonInfoDialogComponent implements OnInit {

  
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<CommonInfoDialogComponent>,
  ) 
  { 
    
  }

  ngOnInit(): void {
  }

}
