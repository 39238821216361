import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService implements Resolve<any> {
  feedback = new BehaviorSubject<any>([])
  maxAvailableRecordsInTable: any;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _CommonService : CommonService
  ) { 

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
            this.getAllFeedbackList(),
          ]).then(
            () => {
              resolve('');
            },
          reject
        );
    })
  }

  getAllFeedbackList(data?){
    this._fuseProgressBarService.show();
    const payload  = {
      pageIndex: 1, 
      pageSize: 10
    }
    return new Promise((resolve, reject)=>{
      this._httpClient.post(environment.apiURL + 'admin/feedbackList', data ? data : payload, this._CommonService.getHeader()).subscribe((response:any) =>{
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this.feedback.next(response.data.feedbackList);
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }else if(response.status == 201){
          this.feedback.next([]);
          this.maxAvailableRecordsInTable = 0;
        }
        else{
          this.feedback.next([]);
          this.maxAvailableRecordsInTable = 0;
        }
        resolve(response)
      }, reject)
    })
  }

  sendFeedback(data){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/adminFeedback', data , this._CommonService.getHeader()).subscribe((response:any)=>{
        if(response.status == 200){
          this._matSnackBar.open(response.message, "OK", {
            duration: 2000
          })
        }else{
          this._matSnackBar.open(response.message, "OK", {
            duration: 2000
          })
        }
        this._fuseProgressBarService.hide();
        resolve(response);
      }, reject)
    })
  }

}
