import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { AddVerifierService } from '../add-verifier.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { VerifiersScanLocationService } from '../../verifiers-scan-location/verifiers-scan-location.service';

@Component({
  selector: 'app-sacn-history-list',
  templateUrl: './sacn-history-list.component.html',
  styleUrls: ['./sacn-history-list.component.scss']
})
export class SacnHistoryListComponent implements OnInit {
  viewVerifierId: any;
  eventId: any;
  
  maxAvailableRecordsInTable
  tableData = []
  dataSource;
  // displayedColumns = ['name','orderId','scanTime','seatNumber','ticketNo','verifierId'];
  displayedColumns = ['userName', 'locationName', 'scanTime', 'scanType',  'name', 'ticketType', 'orderId', 'seatNumber','ticketNo'];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  materLocationsList:[] = []
  ticketTypes:[] = []


  constructor(
    public _commonService : CommonService,
    private _activatedRoute: ActivatedRoute,
    private _ToolbarService : ToolbarService,
    private _VerifiersScanLocationService : VerifiersScanLocationService,
    private _addVerifierService: AddVerifierService,
  ) { 
    this.setToolBarTitle(); 

    this._activatedRoute.queryParams.subscribe((params) => {
      if (params['id'] && params['eventId']) {
        this.viewVerifierId = params['id']
        this.eventId = params['eventId']
      } else {
        console.log("id is unavailable");
      }
    })

    this.gellAllScanedList();
    
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Scan History');
    // for btn
    this._ToolbarService._isBackBtnShow.next(true);
  }


  ngOnInit() {
    this.tableData = this._addVerifierService.scanedList
    this.dataSource = new MatTableDataSource(this.tableData);

    // get data for filters
    // get all locations
    
    this.getAllVerifiersScanList();

    this.getAllTicketType();

  }

  

  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1,
    locationId : 0,
    ticketType : '',
    scanningType : null
  }

  clearFilter(){
    this.filter = {
      searchFilter: '',
      pageSize: 10,
      pageIndex: 1,
      locationId : 0,
      ticketType : '',
      scanningType : null
    }
    this.gellAllScanedList();
  }

  getAllVerifiersScanList(){
    this._commonService.getAllLocationName().then((res:any)=>{
      if(res.status == 200){
        this.materLocationsList = res.data; 
        console.log("materLocationsList", this.materLocationsList);
        
      }else{
        this.materLocationsList = []; 
      }
    })
  }

  getAllTicketType(){
    this._commonService.getAllTicketTypes().then((res:any)=>{ 
      if(res?.length){
        this.ticketTypes = res;
      }else{
        this.ticketTypes = [];
      }
    })
  }

  onFilter(){
    this.gellAllScanedList();
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.gellAllScanedList()
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.gellAllScanedList()
}

  gellAllScanedList(){
    const payload = {
      "eventId" : Number(this.eventId),
      "verifierId": Number(this.viewVerifierId),
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter,
      "locationId":this.filter.locationId, 
      "ticketType":this.filter.ticketType,
      "scanningType":this.filter.scanningType
    }
    this._addVerifierService.getAllScanedEventList(payload).then((result : any) =>{
      if(result.status == 200){
        this.maxAvailableRecordsInTable = result.data.totalRecords;
        this.dataSource.data = result.data.verifierScannedList;
      }else{
        this.maxAvailableRecordsInTable = 0;
        this.dataSource.data = [];
      }
    })
  }

  @ViewChild('search', { static: true }) search;
    clearSearch() {
      this.filter.searchFilter = ''
      this.search.nativeElement.focus()
      this.gellAllScanedList()
  }

}
