import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { SponsorService } from 'app/main/masters/sponsor/sponsor.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry} from "ngx-file-drop";
import { CropperComponent } from '../cropper/cropper.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LyDialog } from '@alyle/ui/dialog';
@Component({
  selector: 'app-add-edit-sponsor',
  templateUrl: './add-edit-sponsor.component.html',
  styleUrls: ['./add-edit-sponsor.component.scss']
})

export class AddEditSponsorComponent implements OnInit {

  previewUrl;
  imageFile;
  isImageValid = true;
  croppedProfileImgBase64 = null;
  form: UntypedFormGroup;
  @ViewChild("fileUpload", { static: true }) fileUpload: ElementRef;
  dialog: any;
  mode
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddEditSponsorComponent>,
    private _fb: UntypedFormBuilder, 
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private _snackbar: MatSnackBar,
    private _matDialog: MatDialog,
    private _SponsorService : SponsorService,
    private _router: Router,
    public _commonService: CommonService
  ) { }

  ngOnInit(): void {
    if (this._data.case == 'add') {
      this.mode = 'add';
      this.createForm();
    }else if(this._data.case == 'view'){
      this.mode = 'view';
      this.createForm(this._data.data);
    }else if(this._data.case == 'edit'){
      this.mode = 'edit';
      this.createForm(this._data.data);
    }
  }

  createForm(data?) {
    if(data?.image){
      this.croppedProfileImgBase64 = data?.image;
    }
    this.form = this._fb.group({
      name: [data ? data.name : "", Validators.required],
      // position: [data ? data.position : ""],
      image: [data ? data.image : ""],
      redirectUrl : [data ? data.redirectUrl : ""],
      about : [data ? data.about : ""]
    });
    this.previewUrl = this.form.value.image;
  }
   
  //file drop
  // public dropped(files: NgxFileDropEntry[], dropperFor: "profile" | "bg") {
  //   // this.files = files;
  //   for (const droppedFile of files) {
  //     // Is it a file?
  //     if (droppedFile.fileEntry.isFile) {
  //       const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //       console.log("fileEntry", fileEntry);
  //       fileEntry.file((file: File) => {
  //         // Here you can access the real file
  //         if (dropperFor == "profile")
  //           this.openCropperFormProfile(file);
  //       });
  //     } else {
  //       // It was a directory (empty directories are added, otherwise only files)
  //       const fileEntry =
  //         droppedFile.fileEntry as FileSystemDirectoryEntry;
  //     }
  //   }
  // }


  openCropperFormProfile(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
            width: 250,
            height: 250,
            round : false,
            type: 'image/png',
            keepAspectRatio: false,
            responsiveArea: true,
            output: {
                // width: 320,
                // height: 320
            },
            resizableArea: true
        }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedProfileImgBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.onUploadImage(result.file, result.blobFile)
      }
    });
  }


  onUploadImage(fileObj, blobFile) { 
    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": 'profile_',
      "folderName": 'event/Sponsors', // it is fix set by backend.
      'mimeType': fileObj?.type
    } 

    return new Promise((resolve, reject) => {
      this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) { 
            this.form.get('image').setValue(result.data.imageUploadUrl); 
          // payload for get Image upload status
          const payload = {
            'id': result.data.id,
            'status': 1 // fix by backend.
          }
          this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
          this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
            if (uploadStatusResponse.status == 200) {
              console.log("uploadStatusResponse", uploadStatusResponse);
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              resolve(result.data);
            } else {
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              reject();
            }
          })
        }
      })
    })
  }

  onRemoveProfile(){
    this.croppedProfileImgBase64 = null;
    this.form.get('image').setValue(null);
  }


  onSubmit(){
    if(this.form.valid){
      // add
      if(this.mode == 'add'){ 

        const payload = {
          name : this.form.controls.name.value,
          image : this.form.controls.image.value,
          position : '',
          redirectUrl : this.form.controls.redirectUrl.value,
          about : this.form.controls.about.value,
        }

        this._commonService.addSponsor(payload).then((res:any) => {
          if(res.status == 200){
            this._snackbar.open(res.message, 'OKAY', {duration : 2000});
            this._matDialogRef.close(true);
          }
          this._snackbar.open(res.message, 'OKAY', {duration : 2000})
        })

      }

      // edit
      else{
        const payload = {
          id : this._data?.data?.id,
          name : this.form.controls.name.value,
          image : this.form.controls.image.value,
          position : '',
          redirectUrl : this.form.controls.redirectUrl.value,
          about : this.form.controls.about.value,
        }
        this._commonService.updateSponsor(payload).then((res:any) => {
          if(res.status == 200){
            this._snackbar.open(res.message, 'OKAY', {duration : 2000});
            this._matDialogRef.close(true);
          }
          this._snackbar.open(res.message, 'OKAY', {duration : 2000})
        })
      }

    }
  }

  onEdit(){
    this.mode = 'edit';
  }



}
