import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-delete-ticket-dialog',
  templateUrl: './delete-ticket-dialog.component.html',
  styleUrls: ['./delete-ticket-dialog.component.scss']
})
export class DeleteTicketDialogComponent implements OnInit {

  eventListing = [];
  form : UntypedFormGroup
  constructor(
    private _commonService : CommonService,
    private _fb : UntypedFormBuilder
  ) { 
    this.form = this.createForm();
  }

  ngOnInit(): void {
    this.getEventsList();
  }

  createForm(){
    return this._fb.group({
      event : ['', Validators.required],
      tickets : ['', Validators.required]
    })
  }



  getEventsList(){
    this._commonService.getEventsList().then((result:any)=>{
      if(result.status === 200){
        this.eventListing = result.data.data;
      }else{
        this.eventListing = [];
      }
    })
  }

  onSelectEvent(event){
    if(this.eventListing.length){
      this.eventListing.filter((item)=>{
        console.log("event", item.id);
      })
    }
  }


}
