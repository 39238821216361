import { Component, OnInit, AfterViewInit, ViewChild, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { OrderService } from './order.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { SelectEventDialogComponent } from './select-event-dialog/select-event-dialog.component';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
// import { PDFDocument } from 'pdf-lib';
// import * as printJS from 'print-js';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { OrderMembersComponent } from '@fuse/Dialog/order-members-details-dialog/order-members/order-members.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';
import { AddEditEventService } from '../events/edit-event/add-edit-event.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { BOOKING_TYPE } from '../events/create-event/createEventTypes';
import { Enums } from '@fuse/utils/systemEnums';
// import * as printJS from 'print-js';


@Component({
  selector: 'app-sale',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  animations : fuseAnimations
})
export class OrderComponent implements OnInit{
  // [x: string]: any;
  allCountries;
  allStates;
  allMembers;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  displayedColumns: string[] = ['orderId', 'customerName', 'mobile', 'totalSeats', 'createdBy', 'bookingType', 'eventName','ticketType', 'amount', 'soldDate', 'status', 'action'];
  dataSource;
  maxAvailableRecordsInTable;
  dialogRef: any;
  updatedData:any = [];
  ticketTypeList;
  eventId;
  currentDate = new Date().getTime();
  slotData = [];
  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  BOOKING_TYPE : BOOKING_TYPE  = Enums.BOOKING_TYPE;

  constructor(
    public _orderService: OrderService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matDialog: MatDialog,
    private _router: Router,
    private _ToolbarService : ToolbarService,
    public _commonService: CommonService,
    private _AddEditEventService:AddEditEventService,
    private _snackbar: MatSnackBar
  ) {
    this.setToolBarTitle();
    this.dataSource = new MatTableDataSource(this._orderService.orders);
    this.maxAvailableRecordsInTable = this._orderService.maxAvailableRecordsInTable;
    this.allCountries = this._orderService.allCountries;
    this.allStates = this._orderService.allStates;
    console.log("this.allCountries ===--->", this.allStates);
    console.log("allStates", this.allStates);
     
  }
  

  ngOnInit() {
    // this._orderService.getOrderMembersById();
   
    this.getAllEventType();
    // const sum = totalPrice.reduce((a, b) => a + b, 0);
    
    this._orderService._getOrdersTotalAmount.subscribe((result) => {
      this.updatedData = result;
      console.log("2. total price count", this.updatedData);
    });
    
    
  }

  // cancelOrderValidation(order){
  //   console.log("order", order);
  //   const currentDate
  //   if(order.showStartTime > new )
  // }


  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value)
        this.filterData();
  }
  // onPrint(id){
    
  // }
  onPrint(pdfLink) {
    if(pdfLink){
    let pdfLinkGen = pdfLink;
      const printWindow = window.open('', '', 'height="100vh",width="100%"');
      printWindow.document.write('<body style="margin:0; padding:0;"><iframe src="' + pdfLinkGen +'" height="100%" width="100%"  type="pdf"></iframe></body>')
    }
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('All Bookings');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  onExport(){

    let payload = { 
      "search": this.filter.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventId": this.filter.eventFilter,
      "ticketType": this.filter.ticketType,
      "bookingType" : this.filter.bookingType,
      "slotId": this.filter.slotFilter,
    } 
    this._fuseProgressBarService.show(); 
    this._orderService.getExportFile(payload).then((res:any)=>{
      if(res.status == 200){
        this._fuseProgressBarService.hide();
        window.open(String(res.data), "_blank");
      }else{
        this._snackbar.open(res.message, 'OKAY', {duration : 2000});
      }
    })
  }
  

  openOrderMemberDetails(element){
    const payload = {
      "orderId": element.orderId,
      "bookingType" : element.bookingType
    }
    this._orderService.getOrderMembersById(payload).then((result: any) => {
      if (result.status == 200) {
        this.dialogRef = this._matDialog.open(OrderMembersComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width: '650px',
          data: {
            orderId : payload.orderId,
            allMembers: result.data,
            allCountries : this.allCountries,
            allStates : this.allStates,
            createdBy : element.createdBy,
          }
        });
      } else{
        this.allMembers = [];
      }
    }) 
  }

  onAddOrder() {
    this._fuseProgressBarService.show();
    this._orderService.getUpcommingPublishedEvents().then((result: any) => {
      if (result.status == 200){
        this._fuseProgressBarService.hide();
        this.openSelectEventDialog();
      }else{
        this._fuseProgressBarService.hide();
        this._snackbar.open(result.message, "OKAY", {duration : 2000});
      }
    })
  }

  openSelectEventDialog() {
    this.dialogRef = this._matDialog.open(SelectEventDialogComponent, {
      panelClass: 'no-padding-dialog',
      width: '400px',
    });
    this.dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this._orderService.selectedEventData = this._orderService.upcommingPublishedEvents[response.index]
          this._router.navigate(['/orders/add'])
        } else
          return;
      });
  }


  // downloadFile(data) {
  //   console.log("this is the url", data);
    
  //   const blob = new Blob([data], { type: 'application/pdf' });
  //   const url = window.URL.createObjectURL(blob);
  //   window.open(url);
  //   printJS({
  //     printable: url,
  //     type: 'pdf',
  //   })
  // }
  onDelete(id) {
    this._matDialog.open(ConfirmationDialogComponent, {
      height: '145px',
    // }).afterClosed().subscribe((row: any) => {
    //   // if (row) {
    //   //   this._orderService.deleteOrganisers(id).then(() => {
    //   //     this.updateTable()
    //   //   })
    //   }
    });
  }

  // updateTable() {
  //   this._orderService.getAllOrganisers().then(() => {
  //     this.dataSource.data = this._orderService.
  //     this.dataSource.paginator = this.paginator;
  //   })

  // async printPDFS(pdfsToMerge: string[]) {
  //   // const mergedPdf = await PDFDocument.create();
  //   for (const pdfCopyDoc of pdfsToMerge) {
  //     let pdfBytes ;
  //     await fetch(pdfCopyDoc).then(res => {
  //       pdfBytes = res.arrayBuffer()
  //     }).catch(err => {
  //       this._snackbar.open(err.message, 'OK', { duration: 4000 })
  //     })

  //     // const pdf = await PDFDocument.load(pdfBytes);
  //     const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
  //     copiedPages.forEach((page) => {
  //       mergedPdf.addPage(page);
  //     });
  //   }
  //   const mergedPdfFile = await mergedPdf.save();
  //   this.downloadFile(mergedPdfFile);
  // }



  // ----------------------- filters section -----------------------

  dateRangeFilterVal
  filter = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    eventFilter: '',
    ticketType: '',
    searchFilter: '',
    slotFilter: '',
    bookingType : '',
    pageSize: 10,
    pageIndex: 1
  }

  clearFilter() {
    this.paginator.pageIndex = 0;
    this.range.get('start').reset();
    this.range.get('end').reset();
    this.filter.eventFilter = '';
    this.filter.searchFilter = '';
    this.filter.slotFilter = '';
    this.filter.ticketType = '';
    this.filter.bookingType = '';
    this.dateRangeFilterVal = '';
    this.filterData();
  }

  getAllEventType(){
    this._commonService.getAllTicketTypes().then((result:any) =>{
        console.log("getAllEventTypes ----->", result);
        this.ticketTypeList = result;
    })
  }

  onEventFilterSelect(val) {
    this.eventId = val.value;
    const payload = {
      "eventId": val.value
    }

    this._orderService.getTicketCreatedEventsList(payload).then((result:any) =>{
      this.slotData = result.data[0].schedule;
      if(this.slotData.length == 1){
        this.filter.slotFilter = result.data[0].schedule[0].slotId;
      }else{
        this.filter.slotFilter = '';
      }
    })
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.eventFilter = val.value;
    this.filterData();
  }

  onSlotFilter(val){
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.eventFilter = this.eventId;
    this.filter.slotFilter = val.value;
    this.filterData();

    // let payload = {
    //   "pageIndex": this.filter.pageIndex,
    //   "pageSize": this.filter.pageSize,
    //   "search": this.filter.searchFilter,
    //   "startDate": this.filter.dateRangeFilter.startDate ? this.filter.dateRangeFilter.startDate : 0,
    //   "endDate": this.filter.dateRangeFilter.endDate ? this.filter.dateRangeFilter.endDate : 0,
    //   "eventId": this.eventId,
    //   "ticketType": this.filter.ticketType,
    //   "slotId": val.value,
    // }

    // this._fuseProgressBarService.show()
    // this._orderService.getOrders(payload).then((result: any) => {
    //   this._fuseProgressBarService.hide()
    //   if (result.status == 200) {
    //     this.dataSource.data = result.data.data
    //     this.maxAvailableRecordsInTable = result.data.totalRecords
    //   } else if (result.status == 400) {
    //     this.dataSource.data = []
    //     this.maxAvailableRecordsInTable = 0;
    //   }
    //   else {
    //     this.dataSource.data = []
    //     this.maxAvailableRecordsInTable = 0
    //   }
    // })
  }
  

  onTicketTypeFilterSelect(){
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filterData();
  }
  
  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.filterData();
  }

  onBookingType(val){
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.bookingType = val.value;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventId": this.filter.eventFilter,
      "ticketType": this.filter.ticketType,
      "slotId": this.filter.slotFilter,
      "bookingType" : this.filter.bookingType
    }

    this._fuseProgressBarService.show()
    this._orderService.getOrders(payload).then((result: any) => {
      this._fuseProgressBarService.hide()
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      } else if (result.status == 400) {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0
      }
    })
  }

  onDateFilterChanges(e) {
    if (e.endDate != null && e.startDate != null) {
      this.paginator.pageIndex = 0;
      this.filter.dateRangeFilter.endDate = +e.endDate
      this.filter.dateRangeFilter.startDate = +e.startDate
      this.filterData()
    }
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

  cancelOrderConfirmation(order){ 
    const dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to cancel this order?',
        image : '/assets/ui/cancel-order.png',
        focus : 'right',
        deleteItem : 'Cancel Order',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })

    dialog.afterClosed().subscribe((res)=>{
      if(res){
        this.cancelOrder(order);
      }
    }) 
  }

  cancelOrder(order){ 
    const payload = {
      orderId : order.orderId,
      eventId : order.eventId,
      slotId : order.slotId,
      ticketId : order.ticketId,
      totalAmount : order.amount,
      ticketPrice : order.ticketPrice,
      userId : order.customerId,
      userName : order.customerName,
      userEmail : order.customerEmail,
      memberId : ""
    }

    this._commonService.cancelOrder(payload).then((res:any) => {
      if(res.status == 200){
        this._snackbar.open(res.message, 'OKAY', {duration : 3000})
        this.filterData();
      }else{
        this._snackbar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
  }

  updateApprovalBookingStatus(element, status){
      const payload = {
        orderId : element.orderId,
        status : status // 1 === 'approval' // 0 === 'reject'
      }
      this._commonService.updateApprovalBooking(payload).then((res:any)=>{
        if(res.status == 200){
          this._snackbar.open(res.message, 'OKAY', {duration : 3000})
          this.filterData();
        }else{
          this._snackbar.open(res.message, 'OKAY', {duration : 3000})
        }
      })
  }

}

