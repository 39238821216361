import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FaqService } from '../faq.service';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-add-edit-faq',
  templateUrl: './add-edit-faq.component.html',
  styleUrls: ['./add-edit-faq.component.scss']
})
export class AddEditFaqComponent implements OnInit,OnDestroy  {

  form : UntypedFormGroup
  constructor(
    private _fb : UntypedFormBuilder,
    public _FaqService : FaqService,
    private _router : Router,
    public _commonService : CommonService
  ) {
    if(this._FaqService.faqId){
      this.form = this.createForm(this._FaqService.faqDetail)
    }else{
      this.form = this.createForm()
    }
  }

  createForm(data?){
    console.log("this is data in response ", data);
    return this._fb.group({
      question: [data ? data?.question : '', Validators.required],
      answer : [data ? data?.answer : '', Validators.required]
    })
  }

  
  ngOnInit(): void {
  }

  onSubmit(){
    if(this.form.valid){
      if(this._FaqService.faqId){
      // this is edit case
      const payload = {
          "question": this.form.get('question').value,
          "answer":this.form.get('answer').value,
          "faqId": this._FaqService.faqId,
      }
      this._FaqService.onUpdateFAQDetails(payload).then((result) => {
        if(result){
          this._router.navigate(['/faq'])
        }
      })
      }else{
      // this is add case
      const payload = {
        "question": this.form.get('question').value,
        "answer":this.form.get('answer').value
      }
      this._FaqService.onAddFAQ(payload).then((result)=> {
        if(result){
          this._router.navigate(['/faq'])
        }
      })
      }
    }
  }

  ngOnDestroy() {
    this._FaqService.faqId = undefined;
    this._FaqService.faqDetail = undefined;
  }
}

