import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[noEdit]'
})
export class NoEditDirective {
  constructor(private el: ElementRef) { 
    console.log("NoEditDirective RUN");    
  }

  ngOnInit() {
    console.log("this.el.nativeElement", this.el.nativeElement);
    if (this.el.nativeElement.innerHTML.includes('{{firstName}}')) {
      this.el.nativeElement.setAttribute('contenteditable', 'false');
      console.log("this.el.nativeElement",this.el.nativeElement);
      this.el.nativeElement.style.color = 'green'
    }
  }
}