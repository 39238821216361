import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-publish-event-confirmation-dialog',
  templateUrl: './publish-event-confirmation-dialog.component.html',
  styleUrls: ['./publish-event-confirmation-dialog.component.scss']
})
export class PublishEventConfirmationDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _commonServices : CommonService,
    private _matDialog: MatDialog,
    private _matSnackBar:MatSnackBar,
    public matDialogRef: MatDialogRef<PublishEventConfirmationDialogComponent>,
  ) {
    console.log("data", this._data);
   }

  ngOnInit(): void {
  }

  createTicketWithOutPublishEvent(){
    this.matDialogRef.close(false);
  }

  createTicketWithPublishEvent(){
    this.matDialogRef.close(true);
  }

}
