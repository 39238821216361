import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'app-choose-template-dialog',
    templateUrl: './choose-template-dialog.component.html',
    styleUrls: ['./choose-template-dialog.component.scss']
})
export class ChooseTemplateDialogComponent implements OnInit {
    data: any = []
    constructor(
        public matDialogRef: MatDialogRef<ChooseTemplateDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public _data: any,
    ) {
        this.data = [..._data.layout]

        console.log(_data.layout)
    }

    ngOnInit() {
    }

    onTicketSelect(layout) {
        this.matDialogRef.close({ layout: layout })
    }

}
