import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { TicketTypeService } from 'app/main/masters/ticket-type/ticket-type.service';
import { AddUserService } from '../add-distributor.service';
import { TicketsSaleHistoryService } from '../tickets-sale-history/tickets-sale-history.service';

@Component({
  selector: 'app-total-sale-history',
  templateUrl: './total-sale-history.component.html',
  styleUrls: ['./total-sale-history.component.scss']
})
export class TotalSaleHistoryComponent implements OnInit {

  dataSource;
  displayedColumns = ['name', 'totalSeats', 'userName', 'amount', 'commission', 'ticketType', 'time', 'ticketNumber'];
  dateRangeFilterVal;

  filter = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    search: '',
    pageSize: 10,
    pageIndex: 1,
    eventFilter: 0,
    ticketType: 0,
  }
  distributorId: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('search', { static: true }) search;
  maxAvailableRecordsInTable: any;
  allEventList: any;

  constructor(
    private _getTicketsHistoryByEvents : TicketsSaleHistoryService,
    private _activatedRoute: ActivatedRoute,
    private _fuseProgressBarService: FuseProgressBarService,
    public  commonService : CommonService,
    private _TicketTypeService: TicketTypeService,
    public _AddUserService : AddUserService

  ) { 
    this.gettAllEventsList();
    this._activatedRoute.params.subscribe((params) => {
      this.distributorId =  params['id'];
    })
    
  }

  ngOnInit() {
    this.getTotalHistoryList();
    this.maxAvailableRecordsInTable = this._getTicketsHistoryByEvents.maxAvailableRecordsInTable;
    this.dataSource = new MatTableDataSource(this._getTicketsHistoryByEvents.allTicketsSaleHistory);
    
  }

  

  gettAllEventsList(){
    this._getTicketsHistoryByEvents.getAllEventList().then((result:any) =>{
      console.log("all data is here", result);
      this.allEventList = result.data;
    })
  }

  onEventChangeFilter(event) {
    this.filter.eventFilter = event.value;
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.getTotalHistoryList();
  }

  onTicketTypeChange(event){
    this.filter.ticketType = event.value;
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.getTotalHistoryList();
  }

  onDateFilterChanges(e) {
    if (e.endDate != null && e.startDate != null) {
      this.filter.dateRangeFilter.endDate = +e.endDate
      this.filter.dateRangeFilter.startDate = +e.startDate
      this.paginator.pageIndex = 0;
      this.getTotalHistoryList();
    }
  }

  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.getTotalHistoryList()
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.getTotalHistoryList()
  }

  onSearch(value) {
    this.paginator.pageIndex = 0;
    this.filter.search = value;
    this.filter.pageIndex = 1;
    this.getTotalHistoryList();
  }


  clearFilter() {
    this.filter.dateRangeFilter.endDate = 0;
    this.filter.dateRangeFilter.startDate = 0;
    this.dateRangeFilterVal = ''
    this.filter.search = '',
    this.filter.pageIndex = 1;
    this.filter.eventFilter = 0;
    this.filter.ticketType = 0,
    this.getTotalHistoryList()
  }

  getTotalHistoryList(){
    this._fuseProgressBarService.show();
    const payload = {
      distributorId: this.distributorId,
      search: this.filter.search,
      pageSize: this.filter.pageSize,
      pageIndex: this.filter.pageIndex,
      eventId: this.filter.eventFilter,
      type: this.filter.ticketType,
      startDate: this.filter.dateRangeFilter.startDate,
      endDate: this.filter.dateRangeFilter.endDate,
    }
    this._getTicketsHistoryByEvents.getTicketsHistoryByEvents(payload).then((result:any) =>{
      if (result.status == 200) {
        this.dataSource.data = result.data.ticketHistory;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
        this._fuseProgressBarService.hide();
      }else{
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
        this._fuseProgressBarService.hide();
      }
    })
  }


}