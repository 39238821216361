import { HttpHeaders } from '@angular/common/http';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

// export const ApiEndPointUrl = 'https://api-stage.eventlocal.io/api/v2/'; //New Stagging
// export const ApiEndPointUrl = 'https://api.eventlocal.io/api/v2/'; //New Producation

export const DefaultFilter = {
  "pageIndex": 1,
  "pageSize": 10,
  "search": "",
  "startDate": 0,
  "endDate": 0,
  "eventStatus": 0,
  "isOnline": 0,
  "isFree": 0
}

export const ReqHeader = {
  reqHeadersJSON: {
    headers: new HttpHeaders({
      'Authorization': localStorage.getItem('userToken'),
      'Content-Type': 'application/json'
    })
  },
  reqHeadersFormData: {
    headers: new HttpHeaders({
      'Authorization': localStorage.getItem('userToken')
    })
  }
}

// pattern validation
export enum Pattern {
  Email = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$',
  Mobile = '[0-9]+',
  MobileMinLength = 10,
  MobileMaxLength = 12,
  Password = '^(?=.*?[A-Z])(?=.*?[0-9]).{6,}$'
}

export const Enums = {
  GENDER: {
    1: 'Male',
    2: 'Female'
  },

  FILTER: {
    1: "Daily",
    2: "Week",
    3: "Month",
    4: "Year"
  },

  EVENT_STATUS: {
    1: "Upcoming",  // if event published.
    2: "Postpone", // if event published.
    3: "Cancelled", // if event published.
    4: "Ongoing", // if event published.
    5: "Complete", 
    6: "Ready To Publish", // if event unpublish.
    7: "In Draft" // if event unpublish or evnet incomplete.
  },

  // for filter 
  EVENT_WAY: {
    1: "ONLINE",
    2: "ON-SITE",
  },

  // for create event.
  WAY_OF_EVENT: {
    0: 'ON-SITE',
    1: 'ONLINE'
  },

  EVENT_FREE_FILTER: {
    1: "FREE EVENTS",
    2: "PAID EVENTS"
  },

  TRANSACTIONTYPE: {
    1: "DEPOSIT",
    2: "REFUND"
  },

  paymentModes: {
    1: "CASH",
    2: "UPI",
    3: "NET BANKING",
    4: "WALLET",
  },

  NOTIFICATIONFILTER: {
    1: "DISTRIBUTOR",
    2: "EVENT NOTIFICATION",
    3: "ORDER",
    4: "UPDATE EVENT",
  },

  SCANNINGBEFORE: {
    30: "30 Min",
    15: "15 Min",
    60: "1 hour",
    120: "2 hour",
    240: "4 hour",
  },

  TITLE: [
    { "id": 1, "name": "Mr." },
    { "id": 2, "name": "Mrs." },
    { "id": 3, "name": "Ms." },
  ],

  SENDVIA: [
    // {"id":4,"name":"All Platform"},
    { "id": 1, "name": "WhatsApp" },
    // { "id": 2, "name": "SMS" },
    { "id": 3, "name": "Email" },
  ],

  EVENT_STEPS: [
    { id: 1, title: 'Event Info', icon: 'assets/icons/create_event_step/event_Info.svg', afterDoneIcon: 'assets/icons/create_event_step/event_Info_active.svg', isDone: false, inProgress: true, form: 'eventInfo',},
    { id: 2, title: 'Event Schedule', icon: 'assets/icons/create_event_step/event_Schedule.svg', afterDoneIcon: 'assets/icons/create_event_step/event_Schedule_active.svg', isDone: false, inProgress: false, form: null,},
    { id: 3, title: 'Ticket Sale', icon: 'assets/icons/create_event_step/ticket_sale.svg', afterDoneIcon: 'assets/icons/create_event_step/ticket_Sale_active.svg', isDone: false, inProgress: false, form: null,},
    { id: 4, title: 'Venue & Activity', icon: 'assets/icons/create_event_step/venue_activity.svg', afterDoneIcon: 'assets/icons/create_event_step/venue_activity_active.svg', isDone: false, inProgress: false, form: null,},
    { id: 5, title: 'Event Details', icon: 'assets/icons/create_event_step/event_details.svg', afterDoneIcon: 'assets/icons/create_event_step/event_details_active.svg', isDone: false, inProgress: false, form: null,},
    { id: 6, title: 'Ready to Publish', icon: 'assets/icons/create_event_step/ready_publish.svg', afterDoneIcon: 'assets/icons/create_event_step/ready_publish_active.svg', isDone: false, inProgress: false, form: null,},
  ],

  EVENT_SCHEDULE: [
    { id: 1, title: 'One Day Event', checked: true },
    { id: 2, title: 'Single Event for Multiple Days', checked: false },
    { id: 3, title: 'Multiple Days unconnected Event', checked: false },
    { id: 4, title: 'Recurring Event', checked: false },
  ],

  EVENT_PRICE: [
    { id: 1, type: 'Paid' },
    { id: 2, type: 'Free' }
  ],

  EVENT_RECURRING: [
    {
      id: 3,
      name: 'Day options',
      types: [
        { id: 9, type: 'Daily' }
      ]
    },
    {
      id: 1,
      name: 'Week options',
      types: [
        { id: 2, type: 'every week in {day}' }, // if event select date (02-08-2023/Wednesday) then it will repeat on upcomming all Wednesday till the and.
        { id: 3, type: 'every alternative week in {day}' }, // it will skip 1 week and repeat on selected day.  
        { id: 4, type: 'every 2 weeks on {day}' }, // it will skip 2 week and repeat on selected day.
        { id: 5, type: 'every 3 weeks on {day}' }, // it will skip 3 week and repeat on selected day.
        { id: 6, type: 'every 4th week on {day}' }, // if event select date (02-08-2023/Wednesday) then if will skip 4th weeks and repeat Wednesday in 5th week.
        { id: 1, type: 'custom days every week' },
      ]
    },
    {
      id: 2,
      name: 'Year & Month options',
      types: [
        { id: 7, type: 'every month on {date} day' },
        { id: 8, type: 'every year on {date}' },
      ]
    },
  ],

  EVENT_RECURRING_DETAIL :[
    { id: 1, title: 'custom days every week'},
    { id: 2, title: 'every week in {day}' },
    { id: 3, title: 'every alternative week in {day}' },
    { id: 4, type: 'every 2 weeks on {day}' }, 
    { id: 5, title: 'every 2 weeks on {day}' },
    { id: 6, title: 'every 4th week on {day}' },
    { id: 7, title: 'every month on {date} day' },
    { id: 8, title: 'every year on {date}' },
    { id: 9, title: 'Daily' }
  ],

  WEEKDAYS_ENUMS: [
    { id: 0, title: 'Sunday', shortName: 'S' },
    { id: 1, title: 'Monday', shortName: 'M' },
    { id: 2, title: 'Tuesday', shortName: 'T' },
    { id: 3, title: 'Wednesday', shortName: 'W' },
    { id: 4, title: 'Thursday', shortName: 'T' },
    { id: 5, title: 'Friday', shortName: 'F' },
    { id: 6, title: 'Saturday', shortName: 'S' },
  ],

  RECURRINGEND: [
    { id: 1, type: 'After Repeat Count' },
    { id: 2, type: 'Date' }
  ],

  EVENT_TICKET_SALE_START_DATE: [
    { id: 1, isdisabled: false, type: 'Immediately' },
    { id: 2, isdisabled: false, type: 'Choose Date & Time' }
  ],

  EVENT_TICKET_SALE_END_DATE: [
    { id: 1, isdisabled: false, type: 'Until Event Start' },
    { id: 2, isdisabled: false, type: 'Until Event End' },
    { id: 3, isdisabled: false, type: 'Choose Date & Time' }
  ],

  EVENT_CONVENIENCE_FEE: [
    { id: 1, title: 'Customer Pay' },
    { id: 2, title: 'Include in ticket cost' },
  ],

  MEMBER_BOOKING_INFO_TYPE: [
    { id: 1, name: 'Atleast One Member' },
    { id: 2, name: 'All Members' },
    { id: 3, name: 'All Optional' },
  ],

  SEATNUMBERGENERATETYPE: [
    { id: 1, type: 'Use system-generated codes (Default)' },
    { id: 2, type: 'Custom formatted system code' }
  ],

  TICKETSEATFORMATTYPE: [
    { id: 1, type: 'Letters' },
    { id: 2, type: 'Numbers' },
    { id: 3, type: 'Numbers & Letters' }
  ],

  TICKETNUMBERLENGTH: [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 }
  ],
  SURVEY_QUESTION_TYPE: [
    { id: '0', value: 'Single Choice' },
    { id: '1', value: 'Multiple Choice' },
    { id: '2', value: 'Satisfaction' },
    { id: '3', value: 'Star Rating' },
  ],
 
  LABEL_CODE: [
    {id : 1, title : 'QR CODE'},
    {id : 2, title : 'BARCODE'},
    {id : 3, title : 'BOTH'}
  ],

  DOME_ORG_SIZE :{
    0 : "-",
    1 : "1 - 10",
    2 : "11 - 50",
    3 : "51 - 100",
    4 : "101 - 200",
    5 : "201 - 500",
    6 : "501 - 1000",
    7: "1001+"
  },

  DOME_ORG_REVENUE :{
    0 : "-" ,
    1 : "< $100k" ,
    2 : "$100 - $500k" ,
    3 : "$500k - $1M" ,
    4 : "$1M - $5M" ,
    5 : "$5M - $10M" ,
    6 : "$10M - $50M" ,
    7 : "$50M+" 
  },

  // PUSH NOTIFICATION
  ADMIN_PUSH_NOTIFICATION_TYPE: {
    PUSH_NOTIFICATION : 1,
    EMAIL_NOTIFICATION : 2,
    WHATSAPP_NOTIFICATION : 3,
    ALL:4
  },  

  NOTIFICATION_CATEGORY : {
    GENERAL_NOTIFICATION :1,
    EVENT_BASED_NOTIFCATION :2
  },

  NOTIFICATION_USERLIST:{
    ALL_USERS:1,
    FILTERED_USER:2,
    SELECTED_USER:3
  },

  NOTIFICATION_SCHEDULING:{
    IMMEDIATELY:1,
    DATE_RANGE :2
  },
  

  BOOKING_TYPE:{
    GENERAL:  1,
    APPROVAL:2,
    INVITATION:3,
    EXTERNAL_BOOKING:4
  },

  BADGESIZES : [
    {id : 0, title : 'Custom Size', actualSize : {width : '', height : ''}, size : {width : '', height : ''},  DPI : ''},
    {id : 1, title : '2.125" x 3.375"', actualSize : {width: '204', height: '324'}, size : {width: '638', height: '1013'},  DPI : '300'},
    {id : 2, title : '2.0" x 3.5"',actualSize : {width: '192', height: '336'}, size : {width: '600', height: '1050'}, DPI : '300'},
    {id : 3, title : '2.0" x 3.0"', actualSize : {width: '192', height: '288'}, size : {width: '600', height: '900'}, DPI : '300'},
    {id : 4, title : '2.25" x 3.5"', actualSize : {width: '216', height: '336'}, size : {width: '675', height: '1050'}, DPI : '300'},
    {id : 5, title : '3.0" x 4.0"', actualSize : {width: '288', height: '384'}, size : {width: '900', height: '1200'}, DPI : '300'},
    {id : 6, title : '3.5" x 2.0"', actualSize : {width: '336', height: '192'}, size : {width: '1050', height: '600'}, DPI : '300'},
    {id : 7, title : '3.4" x 2.1"', actualSize : {width: '326.4', height: '201.6'}, size : {width: '1020', height: '630'}, DPI : '300'},
  ],

  DPIOPTIONS : [
    32,72,150,300
  ]
}

// Use the sort() method to sort the array based on the "time" property
export function sortByTimestamp(arr) {
  arr.sort((a, b) => {
      // Convert the "time" values to timestamps (assuming they are in milliseconds)
      const timestampA = new Date(a.slotDate).getTime();
      const timestampB = new Date(b.slotDate).getTime();
      // Compare the timestamps and return the result of the comparison
      return timestampA - timestampB;
  });
  return arr;
}

export function sortByRank(arr){
  arr.sort((a, b) => {
    const rankA = a.rank;
    const rankB = b.rank;
    return rankA - rankB;
  })
  return arr;
}

export const darkTheme: NgxMaterialTimepickerTheme = {
    container: {
        bodyBackgroundColor: '#fff',
        buttonColor: '#2a8dce'
    },
    dial: {
        dialBackgroundColor: '#2a8dce',
    },
    clockFace: {
        clockFaceBackgroundColor: '#ddd',
        clockHandColor: '#2a8dce',
        clockFaceTimeInactiveColor: '#222'
    }
};

export const addRanking = arr => {
  arr.forEach((item, i) => {
    if(item)
      item['rank'] = (i + 1)
  })
}
