import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { AuthService } from 'app/main/pages/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddVerifierService implements Resolve<any> {
  maxAvailableRecordsInTable
  allCountries: {}[] = []
  assignedEvents = []
  states = []
  permissions: any;
  spaCountryCode;
  seletedCountryCode;
  fullNumber;
  toSeparatCode;
  scanedList: any;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
    private _matSnackBar: MatSnackBar,
    private _authService: AuthService
  ) { }

  public verifier;
  reqHeadersWithToken = new HttpHeaders({
    'Authorization': localStorage.getItem('userToken'),
    'Content-Type': 'application/json'
  });


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      if (route.queryParams.id == undefined) {
        Promise.all([
          // this.getAllCountries(),
          this._commonService.getAllCountries().then((result: any) => {
            if(result.length)
            this.allCountries = result
            else
            this.allCountries = result
          })
        ]).then(
          () => {
            resolve('');
          },
          reject
        );
      } else {

        Promise.all([
          this.getVerifierById(route.queryParams.id),
          this.getAssignedEvents(route.queryParams.id),
          this._commonService.getAllCountries().then((result: any) => {
            if(result.length)
            this.allCountries = result;
            else
            this.allCountries = result;
          })
        ]).then(
          () => {
            resolve('');
          },
          reject
        );
      }
    });
  }

  getAllScanedEventList(payload){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) =>{
      this._httpClient.post(environment.apiURL + 'admin/scannedTickets', payload, this._commonService.getHeader())
      .subscribe((response: any) => {
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          this.maxAvailableRecordsInTable = response.data.totalRecords;
          this.scanedList = response.data.verifierScannedList;
          resolve(response)
        }else if(response.status == 400){
          this.scanedList = []
          resolve(response)
        }else{
          reject()
        }
      })
    })
  }

  getAssignedEvents(verifierId, filter?) {
    let payload
    if (filter) {
      payload = {
        verifierId: verifierId,
        ...filter
      }
    } else {
      payload = {
        verifierId: verifierId
      }
    }
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/verifier/getall/assignedevent', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.maxAvailableRecordsInTable = response.data.totalRecords
            this.assignedEvents = response.data.data
          }
          else
            this.assignedEvents = []

          resolve(response);
        }, reject);
    });
  }

  unassignEvent(eventId, verifierId) {
    this._fuseProgressBarService.show();
    let payload = {
      verifierId: verifierId,
      eventId: eventId
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/verifier/unassign/event', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          if (response.status == 200)
            resolve(response);
          else
            reject('')

          this._matSnackBar.open(response.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 4000
          })
        }, reject);
    });
  }

  deleteVerifier(id) {
    this._fuseProgressBarService.show();

    const payload = {
      verifierId: id
    }
    const options:any = {
      headers: this.reqHeadersWithToken,
      body: payload
    }
    return new Promise((resolve, reject) => {

      this._httpClient.delete(environment.apiURL + 'admin/verifier/deleteverifier', options)
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  getAllUnasignedEvents(verifierId) {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/verifier/getall/unassignedevents', { verifierId: verifierId }, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  getVerifierById(id) {
    this._fuseProgressBarService.show();
    const payload = {
      verifierId: id
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/verifier/getverifierbyid', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.verifier = response.data;
            this.fullNumber = response.data.mobile
            response.data.mobile = response.data.mobile.slice(-10);
            this.toSeparatCode =  parseInt(this.fullNumber.slice(0,-10));
          
            if (response.data.country) {
              this._commonService.getStates(response.data.country).then((result: any) => {
                if (result.status == 200) {
                  this.states = result.data
                  resolve(response);
                }
              })
            } else {
              this.states = []
              resolve(response);
            }
          } else
            reject(response)
        }, reject);
    });
  }

  addVerifier(data) {

    

    this._fuseProgressBarService.show();

    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/verifier/add', data, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  editVerifier(data) {
    this._fuseProgressBarService.hide();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + 'admin/verifier/updateverifier', data, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }



}
