import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PollService {
  eventList: any;
  pollId: any;
  pageType: any;
  pollDetail: any;

  constructor(private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
    private _httpClient: HttpClient) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.pollId = route.params.id;
    this.pageType = route.params.type;

    console.log("this.pollId,this.pageType ===>", this.pollId, this.pageType)
    return new Promise((resolve, reject) => {


      Promise.all([
        this._fuseProgressBarService.show(),
        this.getallpublishedevents()
        // events/getallpublishedevents

      ]).then(
        () => {
          console.log("this.pollDetail,this.eventList ===>", this.pollDetail, this.eventList)
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );

    });
  }


  getallpublishedevents() {
    return new Promise((resolve, reject) => {
      this._commonService.getUpcommingPublishedEvents({}).then((result: any) => {
        if (result.status == 200) {
          this.eventList = result.data.data;
          if (this.pageType === 'edit' || this.pageType === 'detail') {
            this.getPoll().then((result) => { resolve('') })
          } else {
            resolve('')
          }

        } else {
          this.eventList = []
          resolve('')
        }
      })
    })

  }


  addPoll(payload) {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/poll/add', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  editPoll(payload) {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + 'admin/poll/edit', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  publishPoll() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + `admin/poll/publish/${this.pollId}`, {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }


  pollEnd() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + `admin/poll/end/${this.pollId}`, {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }

  getPoll() {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + `admin/poll/get/${this.pollId}`, this._commonService.getHeader())
        .subscribe((response: any) => {
          this.pollDetail = response.data;
          this._fuseProgressBarService.hide();
          resolve(response);
        }, reject);
    });
  }
}
