import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { AddTaxDialogComponent } from '@fuse/Dialog/add-tax-dialog/add-tax-dialog/add-tax-dialog.component';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { title } from 'process';
import { TaxesService } from './taxes.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent implements OnInit {

  displayedColumns: string[] = ['Sr', 'Title', 'amount', 'action'];
  dataSource;
  dialogRef;
  maxAvailableRecordsInTable;
  // maxAvailableRecordsInTable

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('search', { static: true }) search;
  adminId: string;

  constructor(
    private _taxesService : TaxesService,
    private _matDialog: MatDialog,
    private _ToolbarService : ToolbarService,
    private _matSnackBar: MatSnackBar,
    
    ) {

      this.adminId = localStorage.getItem('id');
      this.setToolBarTitle();
    
  }

  onPageChange(event){

  }



  
  ngOnInit(): void {
    // this.getAllTaxList();
    this._taxesService.taxList.subscribe((result) => { 
      this.dataSource = new MatTableDataSource(result);
      this.maxAvailableRecordsInTable = this._taxesService.totalTaxes;
    })
    // this.maxAvailableRecordsInTable = this._eventTypeService.maxAvailableRecordsInTable
   
  }

  
 setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Taxes');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }



  
  // getAllTaxList(){
  //   this._taxesService.getTaxlist().then((result:any) => {
  //     this.dataSource.data = result.data;
  //     console.log("table data ---->", this.dataSource);
  //   })
  // }

  // add new tax
  onAddTax(){
    this.dialogRef = this._matDialog.open(AddTaxDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus : false,
      width: '400px',
      data:''
    })
  }

  /* edit case */
  editTax(id, title, amount){
    this.dialogRef = this._matDialog.open(AddTaxDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '400px',
      data:{
        id: id,
        title: title,
        amount: amount
      }
    })
  }

  onDeleteTax(id){
    this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to remove this tax?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Tax?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    })

    this.dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this._taxesService.deleteTax(id).then((result:any) => {
          if(result.status == 200){
            this._matSnackBar.open(result.message, 'OK',{
              duration:2000
            })
          }
        })
      }
    })
  }



}
