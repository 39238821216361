import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResourcesListComponent } from './resources-list/resources-list.component'; 
import { RouterModule, Routes } from '@angular/router';
import { ResourcesService } from './resources.service';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';

const routes: Routes = [
  {
    path: '',
    component: ResourcesListComponent,
    resolve : {
      data : ResourcesService
    }
  }
]

@NgModule({
  declarations: [
    ResourcesListComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    MatIconModule,
    FuseSharedModule,
    MatButtonModule,
    MatTooltipModule,
    MatPaginatorModule,
    RouterModule.forChild(routes),
  ]
})
export class ResourcesModule { }