import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EventViewTabsComponent } from './event-view-tabs.component';
import { ViewEventComponent } from './event-info/view-event.component';
import { EventDomUsersListComponent } from './event-dome-users-list/event-dome-users-list.component';
import { EventTicketsListComponent } from './event-tickets-list/event-tickets-list.component';
import { EventBookingListComponent } from './event-booking-list/event-booking-list.component';
import { EventPollsSurveysListComponent } from './event-polls-surveys-list/event-polls-surveys-list.component'; 
import { DomeProfileLayoutComponent } from './event-dome-users-list/dome-profile-layout/dome-profile-layout.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { DomeUserProfileModule } from 'app/main/events/view-event/event-dome-users-list/dome-user-profile.module';
import { TracksComponent } from './event-track/tracks/tracks.component';
import { TrackLayoutComponent } from './event-track/track-layout.component';
import { SocialSharePostComponent } from './social-share-post/social-share-post.component';
import { TicketScanningReportComponent } from './ticket-scanning-report/ticket-scanning-report.component';

const routes: Routes = [  
    {
      path : 'info',
      component : ViewEventComponent
    },
    {
      path : 'registration',
      component : EventBookingListComponent
    },
    {
      path : 'tickets',
      component : EventTicketsListComponent
    },
    {
      path : 'tracks',
      component : TrackLayoutComponent,
      children : [
        {path : '', loadChildren: () => import('app/main/events/view-event/event-track/event-track.module').then(m => m.EventTrackModule)}
      ]
    },
    {
      path : 'polls&surveys',
      component : EventPollsSurveysListComponent
    },
    {
      path : 'network',
      component : DomeProfileLayoutComponent,
      children: [
        { path : '', loadChildren: ()=> import('app/main/events/view-event/event-dome-users-list/dome-user-profile.module').then(m => m.DomeUserProfileModule)}
      ]
    },

    {
      path : 'ticket-scanning-report',
      component : TicketScanningReportComponent
    },
    {
      path : 'social-share', 
      component : SocialSharePostComponent
    }
    
]
@NgModule({
  declarations: [
    
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    DomeUserProfileModule,
    FusePipesModule
  ]
})
export class EventViewModule { }
