import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from "@angular/material/snack-bar";
import { ChangeProfileDialogComponent } from './change-profile-dialog/change-profile-dialog.component';
import {MatBadgeModule} from '@angular/material/badge';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonService } from '@fuse/services/common.service';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        ToolbarComponent,
        ChangePasswordDialogComponent,
        ChangeProfileDialogComponent
    ],
    imports: [
        MatDatepickerModule,
        MatProgressSpinnerModule,
        RouterModule,
        MatButtonModule,
        MatIconModule,
        NgxMaterialTimepickerModule,
        MatMenuModule,
        MatToolbarModule,
        NgxFileDropModule,
        FuseSharedModule,
        NgxMatDatetimePickerModule,
        MatSlideToggleModule,
        NgxIntlTelInputModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        MatTooltipModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatInputModule,
        MatSelectModule,
        MatSnackBarModule,
        MatBadgeModule
    ],
    exports: [
        ToolbarComponent,
    ],
    providers: [CommonService]
})
export class ToolbarModule
{
}
