import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.scss']
})
export class AddLocationComponent implements OnInit {

  form:UntypedFormGroup;
  latitude: number;
  longitude: number;
  zoom: number = 8;
  address: string;
  private geoCoder;
  mapLoadingTimes = 0;

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(
    private _fb : UntypedFormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private _matDialogRef : MatDialogRef<AddLocationComponent>
  ) { 
   
  }

  ngOnInit(): void {

    this.setCurrentLocation();
    this.loadPlaceAutoComplete();
    this.createForm();

  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  createForm(){
    return this.form = this._fb.group({
      locationTitle : ['', Validators.required], 
      location: [''],
      lat : [''],
      long : ['']
    })
  }

  markerDragEnd($event: MouseEvent) {
    console.log("markerDragEnd", $event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log("getAddress, result", results);
      console.log("status -->", status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;

          console.log("this.mapLoadingTimes", this.mapLoadingTimes);
          console.log("this is result", results[0]);

          // if (this.mapLoadingTimes > 1) {
            this.handleAddressChange(results[0])
          // }

          this.mapLoadingTimes++

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  public handleAddressChange(address) {

    const addCompLength = address.address_components.length
    console.log(address)

    const addressParts = {

      landMark: address.address_components.filter(item => {
        if (item.types[2] == 'sublocality_level_1')
          return item.long_name
      })[0],

      city: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_2')
          return item.long_name
      })[0],

      state: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_1')
          return item.long_name
      })[0],

      // country: address.address_components.filter(item => {
      //   if (item.types[0] == "country")
      //     return item.long_name
      // })[0],

      zipcode: address.address_components.filter(item => {
        if (item.types[0] == "postal_code")
          return item.long_name
      })[0],
    }

    // if (addressParts.locality)
    //   this.venueForm.get('locality').setValue(addressParts.locality.long_name)
    if (addressParts.landMark)
    //   this.form.get('landMark').setValue(addressParts.landMark.long_name)
    // if (addressParts.city)
    //   this.venueForm.get('city').setValue(addressParts.city.long_name)
    // if (addressParts.state)
    //   this.venueForm.get('state').setValue(addressParts.state.long_name)

    // if (addressParts.country)
    //   this.venueForm.get('country').setValue(addressParts.country.long_name)

    // if (addressParts.zipcode)
    //   this.venueForm.get('zipcode').setValue(addressParts.zipcode.long_name)
    console.log("address", address);
    this.form.get('lat').setValue(address.geometry.location.lat())
    this.form.get('long').setValue(address.geometry.location.lng())
    this.form.get('locationTitle').setValue(address.formatted_address)

  }

  loadPlaceAutoComplete() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)
        });
      });
    });
  }

  onAddLocation(){
    this._matDialogRef.close(this.form.value);
  }
}
