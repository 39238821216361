import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
    selector     : 'reset-password',
    templateUrl  : './reset-password.component.html',
    styleUrls    : ['./reset-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ResetPasswordComponent implements OnInit, OnDestroy{
    showError;
    resetPasswordForm: UntypedFormGroup;
    isPassVisible : boolean = false;
    isConPassVisible : boolean = false;
    paramsId:any;
    isPasswordChanged : boolean = false;
    appLink:string = 'https://eventlocal.app.link/QhIH9ZySVEb';
    private _unsubscribeAll: Subject<any>;
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private _fuseProgressBarService: FuseProgressBarService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private HttpClient: HttpClient,
        private _commonservice: CommonService,
        private snackBar:MatSnackBar,
       
    )
    {
        this._activatedRoute.params.subscribe((params) => {
          if(params)
          this.paramsId = params.id
        })
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    /**
     * On init
     */
    ngOnInit(): void
    {
        // this.route.paramMap.switchMap((params: ParamMap) => {
        //     let user_id = params.get('id');
    
        //     return this.userService.get(user_id);
        // })
        // .subscribe(res => this.user = res); // assuming user is being returned from this.userService.get()
        this.resetPasswordForm = this._formBuilder.group({
            // name           : ['', Validators.required],
            // email          : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
        });
        
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.resetPasswordForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
            });
    }
    
    onSubmit(){
        const payload = {
            password:this.resetPasswordForm.get('password').value,
            // id:this.paramsId
        }
        this.resetPassword(payload, this.paramsId)
    }
    
    resetPassword(payload,tokan){
        this._fuseProgressBarService.show();
        this._commonservice.pass(payload, tokan).then((result:any)=>{ 
            if(result.status == 200){
                this.snackBar.open(result.message,"Okay",{duration:2000})
                this.isPasswordChanged = true;
                console.log("res", );
                
            }else{
                this.snackBar.open(result.message,"Okay",{duration:2000})
                this.isPasswordChanged = false;
            }
            this._fuseProgressBarService.hide();
        }).catch((err) => {
            console.log('err',err);
            this._fuseProgressBarService.hide();
        })
    };
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
/**
 * Confirm password validator
 *
 * @param {AbstractControl} control
 * @returns {ValidationErrors | null}
 */
export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if ( !control.parent || !control )
    {
        return null;
    }
    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');
    if ( !password || !passwordConfirm )
    {
        return null;
    }
    if ( passwordConfirm.value === '' )
    {
        return null;
    }
    if ( password.value === passwordConfirm.value )
    {
        return null;
    }
    return {passwordsNotMatching: true};
};