import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-webhooks-list',
  templateUrl: './webhooks-list.component.html',
  styleUrls: ['./webhooks-list.component.scss'],
  animations : fuseAnimations
})
export class WebhooksListComponent implements OnInit {

  constructor(
    private _ToolbarService : ToolbarService,
  ) { 
    this.setToolBarTitle();
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Webhook');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  ngOnInit(): void {
  }

}
