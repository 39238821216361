import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventsListComponent } from './events-list/events-list.component';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GooglePlaceModule } from "ngx-google-places-autocomplete"; 
import { EventListService } from './events-list/event-list.service';
import { AddEditEventService } from './edit-event/add-edit-event.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatRadioModule} from '@angular/material/radio';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import {MatMenuModule} from '@angular/material/menu';
import { QuillModule } from 'ngx-quill'; 
import { AssignDistributorComponent } from './assign-distributor/assign-distributor.component';
import { AssignDistributorService } from './assign-distributor/assign-distributor.service';
import { VerifierListDialogComponent } from './edit-event/verifier-list-dialog/verifier-list-dialog.component';
import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { BuyerDetailsConfigurationComponent } from './edit-event/buyer-details-configuration/buyer-details-configuration.component';
import { MatChipsModule } from '@angular/material/chips';
import { EventTicketListsComponent } from './event-ticket-lists/event-ticket-lists.component';
import { MatNativeDateModule, MatRippleModule} from '@angular/material/core'; 
import { SlotBookingComponent } from './slot-booking-details/slot-booking/slot-booking.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { CreateEventComponent } from './create-event/create-event.component';
import {MatStepperModule} from '@angular/material/stepper';  
import {MatTabsModule} from '@angular/material/tabs';
import { ViewEventComponent } from './view-event/event-info/view-event.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { LyButtonModule } from '@alyle/ui/button';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyIconModule } from '@alyle/ui/icon';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { TimezoneService } from '@fuse/services/timezone.service';
import { DragDropModule } from '@angular/cdk/drag-drop'; 
import { EventBookingListComponent } from './view-event/event-booking-list/event-booking-list.component';
import { EventViewTabsComponent } from './view-event/event-view-tabs.component';
import { EventTicketsListComponent } from './view-event/event-tickets-list/event-tickets-list.component'; 
import { NgxPaginationModule } from 'ngx-pagination';
import { EventPollsSurveysListComponent } from './view-event/event-polls-surveys-list/event-polls-surveys-list.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { EventViewModule } from 'app/main/events/view-event/event-view.module';
import { AddEditCommonDataProviderService } from './create-event/add-edit-common-data-provider.service';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { SocialSharePostComponent } from './view-event/social-share-post/social-share-post.component';
import {MatSliderModule} from '@angular/material/slider';
import { ColorPickerModule } from 'ngx-color-picker';
import { TicketScanningReportComponent } from './view-event/ticket-scanning-report/ticket-scanning-report.component';
import { CertificateModule } from "../certificate/certificate.module";

interface CustomNgxMatDateFormats extends NgxMatDateFormats {
    dayLabel?: string;
    dayOfWeek?: string;
}

// export const MOMENT_DATETIME_WITH_SECONDS_FORMAT = 'DD-MMM-YYYY hh:mm';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
    parse: {
      dateInput: 'l, LTS'
    },
    display: {
      dateInput: 'YYYY-MM-DD HH:mm A',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    }
};

  


const routes: Routes = [ 

    {
        path: '',
        component: EventsListComponent,
        resolve: {
            data: EventListService 
        }
    },

    {
        path: 'add',
        component: CreateEventComponent,
        resolve: {
            data : AddEditCommonDataProviderService,
        },
        canActivate:[RoleAuthGuardService]
    },

    {
        path: 'view/:id',
        component: EventViewTabsComponent,
        canActivate:[RoleAuthGuardService],
        resolve: {
            data: AddEditEventService
        },
        children : [
            {path: '', loadChildren: () => import('app/main/events/view-event/event-view.module').then(m => m.EventViewModule), resolve : AddEditEventService},
        ]
    },
    

    // { path: 'ticket', component: EventTicketsListComponent, data: { label: 'ticket' } },
    // { path: 'Info', component: ViewEventComponent, data: { label: 'Event Info' } },

    // {
    //     path: 'dome-users/:id',
    //     component: EventDomUsersListComponent,
    //     resolve: {
    //         data: EventDomUsersService
    //     },
    //     // canActivate:[RoleAuthGuardService]
    // },

    {
        path: 'edit/:id',
        component: CreateEventComponent,
        resolve: {
            commonData : AddEditCommonDataProviderService,
            // data: AddEditEventService
        }
    },

    {
        path: 'distributor/:id',
        component: AssignDistributorComponent,
        resolve:{
            data2:AddEditEventService,
            data:AssignDistributorService
        },
        canActivate:[RoleAuthGuardService]
    },

    {
        path: 'slotdetails',
        component: SlotBookingComponent
    }
    
]

@NgModule({
    declarations: [EventsListComponent, SocialSharePostComponent, TicketScanningReportComponent, AssignDistributorComponent, VerifierListDialogComponent, BuyerDetailsConfigurationComponent, EventTicketListsComponent, SlotBookingComponent, CreateEventComponent, ViewEventComponent, EventBookingListComponent, EventViewTabsComponent, EventTicketsListComponent, EventPollsSurveysListComponent],
    providers: [
        TimezoneService,
        // {
        //     provide: NgxMatDateAdapter,
        //     useClass: CustomNgxDatetimeAdapter,
        //     deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        // },
        // { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS }
        AddEditCommonDataProviderService
    ],
    imports: [ 
        CommonModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule, 
        MatCardModule,
        // CertificateModule,  
        MatStepperModule,
        MatSliderModule,
        ClipboardModule,
        LyImageCropperModule,
        DragDropModule,
        LySliderModule,
        ColorPickerModule,
        LyButtonModule,
        LyIconModule,
        FusePipesModule,
        EventViewModule,
        LyDialogModule,
        MatButtonModule,
        MatProgressBarModule,
        NgxShimmerLoadingModule,
        FormsModule,
        MatTabsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatSnackBarModule,
        MatNativeDateModule,
        MatTableModule,
        MatSelectModule,
        MatDatepickerModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        NgxMaterialTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        QuillModule.forRoot(),
        NgxDaterangepickerMd.forRoot(),
        MatMenuModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatToolbarModule,
        MatDialogModule,
        NgxFileDropModule,
        NgxPaginationModule,
        MatCheckboxModule,
        MatChipsModule,
        CertificateModule
    ]
})

export class EventsModule { }

