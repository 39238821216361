import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, Subject } from 'rxjs';
// import * as CryptoJS from 'crypto-js';
// import { ApiEndPointUrl } from '../../../@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

    reqHeaderLogin = new HttpHeaders({ 'Content-Type': 'application/json' });
    isUserLogin: boolean = false;
    routeParams: any;
    onTokenChanged: Subject<any>;
    userPermissions: any = null;

    constructor(
        private _httpClient: HttpClient,
        private _matSnackBar: MatSnackBar,
        private _commonService: CommonService
    ) {

        // set Defaults

        this.onTokenChanged = new Subject();

    }



    /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.checktoken()
            ]).then(
                () => {
                    resolve('');
                },
                (error) => {
                    console.log('Error in navigation :', error);
                    alert('Sorry!! Cannot navigate to Page Expired !! ');
                    reject();
                }
            );
        });
    }



    /**
     * Login user
     *
     * @param cred 
     * @returns {Promise<any>}
     */
    userLogin(cred): Promise<any> {
        console.log("cred-->", cred);
        //  cred.password = CryptoJS.AES.encrypt(cred.password.trim(), environment.client_secret.trim()).toString();

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/login', cred, { headers: this.reqHeaderLogin })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.isUserLogin = true;
                        localStorage.setItem('currencyId', response.data.mySettings.currency);
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 2000,
                        })
                    } else {
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    resolve(response);
                }, reject);
        });
    }

    adminSignUp(cred): Promise<any> {
        console.log("cred-->", cred);
        //  cred.password = CryptoJS.AES.encrypt(cred.password.trim(), environment.client_secret.trim()).toString();

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/adminSignUp', cred, { headers: this.reqHeaderLogin })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.isUserLogin = true;
                        localStorage.setItem('currencyId', response.data.mySettings.currency);
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 2000,
                        })
                    }else if(response.status == 401){
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    else {
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    resolve(response);
                }, reject);
        });
    }

    register2fa(): Promise<any> {
        //  cred.password = CryptoJS.AES.encrypt(cred.password.trim(), environment.client_secret.trim()).toString();
        return new Promise((resolve, reject) => {
            let obs = this._httpClient.get(environment.apiURL + 'admin/create/authuser', this._commonService.getHeader())
            obs.subscribe((response: any) => {
                if (response.status == 200) {
                    this._matSnackBar.open(response.message, 'OK', {
                        duration: 2000,
                    })
                    // localStorage.setItem('currencyId', response.data.mySettings.currency);
                } else {
                    this._matSnackBar.open(response.message, 'OK', {
                        duration: 5000,
                    })
                }
                resolve(response);
            }, reject);
        });
    }

    forgotPasswordRequest(email: string): Observable<any>
    {
        const payload = {
            "email": email
        }
        return this._httpClient.post(environment.apiURL + 'admin/forgotPassword', payload);
    }





    /**
     * Forgot Password user
     *
     * @param cred 
     * @returns {Promise<any>}
     */
    forgotPassword(cred): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'api/v2/forgotpassword', cred, { headers: this.reqHeaderLogin })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        localStorage.setItem('currencyId', response.data.mySettings.currency);
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 2000,
                        })
                    } else {
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    resolve(response);
                }, reject);
        });
    }




    /**
  * Checks if user have valid token for accessing Reset Password Page
  *
  * 
  * @returns {Promise<any>}
  */
    checktoken(): Promise<any> {


        // const url = this.routeParams;

        // console.log('token in Params :', this.routeParams);

        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'api/v2/resetpasswordlink/check/' + this.routeParams.token, { headers: this.reqHeaderLogin })
                .subscribe((res: any) => {
                    // console.log('Reset Response :', res);
                    if (res.response == 1) {

                        resolve(res);
                    }
                    else {
                        reject('Link Expired');
                    }

                }, reject);
        });
    }




    resetPasswordViaLink(tokenPacket): Promise<any> {

        const toSend = {

            "id": this.routeParams.token,
            "password": tokenPacket

        }

        // console.log('this.tokenPacket :', tokenPacket);
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'api/v2/resetpassword', toSend, { headers: this.reqHeaderLogin })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        localStorage.setItem('currencyId', response.data.mySettings.currency);
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 2000,
                        })
                    } else {
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    resolve(response);
                }, reject);

        });


    }



    /**
        * Create user
        *
        * @param userDetails 
        * @returns {Promise<any>}
        */
    userRegister(userDetails): Promise<any> {
        let reqHeaders = new HttpHeaders({
            'Content-Type': 'application/json'
        });

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'api/v2/account/signup', userDetails, { headers: reqHeaders })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.isUserLogin = true;
                        localStorage.setItem('currencyId', response.data.mySettings.currency);
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 2000,
                        })
                    } else {
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    resolve(response);
                }, reject);
        });
    }

    /**
        * Verify otp
        *
        * @param otp 
        * @returns {Promise<any>}
        */
    verifyOtp(otp): Promise<any> {
        let reqHeaders = new HttpHeaders({
            'x-access-token': localStorage.getItem('userToken'),
            'Content-Type': 'application/json'
        });

        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'api/v2/account/verify-email', otp, { headers: reqHeaders })
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        localStorage.setItem('currencyId', response.data.mySettings.currency);
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 2000,
                        })
                    } else {
                        this._matSnackBar.open(response.message, 'OK', {
                            duration: 5000,
                        })
                    }
                    resolve(response);
                }, reject);
        });
    }

    // manage permissions for diff roles

    getPermissions() {
        // return JSON.parse(localStorage.getItem('permission'))
        // console.log("get permissions", this.userPermissions);
        return this.userPermissions
    }

    getPermissionsOnRefresh() {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/getmy/permission', this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.setPermissions(response.data);
                        console.log("my permission --->", response.data);
                    } else {
                        this.setPermissions([])
                    }
                    resolve(response);

                }, reject);
        });
    }

    getPermissionsForCurrentPage(route: ActivatedRouteSnapshot) {
        const permissions = this.getPermissions()
        // console.log("reported events permissions",permissions[19]);
        // console.log('route->*', route)

        const index = permissions.findIndex((item) => {
            if (route.pathFromRoot[1].routeConfig.path == 'masters')
                return item.url == '/' + route.pathFromRoot[1].routeConfig.path + '/' + route.pathFromRoot[2].routeConfig.path
            else
                return item.url == '/' + route.pathFromRoot[1].routeConfig.path
        })

        if (index >= 0)
            return permissions[index].permission;
        else
            return {}
    }

    setPermissions(permissions) {
        localStorage.setItem('permission', permissions)
        this.userPermissions = permissions
    }

    noPermissionMessage() {
        this._matSnackBar.open('Not Permitted', "OK", {
            duration: 4000
        })
        return false
    }

}
