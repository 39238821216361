import { Component, ChangeDetectionStrategy, Inject, ViewChild, AfterViewInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { StyleRenderer, WithStyles, lyl, ThemeRef, ThemeVariables } from '@alyle/ui';
import { LyDialogRef, LY_DIALOG_DATA } from '@alyle/ui/dialog';
import { LySliderChange, STYLES as SLIDER_STYLES } from '@alyle/ui/slider';
import {STYLES as CROPPER_STYLES,LyImageCropper,ImgCropperConfig,ImgCropperEvent,ImgCropperErrorEvent} from '@alyle/ui/image-cropper';

const STYLES = (_theme: ThemeVariables, ref: ThemeRef) => {
  ref.renderStyleSheet(SLIDER_STYLES);
  ref.renderStyleSheet(CROPPER_STYLES);
  const slider = ref.selectorsOf(SLIDER_STYLES);
  const cropper = ref.selectorsOf(CROPPER_STYLES);

  return {
    root: lyl `{
      ${cropper.root} {
        max-width: 500px
        height: 390px
      }
    }`,
    sliderContainer: lyl `{
      position: relative
      ${slider.root} {
        width: 80%
        position: absolute
        left: 0
        right: 0
        margin: auto
        top: -32px
      }
    }`,
    slider: lyl `{
      padding: 0.2em
    }`
  };
};


@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    StyleRenderer
  ],
  
})
export class CropperComponent implements OnInit {
 
  ready: boolean;
  scale: number;
  minScale: number;
  @ViewChild(LyImageCropper, { static: true }) cropper: LyImageCropper;
  
  myConfig: ImgCropperConfig = {
    width: 250,
    height: 250,
    type: 'image/png',
    keepAspectRatio: true,
    responsiveArea: true,
    round : false,
    output: {
      width: 200,
      height: 200
    },
    resizableArea: true
  };
  // ref: renderStyleSheet(SLIDER_STYLES);
  // ref.renderStyleSheet(CROPPER_STYLES);
  // const slider = ref.selectorsOf(SLIDER_STYLES);
  // const cropper = ref.selectorsOf(CROPPER_STYLES);


  // fileName;
  // imageChangedEvent: any = '';
  // imageFileToCrop;
  // croppedImageBase64: any = '';
  // croppedImageFile;
  // imageStatus: boolean = false;
  // errorMsg: boolean = false;
  // sliderVal
  // ratio = 1 / 1;
  // imageData: any;
  // imageType: any;
  // fileObj;
  readonly classes;
  constructor( 
    readonly sRenderer: StyleRenderer,
    private _cd : ChangeDetectorRef,
    // @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(LY_DIALOG_DATA) private data: any,
    public dialogRef: LyDialogRef,
    // private dialogRef: MatDialogRef<CropperComponent>,
    private _matSnakeBar: MatSnackBar
  ) {
    this.classes = this.sRenderer.renderSheet(STYLES, 'root');

    this.myConfig = data['myConfig'];
    // this._cd.detectChanges();
    console.log("this is image data", data);

    // this.imageData = data;
    // this.imageType = data.message ? data.message : '';
    // this.fileObj = data?.imageFile;

    // if (data?.notAllowedType == 'png') {
    //   if (this?.data?.imageFile?.type == 'image/jpg') {
    //   } else if (this?.data?.imageFile?.type == 'image/jpeg') {
    //   } else {
    //     this.dialogRef.close(false);
    //     this._matSnakeBar.open('JPEG and JPG image are allowed.', 'OKAY', { duration: 3000 });
    //   }
    // }

    // if (this?.data?.imageFile?.type == 'image/avif') {
    //   this.dialogRef.close(false);
    //   this._matSnakeBar.open('Invalid image type. Please Select JPG, JPEG, PNG file Type', 'OKAY', { duration: 3000 });
    // }

    // if (this.data.imageEventToCrop) {
    //   this.imageChangedEvent = this.data.imageEventToCrop;
    //   this.fileName = this.data.imageEventToCrop.target.files[0].name;
    // }

    // if (this.data.imageFile) {
    //   this.imageFileToCrop = this.data.imageFile
    //   this.fileName = this.data.imageFile.name;
    // }

    // this.ratio = this.data.ratio
  }


  ngAfterViewInit() {
    // Load image when dialog animation has finished
    this.dialogRef.afterOpened.subscribe(() => {
      this.cropper.selectInputEvent(this.data['fileEvent']);
    });
  }

  
  onCropped(e: ImgCropperEvent) {
    console.log('cropped img: ', e);
  }
  onLoaded(e: ImgCropperEvent) {
    console.log('img loaded', e);
  }
  onError(e: ImgCropperErrorEvent) {
    console.warn(`'${e.name}' is not a valid image`, e);
    // Close the dialog if it fails
    this.dialogRef.close();
  }
  onSliderInput(event: LySliderChange) {
    this.scale = event.value as number;
  }


  // imageCropped(event: ImageCroppedEvent) {
  //   console.log("event", event);
  //   const imageBase64 = event.base64;
  //   console.log("before==>", this.imageCompress.byteCount(imageBase64));
  //   if(this.imageCompress.byteCount(imageBase64) > Number(1000000)){

  //     const imageCompresstionData = {
  //       imageBase64 : imageBase64,
  //       orientation : -1,
  //       ratio : this.imageCompress.byteCount(imageBase64) < 1500000 ? 80 :  this.imageCompress.byteCount(imageBase64) < 2000000 ? 60 : this.imageCompress.byteCount(imageBase64) < 25000000 ? 55 : this.imageCompress.byteCount(imageBase64) < 35000000 ? 50 : this.imageCompress.byteCount(imageBase64) < 45000000 ? 45 : this.imageCompress.byteCount(imageBase64) < 5000000 ? 40 : this.imageCompress.byteCount(imageBase64) < 6000000 ? 30: this.imageCompress.byteCount(imageBase64) < 8000000 ? 20 : 10, 
  //       quality : this.imageCompress.byteCount(imageBase64) < 1500000 ? 80 :  this.imageCompress.byteCount(imageBase64) < 2000000 ? 60 : this.imageCompress.byteCount(imageBase64) < 25000000 ? 55 : this.imageCompress.byteCount(imageBase64) < 35000000 ? 50 : this.imageCompress.byteCount(imageBase64) < 45000000 ? 45 : this.imageCompress.byteCount(imageBase64) < 5000000 ? 40 : this.imageCompress.byteCount(imageBase64) < 6000000 ? 30: this.imageCompress.byteCount(imageBase64) < 8000000 ? 20 : 10, 
  //     }

  //     console.log("imageCompresstionData", imageCompresstionData);
      
  //     this.imageCompress.compressFile(imageCompresstionData['imageBase64'], imageCompresstionData['orientation'], imageCompresstionData['ratio'], imageCompresstionData['quality']).then(
  //       result => {
  //         this.croppedImageBase64 = result;
  //         console.log("after===>", this.imageCompress.byteCount(result)); 
  //         console.log("one layer after", this.imageCompress.byteCount(this.croppedImageBase64)); 
  //         let imageBlob = base64ToFile(this.croppedImageBase64)
  //         let height = event.height;
  //         let width = event.width;
  //         let imageRatio = this.imageData.ratio;
  //         if (this.imageType == 'square') {
  //           if (height >= 400 && width >= 400) {
  //             this.croppedImageFile = new File([imageBlob], this.data.imageFile.name,
  //               { type: this.data.imageFile.type },
  //             );
  //             this.imageStatus = true;
  //             this.errorMsg = false;
  //           } else {
  //             this.imageStatus = false;
  //             this.errorMsg = true;
  //           }
  //         } else if (this.imageType == 'EventBanner') {
  //           if (height >= 500 && width >= 800) {
  //             this.croppedImageFile = new File([imageBlob], this.data.imageFile.name,
  //               { type: this.data.imageFile.type },
  //             );
  //             this.imageStatus = true;
  //             this.errorMsg = false;
  //           } else {
  //             this.imageStatus = false;
  //             this.errorMsg = true;
  //           }
  //         } else if (this.imageType == 'ticketBackground') {
  //           this.croppedImageFile = new File([imageBlob], this.data.imageFile.name,
  //             { type: this.data.imageFile.type },
  //           );
  //           this.imageStatus = true;
  //           this.errorMsg = false;
  //         } else {
  //           this.croppedImageFile = new File([imageBlob], this.data.imageFile.name,
  //             { type: this.data.imageFile.type },
  //           );
  //           this.imageStatus = true;
  //           this.errorMsg = false;
  //         }
  //       }
  //     ); 
  //   }
  //   else{
  //     this.croppedImageBase64 = event.base64;
  //     let imageBlob = base64ToFile(event.base64)
  //     let height = event.height;
  //     let width = event.width;
  //     let imageRatio = this.imageData.ratio;
  //     if(this.imageType == 'square'){
  //       if(height >= 400 && width >= 400){
  //           this.croppedImageFile = new File([imageBlob], this.data.imageFile.name, 
  //             {type:this.data.imageFile.type},
  //           );
  //           this.imageStatus = true;
  //           this.errorMsg = false;
  //         }else{
  //           this.imageStatus = false;
  //           this.errorMsg = true;
  //       }
  //     }else if(this.imageType == 'EventBanner'){
  //       if(height >= 500 && width >= 800){
  //           this.croppedImageFile = new File([imageBlob], this.data.imageFile.name, 
  //             {type:this.data.imageFile.type},
  //           );
  //           this.imageStatus = true;
  //           this.errorMsg = false;
  //       }else{
  //           this.imageStatus = false;
  //           this.errorMsg = true;
  //       }
  //     }else if(this.imageType == 'ticketBackground'){
  //         this.croppedImageFile = new File([imageBlob], this.data.imageFile.name, 
  //           {type:this.data.imageFile.type},
  //         );
  //         this.imageStatus = true;
  //         this.errorMsg = false;
  //     }else{
  //       this.croppedImageFile = new File([imageBlob], this.data.imageFile.name, 
  //         {type:this.data.imageFile.type},
  //       );
  //       this.imageStatus = true;
  //       this.errorMsg = false;
  //     }
  //   }
  // }

  // imageLoaded(image: HTMLImageElement) {
  //   // show cropper
  // }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }

  ngOnInit() {
  }

  public blobToFile = (theBlob: Blob, fileName: string, fileType, size): File => {
    const b: any = theBlob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = new Date();
    b.name = fileName;
    b.type = fileType;
    b.size = size;

    //Cast to a File() type
    return <File>theBlob;
  }

  base64ToBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length); 
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    } 
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }


  onSubmit(){
    const base64Image = this.cropper.crop().dataURL;
    const base64Data = base64Image.split(',')[1];
    const blobImage = this.base64ToBlob(base64Data, this.cropper.crop().type); 

    this.dialogRef.close({file:this.cropper.crop(), blobFile : blobImage})
  }

  // onCropClick(): void {
  //   this.dialogRef.close({ croppedImageBase64: this.croppedImageBase64, croppedImageFile: this.croppedImageFile });
  // }
}
