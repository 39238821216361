import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { AuthService } from 'app/main/pages/auth.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CancelledBookingService implements Resolve<any> {
  allEvents: [] = [];
  allCancelledOrderList:[] = [];
  maxAvailableRecordsInTable:number;
  
  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _authService: AuthService,
    private _fuseProgressBarService: FuseProgressBarService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllCancelledOrders({"search": "","pageIndex": 1,"pageSize": 10,"startDate": 0,"endDate": 0,"eventId": 0,"ticketType":0,"slotId":0}),
        this.getAllEvents(),
      ]).then(() => {
        this._fuseProgressBarService.hide(),
        resolve('');
      }, reject)
    })
  }


  getAllEvents() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallevents', { "eventId": 64 }, this._commonService.getHeader())
        .subscribe((response: any) => {
          console.log("your new data is here");
          // this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.allEvents = response.data.data;
            // this.maxMember = this.allEvents
            resolve(response);
          }
          else if (response.status == 400) {
            this.allEvents = [];
            resolve(response);
          }
        }, reject);
    });
  }
  

  getAllCancelledOrders(body) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + `admin/cancelOrderList`, body, this._commonService.getHeader()).subscribe((response: any) => {
        if(response.status == 200){
          this.allCancelledOrderList = response.data.data;
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }
        resolve(response);
      }, reject);
    }) 
  }

   /* for filter by slot */
   getTicketCreatedEventsList(payload?){
    return new Promise((resolve, reject) =>{
        this._httpClient.post(environment.apiURL + 'admin/getTicketsGeneratedEvents',payload, this._commonService.getHeader())
        .subscribe((response: any) =>{
            if(response.status == 200){
                resolve(response);
            }else{
              reject
            }
          })
      })
  }

  


}
