import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-new-role-dialog',
  templateUrl: './new-role-dialog.component.html',
  styleUrls: ['./new-role-dialog.component.scss']
})
export class NewRoleDialogComponent implements OnInit {

  constructor(
    public matDialogRef: MatDialogRef<NewRoleDialogComponent>,
  ) { }

  ngOnInit() {
  }

}
