import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakerListComponent } from './speaker-list/speaker-list.component';
import { AddEditSpeakerComponent } from './add-edit-speaker/add-edit-speaker.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SpeakerService } from './speaker.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { LyDialogModule } from '@alyle/ui/dialog';
import { MatRippleModule } from '@angular/material/core';
import { HostTypesComponent } from './host-types/host-types.component';
import { HostTypesService } from './host-types/host-types.service';
import { HostGroupComponent } from './host-group/host-group.component';
import { HostGroupService } from './host-group/host-group.service';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatCheckboxModule } from '@angular/material/checkbox';

const routes: Routes = [
  {
    path: '', component: SpeakerListComponent,
    resolve: {data : SpeakerService}
  },
  {
    path: 'add', component : AddEditSpeakerComponent,
    resolve: {data : SpeakerService}
  },
  {
    path: 'edit/:id', component : AddEditSpeakerComponent,
    resolve: {data : SpeakerService}
  },
  {
    path: 'view/:id', component : AddEditSpeakerComponent,
    resolve: {data : SpeakerService}
  },
  {
    path: 'host-types', component : HostTypesComponent,
    resolve: {data : HostTypesService}
  },
  {
    path: 'host-group', component : HostGroupComponent,
    resolve: {data : HostGroupService}
  }
]

@NgModule({
  declarations: [SpeakerListComponent, AddEditSpeakerComponent, HostTypesComponent, HostGroupComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    NgxIntlTelInputModule,
    MatSelectModule,
    LyDialogModule,
    FormsModule,
    MatRippleModule,
    MatCheckboxModule,
    NgxFileDropModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule.forChild(routes),
  ]
})
export class SpeakerModule {}