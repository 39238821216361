import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router'

@Component({
  selector: 'app-referral-details',
  templateUrl: './referral-details.component.html',
  styleUrls: ['./referral-details.component.scss']
})
export class ReferralDetailsComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  referralId      
  ngOnInit() {
    this.route.params.subscribe((params: Params) => {
      this.referralId = params['ref_id'];
    })
    console.log('show details of the user with id is : ', this.referralId)
  }

  onReferralDelete(){
     console.log( 'user deleted : ', this.referralId);
  }

}
