import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations'; 
import { AuthService } from '../../auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector     : 'forgot-password',
    templateUrl  : './forgot-password.component.html',
    styleUrls    : ['./forgot-password.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations   : fuseAnimations
})
export class ForgotPasswordComponent implements OnInit
{ 
    showError;
    isEmailSent:boolean = false; 
    form: UntypedFormGroup; 
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _authService : AuthService,
        private _formBuilder: UntypedFormBuilder,
        private _ActivatedRoute : ActivatedRoute,
        private _matSnakeBar : MatSnackBar
    )
    {

        this._ActivatedRoute.queryParams.subscribe((res)=>{
            if(res.id){
                
            }
        })
        
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar   : {
                    hidden: true
                },
                toolbar  : {
                    hidden: true
                },
                footer   : {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.form = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
        });
    }

    onSubmit(){  
        if (this.form.invalid){ 
            return; 
        }else{
            // Disable the form
            this.form.disable();
            // Hide the alert
            this.showError = false;

            this._authService.forgotPasswordRequest(this.form.controls.email.value).subscribe((res:any)=> {
                if(res.status == 200){
                    this.form.enable();
                    this._matSnakeBar.open(res.message, 'OKAY', { duration : 2000});
                    this.isEmailSent = true;
                }else{
                    this.form.enable();
                    this._matSnakeBar.open(res.message, 'OKAY', { duration : 2000});
                }
            })
        }
    }

}
