import { Component, OnDestroy, OnInit } from '@angular/core';
import { BulkOrderService } from './bulk-order.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddOrderService } from '../add-order/add-order.service';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-bulk-order',
  templateUrl: './bulk-order.component.html',
  styleUrls: ['./bulk-order.component.scss']
})
export class BulkOrderComponent implements OnInit, OnDestroy {
  form:UntypedFormGroup
  allEvents: any;
  tickets: any = [];
  allSchedule: any = [];
  allTimeSlot: any = [];
  ticketType: any = [];
  isSubmiting : boolean = false;

  constructor(
    public _bulkOrderService:BulkOrderService,
    private _ToolbarService : ToolbarService,
    private _fb:UntypedFormBuilder,
    private _router: Router,
    public _commonService: CommonService
    // private _addOrderService: AddOrderService
  ) {
    this.setToolBarTitle();
   }

  ngOnInit() {
    this.createForm()
    this.getAllEventsList();
  }

  
  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Create Bulk Booking');
    // for btn
    this._ToolbarService._isBackBtnShow.next(true);
  }

  createForm(){
    this.form = this._fb.group({
      eventId:['',Validators.required],
      selectedDate:['',Validators.required],
      slotId: ['', Validators.required],
      ticketTypesId: ['', Validators.required],
      // ticketNumFrom:[''],
      totalTickets:['',Validators.required],
    })
  }

  getAllEventsList(){
    this._bulkOrderService.getAllEvents().then((result:any)=>{
      if (result.status == 200) {
        this.allEvents = result.data.data
        console.log("this.allEvents ", this.allEvents);
        
      }else{
        this.allEvents = [];
      }
    })
  }

  onChangeEvent(e) {
    this._bulkOrderService.changeEvent(e).then((result:any)=>{
      if (result.status == 200) {
        this.tickets = result.data.data
        this.allSchedule = this.tickets[0].schedule.filter((item) => item.timeSlot.length);
        console.log("after selete event ", this.allSchedule);
      }
      else if(result.status == 400){
        this.tickets = []
        this.allSchedule = [];
      }
       else {
        this.tickets = []
      }
    })
    this.form.get('selectedDate').setValue('');
    this.form.get('selectedDate').reset();

    this.form.get('slotId').setValue('');
    this.form.get('slotId').reset();

    this.form.get('ticketTypesId').setValue('');
    this.form.get('ticketTypesId').reset();

    this.form.get('totalTickets').setValue('');
    this.form.get('totalTickets').reset();

  }

  onDateSelect(val){
    const slots = this.allSchedule.filter(item => item.eventStartDate == val.value);
    
    this.allTimeSlot = slots[0].timeSlot;

    console.log("this.allTimeSlot---> ", this.allTimeSlot);

    this.form.get('slotId').setValue('');
    this.form.get('slotId').reset();

    this.form.get('ticketTypesId').setValue('');
    this.form.get('ticketTypesId').reset();

    this.form.get('totalTickets').setValue('');
    this.form.get('totalTickets').reset();

  }

  onSlotSelect(val){
    this.ticketType = this.tickets.filter(item => item.slotId == val.value);
    console.log("ticketType--->", this.ticketType);

    this.form.get('ticketTypesId').setValue('');
    this.form.get('ticketTypesId').reset();

    this.form.get('totalTickets').setValue('');
    this.form.get('totalTickets').reset();
  }

  eventTypeSalect(val){
    this.form.get('totalTickets').setValue('');
    this.form.get('totalTickets').reset();
  }

  onCreateBulkOrder(data){
    if(this.form.valid){
      this.isSubmiting = true;

      const payload = {
        "eventId": data.eventId,
        "slotId": data.slotId,
        "ticketId": data.ticketTypesId,
        "totalTicket": data.totalTickets,
      }
      
      // form reset here.
      this.form.disable();
        this._bulkOrderService.createBulkOrder(payload).then((result:any)=>{
          if (result.status == 200) {
            this._router.navigate(['/orders'])
          }else{
            this.isSubmiting = false;
            this.form.reset();
            this.form.enable();
          }
        })
      }
    }

   /* 
    *
    * On ngOnDestroy
    */
    ngOnDestroy(){
      
    }
    
 

}
