import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { SponsorService } from '../sponsor.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddEditSponsorComponent } from '@fuse/Dialog/add-edit-sponsor/add-edit-sponsor.component';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-sponsor-list',
  templateUrl: './sponsor-list.component.html',
  styleUrls: ['./sponsor-list.component.scss']
})
export class SponsorListComponent implements OnInit {
  dialogRef
  maxAvailableRecordsInTable:any=0;
  dataSource
  displayedColumns:string[];
  dialog;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('search', { static: true }) search;

  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onPageChange(event) {
    console.log("on change call");
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData();
  }

  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }
  
  constructor(
    private _ToolbarService : ToolbarService,
    private _matDialog : MatDialog,
    private _commonservice : CommonService,
    private _matSnake : MatSnackBar,
    private _SponsorService : SponsorService
  ) { 
    this.setToolBarTitle();
    this.maxAvailableRecordsInTable = this._SponsorService.maxAvailableRecordsInTable
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Sponsors');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  ngOnInit(): void {
    this.displayedColumns = ['image', 'name', 'about', 'link', 'action'];
    this.dataSource = new MatTableDataSource(this._SponsorService.allSponsors);
  }


  
  onSearch(val) {
    this.filter.pageIndex = 1;
    this.filter.searchFilter = val;
    this.paginator.pageIndex = 0;
    this.filterData();
  }


  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    this._SponsorService.getAllSponsors(payload['pageIndex'], payload['pageSize'], payload['search']).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.sponsorList
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }else if (result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
      }
    }).catch(()=>{
      this.dataSource.data = []
    })
  }


  openAddEdirSponsorDialog(modeType, id?){
    if(modeType == 'add'){
      this.openDialog(modeType);
    }
    else{
      console.log("this is id", id);
      this._SponsorService.getSponsorById(id).then((res:any)=>{
        if(res.status == 200){
          this.openDialog(modeType, res.data);
        }else{
          this._matSnake.open(res.message, "OKAY", {duration : 3000})
        }
      })
    }
    
    
  }

  openDialog(modeType, data?){
    this.dialog = this._matDialog.open(AddEditSponsorComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '400px',
      autoFocus : false, 
      data : {
        case : modeType,
        data : data ? data : undefined
      }
    })
    this.dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.filterData();
      }
    })
  }


  onDelete(item){
    console.log("item", item);
    
    this.dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this sponsor?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Sponsor?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    
    this.dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        if(item?.isDeletable){
          this.callDeleteApi(item)
        }else{
          this.showWarningMessage(item)
        }
      }
    })
  }

  showWarningMessage(item){
    const dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      disableClose: false,
      data : {
        message : ` This sponsor is part of an event. Deleting it will also remove the sponsor from those events. Are you sure you want to delete?`,
        image : '/assets/animated_icon/alarm.gif',
        imageSize : '150px',
        focus : 'right',
        deleteItem : 'Warning',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        this.callDeleteApi(item)
      }
    }) 
  }


  callDeleteApi(item){
    this._commonservice.deleteSponsor(item.id).then((res: any) => {
      if (res.status == 200) {
        this._matSnake.open(res.message, 'OKAY', { duration: 2000 })
        this.filterData();
      }
    })
  }


}
