import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatChipInputEvent } from "@angular/material/chips";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { forEach, indexOf } from "lodash";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { darkTheme } from "@fuse/utils/systemEnums";

// import { MatDatepicker, MatDatepickerInputEvent, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: "app-multiple-datepicker",
  templateUrl: "./multiple-datepicker.component.html",
  styleUrls: ["./multiple-datepicker.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MultipleDatepickerComponent implements OnInit {
  darkTheme = darkTheme;
  minDate:any = new Date();
  setSchedule: UntypedFormGroup;
  timeSlotEnable: boolean = false;
  timeClickToHide: boolean = false;
  timeBtnHide: boolean = false;
  dates: moment.Moment[] = [];
  myDateChips: any = [];

  rowLength: any;

  finalData = [];
  allTimeSlot = [];
  allDates: any = [];
  maxDate: Date;
  dateStamp: any = [];
  strtimeStamp: any = [];
  endtimeStamp: any = [];
  inEditCase: boolean = false;
  editDate: any = [];
  editTimeStartSlote: any = [];
  editTimeEndSlote: any = [];
  onlyTimeSlot: any = [];
  maxLength: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<MultipleDatepickerComponent>,
    private cd: ChangeDetectorRef
  ) {
    
    this.minDate.setDate(new Date().getDate() + 1);

    /* IN EDIT CASH */
    this.finalData = data.editCaseData;
    this.inEditCase = data.edit;
  }

  
  ngOnInit() {

    /* find timeSlot diffrent */
    // this.onlyTimeSlot = [];
    // this.finalData.forEach(element => {
    //   this.onlyTimeSlot = element.timeSlot;
    // });

    /* REMOVE ID HERE */
    this.finalData.forEach(element => {
      element.timeSlot = element.timeSlot.map(({ startTime, endTime }) => ({ startTime, endTime }))
    });

    for (let i = 0; i < this.finalData.length; i++) {
      this.maxLength.push(this.finalData[i].timeSlot.length)
    }

    /* Filter Max Number */
    this.maxLength = Math.max(...this.maxLength);

    this.finalData.forEach(element => {
      if (element.timeSlot.length >= this.maxLength) {
        this.onlyTimeSlot = [];
        this.onlyTimeSlot = element.timeSlot;
      }
    });






    // for (let i = 0; i < this.finalData.length; i++) {
    //   // this.finalData[i].timeSlot.sort((a, b) => a - b);
    //   let timeDecs = this.finalData[i].orderBy(h => (h.startTime, true))
    //   console.log("after sorting data", timeDecs);
    // }



    /* IN EDIT CASH */
    if (this.finalData.length > 0) {
      for (let i = 0; i < this.finalData.length; i++) {
        this.editDate = new Date(Number(this.finalData[i].scheduleStartDate));
        this.editDate = moment(this.editDate);
        this.dates.push(this.editDate);
        this.myDateChips.push(this.dates[i].format("YYYY/MM/DD"));
      }
      if (this.editDate.length == 0) {
        this.timeSlotEnable = false;
      } else {
        this.timeSlotEnable = true;
      }
    }
  }

  /* FOR ADD SELECTED CLASS ON DATES IN CELENDER */
  isSelected = (event: any) => {
    const date = event as moment.Moment;
    return this.dates.find((x) => x.isSame(date)) ? "selected" : null;
  };

  /* FOR SELETED DATE */
  select(event: any, calendar: any) {
    const date = moment(event);
    const index = this.dates.findIndex((x) => x.isSame(date));

    if (index < 0) {
      this.dates.push(date);
      /* PUSH SELECTED DATA INTO EMPTY ARRAY */
      this.myDateChips.push(date.format("YYYY/MM/DD"));
      if (this.myDateChips.length == 0) {
        this.timeSlotEnable = false;
      } else {
        this.timeSlotEnable = true;
      }
    } else {
      /* UNSELECTE TO REMOVE DATE  */
      this.dates.splice(index, 1);
      this.myDateChips.splice(index, 1);
      if (this.myDateChips.length == 0) {
        this.timeSlotEnable = false;
      } else {
        this.timeSlotEnable = true;
      }
    }
    calendar.updateTodaysDate();
  }

  /* MAKE FORM CONTROLS */
  get timeSlotes(): UntypedFormArray {
    return this.setSchedule.controls["timeSlotes"] as UntypedFormArray;
  }

  /* FOR GO NAVIGATE BACK */
  goBack() {
    this.timeBtnHide = false;
  }


  /* FOR SUBMIT DATES AND ADD TIMESLOTES */
  addTimeSlot() {

    /* FOR VALIDATION */
    this.timeClickToHide = true;
    this.timeBtnHide = true;

    /* CREATE FROM */
    this.setSchedule = this._fb.group({
      dates: [this.myDateChips, Validators.required],
      timeSlotes: this._fb.array([]),
    });

    /* FOR TIME SLOTE */
    if (this.finalData.length > 0) {
      for (let i = 0; i < this.onlyTimeSlot.length; i++) {

        let editStartTime: any = new Date(Number(this.onlyTimeSlot[i].startTime));
        let editEndTime: any = new Date(Number(this.onlyTimeSlot[i].endTime));

        editStartTime = editStartTime.getHours() + ":" + editStartTime.getMinutes();
        editEndTime = editEndTime.getHours() + ":" + editEndTime.getMinutes();

        this.editTimeStartSlote.push(editStartTime);
        this.editTimeEndSlote.push(editEndTime);

        /* ONCLICK TO PUSH TIME SLOT INTO TIMESLOT IN EDIT/REUSE CASE*/
        (<UntypedFormArray>this.setSchedule.get("timeSlotes")).push(
          this._fb.group({
            startTime: [this.editTimeStartSlote[i], Validators.required],
            endTime: [this.editTimeEndSlote[i], Validators.required],
          })
        );

      }
    }
  }

  /* ADD NEW TIME SLOTE ROW */
  addRow(i) {
    (<UntypedFormArray>this.setSchedule.get("timeSlotes")).push(
      this._fb.group({ startTime: ["", Validators.required], endTime: ["", Validators.required] })
    );
    this.rowLength = this.setSchedule.get("timeSlotes")["controls"].length - 1;
  }

  /* REMOVE TIME SLOTE ROW */
  removeRow(i) {
    (<UntypedFormArray>this.setSchedule.get("timeSlotes")).removeAt(i);
    this.rowLength = this.setSchedule.get("timeSlotes")["controls"].length - 1;
  }

  /* FOR REMOVE DATES CHIPS */
  remove(item: any): void {
    const index = this.myDateChips.indexOf(item);
    if (index >= 0) {
      this.dates.splice(index, 1);
      this.myDateChips.splice(index, 1);
    }
  }

  /* FINAL SUBMIT */
  onSubmit() {

    console.log("startTime", this.setSchedule);


    /* FUNCATION FOR CONVERTING DATESTIME INTO TIMESTAMP */
    const toTimestamp = (strDate) => {
      const dt = Date.parse(strDate)
      return dt / 1000
    };

    /* for push date into dateArray */
    for (let i = 0; i < this.setSchedule.get("dates").value.length; i++) {
      this.dateStamp.push(this.setSchedule.get("dates")["value"][i]);
    }

    /* for push time into timeslotArray */
    for (let j = 0; j < this.setSchedule.get("timeSlotes").value.length; j++) {
      this.strtimeStamp.push(this.setSchedule.get("timeSlotes")["value"][j].startTime + ":" + "00");
      this.endtimeStamp.push(this.setSchedule.get("timeSlotes")["value"][j].endTime + ":" + "00");
    }

    /* PUSH DATE INTO FINAIL ARRAY */
    this.finalData = [];
    for (let i = 0; i < this.setSchedule.get("dates").value.length; i++) {
      this.finalData.push({
        scheduleStartDate: this.dateStamp[i],
        scheduleEndDate: this.dateStamp[i],
      });
    }

    /* PUSH TIME SLOT INTO FINALARRAY */
    for (let i = 0; i < this.setSchedule.get("timeSlotes")["controls"].length; i++) {
      this.allTimeSlot.push({
        startTime: this.strtimeStamp[i],
        endTime: this.endtimeStamp[i]
      });
    }

    /* AGIAN PICK TIME FOR CONVERT INTO TIMESLOT */
    for (let i = 0; i < this.setSchedule.get("timeSlotes")["controls"].length; i++) {
      this.finalData.forEach((element) => {
        element.timeSlot = this.allTimeSlot;
      });
    }

    /* find max and min dates for ticket saleing date */


    for (let i = 0; i < this.setSchedule.value["dates"].length; i++) {
      this.allDates.push(this.setSchedule.value["dates"][i]);
    }



    /* find Max date */
    this.maxDate = new Date(
      Math.max(...this.allDates.map((element) => { return new Date(element); }))
    );

    /* find Min Date  */
    this.minDate = new Date(
      Math.min(...this.allDates.map((element) => { return new Date(element); }))
    );


    /* set TimeStamp accroding to dates */
    for (let i = 0; i < this.finalData.length; i++) {
      this.onlyTimeSlot = [];

      for (let j = 0; j < this.finalData[i].timeSlot.length; j++) {
        this.onlyTimeSlot.push({ startTime: Number(toTimestamp(this.finalData[i].scheduleStartDate + " " + this.finalData[i].timeSlot[j].startTime) + '000'), endTime: Number(toTimestamp(this.finalData[i].scheduleEndDate + " " + this.finalData[i].timeSlot[j].endTime) + '000') });
      }

      this.finalData[i].scheduleStartDate = Number(toTimestamp(this.finalData[i].scheduleStartDate) + '000');
      this.finalData[i].scheduleEndDate = Number(toTimestamp(this.finalData[i].scheduleEndDate) + '000');
      this.finalData[i].timeSlot = this.onlyTimeSlot
    }


    /* FINDING MAX DATE AND SET TO AS A MAXDATE INTO MAIN ARRAY */
    // let lastIndex = this.finalData.length - 1; //traget lastindex
    // let timeLastIndex = this.finalData[lastIndex].timeSlot.length - 1; //traget lastindex
    // this.maxDate = this.finalData[lastIndex].timeSlot[timeLastIndex].endTime; //setting maxdate

    /* push the max and min date */
    this.finalData.forEach((element) => {
      // element.maxDate = Number(toTimestamp(this.maxDate) + '000'), //hold
      element.maxDate = new Date(this.maxDate).getTime(), //pass maxDate into main array 
        element.minDate = Number(toTimestamp(this.minDate) + '000') //pass minDate into main array
    });

    this.onlyTimeSlot = [];

    /* UNQUIE DATA ACCORDING TO DATES */
    let uniqueData = [
      ...new Map(
        this.finalData.map((item) => [item["scheduleStartDate"], item])
      ).values(),
    ];



    /* ON CLOSE MAT DIALOG */
    if (this.finalData) {
      this.dialogRef.close(uniqueData);
    } else {
      this.dialogRef.close("");
    }

  }

  /* FOR CLOSE BUTTON  */
  closeDialog() {
    console.log("this.finalData", this.finalData);

    if (this.finalData) {
      this.dialogRef.close(this.finalData);
    } else {
      this.dialogRef.close();
    }
  }

  setDateInTime(time, date) {
    console.log("this is the time ", time);
    console.log("this is the data ", date);
  }
  
}
