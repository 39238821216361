import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { FuseConfigService } from '@fuse/services/config.service';

@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
  animations : fuseAnimations
})
export class EmailVerificationComponent implements OnInit {
  animatedImage: any;
  isEmailVerified: boolean = false;
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _matSnakeBar : MatSnackBar,
    private _CommonService : CommonService,
    private http: HttpClient,
    private _activatedRoute: ActivatedRoute,
  ) { 
    
    this.http.get<any>('assets/animated_icon/Angry.json').subscribe(data => {
      console.log("data", data);
      this.animatedImage = data;
    });


    this._activatedRoute.params.subscribe((params) => {
      console.log("params", params);
      if(params?.token){
        this.verifyEmail(params?.token)
      }
    })

    this._fuseConfigService.config = {
      layout: {
          navbar: {
              hidden: true
          },
          toolbar: {
              hidden: true
          },
          footer: {
              hidden: true
          },
          sidepanel: {
              hidden: true
          }
      }
    };

  }

  ngOnInit(): void {
  }

  verifyEmail(token){
    this._CommonService.onAdminEmailVarification(token).then((res:any) => {
      if(res.status == 200){
        this.isEmailVerified = true;
        this._matSnakeBar.open(res.message, 'OKAY', {duration : 2000})
      }else{
        this.isEmailVerified = false;
        this._matSnakeBar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
  }

}
