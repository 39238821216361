import { Injectable } from '@angular/core';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReferralService {

referralList;

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getReferralList(3),
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    });
  }

  getReferralList(filter) {
    const payload ={
      filter:filter
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getreferrals', payload,this._commonService.getHeader())
        .subscribe((response: any) => {
          this.referralList = response;
          resolve(response);
        }, reject);
    });

  }

 
}
