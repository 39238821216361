import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl } from '../../../../@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { DistributorsListService } from 'app/main/distributors/distributors-list/distributors-list.service';
import { EventListService } from 'app/main/events/events-list/event-list.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable()
export class ProjectDashboardService implements Resolve<any>{
    dashboard: any;
    distributorsList: any = [];
    distributorsListStatus :boolean = false;
    eventList: any = []
    eventDataList :any = []
    eventMapData: any[] = []
    ticketMapData: any[] = []
    eventFilter = 1
    ticketFilter = 1
    dashboardCount: any = {}
    salesReport: any = []
    ticketStatesChartData: any = []
    allBookingData: any = []
//   ticketDataList: any;
    allEventData: any = [];
    ticketDataList: any;

    constructor(
        private _httpClient: HttpClient,
        private _commonService: CommonService,
        private _distributorsListService: DistributorsListService,
        private _eventListService: EventListService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar
    ) {
        this._distributorsListService.getDistributorList().then((result: any) => {
            console.log("this is result ===== >> ", result);
            this.distributorsListStatus = true;
            if (result?.status == 200) {
                this.distributorsList = result.data.data;   
            } else {
                console.log("status 400");
                this.distributorsList = [];
            }
        });
        this.getDashboardCount();
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        
        return new Promise((resolve, reject) => {
            
            Promise.all([
                this._fuseProgressBarService.show()
            ]).then(
                () => {
                    this._fuseProgressBarService.hide();
                    resolve('');
                },
                reject
            )
        });
    }


    /* for all event list for filter */
    getAlleventList(payload){
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/events/getallevents',payload, this._commonService.getHeader())
            .subscribe((response:any) =>{
                if(response.status == 200){
                    this.allEventData = response.data.data;
                    resolve(response.data.data)
                }else if(response.status == 400){
                    this.allEventData = [];
                    resolve(response.data)
                }
            }, reject)
        })
    }

    // getDashboard(filter?): Promise<any> {
    //     const payload = {
    //         "filter": filter
    //     }
    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(environment.apiURL + 'admin/getdashboard', payload, this._commonService.getHeader())
    //             .subscribe((response: any) => {
    //                 this.dashboard = response.data;
    //                 console.log("admin/getdashboard --> ", this.dashboard);
    //                 resolve(response.data);
    //             }, reject);
    //     });
    // }

    getTicketsStatesChart() {
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/dashboard/getticketsstats', this._commonService.getHeader())
                .subscribe((response: any) => {
                    this.ticketStatesChartData = response.data.stats;
                    resolve(response);
                }, reject)
        });
    }

    getAllDistributor() {
        
        const payload = {
            "isForDashboard": 1
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getalldistributors', payload, this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        
                    }else{
                        
                    }
                    resolve(response);
                }, reject)
        });
    }

    getEventMap(filter, type) {
        
        const payload = {
            "filter": filter
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/dashboard/events', payload, this._commonService.getHeader())
                .subscribe((response: any) => {
                    // console.log("this._projectDashboardService.eventDataLis  response t", response); 
                    this.eventDataList = response.data
                    if (response.status == 200) {
                        
                        let labels = []
                        let values = []

                        response.data.events.map((item) => {
                            labels.push(item.xAxisLabel)
                            values.push(item.dataCount)
                        })
                        this.eventMapData = [[...labels], [...values]]
                        if(type == 'formService'){
                            // console.log("run service case");
                            resolve(response.data);
                        }else if(type == 'ts'){
                            // console.log("run ts case");
                            resolve(response.data);
                        }

                    } else {
                        
                        this._matSnackBar.open(response.message, 'OK', { duration: 2000 })
                        reject(response);
                    }
                }, reject)
        });
    }

    getTicketMap(filter) {
        
        const payload = {
            "filter": filter
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/dashboard/tickets', payload, this._commonService.getHeader())
                .subscribe((response: any) => {
                    this.ticketDataList = response.data
                    if (response.status == 200) {
                        let labels = []
                        let values = []
                        response.data.tickets.map((item) => {
                            labels.push(item.xAxisLabel)
                            values.push(item.dataCount)
                        })
                        this.ticketMapData = [[...labels], [...values]]
                        // resolve([[...labels], [...values]])
                        resolve(response.data);
                    } else {
                        
                        this._matSnackBar.open(response.message, 'OK', { duration: 2000 })
                        reject(response);
                    }
                }, reject)
        });
    }

    getDashboardCount() {
        
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/dashboard/getallcount', this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.dashboard = response.data
                        resolve(response)
                    }else if(response.status == 400){
                        this.dashboard = [];
                        resolve(response)
                    } else if(response.status == 201){
                        this.dashboard = [];
                        resolve(response)
                    }
                    else {
                        this._matSnackBar.open(response.message, 'OK', { duration: 2000 })
                        reject(response);
                    }
                }, reject);
        });
    }

    getSalesReport() {
        
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/dashboard/getsalesreport', this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.salesReport = response.data
                        console.log("response.data --->", response.data);
                        resolve(response)
                    }
                    else if(response.status == 201){
                        this.salesReport = [];
                        resolve(response)
                    }
                    else {
                        this.salesReport=[]
                        // this._matSnackBar.open(response.message, 'OK', { duration: 2000 })
                        resolve(response);
                    }
                }, reject);
        });
    }

    getTicketCreatedEventsList(payload?){
        return new Promise((resolve, reject) =>{
            this._httpClient.post(environment.apiURL + 'admin/getTicketsGeneratedEvents',payload?payload: {}, this._commonService.getHeader())
            .subscribe((response: any) =>{
                if(response.status == 200){
                    resolve(response);
                }else{
                    reject
                }
            }, reject)
        })
    }

    getBookingData(payload){
        return new Promise((resolve, reject) =>{
            this._httpClient.post(environment.apiURL + 'admin/getTicketsReport', payload, this._commonService.getHeader())
            .subscribe((response: any) =>{
                if(response.status == 200) {
                    this.allBookingData = response.data;
                    resolve(response)
                }else{
                    reject()
                }
            })
        })
    } 
    

}
