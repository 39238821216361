import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GenerateCertificateService implements Resolve<any> {

  events:[] = [];
  onUserListChanged: BehaviorSubject<any> = new BehaviorSubject([]);
  userList:[] = [];
  maxAvailableRecordsInTable: any;

  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _MatSnackBar : MatSnackBar,
  ) {

   }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    
    console.log("route route", route);
    
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getEventsList({pageSize : 0, pageIndex : 0}),
      ]).then((res)=>{
        resolve('')
      }, reject)
    })
  }

  getEventsList(filter?): Promise<any> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallevents', filter ? filter : {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          if (response.status == 200) {                         // publist          upcoming                     ongoing                complete                isOrderPlace 
            this.events = response.data.data.filter((item) => (item.isPublish) && (item.eventStatus == 1 || item.eventStatus == 4 || item.eventStatus == 5) && item.isOrderPlace); 
            console.log("this.events", this.events);            
          }else if(response.status == 400){
            this.events = [];
            resolve(response)
          }
          resolve(response);
        }, reject);
    });
  }


  getUserList(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/certificate/getAllEventAttendees', body, this._commonService.getHeader()).subscribe((res:any)=>{
        this._fuseProgressBarService.hide();
        if(res.status == 200){
          this.userList = res.data.userList;
          this.onUserListChanged.next(this.userList);
          this.maxAvailableRecordsInTable = res.data.totalRecords;
          resolve(res)
        }else{
          this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000})
          resolve(res)
          this.userList = [];
          this.maxAvailableRecordsInTable = 0;
        }
      }, reject)
    })
  }
}
