import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-order-members',
  templateUrl: './order-members.component.html',
  styleUrls: ['./order-members.component.scss'],
  encapsulation : ViewEncapsulation.None,
})
export class OrderMembersComponent implements OnInit {

  eventName;
  transactionId;
  orderDate;
  totalAmount;
  ticketType;
  ticketNumber;
 
  personDetails;
  
  eventId;
  eventIdlink;
  
  slotstartDate: any;
  slotendDate: any;
  orderPlacedBy: any;
  orderPlaceOn: any;
  pdfLink: any;

  form : FormGroup;
  dynamicFields;

  allCountries: any;
  memberStates: any = [];
  customerInfoType: any;

  allStates:any = []
  defCountryCode:any = '';
  selectedCountryCode;
  selectedCountry = '';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  checkNumberStatus = 1; // 1 == not checked, 2 == checking...,  3 == checked but not found, 4 == checked and found.
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
   
  constructor(
    public commonService : CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fuseProgressBarService : FuseProgressBarService,
    private _fb: FormBuilder,
    public _ToolbarService : ToolbarService,
    private dialogRef: MatDialogRef<OrderMembersComponent>,
    public _commonservice: CommonService,
    private _snakeBar : MatSnackBar,
  ) {
    console.log("data", data);
    
    this.customerInfoType = data.allMembers.eventInfo.customerInfoType;
    this.dynamicFields = data.allMembers.eventInfo.dynamicNames;
    this.allCountries =  data.allCountries;
    this.allStates = data.allStates;

    console.log("data ===--> ", data);
    
    this.pdfLink = data.allMembers.link.pdfImglink;
    /* Event info */
    this.eventName = data.allMembers.eventInfo.eventName
    this.slotstartDate = data.allMembers.eventInfo.showStartTime;
    this.slotendDate = data.allMembers.eventInfo.showEndTime;

    /* Order Info */
    this.orderPlacedBy = data.allMembers.orderInfo.orderPlacedBy;
    this.totalAmount = data.allMembers.orderInfo.orderAmount;
    this.orderPlaceOn = data.allMembers.orderInfo.orderPlacedOn;
    this.transactionId = data.allMembers.orderInfo.transactionId;

    /* TicketType Name */
    this.ticketType = data.allMembers.ticketDescription.TicketTypeName;
    this.ticketNumber = data.allMembers.ticketDescription.ticketNumber;

    
    /* Redirect to Event Details */
    this.eventId = data.allMembers.eventInfo.eventId;
    this.eventIdlink = 'https://dashboard.eventlocal.io/event-admin/events/edit?id=' + this.eventId;
    
    /* member detials */
    this.personDetails = data.allMembers.ticketMembers;
    console.log("this.personDetails", this.personDetails);
    
  }

  ngOnInit() {
    this.form = this.createForm(); 
    if(this.personDetails.length){
      this.setMember(this.personDetails);
    }

  }

  onUpdatePhoneNumber(element){
    console.log("hit on change, this is element", element);
    if(element?.value){
      const result = element?.value
      // allow china 11 digit 
      if((result?.countryCode === 'CN')){
        if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
            element.setErrors(null);
        }else{
          element.setErrors({validatePhoneNumber : {valid : false}});
        }
      }
    }
  }
  
  /* MAKE FORM CONTROLS */
  get members(): UntypedFormArray {
    return this.form.controls["members"] as UntypedFormArray;
  }

  
  setMember(members?){
    for (let i = 0; i < members.length; i++) {
      console.log("this are the member", members[i]);
      if(members && members[i]?.mobile){
        if(members[i].country && members[i].state){
          this.memberStates[i] = this.allStates.filter((item) => item.country_id == members[i].country);
        }
      }

      (<UntypedFormArray>this.form.get('members')).push(
        this._fb.group({
          id : new FormControl({ value : members ? members[i]?.id: "", disabled : true}),
          name: new FormControl({ value : members ? members[i]?.name : "", disabled : true}),
          mobile: new FormControl({value : members ? members[i]?.mobile : "", disabled : true}),
          phoneCode : new FormControl({value : members ? members[i]?.phoneCode :'', disabled : true}),
          countryCode : new FormControl({value : members ? members[i]?.countryCode :'', disabled : true}),
          email: new FormControl({value : members ? members[i]?.email : "", disabled : true}, Validators.email),
          gender: new FormControl({value : members ? members[i]?.gender ? String(members[i]?.gender) : "": "", disabled : true}),
          age: new FormControl({value : members ? members[i]?.age : "", disabled : true}),
          country: new FormControl({value : members ? String(members[i]?.country) : "", disabled : true}),
          // countryTitle : new FormControl({value : members ? members[i].country : '', disabled : true}),
          state: new FormControl({value : members ? String(members[i]?.state) : "", disabled : true}),
          // stateTitle : new FormControl({value : members ? members[i].stateTitle : '', disabled : true}),
          city: new FormControl({value : members ? members[i]?.city : "", disabled : true}),
          companyName : new FormControl({value : members ? members[i]?.companyName : "", disabled : true}),
          jobTitle : new FormControl({value : members ? members[i]?.jobTitle : "", disabled : true}),
          industry : new FormControl({value : members ? members[i]?.industry : "", disabled : true}),
          directNo : new FormControl({value : members ? members[i]?.directNo : "", disabled : true}),
          seatNumber : new FormControl({value : members ? members[i].seatNumber : "", disabled : true}),
          canCreateCustomer : new FormControl({value : members ? members[i]?.canCreateCustomer : "", disabled : true}),
          actuallyPassword : new FormControl({value : members ? members[i]?.actuallyPassword : "", disabled : true})
      }))
  }}

  updateMemberDetails(i){
    this.members.controls[i].disable();
    this._fuseProgressBarService.show() 
    console.log("this.members.controls[i]", this.members.controls[i]);
    
    if(this.members.controls[i]?.get('mobile').value?.number){
      const mobileObj = this.members.controls[i].get('mobile').value;
      this.members.controls[i].get('phoneCode').setValue(mobileObj?.phoneCode);
      this.members.controls[i].get('countryCode').setValue(mobileObj?.countryCode);
    }

    const payload =  {
      "orderId": this.data.orderId,
      "memberId": this.personDetails[i].id,
      "name":this.members.controls[i].get('name').value,
      "email": this.members.controls[i].get('email').value,
      "age": this.members.controls[i].get('age').value,
      "gender": this.members.controls[i].get('gender').value,
      "phoneCode": this.members.controls[i].get('phoneCode')?.value ? this.members.controls[i].get('phoneCode').value : '',
      "mobile":  this.members.controls[i].get('mobile').value ? this.members.controls[i].get('mobile').value?.number ? this.members.controls[i].get('mobile').value.number.split(" ").join("") : this.members.controls[i].get('mobile').value :  '',
      "industry" :this.members.controls[i].get('industry').value,
      "city" : this.members.controls[i].get('city').value,
      "state" : this.members.controls[i].get('state').value,
      "country" : this.members.controls[i].get('country').value,
      "jobTitle" : this.members.controls[i].get('jobTitle').value,
      "companyName" : this.members.controls[i].get('companyName').value,
      "directNo" : this.members.controls[i].get('directNo').value,
      "countryCode" : this.members.controls[i].get('countryCode').value,
      "canCreateCustomer" : this.members.controls[i].get('canCreateCustomer').value ? 1 : 0
    }

    console.log("final payload", payload);
    this.commonService.updateOrderMember(payload).then((res:any) => {
      if(res.status == 200){
        this._snakeBar.open(res.message, 'OKAY', {duration: 2000})
        this.dialogRef.close();
      }else{
        this.members.controls[i].enable();
        this._snakeBar.open(res.message, 'OKAY', {duration: 2000})
      }
      this._fuseProgressBarService.hide()
    })
    
  }
 
  editMember(i, group){
    this.members.controls[i].enable()
    group.get('actuallyPassword').disable();
    console.log("this.members.controls[i]", this.members);
  }

  createForm(){
    return this._fb.group({
      members : this._fb.array([])
    })
  }

  onSelectCountry(e, index){
    this.commonService.getStates(e.value).then((result: any) => {
      this.memberStates[index]=result.data;
    }) 
  }

  downloadBtn(){
    // console.log("this.pdfLink", this.pdfLink);
    // let printWindow = window.open('', '', 'height="100vh",width="100%"');
    // printWindow.document.write('<body style="margin:0; padding:0;"><iframe src="' + this.pdfLink +'" height="100%" width="100%"  type="pdf"></iframe></body>')
    const imageURL = this.pdfLink
    fetch(imageURL).then(response => response.blob()).then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'image.jpg'; // You can change the file name here
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(error => console.error('Error downloading image:', error));
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
