import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AddEventTypeService {

  constructor(
    private _http: HttpClient,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService:CommonService
  ) { }

  addEventType(data) {
    console.log("addEventType called");
    const payload = {
      title : data.typeName,
      icon : data.icon,
      backgroundImage : data.background
    }
    // let formData = new FormData()
    // formData.append("title",data.typeName)
    // formData.append("icon",data.icon)
    // formData.append('backgroundImage', data.background)

    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._http.post(environment.apiURL + 'admin/event/request/eventtype', payload, this._commonService.getHeader()).subscribe((result: any) => {
        this._fuseProgressBarService.hide()
        if (result.status == 200) {
          resolve(result)
        }
        this._matSnackBar.open(result.message, 'OK', {
          verticalPosition: "bottom",
          duration: 2000
        })
      }, reject)
    })
  }
//   editType(data, id) {
//     // const payload = {
//     //   eventTypeId: id,
//     //   title: data.typeName,
//     //   icon: data.icon
//     // }
//     let formData = new FormData()
// formData.append("eventTypeId",id)
// formData.append("title",data.typeName)
// formData.append("icon",data.icon)

//     this._fuseProgressBarService.show()
//     return new Promise((resolve, reject) => {
//       this._http.put(environment.apiURL + 'admin/event/update/eventtype', formData, this._commonService.getHeader('formData')).subscribe((result: any) => {
//         this._fuseProgressBarService.hide()
//         if (result.status == 200) {
//           resolve(result)
//         }
//         this._matSnackBar.open(result.message, 'OK', {
//           verticalPosition: "bottom",
//           duration: 2000
//         })
//       }, reject)
//     })
//   }
}


