import { Injectable } from '@angular/core';
import { CommonService } from '@fuse/services/common.service';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService implements Resolve<any>{
  setting: any
  allCountries
  allCurrencies
  constructor(
    private _commonService: CommonService,
    private _httpClient: HttpClient,
    private _fuesProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _router: Router
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this._fuesProgressBarService.show(),
        this.getSetting(),
        this._commonService.getAllCountries().then((result:any) => {
          if(result.length)
          this.allCountries = result
          else
          this.allCountries = result
        }),
        this._commonService.getAllCurrencies().then((result) => {
          console.log('ck2')
          this.allCurrencies = result
        }),
      ]).then(
        () => {
          resolve('');
            this._fuesProgressBarService.hide();
        },
        reject
      );
    });
  }

  getSetting(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + 'admin/settings/get', this._commonService.getHeader())
        .subscribe((response: any) => {
          console.log(response)
          if (response.status == 200) {
            this.setting = response.data;
            console.log("this.setting", this.setting);
            const currencyId = this.setting.currency;
            localStorage.setItem('currencyId', currencyId);
          } else {
            this.setting = ''
          }
          resolve(response);
        }, reject);
    });
  }


  editSetting(data) {
    let payload = {
      currencyId: data.currency,
      countryId: data.country,
      company: data.companyName,
      setAuthy: +data.tfa,
      banner : data.banner,
      logo : data.logo
    }
    return new Promise((resolve, reject) => {
      // this._fuesProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/settings/edit', payload, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200){
          // this._router.navigate(['/home'])
          resolve(result)
        }
        // this._fuesProgressBarService.hide()
        this._matSnackBar.open(result.message, 'OK', {
          verticalPosition: "bottom",
          duration: 4000
        })
      })
    })
  }

  


}
