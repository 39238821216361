import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { AuthService } from 'app/main/pages/auth.service';
import { environment } from 'environments/environment';
import { filter } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerifiersScanLocationService implements Resolve<any>{

  verifiersScanList = []
  maxAvailableRecordsInTable:number;
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _matsnakebar : MatSnackBar,
    private _commonService: CommonService,
  ) { }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
      return new Promise((resolve, reject) => {
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getAllVerifiersScanList({pageIndex : 1, pageSize : 10})
        ]).then(()=>{
          resolve('');
          this._fuseProgressBarService.hide();
        }, reject)
      })
    }


    getAllVerifiersScanList(payload){
      return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/verifier/scanLocationList',payload, this._commonService.getHeader()).subscribe((response: any) => {
          if(response.status == 200){
            this.verifiersScanList = response.data;
            this.maxAvailableRecordsInTable = response.data.totalRecords;
          }else{
            this.verifiersScanList = [];
            this.maxAvailableRecordsInTable = 0;
            this._matsnakebar.open(response.message, 'OKAY', {duration :2000})
          }
          resolve(response);
        }, reject)
      })
    }

}
