import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebhooksListComponent } from './webhooks-list.component';
import { RouterModule, Routes } from '@angular/router';
import { WebhooksListService } from './webhooks-list.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { AddUpdateWebhookComponent } from './add-update-webhook/add-update-webhook.component';
import { ReactiveFormsModule } from '@angular/forms';

const routes:Routes=[
  {path:'',component:WebhooksListComponent},
  {path:'add',component:AddUpdateWebhookComponent},
]


@NgModule({
  declarations: [
    WebhooksListComponent,
    AddUpdateWebhookComponent
  ],
  imports: [
    CommonModule, 
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatSelectModule,
  ]
})
export class WebhooksModule { }
