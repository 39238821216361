import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.scss']
})
export class SuccessDialogComponent implements OnInit {
  message : string;
  image : string;
  imageSize : string;
  buttonText :string;

  constructor(
    public matDialogRef: MatDialogRef<SuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any, 
  ) {
    this.message = this._data.message;
    this.image = this._data.image;
    this.imageSize = this._data.imageWidthSize;
    this.buttonText = this._data.buttonText;
    
    console.log("_data", _data);
   }

  ngOnInit(): void {
  }

}
