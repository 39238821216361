import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-add-edit-resources-dialog',
  templateUrl: './add-edit-resources-dialog.component.html',
  styleUrls: ['./add-edit-resources-dialog.component.scss']
})
export class AddEditResourcesDialogComponent implements OnInit {

  form: FormGroup
  selectedFile:any = '';
  mode
  constructor(
    private _fb: UntypedFormBuilder,
    private _matDialog : MatDialog,
    private _commonService: CommonService,
    private _cd : ChangeDetectorRef,
    private _matSnackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<AddEditResourcesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { }

  ngOnInit(): void {
    if (this._data.case == 'add') {
      this.mode = 'add';
      this.form = this.createForm();
      this.form.enable();
    }else if(this._data.case == 'view'){
      this.mode = 'view';
      this.form = this.createForm(this._data.data);
      this.form.disable();
    }else if(this._data.case == 'edit'){
      this.mode = 'edit';
      this.form = this.createForm(this._data.data);
      this.form.enable();
    }
  }

  onRemovefile(){
    console.log("remove file called");
    this.selectedFile = '';
    this.form.controls.file.setValue('');
    this._cd.detectChanges();
  }

  createForm(data?) {
    console.log("data", data);
    if(data?.mediaLink){
      this.selectedFile = data?.mediaLink;
    }
    return this._fb.group({
      title: [data?.title || '', Validators.required],
      description: [data?.description || ''],
      file: [data?.mediaLink || '']
    })
  }

  dropped(files: NgxFileDropEntry[]) {
    for (const droppedFile of files) {
      const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
      console.log("fileEntry", fileEntry);
      fileEntry.file((file: File) => {
        if(String(file['type']).split('/')[0] == 'image' || String(file['type']).split('/')[0] == 'application'){
          console.log("file", String(file['type']).split('/')[0]);
          this.uploadFileToServer(file); 
        }else{
          
        }
      })
    }
  }

  uploadFileToServer(fileObj) {
    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": 'resource_',
      "folderName": `event/resources/${String(fileObj?.type).split('/')[0]}`, // it is fix set by backend.
      'mimeType': fileObj?.type
    }
    console.log("payload", payload); 
    this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
      if (result.status == 200) {
        this.form.get('file').setValue(result.data.imageUploadUrl);
        // payload for get Image upload status
        const payload = {
          'id': result.data.id,
          'status': 1 // fix by backend.
        }
        this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, fileObj);
        this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
          if (uploadStatusResponse.status == 200) {
            this.selectedFile = result.data.imageUploadUrl;
            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 });
          } else {
            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 });
          }
        })
      }
    })
  }

  onOpenFile(){
    window.open(this.selectedFile)
  }

  onSubmit(){
    if(this.form.valid){
      // add
      if(this.mode == 'add'){
        const payload = {
          title : this.form.controls.title.value,
          description : this.form.controls.description.value,
          mediaLink : this.form.controls.file.value,
        }
  
        this._commonService.addResourse(payload).then((res:any) => {
          if(res.status == 200){
            this._matSnackBar.open(res.message, 'OKAY', {duration : 2000});
            this.matDialogRef.close(true);
          }
          this._matSnackBar.open(res.message, 'OKAY', {duration : 2000})
        })


      }
      // edit
      else{
        const payload = {
          resoureId : this._data?.data?.id,
          title : this.form.controls.title.value,
          description : this.form.controls.description.value,
          mediaLink : this.form.controls.file.value,
        } 
        this._commonService.updateSponsor(payload).then((res:any) => {
          if(res.status == 200){
            this._matSnackBar.open(res.message, 'OKAY', {duration : 2000});
            this.matDialogRef.close(true);
          }
          this._matSnackBar.open(res.message, 'OKAY', {duration : 2000})
        })
      }
    }
  }

  onEdit(){
    this.mode = 'edit';
    this.form.enable();
  }
  

}

