import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { AddGroupDialogComponent } from '@fuse/Dialog/add-group-dialog/add-group-dialog.component';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { ImportContactDialogComponent } from '@fuse/Dialog/import-contact-dialog/import-contact-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { GroupsService } from '../groups.service';
import { AddMoreFieldsComponent } from '@fuse/Dialog/add-more-fields/add-more-fields.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector: 'app-group-details',
    templateUrl: './group-details.component.html',
    styleUrls: ['./group-details.component.scss']
})
export class GroupDetailsComponent implements OnInit {
    // groupId: any
    groupData;
    // totalMember
    form: UntypedFormGroup;
    dataSource;
    maxAvailableRecordsInTable2;
    // displayedColumns: string[] = ['name', 'startDate', 'endDate', 'action'];
    displayedColumns: string[] = ['name', 'mob', 'profession', 'email', 'action'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('search', { static: true }) search: ElementRef;

    allContactWithPagination = [];

    // ----------------------- filters section -----------------------
    filter = {
        searchFilter: '',
        pageSize: 10,
        pageIndex: 1
    }
    groupId;
    isGroupEdit: boolean = false;

    constructor(
        private _activatedRoute: ActivatedRoute,
        public _groupsService: GroupsService,
        private _fb: UntypedFormBuilder,
        public _commonService: CommonService,
        private _router: Router,
        private _ToolbarService : ToolbarService,
        private _matDialog: MatDialog,
    ) {
        this.setToolBarTitle()
        this.getContactWithPagination();
        this.updateDetailsData(this._groupsService.groupId);
    }

    ngOnInit(): void {
        this.groupData = this._groupsService.groupDetails;
        console.log("this is group data", this.groupData);
        if (this.groupData) {
            if (this._groupsService.memberDetail) {
                this.dataSource = this._groupsService.memberDetail.contactList;
                this.maxAvailableRecordsInTable2 = this._groupsService.maxAvailableRecordsInTable;
            } else {
                this.dataSource = [];
                this.maxAvailableRecordsInTable2 = 0;
            }
        }
        this.createForm(this.groupData);
        this._activatedRoute.params.subscribe((params) => {
            this.groupId = params?.groupId
        })
    }


    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next('Group Detail');
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    }
    
    

    createForm(data) {
        return this.form = this._fb.group({
            groupName: [data?.name],
            description: [data?.description],
            createdAt: [data?.createdAt ? formatedDate(new Date(data.createdAt)) : ''],
            lastUpdate: [data?.createdAt ? formatedDate(new Date(data.modifiedAt)) : '']
        })
        function formatedDate(date) {
            return date.getMonth() + '/' + date.getDate() + '/' + date.getFullYear();
        }
    } 

    onDeleteContact(id) {
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : 'Are you sure you want to remove this contact from group?',
                image : '',
                focus : 'right',
                deleteItem : 'Remove contact from group?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        })

        dialog.afterClosed().subscribe((deleteDialogResponse) => {
            if (deleteDialogResponse) {
                const payload = {
                    id: Number(this.groupId),
                    contact: Number(id)
                }
                this._groupsService.removeContactFromGroup(payload).then((res: any) => {
                    if (res) {
                        this.filterData();
                        this.updateDetailsData(this.groupId)
                    }
                })
            }
        })

    }

    onSearch(val) {
        this.filter.pageIndex = 1
        this.filter.searchFilter = val
        this.paginator.pageIndex = 0;
        this.filterData();
    }

    getContactWithPagination(){
        this._groupsService.getAllContacts().then((result:any)=>{
            if(result.status == 200){
                this.allContactWithPagination = result.data.contactList;
            }else{
                this.allContactWithPagination = []
            }
        })
    }
 
    editGroupDetails(id) {
        if(this.allContactWithPagination?.length){
            this._matDialog.open(AddGroupDialogComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                autoFocus: false,
                width: '400px',
                data: {
                    groupDetails: this.groupData,
                    allContacts: this.allContactWithPagination
                }
            }).afterClosed().subscribe((dialogCloseResponse: any) => {
                if (dialogCloseResponse) {
                    this._groupsService.updataGroup(dialogCloseResponse).then((afterUpdateResponse: any) => {
                        if (afterUpdateResponse) {
                            this.updateDetailsData(id);
                            this.filterData();
                        }
                    })
                }
            });
        }
    }

    updateDetailsData(id) {
        this._groupsService.getGroupById(id).then((result: any) => {
            if (result.status == 200) {
                this.groupData = result.data;
                this.createForm(this.groupData);
                
            }
        })
        // this.filterData();
    }

    // main funcation for calling updated data from api
    filterData() {
        const payload = {
            "id": Number(this.groupId),
            "pageIndex": this.filter.pageIndex,
            "pageSize": this.filter.pageSize,
            "search": this.filter.searchFilter,
        }
        this._groupsService.getGroupContactlist(payload).then((result: any) => {
            if (result) {
                this.dataSource = result?.contactList;
                this.maxAvailableRecordsInTable2 = result?.totalRecords
            } else {
                this.dataSource = [];
                this.maxAvailableRecordsInTable2 = 0
            }
        })
    }

    clearSearch() {
        this.filter.searchFilter = '';
        this.search.nativeElement.focus();
        this.filterData();
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.filterData();
    }

    onEditContact(id) {
        this._router.navigate(['group/edit-contacts/' + id])
    }

    openImportContectDialog() {
        this._commonService.getDynamicFieldList({}).then((result:any)=>{
            if(result.status === 200 || result.status === 400){
              this._matDialog.open(ImportContactDialogComponent, {
                panelClass: ['no-padding-dialog', 'addGroup'],
                width: '68%',
                minHeight: '230px',
                // hasBackdrop : false,
                autoFocus : false,
                data : {
                  feilds : result?.data,
                  groupId : this.groupId ? this.groupId : undefined,
                  isForOrder : false
                }
              }).afterClosed().subscribe((uploadStatus) => {
                if(uploadStatus){
                  this.filterData();
                }
              })
            }
        })

        // this._matDialog.open(ImportContactDialogComponent, {
        //     panelClass: ['no-padding-dialog', 'addGroup'],
        //     width: '300px',
        //     minHeight: '230px',
        //     data: {
        //         type: 'byGroup',
        //         groupId: this.groupId
        //     }
        // }).afterClosed().subscribe((uploadStatus) => {
        //     if (uploadStatus) {
        //         this.filterData();
        //     }
        // })
    }

    onExportContact() {
        const payload = {
            id: this.groupId
        }
        this._groupsService.onExport(payload).then((res: any) => {
            if (res) {
                window.open(res.data);
            }
        })
    }

    onRedirectAddContact() {
        this._router.navigate(['/group/add-contacts'], { queryParams: { 'groupId': String(btoa(this.groupId)) } })
    }

    openConfigureSetting() {
        this._matDialog.open(AddMoreFieldsComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width : '620px',
            autoFocus : false,
        }).afterClosed().subscribe((fieldData) => {
            if(fieldData){
                 
            }
        })
    }
    
}