import { Component, OnInit, ViewChild, NgModule } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Enums } from "@fuse/utils/systemEnums";
import { NotificationListService } from "../notification-list.service";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";

@Component({
    selector: "app-notification-list",
    templateUrl: "./notification-list.component.html",
    styleUrls: ["./notification-list.component.scss"],
})
export class NotificationListComponent implements OnInit {
    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    allNotificationList;
    // notiFilter = Enums;

    maxAvailableRecordsInTable;
    tableData = [];
    dataSource;
    displayedColumns = ["title", "createdDate", "message"];

    constructor(
        private _notificationService: NotificationListService,
        private _ToolbarService: ToolbarService
    ) {
        this.setToolBarTitle(); 
        this.maxAvailableRecordsInTable =
            this._notificationService.maxAvailableRecordsInTable;
        this.allNotificationList =
            this._notificationService.allNotificationList;
    }

    ngOnInit() {
        this.tableData = this._notificationService.allNotificationList;
        this.dataSource = new MatTableDataSource(this.tableData);
    }

    setToolBarTitle() {
        // for title
        this._ToolbarService._ToolBarTitle.next("Notifications");
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }

    filter = {
        // notiType: "",
        pageSize: 10,
        pageIndex: 1,
    };
    clearFilter() {
        this.paginator.pageIndex = 0;
        // this.filter.notiType = "";
        this.getAllNotiList();
    }
    // onNotiTypeSelect(){
    //   this.paginator.pageIndex = 0;
    //   this.filter.pageIndex = 1;
    //   this.getAllNotiList();
    // }

    getAllNotiList() {
        let payload = {
            userId: localStorage.getItem("id"),
            pageIndex: this.filter.pageIndex,
            pageSize: this.filter.pageSize,
        };
        this._notificationService
            .getAllNotiLitst(payload)
            .then((result: any) => {
                if (result.status == 200) {
                    this.dataSource.data = result.data.transactionsList;
                    this.maxAvailableRecordsInTable = result.data.totalRecords;
                } else {
                    this.dataSource.data = [];
                    this.maxAvailableRecordsInTable = 0;
                }
            });
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1;
        this.filter.pageSize = event.pageSize;
        this.getAllNotiList();
    }
}
