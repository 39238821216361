
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { Enums } from '../../../../@fuse/utils/systemEnums';
import { AddUserService } from './add-distributor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { DistributorsListService } from '../distributors-list/distributors-list.service';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { AssignEventDialogComponent } from '@fuse/Dialog/assign-event-dialog/assign-event-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module'
import { DepositDialogComponent } from '@fuse/Dialog/refund/deposit-dialog/deposit-dialog.component';
import { result } from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImgCropperEvent } from '@alyle/ui/image-cropper';
import { LyDialog } from '@alyle/ui/dialog';

@Component({
    selector: 'app-add-user',
    templateUrl: './add-distributor.component.html',
    styleUrls: ['./add-distributor.component.scss'],
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.OnPush
    // encapsulation: ViewEncapsulation.None
})
export class AddUserComponent implements OnInit, OnDestroy {
    todayDate  = new Date()
    displayedColumns: string[] = ['name', 'eventStartDate', 'eventEndDate', 'availableTickets', 'soldTickets', 'action'];
    dataSource;

    /* second Table Transaction list table*/
    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    checkNumberStatus = 1; // 1 == not checked, 2 == checking...,  3 == checked but not found, 4 == checked and found.
    preferredCountries: CountryISO[] = [
      CountryISO.UnitedStates,
      CountryISO.UnitedKingdom,
    ];
    // ------------------

    
    maxAvailableRecordsInTable;

    Enums = Enums;

    distributorForm: UntypedFormGroup;         //form of add new user
    isFormSubmitted: boolean = false //used for know the form status
    maxDate = new Date();

    userData: any;
    userId: any;
    disable: boolean = false;

    user: any = null;

    isEditMode;
    isReadMode = true;

    myDistributorId:any;

    croppedProfileBase64: any = '';
    eightteenYearAgoFormToday;
    countryCode;
    defCountryCode:any = 'IN';
    selectedCountryCode;
    selectedCountry = ''
    totalDues:any;
    distributorId: any;
    dob: any;
    isSubmiting: boolean = false;

    

    cropped = null;
    constructor(
        public _addUserService: AddUserService,
        private fb: UntypedFormBuilder,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _ToolbarService : ToolbarService,
        private _dialog: LyDialog,
        private _cd : ChangeDetectorRef,
        private _activatedRoute: ActivatedRoute,
        private _matDialog: MatDialog,
        private _distributorsListService: DistributorsListService,
        public _commonService: CommonService
    ) {
        
        this.dataSource = new MatTableDataSource(this._addUserService.assignedEvents);
        this.maxAvailableRecordsInTable = this._addUserService.maxAvailableRecordsInTable

        this._activatedRoute.params.subscribe((params) => {
            this.isReadMode = params['id'] ? true : false;
            if(params['id']){
                this.setToolBarTitle(true);
            }else{
                this.setToolBarTitle(false);
            }
        })
 
        // this.distributorId =  this._addUserService.distributorId;

        // this.fetchDataInTable();
        // console.log("form",this.distributorForm);
        this.eightteenYearAgoFormToday = this.todayDate.setFullYear(this.todayDate.getFullYear()-18);
        this.eightteenYearAgoFormToday = new Date(this.eightteenYearAgoFormToday)
        // console.log("this.eightteenYearAgoFormToday",this.eightteenYearAgoFormToday);
        this.getcountryCode();
        if (this._addUserService.distributorId) {
            this.totalDues = this._addUserService.distributor.totalDues;
        }
    }

    yourComponentMethodToTreatyCountryChangedEvent(event){
        console.log("yourComponentMethodToTreatyCountryChangedEvent", event);
    }


    ngOnDestroy(): void {
        // this.isReadMode = undefined;
    }

    setToolBarTitle(edit){
        if(edit){
            // for title
            this._ToolbarService._ToolBarTitle.next('Agent, ' + this._addUserService.distributor.name);
            // for btn
            this._ToolbarService._isBackBtnShow.next(true);
        }else{
            // for title
            this._ToolbarService._ToolBarTitle.next('Add an Agent');
            // for btn
            this._ToolbarService._isBackBtnShow.next(false);
        }
    }

    getcountryCode(){
        this._commonService.getAllCountries().then((result: any) => {
            if(result.length){
                this.countryCode = result;   
            }else{
                this.countryCode = [];
            }
        })
    }


    selectcountryCode(e){
        this.selectedCountryCode = parseInt(e.value);
        console.log("the selected value", this.selectedCountryCode);
    }


    assignEventBtnClick() {
        this._router.navigate(['/distributors', 'event', this._addUserService.distributorId]);    
    }

    ngOnInit(): void {
          
        this.isReadMode ? this.addDistributorForm(this._addUserService.distributor) : this.addDistributorForm();

        if (this.isReadMode){
            this.distributorForm.disable()
        }


        this.distributorForm.get('dob').valueChanges.subscribe((result) => {
            console.log("result", new Date(result).getTime());
            this.dob = new Date(result).getTime();
        })


        this.distributorForm.get('mobile').valueChanges.subscribe((result)=> {
            console.log("result", result);
            console.log("this.distributorForm.get('mobile')", this.distributorForm.get('mobile'));
            console.log("String(result.e164Number).length", String(result?.e164Number).length);

            if(result){

                // allow china 11 digit 
                if((result.countryCode === 'CN')){
                    if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
                        this.distributorForm.get('mobile').setErrors(null);
                    }else{
                        this.distributorForm.get('mobile').setErrors({validatePhoneNumber : {valid : false}});
                    }
                }

                if(this.checkNumberStatus == 4 || this.checkNumberStatus == 3){
                    this.checkNumberStatus = 1;
                    this._cd.detectChanges();
                }
            }
        })

    }

    @ViewChild('fileUpload', { static: true })
    fileUpload: ElementRef; 
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
    addDistributorForm(distributor?) {
        console.log("distributor",distributor);
        if(distributor?.countryCode){
            this.defCountryCode = distributor.countryCode
        }
        this.distributorForm = this.fb.group({
            businessName: [distributor ? distributor.businessName : '', Validators.required],
            name: [distributor ? distributor.name : '', Validators.required],
            // countryCode: [distributor ? this._addUserService.toSeparatCode: this.defCountryCode, Validators.required],
            mobile: [distributor ? distributor.mobile : '', [Validators.required, Validators.pattern]],
            email: [distributor ? distributor.email : '', [Validators.required, Validators.email]],
            gender: [distributor ? distributor.gender.toString() : '', Validators.required],
            dob: [distributor ? new Date(distributor.birthDate) : '', Validators.required],
            // gstIn: [distributor?distributor.gstIn:''],
            commision: [distributor ? distributor.commision : ''],
            city: [distributor ? distributor.city : '', Validators.required],
            state: [distributor ? Number(distributor.state) : '', Validators.required],
            country: [distributor ? distributor.country : '', Validators.required],
            image: [distributor ? distributor.profileImage : '', Validators.required]
        });
        
        if (distributor) {
            this.croppedProfileBase64 = distributor.profileImage;
        }
    }

    onCheckNumber(){
        this.checkNumberStatus = 2;
        console.log("mobile", this.distributorForm.get('mobile').value);
        const payload = {
            phoneCode : this.distributorForm.get('mobile').value.dialCode,
            mobileNumber : this.distributorForm.get('mobile').value.number.split(' ').join(''),
        }

        this._addUserService.onCheckDistributorNumber(payload).then((res:any) => {
            if(res.status == 200){
                if(res?.data?.id){
                    this.checkNumberStatus = 4;
                    let dialog = this._matDialog.open(ConfirmationDialogComponent, { 
                        panelClass: ['commonDialogWrapper'],
                        backdropClass: 'dialogBackdrop',
                        disableClose: false,
                        data : {
                            message : `The distributor is available in our system. You can add this distributor’s info to your panel as one of your distributors.?`,
                            image : '',
                            focus : 'right',
                            deleteItem : '',
                            confirmButtonText : 'Continue',
                            cancelButtonText : 'Cancel'
                        }
                    })

                    dialog.afterClosed().subscribe((res)=>{
                        if(res){
                            this.addDistributorForm(res.data);
                            this.distributorForm.get('mobile').disable();
                        }else{
                            this.checkNumberStatus = 3;
                            this.distributorForm.get('mobile').setValue('');
                            this.distributorForm.get('mobile').reset();
                            // this.distributorForm.get('mobile').untouched;
                            // this.distributorForm.get('mobile').pristine;
                            // this.distributorForm.get('mobile').dirty;
                            this.distributorForm.get('mobile').updateValueAndValidity();
                        }
                    })
                    // this.checkNumberStatus = 4;
                    // this.addDistributorForm(res.data);
                }else{
                    // this.checkNumberStatus = 3;
                }
            }
        })
    }

    onSelectCountry(e) {
        console.log(e.value)
        this._commonService.getStates(e.value).then((result: any) => {
            this._addUserService.states = result.data   
            console.log("this is states data", this._addUserService.states);
        })
    }
    
    
    openCropperDialog(event: any) { 
        console.log("event", event);
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 250,
                    height: 250,
                    round : false,
                    type: 'image/png',
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 320,
                        height: 320
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height : 520,
            disableClose: true
          }).afterClosed.subscribe((result:any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event); 
                this.croppedProfileBase64 = result.file.dataURL;
                this._cd.markForCheck();
                this.onUploadImage(result.file, result.blobFile)
            }
          });
    }

    // on upload Image presignedURL API
    async onUploadImage(fileObj, blobFile){

        const payload = {
            "fileExt": '.' + String(fileObj?.type)?.slice(String(fileObj.type).indexOf('/') + 1),
            "fileName" : 'profile_',
            "folderName": 'distributor/profile', // it is fix set by backend.
            'mimeType': fileObj?.type
        } 
        // const base64 = await fetch(fileObj.dataURL);
      

        return new Promise((resolve, reject) => {
        this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
            if (result.status == 200) {
                this.distributorForm.get('image').setValue(result.data.imageUploadUrl);
                // payload for get Image upload status
                const payload = {
                    'id': result.data.id,
                    'status': 1 // fix by backend.
                }
                this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
                this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                    if (uploadStatusResponse.status == 200) {
                        this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                        resolve(true);
                    } else {
                        this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                        reject();
                    }
                })
            }
            })
        })
    }

   



    onRemoveProfile() {
        this.croppedProfileBase64 = null;
        this.distributorForm.get('image').setValue(null)
    }

    onSubmitAddUser = () => {
        if(this.distributorForm.valid){
            if(this.isEditMode){ 
                this.isSubmiting = true; 
                const payload = {
                    distributorId : this._addUserService.distributorId,
                    businessName : this.distributorForm.get('businessName').value,
                    name: this.distributorForm.get('name').value,
                    countryCode: this.distributorForm.get('mobile').value?.countryCode,
                    mobile: this.distributorForm.get('mobile').value?.number.split(" ").join(""),
                    phoneCode : this.distributorForm.get('mobile').value?.dialCode,
                    email: this.distributorForm.get('email').value,
                    gender: this.distributorForm.get('gender').value,
                    birthDate: this.dob ? this.dob : this.distributorForm.get('dob').value,
                    commision: this.distributorForm.get('commision').value,
                    city: this.distributorForm.get('city').value,
                    state: this.distributorForm.get('state').value,
                    country: this.distributorForm.get('country').value,
                    profileImage: this.distributorForm.get('image').value,
                }

                // form reset here.
                this.distributorForm.disable();
                console.log("this is edit case payload", payload);

                this._addUserService.editDistributor(payload).then((response) => {
                    this._matSnackBar.open(response.message, 'OK', { duration: 2000});
                    this._fuseProgressBarService.hide()
                    if (response.status == 200) {
                        this._router.navigate(['/distributors'])
                    }else{
                        this.isSubmiting = false;
                        this.distributorForm.reset();
                        this.distributorForm.enable();
                    }
                }).catch(err => {
                    this._matSnackBar.open(err.message, 'OK', {duration: 2000});
                    this.isSubmiting = false;
                    this.distributorForm.reset();
                    this.distributorForm.enable();
                })
            } else {
                this.isSubmiting = true;
                const payload = {
                    businessName: this.distributorForm.get('businessName').value,
                    name: this.distributorForm.get('name').value,
                    countryCode: this.distributorForm.get('mobile').value?.countryCode,
                    mobile: this.distributorForm.get('mobile').value?.number.split(" ").join(""),
                    phoneCode : this.distributorForm.get('mobile').value?.dialCode,
                    email: this.distributorForm.get('email').value,
                    gender: this.distributorForm.get('gender').value,
                    birthDate: this.dob,
                    commision: this.distributorForm.get('commision').value,
                    city: this.distributorForm.get('city').value,
                    state: this.distributorForm.get('state').value,
                    country: this.distributorForm.get('country').value,
                    image: this.distributorForm.get('image').value,
                }
                // form reset here.
                this.distributorForm.disable();
                console.log("payload --==>", payload);
                this._addUserService.addDistributor(payload).then((response) => {
                    this._matSnackBar.open(response.message, 'OK', {duration: 2000});
                    this._fuseProgressBarService.hide()
                    if (response.status == 200) {
                        this._router.navigate(['/distributors'])
                    }else{
                        this.isSubmiting = false;
                        this.distributorForm.reset();
                        this.distributorForm.enable();
                    }
                }).catch(err => {
                    this._matSnackBar.open(err.message, 'OK', {duration: 2000});
                    this.isSubmiting = false;
                    this.distributorForm.reset();
                    this.distributorForm.enable();
                })
            }
        }
    }

    goToEditMode() {
        this.isReadMode = false;
        this.isEditMode = true;
        this.distributorForm.enable()
    }

    onDelete() {
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : `Are you sure you want to delete this Distributor?`,
                image : '/assets/ui/delete.png',
                focus : 'right',
                deleteItem : 'Delete Distributor?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        })
        
        dialog.afterClosed().subscribe((row: any) => {
            if (row) {
                this._distributorsListService.deleteDistributor(this._addUserService.distributorId).then((response: any) => {
                    this._matSnackBar.open(response.message, 'OK', { duration: 2000});
                    if (response.status == 200) {
                        this._router.navigate(['/distributors'])
                    }
                }).catch(err => {
                    this._matSnackBar.open(err.message, 'OK', { duration: 2000});
                })
            }
        });
    } 

    updateTransaction(amount?){
        if(this._addUserService.allEventsOfDistributer.length>0){
            this._matDialog.open(DepositDialogComponent,{
                panelClass: 'no-padding-dialog',
                autoFocus : false,
                data: {
                    id:this._addUserService.distributorId,
                    eventsList : this._addUserService.allEventsOfDistributer,
                    amount: amount
                }
            })
        }
        else{
            this._matSnackBar.open("No Event Found","okay",{duration:2000})
        }
    }
    

}