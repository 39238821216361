import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { filter } from 'lodash';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SentNotificationsListService implements Resolve<any> {

  notificationList:[] = [];
  maxAvailableRecordsInTable:number;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _commonService: CommonService,

  ) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllSentNotification({pageSize : 10, pageIndex: 1})
      ]).then(()=>{

        resolve('');

      }, reject)
    })
  }

  getAllSentNotification(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject)=>{
      this._httpClient.post(environment.apiURL + 'admin/sentNotifications', body, this._commonService.getHeader()).subscribe((res:any) => {
        if(res.status == 200){
          this.notificationList = res.data.notificationList;
          this.maxAvailableRecordsInTable = res.data.totalRecords;
        }else{
          this.notificationList = [];
          this.maxAvailableRecordsInTable = 0;
          this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
        }
        this._fuseProgressBarService.hide();
        resolve(res)
      }, reject)
    })
  }

}
