import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { MeetingLocationService } from './meeting-location.service';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { AddUpdateMeetingLocationComponent } from '@fuse/Dialog/add-update-meeting-location/add-update-meeting-location.component';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-meeting-locations-list',
  templateUrl: './meeting-locations-list.component.html',
  styleUrls: ['./meeting-locations-list.component.scss'],
  animations : fuseAnimations

})
export class MeetingLocationsListComponent implements OnInit {

  maxAvailableRecordsInTable: any;
  dataSource;
  displayedColumns = ['id', 'name', 'createdDate', 'action'];
  filter = {
    search : '',
    pageIndex: 1,
    pageSize: 10
  }
  dialogRef: any;

  constructor(
    private _ToolbarService : ToolbarService,
    private _MatSnackBar : MatSnackBar,
    private _MeetingLocationService : MeetingLocationService,
    private _CommonService : CommonService,
    private _dialog : MatDialog
  ) {
    this.setToolBarTitle()

    this._MeetingLocationService.meetingLocations.subscribe((res)=>{
      this.dataSource = new MatTableDataSource(res)
    })
    
    this.maxAvailableRecordsInTable = this._MeetingLocationService.maxAvailableRecordsInTable;

   }

  ngOnInit(): void {
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Meeting Locations');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  filterData(){
    this._MeetingLocationService.getAllMeetingLocations(this.filter).then((res)=>{
      if(res)
      this.maxAvailableRecordsInTable = this._MeetingLocationService.maxAvailableRecordsInTable;
    })
  }

  onPageChange(event){
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData();
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.search = val
    this.filterData();
  }

  @ViewChild('search', { static: true }) search;
  clearSearch() {
      this.filter.search = ''
      this.search.nativeElement.focus()
      this.filterData()
  }

  onOpenMeetingLocationDialog(mode, meetingData?){
    const dialog = this._dialog.open(AddUpdateMeetingLocationComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus : false,
      width : '450px',
      data : {
        mode : mode,
        meeting : meetingData || null
      }
    })

    dialog.afterClosed().subscribe((res)=>{
      if(res?.value){
        if(res.mode == 'add'){
          this._CommonService.addNewMeetingLocation({name: res?.value?.title}).then((response:any) => {
            if(response.status == 200){
              this._MatSnackBar.open(response.message, 'OKAY', {duration: 3000})
              this.filterData();
            }else{
              this._MatSnackBar.open(response.message, 'OKAY', {duration: 4000})
            }
          })
        }else if(res.mode == 'update'){
          this._CommonService.updateMeetingLocations({id: meetingData.id, name: res?.value?.title}).then((response:any) => {
            if(response.status == 200){
              this._MatSnackBar.open(response.message, 'OKAY', {duration: 3000})
              this.filterData();
            }else{
              this._MatSnackBar.open(response.message, 'OKAY', {duration: 4000})
            }
          })
        }
      }
    })
  }

  onDelete(id) {
    const dialog = this._dialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Meeting location?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Location?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }

    })

    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this._CommonService.deleteMeetingLocations({"id": id }).then((res: any) => {
          if (res.status == 200) {
            this._MatSnackBar.open(res.message, 'OKAY', { duration: 2000 })
            this.filterData();
          }else{
            this._MatSnackBar.open(res.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

}
