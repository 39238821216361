import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../pages/auth.service';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})

export class OrderService implements Resolve<any> {
  permissions;
  orders = [];
  maxAvailableRecordsInTable;
  _getOrdersTotalAmount: BehaviorSubject<any>;
  
  allEvents: any;

  allCountries
  
  // for create order purposes
  upcommingPublishedEvents: any = null;
  selectedEventData: any = null;
  allStates: any;
  

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _authService: AuthService,
    private _MatSnackBar : MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    
  ) {
    this._getOrdersTotalAmount = new BehaviorSubject(0);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("route", route);
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getOrders(),
        this.getAllEvents(),

        this._commonService.getAllCountries().then((result:any) => {
          if(result.length)
          this.allCountries = result   
          else
          this.allCountries = result
        }),
        this._commonService.getStates(0).then((result:any) => {
          this.allStates = result.data;
        }), 
        // this.getOrderMembersById(),
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    });
  }

  getOrders(filter?) {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getall/orders', filter ? filter : {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.orders = response.data.data;
              console.log("1. this.orders.length", response);
              /* total price count */
              const totalPrice = this.orders.map((item)=>{
                return  item = item.amount;
              })
              const sum = totalPrice.reduce((a, b) => a + b, 0);
              this._getOrdersTotalAmount.next(sum);
              this.maxAvailableRecordsInTable = response.data.totalRecords
          }
          else if(response.status == 201){
            this.orders = [];
            this._getOrdersTotalAmount.next(0);
            this.maxAvailableRecordsInTable = 0;
            resolve(response);
          }
          else if (response.status == 400) {
            this.orders = [];
            this._getOrdersTotalAmount.next(0);
            this.maxAvailableRecordsInTable = 0;
            resolve(response);
          }
          resolve(response);
        }, reject);
    });
  }

  getOrderMembersById(payload?){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) =>{
      this._httpClient.post(environment.apiURL + 'admin/get/order/ticketinfo',payload, this._commonService.getHeader())
      .subscribe((response: any) =>{
        this._fuseProgressBarService.hide();
        if(response.status == 200){
          resolve(response);
          this._MatSnackBar.open(response.message, 'OKAY', {duration : 3000});
        }
        else if (response.status == 400) {
          resolve(response);
          this._MatSnackBar.open(response.message, 'OKAY', {duration : 3000});
        }
      }, reject);
    })
  }

  getAllEvents() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallevents', {"eventId":64}, this._commonService.getHeader())
        .subscribe((response: any) => {
          console.log("your new data is here");
          // this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.allEvents = response.data.data;
            // this.maxMember = this.allEvents
            resolve(response);
          }
          else if (response.status == 400) {
            this.allEvents = [];
            resolve(response);
          }
        }, reject);
    });
  }

  /* for filter by slot */
  getTicketCreatedEventsList(payload?){
    return new Promise((resolve, reject) =>{
        this._httpClient.post(environment.apiURL + 'admin/getTicketsGeneratedEvents',payload, this._commonService.getHeader())
        .subscribe((response: any) =>{
            if(response.status == 200){
                resolve(response);
            }else{
              reject
            }
          })
      })
  }

   /* for filter by slot */
   getExportFile(payload?){
    return new Promise((resolve, reject) =>{
        this._httpClient.post(environment.apiURL + 'admin/exportOrders',payload, this._commonService.getHeader())
        .subscribe((response: any) =>{  
            resolve(response);
          })
      })
  }

  getUpcommingPublishedEvents() {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallpublishedevents', {}, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          this.upcommingPublishedEvents = result.data.data
          console.log("this.upcommingPublishedEvents", this.upcommingPublishedEvents);
          resolve(result)
        }
        else if (result.status == 400) {
          this.upcommingPublishedEvents = null;
          resolve(result)
        }
         else {
          this.upcommingPublishedEvents = null
        }
      }, reject)
    })
  }

}
