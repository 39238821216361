import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { validate } from 'json-schema';
import * as countries from '../../../static-data/countries.json'
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { AddCustomerService } from './add-customer.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddCustomerComponent implements OnInit {
  previewUrl
  isImageValid = true;
  form: UntypedFormGroup
  customerId;
  countries = countries['default'];
  croppedImageBase64=''

  constructor(
    private fb: UntypedFormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _matDialog: MatDialog,
    public _addCustomerService:AddCustomerService
  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((param: Params) => {
      this.customerId = param.id;
    })

    this.form = this.fb.group({
      name: ['', Validators.required],
      mobile: ['', [Validators.required, Validators.pattern]],
      gender: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      dob: [''],
      city: [''],
      state: [''],
      country: [''],
      image: ['']
    });
  }

  @ViewChild('fileUpload', { static: true })
  fileUpload: ElementRef;

  onCustomerDelete() {

  }
  removeImage() {
    this.fileUpload.nativeElement.value = ''
    this.previewUrl = ''
    this.form.get('image').setValue(null)
  }

  readUrl(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('image type is ', file.type)
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        this.isImageValid = true;
      } else {
        this.isImageValid = false;
      }
      if (this.isImageValid) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrl = reader.result;
          this.openCropper(event)
        }
      }
    }
  }

  openCropper(event) {
    console.log(event)
    this._matDialog.open(CropperComponent, {
        // height: '80vh',
        width: '50vw',
        data: { imageEventToCrop: event, ratio: 1 / 1, message: 'square' }
    }).afterClosed().subscribe((dialogData: any) => {
        if (dialogData) {
            this.croppedImageBase64 = dialogData.croppedImageBase64;
            this.form.get('image').setValue(dialogData.croppedImageFile);
        } else {
            this.fileUpload.nativeElement.value = ''
        }
    });
}

}
