import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadTicketCodesService {
  upcommingPublishedEvents:any;
  allTickets:any;
  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("new route", route);
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getUpcommingPublishedEvents(),
      ]).then(
        () => {
          resolve('');
        },
        reject
      );

    })
  }

  getUpcommingPublishedEvents() {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallpublishedevents', {}, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          this.upcommingPublishedEvents = result.data.data;
          resolve(result)
        }
        else if (result.status == 400) {
          this.upcommingPublishedEvents = [];
          resolve(result)
        }
         else {
          this.upcommingPublishedEvents = [];
        }
      }, reject)
    })
  }

  getAllTickets(data?) {
    const payload = {
      eventId: Number(data.eventId),
      pageSize : 0,
      pageIndex : 0
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/tickets/getalltickets', data ? data: payload, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          resolve(result);
        }
        else if(result.status == 400){
          resolve(result);
        }
         else {
          resolve([]);
        }
      }, reject)
    })
  }

  // getOrdersForTickets // this api get orders status according to event, slot, and tickets
  getOrdersForTickets(data){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/tickets/getOrdersForTickets', data, this._commonService.getHeader()).subscribe((result:any)=>{
        resolve(result)
      }, reject)
    })
  }

}
