import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { FuseUtils } from '@fuse/utils';
import { Subject, fromEvent, BehaviorSubject, Observable, merge } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { DataSource } from '@angular/cdk/table';
import { DistributorsListService } from 'app/main/distributors/distributors-list/distributors-list.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-select-receiver',
  templateUrl: './select-receiver.component.html',
  styleUrls: ['./select-receiver.component.scss']
})
export class SelectReceiverComponent implements OnInit {

  tableData = [
    {id:1,name:'name',email:'email@email.com',contact:'65464'}
  ]
  dataSource: FilesDataSource | null;
  displayedColumns = ['select', 'name', 'email', 'contact'];

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;

  // @ViewChild('filter', { static: true })
  // filter: ElementRef;

  disable: boolean = false;
  dialogRef: any;
selectedUsers

  isAllSelected() {
    const numSelected = this.selectedUsers.length;
    const numRows = this.dataSource._dummy_data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selectedUsers= [];
    } else {
      this.selectedUsers = [];
      this.dataSource._dummy_data.forEach(row => {
        this.selectedUsers.push(row);
      });
    }
  }


  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(

    private _customersListService: DistributorsListService,
    private _router: Router

  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  ngOnInit(): void {
    
    this.tableData = this._customersListService.distributors;
    this.dataSource = new FilesDataSource(this.tableData, this.paginator, this.sort);
  }

  checkSelectino(row) {
    let result = this.selectedUsers.filter(data => data.id == row.id)
    if (result[0] == undefined) {
      return false;
    } else {
      return true;
    }
  }

  toggle(row){
    
    console.log('row from select user')
    
    let index = this.selectedUsers.findIndex(data=>data.id==row.id);
    if (index !== -1) {
      this.selectedUsers.splice(index, 1);
      console.log('row removed')
      console.log(this.selectedUsers)
    }else{
      this.selectedUsers.push(row)
      console.log('row added')
      console.log(this.selectedUsers)
    }
    }

  onSubmitSelectUsers() {
   
    // this._selectUserService.selectionList = this.selectedUsers;
    this._router.navigate(['/notification']);
  }

}

export class FilesDataSource extends DataSource<any>
{
  private _filterChange = new BehaviorSubject('');
  private _filteredDataChange = new BehaviorSubject('');

  constructor(
    public _dummy_data,
    private _matPaginator: MatPaginator,
    private _matSort: MatSort
  ) {
    super();

    this.filteredData = _dummy_data;
  }

  /**
   * Connect function called by the table to retrieve one stream containing the data to render.
   *
   * @returns {Observable<any[]>}
   */
  connect(): Observable<any[]> {
    const displayDataChanges = [
      //this._customersListService.onCustomersChanged,
      this._matPaginator.page,
      this._filterChange,
      this._matSort.sortChange
    ];

    return merge(...displayDataChanges)
      .pipe(
        map(() => {
          let data = this._dummy_data.slice();

          data = this.filterData(data);

          this.filteredData = [...data];

          data = this.sortData(data);

          // Grab the page's slice of data.
          const startIndex = this._matPaginator.pageIndex * this._matPaginator.pageSize;
          return data.splice(startIndex, this._matPaginator.pageSize);
        }
        ));
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Filtered data
  get filteredData(): any {
    return this._filteredDataChange.value;
  }

  set filteredData(value: any) {
    this._filteredDataChange.next(value);
  }

  // Filter
  get filter(): string {
    return this._filterChange.value;
  }

  set filter(filter: string) {
    this._filterChange.next(filter);
  }

  filterData(data): any {
    if (!this.filter) {
      return data;
    }
    return FuseUtils.filterArrayByString(data, this.filter);
  }


  sortData(data): any[] {
    if (!this._matSort.active || this._matSort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      let propertyA: number | string = '';
      let propertyB: number | string = '';
      switch (this._matSort.active) {

        case 'name':
          [propertyA, propertyB] = [a.name, b.name];
          break;
        case 'contact':
          [propertyA, propertyB] = [a.contact, b.contact];
          break;
        case 'plan':
          [propertyA, propertyB] = [a.plan, b.plan];
          break;
      }

      const valueA = isNaN(+propertyA) ? propertyA : +propertyA;
      const valueB = isNaN(+propertyB) ? propertyB : +propertyB;

      return (valueA < valueB ? -1 : 1) * (this._matSort.direction === 'asc' ? 1 : -1);
    });
  }

  /**
   * Disconnect
   */
  disconnect(): void {
  }
}
