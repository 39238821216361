import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-image-viewer-dialog',
  templateUrl: './common-image-viewer-dialog.component.html',
  styleUrls: ['./common-image-viewer-dialog.component.scss']
})
export class CommonImageViewerDialogComponent implements OnInit {
  message : string;
  image : string;
  imageSize : string;
  buttonText :string;
  title : string;

  constructor(
    public matDialogRef: MatDialogRef<CommonImageViewerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any, 
  ) {

    this.message = this._data.message;
    this.image = this._data.image;
    this.imageSize = this._data.imageWidthSize;
    this.buttonText = this._data.buttonText;
    this.title = this._data.title;

   }

  ngOnInit(): void {
  }

}
