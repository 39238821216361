import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCertificateComponent } from './create-certificate/create-certificate.component';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { LySliderModule } from '@alyle/ui/slider';
import { LyIconModule } from '@alyle/ui/icon';
import { LyButtonModule } from '@alyle/ui/button';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { LyDialogModule } from '@alyle/ui/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatRadioModule } from '@angular/material/radio';
import { RightSideNavComponent } from './create-certificate/right-side-nav/right-side-nav.component';
import {MatMenuModule} from '@angular/material/menu';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatSliderModule } from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import { CertificateTemplatesComponent } from './certificate-templates/certificate-templates.component';
import { CertificateTemplatesService } from './certificate-templates/certificate-templates.service';
import { CertificateUpdateService } from './create-certificate/certificate-update.service';
import { GenerateCertificateComponent } from './generate-certificate/generate-certificate.component';
import { GenerateCertificateService } from './generate-certificate/generate-certificate.service';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { UserCertificateComponent } from './user-certificate/user-certificate.component'; 
import { UserCertificatesService } from './user-certificate/user-certificates.service';

const routes: Routes = [
  {
    path     : 'add-new',
    component: CreateCertificateComponent
  },
  {
    path     :  'templates',
    component: CertificateTemplatesComponent,
    resolve  : {
      data : CertificateTemplatesService
    } 
  },
  {
    path     : 'edit-certificate/:id',
    component: CreateCertificateComponent,
    resolve  : {
      data : CertificateUpdateService
    } 
  },
  {
    path     : 'generate-certificate',
    component: GenerateCertificateComponent,
    resolve  : {
      data : GenerateCertificateService
    } 
  },

  {
    path      : 'certificates',
    component : UserCertificateComponent,
    resolve   : {
      data : UserCertificatesService
    }
  }
 
] 

@NgModule({
  declarations: [
    CreateCertificateComponent,
    RightSideNavComponent,
    CertificateTemplatesComponent,
    GenerateCertificateComponent,
    UserCertificateComponent, 
  ],
  imports: [
    CommonModule,
    MatSliderModule,
    MatCardModule,
    MatToolbarModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTooltipModule,
    LyImageCropperModule,
    MatTableModule,
    ColorPickerModule,
    MatSelectModule,
    DragDropModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    MatMenuModule,
    LyDialogModule,
    MatProgressBarModule,
    NgxShimmerLoadingModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatSnackBarModule,
    MatRadioModule,
    MatToolbarModule,
    MatDialogModule,
    NgxFileDropModule,
    MatCheckboxModule,
    MatChipsModule,
    FusePipesModule,
    RouterModule.forChild(routes),
  ],
  exports : [
    RightSideNavComponent
  ]
})
export class CertificateModule { } 