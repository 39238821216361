import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { navigation } from 'app/navigation/navigation';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
// import { ApiEndPointUrl } from '../../../../@fuse/utils/systemEnums';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router"
import { ChangeProfileDialogComponent } from './change-profile-dialog/change-profile-dialog.component';
import { NotificationListService } from 'app/main/notification-lists/notification-list.service';
import { MatDialog } from '@angular/material/dialog'; 
import { CommonService } from '@fuse/services/common.service';
import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { ToolbarService } from './toolbar.service';
import { fuseAnimations } from '@fuse/animations';
import { MatFormField } from '@angular/material/form-field';
import { FormControl } from '@angular/forms';
import { timezones } from '@fuse/utils/timezones';
import { MatSelect } from '@angular/material/select';
import { environment } from 'environments/environment';




@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    animations : fuseAnimations
})

export class ToolbarComponent implements OnInit, OnDestroy { 
    timeZones = timezones;
    timeZone = new FormControl('');
    selectedTimeZone:any;
    // [x: string]: any;
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    userName: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    email: string
    unReadNotificationList:any;
    unReadNotiNum;
    unReadNotiLength;
    unReadNoti;
   
    unReadToRead;
    toCreateOrderLink;
    userLocalData;
    todayDate = new Date();
    // todayDate = new Date(new Date().setDate(new Date().getDate() - 3));
    
    isHide

    allDates;
    todayNotification;
    notificationId;
    userId = localStorage.getItem('id');

    // Private
    private _unsubscribeAll: Subject<any>;
    ipAddress: any;
    adminId: any;
    profile: any;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        public _ToolbarService : ToolbarService,
        public _commonService : CommonService, 
        private renderer: Renderer2,
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        public _dialog: MatDialog,
        private _RoleAuthGuardService : RoleAuthGuardService,
        private _httpClient: HttpClient,
        private _matSnackBar: MatSnackBar,
        private _router: Router,
        private _ActivatedRoute : ActivatedRoute,
        private _notificationListService : NotificationListService,
    ) {
         
        
        
        this._RoleAuthGuardService.toolbarVisible.subscribe((res) => {
            if(res)
                this.isHide = res;
            else 
                this.isHide = false;
        })
        // this.isHide = 

        this.userLocalData = localStorage
        this.getUnreadNotiList();
        // to get admin id;
        this.getAdminId();

        // Set the defaults;
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.navigation = navigation;
        this.userName = localStorage.getItem('userName');
        this.email = localStorage.getItem('userEmail');
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.getProfileInfo();
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
        this.selectedTimeZone = JSON.parse(localStorage.getItem('locationTimeZone'))
        console.log("selectedTimeZone", this.selectedTimeZone);
        
        
        this.timeZone.setValue(String(this.selectedTimeZone.id));

        this.timeZone.valueChanges.subscribe((res)=>{
            if(res){
                const selectedTimeZone:any = this.timeZones.filter((item:any)=> item.id == res)[0]
                this._commonService._selectedTimeZone.next(selectedTimeZone);
            }
        })
    }

    // onChangeTimeZone(){
        // }

    notiBtn(e){
        console.log("this is the element value", e.pushType);
        this.notificationId = e.id;
        if(e.pushType == 1){
            this._router.navigate(['/distributors']);
            console.log("distributors condition is true");
        }else if(e.pushType == 2){
            this._router.navigate(['/events']);
            console.log("events condition is true");
        }else if(e.pushType == 3){
            this._router.navigate(['/orders']);
            console.log("orders condition is true");
        }else if(e.pushType == 4){
            this._router.navigate(['/events']);
            console.log("events condition is true");
        }else{
            return;
        }
        this.getClickedUnreadNoti();
      

    }
    getClickedUnreadNoti(){
        const payload = {
            "userId": this.userId,
            "id": this.notificationId,
        }
        this._notificationListService.getReadNotification(payload).then((result: any) =>{
            if(result.status == 200){
                console.log("working okay", result);
                this.getUnreadNotiList();
            }
        })
    }

    notiReaded(){
        // this.unReadNoti = this.unReadNoti.map((obj:any)=>{
        //     return {...obj, isRead : 1}
        // });
        //     this.readNoti = this.unReadNoti.map((obj:any)=>{return {...obj, isRead : 1}
        // });
        // console.log("this.unReadToRead", this.readNoti);
        // console.log("all entryes", this.unReadNoti);
    }

    /* get 10 unread notification list */
    getUnreadNotiList(){
    const firstpayload = {
        userId: localStorage.getItem('id'),
        quick: 1,
        pageIndex: 1,
        pageSize: 10,
    };
    
    this._notificationListService.getAllNotiLitst(firstpayload).then((result:any) => {
        if (result.status == 200) {
            this.unReadNotificationList = result.data.transactionsList
            this.unReadNoti = this.unReadNotificationList.filter(item => item.isRead == 0)
            console.log("this.unReadNoti", this.unReadNoti);
            
            this.unReadNotiLength = this.unReadNoti.length; 

            // to find today word from date
            this.allDates = this.unReadNotificationList.map(item => new Date(item.createdDate));
            let todayDateDay =  this.todayDate.getDate();
            this.allDates = this.allDates.map(item => item.getDate());
            this.todayNotification = this.allDates.map(item => item == todayDateDay);
        }else{
            
        }
        })
    }
    
    // goRouterLink(i){
    //     console.log("create order link ->", i);
    //     if(this.toCreateOrderLink){
    //         this._router.navigate(['/orders']);
    //     }
    //     this.readNoti = this.unReadNoti.find(i => i.isRead = 1)
    //     console.log("this.readNoti", this.readNoti);   
    // }
    

    logout() {
        localStorage.clear();
        this._router.navigate(['/auth/login']);
    }

    onNavigateBack(){
        console.log("->>-->", this._ToolbarService.routerURL);
        if(!this._ToolbarService.routerURL){
            this._commonService.goBack();
        }else{         
            this._router.navigate([this._ToolbarService.routerURL])
        }
    }

    openChangePassDialog() {
        const changePassDialog = this._dialog.open(ChangePasswordDialogComponent,  {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: '400px',
            disableClose: true 
        });
        changePassDialog.afterClosed().subscribe(data => {
            const header = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('userToken')
            });
            
            if (data[1]) {
                const body = {
                    'oldPassword': data[1].oldPass,
                    'newPassword': data[1].newPass,
                }
                this._httpClient.post(environment.apiURL + 'admin/change/password', body, { headers: header })
                    .subscribe((response: any) => {
                        this._matSnackBar.open(response.message, 'OK', {
                            verticalPosition: 'bottom',
                            duration: 2000
                        });

                    }, error => {
                        this._matSnackBar.open(error.message, 'error', {
                            verticalPosition: 'bottom',
                            duration: 2000
                        });
                    }

                    );
            }
        })
    }

    getAdminId(){
        this.adminId = localStorage['id'];
    }

    // get user details
    getProfileInfo(){
        if(this.adminId){
            this._commonService.getProfile(this.adminId).then((result:any) => {
                this.profile = result.data[0];
                localStorage.setItem('isStripeConnected', this.profile.isStripeConnected)
            })
        }
    }


    openChangeProfileDialog() {
        const changeProfileDialog = this._dialog.open(ChangeProfileDialogComponent, { 
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width : '950px',
            disableClose: true,
            autoFocus:false,
            data : {
                user: this.profile ? this.profile : '',
            }
        })

        changeProfileDialog.afterClosed().subscribe(data => {
            if(data){
                this.getProfileInfo();
            }
        })
    }



    
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

  

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
