import { moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { addRanking } from '@fuse/utils/systemEnums';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { privateDecrypt } from 'crypto';

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.scss'],
  animations : fuseAnimations
})
export class TracksComponent implements OnInit {

  eventData: any;   

  apiDataStatus = {
    isDataLoading : false 
  }
  dataSource:[] = [];

  draggableMode : boolean = false;

  constructor(
    public _commonService : CommonService,
    public _addEditEventService: AddEditEventService,
    private _matSnackBar: MatSnackBar,
    private _matDialog : MatDialog,
    private _ActivatedRoute : ActivatedRoute
    
  ) { 

    this.updateEventDetails();

  }


  ngOnInit(): void {
    
  }

  updateEventDetails(){
    this._addEditEventService.getEvent(this._addEditEventService.eventId).then((res:any)=>{
      if(res.status == 200){
        this.eventData = res.data;
        if(this.eventData?.isTrackAvailble){ 
          this.apiDataStatus.isDataLoading = true;
          this.filterData();
        }
      }else{
        this.eventData = null;
      }
    })
  }

  filterData(){
    this._commonService.getAllTrackByEvent({eventId : this.eventData.id}).then((result:any) =>{
      if(result.status == 200){
        this.apiDataStatus.isDataLoading = true;
        this.dataSource = result.data;
      }else if(result.status == 400){
        this.apiDataStatus.isDataLoading = true;
        this.dataSource = []; 
      }else{
        this.apiDataStatus.isDataLoading = true;
        this.dataSource = []; 
      }
    })
  }

  dropTrack(event){
    moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);

    // change ranking.
    this.dataSource.map((item:any, i) => {
      if(item)
        item.ranking = (i + 1)
    })

  }
 
  updateOrders(){
    this.draggableMode = false;
    const trackData = [];
    this.dataSource.forEach((element:any) => {
      trackData.push({trackId : element.id, ranking: element.ranking})
    })
    this._commonService.onRearrangeEventTrack({trackData:trackData}).then((res:any)=>{
      this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
    })
  }

  onDelete(item){
     
    const dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this track?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Track?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this.callDeleteApi(item)
      }
    })
  }


  callDeleteApi(item){
    const payload = {
      "eventId":this.eventData.id,
      "trackId":item.id
    }
    this._commonService.deleteTrack(payload).then((res: any) => {
      if (res.status == 200) {
        this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
        this.updateEventDetails()
      }
    })
  }

  
}
