import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { GenerateBadgeService } from './generate-badge.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ChooseCertificateDialogComponent } from '@fuse/Dialog/choose-certificate-dialog/choose-certificate-dialog.component';
import { BadgeTemplatesService } from '../badge-templates/badge-templates.service';
import { SuccessDialogComponent } from '@fuse/Dialog/success-dialog/success-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generate-badge',
  templateUrl: './generate-badge.component.html',
  styleUrls: ['./generate-badge.component.scss'],
  animations : fuseAnimations,
})
export class GenerateBadgeComponent implements OnInit {
  isTemplateChoose : boolean = false;
  form : FormGroup;
  categoriesList:string;
  dataSource;
  allCertificate: [] = [];
  selectedTemplate;
  displayedColumns: string[] = ['select', 'customerName',  'email', 'mobile'];


  selectedEvent
  showDetails:boolean = false;
  filtered = {
    pageIndex: 1,
    pageSize: 10,
    search: "",
    eventId: ""
  }
  isIndeterminate: boolean;

  hasSelectedUsers: boolean;
  checkboxes: any = {};
  selectedUsers:any[] = [];
  userList: any[] = [];


  maxAvailableRecordsInTable

  constructor(
    private _fb : FormBuilder,
    private _ToolbarService: ToolbarService,
    private _CommonService :CommonService,
    private _matDialog : MatDialog,
    private _MatSnackBar : MatSnackBar,
    private _router : Router,
    private _fuseProgressBarService : FuseProgressBarService,
    private _BadgeTemplatesService : BadgeTemplatesService, 
    private _GenerateBadgeService : GenerateBadgeService,

  ) {
      this.setToolBarTitle(false);
      this.form = this.createForm();
      this.getAllCertificateList();

   }

  ngOnInit(): void {
    this.dataSource =  new MatTableDataSource(this._GenerateBadgeService.userList);
  }

  
  setToolBarTitle(edit) {
    if (edit) {
        // for title
        this._ToolbarService._ToolBarTitle.next("Select Attendees");
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    } else {
        // for title
        this._ToolbarService._ToolBarTitle.next("Generate label");
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  createForm(){
    return this._fb.group({
      selectedEvent : ['', Validators.required],
      selectedTempateId : ['', Validators.required]
    })
  }

  // EVENT SELECTION
  onEventSelect(value){   
    this._fuseProgressBarService.show();
    this._CommonService.getEventById({eventId : value}).then((res: any) => {
        if (res.status == 200) {
          this._fuseProgressBarService.hide(); 
          this.selectedEvent = res.data;  
          this.showDetails = true;
          let eventCate = [];
          this.filtered.eventId = res.data.id;
          this.selectedEvent.categoriesList.forEach((item) => eventCate.push(item.title));
          this.categoriesList = eventCate.join(', ');  
          this.filterData();
          
        }else{
          this._fuseProgressBarService.hide(); 
          // this._MatSnackBar.open(res.message, "OKAY", {duration : 3000})
          this.selectedEvent = null;
          this.showDetails = false;
          // this.categoriesList = '';
        }
    });
  }

  filterData() {
    this._GenerateBadgeService.getUserList(this.filtered).then((res: any) => {
      if (res.status == 200) {
        this.dataSource.data = this.addNewKey(res.data.userList);
        this.maxAvailableRecordsInTable = res.data.totalRecords;
      } else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  addNewKey(array) {
    array.forEach((obj) => {
      obj['checked'] = false;
    });
    return array;
  }

  checkBoxStuff(user) { 
    this.checkboxes[user] = this.selectedUsers.includes(user); 
  }

  onChooseCertificate(){
    const dialog = this._matDialog.open(ChooseCertificateDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      width : '794px',
      data : {
        certificates : this.allCertificate.length ? this.allCertificate  : []
      }
    }) 
    
    dialog.afterClosed().subscribe((res)=>{
      if(res){
        this.form.get('selectedTempateId').setValue(res.id);
        this.selectedTemplate = res;
        // this.filterData();
      }
    })

  }

  
  /**
   * Toggle selected contact by id
   *
   * @param id
   */
  selectionChanged(user): void {
    // debugger;
    // First, check if we already have that contact as selected...
    if (this.selectedUsers.length > 0) {
      const index = this.selectedUsers.indexOf(user);

      if (index !== -1) {
        this.selectedUsers.splice(index, 1);

        // Trigger the next event
        this.checkBoxStuff(user);
        this.selectAllCheckBoxState();
        this.storeInlocalStorage(this.selectedUsers);
        console.log('this.selectedUsers SelectionRemoved :', this.selectedUsers);


        // Return
        return;
      }
    }
      // If we don't have it, push as selected
      this.selectedUsers.push(user);

      this.checkBoxStuff(user);
      this.selectAllCheckBoxState();

      this.storeInlocalStorage(this.selectedUsers)

      console.log('this.selectedUsers SelectionADDED :', this.selectedUsers);
    
  }

    /** 
   * Maintains State of Select All CheckBox
   */

    selectAllCheckBoxState() {
      this.hasSelectedUsers = this.userList.length == this.selectedUsers.length
      this.isIndeterminate = (this.selectedUsers.length !== this.userList.length && this.selectedUsers.length > 0);
    }
  
    storeInlocalStorage(selectedUsers) {
      this.removeSelectedUsers();
      localStorage.setItem('selectedAttendeesForLabel', JSON.stringify(selectedUsers));
    }

    
    removeSelectedUsers() { 
      localStorage.removeItem('selectedAttendeesForLabel');
    }



  getAllCertificateList(){
    this._BadgeTemplatesService.onGetAllCertificateList({}).then((res:any) => {
      if (res.status == 200) {
        this.allCertificate = res.data;
      }else{ 
        this.allCertificate = [];
      }
    })
  }

  onGenerateCertificate(){
    // this.setToolBarTitle(true);
    this.isTemplateChoose = true; 
  }

  generateCertificate(type){
    console.log("userIdsArray", JSON.parse(localStorage.getItem('selectedAttendeesForLabel')));
    const payload = {
      eventId : this.selectedEvent.id,
      badgeId : this.form.get('selectedTempateId').value,
      isShow : 1, // default
      memberIdList : type == 1 ? this.getSelectedUser() : []
    }
    console.log("this is final payload", payload);
    this._CommonService.onGenerateLabel(payload).then((res:any)=>{
      if(res.status == 200){
        this.showSuccessDialog(res.message);
      }else{
        this._MatSnackBar.open(res.message, 'OKAY', {duration : 4000})
      }
    })
  }

  goBack(){
    this.isTemplateChoose = false; 
    this.removeSelectedUsers();
  }

  showSuccessDialog(message){
    const dialog = this._matDialog.open(SuccessDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '464px',
      autoFocus : false,
      data : {
        image : 'https://event-project-gts.s3.amazonaws.com/ceritificate/layout/backgroundImage/background_1711626310550.png',
        imageWidthSize : '164px',
        message : message,
        buttonText : 'View'
      }
    })

    dialog.afterClosed().subscribe((res)=>{
      this._router.navigate(['/badge/generated-labels'], {queryParams : {filterFor : this.selectedEvent?.id}})
    })
  }

  

  /**
     *  get selected user from localstorage
     * **/
  getSelectedUser() {
    if (localStorage.getItem('selectedAttendeesForLabel') != null) {
      JSON.parse(localStorage.getItem('selectedAttendeesForLabel')).map(contact => {
        this.checkboxes[contact] = true;
      });
    }
    return localStorage.getItem('selectedAttendeesForLabel') == null ? [] : JSON.parse(localStorage.getItem('selectedAttendeesForLabel'));
  }
}
