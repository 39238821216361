import { LyDialog } from '@alyle/ui/dialog';
import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { SocialSharePostService } from './social-share-post.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fuseAnimations } from '@fuse/animations';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { CommonMessageSubmitComponent } from '@fuse/Dialog/common-message-submit/common-message-submit.component';

@Component({
  selector: 'app-social-share-post',
  templateUrl: './social-share-post.component.html',
  styleUrls: ['./social-share-post.component.scss'],
  animations : [
    trigger('slideInOut', [
      state('in', style({
        right: 0,
      })),
      state('out', style({
        right: '-250px',
      })),
      transition('in => out', animate('300ms ease-out')),
      transition('out => in', animate('300ms ease-in')),
    ]),

    trigger('showHide', [
      transition(':enter',
        [
          style({ transform: 'translateY(-20px)', opacity: 0 }),
          animate(200, style({ transform: 'translateY(0px)', opacity: 1 })),
        ]),
      transition(':leave',
        [
          animate(200, style({ transform: 'translateY(-20px)', opacity: 0 })),
        ]),
    ]),
    fuseAnimations
  ]
})

export class SocialSharePostComponent implements OnInit {
  imageUrl: string = 'path_to_your_image.jpg'; // Set your image path here
  @ViewChild('imageDiv') imageDiv: ElementRef;
  clickTimer;
  
  public form: FormGroup;
  parentElement: string = '';
  selectedEleId = null;
  aspectRatio:string;
  public socialPost = 'socialPost';
  public menuState = 'out';
  eventData;

  isViewMode:boolean = false;
  isUpdateMode: boolean = false;

  toggleMenu() {
      this.menuState = this.menuState === 'out' ? 'in' : 'out';
  }
  postId:number;


  constructor(
    private _fb : FormBuilder,
    public _SocialSharePostService : SocialSharePostService,
    private _snackBar : MatSnackBar, 
    private  _commonService:  CommonService,
    private _matDialog : MatDialog,
    private _addEditEventService : AddEditEventService,
    private _dialog: LyDialog,
  ) {

    
    this.eventData = this._addEditEventService.eventData;
    this.getAllBackgroundImages();
    this.createForm();
  
  }

  ngOnInit(): void {
    
    // update case.
    if(this.eventData?.isEventHasSocialPost){
      this._commonService.onGetSocialPostByEvent({eventId : this.eventData.id}).then((socialPost:any) => {
        if(socialPost.status == 200){ 
          this.postId = socialPost.data.id;
          this.createForm(socialPost.data);
          this.form.disable();
          this.isViewMode = true;
          this.isUpdateMode = true;
          this.setImageDimensions(socialPost.data.layout.backgroundImage);
          this._SocialSharePostService.templateData = socialPost.data.layout;
        }else{
          this._snackBar.open(socialPost.message, 'OKAY', {duration : 3000})
        }
      }) 
    }
    // add case.
    else{ 
      this.createForm();
    }

  }



  @HostListener('document:keydown', ['$event']) onClickEvent(event: any) {
    let keys = event.keyCode;
    if (this.selectedEleId) {
      switch (keys) {
        case 37:
          this.leftClick()
          break;
        case 38:
          this.upClick()
          break;
        case 39:
          this.rightClick()
          break;
        case 40:
          this.downClick()
          break;
      }
    }
  }

  
  leftClick() {
    if (!(this.selectedEleId == 'ticket')) {
        let selectedElement;
        if (this.parentElement == '') { selectedElement = this._SocialSharePostService.templateData[this.selectedEleId] }
        else { selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
        if (selectedElement?.position?.left > 0) selectedElement.position.left -= 0.5;
    }
  }

  rightClick() {
    if (!(this.selectedEleId == 'ticket')) {
        let selectedElement
        if (this.parentElement == '') { selectedElement = this._SocialSharePostService.templateData[this.selectedEleId] }
        else { selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
        if (selectedElement.position.left < Number(this._SocialSharePostService.defaultTemplateFormat.width - selectedElement.width))
        selectedElement.position.left += 0.5;
      }
  }

  upClick() {
      if (!(this.selectedEleId == 'ticket')) {
          let selectedElement
          if (this.parentElement == '') { selectedElement = this._SocialSharePostService.templateData[this.selectedEleId] }
          else { selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
          if (selectedElement.position.top > 0) selectedElement.position.top -= 0.5;
      }
  }

  downClick() {
      if (!(this.selectedEleId == 'ticket')) {
          let selectedElement
          if (this.parentElement == '') { selectedElement = this._SocialSharePostService.templateData[this.selectedEleId] }
          else { selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)] } 
          if (selectedElement.position.top < 310)
          selectedElement.position.top += 0.5;
      }
  }

  globalAlign(val) {
    let selectedElement
    if (this.parentElement == '') {
      selectedElement = this._SocialSharePostService.templateData[this.selectedEleId]
    }
    else {
      selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)]
    }

    if (val == 'hl') {
      selectedElement.position.left = 0;
    }
    else if (val == 'hc') {
      selectedElement.position.left = 50;
    }
    else if (val == 'hr') {
      selectedElement.position.left = 100;
    }

    else if (val == 'vt') {
      selectedElement.position.top = 0;
    }
    else if (val == 'vc') {
      selectedElement.position.top = 50;
    }
    else if (val == 'vb') {
      selectedElement.position.top = 100;
    }
  }

  onEditMode(){
    this.isViewMode = false;
    this.form.enable();
  }

  mouseup() {
    clearInterval(this.clickTimer);
  }


  rotateRight() {
    this.clickTimer = setInterval(() => {
      let selectedElement
      if (this.parentElement == '') {
          selectedElement = this._SocialSharePostService.templateData[this.selectedEleId]
      }
      else {
          selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)]
      }
          selectedElement.rotate++
    }, 1)
  }

  rotateLeft() {
      this.clickTimer = setInterval(() => {
        let selectedElement
        if (this.parentElement == '') {
          selectedElement = this._SocialSharePostService.templateData[this.selectedEleId];
        }
        else {
          selectedElement = this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)];
        }
        selectedElement.rotate--
      }, 1)
  }

  

  createForm(data?){
    console.log("data", data?.content);
    this.form = this._fb.group({
      content : [data ? data?.content: '', Validators.required],
      layout : [data ? data : '', Validators.required]
    })
  }

  openCropperForBg(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 868,
                    height: 608,
                    round: false,
                    type: "image/png",
                    keepAspectRatio: false,
                    responsiveArea: true,
                    output: {
                        width: 868,
                        // height: 608,
                    },
                    resizableArea: true,
                },
            },
            width: 520,
            height : 520,
            disableClose: true,
        })
        .afterClosed.subscribe((result: any) => {
            if (result) {
                console.log("result", result);
                this.onFileUpload(result.file, result.blobFile)
            }
        });
  }


  onFileUpload(fileObj, blobFile) {
    const payload = {
        "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
        "fileName": 'background_',
        "folderName": 'socialShare/backgrounds/backgroundImage', // it is fix set by backend. 
        'mimeType': fileObj?.type
    }
    this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) {
          
            // payload for get Image upload status
            const payload = {
                'id': result.data.id,
                'status': 1 // fix by backend. 
            }
            this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile).then((res)=>{
                if(res){
                    this._SocialSharePostService.templateData.backgroundImage = result.data.imageUploadUrl;
                    // this._SocialSharePostService.templateData.width = fileObj.width;
                    // this._SocialSharePostService.templateData.height = fileObj.height;
                    // this.aspectRatio = this.getAspectRatio(fileObj.width, fileObj.height)
                    // this._SocialSharePostService.templateData.aspectRatio = this.getAspectRatio(fileObj.width, fileObj.height);
                    console.log("this._SocialSharePostService.templateData", this._SocialSharePostService.templateData);
                    this.onAddSocialBgImage(result.data.imageUploadUrl);
                }
            })

            this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                if (uploadStatusResponse.status == 200) {
                    this._snackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                } else {
                    this._snackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                }
            })
        }
    })
  }

  onAddSocialBgImage(url){
    const payload = {
      eventId : this.eventData.id,
      image : url
    }
    this._commonService.onAddSocialBgImage(payload).then((res:any) => { 
      if(res.status == 200){ 
        this.getAllBackgroundImages();
      }
    })
  } 

  onElementClick(event, eleType?, item?) {
    console.log("item", item);
    console.log("eleType", eleType);
      if (eleType) {
        this.parentElement = eleType;
      } else {
        this.parentElement = '';
      }
      this.selectedEleId = '';
      this.selectedEleId = event.target.id;
      console.log("event.target.id", event.target.id);
      console.log("parentElement", this.parentElement);
      
      // if(event.target.id != 'socialPost' && (!item?.isContentEditing || item?.link)){
      //     // this.dragElement(document.getElementById(event.target.id), item)
      // }
  }

  dragElement(elmnt, item) {
    console.log("dragElement", item);
    let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;

        if (document.getElementById(elmnt.id + "header")) {
            /* if present, the header is where you move the DIV from:*/
            document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
        } else {
            /* otherwise, move the DIV from anywhere inside the DIV:*/
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            // e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            // e.preventDefault();
            // calculate the new cursor position:
            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;
            // set the element's new position:

                elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
                elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
                
                item.position.top = elmnt.style.top;
                item.position.left =  elmnt.style.left;
                
        }   

        function closeDragElement() {
            /* stop moving when mouse button is released:*/
            document.onmouseup = null;
            document.onmousemove = null;
        }
    

  }

  onShowHideEle(isShow) {
    if (this.parentElement == '')
    this._SocialSharePostService.templateData[this.selectedEleId].display = isShow
    else
    this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].display = isShow
  }

  onSavePost(){

    this._SocialSharePostService.templateData.dynamicElements.map((element) => {  
      if(element.position.top){
          if(String(element.position.top).endsWith('px')){
              element.position.top = String(element.position.top).slice(0, String(element.position.top).length - 2)
          }
      }
      if(element.position.left){
          if(String(element.position.left).endsWith('px')){
              element.position.left = String(element.position.left).slice(0, String(element.position.left).length - 2)
          }
      }
  })

  if(this._SocialSharePostService.templateData?.profile?.position?.top){
      if(String(this._SocialSharePostService.templateData.profile.position.top).endsWith('px')){
          this._SocialSharePostService.templateData.profile.position.top = Number(String(this._SocialSharePostService.templateData.profile.position.top).slice(0, String(this._SocialSharePostService.templateData.profile.position.top).length - 2))
      }
  }

  if(this._SocialSharePostService.templateData?.profile?.position?.left){
      if(String(this._SocialSharePostService.templateData.profile.position.left).endsWith('px')){
          this._SocialSharePostService.templateData.profile.position.left = Number(String(this._SocialSharePostService.templateData.profile.position.left).slice(0, String(this._SocialSharePostService.templateData.profile.position.left).length - 2))
      }
  }
  
    if(!this.isUpdateMode){
      const payload = {
        eventId : this.eventData.id,
        layout : this._SocialSharePostService.templateData,
        content : this.form.get('content').value,
        backgroundImage : this._SocialSharePostService.templateData.backgroundImage,
      }
      console.log("final payload", payload);
      
      this._commonService.onAddEventSocialTemplate(payload).then((result:any)=>{
        if(result.status == 200){
          this.form.disable();
          this.isViewMode = true;
          this._snackBar.open(result.message, 'OKAY', {duration : 3000})
        }else{
          this._snackBar.open(result.message, 'OKAY', {duration : 2000})
        }
      })
    }else{
      const payload = {
        eventId : this.eventData.id,
        layout : this._SocialSharePostService.templateData,
        content : this.form.get('content').value,
        id : this.postId,
        backgroundImage : this._SocialSharePostService.templateData.backgroundImage,
      }

      console.log("update final payload", payload);
      this._commonService.onUpdateSocialPostById(payload).then((res:any)=>{
        if(res.status == 200){
          this.isViewMode = true;
          this.form.disable();
          this._snackBar.open(res.message, 'OKAY', {duration : 3000})
        }else{
          this._snackBar.open(res.message, 'OKAY', {duration : 3000})
        }
      })
      
    }

  }

  
  getAllBackgroundImages(){
    this._commonService.getAllSocialBackgroundsByEvent({eventId : this.eventData.id}).then((res:any)=>{
        if(res.status == 200){
            this._SocialSharePostService.backgrounds =  res.data;
        }else{
            this._SocialSharePostService.backgrounds =  [];
        }
    })
  }
  

  onSelectBg(i){ 
    this._SocialSharePostService.backgrounds.forEach((item:any, j) => {
      if(j == i){
        item.isSelected = 1;
        this._SocialSharePostService.templateData.backgroundImage = item.image;
        this.setImageDimensions(item.image);
      }else{
        item.isSelected = 0; 
      }
    })

  }

  onDeleteBG(image){
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to remove this ?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Remove Background?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    })
  // handle after close
    dialog.afterClosed().subscribe((res) => {
      if(res){
        this._commonService.onDeleteBgImage({id : image.id}).then((result:any)=>{
          if(result.status == 200){
            this.getAllBackgroundImages();
            // check if deleteable image is selected then change the selected image
            if(image.isSelected){
              this._SocialSharePostService.templateData.backgroundImage = '';
            }
          }
          this._snackBar.open(result.message, "OKAY", {duration : 2000})
        })
      }
    })
  }

  setImageDimensions(url) {
    const img = new Image();
    img.onload = () => {
      const divElement = this.imageDiv.nativeElement;
      divElement.style.width = img.width + 'px';
      divElement.style.height = img.height + 'px';

      this._SocialSharePostService.templateData.width = img.width;
      this._SocialSharePostService.templateData.height = img.height; 
    };
    img.src = url
  }

  onAddElement(){
    const tempField = JSON.parse(JSON.stringify(this._SocialSharePostService.newDynamicElement))
    tempField.text = 'Enter Text Here';
    tempField.fieldName = '';
    tempField.contentEditable = true;
    
    if(this._SocialSharePostService.templateData.dynamicElements.length != 0)
      tempField.position.top = (this._SocialSharePostService.templateData.dynamicElements.length * 8);
    else
      tempField.position.top = 5

    this._SocialSharePostService.templateData.dynamicElements.push(tempField);
    console.log("this._certificateToolService.templateData.dynamicElements", this._SocialSharePostService.templateData.dynamicElements);
  }


  onEditContent(item){
    console.log("item --- ", item);
    this.openTextDailog(item)
  }

  openTextDailog(item){
    const dialog = this._matDialog.open(CommonMessageSubmitComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        disableClose: true,
        width : '380px',
        autoFocus : true,
        data : {
            title : `Add Custom Text`,
            label : 'Text',
            confirmButtonText : 'Save',
            errorMsg : 'Text is required.',
            text : item.text
        }
    })
    dialog.afterClosed().subscribe((res)=>{
        if(res)
            item.text = res;
    })
  }

  mousedown(key) {
    if (key == 'lc') {
        this.clickTimer = setInterval(() => {
            this.leftClick()
        }, 125)
    }
    else if (key == 'rc') {
        this.clickTimer = setInterval(() => {
            this.rightClick()
        }, 125)
    }
    else if (key == 'uc') {
        this.clickTimer = setInterval(() => {
            this.upClick()
        }, 125)
    }
    else if (key == 'dc') {
        this.clickTimer = setInterval(() => {
            this.downClick()
        }, 125)
    }
    else if (key == 'rr') {
        this.clickTimer = setInterval(() => {
            this.rotateRight()
        }, 125)
    }
    else if (key == 'rl') {
        this.clickTimer = setInterval(() => {
            this.rotateLeft()
        }, 125)
    }
  }


  changeFont(opreators) {
    if (this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].fontSize > 8 && opreators === '-') {
      this.parentElement == '' ? this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].fontSize-- : this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].fontSize--;
    } else if (this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].fontSize < 150 && opreators === '+') {
      this.parentElement == '' ? this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].fontSize++ : this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].fontSize++;
    }
  }

  setFontColor(color) {
    this.parentElement == '' ? (this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].fontColor = color) : (this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].fontColor = color)
  }

  onBoldClick() {
    if (this.parentElement == '') {
      this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].bold = !this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].bold
    } else
      this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].bold = !this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].bold
  }

  onItalicClick() {
    if (this.parentElement == '') {
        this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].italic = !this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].italic
    } else
        this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].italic = !this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].italic
    }

  onUnderlineClick() {
    if (this.parentElement == '') {
        this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].underline = !this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].underline
    } else
        this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].underline = !this._SocialSharePostService.templateData[this.parentElement][this.selectedEleId].underline
    }

    localAlign(value) {
        if (this.parentElement == '') {
            this._SocialSharePostService.templateData.dynamicElements[this.selectedEleId].align = value
        }
        else {
            this._SocialSharePostService.templateData[this.parentElement][parseInt(this.selectedEleId)].align = value
        }
    }
 

    onDeleteElement(item){
      const index = this._SocialSharePostService.templateData.dynamicElements.findIndex(element => element == item);
      this._SocialSharePostService.templateData.dynamicElements.splice(index, 1);
      this.selectedEleId = '';
      this.parentElement = '';
    }

    ngOnDestroy(): void {
      this._SocialSharePostService.templateData = {
        backgroundImage : '',
        dynamicElements : [],
        width : 868,
        height : 0,
        fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
        profile: {
          link: '',
          width: 100,
          display: true,
          opacity: 1,
          aspectRatio : '1/1',
          rotate: 0,
          position: {
            top: 31.99,
            left: 90.5
          }
        },
      }

      this._SocialSharePostService.defaultTemplateFormat = {
        backgroundImage : '',
        dynamicElements : [],
        width : 868,
        height : 0,
        fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
        profile: {
          link: 'https://event-project-gts.s3.amazonaws.com/common/image/image_1714548997454.png',
          width: 100,
          display: true,
          opacity: 1,
          aspectRatio : '1/1',
          rotate: 0,
          position: {
            top: 31.99,
            left: 90.5
          }
        },
      } 
  }
}
