import { Injectable } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'app/main/pages/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class EventTypeService implements Resolve<any>{

  eventTypes
  maxAvailableRecordsInTable
  permissions: any;
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _matSnackBar:MatSnackBar,
    private _authService:AuthService,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    // console.log("this.permissions",this.permissions);
    
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getAllEventTypes()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    })
  }


  getAllEventTypes(filter?) {
    // this._fuseProgressBarService.show();
    const mypayload = {
      pageIndex:1,
      pageSize:10
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/getall/eventtype', filter? filter : mypayload , this._commonService.getHeader())
        .subscribe((result: any) => {
          // this._fuseProgressBarService.hide();
          if (result.status == 200) {
            this.eventTypes = result.data.data
            this.maxAvailableRecordsInTable = result.data.totalRecords
            resolve(result)
          }else if(result.status == 400){
            this.eventTypes = []
            this.maxAvailableRecordsInTable = 0;
            resolve(result)
          }
          else{
            reject(result)
          }
          // this._fuseProgressBarService.hide()
        }, reject)
    })
  }
  deleteEventType(id) {
      this._fuseProgressBarService.show();

      return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/event/delete/eventtype',{eventTypeId:id}, this._commonService.getHeader())
        .subscribe((result: any) => {
          this._fuseProgressBarService.hide();
          if(result.status==200){
            resolve('')
          }
          this._fuseProgressBarService.hide()
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 2000
          });
        },reject)
    });
  }

}
