import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { CommonService } from '@fuse/services/common.service';
import { Router } from '@angular/router';

interface TYPE {
  id: string | number,
  title : string
}

@Component({
  selector: 'app-event-polls-surveys-list',
  templateUrl: './event-polls-surveys-list.component.html',
  styleUrls: ['./event-polls-surveys-list.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations : fuseAnimations
})
export class EventPollsSurveysListComponent implements OnInit {

  type:TYPE[] = [
    {id : 1, title : 'Polls'},
    {id : 2, title : 'Surveys'}
  ]

  currentType = 1;
  filterPS = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    searchFilter: '',
    itemsPerPage : 5, 
    currentPage : 1,
    totalItems:10,
  } 
  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  }); 
  eventData;
  dataSource

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('search', { static: false }) search;

  apiDataStatus = {
    isDataLoading : false 
  }

  constructor(  
    private _router : Router,
    public _ToolbarService : ToolbarService,
    public _addEditEventService: AddEditEventService,
    private _CommonService : CommonService
  ) { }

  ngOnInit(): void {
    this.eventData = this._addEditEventService?.eventData;
    if(this.eventData?.isPollsCreated){
      this.currentType = 1;
      this.apiDataStatus.isDataLoading = true;
      this.filterData();
    }else if(this.eventData?.isSurveysCreated){
      this.currentType = 2;
      this.apiDataStatus.isDataLoading = true;
      this.filterData();
    }
  }


  onSearch(val) {
    this.filterPS.currentPage = 1
    this.filterPS.searchFilter = val
    this.filterData();
  }

  
  clearSearch() {
    this.filterPS.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData();
  }

  onChangeType(){
    this.filterData();
  }
  
  
  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value){}
    this.filterData();
  }
  
  clearFilter() {
    // if(this.paginator){
    //   this.paginator.pageIndex = 0;
    // }
    this.range.get('start').reset();
    this.range.get('end').reset(); 
    this.filterPS.searchFilter = '';  
    this.filterData()
  }

  filterData(){
    const payload = {
      "search": this.filterPS.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventId":this.eventData.id,
      "pageIndex" :this.filterPS.currentPage,
      "pageSize":this.filterPS.itemsPerPage,
      "recType":this.currentType
    }
    console.log("payload", payload);
    this._CommonService.getAllPollSurvey(payload).then((res:any)=>{
      this.apiDataStatus.isDataLoading = false;
      if(res.status == 200){
        this.dataSource = res.data;
        this.filterPS.currentPage = res.data.pageIndex;
        this.filterPS.totalItems = res.data.totalRecords;
      }else{
        this.dataSource = [];
        this.filterPS.currentPage = 0;
        this.filterPS.totalItems = 0;
      }
    })
  }

  updateStatus(details){
    // console.log("details === >", details);
    const pollStatus = {
      draft : false,
      upcomming : false,
      ongoing : false,
      completed : false
    }
    // check poll is draft or publish
      if(details.status){
        pollStatus.draft = false;
      }else{
        pollStatus.draft = true;
        pollStatus.completed = false;
        pollStatus.ongoing = false;
        pollStatus.upcomming = false;
      }
      
      // make status if poll publish
      if(!pollStatus.draft){
        // console.log("get gap beetween start date", new Date(details?.startDate).getTime() - new Date().getTime() < 0);
        // console.log("get gap beetween end date", new Date(details?.endDate).getTime() - new Date().getTime() < 0);

        // upcomming
        if(!(new Date(details?.startDate).getTime() - new Date().getTime() < 0)){
          pollStatus.completed = false;
          pollStatus.ongoing = false;
          pollStatus.upcomming = true;
        }

        // ongoing
        if((new Date(details?.startDate).getTime() - new Date().getTime() < 0) && !(new Date(details?.endDate).getTime() - new Date().getTime() < 0)){
          pollStatus.completed = false;
          pollStatus.ongoing = true;
          pollStatus.upcomming = false;
        }
        
        // completed
        if((new Date(details?.endDate).getTime() - new Date().getTime() < 0)){
          pollStatus.completed = true;
          pollStatus.ongoing = false;
          pollStatus.upcomming = false;
        } 
      }else{
        pollStatus.completed = false;
        pollStatus.ongoing = false;
        pollStatus.upcomming = false;
      }

      // console.log("poll status", pollStatus);
      const trueKeys = this.getTrueKeys(pollStatus);
      return trueKeys;
  }

  getTrueKeys(obj) {
    let trueKeys = [];
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] === true) {
        trueKeys.push(key);
      }
    }
    return trueKeys;
  }

  onRedirect(item){
    if(this.currentType == 1 && this.currentType){
      // poll
      this._router.navigate(['/polls/detail/' + item.id])
    }else if(this.currentType == 2){
      // survey
      this._router.navigate(['/survey/detail/' + item.id])
    }
  }

}
