import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  // public _selectedTimeZone : BehaviorSubject<any>;
  public _ToolBarTitle: BehaviorSubject<any>;
  public _isBackBtnShow : BehaviorSubject<any>;
  public routerURL:string;
  showBackButton:false;
  goBackAction;
  userLocalData;

  title;
  backEnabled;

  constructor() {
    this._ToolBarTitle = new BehaviorSubject('');
    // this._selectedTimeZone = new BehaviorSubject('');
    this._isBackBtnShow = new BehaviorSubject(false)

    // for title
    this._ToolBarTitle.subscribe((value)=>{
      if(value){
        this.title = value;
      }
    })

    // for backbtn
    this._isBackBtnShow.subscribe((value)=>{
      if(value){
        this.backEnabled = value;
      }else{
        this.backEnabled = false;
      }
    })
    
    // for timezone
    // this._selectedTimeZone.subscribe((res)=>{
    //   this.currenttimeZone = res;
    //   // console.log("this.currenttimeZone", this.currenttimeZone); 
    // })
  }
  
}
