import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SendFeedbackComponent } from '@fuse/Dialog/send-feedback/send-feedback.component';
import { CommonService } from '@fuse/services/common.service';
import { FeedbackService } from './feedback.service';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styleUrls: ['./feedback-list.component.scss']
})
export class FeedbackListComponent implements OnInit {
  maxAvailableRecordsInTable: any;
  dataSource;
  displayedColumns = ['customerName','by','createdDate','eventName','subjects','message'];
  filter = {
    pageIndex: 1,
    pageSize: 10
  }
  dialogRef: any;
  constructor(
    public _commonService : CommonService,
    private _FeedbackService : FeedbackService,
    private _matDialog: MatDialog,
    private _matSnackBar:MatSnackBar,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this._FeedbackService.feedback.subscribe((value) => {
      this.dataSource = new MatTableDataSource(value);
    })
    this.maxAvailableRecordsInTable = this._FeedbackService.maxAvailableRecordsInTable;
  }

  onPageChange(event){
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.getFeedback();
  }

  getFeedback(){
    let data = {
      "pageSize": this.filter.pageSize,
      "pageIndex": this.filter.pageIndex,
    }
    this._FeedbackService.getAllFeedbackList(data).then((result:any) => {
      if(result.status == 200){
        this.dataSource.data = result.data.feedbackList;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else{
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
        this._matSnackBar.open(result.message, 'OK', {
          duration: 2000
        })
      }
    })
  }

  onAddFeedback(){
    this.dialogRef = this._matDialog.open(SendFeedbackComponent, {
      panelClass: 'no-padding-dialog',
      
    })
  }

}
