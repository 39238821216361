import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'drpSearch'
})
export class DrpSearchPipe implements PipeTransform {

  transform(value: any[], searchText:string) { 
    if (searchText.trim()) {
      let result = value.filter((item) => {
        if(item.name){
          return item.name.toLowerCase().search(searchText.trim().toLowerCase()) >= 0 ? true : false
        }else{
          return item.fullName.toLowerCase().search(searchText.trim().toLowerCase()) >= 0 ? true : false
        }
      })
      return result;
    } else {
      return value
    }
  }

}
