import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { CommonService } from "@fuse/services/common.service";
// import { ApiEndPointUrl } from "@fuse/utils/systemEnums";
import { environment } from "environments/environment";
import { reject } from "lodash";
import { promise } from "protractor";
import { Observable } from "rxjs";
import { resolve } from "url";

@Injectable({
  providedIn: "root",
})
export class NotificationListService {
  
  allNotificationList;
  maxAvailableRecordsInTable;

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _fuseProgressBarService: FuseProgressBarService,
  ) {
   
  }

  resolve(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {Promise.all([
      this._fuseProgressBarService.show(),
      this.getAllNotiLitst(),
    ]).then(() => {
        resolve("");
        this._fuseProgressBarService.hide();
      }, reject);
    });
  }
  

  /* 10 unread notification */
  getReadNotification(payload){
    return new Promise((resolve, reject) =>{
      this._httpClient.post(environment.apiURL + "admin/readNotification", payload, this._commonService.getHeader())
      .subscribe((response: any)=>{
        if(response == 200){
          console.log("all read notification", response);
        }else if(response == 400){
          resolve(response);
        }else{
          resolve(response);
        }
      }, reject)
    })
  }

  /* for get all notification list */
  getAllNotiLitst(payload?) {
    const firstpayload = {
      userId: localStorage.getItem('id'),
      quick: 0,
      pageIndex: 1,
      pageSize: 10,
    };
    
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + "admin/adminNotification", payload ? payload : firstpayload,this._commonService.getHeader())
        .subscribe((response: any) => {
          if (response.status == 200) {
            this.allNotificationList = response.data.transactionsList;
            this.maxAvailableRecordsInTable = response.data.totalRecords;
            // console.log("to find total records", this.maxAvailableRecordsInTable);
            resolve(response);
          } else if (response.status == 400) {
            this.allNotificationList = [];
            this.maxAvailableRecordsInTable = 0;
            resolve(response);
          } else {
            this.allNotificationList = [];
            this.maxAvailableRecordsInTable = 0;
            resolve(response);
          }
        }, reject);
    });
  }
}
