import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { CreateTicketService } from '../create-ticket.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@fuse/services/common.service';
import { ChooseTemplateDialogComponent } from '../../template/choose-template-dialog/choose-template-dialog.component';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-choose-ticket',
  templateUrl: './choose-ticket.component.html',
  styleUrls: ['./choose-ticket.component.scss']
})
export class ChooseTicketComponent implements OnInit {

  @Output() preBuildTemplate = new EventEmitter<any>();
  
  constructor(
    public matDialogRef: MatDialogRef<ChooseTicketComponent>,
    private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _createTicketService: CreateTicketService,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    public _commonService: CommonService,
  ) {
    matDialogRef.disableClose = true;
  }

  onTicketSelect(e) {
    this._fuseProgressBarService.show()
    this._createTicketService.getTicketById(e.value).then((result: any) => {
      this._fuseProgressBarService.hide()
      if (result.status == 200)
        this.matDialogRef.close({ ticketData: result.data })
      else
        this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
    })
  }

  onNewTicketClick() {
    this.matDialogRef.close(false);
  }

  ngOnInit() {
  }
  
  openTemplateDialog() {
    this._fuseProgressBarService.show()
    this._httpClient.get(environment.apiURL + 'admin/tickets/getall/layout', this._commonService.getHeader()).subscribe((result: any) => {
      this._fuseProgressBarService.hide()
      if (result.status == "200") {
        this._matDialog.open(ChooseTemplateDialogComponent, {
          height: '80vh',
          width: '80vw',
          disableClose: true,
          data: {
            layout: result.data.layout
          }
        }).afterClosed().subscribe((dialogData: any) => {
          if (dialogData) {
            this._createTicketService.applyTicketFormat(JSON.parse(JSON.stringify(dialogData.layout)))
            this.preBuildTemplate.emit(this._createTicketService.applyTicketFormat(JSON.parse(JSON.stringify(dialogData.layout))))
            this.matDialogRef.close();
          }
        });
      } else {
        this._matSnackBar.open(result.message, "OK", { duration: 4000 })
      }
    })
  }

}
