import { Component, OnInit, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { AddEditEventService } from '../edit-event/add-edit-event.service';
// import { eventTicketService } from './event-ticket-list.service';


@Component({
    selector: 'app-event-ticket-lists',
    templateUrl: './event-ticket-lists.component.html',
    styleUrls: ['./event-ticket-lists.component.scss']
})
export class EventTicketListsComponent implements OnInit {
    /* for ticket list */
    newtableData;
    tableData = [];
    maxAvailableRecordsInTable;
    dataSource;
    displayedColumns = ['SrNo','eventStartDate','totalSeats', 'totalbooked','totalavailable', 'action'];
    eventId;
    allticketdata;

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    @ViewChild('search', { static: true })
    search;

    
    constructor(
       private _commanService: CommonService,
       private _router: Router,
       private _addEditEventService : AddEditEventService
    ) {
        this.eventId = this._addEditEventService.eventId;
    }


    ngOnInit() {
        console.log("this is an result form service file", this._addEditEventService.allSlot);
        this.maxAvailableRecordsInTable = this._addEditEventService.maxAvailableRecordsInTableAllSlot;
        this.tableData = this._addEditEventService.allSlot;
        this.dataSource = new MatTableDataSource(this.tableData);
        console.log("this.tableData", this.dataSource);
    }


    filter = {
        pageSize: 10,
        pageIndex: 1
    }
    
    getSlotByEventId(){
        const payload = {
            "eventId": this.eventId,
            "pageIndex":this.filter.pageIndex,
            "pageSize":this.filter.pageSize
        }
        this._addEditEventService.getSlotByEvent(payload).then((result:any) =>{
          if (result.status == 200) {
            // this.tableData = result.data.data;
            this.dataSource.data = result.data.data;
            console.log("this.tableData");
            
            this.maxAvailableRecordsInTable = result.data.totalRecords;          
          }else if(result.status == 400){
            this.tableData = [];
            this.maxAvailableRecordsInTable = 0;
          }else{
            this.tableData = [];
            this.maxAvailableRecordsInTable = 0;
        }
        })
        
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.getSlotByEventId();
    }


    getBookingBySlot(eventId,slotId){
        console.log("your slot id is here");
        this._router.navigate(['/events/slotdetails'], { queryParams: {eventId: eventId, id: slotId}});
    }



}
