import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/main/pages/auth.service';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ListSubUserService implements Resolve<any>{
  allSubUsers: any = []
  permissions
  maxAvailableRecordsInTable = 0
  constructor(
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _matSnackBar:MatSnackBar,
    private _commonService:CommonService,
    private _fuseProgressBarService: FuseProgressBarService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route)
    
    return new Promise((resolve, reject) => {
      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllSubUsers()
      ]).then(
        () => {
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );
    });
  }

  getAllSubUsers(filter?) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/subuser/getall', filter ? filter : {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          if (response.status == 200) {
            this.allSubUsers = response.data.data;
            this.maxAvailableRecordsInTable = response.data.totalRecords;
          }else{
            this.allSubUsers = [];
            this.maxAvailableRecordsInTable = 0;
            this._matSnackBar.open(response.message,'OK',{duration:4000})
          }
          resolve(response)
        }, reject);
    });
  }

}
