import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { GenerateCertificateService } from './generate-certificate.service';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChooseCertificateDialogComponent } from '@fuse/Dialog/choose-certificate-dialog/choose-certificate-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CertificateTemplatesService } from '../certificate-templates/certificate-templates.service';
import { OrderService } from 'app/main/orders/order.service';
import { MatTableDataSource } from '@angular/material/table';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SuccessDialogComponent } from '@fuse/Dialog/success-dialog/success-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-generate-certificate',
  templateUrl: './generate-certificate.component.html',
  styleUrls: ['./generate-certificate.component.scss'],
  animations : fuseAnimations,
})
export class GenerateCertificateComponent implements OnInit, OnDestroy {
  selectedEvent;
  form : FormGroup;
  showDetails:boolean = false;
  categoriesList:string;
  allCertificate: [] = [];
  selectedTemplate;
  isTemplateChoose : boolean = false;
  selectedUsers:any[] = []
 
  filtered = {
    pageIndex: 1,
    pageSize: 10,
    search: "",
    eventId: ""
  }

  displayedColumns: string[] = ['select', 'customerName',  'email', 'mobile'];
  dataSource;
  userList: any[] = [];
  maxAvailableRecordsInTable;
  isIndeterminate: boolean;

  
  
  hasSelectedUsers: boolean;
  checkboxes: any = {};

   // Private
   private _unsubscribeAll: Subject<any>;
   
  constructor(
    private _fb : FormBuilder,
    public _GenerateCertificateService : GenerateCertificateService,
    private _router : Router,
    private _fuseProgressBarService : FuseProgressBarService,
    private _MatSnackBar : MatSnackBar, 
    private _CertificateTemplatesService : CertificateTemplatesService,
    public _orderService: OrderService,
    private _matDialog : MatDialog,
    public _CommonService : CommonService,
    private _ToolbarService: ToolbarService,
  ) {

    this.setToolBarTitle(false);
    this.form = this.createForm();
    this.getAllCertificateList();

  }

  createForm(){
    return this._fb.group({
      selectedEvent : ['', Validators.required],
      selectedTempateId : ['', Validators.required]
    })
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._GenerateCertificateService.userList); 

    // this._GenerateCertificateService.onUserListChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(users => {
    //   this.userList = users;
    //   this.selectedUsers = this.getSelectedUser();
    //   this.hasSelectedUsers = false;
    //   /** check if selected  user set in local storage */
    //   if(this.selectedUsers.length==0)
    //   {
    //       this.checkboxes = {};
    //       users.map(contact => {
    //           this.checkboxes[contact.id] = false;
    //       });
    //   }
    // })
    
  }

   /**
     * On destroy
     */
   ngOnDestroy(): void
   {
       // Unsubscribe from all subscriptions
       this._unsubscribeAll?.next([]);
       this._unsubscribeAll?.complete();
       this.removeSelectedUsers();
   }

   
    /**
     *  get selected user from localstorage
     * **/
    getSelectedUser() {
      if (localStorage.getItem('selectedAttendees') != null) {
        JSON.parse(localStorage.getItem('selectedAttendees')).map(contact => {
          this.checkboxes[contact] = true;
        });
      }
      return localStorage.getItem('selectedAttendees') == null ? [] : JSON.parse(localStorage.getItem('selectedAttendees'));
    }


    removeSelectedUsers() { 
      localStorage.removeItem('selectedAttendees');
    }



  // EVENT SELECTION
  onEventSelect(value){   
    this._fuseProgressBarService.show();
    this._CommonService.getEventById({eventId : value}).then((res: any) => {
        if (res.status == 200) {
          this._fuseProgressBarService.hide(); 
          this.selectedEvent = res.data;  
          this.showDetails = true;
          let eventCate = [];
          this.filtered.eventId = res.data.id;
          this.selectedEvent.categoriesList.forEach((item) => eventCate.push(item.title));
          this.categoriesList = eventCate.join(', ');  
          this.filterData();
          
        }else{
          this._fuseProgressBarService.hide(); 
          this._MatSnackBar.open(res.message, "OKAY", {duration : 3000})
          this.selectedEvent = null;
          this.showDetails = false;
          this.categoriesList = '';
        }
    });
  }

  
  onPageChange(event) {
    this.filtered.pageIndex = event.pageIndex + 1;
    this.filtered.pageSize = event.pageSize;
    this.filterData();
  }

  setToolBarTitle(edit) {
    if (edit) {
        // for title
        this._ToolbarService._ToolBarTitle.next("Select Attendees");
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    } else {
        // for title
        this._ToolbarService._ToolBarTitle.next("Generate Certificate");
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  onChooseCertificate(){
    const dialog = this._matDialog.open(ChooseCertificateDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      width : '794px',
      data : {
        certificates : this.allCertificate.length ? this.allCertificate  : []
      }
    }) 
    
    dialog.afterClosed().subscribe((res)=>{
      if(res){
        this.form.get('selectedTempateId').setValue(res.id);
        this.selectedTemplate = res;
        // this.filterData();
      }
    })

  }

  getAllCertificateList(){
    this._CertificateTemplatesService.onGetAllCertificateList({}).then((res:any) => {
      if (res.status == 200) {
        this.allCertificate = res.data;
      }else{ 
        this.allCertificate = [];
        this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000});
      }
    })
  }
  
  onGenerateCertificate(){
    // this.setToolBarTitle(true);
    this.isTemplateChoose = true; 
  }

  goBack(){
    this.isTemplateChoose = false; 
    this.removeSelectedUsers();
  }
  clearSearch() {
    this.filtered.search = '';
    this.filterData();
  }
 
  filterData() {
    this._GenerateCertificateService.getUserList(this.filtered).then((res: any) => {
      if (res.status == 200) {
        this.dataSource.data = this.addNewKey(res.data.userList);
        this.maxAvailableRecordsInTable = res.data.totalRecords;
      } else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }
 
  addNewKey(array) {
    array.forEach((obj) => {
      obj['checked'] = false;
    });
    return array;
  }

  /**
   * Toggle selected contact by id
   *
   * @param id
   */
  selectionChanged(user): void {
    // debugger;
    // First, check if we already have that contact as selected...
    if (this.selectedUsers.length > 0) {
      const index = this.selectedUsers.indexOf(user);

      if (index !== -1) {
        this.selectedUsers.splice(index, 1);

        // Trigger the next event
        this.checkBoxStuff(user);
        this.selectAllCheckBoxState();
        this.storeInlocalStorage(this.selectedUsers);
        console.log('this.selectedUsers SelectionRemoved :', this.selectedUsers);


        // Return
        return;
      }
    }
      // If we don't have it, push as selected
      this.selectedUsers.push(user);

      this.checkBoxStuff(user);
      this.selectAllCheckBoxState();

      this.storeInlocalStorage(this.selectedUsers)

      console.log('this.selectedUsers SelectionADDED :', this.selectedUsers);
    
  }


  checkBoxStuff(user) { 
    this.checkboxes[user] = this.selectedUsers.includes(user); 
  }


  
  /** 
   * Maintains State of Select All CheckBox
   */

  selectAllCheckBoxState() {
    this.hasSelectedUsers = this.userList.length == this.selectedUsers.length
    this.isIndeterminate = (this.selectedUsers.length !== this.userList.length && this.selectedUsers.length > 0);
  }


  storeInlocalStorage(selectedUsers) {
    this.removeSelectedUsers();
    localStorage.setItem('selectedAttendees', JSON.stringify(selectedUsers));
  }

  generateCertificate(type){

    const payload = {
      eventId : this.selectedEvent.id,
      id : this.form.get('selectedTempateId').value,
      isShow : 1, // default
      userIdsArray : type == 1 ? this.getSelectedUser() : []
    }

    // console.log("this is payload", payload);
    this._CommonService.onGenerateCertificate(payload).then((res:any)=>{
      if(res.status == 200){
        this.showSuccessDialog(res.message);
      }else{
        this._MatSnackBar.open(res.message, 'OKAY', {duration : 4000})
      }
    })
    
  }


  showSuccessDialog(message){
    const dialog = this._matDialog.open(SuccessDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '464px',
      autoFocus : false,
      data : {
        image : 'https://event-project-gts.s3.amazonaws.com/ceritificate/layout/backgroundImage/background_1711626310550.png',
        imageWidthSize : '164px',
        message : message,
        buttonText : 'View'
      }
    })

    dialog.afterClosed().subscribe((res)=>{
      this._router.navigate(['/certificate/certificates'], {queryParams : {filterFor : this.selectedEvent?.id}})
    })
  }

}