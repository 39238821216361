import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { CommonService } from "@fuse/services/common.service";
import { DefaultFilter } from "@fuse/utils/systemEnums";
import { Observable } from "rxjs";
import { EventListService } from "../events/events-list/event-list.service";
import { environment } from "environments/environment";

@Injectable({
  providedIn: "root",
})
export class ReportedEventsService {
  [x: string]: any;

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _eventListService: EventListService
    
  ) {}

  customerSubjectList;
  allEventList;

  allReportedEvents;
  maxAvailableRecordsInTable;

  resolve(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {Promise.all([
      this._fuseProgressBarService.show(),
      /* for get all subject list */
      this.getCustomerSubject(),
    
      /* for getting reported events list */
      this.getAllReport(),
      
      /* for all event list */
      this._eventListService.getEventsList().then((result) => {
        if (result.status == 200){
          this.allEventList = result.data.data
          
        }
        else if(result.status == 400){
          this.allEventList = []
        }
      })

    ]).then(() => {
        resolve("");
        this._fuseProgressBarService.hide();
      }, reject);
    });
  }

  /* for get all subjects list */
  getCustomerSubject() {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + "customer/getAllReportSubject", this._commonService.getHeader())
          .subscribe((result: any) => {
          if (result.status == 200) {
            this.customerSubjectList = result.data.subjectsList;
            resolve(result);
          }else if(result.status == 400){
            resolve(result);
          }else{
            resolve(result);
          }
        }, reject);
    });
  }


  /* all events report */
  getAllReport(payload?){
    const fisrtPayload = {
      "startDate": "0",
      "endDate" : "0",
      "eventId" : 0,
      "reason" : 0,
      "pageIndex":1,
      "pageSize":10
    }
    return new Promise((resolve, reject) =>{
      this._httpClient.post(environment.apiURL + 'admin/getEventReport', payload? payload : fisrtPayload, this._commonService.getHeader())
      .subscribe((result: any) =>{
        if(result.status == 200){
            this.allReportedEvents =  result.data.data;
            console.log("1 total recoeds", this.allReportedEvents);
            this.maxAvailableRecordsInTable = result.data.totalRecords;
            resolve(result);
        }else if(result == 400){
          resolve(result);
        }else{
          resolve(result);
        }
      }, reject);
    })
  }
}
