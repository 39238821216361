import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { InvitationsService } from '../invitations.service';
import { InvitationMessageDetailComponent } from '@fuse/Dialog/invitation-message-detail/invitation-message-detail.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.scss']
})
export class InvitationsComponent implements OnInit {
  upComingEvent: any
  maxAvailableRecordsInTable: any;
  displayedColumns: string[] = ['title', 'subject', 'sendVia', 'createdAt', 'scheduleTime', 'totalContact', 'status', 'action'];
  dataSource;
  dataSourceSendList;
  displayedColumnsSendList : String[] = ['title', 'subject', 'sendVia', 'createdAt', 'sendingTime',  'totalContact', 'status','action'];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  maxAvailableRecordsInTableSendList: any;

  constructor(
    private _InvitationsService: InvitationsService,
    private _ToolbarService : ToolbarService,
    public _route: Router,

    private _matDialog : MatDialog
  ) {
    this.dataSource = new MatTableDataSource(this._InvitationsService.invitationListingData.invitationListing);
    this.dataSourceSendList = new MatTableDataSource(this._InvitationsService.invitationSendList)
    // this.upComingEvent = this._InvitationsService.invitationListingData
    console.log(this.upComingEvent);
    this.maxAvailableRecordsInTable = this._InvitationsService.maxAvailableRecordsInTable
    this.maxAvailableRecordsInTableSendList = this._InvitationsService.maxAvailableRecordsInTableSendList
    this.setToolBarTitle();
    
   }

  ngOnInit(): void {
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Invitations');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  sendInvitation(item){
    this._route.navigate(["/Invitation/send-invitation/"+ item])
  }




   // ----------------------- filters section -----------------------

   filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    console.log("this is serached value",val)
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.paginator.pageIndex = 0;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "listingType": 2,
      "search": this.filter.searchFilter
      // "search": this.filter.searchFilter
    }
    this._InvitationsService.getInvitationListing(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.invitationListing;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }
      else {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  filterDataForSendInvitation() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "listingType": 2,
      "search": this.filter.searchFilter
      // "search": this.filter.searchFilter
    }
    this._InvitationsService.getInvitationHistory(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSourceSendList.data = result.data.invitationListing;
        this.maxAvailableRecordsInTableSendList = result.data.totalRecords;
      }
      else {
        this.dataSource.data = []
        this.maxAvailableRecordsInTableSendList = 0;
      }
    })
  }
  
  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  deleteInvitation(id){
    let dialog = this._matDialog.open(ConfirmationDialogComponent, { 
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Invitation?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Invitation?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    });

    dialog.afterClosed().subscribe((res:any)=>{
      if(res){
        const payload = {
          id: id
        }
        this._InvitationsService.onDeleteInvitation(payload).then((res:any) => {
          if(res?.status == 200){
            this.filterData();
          }else{

          }
        })
      }
    })
    
  }

  getInvitationDetail(id){
    this._route.navigate(['Invitation/edit-invitation/' + id]);
  }

  openMessageDialog(id){
    const payload = {
      id : id
    }
    this._InvitationsService.getInvitationMessageInfomation(payload).then((result:any) => {
      if(result){
        console.log(result);
        this._matDialog.open(InvitationMessageDetailComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          autoFocus : false,
          maxWidth : '450px',
          data : result?.data
        })
        
      }
    })
  } 
  
  onPageChangeForHistoryList(event){
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterDataForSendInvitation()
  }

  openAttachment(link){
    window.open(link)
  }
}
