import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { MatPaginator } from '@angular/material/paginator';
import { fuseAnimations } from '@fuse/animations';
import { MatTableDataSource } from '@angular/material/table';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-ticket-scanning-report',
  templateUrl: './ticket-scanning-report.component.html',
  styleUrls: ['./ticket-scanning-report.component.scss'],
  animations : fuseAnimations
})
export class TicketScanningReportComponent implements OnInit {

  filter = {
    eventId: 0,
    manualScanned: 0,
    locationId: 0,
    verifierId: 0,
    pageIndex: 1,
    pageSize: 10,
    search: "",
    ticketType: ""
  }
  apiDataStatus = {
    isDataLoading : false 
  }

  eventId:number; 
  eventData;
  isTicketScanned : boolean = false;
  dataSource = new MatTableDataSource([]);;
  maxAvailableRecordsInTable;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  displayedColumns = ['userName', 'scannedby', 'locationName', 'scanTime', 'scanType', 'ticketType', 'orderId', 'seatNumber','ticketNo'];

  materLocationsList:[] = []

  ticketTypes: [] = []

  asignedVerifiers:[] = []


  constructor(
    private _commanService: CommonService,
    private _messageBar : MatSnackBar,
    private _router: Router,
    private _addEditEventService : AddEditEventService,
    private _progressBar : FuseProgressBarService
  ) { 
    this.filter.eventId = this._addEditEventService.eventId;
    this.eventData = this._addEditEventService.eventData;
    console.log("TicketScanningReportComponent this.eventData === >", this.eventData);
    if(this.eventData.isTicketScanned){
      this.isTicketScanned = true;  
      this.apiDataStatus.isDataLoading = true;
      this.filterData()
    }else{
      this.apiDataStatus.isDataLoading = true;
      this.isTicketScanned = false;
    }
    
  }

  ngOnInit(): void {
    
    
    this.getAllVerifiersScanList();
    this.getAllTicketType();

    this.getEventAsignedVerifierList()

  }

  filterData(){
    this._progressBar.show();
    this._addEditEventService.getTicketScanningReport(this.filter).then((res:any)=>{
      this.apiDataStatus.isDataLoading = false;
      this._progressBar.hide();
      if(res.status == 200){
        this.dataSource.data = res.data.data;
        this.maxAvailableRecordsInTable = res.data.totalRecords;
      }else{
        this.dataSource.data = []; 
        this.maxAvailableRecordsInTable = 0;
        this._messageBar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
  }

  onSearch() {
    this.filter.pageIndex = 1;
    this.filterData();
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData();
  }

  @ViewChild('search', { static: true }) search;
  clearSearch() {
      this.filter.search = '';
      // this.search?.nativeElement.focus();
      this.filterData();
  }

  onFilter(){
    this.filterData();
  }

  clearFilter(){
    this.filter = {
      eventId: this.eventData.id,
      manualScanned: 0,
      locationId: 0,
      verifierId: 0,
      pageIndex: 1,
      pageSize: 10,
      search: "",
      ticketType: ""
    }
    this.filterData();
  }

  getAllVerifiersScanList(){
    this._commanService.getAllLocationName().then((res:any)=>{
      if(res.status == 200){
        this.materLocationsList = res.data;
      }else{
        this.materLocationsList = []; 
        this._messageBar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
  }

  
  getAllTicketType(){
    this._commanService.getAllTicketTypes().then((res:any)=>{ 
      if(res?.length){
        this.ticketTypes = res;
      }else{
        this.ticketTypes = [];
        this._messageBar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
  }

  getEventAsignedVerifierList(){
    this._commanService.getEventAsignedVerifierList({eventId : this.eventData.id}).then((res:any)=>{ 
      if(res?.status == 200){
        console.log("res", res.data);
        this.asignedVerifiers = res.data;
      }else{
        this.asignedVerifiers = []
      }
    })
  }


}
