import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddEditHostTypeComponent } from '@fuse/Dialog/add-edit-host-type/add-edit-host-type.component';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { HostTypesService } from './host-types.service';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-host-types',
  templateUrl: './host-types.component.html',
  styleUrls: ['./host-types.component.scss'],
  animations : fuseAnimations
})
export class HostTypesComponent implements OnInit {

  filter = {
    search: '',
    pageSize: 10,
    pageIndex: 1
  }
  maxAvailableRecordsInTable;
  dataSource;
  displayedColumns: string[] = ['title', 'date', 'action'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private _ToolbarService : ToolbarService,
    private _matdialog : MatDialog,
    private _commonservice : CommonService,
    private _matsnakebar : MatSnackBar,
    private _hosttypesservice : HostTypesService
  ) {
    this.setToolBarTitle();
   }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._hosttypesservice.hostTypesList);
    this.maxAvailableRecordsInTable =  this._hosttypesservice.maxAvailableRecordsInTable;
  }

  setToolBarTitle(){
    this._ToolbarService._ToolBarTitle.next('Host Types');
    this._ToolbarService._isBackBtnShow.next(false);
  }


  openHostTypeDialog(mode, hostData?){
    const dialog = this._matdialog.open(AddEditHostTypeComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '400px',
      autoFocus : false,
      data : {
        mode : mode,
        hostType : hostData
      }
    })
    dialog.afterClosed().subscribe((res) => {
      console.log("res", res);
      if(res?.data){
        if(res.mode == 'add'){
          const payload = {
            title : res.data.title
          }
          this._commonservice.addHostType(payload).then((result:any)=>{
            if(result.status == 200){
              this._matsnakebar.open(result.message, 'OKAY',{duration : 2000})
              this.updateListing()
            }
          })
        }else if(res.mode == 'edit'){
          const payload = {
            title : res.data.title,
            id : res.data.id
          }
          this._commonservice.updateHostType(payload).then((result:any)=>{
            if(result.status == 200){
              this._matsnakebar.open(result.message, 'OKAY',{duration : 2000})
              this.updateListing()
            }
          })
        }
      
      }
    })
  }


  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.updateListing();
  }

  updateListing(){
    this._hosttypesservice.getAllHostTypeList(this.filter).then((result:any) => {
      console.log("result", result);
      if(result.status == 200){
        this.dataSource.data = result.data.getAllHost;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else if(result.status == 400){
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  onDelete(item){  
      const dialog = this._matdialog.open(ConfirmationDialogComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        disableClose: false,
        data : {
          message : 'Are you sure you want to remove this Host Type?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Host Type?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
        }
      }) 
      dialog.afterClosed().subscribe((result)=> {
        if(result){
          // if(!item.isDeletable){
            this.callDeleteApi(item)
          // }else{
            // this.showWarningMessage(item)
          // }
        }
      }) 
  }

  showWarningMessage(item){
    const dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      disableClose: false,
      data : {
        message : `The host type you want to delete contains selected hosts. If you proceed with the deletion, these hosts will be unselected from this type.`,
        image : '/assets/animated_icon/alarm.gif',
        imageSize : '150px',
        focus : 'right',
        deleteItem : 'Warning',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        this.callDeleteApi(item)
      }
    })

  }
  
  callDeleteApi(item){
    this._commonservice.deleteHost({ "id": item.id }).then((result: any) => {
      if (result) {
        console.log("result", result);
        this._matsnakebar.open(result.message, 'OKAY',{duration: 2000})
        this.updateListing();
      }
    })
  }

  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.updateListing();
  }

  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.search = val;
    this.updateListing()
  }

}
