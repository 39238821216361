import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { GuestListService } from './guest-list.service';
import { AddGuestDialogComponent } from '@fuse/Dialog/add-guest-dialog/add-guest-dialog.component';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent implements OnInit {
  dialogRef
  maxAvailableRecordsInTable;
  displayedColumns: string[] = ['id', 'image', 'guestName', 'guestPosition'];
  dataSource;

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  constructor(
    private _ToolbarService : ToolbarService,
    public _guestListService: GuestListService,
    private _matDialog: MatDialog
  ) {
    this.dataSource = new MatTableDataSource(this._guestListService.allGuest);
    this.maxAvailableRecordsInTable = this._guestListService.maxAvailableRecordsInTable
    if (this._guestListService.permissions.delete)
    this.displayedColumns.push('action')

    this.setToolBarTitle();
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Guests');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
}


  ngOnInit() {
  }



  

  openAddDialog(id?): void {
    let selectedEventType = []
    if (id) {
      selectedEventType = this._guestListService.allGuest.filter((type) => {
        return type.id == id
      })
    }

    this.dialogRef = this._matDialog.open(AddGuestDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus : false,
      width:'350px',
      data: { guest: selectedEventType[0] }
    });

    this.dialogRef.afterClosed().subscribe(response => {
        if (response)
          this.updateTable()
      });
  }

  
  updateTable() {
    this._guestListService.getAllGuest().then(() => {
      this.dataSource.data = this._guestListService.allGuest
      this.dataSource.paginator = this.paginator;
    })
  }
  

  onDelete(id) {
    let dialog =  this._matDialog.open(ConfirmationDialogComponent, { 
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Guest?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Guest?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._guestListService.deleteGuest(id).then(() => {
          this.updateTable()
        })
      }
    });
  }
 
  // ----------------------- filters section -----------------------

  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.paginator.pageIndex = 0;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    this._guestListService.getAllGuest(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }else if (result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
      }
    }).catch(() => {
      this.dataSource.data = []
      this.maxAvailableRecordsInTable = 0
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }
}
