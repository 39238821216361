import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { TicketListService } from 'app/main/tickets/ticket-list/ticket-list.service';
import { VerifiersScanLocationService } from 'app/main/verifiers/verifiers-scan-location/verifiers-scan-location.service';
import { VerifiersListService } from 'app/main/verifiers/verifiers.service';
import { logWarnings } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-verifier-assigning-dialog',
  templateUrl: './verifier-assigning-dialog.component.html',
  styleUrls: ['./verifier-assigning-dialog.component.scss']
})
export class VerifierAssigningDialogComponent implements OnInit {
  verifiersList:[] = [];
  selectedValues: number[] = []; // Assuming IDs are of type number
  form:FormGroup;
  locationList:[] = [];
  allTicketTypes:any = [];
  selectedYears;
  unasignedEvents:[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<VerifierAssigningDialogComponent>,
    private _fb: FormBuilder,
    private _ticketListService: TicketListService,
    private _snackBar : MatSnackBar,
    private _progressBar : FuseProgressBarService,
    public _commonService: CommonService,
    private _verifiersListService :VerifiersListService,
    private verifiersScanLocationService : VerifiersScanLocationService
  ) { 
    
    this.createForm() 
    // get listing data.
    console.log("_data", this._data);

    if(this._data.by =='eventDetails'){
      this.getAllVerifiersList();
      this.form.get('eventId').setValue(String(this._data.eventId))
      this.getAllTicketTypes();
    }else{
      console.log("this is data", this._data.verifierData);
      const verifierId = this._data.verifierData.id
      this.unasignedEvents = this._data.unasignedEvents; 
      this.form.get('verifierId').setValidators(null);
      this.form.get('verifierId').setValue(String(this._data.verifierData?.id));
      this.form.get('verifierId').updateValueAndValidity();
    }

    this.getAllLocationsData();
  }

  ngOnInit(): void {
     
  }

  getAllLocationsData(){
    this.verifiersScanLocationService.getAllVerifiersScanList({pageSize:0, pageIndex:0}).then((res:any)=>{
      if(res.status == 200){
        this.locationList = res.data;
      }else{
        this.locationList = [];
      }
    })
  }

  createForm(){
    this.form = this._fb.group({
      eventId : ['', Validators.required],
      verifierId : ['', Validators.required],
      scanLocationIds : ['', Validators.required],
      ticketType: ['', Validators.required]
    })
  }

  selectAll(checkAll) {
    console.log("checkAll", checkAll);
  }
  
  // FOR FORM ARRAY LOOP IN UI
  get verifierLocation(): FormArray {
    return this.form.controls["verifierLocation"] as FormArray;
  }
 
  // onSelectEvent
  onSelectEvent(event:any){
    this.allTicketTypes = [];
    this.getAllTicketTypes(event.value);
  }
 

  removeRow(id,ids){ 
    if(!id){
      ids.forEach((id)=>{
        this.verifierLocation.controls.forEach((group, j)=>{
          if(group.value.verifierId == id){
            (<FormArray>this.form.get('verifierLocation')).removeAt(j);
          }
        })
      })
    }else{
      this.verifierLocation.controls.forEach((group, j)=>{
        if(group.value.verifierId == id){ 
          (<FormArray>this.form.get('verifierLocation')).removeAt(j);
        }
      })
    }
  }

  getAllVerifiersList(){
    const payload = {
      "eventId": this._data.eventId,
      "pageIndex": 0,
      "pageSize": 0,
      "forAssign": 1
    }
    this._verifiersListService.getAllVerifiers(payload).then((res:any)=>{
      if(res.status == 200){ 
        this.verifiersList = res.data.data;
        console.log("this.verifiersList", this.verifiersList);
        
      }else{
        this.verifiersList = [];
      }
    })
  }


  getSelectedName(locationType){
    if(this._data.by == 'eventDetails'){
      const selectedEle:any = this.verifiersList.filter((item:any) => item.id == locationType.value.verifierId);
      return selectedEle[0]?.name;
    }
    if(this._data.by == 'verifierDetails'){
      const selectedEle:any = this.unasignedEvents.filter((item:any) => item.id == locationType.value.eventId);
      return selectedEle[0]?.name;
    }
  }

  getAllTicketTypes(eventId?){
    this._progressBar.show();
    this._ticketListService.getAllTickets({pageIndex:0,pageSize:0, eventId: eventId ? eventId : this._data.eventId}).then((res)=>{
      if(res.status == 200){
        this.allTicketTypes = this.removeDuplicate(res.data.data);
        this._progressBar.hide();
        console.log("this.allTicketTypes", this.allTicketTypes);
      }else{
        this.allTicketTypes = [];
      }
    })
  }
  
  onSubmit(){
    const payload = {...this.form.value};
    if(payload['scanLocationIds']?.length){
      payload['scanLocationIds'] = payload.scanLocationIds.join(",")
    }
    console.log("submit hit", payload);
    this._commonService.assignEventToVarifier(payload).then((res:any)=>{
      if(res.status == 200){
        this._snackBar.open(res.message, "OKAY", { duration : 2000});
        this._matDialogRef.close();
      }else{
        this._snackBar.open(res.message, "OKAY", { duration : 2000});
      }
    })
  }

  removeDuplicate(arr){
    const uniqueIds = [];
    const unique = arr.filter(element => {
      const isDuplicate = uniqueIds.includes(element.ticketTypeId);
      if (!isDuplicate) {
        uniqueIds.push(element.ticketTypeId);
        return true;
      }
      return false;
    }); 
    return unique;
  }
  
}