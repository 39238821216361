import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { ImportContactDialogComponent } from '@fuse/Dialog/import-contact-dialog/import-contact-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { GroupsService } from '../groups.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
// import * as XLSX from 'xlsx';

@Component({
  selector: 'app-all-contacts',
  templateUrl: './all-contacts.component.html',
  styleUrls: ['./all-contacts.component.scss']
})
export class AllContactsComponent implements OnInit {

  displayedColumns: string[] = ['name', 'contact', 'email','action' ];
  maxAvailableRecordsInTable: any;
  // dataSource =  new MatTableDataSource(this._groupsService.allContactsDetails);
  dataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dialogRef
  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }
  // dataSource = this.groupsService.allContactsDetails
  // tableData = []
  // wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  @ViewChild('search', { static: true }) search;
  constructor(
    public _commonService: CommonService,
    public route: Router,
    private _ToolbarService : ToolbarService,
    private _groupsService: GroupsService,
    private _matDialog: MatDialog,
  ) {
    this.setToolBarTitle();

    // this.tableData = this.groupsService.allContactsDetails
   }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._groupsService.allContactsDetails);
    this.filter.pageIndex = this._groupsService.allContactsDetails.pageIndex;
    this.maxAvailableRecordsInTable = this._groupsService.maxAvailableRecordsInTable;
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('All Contacts');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  addContact(){
    this.route.navigate(["group/add-contacts"])
  }

  customBack(){
    this.route.navigate(["group"])
  }

  onDeleteContact(id){
    this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        disableClose: false,
        data : {
          message : 'Are you sure you want to delete this Contact?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Contact?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
        }
    }).afterClosed().subscribe((result:any) => {
      if (result) {
        this._groupsService.deleteContact(id).then((result:any) => {
          if(result.status == 200){
            this.filterData();
          }
        });
      }
    });
  }

  editContact(id){
    this.route.navigate(["group/edit-contacts/" + id ]);
  }

  onSearch(val) {
    this.filter.pageIndex = 1;
    this.filter.searchFilter = val;
    this.paginator.pageIndex = 1;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex || 1,
      "pageSize": this.filter.pageSize || 10,
      "search": this.filter.searchFilter
    }
    this._groupsService.getAllContacts(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.contactList;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else if (result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
      }
    })
  }

  clearSearch() {
    this.filter.searchFilter = '';
    this.search.nativeElement.focus();
    this.filterData();
  }

  onPageChange(event){
    this.filter.pageIndex = event.pageIndex + 1;
    this.filter.pageSize = event.pageSize;
    this.filterData();
  }

  openImportContectDialog(){
    this._commonService.getDynamicFieldList({}).then((result:any)=>{
      if(result.status === 200 || result.status === 400){
        console.log("result", result);
        this._matDialog.open(ImportContactDialogComponent, {
          panelClass: ['addGroup', 'commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width: '68%',
          minHeight: '230px',
          // hasBackdrop : false,
          autoFocus : false,
          data : {
            feilds : result?.data,
            isForOrder : false
          }
        }).afterClosed().subscribe((uploadStatus) => {
          if(uploadStatus){
            this.filterData();
          }
        })
      }
    })
    
  }

}