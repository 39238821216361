import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FeedbackService } from 'app/main/feedback/feedback.service';

@Component({
  selector: 'app-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss']
})
export class SendFeedbackComponent implements OnInit {
  form = this.sendForm.group({
    subject: ['', Validators.required],
    message: ['', Validators.required]
  });
  constructor(
    private sendForm: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<SendFeedbackComponent>,
    private _FeedbackService: FeedbackService

  ) { }

  ngOnInit(): void {
  }

  onSubmit(){
    const payload = {
      subjects: this.form.get('subject').value,
      message: this.form.get('message').value,
      eventId: 0
    }
    this._FeedbackService.sendFeedback(payload).then((result:any)=>{
      if(result.status == 200){
        this.matDialogRef.close();
      }else{
        this.matDialogRef.close();
      }
    })
  }

}
