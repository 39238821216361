import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations'; 
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  animations : fuseAnimations
})
export class ConfirmationDialogComponent implements OnInit {

  token: string|undefined;
  recapcha:FormControl =  new FormControl('', Validators.required)

  @ViewChild('form', {static: false}) form;
  // message: any = "Are you sure you want to delete this?";
  // confirmButtonText = "Yes";
  // cancelButtonText = "Cancel";
  // type = 'delete';
  // DeleteData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _commonService: CommonService,
    private _MatSnackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>) {
  // if(data){
    // this.message = data.message || this.message;
    // if (data.buttonText) {
    //   this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
    //   this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    // }
    // if(data.type){
    //   this.type = data.type
    // }
  // }
}


  ngOnInit() {
    this.token = undefined;
    this.recapcha.valueChanges.subscribe((res)=>{
      console.log("token", res);
      if(res){
          this.checkTokenValidation(res)
      }
    })
  }

  checkTokenValidation(token){
    const params = {
        secret : environment.recaptcha.secretKey,
        response : token
    }
    this._commonService.validateRecapchaToken(params).then((res:any)=>{
        if(!res?.data?.success){
          this.recapcha.reset();
          this._MatSnackBar.open('reCAPTCHA token expired or is invalid. please try again.', 'OKAY', {duration : 5000})
        }
    })
  }

  onConfirmClick(): void {
    if(this._data?.recapcha){
      if (this.recapcha?.invalid) {
        this.recapcha.markAsTouched();
        return;
      }else{
        this.dialogRef.close({delete: true});
      }
    }else{
      this.dialogRef.close({delete: true});
    }
   
  }

  
}