import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AssignEventDialogService } from './assign-event-dialog.service';

@Component({
  selector: 'app-assign-event-dialog',
  templateUrl: './assign-event-dialog.component.html',
  styleUrls: ['./assign-event-dialog.component.scss']
})
export class AssignEventDialogComponent implements OnInit {

  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AssignEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _assignEventDialogService: AssignEventDialogService
  ) {

    console.log("_data", _data);
    
  }

  form: UntypedFormGroup
  get eventTypeList() {
    return this.form.controls["eventTypeList"] as UntypedFormArray;
  }

  ngOnInit() {

    
    console.log("all data is here", this._data);
    

    this.form = this._fb.group({
      eventTypeList: this._fb.array([])
    })

    this._data.ticketType.forEach(item => {
      (this.form.controls["eventTypeList"] as UntypedFormArray).push(this.createFormRow())
    });
   

    // if edit then set values in form
    if (this._data.assignedTicketTypes) {
      this._data.assignedTicketTypes.forEach(item => {

        let index = this._data.ticketType.findIndex(ele => { return ele.ticketTypeId == item.ticketTypeId });
        console.log('ind', index);
        if (index != -1) {
          (this.form.controls["eventTypeList"] as UntypedFormArray).controls[index].get('commission').setValue(item.commission);
          (this.form.controls["eventTypeList"] as UntypedFormArray).controls[index].get('limit').setValue(item.limit);
        }else{
           console.log("hello there ");
           (this.form.controls["eventTypeList"] as UntypedFormArray).controls[index].get('commission').setValue(this._data.commission);
        }
      })
    }
    else if(this._data.commission)
    {
      
      // this.eventTypeList.controls.forEach((element:any)=>{
      //   (this.form.controls["eventTypeList"] as FormArray).controls[0].get('commission').setValue(this._data.commission?this._data.commission:'');
      //   element.value = this._data.commission ;
      // })

      for (let i = 0; i < this.eventTypeList.length; i++) {
        this.eventTypeList.controls.forEach((element:any)=>{
          (this.form.controls["eventTypeList"] as UntypedFormArray).controls[i].get('commission').setValue(this._data.commission?this._data.commission:'');
          element.value = this._data.commission ;
        })
      }

    }

  }

  onSubmit() {
    console.log('this._data.ticketType', this._data.ticketType)
    if (this._data.assignedTicketTypes) {
      //edit assigned event
      let ticketInfo = []
      this.form.value.eventTypeList.map((item, index) => {

        if(item.commission==''){
          item.commission=0
        }
        if(item.limit==''){
          item.limit=0
        }

          item.ticketTypeId = this._data.ticketType[index].ticketTypeId;
          // item.id = this._data.ticketType[index].id
          ticketInfo.push(item)
        })

      let payload = {
        distributorId: this._data.distributorId,
        eventId: this._data.eventId.toString(),
        ticketInfo: ticketInfo
      }
      console.log("payload in edit case", payload);
      
      this._assignEventDialogService.editAssignedEvent(payload).then(() => {
        this.matDialogRef.close(true)
      }).catch(() => {
        this.matDialogRef.close(false)
      })

    } else {
      //assign new event
      let ticketInfo = []
      this.form.value.eventTypeList.map((item, index) => {
        if (item.commission || item.limit) {
          // console.log("item.ticketType", item);
          // item.ticketTypeId = this._data.ticketType[index].id
          item.ticketTypeId = this._data.ticketType[index].ticketTypeId;
          ticketInfo.push(item)
        }
      })
      console.log("to finding tickettypeid ", ticketInfo);

      let payload = {
        distributorId: this._data.distributorId,
        eventId: this._data.eventId.toString(),
        ticketInfo: ticketInfo
      }

      console.log("payload in add case", payload);
      this._assignEventDialogService.assignEventToDistributor(payload).then(() => {
        this.matDialogRef.close(true)
      }).catch(() => {
        this.matDialogRef.close(false)
      })
    }
  }


  onUnAssign() {
    let payload = {
      distributorId: this._data.distributorId,
      eventId: this._data.eventId.toString(),
      isAssign: 0
    }

    if (this._data.assignedTicketTypes) {
      this._assignEventDialogService.editAssignedEvent(payload).then(() => {
        this.matDialogRef.close(true)
      }).catch(() => {
        this.matDialogRef.close(false)
      })
    }
  }

  createFormRow() {
    console.log("working here", this._data);
    
    return this._fb.group({
      commission: [''],
      limit: ['1']
    })
  }

}
