import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ListSubUserService } from './list-sub-user.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-list-sub-user',
  templateUrl: './list-sub-user.component.html',
  styleUrls: ['./list-sub-user.component.scss']
})
export class ListSubUserComponent implements OnInit {
  maxAvailableRecordsInTable
  dataSource;
  displayedColumns = ['name', 'mobile', 'role', 'gender'];

  @ViewChild(MatPaginator, { static: false })
  paginator: MatPaginator;
  @ViewChild('search', { static: false })
  search: ElementRef;

  constructor(
    public _listSubUserService: ListSubUserService,
    private _ToolbarService : ToolbarService,
  ) {
    this.setToolBarTitle();
    this.maxAvailableRecordsInTable = _listSubUserService.maxAvailableRecordsInTable
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this._listSubUserService.allSubUsers)
    this.setToolBarTitle();
  }


  
 setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Staff');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  // ----------------------- filters section -----------------------


  filter = {
      search: '',
      pageSize: 10,
      pageIndex: 1
  }

  onSearch(val) {
      this.search.nativeElement.focus()
      if (val) {
          this.filter.pageIndex = 1
          this.filter.search = val
          this.filterData();
      }
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.search
    }
    this._listSubUserService.getAllSubUsers(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }
      else {
        this.dataSource.data = []
      }
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  clearSearch() {
    this.filter.search = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

}
