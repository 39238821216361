import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { getCurrencySymbol } from '@angular/common';
import { SettingService } from './setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { LyDialog } from '@alyle/ui/dialog';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'user-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class UserSettingsComponent implements OnInit {
  public settingForm: UntypedFormGroup
  public files: NgxFileDropEntry[] = [];
  croppedThumbBase64: any = null;
  croppedBannerBase64: any = null;
  displayedSecretKey:string;
  secretKey: string = ''; // Replace this with your actual secret key
  displayedAccesskey:string;
  accesskey: string;

  constructor(
    private fb: UntypedFormBuilder,
    private _matDialog: MatDialog,
    public _settingService: SettingService,
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private _CommonService : CommonService,
    private _ToolbarService : ToolbarService,
    public _matSnackBar: MatSnackBar,

  ) { 
    this.setToolBarTitle();
    
  }

  ngOnInit() {
    console.log('--', this._settingService.setting)
    this.createForm(this._settingService.setting)

    // this.settingForm.get('email').setValue(localStorage.getItem('userEmail'));
  }
   
  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Settings');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  createForm(data) {
    console.log("data", data);
    if(data?.secretKey){
      this.secretKey = data?.secretKey;
      this.displayedSecretKey = '*****************' + this.secretKey.slice(-4);
    }
    if(data?.accessKey){
      this.accesskey = data?.accessKey;
      this.displayedAccesskey = '*****************' + this.accesskey.slice(-4);      
    }

    if(data.banner){
      this.croppedBannerBase64 = data.banner;
    } 
    if(data.logo){
      this.croppedThumbBase64 = data.logo;
    }
    this.settingForm = this.fb.group({
      country: [data.country ? data.country : ''],
      currency: [data.currency ? data.currency : ''],
      companyName: [data.company ? data.company : ''],
      tfa:[data.isAuthySetUp?data.isAuthySetUp:''],
      banner : [data.banner || ''],
      logo : [data.logo || ''],
      email : [localStorage.getItem('userEmail') || '']
    });
  }


  onRemoveBanner(){
    this.settingForm.get('banner').setValue('')
    this.croppedBannerBase64 = null;
  }
  
  onRemoveProfile(){
    this.settingForm.get('logo').setValue('')
    this.croppedThumbBase64 = null;
  }
  

  onSaveSetting() {
    this._settingService.editSetting(this.settingForm.value).then((result:any)=>{
      localStorage.setItem("company",result.data.company);
      localStorage.setItem("country",result.data.country);
      localStorage.setItem("currencyId", result.data.currency);
      localStorage.setItem("symbol",result.data.symbol);
    })
  }

  onSendVerification(){
    this._CommonService.sendVarificationRequest().then((res:any) => {
      if(res.status == 200){
        this._matSnackBar.open(res.message, 'OKAY', {duration : 6000})
        this._settingService.getSetting().then((response) => {
          if (response.status == 200) {
            this.createForm(response.data);
          }
        })
      }else{
        this._matSnackBar.open(res.message, 'OKAY', {duration : 2000})
      }
    })
  }
  
  openCropperForThumbnail(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 320,
              height: 320,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 420,
                  height: 420
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedThumbBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.uploadLogoOnPresignedURL(result.file, result.blobFile)
      }
    });
  }

  openCropperForBanner(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 1920,
              height: 1200,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 1920,
                  height: 1200
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedBannerBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.uploadBannerOnPresignedURL(result.file, result.blobFile)
      }
    });
  }

  uploadLogoOnPresignedURL(fileObj, blobFile){
    console.log("fileObj", fileObj);
    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName" : 'compLogo_',
      "folderName": 'admin/logo', // it is fix set by backend.
      'mimeType': fileObj?.type
    }
    this._CommonService.getPresignedUrlAPI(payload).then((result: any) => {
      if (result.status == 200) {
        this.settingForm.get('logo').setValue(result.data.imageUploadUrl);
        // payload for get Image upload status
        const payload = {
          'id': result.data.id,
          'status': 1 // fix by backend.
        }
        this._CommonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile);
        this._CommonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
          if (uploadStatusResponse.status == 200) {
              this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
          } else {
              this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

  uploadBannerOnPresignedURL(fileObj, blobFile){
    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName" : 'compBanner_',
      "folderName": 'admin/banner', // it is fix set by backend.
      'mimeType': fileObj?.type
    }

    this._CommonService.getPresignedUrlAPI(payload).then((result: any) => {
      if (result.status == 200) {
        this.settingForm.get('banner').setValue(result.data.imageUploadUrl);
        // payload for get Image upload status
        const payload = {
          'id': result.data.id,
          'status': 1 // fix by backend.
        }
        this._CommonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile);
        this._CommonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
          if (uploadStatusResponse.status == 200) {
              this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
          } else {
              this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

  onRegenerateKeys(){
    const dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      disableClose: false,
      data : {
          message : `Please note: If you revoke the keys, you will need to update them for your next API calls.`,
          image : 'assets/animated_icon/alarm.gif',
          focus : 'right',
          imageSize : '100px',
          deleteItem : 'warning!',
          confirmButtonText : 'Continue',
          cancelButtonText : 'Cancel'
      } 
    }) 

    dialog.afterClosed().subscribe((res)=>{
      if(res){
        console.log("res", res);
        this._CommonService.regenerateKeys().then((res:any)=>{
          if(res.status == 200){
            this._settingService.getSetting().then((response)=>{
              if (response.status == 200) {
                this.createForm(response.data);
              }
            })
            this._matSnackBar.open(res.message, "OKAY", {duration : 3000})
          }else{
            this._matSnackBar.open(res.message, "OKAY", {duration : 3000})
          }
        })
      }
    })

  }

}
