import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { AddUserService } from '../add-distributor.service';
import { TicketsSaleHistoryService } from './tickets-sale-history.service';


@Component({
  selector: 'app-tickets-sale-history',
  templateUrl: './tickets-sale-history.component.html',
  styleUrls: ['./tickets-sale-history.component.scss']
})

export class TicketsSaleHistoryComponent implements OnInit {

  maxAvailableRecordsInTable
  dataSource;
  dateRangeFilterVal
  displayedColumns = ['name', 'totalSeats', 'userName', 'amount', 'commission', 'ticketType', 'time', 'ticketNumber'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('search', { static: true }) search;
  evnetId: string;


  constructor(
    public ticketsSaleHistoryService: TicketsSaleHistoryService,
    public _commonService: CommonService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _addDistributorsService : AddUserService,
    public commonService : CommonService
  ) {
   console.log("Number(this._addDistributorsService.distributorId)", this._addDistributorsService.distributorId);
   
  }


  filter = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    search: '',
    pageSize: 10,
    pageIndex: 1,
    eventId: 52,
    distributorId: 47,
    typeFilter: 0
  }

  ngOnInit() {

    this._route.queryParams.subscribe(params => {
      console.log("params", params.eventId);
      this.evnetId = params.eventId;
    });

    
    this.fetchDataInTable();
    this.maxAvailableRecordsInTable = this.ticketsSaleHistoryService.maxAvailableRecordsInTable
    this.dataSource = new MatTableDataSource(this.ticketsSaleHistoryService.allTicketsSaleHistory);
  }

  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.fetchDataInTable()
  }
  clearFilter() {
    this.filter.dateRangeFilter.endDate = 0;
    this.filter.dateRangeFilter.startDate = 0;
    this.dateRangeFilterVal = ''
    this.filter.search = '',
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.typeFilter = 0;
    this.fetchDataInTable()
  }
  onSearch(value) {
    this.paginator.pageIndex = 0;
    this.filter.search = value;
    this.filter.pageIndex = 1;
    this.fetchDataInTable()
  }
 
  onDateFilterChanges(e) {
    if (e.endDate != null && e.startDate != null) {
      this.filter.dateRangeFilter.endDate = +e.endDate
      this.filter.dateRangeFilter.startDate = +e.startDate
      this.fetchDataInTable()
      this.paginator.pageIndex = 0;
    }
  }
  onTypeFilterSelect(event) {
    this.filter.typeFilter = event.value;
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.fetchDataInTable()
  }
  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.fetchDataInTable()
  }

  fetchDataInTable() {
    this._fuseProgressBarService.show();
    const payload = {
      search: this.filter.search,
      pageSize: this.filter.pageSize,
      pageIndex: this.filter.pageIndex,
      eventId: Number(this.evnetId),
      distributorId: Number(this._addDistributorsService.distributorId),
      startDate: this.filter.dateRangeFilter.startDate,
      endDate: this.filter.dateRangeFilter.endDate,
      type: this.filter.typeFilter
    }
    this.ticketsSaleHistoryService.getTicketsHistoryByEvents(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.ticketHistory;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
        this._fuseProgressBarService.hide();
      }else if(result.status == 400){  
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
        this._fuseProgressBarService.hide();
      }
      else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
        this._fuseProgressBarService.hide();
      }
    })
  }

  // goBack(){
  //   console.log("go back", Number(this._addDistributorsService.distributorId));
  //   this._router.navigate(['/distributors'])
  // }

}