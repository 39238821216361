import { Pipe, PipeTransform } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'app/main/pages/auth.service';
 
@Pipe({
  name: 'rolePermissions'
})
export class RolePermissionsPipe implements PipeTransform {

  constructor(
    private _cookieService: CookieService,
    private _authService: AuthService
  ) { }

  transform(value: any): any {


    return new Promise(async(resolve,reject)=>{
   
      if (!this._authService.userPermissions) {
        await this._authService.getPermissionsOnRefresh()
      }

      const permissions = this._authService.getPermissions()
      
      if (permissions) {

        let permittedPages = []

        value.map((page) => {

          let index = permissions.findIndex((item) => {
            return item.url == page.url
          })
          if (index >= 0)
            if (permissions[index].permission.view)
              permittedPages.push(page)
        })
        resolve( permittedPages);
      } else
        resolve( [])

 
      })




  }


}
