import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { OrganiserListService } from './organiser-list.service';
import { AddOrganiserDialogComponent } from '@fuse/Dialog/add-organiser-dialog/add-organiser-dialog.component';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';


@Component({
  selector: 'app-organiser-list',
  templateUrl: './organiser-list.component.html',
  styleUrls: ['./organiser-list.component.scss']
})
export class OrganiserListComponent implements OnInit {
  dialogRef
  maxAvailableRecordsInTable:any=0;
  dataSource
  displayedColumns:string[];
  constructor(
    public _organiserListService: OrganiserListService,
    private _ToolbarService : ToolbarService,
    private _matDialog: MatDialog
  ) {

    this.setToolBarTitle();

    this.maxAvailableRecordsInTable = this._organiserListService.maxAvailableRecordsInTable
    
    
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Organisers');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  ngOnInit() {
    this.displayedColumns = ['profileImage', 'name', 'website', 'contact'];
    this.dataSource = new MatTableDataSource(this._organiserListService.allOrganisers);
    if (this._organiserListService.permissions.delete){
      this.displayedColumns.push('action');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  openAddOrganiserDialog(id?): void {
    let selectedOrganiser = []

    if (id) {
      selectedOrganiser = this._organiserListService.allOrganisers.filter((org) => {
        return org.id == id
      })
    }

    this.dialogRef = this._matDialog.open(AddOrganiserDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '550px',
      data: { organiser: selectedOrganiser[0] }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (response)
         this.filterData()
      });
  }

  onDelete(id) {
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Organier?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Organier?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })

    dialog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._organiserListService.deleteOrganisers(id).then(() => {
          let lastDigitOfPage = this.maxAvailableRecordsInTable.toString().slice(-1);
          if (lastDigitOfPage == 1){
            if(this.filter.pageIndex!=1){
            this.filter.pageIndex = this.filter.pageIndex - 1;
            this.paginator.pageIndex = this.filter.pageIndex - 1;
            }
          }
          this.filterData()
        })
      }
    });
    
  }


  updateTable() {
    this._organiserListService.getAllOrganisers().then(() => {
      this.dataSource.data = this._organiserListService.allOrganisers
      this.dataSource.paginator = this.paginator;
    })
  }

  // ----------------------- filters section -----------------------

  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.paginator.pageIndex = 0;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    this._organiserListService.getAllOrganisers(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }else if (result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
      }
    }).catch(()=>{
      this.dataSource.data = []
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

}
