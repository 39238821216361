import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-edit-verifiers-location',
  templateUrl: './add-edit-verifiers-location.component.html',
  styleUrls: ['./add-edit-verifiers-location.component.scss']
})
export class AddEditVerifiersLocationComponent implements OnInit {

  form : FormGroup;
  mode:string;
  constructor(
    private _fb : FormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public _matDialogRef: MatDialogRef<AddEditVerifiersLocationComponent>,
  ) { 
    this.mode = this._data.mode;
    console.log("_data", this._data);
    if(this.mode == 'add'){
      this.form = this.createForm();
      this.addRow();
    }else if(this.mode == 'view'){
      this.form = this.createForm(this._data.createData);
      if(this._data.createData.locationNames.length){
        this._data.createData.locationNames.forEach((item) => this.addRow(item))
      }
      this.form.disable();
      this.form.updateValueAndValidity();
    }
    
  }


  ngOnInit(): void {
  }



  // main form
  createForm(data = null){
    return this._fb.group({
      locationType : [data ? data.locationType : '', Validators.required],
      locationNames : this._fb.array([])
    })
  }

  // FOR FORM ARRAY LOOP IN UI
  get locationNames(): UntypedFormArray {
    return this.form.controls["locationNames"] as UntypedFormArray;
  }

  public addRow(locations?): void {
    (<FormArray>this.form.get("locationNames")).push(this._fb.group({
      locationName : [locations?.locationName ? locations?.locationName : '', Validators.required]
    }))
  }

  public removeRow(i): void{
    (<FormArray>this.form.get('locationNames')).removeAt(i);
  }


  onSubmit(){
    const locationNames = []
    if(this.form.value?.locationNames?.length){
      this.form.value?.locationNames.forEach((item) => locationNames.push(item.locationName))
    }
    const payload = {
      locationType : this.form.value?.locationType,
      locationNames : this.form.value?.locationNames?.length ? locationNames : []
    }
    this._matDialogRef.close({data : payload, mode: this.mode})
  }

}
