import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-extend-ticket-limit-dialog',
  templateUrl: './extend-ticket-limit-dialog.component.html',
  styleUrls: ['./extend-ticket-limit-dialog.component.scss']
})
export class ExtendTicketLimitDialogComponent implements OnInit {
  ticketLimit:FormControl = new FormControl('', Validators.required);
  submited:boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<ExtendTicketLimitDialogComponent>,
    private _commonService : CommonService,
    private _matsnackbar : MatSnackBar
  ) {
  }

  ngOnInit(): void {
  }

  onSubmit(){
    this.submited = true;
    this.ticketLimit.disable();
    const payload = {
      eventId : this._data.ticketData.eventId,
      ticketTypeId : this._data.ticketData.id,
      slotId : this._data.ticketData.slotId,
      quantity : this.ticketLimit.value
    }
    this._commonService.ticketsCount(payload).then((res:any)=>{
      if(res.status == 200){
        this._matsnackbar.open(res.message, 'OKAY', {duration : 2000})
        this.dialogRef.close(true)
      }else{
        this._matsnackbar.open(res.message, 'OKAY', {duration : 2000})
      }
    })
  }
}
