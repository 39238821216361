import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-print-ticket',
  templateUrl: './print-ticket.component.html',
  styleUrls: ['./print-ticket.component.scss']
})
export class PrintTicketComponent implements OnInit {
  form: UntypedFormGroup
  constructor(
    private _fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.form = this._fb.group({
      ticketNumberFrom: ['',Validators.required],
      totalTickets: ['',Validators.required],
      event:['',Validators.required],
      ticketType:['',Validators.required]
    })
  }


}
