import { Component, OnInit, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@fuse/services/common.service';
import { AddEditEventService } from '../add-edit-event.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Enums } from '@fuse/utils/systemEnums';

@Component({
  selector: 'app-buyer-details-configuration',
  templateUrl: './buyer-details-configuration.component.html',
  styleUrls: ['./buyer-details-configuration.component.scss']
})
export class BuyerDetailsConfigurationComponent implements OnInit {

  MEMBER_BOOKING_INFO_TYPE = Enums.MEMBER_BOOKING_INFO_TYPE
  dataSource
  displayedColumns: string[] = ['selection', 'fieldName', 'isRequired'];
  customerDetails;
  directSave;
  editDirectSave;
  form : FormGroup
  atleastOnFieldRequired = true;
  isAllOptionDisabled : boolean = false;
  dynamicFields;
  customerdetailsKey: any;  
  public customerDetailsDefault = 1;

  constructor(
    private _httpClient: HttpClient,
    public _matDialogRef: MatDialogRef<BuyerDetailsConfigurationComponent>,
    private matSnackBar: MatSnackBar,
    private _commonService: CommonService,
    private _addEditEventService:AddEditEventService,
    private _fb : UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ){
    console.log("this is the data for set", );
    
    this.form = this._fb.group({
      customerInfoType : [_data?.custormerInfo?.type ? _data?.custormerInfo?.type : 2],
    })

    
  }

  ngOnInit() {
    if(this._data?.custormerInfo['data']?.length){
      this.dynamicFields = this._data.custormerInfo['data'];
      this.checkAllOptionalDisabled();
    }else{
      this.dynamicFields = this._addEditEventService.dynamicFields;
      this.checkAllOptionalDisabled();
    }
    this.dataSource = new MatTableDataSource(this.dynamicFields);
 

    if(this._data.isForView){
      this.dataSource?.data.forEach((item) => {
        item.isDisabled = true;
        item.checkMarkDisabled = true;
      });
      this.form.controls.customerInfoType.disable();
      console.log("this.datasource", this.dataSource);
    }else{ 
        this.form.controls.customerInfoType.valueChanges.subscribe((value) => {
          console.log("value", value);
          if(value)
            if(value == 3){
              this.onSelectAllOption();
            }else if(value == 2 || value == 1){
              this.onSelectOtherOption();
            }
        })

        this.dataSource?.data.forEach((item) => {
          if(item.isAdded == 0)
          item.isDisabled = true;
          item.checkMarkDisabled = false;
        });
    }
  }
 
  // used to apply business logics
  onCheckboxChange(val, index, name) {
    this.dataSource.data.map((field) => {
      if(field.title == name){
        field['isAdded'] = val ? 1 : 0;
        if(!field.isAdded){
          field['isRequired'] = 0;
          field['isDisabled'] = true;
        }else{
          field['isRequired'] = 0;
          field['isDisabled'] = false;
        }
      }
    })
  }

  onSubmit() { 
    this._matDialogRef.close({data:this.dataSource.data, type:this.form.controls.customerInfoType.value});
  }

  checkAllOptionalDisabled(){
    const index = this.dynamicFields.filter(item => item.isRequired == 1);
    if (index.length) {
      this.isAllOptionDisabled = true;
    }else{
      this.isAllOptionDisabled = false;
    }
  }

  onSelectAllOption(){
    this.dataSource.filteredData.map((item) => {
      item.isAdded = 0;
      item.isRequired = 0;
      item.checkMarkDisabled = true;
      item.isDisabled = true;
    })
  }

  /* 
    Atleast One Member & All Members
  */
  onSelectOtherOption(){
    this.dataSource.filteredData.map((item) => {
      item.isAdded = 1;
      item.isRequired = 1;
      item.checkMarkDisabled = false;
      item.isDisabled = false;
    })
  }

}
