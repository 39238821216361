import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReferralComponent } from './referral/referral.component';
import {RouterModule,Routes} from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { ReferralDetailsComponent } from './referral-details/referral-details.component';
import { ReferralService } from './referral/referral.service';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
const route:Routes = [
   {
     path:'',
     component:ReferralComponent,
     resolve  : {
      data: ReferralService
  }
   },
  //  {
  //    path:':ref_id',
  //    component:ReferralDetailsComponent
  //  }
 ]

@NgModule({
  declarations: [ReferralComponent, ReferralDetailsComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(route),
    MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatDialogModule,
        MatMenuModule,
        MatGridListModule
  ]
})
export class ReferralModule { }
