import { FuseNavigation } from '@fuse/types';
export const navigation: FuseNavigation[] = [
    {
        id: 'home',
        title: 'Home',
        type: 'item',
        forHoverIcon: 'home_forHoverIcon.svg',
        normalIcon: 'home_normalIcon.svg',
        url: '/home'
    },
    {
        id: 'dashboards',
        title: 'Dashboard',
        type: 'item',
        forHoverIcon: 'dashboard_forHoverIcon.svg',
        normalIcon: 'dashboard_normalIcon.svg',
        url: '/dashboard'
    },
    {
        id: 'events',
        title: 'Events',
        type: 'collapsable',
        forHoverIcon: 'event_forHoverIcon.svg',
        normalIcon: 'event_normalIcon.svg',
        url: '/events',
        children: [
            {
                id: 'create-event',
                title: 'Create An Event',
                type: 'item',
                exactMatch: true,
                url: '/events/add',
            },
            {
                id: 'all-event',
                title: 'All Events',
                type: 'item',
                exactMatch: true,
                url: '/events',
            }, 
            {
                id: 'meeting-Location',
                title: 'Meeting Locations',
                type: 'item',
                exactMatch: true,
                url: '/meetingLocation',
            },
            {
                id: 'reported-events',
                title: 'Reported Events',
                type: 'item',
                exactMatch: true,
                url: '/reported',
            },
            {
                id: 'type-of-events',
                title: 'Type Of Events',
                type: 'item',
                exactMatch: true,
                url: '/masters/event-type',
            },
            {
                id: 'categories',
                title: 'Categories',
                type: 'item',
                exactMatch: true,
                url: '/masters/event-category',
            },
            {
                id: 'resources',
                title: 'Additional Resources',
                type: 'item',
                exactMatch: true,
                url: '/resources',
            },
        ]
    },
    {
        id: 'create-order',
        title: 'All Bookings',
        type: 'collapsable', 
        forHoverIcon: 'bookings_forHoverIcon.svg',
        normalIcon: 'bookings_normalIcon.svg',
        url: '/orders',
        children : [
            {
                id: 'all-order',
                title: 'All Bookings',
                type: 'item',
                exactMatch: true,
                url: '/orders',
            },
            {
                id: 'create-order',
                title: 'Create Booking',
                type: 'item',
                exactMatch: true,
                url: '/orders/create-order',
            }, 
            {
                id: 'create-bulk-order',
                title: 'Create Bulk Booking',
                type: 'item',
                exactMatch: true,
                url: '/orders/bulk-order',
            },
            {
                id: 'cancel-orders',
                title: 'Cancelled Booking',
                type: 'item',
                exactMatch: true,
                url: '/orders/cancelled',
            },
        ]
    },
    {
        id: 'tickets',
        title: 'Tickets',
        type: 'collapsable',
        forHoverIcon: 'tickets_forHoverIcon.svg',
        normalIcon: 'tickets_normalIcon.svg',
        url: '/tickets',
        children: [
            {
                id: 'add-tickets',
                title: 'Create a Ticket',
                type: 'item',
                exactMatch: true,
                url: '/tickets/create',
            },
            {
                id: 'tickets-list',
                title: 'List Tickets',
                type: 'item',
                exactMatch: true,
                url: '/tickets',
            },
            {
                id: 'tickets-type',
                title: 'Ticket Types',
                type: 'item',
                exactMatch: true,
                url: '/masters/ticket-type',
            },
            {
                id : 'rq_bar',
                title: 'Import QR & Bar Code',
                type : 'item',
                exactMatch : true,
                url : '/masters/upload-tickets-codes'
            }
        ]
    },
    {
        id: 'distributors',
        title: 'Distribution',
        type: 'collapsable',
        forHoverIcon: 'distribution_forHoverIcon.svg',
        normalIcon: 'distribution_normalIcon.svg',
        url: '/distributors',
        children: [
            {
                id: 'create-distributors',
                title: 'Add an Agent',
                type: 'item',
                exactMatch: true,
                url: '/distributors/add',
            },
            {
                id: 'distributors-list',
                title: 'List Agents',
                type: 'item',
                exactMatch: true,
                url: '/distributors',
            },
            // {
            //     id : 'agent-sales',
            //     title: 'Agent Sales',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/distributors',
            // },
            // {
            //     id : 'agent-transactions',
            //     title: 'Agent Transactions',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/distributors',
            // },
        ]
    },
    {
        id: 'verifiers',
        title: 'Verifiers',
        type: 'collapsable',
        forHoverIcon: 'verifiers_forHoverIcon.svg',
        normalIcon: 'verifiers_normalIcon.svg',
        url: '/verifiers',
        children: [
            {
                id: 'add-verifiers',
                title: 'Add a Verifier',
                type: 'item',
                exactMatch: true,
                url: '/verifiers/add',
            },
            {
                id: 'verifiers-list',
                title: 'List Verifiers',
                type: 'item',
                exactMatch: true,
                url: '/verifiers',
            },
            {
                id : 'verifiers-scan-location',
                title: 'Verifiers Scan Location',
                type: 'item',
                exactMatch : true,
                url: '/verifiers/verifiers-scan-location',
            }
        ]
    }, 
    {
        id: 'invitations',
        title: 'Invitations',
        type: 'collapsable',
        forHoverIcon: 'invitations_forHoverIcon.svg',
        normalIcon: 'invitations_normalIcon.svg',
        url: '/Invitation',
        children: [
            {
                id: 'add-invitations',
                title: 'Send new Invite',
                type: 'item',
                exactMatch: true,
                url: '/Invitation/create-new-invitation',
            },
            {
                id: 'invitations-list',
                title: 'View History',
                type: 'item',
                exactMatch: true,
                url: '/Invitation',
            },
            // {
            //     id : 'list-scheduled',
            //     title: 'List Scheduled',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/Invitation',
            // },
            {
                id: 'contact-group',
                title: 'Contact Groups',
                type: 'item',
                exactMatch: true,
                url: '/group',
            },
            {
                id: 'list-contact',
                title: 'List Contact',
                type: 'item',
                exactMatch: true,
                url: '/group/all-contacts',
            },
        ]
    },
    {
        id : 'Certificate',
        title : 'Certificate',
        type : 'collapsable',
        forHoverIcon : 'certificate_forHoverIcon.svg',
        normalIcon : 'certificate.svg',
        url : '/certificate', 
        children : [
            {
                id : 'create-certificate',
                title : 'Create Certificate',
                type : 'item',
                exactMatch : true,
                url : '/certificate/add-new'
            },
            {
                id : 'certificate-templates',
                title : 'Certificate Templates',
                type : 'item',
                exactMatch : true,
                url : '/certificate/templates'
            },
            {
                id : 'generate-certificate',
                title : 'Generate Certificate',
                type : 'item',
                exactMatch : true,
                url : '/certificate/generate-certificate'
            },
            {
                id : 'Certificates',
                title : 'Certificates',
                type : 'item',
                exactMatch : true,
                url : '/certificate/certificates'
            }
        ]
    },
    // {
    //     id: 'notifications',
    //     title: 'Notification',
    //     type: 'collapsable',
    //     forHoverIcon: 'notification_forHoverIcon.svg',
    //     normalIcon: 'notification_normalIcon.svg',
    //     url: '/notification',
    //     children: [
    //         {
    //             id: 'add-notification',
    //             title: 'Send Notification',
    //             type: 'item',
    //             exactMatch: true,
    //             url: '/notification',
    //         },
    //         {
    //             id: 'sent-notification',
    //             title: 'Sent Notifications',
    //             type: 'item',
    //             exactMatch: true,
    //             url: '/notification/sent-list',
    //         },
    //         {
    //             id: 'list-notification',
    //             title: 'List Notifications',
    //             type: 'item',
    //             exactMatch: true,
    //             url: '/notification-list',
    //         },
    //         // {
    //         //     id : 'add-notification',
    //         //     title: 'Scheduled Records',
    //         //     type: 'item',
    //         //     exactMatch : true,
    //         //     url: '/notification',
    //         // },
    //     ]
    // },
    {
        id: 'venue',
        title: 'Venue',
        type: 'collapsable',
        forHoverIcon: 'venues_forHoverIcon.svg',
        normalIcon: 'venues_normalIcon.svg',
        url: '/masters/venue',
        children: [
            {
                id: 'add-venue',
                title: 'Add new Venue',
                type: 'item',
                exactMatch: true,
                url: '/masters/venue/add',
            },
            {
                id: 'all-venues',
                title: 'All Venues',
                type: 'item',
                exactMatch: true,
                url: '/masters/venue',
            },
        ]
    }, 
    {
        id: 'hosts',
        title: 'Hosts',
        subtitle: '',
        type: 'collapsable',
        forHoverIcon: 'host_forHoverIcon.svg',
        normalIcon: 'host_normalIcon.svg',
        url: '/speaker',
        children: [
            {
                id: 'add-hosts',
                title: 'Add a Host',
                type: 'item',
                exactMatch: true,
                url: '/speaker/add',
            }, 
            {
                id: 'list-hosts',
                title: 'List hosts',
                type: 'item',
                exactMatch: true,
                url: '/speaker',
            },
            {
                id: 'add-hosts-type',
                title: 'Host Types',
                type: 'item',
                exactMatch: true,
                url: '/speaker/host-types',
            },
            {
                id: 'add-hosts-group',
                title: 'Host Groups',
                type: 'item',
                exactMatch: true,
                url: '/speaker/host-group',
            },
        ]
    }, 
    {
        id: 'sponsor',
        title: 'Sponsors',
        type: 'collapsable',
        exactMatch: true,
        forHoverIcon: 'sponsor_forHoverIcon.svg',
        normalIcon: 'sponsor_normalIcon.svg',
        url: '/sponsors',
        children : [
            {
                id : 'sponsors',
                title : 'Sponsors',
                type : 'item',
                exactMatch : true,
                url : '/sponsors'
            },
            {
                id : 'sponsorsGroup',
                title : 'Sponsor Groups',
                type : 'item',
                exactMatch : true,
                url : '/sponsors/sponsor-groups'
            }
        ]
    }, 
    {
        id: 'pollsSurvey',
        title: 'Polls & Survey',
        type: 'collapsable',
        forHoverIcon: 'host_forHoverIcon.svg',
        normalIcon: 'host_normalIcon.svg',
        url: '/polls',
        children: [
            {
                id: 'polls',
                title: 'Polls',
                type: 'item',
                exactMatch: true,
                url: '/polls',
            },
            {
                id: 'survey',
                title: 'Survey',
                type: 'item',
                exactMatch: true,
                url: '/survey',
            },
        ]
    }, 
    {
        id: 'manage',
        title: 'Manage',
        // subtitle : '(Speakers/Performers)',
        type: 'collapsable',
        forHoverIcon: 'manage_forHoverIcon.svg',
        normalIcon: 'manage_normalIcon.svg',
        url: '/speaker',
        children: [
            {
                id: 'taxes',
                title: 'Taxes',
                type: 'item',
                exactMatch: true,
                url: '/taxes',
            },
            {
                id: 'faqs',
                title: 'FAQs',
                type: 'item',
                exactMatch: true,
                url: '/faq',
            },
            {
                id: 'guest',
                title: 'Guests',
                type: 'item',
                exactMatch: true,
                url: '/masters/guest'
            },
            {
                id: 'organiser',
                title: 'Organisers',
                type: 'item',
                exactMatch: true,
                url: '/masters/organiser'
            },
            // {
            //     id : 'terms-conditions',
            //     title: 'Terms & Conditions',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/faq',
            // },
            // {
            //     id : 'privacy-policy',
            //     title: 'Privacy policy',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/faq',
            // },
        ]
    }, 
    // {
    //     id: 'Webhooks',
    //     title: 'Webhooks',
    //     type: 'item',
    //     forHoverIcon: 'webhooks_forHoverIcon.svg',
    //     normalIcon: 'webhooks_normalIcon.svg',
    //     url: '/webhooks'
    // },

    {
        id : 'badge',
        title : 'label',
        type : 'collapsable',
        forHoverIcon : 'staffs_forHoverIcon.svg',
        normalIcon: 'staffs_normalIcon.svg',
        url: '/badge', 
        children: [
            {
                id: 'add-label',
                title: 'Create Label',
                type: 'item',
                exactMatch: true,
                url: '/badge/add',
            },
            {
                id: 'badge-templates',
                title: 'Label Templates',
                type: 'item',
                exactMatch: true,
                url: '/badge/badge-templates',
            },
            {
                id: 'generate-badge',
                title: 'Generate Label',
                type: 'item',
                exactMatch: true,
                url: '/badge/generate-badge',
            },
            {
                id: 'generate-Label',
                title: 'Labels',
                type: 'item',
                exactMatch: true,
                url: '/badge/generated-labels',
            },
        ]
    },
    
    // {
    //     id: 'staffs',
    //     title: 'Staffs',
    //     // subtitle : '(Speakers/Performers)',
    //     type: 'collapsable',
    //     forHoverIcon: 'staffs_forHoverIcon.svg',
    //     normalIcon: 'staffs_normalIcon.svg',
    //     url: '/subuser',
    //     children: [
    //         {
    //             id: 'add-a-staff',
    //             title: 'Add a Staff',
    //             type: 'item',
    //             exactMatch: true,
    //             url: '/subuser/add',
    //         },
    //         {
    //             id: 'list-staffs',
    //             title: 'List Staffs',
    //             type: 'item',
    //             exactMatch: true,
    //             url: '/subuser',
    //         },
    //         {
    //             id: 'roles-permission',
    //             title: 'Roles & Permission',
    //             type: 'item',
    //             exactMatch: true,
    //             url: '/roles',
    //         },
    //     ]
    // },
    {
        id: 'myaccount',
        title: 'My Account',
        // subtitle : '(Speakers/Performers)',
        type: 'collapsable',
        forHoverIcon: 'my_account_forHoverIcon.svg',
        normalIcon: 'my_account_normalIcon.svg',
        url: '/subuser',
        children: [
            {
                id: 'settings',
                title: 'Settings',
                type: 'item',
                exactMatch: true,
                url: '/settings',
            },
            // {
            //     id : 'plan-usage',
            //     title: 'Plan & Usage',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/settings',
            // },

            // {
            //     id : 'susbcription',
            //     title: 'Susbcription',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/smspack',
            // }, 

            // {
            //     id : 'payments',
            //     title: 'Payments',
            //     type: 'item',
            //     exactMatch : true,
            //     url: '/settings',
            // }, 
        ]
    }, 
]