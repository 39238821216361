import { Component, OnInit, Inject, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonService } from '@fuse/services/common.service';
import { AddEventCategoryService } from './add-event-category.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from "ngx-file-drop";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CropperComponent } from '../cropper/cropper.component';
import { LyDialog } from '@alyle/ui/dialog';
@Component({
    selector: 'app-add-dialog',
    templateUrl: './add-event-category-dialog.component.html',
    styleUrls: ['./add-event-category-dialog.component.scss']
})
export class AddEventCategoryDialogComponent implements OnInit {

    previewUrl;
    previewUrlBg;
    imageFile;
    imageFileBg;
    isImageValid = true;
    isImageValidBg = true;
    form: UntypedFormGroup;
    heading: string;
    @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef
    @ViewChild('fileUploadBg', { static: true }) fileUploadBg: ElementRef;
    croppedCateIconBase64: any = null;
    croppedCateBgBase64: any = null;

    constructor(
        private _fb: UntypedFormBuilder,
        public matDialogRef: MatDialogRef<AddEventCategoryDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: any,
        private _dialog: LyDialog,
        private _cd : ChangeDetectorRef,
        private _snackbar: MatSnackBar,
        private _commonService: CommonService,
        private _addEventCategoryService: AddEventCategoryService,
        private sanitizer: DomSanitizer,
        private _matDialog: MatDialog
    ) { }
    ngOnInit() {
        if (this._data.eventCategory) {
            this.createForm(this._data.eventCategory)
            this.heading = this._data.heading
        }
        else
            this.createForm()
    }

    createForm(data?) {
        this.form = this._fb.group({
            "title": ['', Validators.required],
            "description": [''],
            "icon": ['', Validators.required],
            "background": ['', Validators.required]
        })
        // this.previewUrl =this.form.value.icon
    }

    onSubmit() {
        if (this.form.valid) {
            const payload = {
                'title': this.form.value.title,
                'description': this.form.value.description,
                'icon': this.form.value.icon,
                'backgroundImage': this.form.value.background,
            }
            this._addEventCategoryService.addCategory(payload).then((result:any) => {
                if (result.status)
                    this._snackbar.open(result.message, 'OKAY', {duration: 2000})
                    this.matDialogRef.close(true)
            })
        }
    }


    // on upload Image presignedURL API
    onUploadImage(fileObj, type, blobFile) {

        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName": type == 'icon' ? 'category_' : 'backgroundImage_',
            "folderName": 'event/category', // it is fix set by backend.
            'mimeType': fileObj?.type
        }

        console.log("payload", payload);


        return new Promise((resolve, reject) => {
            this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
                if (result.status == 200) {
                    if (type == 'icon') {
                        this.form.get('icon').setValue(result.data.imageUploadUrl);
                    } else {
                        this.form.get('background').setValue(result.data.imageUploadUrl);
                    }

                    // payload for get Image upload status
                    const payload = {
                        'id': result.data.id,
                        'status': 1 // fix by backend.
                    }
                    this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
                    this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                        if (uploadStatusResponse.status == 200) {
                            console.log("uploadStatusResponse", uploadStatusResponse);
                            this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            resolve(result.data);
                        } else {
                            this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            reject();
                        }
                    })
                }
            })
        })

    }


    removeImage() {
        this.fileUpload.nativeElement.value = ''
        this.previewUrl = ''
        this.form.get('icon').setValue('')
    }

    readUrlIcon(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log('image type is ', file)
            if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/svg+xml' || file.type == 'image/vnd.microsoft.icon') {
                this.isImageValid = true;
            } else {
                this.isImageValid = false;
            }
            if (this.isImageValid) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (_event) => {
                    this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString())
                    console.log(this.previewUrl)
                }
                this.imageFile = file
                this.form.get('icon').setValue(file)
            }
        }
        console.log("form status", this.form);
    }


    readUrlBackground(event: any) {
        if (event.target.files.length > 0) {
            const file = event.target.files[0];
            console.log('image type is ', file.type)
            if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/svg+xml' || file.type == 'image/vnd.microsoft.icon') {
                this.isImageValidBg = true;
            } else {
                this.isImageValidBg = false;
            }
            if (this.isImageValidBg) {
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = (_event) => {
                    this.previewUrlBg = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString())
                    console.log(this.previewUrlBg)
                }
                this.imageFileBg = file
                this.form.get('background').setValue(file)
            }
        }
        console.log("form status", this.form);
    }


    //file drop
    // public dropped(files: NgxFileDropEntry[], dropperFor: "icon" | "bg") {
    //     // this.files = files;
    //     for (const droppedFile of files) {
    //         // Is it a file?
    //         if (droppedFile.fileEntry.isFile) {
    //             const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
    //             console.log("fileEntry", fileEntry);
    //             fileEntry.file((file: File) => {
    //                 // Here you can access the real file
    //                 if (dropperFor == "icon")
    //                     this.openCropperForIcon(file);
    //                 else if (dropperFor == "bg")
    //                     this.openCropperForBg(file);
    //             });
    //         } else {
    //             // It was a directory (empty directories are added, otherwise only files)
    //             const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    //         }
    //     }
    // }

    openCropperForIcon(event) {
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 250,
                    height: 250,
                    round : false,
                    type: 'image/png',
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 320,
                        height: 320
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height : 520,
            disableClose: true
          }).afterClosed.subscribe((result:any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event); 
                this.croppedCateIconBase64 = result.file.dataURL;
                this._cd.markForCheck();
                this.onUploadImage(result.file, 'icon', result.blobFile)
            }
          });
    }

    openCropperForBg(event) {
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 250,
                    height: 250,
                    round : false,
                    type: 'image/png',
                    keepAspectRatio: true,
                    responsiveArea: true,
                    output: {
                        width: 320,
                        height: 320
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height : 520,
            disableClose: true
          }).afterClosed.subscribe((result:any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event); 
                this.croppedCateBgBase64 = result.file.dataURL;
                this._cd.markForCheck();
                this.onUploadImage(result.file, 'bg', result.blobFile)
            }
          });
    }

    /* 
        to remove icon and bg image
    */
    onRemoveThumb(type) {
        if (type == 'icon') {
            this.croppedCateIconBase64 = null;
            this.form.get('icon').setValue('');
        }
        else {
            this.croppedCateBgBase64 = null;
            this.form.get('background').setValue('');
        }
    }

}
