import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { Enums } from '@fuse/utils/systemEnums';
import { FileSystemFileEntry, NgxFileDropEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import * as XLSX from "xlsx";
type AOA = any[][];

// interface's
interface steptype {
    id: Number,
    title: String,
    warring : String,
    des: String,
    link: Boolean,
    status: Boolean,
}

@Component({
    selector: 'app-import-contact-dialog',
    templateUrl: './import-contact-dialog.component.html',
    styleUrls: ['./import-contact-dialog.component.scss']
})

export class ImportContactDialogComponent implements OnInit {
    stepData: steptype[] = [
        {
            id: 1,
            title: 'Upload your file',
            des: 'Select a file containing your contacts to import.',
            warring : '',
            link: true,
            status: true,
        },
        {
            id: 2,
            title: 'Mapping data',
            des: ``,
            warring : '',
            link: true,
            status: false,
        }
    ]

    form: UntypedFormGroup;
    data: AOA = [];
    wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };
    isSheetValid: boolean;
    mappingTableData = [];
    dataToUpload = [];
    optionForm : UntypedFormGroup;
    selectedValue: Number | undefined = 1;
    constructor(
        public _matDialogRef: MatDialogRef<ImportContactDialogComponent>,
        private _fb: UntypedFormBuilder,
        private _snackbar: MatSnackBar,
        private _CommonService: CommonService,
        private _cd: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public _data,
    ) {
        // CREATE FORM TO UPLOAD FILE
        this.createForm();

        if(_data?.for == 'isForOrder'){
            // console.log("this._data.feilds", this._data.feilds);
            let fields = []
            this._data.feilds.forEach((field, i) => {
                if(field.isRequired)
                fields.push(field.title);
            })
            this.stepData[this.stepData.length - 1].des = `Please choose the necessary options from the dropdown fields to match with your Excel columns. The essential fields include <span class="text-rose-500"> ${fields.join(', ').toUpperCase()}</span>.`
        }else if(this._data.for == 'ticketCode'){
            // console.log("this._data", this._data);
            let fields = []
            this._data.feilds.forEach((field, i) => {
                if(field.isRequired)
                fields.push(field.title);
            })
            this.stepData[this.stepData.length - 1].des = `Please choose the necessary options from the dropdown fields to match with your Excel columns. The essential fields include <span class="text-rose-500"> ${fields.join(', ').toUpperCase()}</span>.`
        }else{
            this.stepData[this.stepData.length - 1].des = `Please choose the necessary options from the dropdown fields to match with your Excel columns. The essential fields include <span class="text-rose-500"> 'TITLE,' 'FIRST NAME,' 'LAST NAME,' 'MOBILE NUMBER,' and 'EMAIL'</span>.`
        }
    }

    createForm() {
        return this.form = this._fb.group({
            file: ['', Validators.required]
        })
    }

    ngOnInit(): void {
    }

    // DROPPED FILE FUNCTION
    dropped(files) {
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    this.onFileChange(file, 'dropped')
                });
            }
        }
    }

    // download Sample File
    downloadSampleFile(){
        if(this._data?.for == 'isForOrder'){
            window.open('../../../assets/sample-file/example-members.xlsx');
        }else if(this._data?.for == 'ticketCode'){
            window.open('../../../assets/sample-file/file-format.xlsx');
        }
        else{
            window.open('../../../assets/sample-file/file-format.xlsx');
        }
    }

    // ON CALL FILE SELECT
    onFileChange(evt, method?) {
        //on file selection
        const reader: FileReader = new FileReader();
        let fileObj: any;
        if (method == 'dropped') {
            fileObj = evt;
        } else {
            /* wire up file reader */
            const target: DataTransfer = <DataTransfer>evt.target;
            if (target.files.length > 1) {
                // MULTIPLE FILE NOT ALLOWED
                this._snackbar.open("Cannot use multiple files", "OKAY");
                this.form.get("file").setValue(target.files);
            }
            fileObj = target.files[0]
        }

        // ON REAL FILE
        reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];

            /* save data */
            this.data = <AOA>XLSX.utils.sheet_to_json(ws, { header: 1 });
            this.data = this.data.filter((element: any) => element.length); 
              
            let maxDataLength = this.data.length;
            let maxItemLength = this.data[0].length;
            let data =this.data;
            for (let index = 0; index < maxDataLength; index++) {
                if(data[index].length){
                    for (let inx = 0; inx < maxItemLength; inx++) {

                        if((data[index][inx]) == ''){
                            data[index][inx] = "";
                        }

                        if((data[index][inx]) == undefined){
                            data[index][inx] = "";
                        }
                        
                        if((data[index][inx-1]) == undefined){
                            data[index][inx-1] = "";
                        }
                        
                    }
                }
            }

            this.data.forEach((item) => console.log("item after condition", item))

            if (this.data?.length) {
                // console.log("this is you data", this.data);
                if(this._data.for =='ticketCode'){
                    if(this._data.matchIndex !== (this.data?.length - 1)){
                        if(this._data?.importType == '1'){
                            this._snackbar.open(`The count in your sheet is ${this.data?.length - 1}, which does not match the seat count of the selected ticket type, which is ${this._data.matchIndex}. Please add ${this._data.matchIndex} entries to the sheet first and then upload it again.`, 'OKAY', {duration: 5000})
                        }
                        else{
                            this._snackbar.open(`The count in your sheet does not match the order count for the selected ticket type, which is ${this.data?.length - 1}. Please add ${this._data.matchIndex} entries to the sheet first, and then upload it again.`, 'OKAY', {duration: 5000})
                        }
                        this.sheetInvalid();
                    }
                }else if(this._data.for == 'isForOrder'){
                    if(this._data.maxIndex < (this.data?.length - 1)){
                        this._snackbar.open(`Your seat has ${this.data?.length - 1} members, but there are only ${this._data.maxIndex} available tickets. Please keep the number of members in the seat equal to or less than the Available Tickets.`, 'OKAY', {duration: 5000})
                        this.sheetInvalid();
                    }
                }


                /* add validation for SrNo */
                let srChecker = [];
                let titleChecker = [];
                let firstNameChecker = [];
                let lastNameChecker = [];
                let phoneNumberChecker = [];
                let emailChecker = [];


                // CHECK EXCEL FILE VALIDATION
                // for (let i = 0; i < this.data.length; i++) {
                //     // for Serial Number.
                //     if (this.data[i][0]) { // according to pre-fix format "serial number" column is "A"
                //         srChecker.push(this.data[i][0]);
                //     } else {
                //         this._snackbar.open('All "Serial Number" are not available in the sheet.', "OKAY", { duration: 4000 });
                //         this.sheetInvalid();
                //     }

                //     // for title 
                //     if (this.data[i][1]) { // according to pre-fix format "title" column is "B"
                //         titleChecker.push(this.data[i][1]);
                //     } else {
                //         this._snackbar.open('All "Title" are not available in the sheet.', "OKAY", { duration: 4000 })
                //         this.sheetInvalid();
                //     }

                //     // for First Name
                //     if (this.data[i][2]) { // according to pre-fix format "First Name" column is "C"
                //         firstNameChecker.push(this.data[i][2]);
                //     } else {
                //         this._snackbar.open('All "First Name" are not available in the sheet.', "OKAY", { duration: 4000 })
                //         this.sheetInvalid();
                //     }

                //     // Last Name
                //     if (this.data[i][3]) { // according to pre-fix format "Last Name" column is "D"
                //         lastNameChecker.push(this.data[i][3]);
                //     } else {
                //         this._snackbar.open('All "Last Name" are not available in the sheet.', "OKAY", { duration: 4000 })
                //         this.sheetInvalid();
                //     }


                //     // Phone Number
                //     if (this.data[i][4]) { // according to pre-fix format "Phone Number" column is "E"
                //         phoneNumberChecker.push(this.data[i][4]);
                //     } else {
                //         this._snackbar.open('All "Phone Number" are not available in the sheet.', "OKAY", { duration: 4000 });
                //         this.sheetInvalid();
                //     }


                //     // Email
                //     if (this.data[i][5]) { // according to pre-fix format "Email" column is "F"
                //         emailChecker.push(this.data[i][5]);
                //     } else {
                //         this._snackbar.open('All "Email" are not available in the sheet.', "OKAY", { duration: 4000 });
                //         this.sheetInvalid();
                //     }
                // }

                if (this.data.length == srChecker.length && this.data.length == titleChecker.length && this.data.length == firstNameChecker.length && this.data.length == lastNameChecker.length && this.data.length == phoneNumberChecker.length && this.data.length == emailChecker.length) {
                    // noting to do.
                    // THIS IS PETTERN FOR EMAIL FOTMATE VALIDATION
                    let pattern: any = /^(?!.*@.*@.*$)(?!.*@.*--.*\..*$)(?!.*@.*-\..*$)(?!.*@.*-$)((.*)?@.+(\..{1,11})?)$/;

                    // FUNCTION FOR CHECK VALIDATION 
                    function checkEmailValidation(email) {
                        return pattern.test(email);
                    }

                    if(emailChecker.length){
                        emailChecker.splice(0, 1);
                        // CALLING EMAIL VALIDATION FUNCION
                        if (emailChecker.every(checkEmailValidation)) {
                            this.form.get('file').setValue(e.target.files[0]);
                            this.sheetValid();
                        } else {
                            // PREVENT TO PROCEED
                            this._snackbar.open(`All "Email" are not valid in the sheet.`, "OKAY", { duration: 4000 })
                            this.sheetInvalid();
                        }
                    }
                } else {
                    // IF SERIAL NUMBER IS NOT VALIDATE.
                    // this._snackbar.open('All serial number are not available in the sheet', "OKAY", { duration: 4000 })
                    // this.form.get('file').setValue('');
                }
            } else {
                this.data = [];
                this._snackbar.open("Sorry, no data was found on the sheet. Please make another selection.", "OKAY");
                this.form.get("file").setValue("");
                this.sheetInvalid();
            }
        };
        reader.readAsBinaryString(fileObj);
    }

    // ON CALL ON CONFIRM ACTION
    // PROCEED TO STEPS 2
    onConfirmFile() {
        this.stepData[0].status = false;
        this.stepData[1].status = true;
        console.log("this.data====>>", this.data);
        this.setDataIntoMappingTabel(this.data);
        const requiredOptionsLength = this.mappingTableData[0].options.filter((field) => field.isRequired).length;
        if(requiredOptionsLength > this.data[0].length){
            this.stepData[1].warring = 'Your sheet is missing some required columns. Please adjust your data to match the required fields and try again.';
        }
    }

    handleUniqueIds(data){
        const uIdData = data.filter((item) =>item.option ? item.option?.id == 1 : null);
        if(uIdData.length){
            let uid = 0;
            uIdData[0].data.forEach((item) => {
                if(item != uid){
                    uid = item;
                }else{
                    this._snackbar.open('Duplicate Unique ID found in your sheet. Please review the sheet data and re-upload it.', 'OKAY', {duration : 3000})
                    this.resetStateData();
                }
            })
        }
    }

    resetStateData(){
        this.stepData[0].status = true;
        this.stepData[1].status = false;
        this.stepData[1].warring = '';
        this.mappingTableData = [];
        this.data = undefined;
        this.sheetInvalid();
    }

    /* 
      MAIN FUNCTION FOR SET DATA FOR COLUMNS MAPPING
    */
      setDataIntoMappingTabel(data) {
        this.optionForm = this._fb.group({})
        data.forEach((element, i) => {
            if (i === 0) {
                element.forEach((item) => {
                    const dataObject: any = {
                        colName   : item,
                        options   : this.createOption(),
                        colId     : undefined,
                        data      : [],
                        selected  : 0,
                        option    : null,
                    }
                    this.mappingTableData.push(dataObject);
                    this.optionForm.addControl(dataObject['colName'], new UntypedFormControl(''));
                })
            }
            if (i !== 0) {
                console.log("this is element", element);
                element.forEach((item, i) => {
                    this.mappingTableData[i].data.push(item == "" ? "" : item);
                })
            }
            console.log("this.mappingTableData",this.mappingTableData);
        });

        // setValue for option selected 
        this.getSeletedValue();
    }

    getSeletedValue(): void{
        for (const key in this.optionForm.controls) {
            this.mappingTableData.forEach((field, i) => {
                if(String(field?.colName).replace(" ", "").toLowerCase() == String(key).replace(" ", "").toLowerCase()){
                    // console.log("field", field);
                    field.options.forEach((optionItem) => {
                        // console.log("optionItem", optionItem);
                        if(String(optionItem.createField).replace(" ", "").toLowerCase() == String(key).replace(" ", "").toLowerCase()){
                            this.optionForm.get(key).setValue(optionItem.id);
                            field.colId = optionItem.id
                            field.option = optionItem;
                            field.selected = optionItem.id;
                        }
                    })

                    // if some field are rquired than check all col. 
                    if(this._data?.for == 'isForOrder'){ 
                        this.checkRequiredFieldEmpty(field)
                    }
                }
            })
        }
    }

    // FUNCTION  FOR SET OPTION IN MAPPING DATA OPTION. 
    createOption() {
        // ALL OPTION CREATION
        let option: any = [
            {
                id: 0,
                name: 'Do Not Import'
            },
        ]
        // SET API FEILDS DATA INTO OPTIONS
        this._data?.feilds.forEach((item, index) => {
            // console.log("item", item);
            item['isDisable'] = false;
            option.push(item)
        })
        return option
    }

    // CALL ON SELECTION CHANGE  
    onChangeColField(event, i, options) {
        let optionIsSelected = false;
        this.mappingTableData.forEach((element) => {
            if (element?.colId) {
                if (element.colId === Number(event.value)) {
                    optionIsSelected = true;
                }
            }
        })
        if (!optionIsSelected) {
            this.mappingTableData[i].colId = Number(event.value);
            this.mappingTableData[i].options.forEach((item) => {
                if (item.id == event.value) {
                    this.mappingTableData[i].option = item;
                    if(this._data?.for == 'isForOrder'){
                        this.checkRequiredFieldEmpty(this.mappingTableData[i])
                    }
                }
            })
        }else{
            this.mappingTableData[i].colId = null;
            this.mappingTableData[i].option = null;
            this.mappingTableData[i].selected = null;
            this._cd.detectChanges();
            this._snackbar.open(`"${options.filter((item) => item.id === event.value)[0].createField.toUpperCase()}" is already selected in other columns. Please select a different option.`, "OKAY", {duration: 3000});
            this.optionForm['controls'][this.mappingTableData[i].colName].setValue('');
        }

        // hanle unique id.
        // if duplicate uid availble.
        if(this._data?.for == 'isForOrder'){
            this.handleUniqueIds(this.mappingTableData);
        }

    }

    // if field is required then check all the col. in the sheet should filled. 
    checkRequiredFieldEmpty(field){
        console.log("this is the field", field);
        if(field?.selected && field?.option?.isRequired && field?.data?.length){
            if(!field.data.every(item => item)){
                const message = this._snackbar.open(`The ${field.option.name} is required, and there is some missing data in the '${field.colName}' column of your sheet. Please fill in all the data for this column.`, 'OKAY')
                message.afterDismissed().subscribe((res) => {
                    console.log("res", res.dismissedByAction);
                    if(res.dismissedByAction){
                        field['option'] = null;
                        field['colId'] = null;
                        field['selected'] = null;
                        this._cd.detectChanges();
                        this.optionForm['controls'][field.colName].setValue('');
                    }
                })
            }
        }
    }

    // IF SHEET INVALID
    sheetInvalid() {
        this.isSheetValid = false;
        this.data = [];
    }

    // IF SHEET VALID
    sheetValid() {
        this.isSheetValid = true;
    }

   // CALL ON FINAL IMPORT 
   onImportContact() {
        const newArr = [];
        this.mappingTableData.forEach((el, index) => {
            if (el.colId) {
                if (index === 0) {
                    el.data.forEach((ele) => {
                        const obj = {};
                        obj[el.option.createField] = ele;
                        newArr.push(obj)
                    })
                } else {
                    el.data.forEach((ele, i) => {
                        const obj = {};
                        obj[el.option.createField] = ele;
                        newArr[i] = { ...obj, ...newArr[i] }
                    })
                }
            }
        });
        // console.log("this is new newArr", newArr);        
        // CALLING API AND SUBMIT DATAS
        this.uploadContactsDetails(newArr)
    }


    /* 
        HANDLE VALIDATION FOR COLUMNS 
        HANDLE ALL COLUMNS ARE SELECTED.
        HANDLE ALL REQUIRED COLUMNS ARE SELECTED.
    */
    formValidator() {
        // console.log("==>", this.mappingTableData);
        // console.log("sheet data", this._data);
        // console.log("this.mappingTableData.filter((el) => (el?.colId && el.option?.isRequired)).length", this.mappingTableData.filter((el) => (el?.colId && el.option?.isRequired)).length);
        // console.log("this._data.feilds.filter((item) => item.isRequired).length", this._data.feilds.filter((item) => item.isRequired).length);
         
        return this.mappingTableData.filter((el) => (el?.colId && el.option?.isRequired)).length === this._data.feilds.filter((item) => item.isRequired).length && !this.mappingTableData.filter((item) => item.selected === null).length && this.mappingTableData[0].options.filter((field) => field.isRequired).length <= this.data[0].length;
    }

    // SUBMIT ALL THE FINAL DATA INTO API.
    uploadContactsDetails(data) {
        if(this._data?.for == 'isForOrder'){
            this._matDialogRef.close(data);
        }
        else if(this._data?.for == 'ticketCode'){
            this._matDialogRef.close(data);
        }
        else{
            const payload = {
                "contactList": data,
            }
            this._data?.groupId ? payload['groupId'] = this._data?.groupId : undefined;
            
            this._CommonService.onUploadBuilkUser(payload).then((result: any) => {
                if (result.status == 200) {
                    this._snackbar.open(result.message, 'OK', { duration: 2000 })
                    this._matDialogRef.close(true);
                } else {
                    this._snackbar.open(result.message, 'OK', { duration: 2000 })
                }
            })
        }
    }

}