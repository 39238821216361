import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqListComponent } from './faq-list/faq-list.component';
import { AddEditFaqComponent } from './add-edit-faq/add-edit-faq.component';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { FaqService } from './faq.service';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes: Routes = [
  {
    path: '', component: FaqListComponent,
    resolve: {data : FaqService}
  },
  {
    path: 'add', component : AddEditFaqComponent
  },
  {
    path: 'edit/:id', component : AddEditFaqComponent,
    resolve: {data : FaqService}
  }
]

@NgModule({
  declarations: [FaqListComponent, AddEditFaqComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule.forChild(routes),
  ]
})
export class FaqModule { }
