import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateBadgeDesignComponent } from './create-badge-design/create-badge-design.component';
import { RouterModule, Routes } from '@angular/router';
import { LyButtonModule } from '@alyle/ui/button';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyIconModule } from '@alyle/ui/icon';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { CertificateModule } from '../certificate/certificate.module';
import { BadgeTemplatesComponent } from './badge-templates/badge-templates.component';
import { BadgeTemplatesService } from './badge-templates/badge-templates.service';
import { GenerateBadgeComponent } from './generate-badge/generate-badge.component';
import { GenerateBadgeService } from './generate-badge/generate-badge.service';
import { GeneratedLabelsComponent } from './generated-labels/generated-labels.component';
import { GeneratedLabelsService } from './generated-labels/generated-labels.service';
import { UpdateBadgeService } from './create-badge-design/update-badge.service';

const routes: Routes = [
  {
    path     : 'add',
    component: CreateBadgeDesignComponent
  },
  {
    path     : 'edit/:id',
    component: CreateBadgeDesignComponent,
    resolve : {
      data : UpdateBadgeService
    }
  },
  {
    path     : 'badge-templates',
    component: BadgeTemplatesComponent,
    resolve : {
      data : BadgeTemplatesService
    }
  },
  {
    path     : 'generate-badge',
    component : GenerateBadgeComponent,
    resolve : {
      data : GenerateBadgeService
    }
  },
  {
    path     : 'generated-labels',
    component : GeneratedLabelsComponent,
    resolve : {
      data : GeneratedLabelsService
    }
  }
]

@NgModule({
  declarations: [
    CreateBadgeDesignComponent,
    BadgeTemplatesComponent,
    GenerateBadgeComponent,
    GeneratedLabelsComponent
  ],
  imports: [
    CommonModule,
    MatSliderModule,
    MatCardModule,
    MatToolbarModule,
    CertificateModule,
    MatPaginatorModule,
    MatButtonModule,
    MatTooltipModule,
    LyImageCropperModule,
    MatTableModule,
    ColorPickerModule,
    MatSelectModule,
    DragDropModule,
    LySliderModule,
    LyButtonModule,
    LyIconModule,
    MatMenuModule,
    LyDialogModule,
    MatProgressBarModule,
    NgxShimmerLoadingModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    MatRippleModule,
    MatSnackBarModule,
    MatRadioModule,
    MatToolbarModule,
    MatDialogModule,
    NgxFileDropModule,
    MatCheckboxModule,
    MatChipsModule,
    FusePipesModule,
    RouterModule.forChild(routes),
  ]
})
export class BadgeDesignModule { }
