import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SurveyService } from './survey.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SurveyQuestionComponent } from '@fuse/Dialog/survey-question/survey-question.component';
import { PublishPollComponent } from '@fuse/Dialog/publish-poll/publish-poll.component';
import { Enums } from '@fuse/utils/systemEnums';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {
  surveyId: any;
  pageType: any;
  form: FormGroup<any>;
  surveyQuestions: any = [];
  enums = Enums
  surveyDetail: any;
  interval: NodeJS.Timer;

  surveyStatus = {
    draft : false,
    upcomming : false,
    ongoing : false,
    completed : false
  }

  constructor(private _activateRoute: ActivatedRoute,
    private _surveyService: SurveyService,
    private _toolbarService: ToolbarService,
    private fb: FormBuilder,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _router: Router) {

    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    
    this._activateRoute.params.subscribe((result: any) => {
      // console.log('this.paramId', result);
      if (result.type) {
        this.surveyId = result.id;
        this.pageType = result.type;
      }
    });

    if (this.pageType === 'detail') {
      this.surveyDetail = this._surveyService.surveyDetail;
      this.form = this.createFrom(this.surveyDetail);
      // this.surveyDetail.isPublishedAlready = new Date(this.surveyDetail?.startDate).getTime() - new Date().getTime() < 0;
      this.updateStatus();
      // console.log("this.surveyDetail.isPublishedAlready ===>", this.surveyDetail)
      this.surveyDetail.qna.forEach(el => {
        el.options.forEach(element => {
          const r = Math.floor(Math.random() * 156 + 100); // Red component (100-255)
          const g = Math.floor(Math.random() * 156 + 100); // Green component (100-255)
          const b = Math.floor(Math.random() * 156 + 100); // Blue component (100-255)

          const color = `rgb(${r},${g},${b})`;

          element.color = color;
        });
      });
      this.interval = setInterval(() => { this.getSurveyEndTime() }, 1000)

      // this.setToolBarTitle();
      this._toolbarService._ToolBarTitle.next("View a survey");
      this._toolbarService._isBackBtnShow.next(true);

    } else if (this.pageType === 'add') {
      // this.surveyDetail = {}
      this.form = this.createFrom()
      // this.setToolBarTitle();
      this._toolbarService._ToolBarTitle.next("Add a survey");
      this._toolbarService._isBackBtnShow.next(true);
    } else {
      this.surveyDetail = this._surveyService.surveyDetail
      this.form = this.createFrom(this.surveyDetail)
      this.surveyQuestions = this._surveyService.surveyDetail.qna.map((el) => ({
        type: el.qtype, options: el.options.map((option) => ({ ...option, option: option.sOption })), question: el.question,
        questionType: this.enums.SURVEY_QUESTION_TYPE.find((ele) => ele.id === el.qtype).value
      }))
      // this.setToolBarTitle();
      this._toolbarService._ToolBarTitle.next("Edit a survey");
      this._toolbarService._isBackBtnShow.next(true);
    }

  }

  ngOnInit(): void {

  }

  public saveToDraft(): void {
    console.log("saveToDraft ==>", this.form);
    if (this.form.valid) {
      const payload = { ...this.form.value, qna: this.surveyQuestions };
      payload.status = 0;
      payload.eventId = payload.eventId;
      payload.surveySchedule = '0';
      payload.qna = payload.qna.map((el) => ({ question: el.question, type: el.type, options: el.options.map((el) => el.option) }));
      // payload.qna.forEach(el => {
      //   if (el.type == 2) {
      //     el.options = ["Great", "Good", "Average", "Bad", "Terrible"];
      //   } else if (el.type === 3) {
      //     el.options = ["1", "2", "3", "4", "5"];
      //   } else {

      //   }
      // });
      console.log(payload)

      if (this.pageType === 'add') {
        this.addSurvey(payload);
      } else {
        payload.id = this.surveyId
        console.log("edit case poll payload final ==>", payload)
        this.editSurvey(payload);
      }
    } else {
      this.form.markAllAsTouched()
    }

  }

  public publish(): void {
    console.log("publish ==>", this.form)
    console.log(this.form.get('eventId').value)
    if (this.form.valid) {

      this._dialog
        .open(PublishPollComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width: '600px',
          data: {
            for :  'survey',
            eventEndDate: this._surveyService.eventList.find((el) => this.form.get('eventId').value === el.id).eventEndDate
          }
        }).afterClosed().subscribe((result: any) => {
          if (result) {
            const payload = { ...this.form.value, qna: this.surveyQuestions, ...result };
            // const payload = { ...result, ...this.form.value }
            console.log("payload after dialog close ===>", payload)

            if (payload.publishType === '1') {
              payload.startDate = new Date().getTime();
            } else if (payload.publishType === '3') {
              payload.startDate = this._surveyService.eventList.find((el) => this.form.get('eventId').value === el.id).eventEndDate;
            } else {
              payload.startDate = new Date(payload.startDate).getTime();
            }

            payload.surveySchedule = payload?.publishType == '1' ? '1': '2';
            payload.eventId = payload.eventId;
            payload.qna = payload.qna.map((el) => ({ question: el.question, type: el.type, options: el.options.map((el) => el.option) }))
            // payload.qna.forEach(el => {
            //   if (el.type === '2') {
            //     el.options = ["Great", "Good", "Average", "Bad", "Terrible"];
            //   } else if (el.type === '3') {
            //     el.options = ["1", "2", "3", "4", "5"];
            //   } else {
            //   }
            // });
            payload.endDate = this.convertToTimestamp(payload.days, payload.hours, payload.minutes) + payload.startDate;
            payload.status = 1;
            delete payload.publishType;
            delete payload.days;
            delete payload.hours;
            delete payload.minutes;
            console.log("payload final ===>", payload);


            if (this.pageType === 'add') {
              this.addSurvey(payload);
            } else {
              payload.id = this.surveyId
              console.log("edit case poll payload final publish ==>", payload)
              this.editSurvey(payload);
            }
          }
        })
    } else {
      // this.form.markAsDirty()
      this.form.markAllAsTouched()
    }
  }

  convertToTimestamp(days, hours, minutes) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // 1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds
    const millisecondsPerHour = 60 * 60 * 1000; // 1 hour = 60 minutes * 60 seconds * 1000 milliseconds
    const millisecondsPerMinute = 60 * 1000; // 1 minute = 60 seconds * 1000 milliseconds

    const totalMilliseconds =
      days * millisecondsPerDay +
      hours * millisecondsPerHour +
      minutes * millisecondsPerMinute;

    const timestamp = totalMilliseconds;
    return timestamp;
  }

  public addSurvey(payload): void {
    this._surveyService.addSurvey(payload).then((result: any) => {
      this._snackBar.open(result.message, 'Okay', { duration: 2000 })
      if (result.status === 200) {
        this._router.navigate(['/survey/detail/' + result.data.id])
      } else {
      }
    })
  }

  public editSurvey(payload): void {
    this._surveyService.editSurvey(payload).then((result: any) => {
      this._snackBar.open(result.message, 'Okay', { duration: 2000 })
      if (result.status === 200) {
        this._router.navigate(['/survey'])
      } else {
      }
    })
  }

  refresh(){
    this._surveyService.getSurvey().then((res:any)=>{
      if(res.status == 200){
        this.surveyDetail = res.data;
        this.updateStatus();
        this.surveyDetail.qna.forEach(el => {
          el.options.forEach(element => {
            const r = Math.floor(Math.random() * 156 + 100); // Red component (100-255)
            const g = Math.floor(Math.random() * 156 + 100); // Green component (100-255)
            const b = Math.floor(Math.random() * 156 + 100); // Blue component (100-255)
  
            const color = `rgb(${r},${g},${b})`;
  
            element.color = color;
          });
        });
        this.interval = setInterval(() => { this.getSurveyEndTime() }, 1000)
      }else{
        this._snackBar.open(res.message, 'OKAY', {duration : 2000}) 
      }
    })
  }
  
  updateStatus(){
    // check poll is draft or publish
      if(this.surveyDetail.status){
        this.surveyStatus.draft = false;
      }else{
        this.surveyStatus.draft = true;
        this.surveyStatus.completed = false;
        this.surveyStatus.ongoing = false;
        this.surveyStatus.upcomming = false;
      }
      
      // make status if poll publish
      if(!this.surveyStatus.draft){
        console.log("get gap beetween start date", new Date(this.surveyDetail?.startDate).getTime() - new Date().getTime() < 0);
        console.log("get gap beetween end date", new Date(this.surveyDetail?.endDate).getTime() - new Date().getTime() < 0);

        // upcomming
        if(!(new Date(this.surveyDetail?.startDate).getTime() - new Date().getTime() < 0)){
          this.surveyStatus.completed = false;
          this.surveyStatus.ongoing = false;
          this.surveyStatus.upcomming = true;
        }

        // ongoing
        if((new Date(this.surveyDetail?.startDate).getTime() - new Date().getTime() < 0) && !(new Date(this.surveyDetail?.endDate).getTime() - new Date().getTime() < 0)){
          this.surveyStatus.completed = false;
          this.surveyStatus.ongoing = true;
          this.surveyStatus.upcomming = false;
        }
        
        // completed
        if((new Date(this.surveyDetail?.endDate).getTime() - new Date().getTime() < 0)){
          this.surveyStatus.completed = true;
          this.surveyStatus.ongoing = false;
          this.surveyStatus.upcomming = false;
        } 
      }else{
        this.surveyStatus.completed = false;
        this.surveyStatus.ongoing = false;
        this.surveyStatus.upcomming = false;
      }

      console.log("poll status", this.surveyStatus);
  }

  private createFrom(survey?): FormGroup {
    return this.fb.group({
      eventId: [survey?.eventId ?? '', [Validators.required]],
      subject : [survey?.subject ?? '', Validators.required],
      description: [survey?.description ?? '', [Validators.required]],
      isUserVerified: [survey?.isUserVerified ?? ''],
    })

  }

  public deleteQuestion(item, i) {
    this.surveyQuestions.splice(i, 1);
  }

  public questionDialog(question?, index?): void {
    console.log("publish ==>", this.form)
    console.log(this.form.get('eventId').value)
    if (this.form.valid) {

      this._dialog
        .open(SurveyQuestionComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width: '600px',
          data: {
            ...(question ? question : {})
            // eventEndDate: this._pollService.eventList.find((el) => this.form.get('eventId').value === el.id).eventEndDate
          }
        }).afterClosed().subscribe((result: any) => {
          if (result) {
            const payload = { ...result, questionType: this.enums.SURVEY_QUESTION_TYPE.find((el) => el.id === result.type).value }
            console.log("index ==+.", index)
            if (!index)
              this.surveyQuestions.push(payload);
            else {
              this.surveyQuestions[index] = payload;
            }
            console.log("after question add popup afterclosed ===>", this.surveyQuestions)
          }
        })
    } else {
      // this.form.markAsDirty()
      this.form.markAllAsTouched()
    }
  }


  public getSurveyEndTime(): void {
    // Create Date objects

    const startDate = (new Date(this.surveyDetail.startDate).getTime() > new Date().getTime()) ? new Date(this.surveyDetail.startDate).getTime() : new Date().getTime();
    const targetDate = new Date(this.surveyDetail.endDate).getTime();
    // Calculate the time difference in milliseconds
    const timeDifference = targetDate - startDate;

    // Calculate days, hours, minutes, and seconds
    this.surveyDetail.days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    this.surveyDetail.hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    this.surveyDetail.minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    this.surveyDetail.seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
  }


  public surveyEnd(): void {
    this._surveyService.surveyEnd().then((result: any) => {
      this._snackBar.open(result.message, 'Okay', { duration: 2000 });
      if (result.status === 200) {
        this._router.navigate(['/survey']);
      } else {

      }
    })
  }

}
