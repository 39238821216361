import { Component, OnInit } from '@angular/core';
import { SurveyListService } from './survey-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.scss']
})
export class SurveyListComponent implements OnInit {


  dataSource;
  maxAvailableRecordsInTable = 10;
  displayedColumns = ['surveyName', 'eventName', 'responses', 'publish', 'close', 'actions'];
  confirmDialogRef: any;
  pageIndex: any = 1;

  constructor(private _surveyListService: SurveyListService,
    private _toolbarService: ToolbarService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar) {
    console.log("this._pollsListService.tableData.pollsList ===>", this._surveyListService.tableData);
    const tableData = this._surveyListService.tableData.surveyList;
    this.dataSource = new MatTableDataSource(tableData);
    this.maxAvailableRecordsInTable = this._surveyListService.tableData.totalRecords;
    this.setToolBarTitle()
  }

  ngOnInit(): void {

  }

  public onPageChange(event): void {
    this.pageIndex = event.pageIndex + 1;
    this.fetchDataInTable(event.pageIndex + 1)
  }


  public deletePoll(id: number): void {
    this.confirmDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      // disableClose: false,
      // data : {
      //   message : 'Are you sure you want to delete?'
      // }
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to delete?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : '',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    });  
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._surveyListService.deleteSurvey(id).then((result: any) => {
          if (result.status === 200) {
            this.fetchDataInTable(this.pageIndex)
          }
          this._snackBar.open(result.message, 'Okay', { duration: 2000 })
        })
      }
    });
  }

  private setToolBarTitle(): void {
    // for title
    this._toolbarService._ToolBarTitle.next('Survey');
    // for btn
    this._toolbarService._isBackBtnShow.next(false);
  }

  public fetchDataInTable(pageIndex): void {
    this._surveyListService.getSurveysList(pageIndex, 10, '').then((result: any) => {
      if (result.status === 200) {
        this.dataSource.data = result.data.surveyList;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }
    })
  }

}
