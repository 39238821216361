import { Component, OnInit } from '@angular/core';
import { PollsListService } from './polls-list.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-polls-list',
  templateUrl: './polls-list.component.html',
  styleUrls: ['./polls-list.component.scss']
})
export class PollsListComponent implements OnInit {
  dataSource;
  maxAvailableRecordsInTable = 10;
  confirmDialogRef: MatDialogRef<ConfirmationDialogComponent>;

  displayedColumns = ['pollName', 'eventName', 'responses', 'publish', 'close', 'actions'];
  pageIndex: any = 1;
  constructor(private _pollsListService: PollsListService, private _ToolbarService: ToolbarService, private _dialog: MatDialog, private _snackBar: MatSnackBar) {
    console.log("this._pollsListService.tableData.pollsList ===>", this._pollsListService.tableData)
    const tableData = this._pollsListService.tableData.pollList
    this.dataSource = new MatTableDataSource(tableData);
    this.maxAvailableRecordsInTable = this._pollsListService.tableData.totalRecords;
    this.setToolBarTitle()
  }

  ngOnInit(): void {
  }

  setToolBarTitle() {
    // for title
    this._ToolbarService._ToolBarTitle.next('Polls');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  public onPageChange(event): void {
    this.pageIndex = event.pageIndex + 1;
    this.fetchDataInTable(event.pageIndex + 1)
  }


  public deletePoll(id: number): void {
    this.confirmDialogRef = this._dialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this poll?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Poll?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    }); 
    
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._pollsListService.deletePoll(id).then((result: any) => {
          if (result.status === 200) {
            this.fetchDataInTable(this.pageIndex)
          }
          this._snackBar.open(result.message, 'Okay', { duration: 2000 })
        })
      }
    });
  }

  public fetchDataInTable(pageIndex): void {
    this._pollsListService.getPollsList(pageIndex, 10, '').then((result: any) => {
      if (result.status === 200) {
        this.dataSource.data = result.data.pollList;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }
    })
  }
}
