import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";
import { AddEditEventService } from "../../edit-event/add-edit-event.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { SnippetIframeCodeComponent } from "@fuse/Dialog/snippet-iframe-code/snippet-iframe-code.component";
import { CancelEventDialogComponent } from "@fuse/Dialog/cancel-event-dialog/cancel-event-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { VerifierListDialogComponent } from "../../edit-event/verifier-list-dialog/verifier-list-dialog.component";
import { ConfirmationDialogComponent } from "@fuse/Dialog/confirmation-dialog/confirmation-dialog.component";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { EventListService } from "../../events-list/event-list.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Enums, sortByRank, sortByTimestamp } from "@fuse/utils/systemEnums";
import { CommonService } from "@fuse/services/common.service";
import { fuseAnimations } from "@fuse/animations";
import { TimezoneService } from "@fuse/services/timezone.service";
import { publish } from "rxjs/operators";
import moment = require("moment");
import { CommonMessageSubmitComponent } from "@fuse/Dialog/common-message-submit/common-message-submit.component";
import { VerifierAssigningDialogComponent } from "@fuse/Dialog/verifier-assigning-dialog/verifier-assigning-dialog.component";
import { AddEditEventMeetingLocationComponent } from "@fuse/Dialog/add-edit-event-meeting-location/add-edit-event-meeting-location.component";
import { BuyerDetailsConfigurationComponent } from "../../edit-event/buyer-details-configuration/buyer-details-configuration.component";

@Component({
    selector: "app-view-event",
    templateUrl: "./view-event.component.html",
    styleUrls: [
        "./view-event.component.scss",
        "../../../../../../node_modules/quill/dist/quill.core.css",
        "../../../../../../node_modules/quill/dist/quill.bubble.css",
        "../../../../../../node_modules/quill/dist/quill.snow.css",
    ],
    encapsulation: ViewEncapsulation.None,
    animations : fuseAnimations
})
export class ViewEventComponent implements OnInit {
    enum = Enums;
    sorting:Function = sortByTimestamp;
    sortingByRank:Function = sortByRank;
    eventData;
    todayDate = new Date();

    EVENT_SCHEDULE = Enums.EVENT_SCHEDULE;
    EVENT_RECURRING_DETAIL = Enums.EVENT_RECURRING_DETAIL;
    eventType: void;
    performerSchedule:[] = [];
    ticketData:[] = [];
    venue;
    currenttimeZone: any;

    constructor(
        public _ToolbarService: ToolbarService,
        private _router: Router,
        private _matSnackBar: MatSnackBar,
        private sanitizer: DomSanitizer,
        private _matDialog: MatDialog,
        private _eventListService: EventListService,
        public _addEditEventService: AddEditEventService, 
        private _commonService : CommonService,
        private _cd : ChangeDetectorRef,
        private _fuseProgressBarService: FuseProgressBarService,
        private _timezoneService : TimezoneService
    ) {
        // Example usage:
        const timestamp = 1698234308322; // Replace with your timestamp
        const timeZoneOffset = 3; // Replace with your desired offset (+04:00)
        this.setToolBarTitle();
        const convertedTime = this.convertTimestampToTimeZone(timestamp, timeZoneOffset);
    }

    ngOnInit(): void {
        this.eventData = this._addEditEventService.eventData;
        // handle performer
        if(this.eventData?.performerSchedule?.length){
            this.performerSchedule = this.eventData?.performerSchedule;
            this.performerSchedule = this.sorting(this.performerSchedule);
        }else{
            this.performerSchedule = [];
        }

        console.log("ticketData", this._addEditEventService.ticketData);
        // this. = this._addEditEventService.ticketData;
        console.log("this.performerSchedule --- >", this.performerSchedule);
    }

    onRedirectCreateTicket(){
        this._router.navigate(["/tickets/create"], { queryParams: { eventId: this.eventData?.id, to : 'view' } });
    }

    checkActivesLength(){
        let isActivesAvailabel : boolean = false;
        this.performerSchedule?.some((item:any) => {
            if(item.activitesList?.length)
            isActivesAvailabel = true;
        })
        return isActivesAvailabel;
    }

    publishEvent(){
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            width : '450px',
            data : {
                message : `You are moments away from publishing your event. To ensure that you're a genuine user, please complete the reCAPTCHA below.`,
                image : 'assets/animated_icon/publish_event.gif',
                focus : 'right',
                recapcha : true,
                imageSize : '130px',
                deleteItem : `Publish Event`,
                confirmButtonText : 'Publish',
                cancelButtonText : ''
            }
        })

        dialog.afterClosed().subscribe((res)=>{
        if(res?.delete){
            this._commonService.onPublishEvent({eventId : this.eventData.id}).then((res:any) => {
                if(res.status == 200){
                    this.updateViewEventData();
                }
                this._matSnackBar.open(res.message, 'OKAY', {duration : 3000});
            })
        }})
    }

    unPublishEvent(){
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            width : '450px',
            data : {
                message : `Are you sure you want to unpublish this event? Please note that once unpublished, this event will no longer be visible on the app or website.`,
                image : '',
                focus : 'right',
                deleteItem : `"${this.eventData.name}" event unpublish?`,
                confirmButtonText : 'Unpublish',
                cancelButtonText : 'Cancel'
            }
        })
        dialog.afterClosed().subscribe((res)=>{
            if(res?.delete){
                this._commonService.onUnPublishEvent({eventId : this.eventData.id}).then((res:any)=>{
                    if(res.status == 200){
                        this.updateViewEventData();
                    }
                    this._matSnackBar.open(res.message, 'OKAY', {duration : 3000});
                })
            }
        })
    }

    convertTimestampToTimeZone(timestamp, timeZoneOffset) {
        // Timestamp in milliseconds
        const timestampInMillis = timestamp;
    
        // Offset in minutes
        const offsetInMinutes = timeZoneOffset * 60;
    
        // Create a new Date object
        const date = new Date(timestampInMillis);
    
        // Get the UTC time
        const utcTime = date.getTime() + (date.getTimezoneOffset() * 60000);
    
        // Apply the desired time zone offset
        const newTime = new Date(utcTime + (offsetInMinutes * 60000));
    
        return newTime;
    }
    
    showDateForm(startDate, endDate){
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        // Create a Date object from the timestamp (assuming the timestamp is in milliseconds)
        const startNewDate = new Date(startDate);
        const endNewDate = new Date(endDate);
        // Get the month abbreviation and year
        const startMonth = months[startNewDate.getMonth()];
        const endMonth = months[endNewDate.getMonth()];
        // Get the day of the month
        const endDay = endNewDate.getDate();
        const startDay = startNewDate.getDate();
        const endYear = endNewDate.getFullYear();
        const startYear = startNewDate.getFullYear();
        // Format the date string as "Day Month Day, Year" (e.g., "Monday Nov 29, 2023")
        const formattedDate = `${startMonth} ${startDay} To ${startMonth != endMonth ? endMonth : ''} ${endDay}, ${startYear}`;
        return formattedDate;
    }

    updateViewEventData(){
        this._addEditEventService.getEvent(this.eventData.id).then((res:any)=>{
            if(res.status == 200){
                this.eventData = this._addEditEventService.eventData;
                this.performerSchedule = this.eventData?.performerSchedule;
                this.performerSchedule = this.sorting(this.performerSchedule);
                this._cd.detectChanges();
            }
        })
    }

    renderPostWithMentions(content: string) : SafeHtml{
        const sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(content);
        return sanitizedContent;
    }

    setToolBarTitle() {
        // this._ToolbarService._ToolBarTitle.next("View Event");
        // allow back btn in this component
        // this._ToolbarService._isBackBtnShow.next(true);
    }

    onEditEvent() {
        console.log("edit",this.eventData.id);
        this._router.navigate(["events/edit/" + this.eventData.id]);
    }

    openIframeDialog() {
        this._matDialog.open(SnippetIframeCodeComponent, {
            panelClass: ["commonDialogWrapper"],
            backdropClass: "dialogBackdrop",
            autoFocus: false,
            width: "450px",
            data: {
                id: this.eventData.id,
            },
        });
    }

    onCancelEvent() {
        const dialog = this._matDialog.open(CancelEventDialogComponent, {
                panelClass: ["commonDialogWrapper"],
                backdropClass: "dialogBackdrop",
                autoFocus: false,
            })
        dialog.afterClosed().subscribe((closeResponse) => {
            if (closeResponse) {
                const payload = {
                    id: this._addEditEventService.eventData?.id,
                    reason: closeResponse?.reason,
                };
                this._addEditEventService.onCancelEvent(payload).then((result: any) => {
                    if (result.status == 200) {
                        this._router.navigate(["/events"]);
                    } else {
                        this._matSnackBar.open(result.message, "OKAY", {
                            duration: 2000,
                        });
                    }
                });
            }
        });
    }

    openFieldsConfigDialog() {
        const dialog = this._matDialog.open(
            BuyerDetailsConfigurationComponent,
            {
                panelClass: ["commonDialogWrapper"],
                backdropClass: "dialogBackdrop",
                width: "450px",
                autoFocus: false,
                disableClose: false,
                data: {
                    isForView: true,
                    custormerInfo: this.eventData.customerFieldList,
                    customerInfoType: this.eventData.customerInfoType,
                },
            }
        );
    }

    openInTab(link){
        window.open(link, '_black')
    }

    onAssignDist() {
        this._router.navigate(["/events/distributor/"+ this.eventData.id]);
    }

    showListOfUnassignedVerifier() {
        const dialog = this._matDialog.open(VerifierAssigningDialogComponent, {
            panelClass: ["commonDialogWrapper"],
            backdropClass: "dialogBackdrop",
            width: "450px",
            autoFocus : false,
            data: {
                by : 'eventDetails',
                mode : 'add',
                eventId : this.eventData.id
            },
        });
    }

    openDialog() {
        const dialog = this._matDialog.open(VerifierListDialogComponent, {
            panelClass: ["commonDialogWrapper", "verifierAssignWrapper"],
            backdropClass: "dialogBackdrop",
            width: "500px",
            autoFocus : false,
            data: {
                eventId: this.eventData.id,
            },
        });
    }

    deleteEvent() {
        const dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : `Are you sure you want to delete this Event?`,
                image : '/assets/ui/delete.png',
                focus : 'right',
                deleteItem : 'Delete Event?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        }) 
        dialog.afterClosed().subscribe((row: any) => {
        if (row) {
            this._fuseProgressBarService.show();
            this._eventListService.deleteEvent(this.eventData.id).then((response: any) => {
                    this._matSnackBar.open(response.message, "OK", {
                        verticalPosition: "bottom",
                        duration: 2000,
                    });
                    this._fuseProgressBarService.hide();
                    if (response.status == 200) {
                        this._router.navigate(["/events"]);
                    }
                }).catch((err) => {
                    this._matSnackBar.open(err.message, "OK", {
                        verticalPosition: "bottom",
                        duration: 2000,
                    });
                });
            }
        });
    }

    onCreateTicket() {
        this._router.navigate(["/tickets/create"], { queryParams: { eventId: this.eventData?.id, to : 'view' } });
    }

    disableBooking(){
        const dialog = this._matDialog.open(CommonMessageSubmitComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            width : '380px',
            autoFocus : false,
            data : {
                title : `Add reason for disable booking`,
                label : 'Reason',
                confirmButtonText : 'Disable',
                errorMsg : 'Rejection reason is required.'
            }
        }) 

        dialog.afterClosed().subscribe((res)=>{
            if(res){
                console.log("res === >", res);
                const payload = {
                    eventId: this.eventData.id,
                    bookingDisable: 1,
                    disableMsg: res
                }
                this._commonService.bookingDisable(payload).then((result:any) => {
                    if(result.status == 200){
                        this._matSnackBar.open(result.message, 'OKAY', {duration : 2000})
                        this.updateViewEventData();
                    }else{
                        this._matSnackBar.open(result.message, 'OKAY', {duration : 2000})
                    }
                })
            }
        })
    }

    enableBooking(){
        const payload = {
            eventId: this.eventData.id,
            bookingDisable: 0,
            disableMsg: ''
        }
        this._commonService.bookingDisable(payload).then((result:any) => {
            if(result.status == 200){
                this._matSnackBar.open(result.message, 'OKAY', {duration : 2000})
                this.updateViewEventData();
            }else{
                this._matSnackBar.open(result.message, 'OKAY', {duration : 2000})
            }
        })
    }

    downloadInvitationCodes(){
        this._commonService.getInvitationCodes({eventId : this.eventData.id}).then((res:any) => {
            if(res.status == 200){
                window.open(String(res.data), "_blank");
            }
            this._matSnackBar.open(res.message, 'OKAY', {duration : 3000});
        })
    }

    openMeetingLocationDialog(){
        const dialog = this._matDialog.open(AddEditEventMeetingLocationComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            autoFocus : false,
            width : '550px',
            disableClose : true,
            data : {
                eventId : this.eventData.id,
                selectedIds : (this.eventData?.eventMeetingLocation?.length) ? this.eventData?.eventMeetingLocation : []
            }
        })

        dialog.afterClosed().subscribe((res) => {
            if(res){
                this.updateViewEventData();
            }
        })
    }

    
    onDeleteMeetingLocation(item){
        const dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : `Are you sure you want to delete this meeting location?`,
                image : '',
                focus : 'right',
                deleteItem : 'delete meeting location ?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            } 
        }) 

        dialog.afterClosed().subscribe((res)=>{
            if(res){
                const payload = {
                    "eventId":this.eventData.id,
                    "locationId":item.id
                }
                
                this._commonService.deleteEventMeetingLocation(payload).then((res:any)=>{
                    if(res.status == 200){
                        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
                        this.updateViewEventData();
                    }else{
                        this._matSnackBar.open(res.message, 'OKAY', {duration : 3000})
                    }
                })
            }}
        )
    }

    
}
