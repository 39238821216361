import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { AddEditHostTypeComponent } from '../add-edit-host-type/add-edit-host-type.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { addRanking } from '@fuse/utils/systemEnums';

@Component({
  selector: 'app-add-edit-sponsor-group',
  templateUrl: './add-edit-sponsor-group.component.html',
  styleUrls: ['./add-edit-sponsor-group.component.scss']
})
export class AddEditSponsorGroupComponent implements OnInit {
  mode : string;
  form:FormGroup;
  selectedSponsors;
  addRanking = addRanking;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddEditHostTypeComponent>,
    private _fb: UntypedFormBuilder,
    public _commonService: CommonService
  ) {
    this.mode = this._data.mode;
   }

  ngOnInit(): void {
    if(this.mode == 'add'){
      this.createForm()
    }else if(this.mode == 'view'){
      this.createForm(this._data.hostType)
      this.form.disable();
    }else{
      this.createForm(this._data.hostType)
    }
  }

  createForm(data?){
    const membersId = []
    if(data){
      this.selectedSponsors = data.members;
      data.members.forEach((item) => membersId.push(String(item.id)));
    }
    this.form = this._fb.group({
      id : [data ? data.id : ''],
      title : [data ? data.groupName : '', Validators.required],
      sponsors : [data ? data.members.length ?  membersId: '' : '', Validators.required]
    })
  }

  onSelectSponsors(event){
    this.selectedSponsors = [];
        if(this.form.get('sponsors').value)
            this._data.sponsors.forEach(item =>{
              this.form.get('sponsors').value.forEach((spId) => {
                  if(Number(item.id) === Number(spId)){
                    this.selectedSponsors.push(item)
                  }
              })
          })
        this.addRanking(this.selectedSponsors)
        console.log("this.selectedSpeakers ===-->", this.selectedSponsors);
  }

  onSubmit(){
    this._matDialogRef.close({formData : this.form.value, selectedSponsors : this.selectedSponsors, mode : this.mode})
  }

  dropSponsor(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedSponsors, event.previousIndex, event.currentIndex);
    this.addRanking(this.selectedSponsors);
  }

  
}
