import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Enums } from '@fuse/utils/systemEnums';
import { fromEvent, merge, Observable, BehaviorSubject, Subject } from 'rxjs';
import { EventListService } from './event-list.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
@Component({
    selector: 'app-events-list',
    templateUrl: './events-list.component.html',
    styleUrls: ['./events-list.component.scss'],
    animations : fuseAnimations
})
export class EventsListComponent implements OnInit {
    enum = Enums;
    maxAvailableRecordsInTable; 

    range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    });

    tableData = []
    dataSource;
    displayedColumns = ['eventName', 'startTime', 'endTime', 'availableTickets', 'soldTickets', 'status'];
    private _unsubscribeAll: Subject<any>;


    @ViewChild(MatPaginator, { static: true })paginator: MatPaginator;
    @ViewChild('search', { static: true })search;

    constructor(
        public _eventListService: EventListService,
        public _ToolbarService : ToolbarService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        public _commonservice : CommonService
    ) {
        
        this._commonservice._selectedTimeZone.subscribe((res)=> {
            // if(res){
                console.log("this.timeZoneForShowTime", res);
            // }
        })
        

        if(this._eventListService.typeQueryParmas=="active"){
            this.filter.typeFilter = '4';
            this.filterData();
        }

        
        // if (this._eventListService.permissions.publish)
        //     this.displayedColumns.push('action')
        this.maxAvailableRecordsInTable = this._eventListService.totalEvents;
        // /* IF EVENT START AND END DATE ARE SAME THEN SHOWING ONLY TIME IN EVENT END DATE */
        // this._eventListService.events
        // console.log("to finding dates", this._eventListService.events);
        // let startDate = this._eventListService.events.map(item => new Date(item.eventStartDate).getDate() + " " +  new Date(item.eventStartDate).getMonth() + " " +new Date(item.eventStartDate).getFullYear());
        // console.log("all starting date", startDate);

        // let endDate = this._eventListService.events.map(item => new Date(item.eventEndDate).getDate() + " " +  new Date(item.eventEndDate).getMonth() + " " +new Date(item.eventEndDate).getFullYear());
        // console.log("all End date", endDate);
        this.setToolBarTitle();
    }

    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next('All Events');
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }


    ngOnInit() {
        this.tableData = this._eventListService.events
        this.dataSource = new MatTableDataSource(this.tableData);
    }

    onDateChange(){
        if(this.range.controls['start'].value && this.range.controls['end'].value)
            this.filterData();
    }

    deleteEvent(id) {
        let dialog =  this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : `Are you sure you want to delete this Event?`,
                image : '/assets/ui/delete.png',
                focus : 'right',
                deleteItem : 'Delete Event?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        })
        
        dialog.afterClosed().subscribe((row: any) => {
            if (row) {
                this._fuseProgressBarService.show();
                this._eventListService.deleteEvent(id).then((response: any) => {
                    this._matSnackBar.open(response.message, 'OK', {
                        verticalPosition: 'bottom',
                        duration: 2000
                    });
                    this._fuseProgressBarService.hide()
                    if (response.status == 200) {
                        this.updateTable();
                    }
                }).catch((err) => {
                    this._matSnackBar.open(err.message, 'OK', {
                        verticalPosition: 'bottom',
                        duration: 2000
                    });
                })
            }
        });

    }

    updateTable() {
        this._eventListService.getEventsList().then(() => {
            this.tableData = this._eventListService.events
            this.dataSource.data = this.tableData
        })
    }
    

    // publishEvent(id) {
    //     this._eventListService.publishEvent(id).then(() => {
    //         this.tableData = this._eventListService.events
    //         this.dataSource.data = this.tableData
    //     })
    // }

    // ----------------------- filters section -----------------------

    dateRangeFilterVal
    filter = {
        typeFilter: '',
        searchFilter: '',
        eventWayFilter: '',
        eventFreeFilter: '',
        pageSize: 10,
        pageIndex: 1
    }

    clearFilter() {
        this.paginator.pageIndex = 0;
        this.range.get('start').reset();
        this.range.get('end').reset();
        this.filter.typeFilter = ''
        this.filter.eventWayFilter = ''
        this.filter.eventFreeFilter = ''
        this.filter.searchFilter = ''
        this.dateRangeFilterVal = ''
        this.filterData()
    }

    onTypeFilterSelect() {
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1
        this.filterData();
    }

    onEventWay() {
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1
        this.filterData();
    }

    eventFreeFilter(){
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1
        this.filterData();
    }

    onSearch(val) {
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1
        this.filter.searchFilter = val
        this.filterData();
    }

    filterData() {
        console.log("filter function called",this.range);
        let payload = {
            "pageIndex": this.filter.pageIndex,
            "pageSize": this.filter.pageSize,
            "search": this.filter.searchFilter,
            "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
            "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
            "eventStatus": this.filter.typeFilter ? this.filter.typeFilter : 0,
            "isFree": this.filter.eventFreeFilter ? this.filter.eventFreeFilter : 0,
            "isOnline": this.filter.eventWayFilter ? this.filter.eventWayFilter : 0,
        }
         
        this._eventListService.getEventsList(payload).then((result: any) => {
            if (result.status == 200) {
                this.dataSource.data = result.data.data
                this.maxAvailableRecordsInTable = result.data.totalRecords
            }else if (result.status == 400) {
                this.dataSource.data = [];
                this.maxAvailableRecordsInTable = 0;
            }
            else {
                this.dataSource.data = []
                this.maxAvailableRecordsInTable = 0
            }
        })
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.filterData()
    }
    
    clearSearch() {
        this.filter.searchFilter = ''
        this.search.nativeElement.focus()
        this.filterData()
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        // this._unsubscribeAll.next();
        // this._unsubscribeAll.complete();
    }
}

