import { FormGroup } from '@angular/forms';
import { Component, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { EventCategoryService } from '../event-category.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { AddEventCategoryDialogComponent } from '@fuse/Dialog/add-event-category-dialog/add-event-category-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations : fuseAnimations
})

export class ListComponent implements OnInit {
  maxAvailableRecordsInTable
  displayedColumns: string[] = ['type', 'icon', 'desc', 'status'];
  dataSource;
  dialogRef
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private _matDialog: MatDialog,
    public _eventCategoryService: EventCategoryService,
    private _ToolbarService : ToolbarService
  ) {
    this.dataSource = new MatTableDataSource(this._eventCategoryService.eventCategories);
    this.maxAvailableRecordsInTable = this._eventCategoryService.maxAvailableRecordsInTable;
    this.setToolBarTitle();

  }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Event Categories');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }



  updateTable() {
    this._eventCategoryService.getAllEventCategory().then(() => {
      this.dataSource.data = this._eventCategoryService.eventCategories
      this.dataSource.paginator = this.paginator;
    })
  }
  openAddEventCategoryDialog(id?): void {
    let selectedEventCategory = []
    if (id) {
      selectedEventCategory = this._eventCategoryService.eventCategories.filter((type) => {
        return type.id == id
      })
    }

    this.dialogRef = this._matDialog.open(AddEventCategoryDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '480px',
      data: { eventCategory: selectedEventCategory[0],
        heading:'Edit new event category'
      },
      autoFocus: false
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (response)
        this.filterData()
      });
  }

  onDelete(id) {
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Category?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Category?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    
    dialog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._eventCategoryService.deleteEventCategory(id).then(() => {
            let lastDigitOfPage = this.maxAvailableRecordsInTable.toString().slice(-1);
            if (lastDigitOfPage == 1){
              if(this.filter.pageIndex!=1){
                this.filter.pageIndex = this.filter.pageIndex - 1;
                this.paginator.pageIndex = this.filter.pageIndex - 1;
              }
            }
            this.filterData()
        })
      }
    });
  }

  // ----------------------- filters section -----------------------

  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.paginator.pageIndex = 0;
    this.filterData();
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    this._eventCategoryService.getAllEventCategory(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }else if (result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
      }
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }
  
  @ViewChild('search', { static: true }) search;
  
  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

}
