import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AddUpdateTrackService } from '../add-edit-track/add-update-track.service';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-track-detail',
  templateUrl: './track-detail.component.html',
  styleUrls: ['./track-detail.component.scss'],
  animations : fuseAnimations,
  encapsulation : ViewEncapsulation.None
})
export class TrackDetailComponent implements OnInit {

  tranckDetails;

  constructor(
    public _ToolbarService: ToolbarService,
    private _AddUpdateTrackService : AddUpdateTrackService,
    private _router : Router,
    private _commonservice : CommonService
  ) { 
    
    
  }

  ngOnInit(): void {
    this.tranckDetails = this._AddUpdateTrackService.trackDetails;
    console.log("this.tranckDetails", this.tranckDetails);
  }


  goBack(){
    this._commonservice.goBack();
  }

  checkActivesLength(){
    return this.tranckDetails.performerSchedule?.some((item:any) => item.activitesList?.length);
    // let isActivesAvailabel : boolean = false;
    // this.tranckDetails.performerSchedule?.some((item:any) => {
    //     if(item.activitesList?.length)
    //     isActivesAvailabel = true;
    // })
    // console.log("checker", this.tranckDetails.performerSchedule?.some((item:any) => item.activitesList?.length));
    // return isActivesAvailabel;
  }
}
