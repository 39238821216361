import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { OrderService } from 'app/main/orders/order.service';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { MatPaginator } from '@angular/material/paginator';
import { OrderMembersComponent } from '@fuse/Dialog/order-members-details-dialog/order-members/order-members.component';

@Component({
  selector: 'app-event-booking-list',
  templateUrl: './event-booking-list.component.html',
  styleUrls: ['./event-booking-list.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations : fuseAnimations
})
export class EventBookingListComponent implements OnInit {

  displayedColumns: string[] = ['orderId', 'customerName', 'mobile', 'totalSeats', 'createdBy', 'eventName','ticketType', 'amount', 'soldDate', 'action'];
  dataSource = new MatTableDataSource([]);
  maxAvailableRecordsInTable:number = 0;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild('search', { static: false }) search;

  allStates:[] = [];
  allCountries

  filter = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    eventFilter: '',
    ticketType: '',
    searchFilter: '',
    slotFilter: '',
    pageSize: 10,
    isApproved : '',
    pageIndex: 1
  }
  isDataLoading : Boolean = false;

  dateRangeFilterVal;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  slotData:any;
  ticketTypeList:any;

  eventData;

  constructor(
    public _orderService: OrderService,
    private _fuseProgressBarService: FuseProgressBarService,
    public _ToolbarService : ToolbarService,
    private _MatSnackBar : MatSnackBar,
    public _addEditEventService: AddEditEventService, 
    private _matDialog: MatDialog,
    public _commonService : CommonService
  ) {
    
  }

  ngOnInit(): void {
    this.eventData = this._addEditEventService.eventData;
    if(this.eventData.bookingType == 1){
      if(this.eventData.totalOccupiedSeats){
          this.getAllEventType();
          this.onEventFilterSelect();
      }
    }else if(this.eventData.bookingType == 2){
        this.getAllEventType();
        this.onEventFilterSelect();
        // this.filter.isApproved = '3';
        this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'status');
       
    }
 
    // this.allStates = this._orderService.allStates;
    this._commonService.getAllCountries().then((result:any) => {
      if(result.length){
        this.allCountries = result
      }else{
        this.allCountries = []
      }
    });
    this._commonService.getStates(0).then((result:any) => {
      this.allStates = result.data;
    }); 
  }
  
  onPrint(pdfLink) {
    if(pdfLink){
    let pdfLinkGen = pdfLink;
      const printWindow = window.open('', '', 'height="100vh",width="100%"');
      printWindow.document.write('<body style="margin:0; padding:0;"><iframe src="' + pdfLinkGen +'" height="100%" width="100%"  type="pdf"></iframe></body>')
    }
  }

  
  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value)
        this.filterData();
  }

  clearFilter() {
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.range.get('start').reset();
    this.range.get('end').reset();
    this.filter.eventFilter = '';
    this.filter.searchFilter = '';
    this.filter.slotFilter = '';
    this.filter.ticketType = '';
    this.dateRangeFilterVal = '';
    this.filter.isApproved = '';
    this.filterData()
  }

  filterData() {
    this.isDataLoading = true;
    const payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventId": this.eventData.id,
      "ticketType": this.filter.ticketType,
      "slotId": this.filter.slotFilter,
      "isApproved" : this.filter.isApproved,
      "bookingType" : this.eventData.bookingType,
    }

    this._fuseProgressBarService.show();
    this._orderService.getOrders(payload).then((result: any) => {
      this._fuseProgressBarService.hide();
      this.isDataLoading = false;
      if (result.status == 200){
        this.dataSource.data = result.data.data;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
        this._addEditEventService.isRegistrationData = result.data.data;
      } 
      else if (result.status == 400){
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }


  openOrderMemberDetails(id){
    const payload = {
      "orderId": id,
      "bookingType" : this.eventData.bookingType
    }
    this._orderService.getOrderMembersById(payload).then((result: any) => {
      if (result.status == 200) {
        const dialog = this._matDialog.open(OrderMembersComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width: '650px',
          data: {
            orderId : payload.orderId,
            allMembers : result.data,
            allCountries : this.allCountries,
            allStates : this.allStates
          }
        });
      }
    }) 
  
    // this.dialogRef.afterClosed()
    //   .subscribe(response => {
    //     if (response)
    //       this.updateTable()
    //   });
  }

  updateApprovalBookingStatus(element, status){
    const payload = {
      orderId : element.orderId,
      status : status // 1 === 'approval' // 0 === 'reject'
    }
    this._commonService.updateApprovalBooking(payload).then((res:any)=>{
      if(res.status == 200){
        this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000})
        this.filterData();
      }else{
        this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000})
      }
    })
}


  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  onSearch(val) {
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.filterData();
  }

  
  clearSearch() {
    console.log("this.search", this.search);
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }


  onSlotFilter(val){
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.filter.pageIndex = 1;
    this.filter.eventFilter = this.eventData.id;
    this.filter.slotFilter = val.value;
    this.filterData();
  }

  
  onStatusFilter(event){
    this.filter.pageIndex = 1;
    this.filter.eventFilter = this.eventData.id;
    // if(this.filter['isApproved']){
    this.filter.isApproved = event.value;
    // }
    this.filterData();
  }

  onEventFilterSelect() {
    const payload = {
      "eventId": this.eventData.id
    }
    this._orderService.getTicketCreatedEventsList(payload).then((result:any) =>{
      this.slotData = result.data[0].schedule;
      if(this.slotData.length == 1){
        this.filter.slotFilter = result.data[0].schedule[0].slotId;
      }else{
        this.filter.slotFilter = '';
      }
    })
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.filter.pageIndex = 1;
    this.filter.eventFilter = this.eventData.id;
    this.filterData();
  }

  onTicketTypeFilterSelect(){
    if(this.paginator){
      this.paginator.pageIndex = 0;
    }
    this.filter.pageIndex = 1;
    this.filterData();
  }

  getAllEventType(){
    this._commonService.getAllTicketTypes().then((result:any) =>{
        console.log("getAllEventTypes ----->", result);
        this.ticketTypeList = result;
    })
  }

}
