import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { GroupsService } from 'app/main/groups/groups.service';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-add-group-dialog',
  templateUrl: './add-group-dialog.component.html',
  styleUrls: ['./add-group-dialog.component.scss']
})
export class AddGroupDialogComponent implements OnInit {
  form: UntypedFormGroup
  allContacts;
  groupDetails;
  contactId;
  selectedContacts: any = []
  searchValue
  
  // public bankMultiFilterCtrl: FormControl<string> = new FormControl<string>('');
  public bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl('');

  public filteredBanksMulti: ReplaySubject<any> = new ReplaySubject<any>(
    1
  );
  protected _onDestroy = new Subject<void>();

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddGroupDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data,
    public _groupsService: GroupsService,

  ) {
      console.log("_data -->", _data);
   }

  ngOnInit(): void {

    this.groupDetails = this._data?.groupDetails
    this.allContacts = this._data?.allContacts
 
    if(this.groupDetails){
      this.contactId = this.groupDetails.contactList
      this.allContacts.map((contact:any) => {
        this.contactId.map((id:any) => {
          if(contact.id == id){
            this.selectedContacts.push(contact.fullName);
          }
        })
      })
      this.createForm(this.groupDetails);
    }else {
      this.createForm();
    }

    this.filteredBanksMulti.next(this.allContacts.slice());


    this.bankMultiFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe((val) => {
        // console.log("THias is value in subscribe-->",val)
        this.filterBanksMulti();
        // console.log("BAhut hi zyda important",this.searchValue)
      });

    
    // console.log(this.selectedContacts)
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  createForm(data?){
    console.log("THIS IS DATA OF GROUP DETAILS data.contactIds" ,data)
    this.form = this._fb.group({
      "title": [data ? data.name : '',Validators.required],
      "description": [data ? data.description : ''],
      "member": [data ? data.contactList?.length ? data.contactList.map(item => String(item)) : null : null],
    })
  }

  onSubmit(){
    if(this._data.groupDetails?.id){
      const payload = {
        "id" : this._data.groupDetails.id,
        "name": this.form.get('title').value,
        "description": this.form.get('description').value,
        "totalMember": this.form.get('member').value ? this.form.get('member').value?.length : '',
        "contactList": this.form.get('member').value ? this.form.get('member').value.map(item => Number(item))  : '',
      }
      this.matDialogRef.close(payload)
    }else{
      const payload = {
        "name": this.form.get('title')?.value || '',
        "description": this.form.get('description')?.value || '',
        "totalMember": this.form.get('member')?.value ? this.form.get('member').value?.length : '',
        "contactList": this.form.get('member')?.value ? this.form.get('member')?.value.map(item => Number(item)) : '',
      }
      this.matDialogRef.close(payload)
    }
  }

  checkBoxvalue(value){
    // console.log("This is value")
    // console.log("This is value",value)
    // value.forEach(item => {
    //   this.allContacts.map((element)=>{
    //     if(element.id == item){
    //       element['status'] = true
    //       return
    //     }
    //   })  
    // });

    // console.log("this.allContacts ==>",this.allContacts);
    
  }

  setInitialValue(){
    this.filteredBanksMulti.pipe(take(1),takeUntil(this._onDestroy)).subscribe((result:any) => {
    })
  }

  filterBanksMulti(){
    let search = this.bankMultiFilterCtrl.value;
    if(!search){
      this.filteredBanksMulti.next(this.allContacts.slice());
      return;
    } else {
        search = search.toLowerCase();
    }

    this.filteredBanksMulti.next(
      this.allContacts.filter(bank => bank.fullName.toLowerCase().indexOf(search.toLowerCase()) > -1)
    );

  }
  
}
