import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GeneratedLabelsService implements Resolve<any> {
  totalRecords = 0;
  badgeList  = []
  allEvents: any;
  filterFor : number = 0;


  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    if(route?.queryParams?.filterFor){
      this.filterFor = Number(route?.queryParams?.filterFor);
    }

    
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllCertificateUserList({pageSize : 10, pageIndex : 1,eventId: this.filterFor,search : ''}),
      ]).then((res)=>{
        resolve('')
      }, reject)
    })
  }

  getAllCertificateUserList(body): Promise<any> {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/badge/getAllGenratedBadgeMemberList', body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide();
          if(response.status == 200){
            this.badgeList = response.data.badgeList;
            this.totalRecords = response.data.totalRecords;
          }else{
            this.badgeList = [];
            this.totalRecords = 0;
          }
          console.log("response", response);
          resolve(response);
        }, reject);
    });
  }

  getAllEvents() {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallevents', {"eventId":64}, this._commonService.getHeader())
        .subscribe((response: any) => {
          console.log("your new data is here");
          // this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.allEvents = response.data.data;
            // this.maxMember = this.allEvents
            resolve(response);
          }
          else if (response.status == 400) {
            this.allEvents = [];
            resolve(response);
          }
        }, reject);
    });
  }
}
