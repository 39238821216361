import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-dome-customer-connections',
  templateUrl: './dome-customer-connections.component.html',
  styleUrls: ['./dome-customer-connections.component.scss']
})
export class DomeCustomerConnectionsComponent implements OnInit {
  customer
  connectionList
  @ViewChild('filter', { static: true }) filter: ElementRef;

  defaultImages = [
    'assets/images/profileImage/01.png',
    'assets/images/profileImage/02.png',
    'assets/images/profileImage/03.png',
    'assets/images/profileImage/04.png',
    'assets/images/profileImage/05.png',
    'assets/images/profileImage/06.png',
    'assets/images/profileImage/07.png',
    'assets/images/profileImage/08.png',
    'assets/images/profileImage/09.png',
  ]
  // Private
  private _unsubscribeAll: Subject<any>;
  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<DomeCustomerConnectionsComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
  ) { 
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    
    this.customer = this._data.customer;
    this.connectionList = this._data.connectionList
  }

  ngOnInit(): void {
    fromEvent(this.filter.nativeElement, 'keyup').pipe(takeUntil(this._unsubscribeAll),debounceTime(150),distinctUntilChanged()).subscribe(() => {
      if (!this.connectionList)
      {
          return;
      } 
      this.connectionList = this._data.connectionList;
      const searchText = String(this.filter.nativeElement.value.trim());
      this.connectionList = this.connectionList.filter(item => {
        if(item?.fullName?.toLowerCase().includes(searchText?.toLowerCase()) || item?.email?.includes(searchText?.toLowerCase()) || item?.occupation?.includes(searchText?.toLowerCase()) || item?.orgnizationName?.includes(searchText?.toLowerCase()) || item?.workingIndustry?.includes(searchText?.toLowerCase())){
          return item
        }
      });
    })
  } 

  /**
     * On destroy
     */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }

}
