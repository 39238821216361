import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { UserCertificatesService } from './user-certificates.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '@fuse/services/common.service';
import { fuseAnimations } from '@fuse/animations';
import { SuccessDialogComponent } from '@fuse/Dialog/success-dialog/success-dialog.component';
import { CommonMessageSubmitComponent } from '@fuse/Dialog/common-message-submit/common-message-submit.component';
import { CommonImageViewerDialogComponent } from '@fuse/Dialog/common-image-viewer-dialog/common-image-viewer-dialog.component';

@Component({
  selector: 'app-user-certificate',
  templateUrl: './user-certificate.component.html',
  styleUrls: ['./user-certificate.component.scss'],
  animations : fuseAnimations
})
export class UserCertificateComponent implements OnInit {

  displayedColumns: string[] = ['name', 'email', 'phoneNumber', 'eventName', 'action'];
  dataSource;
  maxAvailableRecordsInTable;
  filter = {
    eventId: 0,
    search: '',
    pageSize: 10,
    pageIndex: 1
  }

  constructor(
    private _ToolbarService: ToolbarService,
    private _fuseProgressBarService: FuseProgressBarService,
    public _UserCertificatesService : UserCertificatesService,
    public _commonService : CommonService,
    private _matDialog: MatDialog,
  ) { 

    this._UserCertificatesService.getAllEvents();
    this.setToolBarTitle(false);

    if(this._UserCertificatesService?.filterFor){
      this.filter.eventId = this._UserCertificatesService.filterFor;
    }
    
  }

  ngOnInit(): void { 
    this.dataSource = new MatTableDataSource(this._UserCertificatesService.certificates);
    this.maxAvailableRecordsInTable = this._UserCertificatesService.totalRecords;
  }

  
  setToolBarTitle(edit) {
    if (edit) {
        // for title
        this._ToolbarService._ToolBarTitle.next("Select Attendees");
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    } else {
        // for title
        this._ToolbarService._ToolBarTitle.next("Generated Certificate's");
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.search = val
    this.filterData();
  }

  
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.search = ''
    this.search.nativeElement.focus()
    this.filterData()
  }


  onEventFilterSelect(val){
    this.filter.eventId = val.value;
    this.filterData()
  }

  clearFilter() {
    this.filter.eventId = 0;
    this.filter.search = '';
    this.filterData();
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  filterData() {
    this._fuseProgressBarService.show()
    this._UserCertificatesService.getAllCertificateUserList(this.filter).then((result: any) => {
      this._fuseProgressBarService.hide()
      if (result.status == 200) {
        this.dataSource.data = result.data.certificates;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      } else if (result.status == 400) {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0
      }
    })
  }

  onViewCertificate(image){
    const dialog = this._matDialog.open(CommonImageViewerDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '568px',
      autoFocus : false,
      data : {
        image : image,
        imageWidthSize : '100%',
        message : null,
        buttonText : 'Download',
        title : 'View Certificate'
      }
    })
    
  }

}
