import { CommonService } from '@fuse/services/common.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements Resolve<any> {
  notificationList: [];
  events: any;
  eventList: [] = [];
  onUserListChanged: BehaviorSubject<any>;

  
  userList:[] = [];
  maxAvailableRecordsInTable: any;

  constructor(
    private _httpClient: HttpClient, 
    private _fuseProgressBarService: FuseProgressBarService,
    private _MatSnackBar : MatSnackBar,
    private _commonService:CommonService
    ) {
      this.onUserListChanged = new BehaviorSubject({});

  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    // this.userId = route.params.id;

    
      return new Promise((resolve, reject) => {
        Promise.all([
          // this._fuseProgressBarService.show(), 
          this.getEventList(),
          this.getUserList({pageSize : 10, pageIndex : 1})
        ]).then(
          () => {
            resolve('');
            // this._fuseProgressBarService.hide();
          }, reject);
      });

    
  }
 
  getEventList(){
    return new Promise((resovle, reject) => {
      this._commonService.getEventsList().then((result:any)=>{
        if (result.status == 200) {
          this.eventList = result.data.data;
          resovle(result);
        }else{
          this.eventList = [];
          resovle([]);
        }
      })
      
    })
  }


  getUserList(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/userList', body, this._commonService.getHeader()).subscribe((res:any)=>{
        this._fuseProgressBarService.hide();
        if(res.status == 200){
          this.userList = res.data.userList;
          this.onUserListChanged.next(this.userList);
          this.maxAvailableRecordsInTable = res.data.totalRecords;
          resolve(res)
        }else{
          this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000})
          resolve(res)
          this.onUserListChanged.next([]);
          this.userList = [];
          this.maxAvailableRecordsInTable = 0;
        }
      }, reject)
    })
  }
  
 
}
