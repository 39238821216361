import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TaxesComponent } from './taxes.component';
import { RouterModule, Routes } from '@angular/router';
import { TaxesService } from './taxes.service';
import { MatButtonModule } from '@angular/material/button';
import { FuseWidgetModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';

const routes: Routes = [
  {
    path: '', 
    component: TaxesComponent,
    resolve: {
      data: TaxesService
    }
  }
]


@NgModule({
  declarations: [TaxesComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatButtonModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatPaginatorModule,
    MatTableModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule, 
  ]
  
})

export class TaxesModule { }
