import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from "@angular/core"
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CryptoService } from './crypto.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
  constructor(
    private _fuseProgressbarService: FuseProgressBarService,
    private _CryptoService : CryptoService,
    private _matSnackBar: MatSnackBar
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {

          if (evt.status != 200 || evt.body.status != 200) {
            this._fuseProgressbarService.hide();
            if (evt.body?.message) {
              // this._matSnackBar.open(evt.body.message, 'OKAY', {duration : 3000});
            } else {
              // this._matSnackBar.open('Something Went Wrong', 'OKAY', {duration : 3000});
            } 
          }

        

 
          // if (evt.status == 401 || evt.body.status == 401) {
          //   this._matSnackBar.open(evt.body.message, 'OKAY', {duration : 3000});
          //   // localStorage.clear()
          //   // this._router.navigate['/login']
          // }

          
        }
      }),
      catchError((err: any) => {
        if (err.status != 200 || err?.body?.status != 200) {
          // if (err?.body?.status || err.status) {
          //   this._matSnackBar.open('Something Went Wrong', 'OKAY', { duration: 3000 });
          // }
          if(err.status == 0){
            this._matSnackBar.open('Unable to fetch data due to security restrictions (CORS error). Apologies for the inconvenience. Please try again later.', 'OKAY', { duration: 10000 });
          }
          this._fuseProgressbarService.hide();
        }

        if (err instanceof HttpErrorResponse) {
          if (err.status == 401 || err.error.status == 401) {
            if (err.error.status || err.status) {
              this._matSnackBar.open('Something Went Wrong', 'OKAY', { duration: 3000 });
            }
            this._fuseProgressbarService.hide();
            //localStorage.setItem("userLoggedIn", "false")
            // localStorage.clear()
            // // window.location.href = "/auth/login";
            // this._router.navigate(['/auth/login'])
          }

        }
        return of(err);
      }));

  }




}