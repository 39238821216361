import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ConfirmationDialogComponent } from './Dialog/confirmation-dialog/confirmation-dialog.component';
import { CropperComponent } from './Dialog/cropper/cropper.component';
import { MatButtonModule } from '@angular/material/button'; 
import { MatSliderModule } from '@angular/material/slider';
import { AddVenueComponent } from './Dialog/add-venue/add-venue.component';
import { AgmCoreModule } from '@agm/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AddEventTypeDialogComponent } from './Dialog/add-event-type-dialog/add-event-type-dialog.component';
import { AddOrganiserDialogComponent } from './Dialog/add-organiser-dialog/add-organiser-dialog.component';
import { AddTicketTypeDialogComponent } from './Dialog/add-ticket-type-dialog/add-ticket-type-dialog.component';
import { AddGuestDialogComponent } from './Dialog/add-guest-dialog/add-guest-dialog.component';
import { AddEventCategoryDialogComponent } from './Dialog/add-event-category-dialog/add-event-category-dialog.component';
import { AssignEventDialogComponent } from './Dialog/assign-event-dialog/assign-event-dialog.component';
import { ChangePasswordDialogComponent } from './Dialog/change-password-dialog/change-password-dialog.component';
import { AuthQrDialog } from './Dialog/auth-qr-dialog/auth-qr-dialog';
import { DepositDialogComponent } from './Dialog/refund/deposit-dialog/deposit-dialog.component';
import { OrderMembersComponent } from './Dialog/order-members-details-dialog/order-members/order-members.component';
import { MatCardModule } from '@angular/material/card';
import { MultipleDatepickerComponent } from './Dialog/event-multiple-datepicker-dialog/multiple-datepicker/multiple-datepicker.component';
import { MatChipsModule } from '@angular/material/chips';
import { DateAdapter, MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, } from '@angular/material-moment-adapter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AddTicketGuidelinesComponent } from './Dialog/add-ticket-guidelines/add-ticket-guidelines/add-ticket-guidelines.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { TicketTypeBgColorComponent } from './Dialog/ticket-type-bg-color/ticket-type-bg-color.component';
import { AddTaxDialogComponent } from './Dialog/add-tax-dialog/add-tax-dialog/add-tax-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar'; 
import { SendFeedbackComponent } from './Dialog/send-feedback/send-feedback.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EventPublishDialogComponent } from './Dialog/event-publish-dialog/event-publish-dialog.component';
import { PublishEventConfirmationDialogComponent } from './Dialog/publish-event-confirmation-dialog/publish-event-confirmation-dialog.component';
import { AddGroupDialogComponent } from './Dialog/add-group-dialog/add-group-dialog.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ImportContactDialogComponent } from './Dialog/import-contact-dialog/import-contact-dialog.component';
import { InvitationTemplateDialogComponent } from './Dialog/invitation-template-dialog/invitation-template-dialog.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AddLocationComponent } from './Dialog/add-location/add-location.component';
import { InvitationMessageDetailComponent } from './Dialog/invitation-message-detail/invitation-message-detail.component';
import { CancelEventDialogComponent } from './Dialog/cancel-event-dialog/cancel-event-dialog.component';
import { QuillModule } from 'ngx-quill';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AddMoreFieldsComponent } from './Dialog/add-more-fields/add-more-fields.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatTableModule } from '@angular/material/table';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AddNewDynamicVariableComponent } from './Dialog/add-new-dynamic-variable/add-new-dynamic-variable.component';
import { SnippetIframeCodeComponent } from './Dialog/snippet-iframe-code/snippet-iframe-code.component';
import { AddEditFaqDialogComponent } from './Dialog/add-edit-faq-dialog/add-edit-faq-dialog.component';
import { AddSchedulesComponent } from './Dialog/add-schedules/add-schedules.component';
import { MatTabsModule } from '@angular/material/tabs';
import { DeleteTicketDialogComponent } from './Dialog/delete-ticket-dialog/delete-ticket-dialog.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { AddPerformerComponent } from './Dialog/add-performer/add-performer.component';
import { AddActivityComponent } from './Dialog/add-activity/add-activity.component';
import { AddEditResourcesDialogComponent } from './Dialog/add-edit-resources-dialog/add-edit-resources-dialog.component';
import { AddBookingCustomerInfoComponent } from './Dialog/add-booking-customer-info/add-booking-customer-info.component';
import { AddEditSponsorComponent } from './Dialog/add-edit-sponsor/add-edit-sponsor.component';
import { PublishPollComponent } from './Dialog/publish-poll/publish-poll.component';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { SurveyQuestionComponent } from './Dialog/survey-question/survey-question.component';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog'; 
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { AddEditVerifiersLocationComponent } from './Dialog/add-edit-verifiers-location/add-edit-verifiers-location.component';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'environments/environment';
import { AddEditHostTypeComponent } from './Dialog/add-edit-host-type/add-edit-host-type.component';
import { AddEditSponsorGroupComponent } from './Dialog/add-edit-sponsor-group/add-edit-sponsor-group.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AddEditHostGroupComponent } from './Dialog/add-edit-host-group/add-edit-host-group.component';
import { DomeCustomerConnectionsComponent } from './Dialog/dome-customer-connections/dome-customer-connections.component';
import { CommonMessageSubmitComponent } from './Dialog/common-message-submit/common-message-submit.component';
import { ExtendTicketLimitDialogComponent } from './Dialog/extend-ticket-limit-dialog/extend-ticket-limit-dialog.component';
import { PaymentGatewayConnectedComponent } from './Dialog/payment-gateway-connected/payment-gateway-connected.component';
import { VerifierAssigningDialogComponent } from './Dialog/verifier-assigning-dialog/verifier-assigning-dialog.component';
import { AddUpdateMeetingLocationComponent } from './Dialog/add-update-meeting-location/add-update-meeting-location.component';
import { AddEditEventMeetingLocationComponent } from './Dialog/add-edit-event-meeting-location/add-edit-event-meeting-location.component';
import { ChooseCertificateDialogComponent } from './Dialog/choose-certificate-dialog/choose-certificate-dialog.component';
import { SuccessDialogComponent } from './Dialog/success-dialog/success-dialog.component';
import { CommonImageViewerDialogComponent } from './Dialog/common-image-viewer-dialog/common-image-viewer-dialog.component';
import { CommonUserSearchSelectDialogComponent } from './Dialog/common-user-search-select-dialog/common-user-search-select-dialog.component';
import { CommonInfoDialogComponent } from './Dialog/common-info-dialog/common-info-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatButtonModule,
        RecaptchaModule,
        MatTooltipModule,
        FlexLayoutModule,
        DragDropModule,
        RecaptchaFormsModule,
        MatDialogModule,
        FuseDirectivesModule,
        FusePipesModule,
        NgxMatSelectSearchModule,
        ClipboardModule, 
        MatSlideToggleModule,
        NgxMaterialTimepickerModule,
        MatSliderModule,
        MatTabsModule,
        MatSnackBarModule,
        AgmCoreModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        NgxFileDropModule,
        LyImageCropperModule,
        LySliderModule,
        LyButtonModule,
        LyIconModule,
        LyDialogModule,
        MatTableModule,
        MatInputModule,
        MatIconModule,
        MatToolbarModule,
        MatSelectModule,
        MatCardModule,
        MatIconModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatChipsModule,
        MatMenuModule,
        NgxShimmerLoadingModule,
        MatRippleModule,
        MatDividerModule,
        NgxIntlTelInputModule,
        QuillModule,
        QuillModule.forRoot(),
        MatRadioModule,
        NgxMatDatetimePickerModule
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule
    ],
    declarations: [ConfirmationDialogComponent,CommonUserSearchSelectDialogComponent,CommonInfoDialogComponent, CommonImageViewerDialogComponent, SuccessDialogComponent, ChooseCertificateDialogComponent, AddEditEventMeetingLocationComponent, AddUpdateMeetingLocationComponent, VerifierAssigningDialogComponent, PaymentGatewayConnectedComponent, ExtendTicketLimitDialogComponent, DomeCustomerConnectionsComponent, CommonMessageSubmitComponent, AddEditHostGroupComponent, AddEditSponsorGroupComponent, AddEditHostTypeComponent, AddEditVerifiersLocationComponent, AddEditSponsorComponent, AddBookingCustomerInfoComponent, AddEditResourcesDialogComponent, AddGuestDialogComponent, AddActivityComponent, AddPerformerComponent, DeleteTicketDialogComponent, AddSchedulesComponent, AddEditFaqDialogComponent, SnippetIframeCodeComponent, AddNewDynamicVariableComponent, AddMoreFieldsComponent, CancelEventDialogComponent, InvitationMessageDetailComponent, AddLocationComponent, InvitationTemplateDialogComponent, ImportContactDialogComponent, PublishEventConfirmationDialogComponent, EventPublishDialogComponent, SendFeedbackComponent, AddTaxDialogComponent, TicketTypeBgColorComponent, AddTicketGuidelinesComponent, MultipleDatepickerComponent, OrderMembersComponent, CropperComponent, AddVenueComponent, AddEventTypeDialogComponent, AddOrganiserDialogComponent, AddTicketTypeDialogComponent, AddGuestDialogComponent, AddEventCategoryDialogComponent, AssignEventDialogComponent, ChangePasswordDialogComponent, AuthQrDialog, AddGroupDialogComponent,
        DepositDialogComponent, PublishPollComponent, SurveyQuestionComponent],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en' }, { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE], }, { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }, 
    {
        provide: RECAPTCHA_SETTINGS,
        useValue: {
          siteKey: environment.recaptcha.siteKey,
        } as RecaptchaSettings,
    },]
})
export class FuseSharedModule {
}
