import { Component, OnInit, ViewChild, ChangeDetectorRef, AfterViewInit, ViewEncapsulation,ElementRef } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ProjectDashboardService } from '../project/project.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatTableDataSource } from '@angular/material/table';
import { EventListService } from 'app/main/events/events-list/event-list.service';
import { DistributorListDialogComponent } from './distributor-list-dialog/distributor-list-dialog.component';
import { CommonService } from '@fuse/services/common.service';
// import { ChangeDetectorRef } from '@angular/core';
import { ChartComponent, ApexAxisChartSeries, ApexChart, ApexXAxis, ApexDataLabels, ApexTitleSubtitle, ApexStroke, ApexGrid } from "ng-apexcharts";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector: 'project-dashboard',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    animations: fuseAnimations
})

export class ProjectDashboardComponent implements OnInit {
    displayedColumns: string[] = ['sr', 'title', 'date', 'ticketQty'];
    displayedColumnsSalesReport: string[] = ['name', 'earning', 'growthCount', 'profitStatus'];
    dataSource:any;
    salesDataSource: any = []
    dialogRef: any;
    eventMapData: any = []
    ticketMapData: any = []
    allEventListForFilter: any = []
    allEvent: number = 0;

    @ViewChild("event-chart", { static: false }) chart: ChartComponent;
    @ViewChild("ticket-chart", { static: false }) ticketChart: ChartComponent;
    @ViewChild("ticket-booking", { static: false }) ticketBooking: ChartComponent;



    // event list array 
    eventListChart: any = [];
    showXAxis = true;
    showYAxis = true;
    gradient = false;
    showLegend = true;
    showXAxisLabel = true;
    xAxisLabel = 'Country';
    showYAxisLabel = false;
    yAxisLabel = 'Population';
    colorScheme = {
        domain: ['#00CCF2']
    };
    view = [500, 200];

    // ticket chart array
    ticketDataChart: any = [];
    legend: boolean = false;
    showLabels: boolean = false;
    animations: boolean = true;
    xAxis: boolean = true;
    yAxis: boolean = true;
    timeline: boolean = false;

    // report chart array
    reportListChart: any[] = [];
    gradientPie: boolean = false;
    showLegendPie: boolean = true;
    showLabelsPie: boolean = false;
    isDoughnut: boolean = false;
    legendPosition: string = 'below';
    arcWidth: number = 0;
    colorSchemePie = {
        domain: ['#00CCF2', '#FF007C ']
    };


    multi = [
        {
            "name": "Total Ticket",
            "series": [
                {
                    "name": "",
                    "value": 7300
                }

            ]
        },

        {
            "name": "Sold Ticket ",
            "series": [
                {
                    "name": "",
                    "value": 7870
                }

            ]
        },

        {
            "name": "Member Attended ",
            "series": [
                {
                    "name": "",
                    "value": 5000
                }

            ]
        }
    ];


    showXAxisbar: boolean = true;
    showYAxisbar: boolean = true;
    gradientBar: boolean = false;
    showLegendbar: boolean = false;
    legendPositionbar: string = 'below';
    showXAxisLabelbar: boolean = false;
    yAxisLabelbar: string = 'Country';
    showYAxisLabelbar: boolean = false;
    xAxisLabelbar = 'Population';
    schemeType: string = 'linear';

    eventListFilter = [
        { name: "All Time", value: 1 },
        { name: "This year", value: 2 },
        { name: "This week", value: 3 }

    ]
    ticketChartOptions: any;
    slotById: any;
    eventId: any;
    selectedSlot: any;

    onSelect(event) {
        console.log(event);
    }
    userLocalData;
    constructor(
        public _projectDashboardService: ProjectDashboardService,
        private _fuseProgressBarService: FuseProgressBarService,
        public _ToolbarService : ToolbarService,
        public _eventListService: EventListService,
        private _matDialog: MatDialog,
        private _matSnackBar: MatSnackBar,
        public _commonService: CommonService,
        private cd: ChangeDetectorRef,
        private _router: Router,
        private _ActivatedRoute: ActivatedRoute
    ) {
        this.userLocalData = localStorage;
        this.setToolBarTitle();

        this.eventMapData = this._projectDashboardService.eventMapData
        this.ticketMapData = this._projectDashboardService.ticketMapData
        this._projectDashboardService.getAlleventList({pageSize:10,pageIndex:1}).then((result:any) => {
            this.dataSource = new MatTableDataSource(result);
        });
        // this.salesDataSource = new MatTableDataSource(this._projectDashboardService.salesReport);

        // let reportDataArr = []
        // let singleDataForReport = this._projectDashboardService.allBookingData
        // reportDataArr.push(
        //     {
        //         name: "Available Tickets - " + singleDataForReport.availableTickets,
        //         value: singleDataForReport.availableTickets
        //     },
        //     {
        //         name: "Booked Tickets - " + singleDataForReport.bookedTickets,
        //         value: singleDataForReport.bookedTickets
        //     }
        // )
        // this.reportListChart = reportDataArr
    }

    setToolBarTitle(){
        this._ToolbarService._ToolBarTitle.next('Welcome, ' + this.userLocalData.getItem('userName'));
    }

    ngOnInit(): void {
        this.createTicketStateChart();
    }

    ngAfterViewInit(): void {
        // event list chart 
        this._projectDashboardService.getEventMap(1, 'formService').then((result: any) => {
            this._projectDashboardService.eventFilter = 1;
            const eventListArr = []
            let singleData = result.events;
            singleData.forEach(element => {
                eventListArr.push({ name: element.xAxisLabel, value: element.dataCount })
            });
            this.eventListChart = eventListArr;
            this.cd.detectChanges();
        })

        // Tickets Chart
        this._projectDashboardService.getTicketMap(1).then((result) => {
            let seriestempArr: any = [];
            let singleDataForTicket = result['tickets']
            singleDataForTicket.forEach(element => {
                seriestempArr.push({ name: element.xAxisLabel, value: element.dataCount })
            });
            let data = [
                {
                    name: "",
                    series: seriestempArr
                }
            ];
            seriestempArr = data;
            this.ticketDataChart = seriestempArr;
        })

        this.cd.detectChanges();
        this._projectDashboardService.getTicketCreatedEventsList({value:0}).then((result: any) => {
            this.slotById = result.data[0].schedule;
            this.allEventListForFilter = result.data;
            if (this.slotById.length == 1) {
                this.selectedSlot = result.data[0].schedule[0].slotId;
            } else {
                this.selectedSlot = '';
            }
        })

        this._projectDashboardService.getBookingData({value:0}).then((result: any) => {
            let availableTickets = result.data.availableTickets;
            let bookedTickets = result.data.bookedTickets;
            let reportDataArr = []
            let singleDataForReport = this._projectDashboardService.allBookingData;
            reportDataArr.push(
              {
                name: "Available Tickets - " + singleDataForReport.availableTickets,
                value: singleDataForReport.availableTickets
              },
              {
                name: "booked Tickets - " + singleDataForReport.bookedTickets,
                value: singleDataForReport.bookedTickets
              }
            )
            this.reportListChart = reportDataArr
          })

          
    }

    eventsFilter(e) {
        this.eventId = e.value;
        const payload = {
            "eventId": e.value
        }
        if (e.value) {
            console.log("if case run");
            this._projectDashboardService.getTicketCreatedEventsList(payload).then((result: any) => {
                console.log("result.data[0].schedule", result.data[0].schedule);
                this.slotById = result.data[0].schedule;
                console.log("result.data[0].schedule[0].slotId", result.data[0].schedule[0].slotId);
                if (this.slotById.length == 1) {
                    this.selectedSlot = result.data[0].schedule[0].slotId;
                    this.cd.detectChanges();
                } else {
                    this.selectedSlot = '';
                }
            })
        } else {
            this.slotById = []
            this.selectedSlot = '';
        }
        this._projectDashboardService.getBookingData(payload).then((result: any) => {      
          let availableTickets = result.data.availableTickets;
          let bookedTickets = result.data.bookedTickets;
          let reportDataArr = []
          let singleDataForReport = this._projectDashboardService.allBookingData;
          reportDataArr.push(
            {
              name: "Available Tickets - " + singleDataForReport.availableTickets,
              value: singleDataForReport.availableTickets
            },
            {
              name: "booked Tickets - " + singleDataForReport.bookedTickets,
              value: singleDataForReport.bookedTickets
            }
          )
          this.reportListChart = reportDataArr
        })

        console.log("this.selectedSlot", this.selectedSlot);
        console.log("slotById", this.slotById);
        
    }

    onSlotFilter(val) {
        const payload = {
            "eventId": this.eventId,
            "slotId": val.value
        }
        this._projectDashboardService.getBookingData(payload).then((result: any) => {
            let availableTickets = result.data.availableTickets;
            let bookedTickets = result.data.bookedTickets;
            let reportDataArr = []
            let singleDataForReport = this._projectDashboardService.allBookingData
            console.log("let singleDataForReport", singleDataForReport);

            reportDataArr.push(
                {
                    name: "Available Tickets - " + singleDataForReport.availableTickets,
                    value: singleDataForReport.availableTickets
                },
                {
                    name: "booked Tickets - " + singleDataForReport.bookedTickets,
                    value: singleDataForReport.bookedTickets
                }
            )
            this.reportListChart = reportDataArr;
        })
    }


    createTicketStateChart() {

        // let tempSold = { name: 'Sold', data: [] }
        // let tempNotSold = { name: 'Not Sold', data: [] }


        this._projectDashboardService.ticketStatesChartData.map((item) => {
            // this.ticketDataChart.xaxis.categories.push(item.xAxisLabel)
            // tempSold.data.push(item.totalSold)
            // tempNotSold.data.push(item.totalTickets - item.totalSold)  
        })

    }

    eventFilter(filter) {
        this._projectDashboardService.getEventMap(filter, 'ts').then((result: any) => {
            console.log("result", result);
            this._projectDashboardService.eventFilter = filter;
            // event list chart 
            const eventListArr = []
            let singleData = result.events;
            singleData.forEach(element => {
                eventListArr.push({ name: element.xAxisLabel, value: element.dataCount })
            });
            this.eventListChart = eventListArr;
            this.cd.detectChanges();
        })
    }

    ticketFilter(filter) {
        this._projectDashboardService.getTicketMap(filter).then((result) => {
            let seriestempArr: any = [];
            let singleDataForTicket = result['tickets']
            singleDataForTicket.forEach(element => {
                seriestempArr.push({ name: element.xAxisLabel, value: element.dataCount })
            });
            let data = [
                {
                    name: "",
                    series: seriestempArr
                }
            ];
            seriestempArr = data;
            this.ticketDataChart = seriestempArr;
        })
    }

    publishEvent(id) {

        this._eventListService.publishEvent(id).then((result: any) => {
            if (result.status == 200) {

                this._projectDashboardService.eventList = result.data.data
                this.dataSource.data = result.data.data;
            } else if (result.status == 200) {

                this.dataSource.data = [];
            }
        }
        )
    }

    openEventDetails(id) {
        this._router.navigate(['events/edit'], {
            queryParams: {
                id: id
            }
        })
    }

    // openDialog() {
    //   this._projectDashboardService.getAllDistributor().then((result: any) => {
    //     if (result.status == 200) {
    //       this.dialogRef = this._matDialog.open(DistributorListDialogComponent, {
    //         panelClass: 'no-padding-dialog',
    //         width: '400px',
    //         data: {
    //           distributors: result.data
    //         }
    //       });
    //     } else {
    //       this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
    //     }
    //   })

    // this.dialogRef.afterClosed()
    //   .subscribe(response => {
    //     if (response) {
    //       // this._addVerifierService.getAssignedEvents(this.verifierId).then(() => {
    //       //   this.dataSource.data = this._addVerifierService.assignedEvents
    //       // })

    //     } else
    //       return;

    //   });
    // }

}



