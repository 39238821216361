import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/main/pages/auth.service';
import { HttpClient } from '@angular/common/http';
import { ReqHeader } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddSubUserService implements Resolve<any>{
  allCountries: any = []
  states: any = []
  roles: any = []
  permissions: any
  subUser: any = ''
  subUserId: any
  constructor(
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matDialog: MatDialog,
    private _router: Router
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route)
    this.subUserId = route.params.id
    return new Promise((resolve, reject) => {
      if (this.subUserId) {
        Promise.all([
          this.getSubUser(this.subUserId),
          this.getAllroles(),
          this._commonService.getAllCountries().then((countries:any) => {
            if(countries.length)
            this.allCountries = countries
            else
            this.allCountries = countries
          })
        ]).then(
          () => {
            resolve('');
          },
          reject
        );
      } else {
        Promise.all([
          this.getAllroles(),
          this._commonService.getAllCountries().then((countries:any) => {
            if(countries?.length)
              this.allCountries = countries
              else
              this.allCountries = countries
          })
        ]).then(
          () => {
            this.subUserId = ''
            this.subUser = ''
            resolve('');
          },
          reject
        );
      }
    });
  }

  getSubUser(id) {
    const payload = {
      "subUserId": id
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/subuser/getbyid', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          if (response.status == 200) {
            this.subUser = response.data;
            if (response.country)
              this._commonService.getStates(response.data.country).then((result: any) => {
                this.states = result.data
                resolve(response);
              })
            else
              resolve(response)
          } else {
            this.subUser = ''
          }
        }, reject);
    });
  }

  onDelete() {
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to remove this user?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete User?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    })
    
    dialog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._fuseProgressBarService.show();
        const payload = {
          "subUserId": this.subUserId
        }
        return new Promise((resolve, reject) => {
          this._httpClient.post(environment.apiURL + 'admin/subuser/delete', payload, this._commonService.getHeader())
            .subscribe((response: any) => {
              this._fuseProgressBarService.hide();
              if (response.status == 200) {
                this._matSnackBar.open(response.message, 'OK', {
                  verticalPosition: 'bottom',
                  duration: 4000
                })
                this._router.navigate(['/subuser'])
              }
            }, reject);
        });
      }
    });
  }
  
  getAllroles() {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + 'admin/role/getall', this._commonService.getHeader())
        .subscribe((response: any) => {
          if (response.status == 200) {
            this.roles = response.data.roles;
            resolve(response);
          }else{
            this.roles = null;
            resolve(response);
          }
        }, reject);
    });
  }

  onAddSubUser(payload) {

    // let formData = new FormData()
    // formData.append('permissionId', payload.permissionId)
    // formData.append('gender', payload.gender)
    // // formData.append('country', payload.country)
    // // formData.append('state', payload.state)
    // // formData.append('city', payload.city)
    // // formData.append('birthDate', (+ new Date(payload.birthDate)).toString())
    // formData.append('mobile', payload.mobile)
    // formData.append('email', payload.email)
    // formData.append('name', payload.name)
    // formData.append('profile', payload.profile)
    // formData.append('password',payload.password)

    const dataPayload = {
      permissionId: payload.permissionId,
      gender: payload.gender,
      // formData.append('country', payload.country)
      // formData.append('state', payload.state)
      // formData.append('city', payload.city)
      // formData.append('birthDate', (+ new Date(payload.birthDate)).toString())
      mobile: payload.mobile,
      email: payload.email,
      name: payload.name,
      profile: payload.profile,
      password:payload.password,
    }

    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/subuser/add', dataPayload, this._commonService.getHeader()).subscribe((response: any) => {
          console.log('sub user resp', response)
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000
          })
          if (response.status == 200) {
            resolve('')
            this._router.navigate(['/subuser'])
          }
        }, reject);
    });
  }
  onEditSubUser(payload) {

    // let formData = new FormData()
    // formData.append('subUserId', this.subUserId)     //it will discuess with harish
    // formData.append('permissionId', payload.permissionId)
    // formData.append('gender', payload.gender)
    // // formData.append('country', payload.country)
    // // formData.append('state', payload.state)
    // // formData.append('city', payload.city)
    // // formData.append('birthDate', (+ new Date(payload.birthDate)).toString())
    // formData.append('mobile', payload.mobile)
    // formData.append('email', payload.email)
    // formData.append('name', payload.name)
    // formData.append('profile', payload.profile)

    const dataPayload = {
      permissionId: payload.permissionId,
      subUserId: this.subUserId,     //it will discuess with harish
      gender: payload.gender,
      // formData.append('country', payload.country)
      // formData.append('state', payload.state)
      // formData.append('city', payload.city)
      // formData.append('birthDate', (+ new Date(payload.birthDate)).toString())
      mobile: payload.mobile,
      email: payload.email,
      name: payload.name,
      profile: payload.profile,
      password:payload.password,
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/subuser/edit', dataPayload, this._commonService.getHeader()).subscribe((response: any) => {
          console.log('sub user edit resp', response)
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000
          })
          if (response.status == 200) {
            resolve('')
            this._router.navigate(['/subuser'])
          }
        }, reject);
    });
  }

}
