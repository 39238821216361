import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { AddTicketTypeDialogComponent } from '@fuse/Dialog/add-ticket-type-dialog/add-ticket-type-dialog.component';
import { FormGroup } from '@angular/forms';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { TicketTypeService } from '../ticket-type.service';
import { result } from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations : fuseAnimations
})
export class ListComponent implements OnInit, AfterViewInit {
  dialogRef;

  displayedColumns: string[] = ['icon', 'type', 'desc'];
  dataSource;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  maxAvailableRecordsInTable;
  
  constructor(
    private _matDialog: MatDialog,
    public _ticketTypeService: TicketTypeService,
    private _ToolbarService : ToolbarService

  ) {

    this.setToolBarTitle();

    if (this._ticketTypeService.permissions.delete){
      this.displayedColumns.push('action')
    }
    this.dataSource = new MatTableDataSource(this._ticketTypeService.allTicketTypes);
    this.maxAvailableRecordsInTable =  this._ticketTypeService.maxAvailableRecordsInTable;
  }

  ngOnInit() {
   
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Manage Ticket Type');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  } 
  
  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  ngAfterViewInit() {
    // this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }

  // updateTable() {
  //   this._ticketTypeService.getAllTicketType().then(() => {
  //     this.dataSource = new MatTableDataSource(this._ticketTypeService.allTicketTypes);
  //     this.dataSource.paginator = this.paginator;
  //   })
  // }
  openAddTicketTypeDialog(id?): void {
    let selectedTicketType = []
    if (id) {
      selectedTicketType = this._ticketTypeService.allTicketTypes.filter((type) => {
        return type.id == id
      })
    }

    this.dialogRef = this._matDialog.open(AddTicketTypeDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '350px',
      data: { ticketType: selectedTicketType[0] }
    });

    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (response)
        this.filterData()
      });
  }

  onDelete(id) {
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Ticket Type?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Ticket Type?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    
    dialog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._ticketTypeService.deleteTicketType(id).then(() => {
           let lastDigitOfPage = this.maxAvailableRecordsInTable.toString().slice(-1);
          if (lastDigitOfPage == 1){
            if(this.filter.pageIndex!=1){
            this.filter.pageIndex = this.filter.pageIndex - 1;
            this.paginator.pageIndex = this.filter.pageIndex - 1;
            }
          }        
          this.filterData()
        })
      }
    });
  }
  

  filterData() {
    const payload = {
      pageIndex: this.filter.pageIndex,  
      pageSize: this.filter.pageSize,
    }
    this._ticketTypeService.getAllTicketType(payload).then((result: any) => {
      if (result.status == 200) { 
        this.dataSource.data = result.data.ticketTypes;  
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }
      else if (result.status == 400) { 
        this.dataSource.data = []  
        this.maxAvailableRecordsInTable = 0;
      }
      else{
        this.dataSource.data = []  
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }
  
  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }
}
