import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/main/pages/auth.service';
import { CommonService } from '@fuse/services/common.service';
import { EventListService } from 'app/main/events/events-list/event-list.service';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { OrderService } from '../order.service';
import { forEach } from 'lodash';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AddOrderService implements Resolve<any>{
  permissions: any;
  allCountries: any = [];
  eventList: any;
  states: any = []
  memberStates: any = []
  allTickets: any = [];
  ticketPrice: number = 0

  constructor(
    private _authService: AuthService,
    private _commonService: CommonService,
    private _eventListService: EventListService,
    private _httpClient: HttpClient,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    private _router: Router,
    private _orderService: OrderService
  ) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    if (!this._orderService.selectedEventData) {
      this._router.navigate(['/orders'])
      return false
    }

    this.permissions = this._authService.getPermissionsForCurrentPage(route);

    return new Promise((resolve, reject) => {
      Promise.all([
        this._commonService.getAllCountries().then((result:any) => {
          if(result.length)
            this.allCountries = result
            else
            this.allCountries = result
        }),
        this.getTicketType()

      ]).then(
        () => {
          resolve('');
        },
        reject
      );

    });
  }

  onSelectCountry(e, index?, type?) {
    this._commonService.getStates(e.value).then((result: any) => {
      if (type)
        this.memberStates[index]=result.data
      else
        this.states = result.data
    })
  }

  getTicketType() {
    return new Promise((resolve, reject) => {
      const payload = {
        eventId: this._orderService.selectedEventData.id,
        pageSize : 0,
        pageIndex : 0
      }
      this._httpClient.post(environment.apiURL + 'admin/tickets/getalltickets', payload, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          this.allTickets = result.data.data
          resolve(result)
        }
        else if(result.status == 400){
          this.allTickets = []
          // this.maxAvailableRecordsInTable = 0;
          resolve(result)
        }
         else {
          this._matSnackBar.open('Tickets Not available for selected event', 'OK', { duration: 4000 })
          this.allTickets = []
        }
      }, reject)
    })
  }

  onChangeTicketType(e) {
    let index = this.allTickets.findIndex(ticket => ticket.id == e.value)
    this.ticketPrice = this.allTickets[index].price
  }

  // placeOrder(data) {
  //   console.log(data);
    
  //   return new Promise((resolve, reject) => {
  //     this._fuseProgressBarService.show()
  //     data.adminId = parseInt(localStorage.getItem('id'))
  //     data.eventId = this._orderService.selectedEventData.id
  //     data.isBulk = 0

  //     console.log('form data is ', JSON.stringify(data))
  //     this._httpClient.post(environment.apiURL + 'admin/distributor/create/order', data, this._commonService.getHeader()).subscribe((result: any) => {
  //       this._fuseProgressBarService.hide()
  //       if (result.status == 200) {
  //         this._router.navigate(['/orders'])
  //         resolve(result)
  //       } else {
  //         this.allTickets = []
  //       }
  //       this._matSnackBar.open(result.message, 'OK', { duration: 2000 })
  //     }, reject)
  //   })
  // }

  placeOrder(payload) {
    this._fuseProgressBarService.show();
     
    return new Promise((resolve, reject) => {
      // data.adminId = parseInt(localStorage.getItem('id'))
      // const members = data['members'] 
      // const memIndex = members.length;

      // for (let i = 0; i < memIndex; i++) {
      //   const element = members[i].ticketTypeId;
      //   delete members[i].ticketTypeId; 
      // }
 
      // const payload = {
      // eventId : this._orderService.selectedEventData.id,
      // totalAmount : grandTotal, 
      // ticketDetail :[ 
      //   {
      //     ticketId: ticketTypeId,
      //     ticketAmount: ticketPrice,
      //     slotId: slotId,
      //     members
      //     }
      //   ]
      // } 
 
      this._httpClient.post(environment.apiURL + 'admin/tickets/createOrder', payload, this._commonService.getHeader()).subscribe((result: any) => {
        this._fuseProgressBarService.hide()
        if (result.status == 200) {
          resolve(result)
        } else {
          this.allTickets = []
          resolve(result)
        }
        this._matSnackBar.open(result.message, 'OK', { duration: 4000 })
      }, reject)

    })
  }

}
