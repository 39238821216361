import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MultipleDatepickerComponent } from '../event-multiple-datepicker-dialog/multiple-datepicker/multiple-datepicker.component';
import { SpeakerService } from 'app/main/speaker/speaker.service';
import { Enums, darkTheme } from '@fuse/utils/systemEnums';
import { AddEditSpeakerComponent } from 'app/main/speaker/add-edit-speaker/add-edit-speaker.component';
import { AddPerformerComponent } from '../add-performer/add-performer.component';

@Component({
  selector: 'app-add-schedules',
  templateUrl: './add-schedules.component.html',
  styleUrls: ['./add-schedules.component.scss']
})
export class AddSchedulesComponent implements OnInit {
  darkTheme = darkTheme;
  form : UntypedFormGroup;
  allSpeakersList = [];
  enums = Enums;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: UntypedFormBuilder,
    public _matDialogRef: MatDialogRef<MultipleDatepickerComponent>,
    private _matDialog: MatDialog,
    private _SpeakerService : SpeakerService,
    private cd: ChangeDetectorRef
  ) {

    // GET ALL SPACKER LISTING...
    this.getAllSpackerList();
  }

  // ON DAILOG IN IT
  ngOnInit(): void {
     // CREATE FORM
     this.form = this.createFrom();
     // CREATE DYNAMIC FORM ACCORDING TO EVENT'S DATE'S
    if(this._data.days.length){
      this.createDynamicForm();
    }else if(this._data.performerSchedule.length){
      this.createDynamicFormWithScheduleData();
      this.setPerformerScheduleInEditCase();
    }
     // console.log("this is form status", this.form.get('schedules')['controls'][0]['controls'].scheduleData['controls'][0]['controls']); 
    // if(this._data.performerSchedule.length){
    //   this.setPerformerScheduleInEditCase();
    // }
  }

  getAllSpackerList(){
    this._SpeakerService.getSpeakersListData({"pageIndex":0,"pageSize":0}).then((result:any)=> {
      if(result.status == 200){
        this.allSpeakersList = result.data.data;
      }else{
        this.allSpeakersList = [];
      }
    })
  }

  // FOR FORM ARRAY LOOP IN UI
  get schedules(): UntypedFormArray {
    return this.form.controls["schedules"] as UntypedFormArray;
  }
  
  // CREATE FORM FOR IN IT TIME
  createFrom(){
    return this._fb.group({
      schedules : this._fb.array([])
    })
  }

  // CREATE FORM DYNAMICLY ACCORDING TO DATE'S
  createDynamicForm(){
    this._data.days.forEach(element => {
      (<UntypedFormArray>this.form.get("schedules")).push(this._fb.group({
        activeDate   : [element],
        scheduleData : this._fb.array([])
      }));
    });
  }

  createDynamicFormWithScheduleData(){
    this._data.performerSchedule.forEach((item)=>{
      (<UntypedFormArray>this.form.get("schedules")).push(this._fb.group({
        activeDate   : [item?.slotDate],
        scheduleData : this._fb.array([])
      }));
    })
  }

  setPerformerScheduleInEditCase(){
    this._data.performerSchedule.forEach((item, i) => {
      item.activitesList.forEach((element)=>{
        this.onAddScheduleObjInFrom(i, element);
      })
    })
  }

  // ADD SCHEDULE FORM FIELDS INTO FORM ARRAT. 
  // FUNCTION CALL - CLICK ON 'Add New Schedule for DATE'
  onAddScheduleObjInFrom(i, item?){
    (<UntypedFormArray>this.form.get('schedules')['controls'][i]['controls'].scheduleData.push(this.createSchules(item)))
  }

  // FUNCTION THAT RETURN SCHEDULE FIELDS OBJECT
  createSchules(item?){
    if(this._data.performerSchedule.length){
      return this._fb.group({
        title           : [item?.title ? item?.title : '', Validators.required],
        description     : [item?.description ? item?.description : ''],
        startTime       : [item?.startTime ? this.formatTime(item?.startTime) : '', Validators.required],
        endTime         : [item?.endTime ? this.formatTime(item?.endTime) : '', Validators.required],
        performerList   : [item?.performerList?.length ? item?.performerList :''],
        performerLabel  : [item?.performerLabel ? item?.performerLabel : '']
      })
    }else if(this._data.days.length){
      return this._fb.group({
        title           : ['', Validators.required],
        description     : [''],
        startTime       : ['', Validators.required],
        endTime         : ['', Validators.required],
        performerList   : [''],
        performerLabel  : ['']
      })
    }
  }

  formatTime(timestamp) {
    const date = new Date(Number(timestamp));
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // 0 should be displayed as 12
    minutes = minutes < 10 ? 0 + minutes : minutes;
    const formattedTime = hours + ':' + minutes + ' ' + ampm;
    return formattedTime;
  }

  /* FUNCATION FOR CONVERTING DATESTIME INTO TIMESTAMP */
  toTimestamp = (strDate) => {
    const dt = Date.parse(strDate)
    return Number(dt / 1000 + '000');
  };

  onSubmit(){
    if(this.form.valid){
      const performerSchedule = []
      console.log("this.form.valid", this.form.value);
      this.form.value.schedules.filter((item) => {
        // if(item?.scheduleData?.length){
          const activityObj = {}
          activityObj['slotDate'] = new Date(item.activeDate).getTime();
          item.scheduleData.forEach((element) => {
            if(String(element['startTime']).endsWith("AM") || String(element['startTime']).endsWith("PM")){
              element['startTime'] = this.getTimeStamp(element['startTime'])
            }
            if(String(element['endTime']).endsWith("AM") || String(element['endTime']).endsWith("PM")){
              element['endTime'] = this.getTimeStamp(element['endTime'])
            }
          })
          activityObj['activitesList'] = item.scheduleData;
          performerSchedule.push(activityObj);
        // }
      })
      console.log("performerSchedule", performerSchedule);
      this._matDialogRef.close(performerSchedule)
    }
  }
  
  getTimeStamp(strTime){
      // Input time in "4:23 AM" format
      const timeString = strTime;

      // Create a new Date object with the current date
      const currentDate = new Date();

      // Extract the hour and minute values from the time string
      const [time, period] = timeString.split(' ');
      const [hours, minutes] = time.split(':').map(Number);

      // Adjust the hour based on the period (AM/PM)
      let adjustedHours = hours;
      if (period === "PM" && hours !== 12) {
        adjustedHours += 12;
      } else if (period === "AM" && hours === 12) {
        adjustedHours = 0;
      }

      // Set the hour and minute values to the current date object
      currentDate.setHours(adjustedHours);
      currentDate.setMinutes(minutes);

      // Get the timestamp in milliseconds
      const timestamp = currentDate.getTime();


      return timestamp
  }

  // ON TABS CHANGE CALL ON SELECTION CHANGE
  onTabChange(event){

  }

  // on Remove Schedule
  onRemoveSchedule(i,j){
    (<UntypedFormArray>this.form.get('schedules')['controls'][i]['controls'].scheduleData.removeAt(j))
  }

  openAddPerformerDialog(){
    this._matDialog.open(AddPerformerComponent, {
      panelClass: "no-padding-dialog",
      // maxWidth : '960px',
      // minWidth : '960px',
      autoFocus : false,
      data : {
        isDialog : true
      }
    }).afterClosed().subscribe((result:any) => {
      if(result){
        this.getAllSpackerList()
      }
    })
  }

}
