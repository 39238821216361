import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { UploadTicketCodesService } from './upload-ticket-codes.service';
import { fuseAnimations } from '@fuse/animations';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ImportContactDialogComponent } from '@fuse/Dialog/import-contact-dialog/import-contact-dialog.component';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-upload-ticket-codes',
  templateUrl: './upload-ticket-codes.component.html',
  styleUrls: ['./upload-ticket-codes.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations : fuseAnimations
})
export class UploadTicketCodesComponent implements OnInit {

  selectedEventData;
  categoriesList;
  form:FormGroup;
  slotTicket;
  selectedTicketType
  timeSlots = [];
  allTickets = [];
  allOrders:any = []
  qrAndBarCodeList = []
  importOrderType = [
    {id : 1, title : 'Tickets'},
    {id : 2, title : 'Orders'},
  ]
  showTypeOption:boolean = false;
  importOptions = []

  constructor(
    private _AddEditEventService: AddEditEventService,
    public  _UploadTicketCodesService : UploadTicketCodesService,
    private _matsnakebar : MatSnackBar,
    private _fb : FormBuilder,
    private _ToolbarService : ToolbarService,
    private _matDialog : MatDialog,
    private _commonservice : CommonService,
    private _FuseProgressBarService : FuseProgressBarService
  ) { 
    this.setToolBarTitle();
    this.createForm()
  }

  ngOnInit(): void {
    this.form.get('importType').valueChanges.subscribe((res)=>{
      if(res){
        // this.addMapFields()
      }
    })
  }

  setToolBarTitle(){
      // for title
      this._ToolbarService._ToolBarTitle.next('Import QR & Bar Code');
      // for btn
      this._ToolbarService._isBackBtnShow.next(false);
  }

  createForm(){
    this.form = this._fb.group({
      selectedEvent : ['', Validators.required],
      importType : ['1', Validators.required],
      selectedDate  : ['', Validators.required],
      timeSlot : ['', Validators.required],
      ticketType : ['', Validators.required],
    })
  }

  // EVENT SELECTION
  onEventSelect(value){
    this._FuseProgressBarService.show();
    this.form.controls.selectedDate.setValue('');
    this.form.controls.importType.setValue('1')
    this.form.controls.selectedDate.setValue('')
    this.form.controls.timeSlot.setValue('')
    this.form.controls.ticketType.setValue('')
    this.form.updateValueAndValidity();
    this.timeSlots = undefined;
    this.slotTicket = undefined;
    this.categoriesList = undefined;
    this.qrAndBarCodeList = [];
    this.selectedTicketType = undefined;
    this.importOptions = [];
    this.allTickets = []
    this.allOrders = [];
    this._AddEditEventService.getEvent(value).then((res: any) => {
      if (res.status == 200) {
          this._FuseProgressBarService.hide();
          this.selectedEventData = res.data;
          console.log("this is your selected evnet details", this.selectedEventData);
          let eventCate = [];
          this.selectedEventData.categoriesList.length ? this.selectedEventData.categoriesList.forEach((item) => eventCate.push(item.title)): eventCate = [];
          this.categoriesList = eventCate.join(', ');
          this.getAllTickets()
      }else{
        this._matsnakebar.open(res.message, 'OKAY', {duration : 2000})
      }
    });
  }

  // on select event date
  onSelectEventDate(value){
    let selectedDate =  this.selectedEventData?.eventSchedule.filter(item => item.eventStartDate === value)[0]
    this.form.controls.selectedDate.setValue(selectedDate.eventStartDate)
    this.form.updateValueAndValidity();
    this.timeSlots = selectedDate.timeSlot;
  }

  getAllTickets(){
    this._UploadTicketCodesService.getAllTickets({eventId:this.selectedEventData.id}).then(((res:any)=>{
      if(res.status == 200){
        this._FuseProgressBarService.hide();
        this.allTickets = res.data.data;
        console.log("this.allTickets", this.allTickets);
      }else{
        this._matsnakebar.open(res.message, 'OKAY', {duration : 2000})
      }
    }))
  }

  // on select event slot
  onSelectEventSlot(value){
    if(this.allTickets.length){
      this.slotTicket = this.allTickets.filter((item) => item.slotId == value);
    }
  }

  onSelectTicketType(value){
    this.selectedTicketType = this.slotTicket.filter((item) => item.id == value)[0]
    console.log("this.selectedTicketType == >", this.selectedTicketType);
    if(!this.selectedTicketType?.totalAvailableSeats){
      this.form.get('importType').setValue('2');
    }else{
      this.form.get('importType').setValue('1');
    }
    this.getOrdersForTickets();
  }

  // getOrders For Upload BAR & QR
  getOrdersForTickets(){
    this._FuseProgressBarService.show();
    const payload = {
      eventId : this.selectedEventData.id,
      ticketId : this.selectedTicketType.id,
      slotId : this.selectedTicketType.slotId,
    }
    this._UploadTicketCodesService.getOrdersForTickets(payload).then((res:any)=>{
      if(res.status == 200){
        this._FuseProgressBarService.hide();
        this.allOrders = res.data;
        this.addMapFields()
      }else{
        this._FuseProgressBarService.hide();
        this.allOrders = undefined;
        this.addMapFields()
      }
    })
  }
 

  // remove order id from map fields
  addMapFields(){
    console.log("labelCode", this.selectedTicketType.labelCode);
    console.log("importType')--->", this.form.get('importType').value);
    const lableCode = this.selectedTicketType.labelCode ? this.selectedTicketType.labelCode : 1;
    if(this.form.get('importType').value == 1){
      switch (lableCode) {
        case 1:
          this.importOptions = []
          this.importOptions.push(
              {
                id : 2, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Bar Code", createField : "BarCode", name : "Bar Code"
              }
          )
          break;
        case 2:
          this.importOptions = []
          this.importOptions.push(
              {
                id : 3, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "QR Code", createField : "QRCode", name : "QR Code"
              }
          )
          break;
        case 3:
          this.importOptions = []
          this.importOptions.push(
              {
                id : 2, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Bar Code", createField : "BarCode", name : "Bar Code"
              },
              {
                id : 3, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "QR Code", createField : "QRCode", name : "QR Code"
              }
          )
          break;
      }
    }
    else{
      switch (lableCode) {
        case 1:
          this.importOptions = []
          this.importOptions.push(
              {
                id : 1, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Order Id", createField : "OrderId", name : "Order Id"
              },
              {
                id : 2, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Bar Code", createField : "BarCode", name : "Bar Code"
              }
          )
          break;
        case 2:
          this.importOptions = []
          this.importOptions.push(
              {
                id : 1, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Order Id", createField : "OrderId", name : "Order Id"
              },
              {
                id : 3, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "QR Code", createField : "QRCode", name : "QR Code"
              }
          )
          break;
        case 3:
          this.importOptions = []
          this.importOptions.push(
              {
                id : 1, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Order Id", createField : "OrderId", name : "Order Id"
              },
              {
                id : 2, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "Bar Code", createField : "BarCode", name : "Bar Code"
              },
              {
                id : 3, isAdded : 1, isDelete : 0, isDisable : false, isRequired : 1, title : "QR Code", createField : "QRCode", name : "QR Code"
              }
          )
          break;
      }
    }
    console.log("map array", this.importOptions);
  }

  openImportContectDialog(){
    let mapFieldsNames = [];
    if(!mapFieldsNames.length){
      this.addMapFields();
      mapFieldsNames = JSON.parse(JSON.stringify(this.importOptions));
        console.log("mapFieldsNames", mapFieldsNames);
    }
    
    this._matDialog.open(ImportContactDialogComponent, {
      panelClass: ['addGroup', 'commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '68%',
      minHeight: '230px',
      autoFocus : false,
      data : {
        feilds : mapFieldsNames,
        for : 'ticketCode',
        matchIndex : this.form.get('importType').value == '2' ? this.allOrders.totalOrders : this.selectedTicketType?.totalAvailableSeats,
        importType : this.form.get('importType').value
      }
    }).afterClosed().subscribe((uploadStatus) => {
      if(uploadStatus){
        console.log("uploadStatus", uploadStatus);
        this.qrAndBarCodeList = uploadStatus;
      }
    })
  }

  importCodes(){
    console.log("hit final submit", this.qrAndBarCodeList);
    console.log("this.form.get('importType')?.value", this.form.get('importType')?.value);
    const payload = {
      eventId : this.selectedEventData.id,
      ticketId : this.selectedTicketType.id,
      slotId : this.form.controls.timeSlot.value,
      customQrCode : []
    }
    // check which is the case
    if(this.form.get('importType')?.value && this.form.get('importType')?.value == '1'){ 
      this.qrAndBarCodeList.forEach((item) => payload['customQrCode'].push({qrcode : item?.QRCode || '', barCode : item.BarCode || ''}))
      // ticket case
      console.log("this is final payload", payload);
      this._commonservice.importQRANDBarCodeForTicket(payload).then((res:any)=>{
        if(res.status == 200){
          this._matsnakebar.open(res.message, 'OKAY',{duration : 2000})
        }else{
          this._matsnakebar.open(res.message, 'OKAY',{duration : 2000})
        }
      })
    }else{
      // order case 
      this.qrAndBarCodeList.forEach((item) => payload['customQrCode'].push({qrcode : item?.QRCode || '', barCode : item.BarCode || '', orderId : item.OrderId}))
      console.log("this is the final payload", payload);
      this._commonservice.importQrcodesForOrders(payload).then((res:any)=>{
        if(res.status == 200){
          this._matsnakebar.open(res?.message, 'OKAY',{duration : 2000})

          // reset the all values
          this.form.controls.selectedDate.setValue('');
          this.form.controls.importType.setValue('1');
          this.form.controls.selectedEvent.setValue('');
          this.form.controls.timeSlot.setValue('');
          this.form.controls.ticketType.setValue('');
          this.selectedEventData = undefined;
          this.form.updateValueAndValidity();
          this.timeSlots = undefined;
          this.slotTicket = undefined;
          this.categoriesList = undefined;
          this.qrAndBarCodeList = [];
          this.selectedTicketType = undefined;
          this.importOptions = [];
          this.allTickets = []
          this.allOrders = [];

        }else{
          this._matsnakebar.open(res?.message, 'OKAY',{duration : 2000})
        }
      })
    }
  }
  
}
