
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter,ContentChild, TemplateRef } from "@angular/core";
import { calculateViewDimensions } from "@swimlane/ngx-charts";
import { ColorHelper } from "@swimlane/ngx-charts";
import { BaseChartComponent } from "@swimlane/ngx-charts"


@Component({
  selector: 'app-custom-pie-chart',
  templateUrl: './custom-pie-chart.component.html',
  styleUrls: ['./custom-pie-chart.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})


export class CustomPieChartComponent  extends BaseChartComponent  {


  @Input() labels = false;
  @Input() legend = false;
  @Input() legendTitle: string = "Legend";
  @Input() legendPosition: string = "right";
  @Input() explodeSlices = false;
  @Input() doughnut = false;
  @Input() arcWidth = 0.25;
  @Input() gradient: boolean;
  @Input() activeEntries: any[] = [];
  @Input() tooltipDisabled: boolean = false;
  @Input() labelFormatting: any;
  @Input() trimLabels: boolean = true;
  @Input() maxLabelLength: number = 10;
  @Input() tooltipText: any;
  @Input() showPercentage: boolean = true;
  @Input() colorPercentage: string = "#FFFFFF";

  @Output() dblclick = new EventEmitter();
  @Output() select = new EventEmitter();
  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild("tooltipTemplate") tooltipTemplate: TemplateRef<any>;
  translation: string;
  outerRadius: number;
  innerRadius: number;
  data: any;
  colors: ColorHelper;
  domain: any;
  dims: any;
  margin = [20, 20, 20, 20];
  legendOptions: any;


 

  ngOnInit(): void {
  }


  update(): void {
    super.update();

    if (this.labels) {
      this.margin = [30, 80, 30, 80];
    }

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showLegend: this.legend,
      legendPosition: this.legendPosition
    });

    const xOffset = this.margin[3] + this.dims.width / 2;
    const yOffset = this.margin[0] + this.dims.height / 2;
    this.translation = `translate(${xOffset}, ${yOffset})`;
    this.outerRadius = Math.min(this.dims.width, this.dims.height);
    if (this.labels) {
      // make room for labels
      this.outerRadius /= 3;
    } else {
      this.outerRadius /= 2;
    }
    this.innerRadius = 0;
    if (this.doughnut) {
      this.innerRadius = this.outerRadius * (1 - this.arcWidth);
    }

    this.domain = this.getDomain();

    // sort data according to domain
    this.data = this.results.sort((a, b) => {
      return this.domain.indexOf(a.name) - this.domain.indexOf(b.name);
    });

    this.setColors();
    this.legendOptions = this.getLegendOptions();
  }
  getDomain(): any[] {
    const items = [];

    this.results.map(d => {
      let label = d.name;
      if (label.constructor.name === "Date") {
        label = label?.toLocaleDateString();
      } else {
        label = label?.toLocaleString();
      }

      if (items.indexOf(label) === -1) {
        items.push(label);
      }
    });

    return items;
  }

  onClick(data): void {
    this.select.emit(data);
  }

  setColors(): void {
    this.colors = new ColorHelper(
      this.scheme,
      "ordinal",
      this.domain,
      this.customColors
    );
  }

  getLegendOptions() {
    return {
      scaleType: "ordinal",
      domain: this.domain,
      colors: this.colors,
      title: this.legendTitle,
      position: this.legendPosition
    };
  }

  onActivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });
    if (idx > -1) {
      return;
    }

    this.activeEntries = [item, ...this.activeEntries];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(item) {
    const idx = this.activeEntries.findIndex(d => {
      return d.name === item.name && d.value === item.value;
    });

    this.activeEntries.splice(idx, 1);
    this.activeEntries = [...this.activeEntries];

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }

}
