import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CreateOrderService implements Resolve<any>{
  upcommingPublishedEvents = []
  allCountries:any;
  allStates;
  
  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("new route", route);
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getUpcommingPublishedEvents(), 
      ]).then(
        () => {
          resolve('');
        },
        reject
      );

    });
  }


  getUpcommingPublishedEvents() {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallpublishedevents', {}, this._commonService.getHeader()).subscribe((result: any) => {
        if (result.status == 200) {
          this.upcommingPublishedEvents = result.data.data;
          resolve(result)
        }
        else if (result.status == 400) {
          this.upcommingPublishedEvents = [];
          resolve(result)
        }
         else {
          this.upcommingPublishedEvents = [];
        }
      }, reject)
    })
  }

  
  createOrder(data){
    return new Promise((resovle, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/createOrder', data, this._commonService.getHeader()).subscribe((result: any) => {
        resovle(result)
      }, reject)
    })
  }
  
  
}
