import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { Observable } from 'rxjs';
import { promise } from 'protractor';
import { reject } from 'lodash';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvitationsService {
  invitationListingData;
  maxAvailableRecordsInTable;
  groupList;
  invitationDetail: any;
  eventListing: any;
  invitationSendList: any;
  maxAvailableRecordsInTableSendList: any;

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,    
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,

  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("route form invitation page", route);
    return new Promise((resolve,reject) => {
      if(route.params?.id){
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getInvitationById({id:route.params?.id}),
          this.getAllrunningEventsList({listingType:2, pageSize : 0 , pageIndex : 0})
        ]).then(
          () => {
            resolve(''); 
            this._fuseProgressBarService.hide();
          },
          reject
        );
      }
      else if(route?.routeConfig?.path == 'create-new-invitation'){
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getAllrunningEventsList({listingType:2, pageSize : 0 , pageIndex : 0})
        ]).then(
          () => {
            resolve(''); 
            this._fuseProgressBarService.hide();
          },
          reject
        );
      }
      else{
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getInvitationListing({pageSize:10, pageIndex:1}),
          this.getInvitationHistory({pageSize:10, pageIndex:1})
          // this.getAllGroup()
        ]).then(
          () => {
            resolve(''); 
            this._fuseProgressBarService.hide();
          },
          reject
        );
      }
    })
  }

  getInvitationListing(filter?){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/invitationlisting',filter?filter:{}, this._commonService.getHeader()).subscribe((result: any) => {
        if(result.status == 200){
          this.invitationListingData = result.data;
          this.maxAvailableRecordsInTable = result.data.totalRecords;
        }else{
          this.invitationListingData = [];
          this.maxAvailableRecordsInTable = 0;
        }
        resolve(result)
      }, reject)
    })
  }

  getInvitationHistory(filter?){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/invitationSentList' ,filter?filter:{}, this._commonService.getHeader()).subscribe((result: any) => {
        if(result.status == 200){
          this.invitationSendList = result.data.invitationListing;
          this.maxAvailableRecordsInTableSendList = result.data.totalRecords;
        }else{
          this.invitationSendList = [];
          this.maxAvailableRecordsInTableSendList = 0;
        }
        resolve(result)
      }, reject)
    })
  }

  sendInvitation(payload){
    console.log("sendInvitation", payload);
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/sendInvitation',payload,this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          resolve(result)
        }
        this._matSnackBar.open(result.message, 'OK', {
          duration: 2000,
          verticalPosition: "bottom"
        })
      },reject)
    })
  }

  getAllGroup(){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getAllContactGroups',{"pageIndex":1,"pageSize":30,"search":""},this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          this.groupList = result.data.groupList;
        }else{
          this.groupList = [];
        }
        resolve(result)
      },reject)
    })
  }

  onDeleteInvitation(body){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/deleteInvitation',body,this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
        }else{
          this._matSnackBar.open(result.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }
        resolve(result);
      },reject)
    })
  }

  getInvitationById(body){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getInvitationById',body,this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          this.invitationDetail = result.data;
          resolve(result);
        }else{
          this.invitationDetail = '';
          this._matSnackBar.open(result.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }
      },reject)
    })
  }

  editInvitation(body){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/updateInvitation',body,this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          resolve(result);
        }
        this._matSnackBar.open(result.message, 'OK', {
          duration: 2000,
          verticalPosition: "bottom"
        })
      },reject)
    })
  }

  getAllrunningEventsList(body){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getAllrunningEvents',body,this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          this.eventListing = result.data.getAllrunningEvents
          resolve(result);
        }else{
          this._matSnackBar.open(result.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }
      },reject)
    })
  }

  getVenueDetailsByEvent(body){
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getEventVenueDetail',body, this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          this._fuseProgressBarService.hide()
          resolve(result)
        }else{
          this._matSnackBar.open(result.message, 'OKAY', {duration : 2000})
        }
      }, reject)
    })
  }

  getInvitationMessageInfomation(body){
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/invitationMemberList',body, this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          this._fuseProgressBarService.hide()
          resolve(result)
        }else{
          this._matSnackBar.open(result.message, 'OKAY', {duration : 2000})
        }
      }, reject)
    })
  }

  cancelInvitation(body){
    return new Promise((resolve,reject) => {
      this._httpClient.post(environment.apiURL + 'admin/cancelInvitation',body,this._commonService.getHeader()).subscribe((result:any) => {
        if(result.status == 200){
          resolve(result);
        }
        this._matSnackBar.open(result.message, 'OK', {
          duration: 2000,
          verticalPosition: "bottom"
        })
      },reject)
    })
  }
  
}
