import { Component, OnInit, ViewChild } from '@angular/core';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { ResourcesService } from '../resources.service';
import { MatDialog } from '@angular/material/dialog';
import { AddEditResourcesDialogComponent } from '@fuse/Dialog/add-edit-resources-dialog/add-edit-resources-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatPaginator } from '@angular/material/paginator';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.scss'],
  animations : fuseAnimations
})
export class ResourcesListComponent implements OnInit {
  dataSource;
  displayedColumns = ['title', 'description', 'mediaLink', 'action'];
  maxAvailableRecordsInTable: any;
  dialog;
  filter = {
    search: '',
    pageSize: 10,
    pageIndex: 1
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('search', { static: true }) search;

  constructor(
    private _ToolbarService: ToolbarService,
    private _resourcesService: ResourcesService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matsnackbar: MatSnackBar,
    private _matdialog: MatDialog,
    private _commonservice: CommonService,
  ) {
    this.setToolBarTitle();
  }

  ngOnInit(): void {
    this.dataSource = this._resourcesService.resourcesList;
    this.maxAvailableRecordsInTable = this._resourcesService.maxAvailableRecordsInTable;
  }

  setToolBarTitle() {
    // for title
    this._ToolbarService._ToolBarTitle.next('Resources');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  // openAddDialog
  openAddDialog() {
    this.dialog = this._matdialog.open(AddEditResourcesDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width: '450px',
      autoFocus: false,
      data: {
        case: 'add',
      }
    })

    this.dialog.afterClosed().subscribe((res) => {
      if (res) {
        this._resourcesService.getAllResources({ "searchData": "", "pageIndex": 1, "pageSize": 10 }).then((res: any) => {
          this.dataSource = res.data.resourseList;
          this.maxAvailableRecordsInTable = res.data.totalRecords;
        })
      }
    })
  }


  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.fetchDataInTable();
  }

  fetchDataInTable() {
    this._fuseProgressBarService.show();
    this._resourcesService.getAllResources(this.filter).then((res: any) => {
        if (res.status == 200) {
          console.log("getAllResources-->", res);
          this.dataSource = res.data.resourseList;
          this.maxAvailableRecordsInTable = res.data.totalRecords;
          this._fuseProgressBarService.hide();
        } else if (res.status == 400) {
          this.dataSource = [];
          this.maxAvailableRecordsInTable = 0;
        }
        else {
          this.dataSource = [];
          this.maxAvailableRecordsInTable = 0;
        }

    })
  }

  onSearch(val) {

    this.search.nativeElement.focus()
    if (val) {
      this.filter.pageIndex = 1
      this.filter.search = val
      this.fetchDataInTable();
    }
  }

  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.fetchDataInTable();
  }

  onViewResources(id) {
    this.dialog = this._matdialog.open(AddEditResourcesDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus: false,
      width: '450px',
      data: {
        case: 'view',
        data: this.dataSource.filter(item => item.id == id)[0]
      }
    })

    this.dialog.afterClosed().subscribe((res) => {
      if (res) {
        this._resourcesService.getAllResources({ "searchData": "", "pageIndex": 1, "pageSize": 10 }).then((res: any) => {
          this.dataSource = res.data.resourseList;
          this.maxAvailableRecordsInTable = res.data.totalRecords;
        })
      }
    })

  }

  onDeleteResources(id) {
    this.dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Resources?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Resources?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }

    })

    this.dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this._commonservice.deleteResoures({ "resoureId": id }).then((res: any) => {
          if (res.status == 200) {
            this._matsnackbar.open(res.message, 'OKAY', { duration: 2000 })
            this.fetchDataInTable();
          }else{
            this._matsnackbar.open(res.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

  onDeleteConfirm(id) {
    // this._cdeleteResoures
  }

  onEditResources(id) {
    this.dialog = this._matdialog.open(AddEditResourcesDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus: false,
      width: '450px',
      data: {
        case: 'edit',
        data: this.dataSource.filter(item => item.id == id)[0]
      }
    })
    this.dialog.afterClosed().subscribe((res) => {
      if (res) {
        this._resourcesService.getAllResources({ "searchData": "", "pageIndex": 1, "pageSize": 10 }).then((res: any) => {
          this.dataSource = res.data.resourseList;
          this.maxAvailableRecordsInTable = res.data.totalRecords;
        })
      }
    })
  }

  onOpenFile(path){
    window.open(path)
  }


}
