import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-add-edit-host-type',
  templateUrl: './add-edit-host-type.component.html',
  styleUrls: ['./add-edit-host-type.component.scss']
})
export class AddEditHostTypeComponent implements OnInit {

  mode : string;
  form:FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddEditHostTypeComponent>,
    private _fb: UntypedFormBuilder,
    public _commonService: CommonService
  ) {
    console.log("this is data", _data);
    this.mode = this._data.mode; 
   }

  ngOnInit(): void {
    if(this.mode == 'add'){
      this.createForm()
    }else if(this.mode == 'view'){
      this.createForm(this._data.hostType)
      this.form.disable();
    }else{
      this.createForm(this._data.hostType)
    }
  }

  createForm(data?){
    this.form = this._fb.group({
      id : [data ? data.id : ''],
      title : [data ? data.title : '', Validators.required]
    })
  }

  


}
