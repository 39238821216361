import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-payment-gateway-connected',
  templateUrl: './payment-gateway-connected.component.html',
  styleUrls: ['./payment-gateway-connected.component.scss']
})
export class PaymentGatewayConnectedComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<PaymentGatewayConnectedComponent>,
    private _commonservice : CommonService,
    private router: Router,
    private _matSnackBar : MatSnackBar
  ) { 
    console.log("_data", this._data);

  }

  ngOnInit(): void {
  }

  onSubmit(){
    const payload = {
      id : localStorage.getItem('id'),
      acctId : this._data.acctId
    }

    this._commonservice.stripeconnect(payload).then((res:any)=>{
      if(res.status == 200){
        localStorage.setItem('isStripeConnected', res.data.isStripeConnected);
        this._matSnackBar.open(res.message, 'OKAY', {duration : 2000})
        this.dialogRef.close();
        this.router.navigate(['/home'])
      }else{
        this._matSnackBar.open(res.message, 'OKAY', {duration : 5000})
      }
    })
  }

}
