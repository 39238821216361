import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddGroupDialogComponent } from '@fuse/Dialog/add-group-dialog/add-group-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { GroupsService } from '../groups.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '@fuse/services/common.service';
import { AddMoreFieldsComponent } from '@fuse/Dialog/add-more-fields/add-more-fields.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {

  displayedColumns: string[] = ['groupName', 'description','updatedOn', 'totalMembers', 'action'];
  maxAvailableRecordsInTable2: any;
  dialogRef;
  dataSource;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  allContact: any;

  @ViewChild('search', { static: true }) search;
  
  constructor(
    private route: Router,
    private _matDialog: MatDialog,
    private _commonService : CommonService,
    private _ToolbarService : ToolbarService,
    private _groupsService: GroupsService
  ) { this.allContact = this._groupsService.allContactsDetails
    this.setToolBarTitle()
  
  }

  ngOnInit(): void {
    this.dataSource  = new MatTableDataSource(this._groupsService.allGroupDetails);
    this.maxAvailableRecordsInTable2 = this._groupsService.maxAvailableRecordsInTable2
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Groups');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  addGroup(){
    // this.route.navigate(["/add"])
    this.dialogRef = this._matDialog.open(AddGroupDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus: false,
      width: '400px',
      disableClose : true,
      data: {
        // title: "Invite collegues",
        // allColleauge: this.colleaugeList,
        // selected: this.selectedId,
        // alreadySelected: this.alreadySelected
        allContacts: this.allContact
      }
    }).afterClosed().subscribe((result:any) => {
      console.log("This is add memeber dialog",result)
      if(result){
        this._groupsService.createGroup(result).then((val:any) => {
          if(val.status == 200){
            this.filterData();
          }
        });
      }
      
    })
  }


  updateGroupsList(){

  }

  allContacts(){
    this.route.navigate(["group/all-contacts"])
  }


  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.paginator.pageIndex = 0;
    this.filterData();
  }

  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    this._groupsService.getGroups(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.groupList
        this.maxAvailableRecordsInTable2 = result.data.totalRecords
      }
      else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable2 = 0;
      }
    })
  }

  onDeleteGroup(id){
    this.dialogRef = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Group?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Group?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    
    this.dialogRef.afterClosed().subscribe((result:any) => {
      if (result) {
        this._groupsService.deleteGroup(id).then((result:any) => {
          if(result.status == 200){
            this.filterData();         
          }
        });  
      }  
    });

  }

  getGroupById(id){
    this.route.navigate(["group/group-details/" + id ])
  }

  editGroupDetails(id){
    this.dataSource.filteredData.map((res:any) => {
      if(res.id == id){
        console.log("res", res);
        this.dialogRef = this._matDialog.open(AddGroupDialogComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          autoFocus: false,
          width: '400px',
          data: {
            groupDetails: res,
            allContacts: this.allContact
          }
        }).afterClosed().subscribe((dialogCloseResponse:any) => {
          if(dialogCloseResponse){
            this._groupsService.updataGroup(dialogCloseResponse).then((afterUpdateResponse:any) => {
              if(afterUpdateResponse){
                this.filterData();
              }
            })
          }
        });
      };
    }); 
  }

  onPageChange(event){
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  openFieldSetting(){
    this._commonService.getDynamicFieldList({}).then((result:any) => {
      if(result.status == 200 || result.status === 400){
         this._matDialog.open(AddMoreFieldsComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          disableClose: true,
          width : '620px',
          autoFocus : false,
          data : {
            fields : result.data
          }
          }).afterClosed().subscribe((fieldData) => {
              if(fieldData){
                  
              }
          })
      }
    })
  }
}
