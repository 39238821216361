import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import {
    Resolve,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
} from "@angular/router";
import { Observable } from "rxjs";
import {
    DefaultFilter,
} from "../../../../@fuse/utils/systemEnums";
import { FuseProgressBarService } from "@fuse/components/progress-bar/progress-bar.service";
import { EventTypeService } from "app/main/masters/event-type/event-type.service";
import { AuthService } from "app/main/pages/auth.service";
import { TicketTypeService } from "app/main/masters/ticket-type/ticket-type.service";
import { CommonService } from "@fuse/services/common.service";
import { environment } from "environments/environment";


@Injectable({
    providedIn: "root",
})
export class SlotBookingService implements Resolve<any> {
    events;
    totalEvents;
    allEventTypes;
    permissions: any;
    typeQueryParmas: any;
    allTicketTypes: any;
    private _authService: any;
    maxAvailableRecordsInTable: any;


   


    constructor(
        private _httpClient: HttpClient,
        private _fuseProgressBarService: FuseProgressBarService,
        private _commonService: CommonService,
    ) { }

    

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> | Promise<any> | any {
        this.permissions = this._authService.getPermissionsForCurrentPage(route);
        return new Promise((resolve, reject) => {
            Promise.all([]).then(() => {
                resolve("");
            }, reject);
        });
    }

    getAllTicketTypeSlot(payload?) {
        const myPayload = {
            pageIndex: 0,
            pageSize: 0,
        };
        return new Promise((resolve, reject) => {
            // this._fuseProgressBarService.show()
            this._httpClient
                .post(environment.apiURL + "tickets/getall/type", payload ? payload : myPayload, this._commonService.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        resolve(result);
                    } else if (result.status == 400) {
                        resolve(result);
                    }
                }, reject);
            });
    }
}
