import { NgModule } from '@angular/core';

import { LoginModule } from 'app/main/pages/authentication/login/login.module';

import { RegisterModule } from 'app/main/pages/authentication/register/register.module';

import { ForgotPasswordModule } from 'app/main/pages/authentication/forgot-password/forgot-password.module';

import { ResetPasswordModule } from 'app/main/pages/authentication/reset-password/reset-password.module';

import { LockModule } from 'app/main/pages/authentication/lock/lock.module';
import { MailConfirmModule } from 'app/main/pages/authentication/mail-confirm/mail-confirm.module';
import { Error404Module } from 'app/main/pages/errors/404/error-404.module';
import { Error500Module } from 'app/main/pages/errors/500/error-500.module';
import { ProfileModule } from 'app/main/pages/profile/profile.module';
import { LoginComponent } from './authentication/login/login.component';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { LockComponent } from './authentication/lock/lock.component';
import { AuthService } from './auth.service';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { RegisterComponent } from './authentication/register/register.component';
import { VerifyOtpModule } from './authentication/verify-otp/verify-otp.module';
import { VerifyOtpComponent } from './authentication/verify-otp/verify-otp.component';
import { VerifyService } from './verify.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRippleModule} from '@angular/material/core';
import { ForgotPasswordAdminComponent } from './authentication/forgot-password-admin/forgot-password-admin.component';
import { ForgotPasswordAdminModule } from './authentication/forgot-password-admin/forgot-password-admin.module';
import { CommonModule } from '@angular/common';
import { EmailVerificationComponent } from './authentication/email-verification/email-verification.component';




const authRoutes: Routes = [


    { path: 'login', component: LoginComponent },
    { path: 'send-admin-forgot-link', component: ForgotPasswordComponent },
    { path: 'forgot-password/:token', component: ForgotPasswordAdminComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'verify-email', component: VerifyOtpComponent },
    { path: 'reset-password/:id', component: ResetPasswordComponent },
    { path: 'email-verification/:token', component: EmailVerificationComponent },
    // {path: 'lock',component: LockComponent},
    // {
    //     path     : 'reset-password/check/:token', 
    //     component: ResetPasswordComponent,
    //     resolve  : {
    //         data: AuthService
    //     }
    // },

    // {path: 'lock',component: LockComponent},
    // {
    //     path     : 'verify-email/:token',
    //     component: VerifyOtpComponent,
    //     resolve  : {
    //         data: VerifyService
    //     }
    // },
    { path: '', redirectTo: 'login', pathMatch: 'full' },

];


@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        LockComponent,
        VerifyOtpComponent,
        ForgotPasswordAdminComponent,
        EmailVerificationComponent
    ],

    imports: [
        // Authentication
        LoginModule,
        ForgotPasswordAdminModule,
        RegisterModule,
        ForgotPasswordModule,
        CommonModule,
        RouterModule,
        ResetPasswordModule,
        MatRippleModule,
        LockModule,
        MailConfirmModule,
        VerifyOtpModule,
        // Errors
        Error404Module,
        Error500Module,
        MatProgressSpinnerModule,
        // Profile
        ProfileModule,
        // Routing module
        RouterModule.forChild(authRoutes),
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatDialogModule,
        MatToolbarModule,
        FuseSharedModule,
        MatSnackBarModule


    ]
})
export class PagesModule { }
