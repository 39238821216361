import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/main/pages/auth.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TicketTypeService implements Resolve<any>{
  permissions: any;
  maxAvailableRecordsInTable: any;
  allTicketTypes;

  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _authService:AuthService,
    private _commanService : CommonService
  ) {
    
   }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable <any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllTicketType(),
      ]).then(
        () => {
          this._fuseProgressBarService.hide();
          resolve('');
        },
        reject
      );
    })
  }


  getAllTicketType(payload?) {
    console.log("1. funcation called");
    const myPayload = {
      pageIndex: 1,
      pageSize: 10
    }
    return new Promise((resolve, reject) => {
      console.log("funcation called inside");
      
      this._httpClient.post(environment.apiURL + 'admin/tickets/getall/type',payload?payload:myPayload, this._commanService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200) {
            this.allTicketTypes = result.data.ticketTypes;
            console.log("data.ticketTypes", result.data.ticketTypes);
            this.maxAvailableRecordsInTable = result.data.totalRecords;
            resolve(result)
          }
          else if(result.status == 400){
            this.allTicketTypes = []
            this.maxAvailableRecordsInTable = 0;
            resolve(result)
          }
          // this._fuseProgressBarService.hide()
        }, reject)
    })
  }

  deleteTicketType(id) {
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/tickets/delete/type', { typeId: id }, this._commanService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200) {
            resolve('')
          }
          this._fuseProgressBarService.hide()
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 2000
          });
        }, reject)
    });
  }
}
