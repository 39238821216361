import { Component, OnInit, ViewChild } from '@angular/core';
import { FaqService } from '../faq.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { AddEditFaqDialogComponent } from '@fuse/Dialog/add-edit-faq-dialog/add-edit-faq-dialog.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector: 'app-faq-list',
    templateUrl: './faq-list.component.html',
    styleUrls: ['./faq-list.component.scss']
})
export class FaqListComponent implements OnInit {

    displayedColumns: string[] = ['sr', 'question', 'answer', 'createdAt', 'action'];
    dataSource;
    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    maxAvailableRecordsInTable;
    executed = false;
    constructor(
        public _FaqService: FaqService,
        private _ToolbarService : ToolbarService,
        private _matDialog: MatDialog,
        
    ) {
        this.setToolBarTitle(); 
        this.dataSource = new MatTableDataSource(this._FaqService.faqDataList);
        this.maxAvailableRecordsInTable = this._FaqService.maxAvailableRecordsInTable;
        console.log("this.dataSource", this.dataSource);
    }


    filter = {
        searchFilter: '',
        pageSize: 10,
        pageIndex: 1
    }

    ngOnInit(): void {
    }


    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next(`FAQ's`);
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1,
        this.filter.pageSize = event.pageSize,
        this.updateListing();
    }

    onDelete(id) {
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : `Are you sure you want to remove this question?`,
                image : '/assets/ui/delete.png',
                focus : 'right',
                deleteItem : 'Delete Question?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        })

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this._FaqService.ondeleteFaqQuestion({ "faqId": id }).then((result: any) => {
                    if (result) {
                        this.updateListing();
                    }
                })
            }
        })
        
    }

    updateListing() {
        this._FaqService.getFAQListData(this.filter).then((result: any) => {
            if (result.status == 200) {
                this.dataSource.data = result.data.getFaqList;
                this.maxAvailableRecordsInTable = result.data.totalRecords;
            } else if (result.status == 400) {
                this.dataSource.data = [];
                this.maxAvailableRecordsInTable = 0;
            }
        })
    }

    onAddFAQOpenDailog(isAddCase, id?) {
        if(isAddCase){
            if(this._matDialog.openDialogs.length === 0){
                this._matDialog.open(AddEditFaqDialogComponent, {
                    panelClass: ['commonDialogWrapper'],
                    backdropClass: 'dialogBackdrop',
                    width: '420px',
                    autoFocus: false,
                    data: {
                        isAddCase: isAddCase
                    }
                }).afterClosed().subscribe((result) => {
                    if (result) {
                        this.updateListing();
                    }
                })
            }
        }else{
            this._FaqService.getFAQDetails({ "faqId": id }).then((result:any) => {
                if(result.status == 200){
                    if(this._matDialog.openDialogs.length === 0){
                        this._matDialog.open(AddEditFaqDialogComponent, {
                            panelClass: ['commonDialogWrapper'],
                            backdropClass: 'dialogBackdrop',
                            width: '420px',
                            autoFocus: false, 
                            data: {
                                isAddCase: isAddCase,
                                faqData: result.data
                            }
                        }).afterClosed().subscribe((result) => {
                            if (result) {
                                this.updateListing();
                            }
                        })
                    }
                }
            })
        }
    }

}
