import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-common-message-submit',
  templateUrl: './common-message-submit.component.html',
  styleUrls: ['./common-message-submit.component.scss']
})
export class CommonMessageSubmitComponent implements OnInit {
  message:FormControl = new FormControl('', Validators.required);
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    public dialogRef: MatDialogRef<CommonMessageSubmitComponent>
  ) {
    console.log("_data", this._data);
    if(this._data.text){
      this.message.setValue(this._data.text);
    }
   }

  ngOnInit(): void {
  }

  onSubmit(){
    this.dialogRef.close(this.message.value)
  }
}
