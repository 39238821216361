import { ChangeDetectorRef, Component, ElementRef, isDevMode, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationTemplateDialogComponent } from '@fuse/Dialog/invitation-template-dialog/invitation-template-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { Enums } from '@fuse/utils/systemEnums';
import * as moment from 'moment';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InvitationsService } from '../invitations.service';
import * as XLSX from "xlsx";
import { MapsAPILoader} from '@agm/core';
import { AddLocationComponent } from '@fuse/Dialog/add-location/add-location.component';
import { FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import { AddGroupDialogComponent } from '@fuse/Dialog/add-group-dialog/add-group-dialog.component';
import { GroupsService } from 'app/main/groups/groups.service';
import { QuillEditorComponent } from 'ngx-quill';
// import { Quill } from 'quill';
import { Editor, toHTML, Toolbar } from 'ngx-editor';
import { ApplicationRef } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { AddMoreFieldsComponent } from '@fuse/Dialog/add-more-fields/add-more-fields.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-send-invitation',
    templateUrl: './send-invitation.component.html',
    styleUrls: [
        './send-invitation.component.scss',
        "../../../../../node_modules/quill/dist/quill.core.css",
        "../../../../../node_modules/quill/dist/quill.bubble.css",
        "../../../../../node_modules/quill/dist/quill.snow.css"
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SendInvitationComponent implements OnInit, OnDestroy{
    form: UntypedFormGroup
    groupList = [];
    eventStartMin = new Date()
    eventId;
    selectedImage: any;
    todayDate = new Date();
    public bankMultiFilterCtrl: UntypedFormControl = new UntypedFormControl('');
    public filteredBanksMulti: ReplaySubject<any> = new ReplaySubject<any>(1);
    protected _onDestroy = new Subject<void>();

    @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
    @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
    @ViewChild('getElement') getElement: any;
    @ViewChild('imageElement') imageElement: Element;
    @ViewChild('file') myFile:Element;
    @ViewChild('fileUploadingStatus') fileUploadingStatus:ElementRef;
    @ViewChild('attachmentWrapper') attachmentWrapper:ElementRef;

    elementInterface = Element;

    searchValue;
    eventStartMax;

    templates = [
        {
            id:1,
            isShow: false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710236321904.png',
            templateContent : `
            <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>
    <!-- fonts -->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: "Outfit", sans-serif;
        }

        a {
            color: #0059A0;
        }

        table, tr, td,tbody{
            border: 0;
            padding: 0;
            border-spacing: 0;
        }

    </style>
</head>

<body>

    <table style="width: 640px; margin: 0 auto;">
        <tr>
            <td>
                <!-- logo -->
                <table style="width: 100%;">
                    <tr>
                        <td>
                            <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                alt="">
                        </td>
                    </tr>
                </table>

            </td>
        </tr>

        <tr>
            <td>
                <table style="box-shadow: 0px 3px 20px #0000000D;">
                    <tr>
                        <td>

                            <!-- banner img -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img width="100%"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221763867.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                    <tr>
                        <td style="padding:30px 30px">
                            <!-- text tabel here -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>

                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 40px; text-align: center; font-weight: 600; line-height: 50px; color: #5B1758;">
                                                    Martina Lopez
                                                </td>
                                            </tr>
                                        </table>

                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 600; line-height: 19px; color: #5B1758;">
                                                    Date & Time:
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                    19th October 2024, 7 PM onwards
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 600; line-height: 19px; color: #5B1758;">
                                                    Venue
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                    Suite 865 612 Gutmann Knolls, South Antione, RI 89515-2473
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                    We hope this email finds you in good health and spirits. We are
                                                    delighted to wish you a very happy birthday and many more to come!
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                    You are a valued member of our team and we appreciate your hard work
                                                    and dedication. You have made a positive impact on our organization
                                                    and we are lucky to have you on board.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                    Have a wonderful day and a fantastic year ahead!
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                    Sincerely,
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                    Juliea Franz
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                            </table>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <!-- footer -->
                            <table style="background-color: #F2F6F9; width: 100%;">
                                <tr>
                                    <td>
                                        <table style="width: 100%;margin: 30px 0 22px 0">
                                            <tr>
                                                <td
                                                    style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                    Thanks you for choosing EventLocal
                                                    <img width="20px"
                                                        style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 100%;margin: 0 0 11px 0">
                                            <tr>
                                                <td
                                                    style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                    Need Help?
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 100%; margin: 0">
                                            <tr>
                                                <td
                                                    style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                    If you have any trouble need further assistance, please don’t <br>
                                                    hesitate to contact our support team at
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                    <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 200px; margin: 30px auto 30px auto;">
                                            <tr>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

</body>

            </html>`,
        },
        {
            id:2,
            isShow: false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710236373508.png',
            templateContent : `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710233174873.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
            
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 38px; text-align: center; font-weight: 600; line-height: 48px; color: #003B95;">
                                                                Global Tech Seminar 2024
                                                            </td>
                                                        </tr>
                                                    </table>
            
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We are delighted to invite you to the Global Tech Seminar 2024, where industry leaders and innovators will converge to explore the latest advancements and trends in technology. This event promises to be an invaluable opportunity to gain insights, network with peers, and engage in thought-provoking discussions. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We look forward to your participation in this groundbreaking event that will shape the future of technology. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>`,
        },
        {
            id:3,
            isShow: false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710236408126.png',
            templateContent : `
            <!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710234255823.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td style="font-size: 48px; text-align: center; font-weight: 600; line-height: 60px; color: #D86349;">
                                                                Cassie Smith
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 20px;padding: 9px 0; text-align: center; font-weight: 500; line-height: 25px; color: #D86349;">
                                                                WEDS
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td style="font-size: 48px; text-align: center; font-weight: 600; line-height: 60px; color: #D86349;">
                                                                Mark Anthony
                                                            </td>
                                                        </tr>
                                                    </table> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 395px; margin: 0 auto 15px auto">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; padding-top: 15px; font-weight: 500; line-height: 19px; color: #242424;">
                                                                You are cordially invited to celebrate the union of Cassie Smith and Mark Anthony as they exchange vows and begin their journey of love and companionship. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 395px; margin: 0 auto 15px auto">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Your presence on this joyous occasion would mean the world to the happy couple. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 395px; margin: 0 auto 15px auto">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Let's come together to witness and celebrate this beautiful milestone in their lives.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>
            `,
        },
        {
            id:4,
            isShow: false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710236491306.png',
            templateContent : `
            <!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710235283878.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td style="font-size: 31px; text-align: center; font-weight: 700; line-height: 40px; color: #271B4A;">
                                                                NEW YEAR PARTY <br> 2K24
                                                            </td>
                                                        </tr> 
                                                    </table> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 543px; margin: 0 auto 15px auto">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; padding-top: 15px; font-weight: 500; line-height: 19px; color: #242424;">
                                                                As we bid farewell to the old and welcome the new, we invite you to join us in ringing in the New Year with great cheer and merriment. Let's raise our glasses to new beginnings and unforgettable memories.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 395px; margin: 0 auto 15px auto">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Let's make the transition to 2024 a night to remember!
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 35px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>
            `,
        },
        {
            id:5,
            isShow: false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710237139001.png',
            templateContent : `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710236726918.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td style="font-size: 31px; text-align: center; font-weight: 700; line-height: 40px; color:#271B4A;">
                                                                <span style="color: #98B76C;">PLANET </span>  NURTURE CAMPAIGN
                                                            </td>
                                                        </tr> 
                                                    </table> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We are thrilled to extend an invitation to join us in our mission to nurture and protect our planet. The Planet Nurture Campaign aims to raise awareness and inspire action towards sustainable living and environmental conservation. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Your participation will make a tangible difference in safeguarding our planet for future generations.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
             
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>`,
        },
        {
            id:6,
            isShow: false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710237870919.png',
            templateContent : `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710237590150.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td style="font-size: 40px; text-align: center; font-weight: 700; line-height: 50px; color:#242424;">
                                                                <span style="color: #003B95;">Animal </span>  feed drive
                                                            </td>
                                                        </tr> 
                                                    </table> 
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                You are invited to join our efforts in providing nourishment and care for our furry friends. The Animal Feed Drive seeks to gather support for shelters and rescue organizations, ensuring that animals in need receive the sustenance they deserve.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Your contribution will make a meaningful impact in the lives of these beloved creatures.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
             
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
            
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>`,
        },
        {
            id: 7,
            isShow: false, 
            image: 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710239968935.png',
            templateContent : `
            <!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title></title>
    <!-- fonts -->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
    <style>
        body {
            font-family: "Outfit", sans-serif;
        }

        a {
            color: #0059A0;
        }

        table, tr, td,tbody{
            border: 0;
            padding: 0;
            border-spacing: 0;
        }

    </style>
</head>

<body>

    <table style="width: 640px; margin: 0 auto;">
        <tr>
            <td>
                <!-- logo -->
                <table style="width: 100%;">
                    <tr>
                        <td>
                            <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                alt="">
                        </td>
                    </tr>
                </table>

            </td>
        </tr>

        <tr>
            <td>
                <table style="box-shadow: 0px 3px 20px #0000000D;">
                    <tr>
                        <td>

                            <!-- banner img -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img width="100%"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710238098975.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>

                        </td>
                    </tr>
                    <tr>
                        <td style="padding:15px 30px">
                            <!-- text tabel here -->
                            <table style="width: 100%;">
                                <tr>
                                   <td>
                                    <table style="width: 100%;margin-bottom: 10px;">
                                        <tr>
                                            <td style="font-size: 12px; text-align: center; line-height: 15px; text-transform: uppercase;color: #242424; font-weight: 600;">
                                                IT'S AMY AND RONALD'S SPECIAL DAY!
                                            </td>
                                        </tr>
                                    </table>
                                   </td>
                                </tr>
                                <tr>
                                    <td> 
                                        <table style="width: 100%;">
                                            <tr>
                                               <td style="font-size: 87px; text-align: center; line-height: 100px; color: #242424; font-weight: 700;">
                                                    50th
                                               </td>
                                            </tr> 
                                        </table> 
                                    </td>
                                </tr>
                                <tr>
                                    <td> 
                                        <table style="width: 100%; margin-bottom: 25px;">
                                            <tr>
                                               <td style="font-size: 54px; text-align: center; line-height: 68px; color: #ED6652; font-weight: 600;">
                                                    Anniversary
                                               </td>
                                            </tr> 
                                        </table> 
                                    </td>
                                </tr> 
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 23px;">
                                            <tr>
                                                <td style="font-size: 15px; text-align: center; text-decoration: underline; line-height: 19px; color: #000000; font-weight: 600;">
                                                    19th October 2024, 7 PM onwards | 1296 Ortiz Meadows, McCulloughfurt
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr> 
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                    We are thrilled to extend our warmest invitation to join us in celebrating a remarkable milestone - our 50th anniversary. It is a momentous occasion that we wish to share with our cherished friends and family. 
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                    Your presence will make this celebration all the more special as we reminisce and look forward to the future together.
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr> 
                                <tr>
                                    <td>
                                        <table style="width: 100%; margin-bottom: 15px;">
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 500; line-height: 19px; color: #242424;">
                                                    Sincerely,
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="font-size: 15px; text-align: center; font-weight: 600; line-height: 19px; color: #242424;">
                                                    Juliea Franz
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr> 
                            </table>

                        </td>
                    </tr>
                    <tr>
                        <td>
                            <!-- footer -->
                            <table style="background-color: #F2F6F9; width: 100%;">
                                <tr>
                                    <td>
                                        <table style="width: 100%;margin: 30px 0 22px 0">
                                            <tr>
                                                <td
                                                    style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                    Thanks you for choosing EventLocal
                                                    <img width="20px"
                                                        style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 100%;margin: 0 0 11px 0">
                                            <tr>
                                                <td
                                                    style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                    Need Help?
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 100%; margin: 0">
                                            <tr>
                                                <td
                                                    style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                    If you have any trouble need further assistance, please don’t <br>
                                                    hesitate to contact our support team at
                                                </td>
                                            </tr>
                                            <tr>
                                                <td
                                                    style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                    <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                </td>
                                            </tr>
                                        </table>
                                        <table style="width: 200px; margin: 30px auto 30px auto;">
                                            <tr>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                                <td style="text-align: center;">
                                                    <a href="#">
                                                        <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                            alt="">
                                                    </a>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>

</body>

            </html>`
        },
        {
            id:8,
            isShow : false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710240820201.png',
            templateContent : `<!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710240099549.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 23px;">
                                                        <tr>
                                                            <td style="font-size: 15px; text-align: center; text-decoration: underline; line-height: 19px; color: #000000; font-weight: 600;">
                                                                Rubikz International School <span style="font-weight: 500;">Presents</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr> 
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 35px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 31px; text-align: center; font-weight: 600; line-height: 39px; color: #003B95;">
                                                                Geekheads: project Showcase 2024
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We are excited to invite you to the Geekheads: Project Showcase 2024, a platform for students to exhibit their innovative projects and technological prowess. This event promises to showcase the brilliance and creativity of the students at Rubikz International School. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We look forward to your presence as we celebrate the ingenuity of the next generation of tech enthusiasts.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                             
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 35px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr> 
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>`
        },
        {
            id : 9,
            isShow : false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710241559314.png',
            templateContent : `<!DOCTYPE html>
            <html lang="en"> 
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710241279398.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;"> 
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 31px; text-align: center; font-weight: 600; line-height: 39px; color: #000;">
                                                                Geekheads: project Showcase 2024
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We are excited to invite you to the Geekheads: Project Showcase 2024, a platform for students to exhibit their innovative projects and technological prowess. This event promises to showcase the brilliance and creativity of the students at Rubikz International School. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We look forward to your presence as we celebrate the ingenuity of the next generation of tech enthusiasts.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                             
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 35px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr> 
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>`
        },
        {
            id : 10,
            isShow : false, 
            image : 'https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710241941445.png',
            templateContent : `
            <!DOCTYPE html>
            <html lang="en">
            
            <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title></title>
                <!-- fonts -->
                <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                <link href="https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap" rel="stylesheet">
                <style>
                    body {
                        font-family: "Outfit", sans-serif;
                    }
            
                    a {
                        color: #0059A0;
                    }
            
                    table, tr, td,tbody{
                        border: 0;
                        padding: 0;
                        border-spacing: 0;
                    }
            
                </style>
            </head>
            
            <body>
            
                <table style="width: 640px; margin: 0 auto;">
                    <tr>
                        <td>
                            <!-- logo -->
                            <table style="width: 100%;">
                                <tr>
                                    <td>
                                        <img style="width: 206px; margin: 40px auto 32px auto; display: block;"
                                            src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710221554049.png"
                                            alt="">
                                    </td>
                                </tr>
                            </table>
            
                        </td>
                    </tr>
            
                    <tr>
                        <td>
                            <table style="box-shadow: 0px 3px 20px #0000000D;">
                                <tr>
                                    <td>
            
                                        <!-- banner img -->
                                        <table style="width: 100%;">
                                            <tr>
                                                <td>
                                                    <img width="100%"
                                                        src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710241819337.png"
                                                        alt="">
                                                </td>
                                            </tr>
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding:15px 30px">
                                        <!-- text tabel here -->
                                        <table style="width: 100%;"> 
                                            <tr>
                                                <td> 
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 31px; text-align: center; font-weight: 600; line-height: 39px; color: #000;">
                                                                2024 Sports Weekend: Yoga
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Date & Time:
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                19th October 2024, 7 PM onwards
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 25px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 700; line-height: 22px; color: #000000;">
                                                                Location
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: center; font-weight: 500; line-height: 22px; color: #242424;">
                                                                1296 Ortiz Meadows, McCulloughfurt, NC 52153-5019
                                                            </td>
                                                        </tr>
            
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We are excited to invite you to the Geekheads: Project Showcase 2024, a platform for students to exhibit their innovative projects and technological prowess. This event promises to showcase the brilliance and creativity of the students at Rubikz International School. 
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 15px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                We look forward to your presence as we celebrate the ingenuity of the next generation of tech enthusiasts.
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                             
                                            <tr>
                                                <td>
                                                    <table style="width: 100%; margin-bottom: 35px;">
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 500; line-height: 19px; color: #242424;">
                                                                Sincerely,
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px; text-align: left; font-weight: 600; line-height: 19px; color: #242424;">
                                                                Juliea Franz
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr> 
                                        </table>
            
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <!-- footer -->
                                        <table style="background-color: #F2F6F9; width: 100%;">
                                            <tr>
                                                <td>
                                                    <table style="width: 100%;margin: 30px 0 22px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Thanks you for choosing EventLocal
                                                                <img width="20px"
                                                                    style="vertical-align: middle; display: inline-block; margin: 0 0 6px 3px;"
                                                                    src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223314602.png"
                                                                    alt="">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%;margin: 0 0 11px 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 20px;text-align: center; line-height: 25px; font-weight: 600; color: #000000;">
                                                                Need Help?
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 100%; margin: 0">
                                                        <tr>
                                                            <td
                                                                style="padding:0 15px; font-size: 15px;text-align: center; line-height: 24px; font-weight: 500; color: #000000;">
                                                                If you have any trouble need further assistance, please don’t <br>
                                                                hesitate to contact our support team at
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td
                                                                style="font-size: 15px;text-align: center; line-height: 24px; font-weight: 500;">
                                                                <a href="mailto: support@eventlocal.com">support@eventlocal.com</a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <table style="width: 200px; margin: 30px auto 30px auto;">
                                                        <tr>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223486432.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223603190.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223641800.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                            <td style="text-align: center;">
                                                                <a href="#">
                                                                    <img src="https://event-project-gts.s3.amazonaws.com/invitation/attachment/image/attachment_1710223679359.png"
                                                                        alt="">
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            
            </body>
            
            </html>
            `
        }
    ]
 
    config: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      sanitize: false,
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        }
      ],
      toolbarPosition : 'top',
      uploadUrl: 'v1/image',
      toolbarHiddenButtons: [
        ['italic'],
        ['insertVideo', 'toggleEditorMode']
      ]
    };

    htmlText:any = `<p noEdit>{{firstName}}</p>`;

    enum = Enums
    invitationDetails: any;
    wopts: XLSX.WritingOptions = { bookType: "xlsx", type: "array" };

    invitationId: any;
    base64textString;
    eventListing;

    isAllowedToEdit:boolean = false;
    isEditable:boolean = false;

    modules = {};
    latitude: number;
    longitude: number;
    zoom: number = 8;
    address: string;
    mapLoadingTimes = 0
    public lat: number = 51.678418;
    public lng: number = 7.809007;
    private geoCoder;

    @ViewChild('search', { static: true })
    public searchElementRef: ElementRef;
    attachmentUrl: any;
    files: NgxFileDropEntry[];
    allContantList: any;
    isSubjectShow;
    isTextMsgShow;
    dataModel;
    editor: Editor;
 
    eventName: string | Blob;
    uploadedImagePath: any;

    // variables = {
    //     firstName : '{{firstName}}'
    // }

    firstName = '{{firstName}}'
    
    constructor(
        private _activatedRoute: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private _InvitationsService: InvitationsService,
        private appRef: ApplicationRef,
        private mapsAPILoader: MapsAPILoader,
        private domSanitizer : DomSanitizer,
        private ngZone: NgZone,
        private _matDialog: MatDialog,
        public _commonService: CommonService,
        private _router: Router,
        private _snackbar: MatSnackBar,
        private _ToolbarService : ToolbarService,
        private _groupsService : GroupsService,    
        private _fuseProgressBarService: FuseProgressBarService,
        private _cd: ChangeDetectorRef,
    ) {
        // this.quillConfig.toolbarenable(false)
        // this.setDefTemp()
        this.eventListing = this._InvitationsService.eventListing;
        console.log("eventListing", this.eventListing);
        this.getContantList();
        this.getGroupListing();
        this.createForm();
        this.handleScheduleDateValidation();
        this.editor = new Editor();

       

        this._activatedRoute.params.subscribe((urlId) => {
            if (urlId?.id) {
                this.invitationId = urlId?.id;
                this.invitationDetails = this._InvitationsService.invitationDetail;
                if(!this.invitationDetails.isInvited){
                    this.isAllowedToEdit = true;
                }
                this.createForm(this.invitationDetails);
                // this.form.disable();
                this.setToolBarTitle(true);
                
            } else {
                this.editForm();
                this.invitationId = ''
                this.invitationDetails = '';
                this.setToolBarTitle(false);
            }
        })
    }
  
    ngOnInit(): void { 
       
        this.bankMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe((val) => {
            if(val == ''){
                this.filteredBanksMulti.next(
                    this.groupList.filter(bank => bank)
                );
            }else{
                this.filterBanksMulti();
            }


            
        }); 
        this.getVenueDetails();
    }

    get doc(): AbstractControl {
        return this.form.get('content');
    }

    setToolBarTitle(edit){
        if(edit){
            // for title
            this._ToolbarService._ToolBarTitle.next('Invitation Detail');
            // for btn
            this._ToolbarService._isBackBtnShow.next(true);
        }else{
            // for title
            this._ToolbarService._ToolBarTitle.next('Create New Invitation');
            // for btn
            this._ToolbarService._isBackBtnShow.next(false);
        }
    }

    addImageOnClick(event){
        this.onFileUpload(event.target.files[event.target.files.length - 1], true)
    }

    setImageInfoContent(url){
        console.log("url", url);
        let editorElement:any = document.getElementsByClassName('NgxEditor__Content');
        const img = new Image();
        img.src = url;
        editorElement.item(0).appendChild(img)
    }
    
    openHelp(){
        // this._matDialog.open()
    }

    triggerChangeDetection() {
        this.appRef.tick();
    }
    
    openDynamicVariable(){
        this._commonService.getDynamicFieldList({}).then((result:any) => {
            if(result.status == 200 || result.status === 400){
               this._matDialog.open(AddMoreFieldsComponent, {
                panelClass: ['commonDialogWrapper'],
                backdropClass: 'dialogBackdrop',
                disableClose: true,
                width : '620px',
                autoFocus : false,
                    data : {
                        fields : result.data,
                        isForCopy : true, 
                    }
                }).afterClosed().subscribe((fieldData) => {
                    if(fieldData){
                        
                    }
                })
            }
          })
    }

    onEditorCreated(quillEditor: QuillEditorComponent) {
        console.log("quillEditor", quillEditor); 
        
        const quill: any = quillEditor;
    
        const style = {
          color: 'red',
          fontWeight: 'bold',
        };
    
        quill?.format('inline', style);
    }

    getContantList(){
        this._groupsService.getAllContacts({"pageIndex":0, "pageSize":0, "search":""}).then((result:any) => {
            if(result.status == 200){
                this.allContantList = result.data.contactList;
            }else{
                this.allContantList = []
            }
        })
    }

    setDefTemp(){
        let getSalected = this.templates.filter((template:any) => template.isShow == true)
        this.htmlText = getSalected[0].templateContent;
    }

    openFileSelect(){
        document.getElementById('getFile').click();
    }

    addLocation() {
        if (this.form.status != "DISABLED") {
          this._matDialog.open(AddLocationComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width: "600px",
            autoFocus: false,
            data : {
                justLocation : true
            }
            }).afterClosed().subscribe((dialogData: any) => {
              if (dialogData) {
                console.log("dialogData", dialogData);
                this.form.get('location').setValue(dialogData.locationTitle)
                this.form.get('lat').setValue(dialogData.lat)
                this.form.get('long').setValue(dialogData.long)
              }
            });
        }
    }

    handleScheduleDateValidation() {
        this.form.get('sendNow').valueChanges.subscribe((res) => {
            if (res) {
                this.form.get('scheduleTime').setValidators(null);
                this.form.get('scheduleTime').setValue('');
                this.form.get('scheduleTime').setErrors(null);
                this.form.updateValueAndValidity();
            } else {
                this.form.get('scheduleTime').setValidators(Validators.required);
                this.form.get('scheduleTime').setValue('');
                this.form.get('scheduleTime').setErrors({ required: true });
                this.form.updateValueAndValidity();
            }
        })

        this.form.get('isInvitationTypeEvent').valueChanges.subscribe((res) => {
            if(res) {
                this.form.get('eventIds').setValidators(Validators.required);
                this.form.get('location').setErrors(null);
                this.form.get('location').setValidators(null);
                this.form.get('location').setValue('');
                this.form.updateValueAndValidity();
            }else{
                this.form.get('location').setValidators(Validators.required);
                this.form.get('eventIds').setValidators(null);
                this.form.get('eventIds').setValue('');
                this.form.get('eventIds').setErrors(null);
                this.form.updateValueAndValidity();
            }
            console.log("this is form status", this.form);
        })

        this.form.get('sendVia').valueChanges.subscribe((res) => {
            console.log("res", res);
            if(res.length){
                if(res.some((val)=> val === 3)){ 
                    this.isSubjectShow = true; 
                    if(!this.invitationDetails?.sendVia){
                        this.form.get('content').setErrors([Validators.required]);
                    }
                }
                else{
                    this.form.get('content').setErrors(null);
                    this.isSubjectShow = false; 
                }
                
                if(res.some((val)=> val === 1 || val === 2)){
                    this.isTextMsgShow = true;
                    if(!this.invitationDetails?.sendVia){
                        this.form.get('textmsg').setErrors([Validators.required]);
                    }
                }else{
                    this.isTextMsgShow = false;
                    this.form.get('textmsg').setErrors(null);
                }
            }
            else{
                this.isTextMsgShow = false;
                this.form.get('content').setErrors(null);
                this.form.get('textmsg').setErrors(null);
                this.isSubjectShow = false; 
            }
        })
    }

    editForm(){
        this.form.enable();
        this.handleScheduleDateValidation();
        this.getVenueDetails();
        this.isEditable = true;
        this.isAllowedToEdit = false;
        this.attachmentWrapper?.nativeElement?.classList.remove('readOnly');
    }
    
    getGroupListing() {
        this._InvitationsService.getAllGroup().then((res: any) => {
            if (res.status == 200) {
                this.groupList = res.data.groupList;
                this.filterBanksMulti();
                this.filteredBanksMulti.next(
                    this.groupList.filter(bank => bank)
                );
                if (this.invitationId) {
                    this.createForm(this.invitationDetails);
                }
                this._cd.detectChanges();
            } else {
                this.groupList = [];
            }
        })
    }

    ngOnDestroy() {
        this.editor.destroy();
    }

    createForm(data?) {
        console.log("data", data);
        if (data?.content) {
            this.htmlText = data?.content
        }
        if(data?.attachment){
            this.attachmentUrl = data?.attachment;
        }
        if(data?.scheduleTime){
            this.todayDate = new Date(data?.scheduleTime);
        }
        if(data?.textMsg){
            this.isTextMsgShow = true;
        }
        if(data?.content){
            this.isSubjectShow = false;
        }
        let methodsArr;
        if(data?.sendVia){
            
            let methods = data.sendVia.split(',');
            methodsArr = data.sendVia.split(',').map(Number);
            if(methods.some((val)=> val === '3')){
                this.isSubjectShow = true; 
                // this.form.get('content').setErrors([Validators.required]);
            }
            else{
                this.form.get('content').setErrors(null);
                this.isSubjectShow = false; 
            }

            if(methods.some((val)=> val === '1' || val === '2')){
                this.isTextMsgShow = true;
                console.log("log");
                this.form.get('textmsg').setErrors(null);
            }else{
                this.isTextMsgShow = false;
                this.form.get('textmsg').setErrors(null);
            }
        }
        console.log("data?.groupList?.join()", data?.groupList?.join());
        
        return this.form = this.fb.group({
            title: [data?.title || '', Validators.required],
            content: [data?.content || ''],
            sendNow: [data?.sendNow || ''],
            eventIds: [data?.isEvent || ''],
            location: [data?.location || ''],
            textmsg: [data?.textMsg || ''],
            lat: [data?.latitude || ''],
            long: [data?.longitude || ''],
            groupIds: [data?.groupList?.length ? data?.groupList.forEach(element =>String(element)) : '', Validators.required],
            subject: [data?.subject || ''],
            attachment: [data?.attachment || ''],
            isInvitationTypeEvent: [data?.isEvent || ''],
            sendVia: [data?.sendVia ? methodsArr :'', Validators.required],
            // sendVia: [data?.sendVia ? [Number(...data?.sendVia.split(","))] :'', Validators.required],
            scheduleTime: [data?.sendNow ? '': new Date(data?.scheduleTime) || '']
        })
    }
    
    dropped(files: NgxFileDropEntry[]) {
    this.files = files;
        for (const droppedFile of files) {
            // Is it a file?
            // if (droppedFile.fileEntry.isFile) {
            //     const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
            //     fileEntry.file((file: File) => {
            //         console.log("file", file);
            //         this.onFileUpload(file)
            //     })
            // }
            if(droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)){
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    console.log('isFile :', file.name);
                    console.log(droppedFile.relativePath, file);
                    this.onFileUpload(file)
                });
            }else{
                this._snackbar.open(`!Invalid File. Allowed File(video, image, pdf)`, 'OKAY', {duration : 4000})
            }
        }
    }

    isFileAllowed(fileName: string) {
        let isFileAllowed = false;
        const allowedFiles = ['.mp4', '.jpeg', '.jpg', '.png','.pdf'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (isDevMode()) {
            console.log('extension du fichier : ', extension);
        }
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }
        return isFileAllowed;
    }
    
    addGroup(){
        this._matDialog.open(AddGroupDialogComponent, {
            autoFocus: false,
            panelClass: 'no-padding-dialog',
            width: '400px',
            data: {
                allContacts: this.allContantList,
            }
        }).afterClosed().subscribe((result:any) => {
            if(result){
                this._groupsService.createGroup(result).then((val:any) => {
                    if(val.status == 200){
                        this.getGroupListing()
                    }
                });
            }
        })
    }

    onViewFile(){
        window.open(this.attachmentUrl)
    }

    clearFile(){
        this.attachmentUrl = '';
        if(this.invitationId){
            this.attachmentWrapper.nativeElement.classList.add('readOnly');
            console.log("attachmentWrapper", this.attachmentWrapper.nativeElement);
        }
    }

    onFileUpload(fileObj, isImagetype?) {
        if(!isImagetype){
            this.fileUploadingStatus.nativeElement.innerHTML = 'Uploading...';
        }
        
        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName" : 'image_',
            "folderName":  `common/${String(fileObj?.type).split('/')[0]}`, // it is fix set by backend.
            // "folderName":  `invitation/attachment/${String(fileObj?.type).split('/')[0]}`, // it is fix set by backend.
            'mimeType': fileObj?.type
        }

        this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
            if (result.status == 200) {
                console.log("result.data", result.data);
                if(!isImagetype){
                    this.attachmentUrl = result.data.imageUploadUrl
                }
                // getFile Upload stauts 
                const payload = {
                    'id': result.data.id,
                    'status': 1 // fix by backend.
                }
                this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, fileObj).then((res)=>{
                    if(res){
                        if(isImagetype){
                            console.log("isImagetype", isImagetype);
                            this.setImageInfoContent(result.data.imageUploadUrl)
                        }
                    }
                })
                this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                    if (uploadStatusResponse.status == 200) {
                        this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    } else {
                        this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    }
                })
            } else {
                this._snackbar.open(result.message, "OKAY", { duration: 2000 })
            }
        })
    }

    onPrint(){
        console.log("attachment", this.form);
        console.log("this.htmlText", this.htmlText);

        const safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.htmlText);
        console.log("safeHtml", safeHtml);
        
        const html = safeHtml['changingThisBreaksApplicationSecurity'];
        console.log("html", html);
        
        let formData = new FormData();
        // let scheduleTimeData:any = this.form.get('sendNow').value ? 1 : Number(moment(this.form.get('scheduleTime').value).format('x'));
        // formData.append("id", this.invitationId)
        // formData.append("title", this.form.get('title').value || '')
        // formData.append("groupIds", this.form.get('groupIds').value || '')
        // formData.append("scheduleTime", scheduleTimeData || '')
        // formData.append("sendNow", this.form.get('sendNow').value == true ? '1' : '0')
        // formData.append("attachment", this.attachmentUrl ? this.attachmentUrl : '')
        formData.append("content", html || '')
        // formData.append('textmsg', this.form.get('textmsg').value || '')
        // formData.append("subject", this.form.get('subject').value || '')
        // formData.append("location", this.form.get('location').value || '')
        // formData.append("latitude", this.form.get('lat').value || '')
        // formData.append("longitude", this.form.get('long').value || '')
        // formData.append("isEvent", this.form.get('isInvitationTypeEvent')?.value == true ? this.form.get('eventIds').value :'0')
        // formData.append("sendVia", this.form.get('sendVia').value ? this.form.get('sendVia').value.join(',') : '')
        console.log("formData", formData.get('content'));
        
    }

    submit(isEditable?) {
        console.log("attachment", this.form);
        this._fuseProgressBarService.show();
        if(this.form.valid){
          if(isEditable){
            console.log("isEditable", isEditable);
            // let formData = new FormData();
            // let scheduleTimeData:any = this.form.get('sendNow').value ? 1 : Number(moment(this.form.get('scheduleTime').value).format('x'));
            // formData.append("id", this.invitationId)
            // formData.append("title", this.form.get('title').value || '')
            // formData.append("groupIds", this.form.get('groupIds').value || '')
            // formData.append("scheduleTime", scheduleTimeData || '')
            // formData.append("sendNow", this.form.get('sendNow').value == true ? '1' : '0')
            // formData.append("attachment", this.attachmentUrl ? this.attachmentUrl : '')
            // formData.append("content", this.form.get('content').value || '')
            // formData.append('textmsg', this.form.get('textmsg').value || '')
            // formData.append("subject", this.form.get('subject').value || '')
            // formData.append("location", this.form.get('location').value || '')
            // formData.append("latitude", this.form.get('lat').value || '')
            // formData.append("longitude", this.form.get('long').value || '')
            // formData.append("isEvent", this.form.get('isInvitationTypeEvent')?.value == true ? this.form.get('eventIds').value :'0')
            // formData.append("sendVia", this.form.get('sendVia').value ? this.form.get('sendVia').value.join(',') : '')

            const scheduleTimeData:any = this.form.get('sendNow').value ? 1 : Number(moment(this.form.get('scheduleTime').value).format('x'));
            const payload = { 
                id :  this.invitationId,
                title :  this.form.get('title').value || '',
                groupIds :  this.form.get('groupIds').value || '',
                scheduleTime :  scheduleTimeData || '',
                sendNow :  this.form.get('sendNow').value == true ? '1' : '0',
                attachment :  this.attachmentUrl ? this.attachmentUrl : '',
                content :  this.form.get('content').value || '',
                textmsg :  this.form.get('textmsg').value || '',
                subject :  this.form.get('subject').value || '',
                location :  this.form.get('location').value || '',
                latitude :  this.form.get('lat').value || '',
                longitude :  this.form.get('long').value || '',
                isEvent :  this.form.get('isInvitationTypeEvent')?.value == true ? this.form.get('eventIds').value :'0',
                sendVia :  this.form.get('sendVia').value ? this.form.get('sendVia').value.join(',') : '',
            }

            this._InvitationsService.editInvitation(payload).then((response:any) => {
              if(response){
                this._fuseProgressBarService.hide();
                this._router.navigate(['Invitation/']);
              }
            })
          }else{
            const scheduleTimeData:any = this.form.get('sendNow')?.value ? new Date() : Number(moment(this.form.get('scheduleTime')?.value)?.format('x'));
            
            const payload = {
                groupList : this.form.get('groupIds')?.value.map(item => Number(item)),
                scheduleTime : scheduleTimeData || '',
                subject : this.form.get('subject')?.value || '',
                attachment : this.attachmentUrl ? this.attachmentUrl : '',
                content : this.form.get('content')?.value || '',
                title : this.form.get('title')?.value || '',
                isEvent : this.form.get('isInvitationTypeEvent')?.value == true ? this.form.get('eventIds')?.value :'0',
                sendVia : this.form.get('sendVia')?.value ? this.form.get('sendVia')?.value.join(',') : '',
                sendNow : this.form.get('sendNow')?.value == true ? '1' : '0',
                location : this.form.get('location')?.value || '',
                latitude : this.form.get('lat')?.value || '',
                longitude : this.form.get('long')?.value || '',
                eventName : this.form.get('eventIds')?.value ? this.eventName : ''
            } 
            console.log("this is the send invitation payload", payload);
            
            this._InvitationsService.sendInvitation(payload).then((response:any) =>{
              if(response){
                this._fuseProgressBarService.hide();
                this._router.navigate(['Invitation/']);
              }
            })
          }
        }
    }

    filterBanksMulti() {
        let search = this.bankMultiFilterCtrl.value;
        console.log("THIS is search value ------------->",search)
        if (!search) {
            if (this.groupList?.length) {
                console.log("set group listing ---> ", this.groupList);
                const groupWithMember = this.groupList.filter((item) => {
                    if (item.contactIds) {
                        return item
                    }
                })
                this.filteredBanksMulti.next(groupWithMember?.slice());
            }
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredBanksMulti.next(
            this.groupList.filter(bank => bank.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
        );

        // this.filteredBanksMulti.subscribe((value:any) =>{
        //   console.log("value ===>", value);
        // })
    }

    onViewTemplate() {
        const dialog =  this._matDialog.open(InvitationTemplateDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            autoFocus: false,
            data : {
                // selectedTemp : 1,
                templates : this.templates
            }
        })
        dialog.afterClosed().subscribe((dialogRes) => {
            if(dialogRes){
                let selectedContent = dialogRes.filter((template) => template.isShow ? template : ``);
                this.htmlText = selectedContent[0].templateContent;
            }
        })
    }

    // get event venue details by event id
    getVenueDetails(){
        this.form.get('eventIds').valueChanges.subscribe((respones)=>{
            console.log("respones", respones);
            if(respones){
                this.getEventName(respones)
                const payload = {
                    id : respones
                }
                this._InvitationsService.getVenueDetailsByEvent(payload).then((respones:any) => {
                    if(respones){
                        if(respones.data?.length == 0){
                            this._snackbar.open('Event is online', 'OKAY', {duration : 2000})
                            this.form.get('location').setValue('')
                            this.form.get('lat').setValue('')
                            this.form.get('long').setValue('')
                        }else{
                            this._snackbar.open('Venue Location Picked', 'OKAY', {duration : 2000})
                            this.form.get('location').setValue(respones.data.fullAddress)
                            this.form.get('lat').setValue(respones.data.lattitude)
                            this.form.get('long').setValue(respones.data.longitude)
                        }
                    }
                })
            }
        })
    }

    getEventName(eventId){
        let selectedEventData = this.eventListing.filter((eventData) => eventData.id == eventId);
        this.eventName = selectedEventData[0].name;
    }
    
    onCancelEvent(){
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : 'Are you sure you want to cancel this invitation?',
                image : '',
                focus : 'right',
                deleteItem : 'cancel invitation?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        })
        dialog.afterClosed().subscribe((result) => {
            if(result){
                this._fuseProgressBarService.show()
                let payload = {
                    id : this.invitationId
                }
            this._InvitationsService.cancelInvitation(payload).then((result) => {
                if(result){
                    this._router.navigate(['Invitation/']);
                }
                this._fuseProgressBarService.hide();
            })
        }})
    } 

}