import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { AddOrderService } from 'app/main/orders/add-order/add-order.service';
import { CreateOrderService } from 'app/main/orders/create-order/create-order.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-add-booking-customer-info',
  templateUrl: './add-booking-customer-info.component.html',
  styleUrls: ['./add-booking-customer-info.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AddBookingCustomerInfoComponent implements OnInit {

  form : FormGroup;
  dynamicFields;

  allCountries: any;
  memberStates: any = [];
  customerInfoType: any;

  defCountryCode:any = '';
  selectedCountryCode;
  selectedCountry = '';
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  checkNumberStatus = 1; // 1 == not checked, 2 == checking...,  3 == checked but not found, 4 == checked and found.
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  allStates: any = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddBookingCustomerInfoComponent>,
    private _CreateOrderService : CreateOrderService,
    private _commonService : CommonService,
    public _addOrderService: AddOrderService,
    private _fb: FormBuilder,
    private _cd : ChangeDetectorRef
  ) { 
    console.log("_data===>->->", _data?.isHostMemberOrder);
  }

  ngOnInit(): void {
    
    this.dynamicFields = this._data.dynamicNames;
    this.customerInfoType = this._data.customerInfoType;
    this.allCountries = this._data.countries;
    this.allStates = this._data.allStates;

    console.log("_data==>", this._data);
    this.changeDynamicFieldsFormate();
    this.form = this.createForm();
    if(this._data.members.length){
      this.setMember(this._data.members);
    }else{
      this.setMember();
    }
    console.log("this is form status", this.form);
    console.log("members", this.members);

      
    // }

  }


  onUpdatePhoneNumber(element){
    console.log("hit on change, this is element", element);
    if(element?.value){
      const result = element?.value;
      // allow china 11 digit 
      if((result?.countryCode === 'CN')){
        if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
            element.setErrors(null);
        }else{
          element.setErrors({validatePhoneNumber : {valid : false}});
        }
      }
    }
  }

  changeDynamicFieldsFormate(){
    let dymanicFieldFormated = {}
    this.dynamicFields.forEach((item) => {
      dymanicFieldFormated[item.title] = item;
    })
    this.dynamicFields = dymanicFieldFormated;
    
  }

  createForm(){
    return this._fb.group({
      members : this._fb.array([])
    })
  }

  /* MAKE FORM CONTROLS */
  get members(): UntypedFormArray {
    return this.form.controls["members"] as UntypedFormArray;
  }

  setMember(members?){
    for (let i = 0; i < this._data.seatCount; i++) {
      console.log("this are the member", members);
      if(members){
        if(members[i].country && members[i].state){
          console.log("allStates", this.allStates);
          this.memberStates[i] = this.allStates.filter((item) => item.country_id == members[i].country);
        }
      }

      (<UntypedFormArray>this.form.get('members')).push(
        this._fb.group({
          udId : [members ? members[i]?.udId: ""],
          name: [members ? members[i]?.name : ""],
          
          mobile: [members ? members[i]?.mobile : ""],
          phoneCode : [members ? members[i]?.phoneCode :''],
          countryCode : [members ? members[i]?.countryCode :''],

          email: [members ? members[i]?.email : "",[Validators.email]],
          gender: [members ? members[i]?.gender ? String(members[i]?.gender) : "": ""],
          age: [members ? members[i]?.age : ""],
          country: [members ? String(members[i]?.country) : ""],
          countryTitle : [members ? members[i].countryTitle : ''],
          state: [members ? String(members[i]?.state) : ""],
          stateTitle : [members ? members[i].stateTitle : ''],
          city: [members ? members[i]?.city : ""],
          companyName : [members ? members[i]?.companyName : ""],
          jobTitle : [members ? members[i]?.jobTitle : ""],
          industry : [members ? members[i]?.industry : ""],
          directNo : [members ? members[i]?.directNo : ""]
        })
      );
      this._cd.detectChanges();
    }
  } 

  onSelectCountry(e, index?) {
    this._commonService.getStates(e.value).then((result: any) => {
      this.memberStates[index]=result.data;
    }) 

    // if(this._data.isBulkOrder){ 
      let selectedCountry = this.allCountries.filter((item) => item.id == e.value)
      if(selectedCountry.length){
        this.members.controls[index].get('countryTitle').setValue(selectedCountry[0].name);
      }
    // }
    
  }

  onSubmit(){
    console.log("this is value", this.members);
    
    this.members.controls.map((item) => {
      if(item.get('mobile').value){
        const mobileObj = item.get('mobile').value;
        item.get('phoneCode').setValue(mobileObj.dialCode)
        item.get('countryCode').setValue(mobileObj.countryCode);
      }
    })
    console.log("after change member details", this.members);
    this._matDialogRef.close(this.members.value);
  }

  onSelectState(event, i){
    // if(this._data.isBulkOrder){
      let selectedState = this.allCountries.filter((item) => item.id == event.value)
      if(selectedState.length){
        this.members.controls[i].get('stateTitle').setValue(selectedState[0].name);
      }
    // }
  }
 
}
