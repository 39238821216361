import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AddEditEventService } from '../../edit-event/add-edit-event.service';
import { CommonService } from '@fuse/services/common.service';
import { TicketListService } from 'app/main/tickets/ticket-list/ticket-list.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrderService } from 'app/main/orders/order.service';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';

@Component({
  selector: 'app-event-tickets-list',
  templateUrl: './event-tickets-list.component.html',
  styleUrls: ['./event-tickets-list.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations : fuseAnimations
})
export class EventTicketsListComponent implements OnInit {
  eventData: any;  
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  filter = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    id: 'ticket-list',
    eventFilter: '',
    ticketType: '',
    searchFilter: '',
    slotFilter: '',
    // pageSize: 10,
    itemsPerPage : 5,
    // pageIndex: 1,
    currentPage : 1,
    totalItems:0,
  }

  ticketTypeList;

  dataSource = [];
  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  @ViewChild('search', { static: false }) search;

  dateRangeFilterVal; 
  slotData;

  apiDataStatus = {
    isDataLoading : false 
  }

  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _matDialog: MatDialog,
    private _TicketListService : TicketListService,
    public _orderService: OrderService,
    private _snackbar: MatSnackBar,
    private _router : Router,
    public _addEditEventService: AddEditEventService,
    public _commonService : CommonService
  ) {
    this.eventData = this._addEditEventService.eventData;
    if(this.eventData.isTicketGenerated){
      this.apiDataStatus.isDataLoading = true;
      this.onEventFilterSelect();
      this.getAllEventType();
    }
   }

  ngOnInit(): void {
  }

  onRedirectCreateTicket(){
    this._router.navigate(["/tickets/create"], { queryParams: { eventId: this.eventData?.id, to : 'view'} });
  }

  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value){
      this.filterData();
    }
  }
    

  filterData() {
    console.log("before", this.filter);
     
    const payload = {
      "pageIndex": this.filter.currentPage,
      "pageSize": this.filter.itemsPerPage,
      "search": this.filter.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventId": this.eventData.id,
      "ticketTypeId": this.filter.ticketType,
      "slotId": this.filter.slotFilter,
    }

    this._fuseProgressBarService.show()
    this._TicketListService.getAllTickets(payload).then((result: any) => {
      this._fuseProgressBarService.hide() 
      this.apiDataStatus.isDataLoading = true;

      if (result.status == 200) {
        console.log("this is all data", result.data);
        console.log("after",  this.filter);
        this.dataSource = result.data.data; 
        this.filter.currentPage = result.data.pageIndex;
        this.filter.totalItems = result.data.totalRecords;
      } else if (result.status == 400) {
        this.dataSource = [];
        this.filter.totalItems = 0;
      }
      else {
        this.dataSource = [];
        this.filter.totalItems = 0;
      }
    })
  }


  onEventFilterSelect() {
    const payload = {
      "eventId": this.eventData.id
    }
    this._orderService.getTicketCreatedEventsList(payload).then((result:any) =>{
      this.slotData = result.data[0].schedule;
      if(this.slotData.length == 1){
        this.filter.slotFilter = result.data[0].schedule[0].slotId;
      }else{
        this.filter.slotFilter = '';
      }
    })
    // if(this.paginator){
    //   this.paginator.pageIndex = 0;
    // }
    this.filter.currentPage = 1;
    this.filter.eventFilter = this.eventData.id;
    this.filterData();
  }

  onSlotFilter(val){
    // if(this.paginator){
    //   this.paginator.pageIndex = 0;
    // }
    this.filter.currentPage = 1;
    this.filter.eventFilter = this.eventData.id;
    this.filter.slotFilter = val.value;
    this.filterData();
  }

  getAllEventType(){
    this._commonService.getAllTicketTypes().then((result:any) =>{
        console.log("getAllEventTypes ----->", result);
        this.ticketTypeList = result;
    })
  }

  onTicketTypeFilterSelect(){
    // if(this.paginator){
    //   this.paginator.pageIndex = 0;
    // }
    this.filter.currentPage = 1;
    this.filterData();
  }

  onSearch(val) {
    // if(this.paginator){
    //   this.paginator.pageIndex = 0;
    // }
    this.filter.currentPage = 1
    this.filter.searchFilter = val
    this.filterData();
  }

  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

  clearFilter() {
    // if(this.paginator){
    //   this.paginator.pageIndex = 0;
    // }
    this.range.get('start').reset();
    this.range.get('end').reset();
    this.filter.eventFilter = '';
    this.filter.searchFilter = '';
    this.filter.slotFilter = '';
    this.filter.ticketType = '';
    this.dateRangeFilterVal = '';
    this.filterData()
  }

  onPageChange(event){
    this.filter.currentPage = event;
    this.filterData();
  }

}
