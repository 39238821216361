import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { UserSettingsComponent } from './settings.component';
import { SettingService } from './setting.service';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card'; 
import { LyDialogModule } from '@alyle/ui/dialog'; 
import { ClipboardModule } from '@angular/cdk/clipboard';


const routes:Routes=[
  {path:'',component:UserSettingsComponent,resolve:{
    data:SettingService
  }},
]

@NgModule({
    declarations: [UserSettingsComponent],
    imports: [
      CommonModule,
      RouterModule.forChild(routes),
      MatCardModule,
      MatButtonModule,
      MatExpansionModule,
      LyDialogModule,
      MatFormFieldModule,
      MatIconModule,
      ClipboardModule,
      MatInputModule,
      MatPaginatorModule,
      MatRippleModule,
      MatSortModule,
      MatSnackBarModule,
      MatTableModule,
      MatSelectModule,
      MatDatepickerModule,
      MatTooltipModule,
      FuseSharedModule,
      FuseWidgetModule,
      MatGridListModule,
      NgxMaterialTimepickerModule,
      NgxFileDropModule,
      MatSlideToggleModule
    ]
  })

export class SettingsModule
{
}
