import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FormGroup, UntypedFormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { CommonService } from '@fuse/services/common.service';

import { Enums } from '@fuse/utils/systemEnums';
import { JsonPipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddUserService } from 'app/main/distributors/add-distributors/add-distributor.service';
@Component({
  selector: 'app-deposit-dialog',
  templateUrl: './deposit-dialog.component.html',
  styleUrls: ['./deposit-dialog.component.scss']
})
export class DepositDialogComponent implements OnInit {
  [x: string]: any;
  transId:any = '';
  updateTranForm;
  formvalue: any;
  transTypeIn: any;
  paymentModeIn: any;
  dobMax = new Date()
  updateTranFormData:any;
  
 
  selectEvent;
  paymentMethod;
  transDate;
  wayOfPayment;
  amount;
  addNote;
  enums = Enums;
  totalDues:any;
  /* payment methord */

  
  eventName:any;
  /* mode of payment*/

 

  selectTransType(tevent) {
    this.transTypeIn = tevent.value
  }

  selectPaymentMode(event) {
    this.paymentModeIn = event.value
  }

  constructor(
    private _fb: UntypedFormBuilder,
    private _commonservice: CommonService,
    public _addUserService: AddUserService,
    private snackBar:MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DepositDialogComponent>,
  ) {
    if(this.data){
      this.transId = this.data.id;
      this.selectEvent  = this.data.eventsList;
      this.amount = this.data.amount;
    }
    this.totalDues = this._addUserService.distributor.totalDues;
    console.log("fonding data for validation", this._addUserService.distributor.totalDues);
   }

  ngOnInit() {
    // this.createForm();
    
      this.updateTranForm = this._fb.group({
        "paymentModeId": ['', Validators.required],
        "entityId": ['',Validators.required],
        "amount":['',(Validators.required, Validators.min(1))],
        // "amount":[this.amount?this.amount:'',(Validators.required, Validators.min(1), Validators.max(this.amount))],
        "paidOn":  ['',Validators.required],
        "transactionType": ['', Validators.required],
        "note": ['',],
        "entityType":['events'],
        "distributorId":[this.transId]
      })
    }

  


 /* to get all event list  */
  // getAllEventListIn(){
  //   const payload = {
  //     distributorId: this.transId,
  //     search: ''
  //   }
  //     this._commonservice.getAllEventList(payload).then((result:any)=>{
  //       if(result.status == 200){
  //         console.log('All Event List Success - ', result.data);
  //         this.eventName = result.data;
  //         console.log("New Event Name Is - ", this.eventName);
          
  //       }
  //     })
  // }



  
  /* on submit */
  onSubmitUT() {
    this._commonservice.addUpdateTrans(this.updateTranForm.value).then((result:any)=>{
      if(result.status == 200){
        this.snackBar.open(result.message,"Okay",{duration:2000})
        let distributorId = this._addUserService.distributorId;
        this._addUserService.getAllTransactionForDistributor({"distributorId": distributorId,"transactionType": 0,"pageIndex": 1,"pageSize": 10});
        this.dialogRef.close(true);
      }else{
        this.snackBar.open('something went wrong',"Okay",{duration:2000})
      }
    })
  }


}