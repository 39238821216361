import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AddUpdateTrackService implements Resolve<any> {
  // private _fuseProgressBarService: any;
  eventData;
  trackDetails;
  mode:string;
  
  constructor(
    private _httpClient: HttpClient,
    private _CommonService : CommonService,
    private _ActivatedRoute : ActivatedRoute,
    private _fuseProgressBarService : FuseProgressBarService,
    private _MatSnackBar : MatSnackBar,
    public _addEditEventService: AddEditEventService, 

  ) { 
    this.eventData = this._addEditEventService.eventData;

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
   

    return new Promise((resolve, reject) => { 
      if(route.url[0].path == 'detail' || route.url[0].path == 'update'){
        this.mode = route.url[0].path;
        console.log("route.url[0].path", route.url[0].path);
        console.log("_ActivatedRoute", this._ActivatedRoute);
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getTrackById({"eventId":this.eventData.id,"trackId": route.params.id})
        ]) .then(() => {
          resolve(this.trackDetails);
        }, reject)
      }

    })
  }

  getTrackById(body){
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.apiURL + 'admin/event/getEventTrackById', body, this._CommonService.getHeader()).subscribe((response:any) => {
              console.log("response=-response", response);
              if(response.status == 200){
                this.trackDetails = response.data;
                resolve(response.data);
              }else{
                this.trackDetails = null;
                this._MatSnackBar.open(response.message, 'OKAY', {duration : 2000});
                reject
              }
              this._fuseProgressBarService.hide();
              resolve(response);
          }, reject)
      })
  }


}
