import { Component, OnInit, AfterViewInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AssignEventService } from './assign-event.service';
import { CommonService } from '@fuse/services/common.service';
import { AssignEventDialogComponent } from '@fuse/Dialog/assign-event-dialog/assign-event-dialog.component';
import { AddUserService } from '../add-distributors/add-distributor.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-assign-event',
  templateUrl: './assign-event.component.html',
  styleUrls: ['./assign-event.component.scss']
})
export class AssignEventComponent implements OnInit {
  dialogRef;
  displayedColumns: string[] = ['name', 'totalSeats','availableSeats', 'eventStartDate', 'saleStartDate', 'action'];
  dataSource;
  maxAvailableRecordsInTable;

  constructor(
    public _assignEventService: AssignEventService,
    private _matDialog: MatDialog,
    public _commonService: CommonService,
    public addDistributorService:AddUserService,
    private _ToolbarService : ToolbarService,
    
  ) {
    this.dataSource = new MatTableDataSource(this._assignEventService.eventsList);
    this.maxAvailableRecordsInTable = this._assignEventService.maxAvailableRecordsInTable;

    this.setToolBarTitle();
  }

  ngOnInit() {
    console.log("addDistributorService", this.addDistributorService.distributor);
    
  }
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;


  
  setToolBarTitle(){ 
      // for title
      this._ToolbarService._ToolBarTitle.next('Select Event to Assign ' + this._assignEventService?.distributor?.name + ' as Ticket Distributor');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true); 
  }


  onRowClick(eventId, ticketType) {
    console.log(eventId)
    console.log(ticketType)
    console.log(this._assignEventService?.distributorId)

    this.dialogRef = this._matDialog.open(AssignEventDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus : false,
      data: {
        eventId: eventId,
        ticketType: ticketType,
        distributorId: this._assignEventService?.distributorId,
        commission:this.addDistributorService.distributorCommission
      }
    });
    this.dialogRef.afterClosed()
      .subscribe(response => {
        if (response) {
          this._assignEventService.getUpcommingEvent().then((result: any) => {
            this.dataSource.data = result.data.data
            this.maxAvailableRecordsInTable = result.data.totalRecords
          })

        } else
          return;



      });

  }


  // ----------------------- filters section -----------------------

  dateRangeFilterVal
  filter = {
    distributorId: this._assignEventService?.distributorId,
    search: '',
    pageSize: 10,
    pageIndex: 1
  }


  onSearch(val) {
    console.log("onSearch hit val", val);
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filter.search = val
    this.filterData();
  }

  filterData() {
    this._assignEventService.getUpcommingEvent(this.filter).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }else if(result.status == 400){
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }
  
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.search = ''
    this.search.nativeElement.focus()
    this.filterData()
  }
  
}
