import { Component, OnInit, ViewChild } from '@angular/core';

import { AssignDistributorService } from './assign-distributor.service';
import { DistributorsListService } from 'app/main/distributors/distributors-list/distributors-list.service';
import { AddEditEventService } from '../edit-event/add-edit-event.service';
import { AssignEventDialogComponent } from '@fuse/Dialog/assign-event-dialog/assign-event-dialog.component';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-assign-distributor',
  templateUrl: './assign-distributor.component.html',
  styleUrls: ['./assign-distributor.component.scss']
})
export class AssignDistributorComponent implements OnInit {
  dialogRef
  displayedColumns: string[] = ['id', 'businessName', 'name', 'totalEvents', 'commission', 'action'];
  dataSource;
  maxAvailableRecordsInTable
  ticketType:any = [];
  constructor(
    private _matDialog: MatDialog,
    private _assignDistributorService: AssignDistributorService,
    private _distributorsListService: DistributorsListService,
    private _addEditEventService: AddEditEventService,
    private _ToolbarService : ToolbarService,
    private _router: Router,
    public _commonService: CommonService,
    private snackBar: MatSnackBar
  ) {
    this.setToolBarTitle();
    this.dataSource = new MatTableDataSource(this._assignDistributorService.eventList);
    this.maxAvailableRecordsInTable = this._assignDistributorService.maxAvailableRecordsInTable
  }

  ngOnInit() {
    
    console.log("this isthis._addEditEventService.eventDatav", this._addEditEventService.eventData);
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Distributors');
    // for btn
    this._ToolbarService._isBackBtnShow.next(true);
  }


  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;


  /* get All distributor */



  onRowClick(distributorId, commission, isAssigned) {
    console.log  ("commission", commission, distributorId, isAssigned);
    console.log("this._assignDistributorService.eventTypeList", this._assignDistributorService.eventTypeList);


    let filterDistributor = this._assignDistributorService.eventTypeList.filter(item => item.id == distributorId);

    console.log("filterDistributor", filterDistributor);
    
    this.ticketType = filterDistributor[0].ticketType;
    console.log("this is data here", this.ticketType);

    
    if (isAssigned) {
      this.updateAssignStatus(distributorId, isAssigned ? 1 : 0)
    } else {
      this.dialogRef = this._matDialog.open(AssignEventDialogComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        autoFocus : false,
        width: '400px',
        data: {
          eventId: this._assignDistributorService.eventId,
          ticketType: this.ticketType,
          commission: commission,
          distributorId: distributorId
        }
      });
      this.dialogRef.afterClosed()
        .subscribe(response => {
          if (response) {
            this.filterData()
            // this._distributorsListService.getDistributorList({ forAssign: 1 }).then((result: any) => {
            //   if (result.status == 200) {
            //     this.maxAvailableRecordsInTable = result.data.totalCount
            //     this.dataSource.data=result.data.data
            //   }
            // })

          } else
            return;
        });

    }

  }

  // ----------------------- filters section -----------------------

  dateRangeFilterVal
  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }


  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.filterData();
  }

  filterData() {
    let payload = {
      "forAssign": 1,
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter,
      "eventId": this._assignDistributorService.eventId
    }
    this._distributorsListService.getDistributorList(payload).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data;
        this.maxAvailableRecordsInTable = result.data.totalRecords
      }else if(result.status == 400) {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

  updateAssignStatus(distributorId, isAssigned) {
    let dialog = this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : `Are you sure you want to unassign this?`,
          image : '',
          focus : 'right',
          deleteItem : 'unassign ?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    })
    
    dialog.afterClosed().subscribe((result: any) => {
      if (result.delete) {
        const payload = {
          "distributorId": distributorId,
          "eventId": this._assignDistributorService.eventId,
          "isAssign": isAssigned
        }
        this._commonService.updateAssignStatus(payload).then((result: any) => {
          if (result.status == 200) {
            this.snackBar.open(result.message, "Okay", { duration: 2000 })
            this.filterData()
          }
        })
      }
    })

  }
}
