import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomeUserProfileService } from './dome-user-profile.service';
import { ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { Enums } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-dome-user-profile',
  templateUrl: './dome-user-profile.component.html',
  styleUrls: ['./dome-user-profile.component.scss'],
  animations : fuseAnimations,
  encapsulation : ViewEncapsulation.None
})
export class DomeUserProfileComponent implements OnInit {

  DOME_ORG_SIZE = Enums.DOME_ORG_SIZE;
  DOME_ORG_REVENUE = Enums.DOME_ORG_REVENUE;
  customerData = null;
  selectedIndex:Number = 0;
  dataFlag = {
    isProfileVisitorApiCalled : false,
    isContactExchangeApiCalled : false,
    isContactScannedApiCalled : false,
    isConnectedApiCalled : false,
    isConnectionRequestApiCalled : false
  }
  currentTab:number; 

  tabs = [
    {
      label : 'Profile Views ',
      dataCount : 0,
      data : [],
    },
    {
      label : 'Contact Exchange ',
      dataCount : 0,
      data : [],
    },
    {
      label : 'Contact Scanned ',
      dataCount : 0,
      data : [],
    },
    {
      label : 'Connected ',
      dataCount : 0,
      data : [],
    },
    {
      label : 'Connection Request ',
      dataCount : 0,
      data : [],
    }
  ]

  constructor(
    private _DomeUserProfileService : DomeUserProfileService,
    private _activatedroute : ActivatedRoute,
    private _commonSerivce : CommonService,
  ) {
 
    this._activatedroute.queryParams.subscribe((res)=>{
      if(res?.customer){
        this._DomeUserProfileService.getDomeUserInfo(res?.customer);
        this.selectedIndex = 0;
        this.resetAllFlag();
      }
    })

    this._DomeUserProfileService.customerData.subscribe((res)=>{
      if(res?.id){
        this.customerData = res; 
        this.setDataCountInTabs();
        this.tabs[0].data = [];
        this.tabs[1].data = [];
        this.tabs[2].data = [];
        this.tabs[3].data = [];
        this.tabs[4].data = [];
        this.onTabChange({index : 0})
      }
    })

  }

  setDataCountInTabs(){
    this.tabs[0].dataCount = this.customerData?.profileView || 0;
    this.tabs[1].dataCount = this.customerData?.contactExchange || 0;
    this.tabs[2].dataCount = this.customerData?.contactScanned || 0;
    this.tabs[3].dataCount = this.customerData?.connectedWith || 0;
    this.tabs[4].dataCount = this.customerData?.ConnectRequestSent || 0;
  }

  ngOnInit(): void {
  }

  resetAllFlag(){
    this.dataFlag = {
      isProfileVisitorApiCalled : false,
      isContactExchangeApiCalled : false,
      isContactScannedApiCalled : false,
      isConnectedApiCalled : false,
      isConnectionRequestApiCalled : false
    } 
  }

  onTabChange(event, filterOutData?){
    this.currentTab = event.index;
    switch (event.index) {
      case 0: 
        if(this.customerData?.profileView){
          const payload = {
            customerId:this.customerData.id,
            search:"",
            pageIndex:1,
            pageSize:9
          }
          if(!this.dataFlag?.isProfileVisitorApiCalled){
            this._commonSerivce.userProfileList(payload).then((res:any)=> {
              if(res.status == 200){
                this.tabs[event.index].data = res.data;
                this.dataFlag.isProfileVisitorApiCalled = true;
              }else{this.tabs[event.index].data = []}
            })
          }else{
            if(filterOutData){
              console.log("userProfileList", filterOutData);
              this._commonSerivce.userProfileList(filterOutData).then((res:any)=> {
                if(res.status == 200){
                  this.tabs[event.index].data = res.data;
                  this.dataFlag.isProfileVisitorApiCalled = true;
                }else{this.tabs[event.index].data = []}
              })
            }
          }
        }
        break;

      case  1:
        if(this.customerData?.contactExchange){
          const payload = {
            customerId:this.customerData.id,
            search:"",
            pageIndex:1,
            pageSize:9
          }
          if(!this.dataFlag?.isContactExchangeApiCalled){
            this._commonSerivce.contactExchangeUser(payload).then((res:any)=>{
              if(res.status == 200){
                this.tabs[event.index].data = res.data;
                this.dataFlag.isContactExchangeApiCalled = true;
              }else{this.tabs[event.index].data = []}
            })
          }else{
            if(filterOutData){
              this._commonSerivce.contactExchangeUser(filterOutData).then((res:any)=>{
                if(res.status == 200){
                  this.tabs[event.index].data = res.data;
                  this.dataFlag.isContactExchangeApiCalled = true;
                }else{this.tabs[event.index].data = []}
              })
            } 
          }
        }
      break;

      case 2:
        if(this.customerData?.contactScanned){
          const payload = {
            customerId:this.customerData.id,
            search:"",
            pageIndex:1,
            pageSize:9
          }
          if(!this.dataFlag?.isContactScannedApiCalled){
            this._commonSerivce.contactScannedUser(payload).then((res:any)=>{
              if(res.status==200){
                this.tabs[event.index].data = res.data;
                this.dataFlag.isContactScannedApiCalled = true;
              }else{this.tabs[event.index].data = []}
            })
          }else{
            if(filterOutData){
              this._commonSerivce.contactScannedUser(filterOutData).then((res:any)=>{
                if(res.status==200){
                  this.tabs[event.index].data = res.data;
                  this.dataFlag.isContactScannedApiCalled = true;
                }else{this.tabs[event.index].data = []}
              })
            }
          }
        }
      break; 

      case 3:
        if(this.customerData?.connectedWith){
          const payload = {
            customerId:this.customerData.id,
            search:"",
            pageIndex:1,
            pageSize:9
          }
          if(!this.dataFlag?.isConnectedApiCalled){
            this._commonSerivce.connectedUserList(payload).then((res:any)=>{
              if(res.status==200){
                this.tabs[event.index].data = res.data;
                this.dataFlag.isConnectedApiCalled = true;
              }else{this.tabs[event.index].data = []}
            })
          }else{
            if(filterOutData){
              this._commonSerivce.connectedUserList(filterOutData).then((res:any)=>{
                if(res.status==200){
                  this.tabs[event.index].data = res.data;
                  this.dataFlag.isConnectedApiCalled = true;
                }else{this.tabs[event.index].data = []}
              })
            }
          }
        }
      break;

      case 4:
        if(this.customerData?.ConnectRequestSent){
          const payload = {
            customerId:this.customerData.id,
            search:"",
            pageIndex:1,
            pageSize:9
          }
          if(!this.dataFlag?.isConnectionRequestApiCalled){
            this._commonSerivce.connectionRequestSentUsers(payload).then((res:any)=>{
              if(res.status==200){
                this.tabs[event.index].data = res.data;
                this.dataFlag.isConnectionRequestApiCalled = true;
              }else{this.tabs[event.index].data = []}
            })
          }else{
            if(filterOutData){
              this._commonSerivce.connectionRequestSentUsers(filterOutData).then((res:any)=>{
                if(res.status==200){
                  this.tabs[event.index].data = res.data;
                  this.dataFlag.isConnectionRequestApiCalled = true;
                }else{this.tabs[event.index].data = []}
              })
            }
          }
        }
      break;
    }

  }


  onPageChange(event){
    event.customerId = this.customerData.id;
    const payload = {
      customerId : this.customerData.id,
      search: event.search,
      pageIndex: event.currentPage,
      pageSize: 9
    }
    this.onTabChange({index:this.currentTab}, payload);
  }
}
