import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Enums } from '@fuse/utils/systemEnums';
import { Subject, fromEvent, merge, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FuseUtils } from '@fuse/utils';
import { DataSource } from '@angular/cdk/collections';
import { TicketListService } from './ticket-list.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { DeleteTicketDialogComponent } from '@fuse/Dialog/delete-ticket-dialog/delete-ticket-dialog.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';

@Component({
    selector: 'app-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.scss'],
    animations : fuseAnimations

})
export class TicketListComponent implements OnInit {
    enum = Enums;

    dataSource
    displayedColumns = ['ticketTitle', 'ticketType', 'schedule',  'eventName',  'totalTickets', 'maxMember'];

    maxAvailableRecordsInTable

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;


    constructor(
        public _ticketListService: TicketListService,
        private _matDailog : MatDialog,
        public _commonservice : CommonService,
        public _ToolbarService : ToolbarService,
    ) {
        this.maxAvailableRecordsInTable = this._ticketListService.maxAvailableRecordsInTable;
        this.setToolBarTitle();
    }

    ngOnInit() {
        this.dataSource = new MatTableDataSource(this._ticketListService.allTickets)
    }
    
    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next('Tickets');
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }

    // ----------------------- filters section -----------------------

    dateRangeFilterVal

    filter = {
        dateRangeFilter: {
            startDate: 0,
            endDate: 0
        },
        typeFilter: 0,
        searchFilter: '',
        pageSize: 10,
        pageIndex: 1,
        eventId: 0,
        eventStatus: 0
    }

    onStatusSelect() {
        this.filter.pageIndex = 1
        this.filterData();
    }
    onEventSelect() {
        this.filter.pageIndex = 1
        this.filterData();
    }
    onTypeFilterSelect() {
        this.filter.pageIndex = 1
        this.filterData();
    }
    onSearch(val) {
        this.filter.pageIndex = 1
        this.filter.searchFilter = val
        this.filterData();
    }

    filterData() {
        let payload = {
            "pageIndex": this.filter.pageIndex,
            "pageSize": this.filter.pageSize,
            "search": this.filter.searchFilter,
            "startDate": this.filter.dateRangeFilter.startDate ? this.filter.dateRangeFilter.startDate : 0,
            "endDate": this.filter.dateRangeFilter.endDate ? this.filter.dateRangeFilter.endDate : 0,
            "ticketTypeId": this.filter.typeFilter,
            "eventId": this.filter.eventId,
            "eventStatus": this.filter.eventStatus,
        }
        this._ticketListService.getAllTickets(payload).then((result: any) => {
            if (result.status == 200) {
                this.dataSource.data = result.data.data
                this.maxAvailableRecordsInTable = result.data.totalRecords
            }
            else if(result.status == 400){
                this.dataSource.data = [];
                this.maxAvailableRecordsInTable = 0;
                // resolve(result)
              }
            else {
                this.dataSource.data = [];
                this.maxAvailableRecordsInTable = 0
            }
        })
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.filterData()
    }
    @ViewChild('search', { static: true }) search;
    clearSearch() {
        this.filter.searchFilter = ''
        this.search.nativeElement.focus()
        this.filterData()
    }
    clearFilter() {
        this.filter.dateRangeFilter = {
            startDate: 0,
            endDate: 0
        };
        this.filter.eventId = 0
        this.filter.eventStatus = 0
        this.filter.typeFilter = 0
        this.filter.searchFilter = ''
        this.dateRangeFilterVal = ''
        this.filterData()
        this.paginator.pageIndex = 0;
    }

    openDeleteTicketDailog(){
        this._matDailog.open(DeleteTicketDialogComponent, {
            panelClass: ["no-padding-dialog", "delete-ticket-dialog"],
            autoFocus : false,
        })
    }
}
