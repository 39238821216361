import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { reject } from 'lodash';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl, DefaultFilter } from '../../../../@fuse/utils/systemEnums';

@Injectable({
  providedIn: 'root'
})
export class GroupsService implements OnDestroy {
  allContactsDetails: any
  maxAvailableRecordsInTable: any
  maxAvailableRecordsInTable2: any
  allGroupDetails: any
  groupDetails: any;
  contactDetail: any;
  memberDetail;
  groupId: any;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
    private _matSnackBar: MatSnackBar,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve,reject) => {
      if(route.params.groupId){
        this.groupId = route.params.groupId
        Promise.all([
          this.getGroupById(route.params.groupId),
          this.getAllContacts({"pageIndex":1 , "pageSize":10, "search":""}),
          this.getGroupContactlist({'id':Number(route.params.groupId), 'search' : '', 'pageIndex' : 1, 'pageSize' : 10})
        ]).then(()=>{
          resolve('');
        })
      }
      else if(route.params.editId){
        Promise.all([
            this.getContactDetail(route.params.editId)
          ]).then(
            () => {
              resolve(''); 
            },
            reject
          );
      }
      else if(route.routeConfig.path == 'all-contacts'){
        Promise.all([
            this.getAllContacts({"pageIndex":1 , "pageSize":10, "search":""}),
          ]).then(
            () => {
              resolve(''); 
            },
            reject
          );
      }
      else{
      Promise.all([
        // this.eventList({pageSize:10, pageIndex:1, listingType:2})
        // this.allContact()
          // this.getAllContacts({"pageIndex":1 , "pageSize":10, "search":""}),
          console.log("else case run"),
          this.getAllContacts({"pageIndex":0, "pageSize":0, "search":""}),
          this.getGroups({"pageIndex":1 , "pageSize":10, "search":""})
        ]).then(
          () => {
            resolve(''); 
          },
          reject
        );
        
      }
    })
  }


  ngOnDestroy() {
    this.contactDetail = '';
  }

  getGroupContactlist(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getGroupContactlist', body, this._commonService.getHeader()).subscribe((response:any) => {
        if(response.status == 200){
          this._fuseProgressBarService.hide();
          this.memberDetail = response.data;
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }else{
          this.memberDetail = '';
          this._fuseProgressBarService.hide();
          this.maxAvailableRecordsInTable = 0;
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }
        resolve(this.memberDetail)
      }, reject)
    })
  }

  getAllContacts(body?){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getAllContact', body || {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            this.allContactsDetails = response.data.contactList
            this.maxAvailableRecordsInTable = response.data.totalRecords 
          }else{
            this.allContactsDetails = []
            this.maxAvailableRecordsInTable = 0; 
          }
          resolve(response);
          // this._matSnackBar.open(response.message, 'OK', {
          //   duration: 2000,
          //   verticalPosition: "bottom"
          // })
        }, reject);
    });
  }

  deleteContact(id){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/deleteContact', {id:id}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            console.log("this is response from delete contact api",response);
            // this.getAllContacts()
          }
          resolve(response);
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }, reject);
    });
  }

  updateContact(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/updateContact', body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            resolve(response);
          }
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }, reject);
    });
  }

  getContactDetail(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getContactById', {id:body}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            this.contactDetail = response.data
            resolve(response);
          }else{
            this._matSnackBar.open(response.message, 'OK', {
              duration: 2000,
              verticalPosition: "bottom"
            })
          }
        }, reject);
    });
  }

  addContact(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/contact', body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            // this.getAllContacts()
            resolve(response)
          }
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }, reject);
    });
  }


  // DETAILS OF GROUP
  getGroups(body?){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getAllContactGroups',body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            this.allGroupDetails = response.data.groupList
            this.maxAvailableRecordsInTable2 = response.data?.totalRecords
          }else{
            this.allGroupDetails = [];
            this.maxAvailableRecordsInTable2 = 0;
          }
          resolve(response);
        }, reject);
    });
  }

  createGroup(body?){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/createGroup', body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          console.log("this is response of create groups api",response);  
          if (response.status == 200) {
            // this.allGroupDetails = response.data
            this.getGroups()
          }
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
          resolve(response);
        }, reject);
    });
  }

  updataGroup(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/updateGroup', body, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            resolve(response);
          }
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }, reject);
    });

  }

  deleteGroup(id){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/deleteGroup', {id:id}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            console.log("this is response from delete group api",response);
            // this.getAllContacts()
          }
          resolve(response);
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }, reject);
    });

  }

  getGroupById(id){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getGroupById', {id:id}, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            this.groupDetails = response.data
            resolve(response);
          }else{
            this._matSnackBar.open(response.message, 'OK', { duration: 2000, verticalPosition: "bottom"})
            this.groupDetails = '';
          }
        }, reject);
    });
  }

  removeContactFromGroup(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/removeContact', body, this._commonService.getHeader()).subscribe((response: any) => {
        this._fuseProgressBarService.hide()
        if(response.status == 200){
          resolve(response)
        }
        this._matSnackBar.open(response.message, 'OK', { duration: 2000, verticalPosition: "bottom"})
      }, reject);
    });
  }

  onExport(body){
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/exportContacts', body, this._commonService.getHeader()).subscribe((response: any) => {
        this._fuseProgressBarService.hide()
        if(response.status == 200){
          resolve(response)
        }
        this._matSnackBar.open(response.message, 'OK', { duration: 2000, verticalPosition: "bottom"})
      }, reject);
    });
  }

}
