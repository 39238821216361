import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { TicketTypeService } from 'app/main/masters/ticket-type/ticket-type.service';
import { AuthService } from 'app/main/pages/auth.service';
import { EventListService } from 'app/main/events/events-list/event-list.service';
import { CommonService } from '@fuse/services/common.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class TicketListService implements Resolve<any>{
  allTickets
  allTicketTypes
  maxAvailableRecordsInTable
  permissions: any;
  allEvents: any;
  constructor(
    private _httpClient: HttpClient,
    private _ticketTypeService: TicketTypeService,
    private _authService: AuthService,    
    private _fuseProgressBarService: FuseProgressBarService,
    private _eventListService: EventListService,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.permissions = this._authService.getPermissionsForCurrentPage(route);

    return new Promise((resolve, reject) => {

      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllTickets(),
        this._ticketTypeService.getAllTicketType({pageSize:'0',pageIndex:'0'}).then((result: any) => {
          if(result.status == 200){
            this.allTicketTypes = result.data.ticketTypes;
          }else{
            this.allTicketTypes = '';
          }
        }),
        this._eventListService.getEventsList().then((result) => {
          if (result.status == 200){
            this.allEvents = result.data.data
          }
          else if(result.status == 400){
            this.allEvents = []
            // this.maxAvailableRecordsInTable = 0;
            // resolve(result)
          }
          else{
            this.allEvents = []
          }
        }),
      ]).then(
        () => {
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );
    });
  }

  getAllTickets(payload?): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/tickets/getalltickets', payload ? payload : { "search": "","ticketTypeId": 0,"pageIndex": 1,"pageSize":10,"eventId": 0,"eventStatus": 0} , this._commonService.getHeader())
      .subscribe((response: any) => {
          if (response.status == 200) {
            this.allTickets = response.data.data;
            
            this.maxAvailableRecordsInTable = response.data.totalRecords
          }
          else if(response.status == 400){
            this.allTickets = []
            this.maxAvailableRecordsInTable = 0;
            resolve(response)
          }
           else {
            this.allTickets = []
          }
          resolve(response);
        }, reject);
    });
  }

  getTicketNameById(id) {
    let typeArr = this.allTicketTypes.filter((type) => {
      return type.id == id
    })
    if (typeArr[0])
      return typeArr[0].title
    else
      return ''
  }

}
