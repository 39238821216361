import { Component, OnInit, ViewChild, ViewEncapsulation, OnChanges, DoCheck } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ElementRef, NgZone } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { AddVenueService } from './add-venue.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']  
})

export class AddComponent implements OnInit {

  venueForm: UntypedFormGroup;

  latitude: number;
  longitude: number;
  zoom: number = 8;
  address: string;
  mapLoadingTimes = 0;
  private geoCoder;

  venueId: any = '' //it contains id only in edit mode

  @ViewChild('search', { static: true })
  public searchElementRef: ElementRef;

  constructor(
    private _fb: UntypedFormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    public _addVenueService: AddVenueService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _fuseProgressBarService: FuseProgressBarService,
    private _activatedRoute: ActivatedRoute,
    private _ToolbarService : ToolbarService,
    public _commonService:CommonService
  ) { 

    this._activatedRoute.queryParams.subscribe((param) => {
      if (param['id']) {
        this.venueId = param['id']
        this.createForm(this._addVenueService.venue);
        // this.geoCoder = new google.maps.Geocoder;
        // this.getAddress(this._addVenueService.venue.lattitude,this._addVenueService.venue.longitude)
        this.latitude = this._addVenueService.venue.lattitude
        this.longitude = this._addVenueService.venue.longitude
        this.zoom = 15
        this.venueForm.disable()
        this.setToolBarTitle(true);
      } else {
        this.setCurrentLocation();
        this.createForm();
        this.setToolBarTitle(false);
      }
    })
    console.log(this.venueForm.status)
  }

  ngOnInit() {

    this.loadPlaceAutoComplete();

   
  }

  setToolBarTitle(res){
    if(res){
      // for title
      this._ToolbarService._ToolBarTitle.next('Edit Venue');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    }else{
      // for title
      this._ToolbarService._ToolBarTitle.next('Add New Venue');
      // for btn
      this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  onDelete() {

  }
  onUpdate(data, id) {
    this._addVenueService.updateVenue(data, id).then(() => {
      this._router.navigate(['/masters/venue'])
    })
  }

  createForm(venue?) {
    this.venueForm = this._fb.group({
      title: [venue ? venue.name : '', Validators.required],
      venue: ['', Validators.required], //this is new added for search required.
      landMark: [venue ? venue.landMark : ''],
      city: [venue ? venue.city : ''],
      state: [venue ? venue.state : ''],
      country: [venue ? venue.country : ''],
      zipcode: [venue ? venue.zipCode : ''],
      lat: [venue ? venue.lattitude : ''],
      long: [venue ? venue.longitude : ''],
      completeAdd: [venue ? venue.fullAddress : '']
    })

  }

  onAddVenue() {
    this._fuseProgressBarService.show();
    if (this.venueForm.valid) {
      console.log(this.venueForm.value)
      this._addVenueService.addVenue(this.venueForm.value).then((result: any) => {

        if (result.status == 200) {
          this._fuseProgressBarService.hide();
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 2000
          })
          this._router.navigate(['/masters/venue'])
        }

      }, err => {
        this._matSnackBar.open(err.message, 'OK', {
          verticalPosition: 'bottom',
          duration: 2000
        })
      })
    }
  }

  // Get Current Location Coordinates 
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    console.log($event);
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  getAddress(latitude, longitude) {
    console.log("geoCoder", this.geoCoder);
    if(this.geoCoder)
      this.geoCoder?.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {

        if (status === 'OK') {
          if (results[0]) {
            if (!this.zoom)
              this.zoom = 12;
            this.address = results[0].formatted_address;

            // if ((this.mapLoadingTimes > 1) || this.venueId) {
              this.handleAddressChange(results[0])
            // }

            this.mapLoadingTimes++

          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }

      });
  }

  public handleAddressChange(address) {
    
    const addCompLength = address.address_components.length
    console.log(address)

    const addressParts = {
      // landmark: address.address_components.filter(item => {
      //   if (item.types[0] == 'landmark')
      //     return item.long_name
      // })[0],

      // locality: address.address_components.filter(item => {
      //   if (item.types[2] == 'sublocality_level_2')
      //     return item.long_name
      // })[0],

      landMark: address.address_components.filter(item => {
        if (item.types[2] == 'sublocality_level_1')
          return item.long_name
      })[0],

      city: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_2')
          return item.long_name
      })[0],

      state: address.address_components.filter(item => {
        if (item.types[0] == 'administrative_area_level_1')
          return item.long_name
      })[0],

      country: address.address_components.filter(item => {
        if (item.types[0] == "country")
          return item.long_name
      })[0],

      zipcode: address.address_components.filter(item => {
        if (item.types[0] == "postal_code")
          return item.long_name
      })[0],
    }

    console.log(addressParts)

    // if (addressParts.locality)
    //   this.venueForm.get('locality').setValue(addressParts.locality.long_name)
    if (addressParts.landMark)
      this.venueForm.get('landMark').setValue(addressParts.landMark.long_name)
    if (addressParts.city)
      this.venueForm.get('city').setValue(addressParts.city.long_name)
    if (addressParts.state)
      this.venueForm.get('state').setValue(addressParts.state.long_name)

    if (addressParts.country)
      this.venueForm.get('country').setValue(addressParts.country.long_name)

    if (addressParts.zipcode)
      this.venueForm.get('zipcode').setValue(addressParts.zipcode.long_name)

    this.venueForm.get('lat').setValue(address.geometry.location.lat())
    this.venueForm.get('long').setValue(address.geometry.location.lng())
    this.venueForm.get('completeAdd').setValue(address.formatted_address)
  }

  loadPlaceAutoComplete() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      if (!this.venueId)
        this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;
      

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          //set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude, this.longitude)
        });
      });
    });
  }

}
