import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { SpeakerService } from '../speaker.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { MatDialog } from '@angular/material/dialog';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { LyDialog } from '@alyle/ui/dialog';
import { fuseAnimations } from '@fuse/animations';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
    selector: 'app-add-edit-speaker',
    templateUrl: './add-edit-speaker.component.html',
    styleUrls: ['./add-edit-speaker.component.scss'],
    animations : fuseAnimations
})
export class AddEditSpeakerComponent implements OnInit {
    form: UntypedFormGroup;
    croppedProfileBase64: any = null;
    isView:boolean = false;
    hostTypesList:[] = []
    /* second Table Transaction list table*/
    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;
    checkNumberStatus = 1; // 1 == not checked, 2 == checking...,  3 == checked but not found, 4 == checked and found.
    preferredCountries: CountryISO[] = [
      CountryISO.UnitedStates,
      CountryISO.UnitedKingdom,
    ];
    defCountryCode:any = 'IN';


    constructor(
        private _fb: UntypedFormBuilder,
        private _router: Router,
        public _commonService: CommonService,
        private _ToolbarService : ToolbarService,
        private _matDialog: MatDialog,
        private _dialog: LyDialog,
        private _cd : ChangeDetectorRef,
        private _matSnackBar: MatSnackBar,
        public _SpeakerService: SpeakerService,
        private _ActivatedRoute: ActivatedRoute
    ) {

        this.hostTypesList = this._SpeakerService.hostTypesList;

        if (this._SpeakerService.speakerId) {
            this.form = this.createForm(this._SpeakerService.speakerDetail); 
            this.setToolBarTitle(true);
            this._ActivatedRoute.url.subscribe((res)=>{
                if(res){
                    if(res[0]?.path == 'view'){
                        this.form.disable();
                        this.isView = true;
                    }else{
                        this.isView = false;
                    }
                }
            })
        } else {
            this.form = this.createForm();
            this.setToolBarTitle(false);
        }

      
        
    }

    onEdit(){
        this.form.enable();
        this.isView = false;
        this.setToolBarTitle(true);
        this._ToolbarService._ToolBarTitle.next('Edit This Host');
        this._ToolbarService._isBackBtnShow.next(true);
    }

    setToolBarTitle(res){
        if(res){ 
                this._ToolbarService._ToolBarTitle.next('Host Details'); 
                this._ToolbarService._isBackBtnShow.next(true); 
        }else{
            // for title
            this._ToolbarService._ToolBarTitle.next('Add New Host');
            // for btn
            this._ToolbarService._isBackBtnShow.next(false);
        }
      }
      
    onDelete(id){
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : 'Are you sure you want to remove this Host?',
                image : '/assets/ui/delete.png',
                focus : 'right',
                deleteItem : 'Delete Speaker?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            }
        })

        dialog.afterClosed().subscribe((result)=> {
            if(result){
            this._SpeakerService.ondeleteFaqQuestion({"speakerId":id}).then((result: any) => {
                if (result) {
                this._router.navigate(['/speaker'])
                }
            })
            }
        })
    }

    createForm(data?) { 
        if(data?.image){
            this.croppedProfileBase64 = data.image;
        }
        const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";
        return this._fb.group({
            name: [data?.name || '', Validators.required],
            image: [data?.image || '', Validators.required],
            position: [data?.position || ''],
            orgName: [data?.orgName ? data.orgName : ''],
            isCustomer : [data?.isCustomer || ''],
            email : [data?.email || '',[Validators.required, Validators.email]],
            mobile : [data?.mobile || '', Validators.required],
            aboutSp: [data?.about || ''],
            hostTypeId : [String(data?.hostTypeId) || '', Validators.required],
            instaLink: [data?.instaLink || '', Validators.pattern(reg)],
            fbLink: [data?.fbLink || '', Validators.pattern(reg)],
            linkedinLink: [data?.linkedinLink || '', Validators.pattern(reg)],
            xLink : [data?.xLink || '', Validators.pattern(reg)]
        })
    }


    ngOnInit(): void {
        this.form.get('mobile').valueChanges.subscribe((result)=> {
            console.log("result", result);

            if(result){
                // allow china 11 digit 
                if((result.countryCode === 'CN')){
                    if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
                        this.form.get('mobile').setErrors(null);
                    }else{
                        this.form.get('mobile').setErrors({validatePhoneNumber : {valid : false}});
                    }
                }

                if(this.checkNumberStatus == 4 || this.checkNumberStatus == 3){
                    this.checkNumberStatus = 1;
                    this._cd.detectChanges();
                }
            }
        })
    }

    onSubmit() {
        if(this.form.valid){
            if(this._SpeakerService?.speakerId){
                const payload = {
                    "name": this.form.get('name').value,
                    "image":this.form.get('image').value,
                    "position":this.form.get('position').value,
                    "orgName":this.form.get('orgName').value,
                    "about":this.form.get('aboutSp').value,
                    "hostTypeId" : this.form.get('hostTypeId').value,
                    "instaLink":this.form.get('instaLink').value,
                    "fbLink":this.form.get('fbLink').value,
                    "linkedinLink":this.form.get('linkedinLink').value,
                    "xLink" : this.form.get('xLink').value,
                    "speakerId" : this._SpeakerService?.speakerId,
                    "email" : this.form.get('email').value,
                    "isCustomer" : this.form.get('isCustomer').value ? 1 : 0,
                    "mobile": this.form.get('mobile').value?.number.split(" ").join(""),
                    "phoneCode" : this.form.get('mobile').value?.dialCode,
                }
                this._SpeakerService.updateSpeaker(payload).then((result:any) => {
                    if(result.status == 200){
                        this._router.navigate(['/speaker'])
                    }
                })
            }else{
                const payload = {
                    "name": this.form.get('name').value,
                    "image":this.form.get('image').value,
                    "position":this.form.get('position').value,
                    "orgName":this.form.get('orgName').value,
                    "hostTypeId" : this.form.get('hostTypeId').value,
                    "about":this.form.get('aboutSp').value,
                    "instaLink":this.form.get('instaLink').value,
                    "fbLink":this.form.get('fbLink').value,
                    "xLink" : this.form.get('xLink').value,
                    "linkedinLink":this.form.get('linkedinLink').value,
                    "isCustomer" : this.form.get('isCustomer').value ? 1 : 0,
                    "email" : this.form.get('email').value,
                    "mobile": this.form.get('mobile').value?.number.split(" ").join(""),
                    "phoneCode" : this.form.get('mobile').value?.dialCode,
                }
                this._SpeakerService.onAddSpeaker(payload).then((result:any) => {
                    if(result.status == 200){
                        this._router.navigate(['/speaker'])
                    }
                })
            }
        }
    }

    // on remove logo
    onRemoveProfile() {
        this.croppedProfileBase64 = null;
        this.form.get('image').setValue('');
    }
  
    openCropperForProfile(event) { 
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 250,
                    height: 250,
                    round : false,
                    type: 'image/png',
                    keepAspectRatio: false,
                    responsiveArea: true,
                    output: {
                        // width: 320,
                        // height: 320
                    },
                    resizableArea: true
                }
            },
            width: 520,
            height : 520,
            disableClose: true
          }).afterClosed.subscribe((result:any) => {
            if (result) {
                console.log("result", result);
                console.log("this is pre event", event); 
                this.croppedProfileBase64 = result.file.dataURL;
                this._cd.markForCheck();
                this.onUploadImage(result.file, result.blobFile)
            }
          });
    }

    // on upload Image presignedURL API
    onUploadImage(fileObj, blobFile) {
        const payload = {
            "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
            "fileName": 'profile_',
            "folderName": 'speakerMedia', // it is fix set by backend.
            'mimeType': fileObj?.type
        }
        return new Promise((resolve, reject) => {
            this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
                if (result.status == 200) {
                    this.form.get('image').setValue(result.data.imageUploadUrl);
                    // payload for get Image upload status
                    const payload = {
                        'id': result.data.id,
                        'status': 1 // fix by backend.
                    }
                    this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
                    this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                        if (uploadStatusResponse.status == 200) {
                            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            resolve(true);
                        } else {
                            this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                            reject();
                        }
                    })
                }
            })
        })
    }


    ngOnDestroy() {
        this._SpeakerService.speakerId = undefined;
        this._SpeakerService.speakerDetail = undefined;
    }
}
