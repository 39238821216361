import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { PollsListComponent } from './polls-list/polls-list.component';
import { PollComponent } from './poll/poll.component';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PollService } from './poll/poll.service';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PollsListService } from './polls-list/polls-list.service';
import { MatRippleModule } from '@angular/material/core';
const routes: Routes = [
  {
    path: '', component: PollsListComponent,
    resolve: { data: PollsListService }
  },
  // {
  //   path: 'add', component: PollComponent,
  //   resolve: {
  //     data: PollService
  //   }
  // },
  {
    path: ':type', component: PollComponent,
    resolve: {
      data: PollService
    }
  },
  {
    path: ':type/:id', component: PollComponent,
    resolve: {
      data: PollService
    }
  },
]


@NgModule({
  declarations: [
    PollsListComponent,
    PollComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRippleModule
  ],
})
export class PollsModule { }
