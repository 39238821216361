import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrganiserListComponent } from './organiser-list/organiser-list.component';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { OrganiserListService } from './organiser-list/organiser-list.service';
// import { AddOrganiserDialogComponent } from '@fuse/Dialog/add-organiser-dialog/add-organiser-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes:Routes=[
  {path:'',component:OrganiserListComponent,resolve:{data:OrganiserListService}},
  

]


@NgModule({
    declarations: [OrganiserListComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatSelectModule,
        MatDatepickerModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        MatToolbarModule,
        MatDialogModule
    ]
})
export class OrganiserModule { }
