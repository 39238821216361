import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './main/pages/auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleAuthGuardService implements CanActivate {

  toolbarVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private _matSnackBar: MatSnackBar,
    private _authService: AuthService
  ) {
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const userToken = localStorage.getItem('userToken')

    if (!userToken) {
      this.router.navigate(['/auth/login'])
    } else {

      // console.log('route 1', route)
      // console.log('ck path 1', route.url[0].path)

      let permissionsForCurrentPage:any = this._authService.getPermissionsForCurrentPage(route)
      console.log("permissionsForCurrentPage-->", permissionsForCurrentPage)
      
      if (true) {
        console.log(route.pathFromRoot[1].routeConfig.path);
        //hadle masters
        if (route.pathFromRoot[1].routeConfig.path == 'masters') {
          if (permissionsForCurrentPage.view){
            // console.log('permission given');
            return true
            
          }
          else{
            console.log('not given ');
            return this._authService.noPermissionMessage();

          }
        } else {

          //manages other pages
          if (route.url[0].path == 'add' || route.url[0].path == 'create') {
            if (permissionsForCurrentPage.add)
              return true
            else
              return this._authService.noPermissionMessage();
          } else if (route.url[0].path == 'event') {
            if (permissionsForCurrentPage.assignEvent)
              return true
            else
              return this._authService.noPermissionMessage();
          }
          else if (route.url[0].path == 'view') {
            if (permissionsForCurrentPage.view)
              return true
            else
              return this._authService.noPermissionMessage();
          }
          else if (route.url[0].path == 'bulk-order') {
            if (permissionsForCurrentPage.bulkTicketCreate)
              return true
            else
              return this._authService.noPermissionMessage();
          }
          else if(route.url[0].path =='ticketSaleHistory'){
            if (permissionsForCurrentPage.add)
            return true
            else
            return this._authService.noPermissionMessage();
          }
          else if (route.url[0].path == 'distributor') {             //for assigning distributor from event page
            // console.log('in the distributers');
            
            if (permissionsForCurrentPage.assignDistributor)
              return true
            else
              return this._authService.noPermissionMessage();
          }
          else
            return this._authService.noPermissionMessage();
        }

      } else
        return this._authService.noPermissionMessage();


      //check permissions except view

      // const permissions = this._authService.getPermissions()
      // let index = permissions.findIndex((item) => {
      //   return item.url == '/' + route.url[0].path
      // })

      // if (index >= 0 && permissions[index].permission.view) {
      //   return true;
      // }
      // else {
      //   this._matSnackBar.open('You are not permitted to use this page', 'OK', {
      //     duration: 4000
      //   });
      // return false
      // }

    }
  }
}
