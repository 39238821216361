import { Component, OnInit, ViewChild } from '@angular/core';
import { CertificateTemplatesService } from './certificate-templates.service';
import { HttpStatusCode } from '@angular/common/http';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-certificate-templates',
  templateUrl: './certificate-templates.component.html',
  styleUrls: ['./certificate-templates.component.scss'],
  animations : fuseAnimations
})
export class CertificateTemplatesComponent implements OnInit {
  searchFilter:string = '';
  @ViewChild('search', { static: true }) search;

  allTemplates : [] = [];
  constructor(
    private _ToolbarService: ToolbarService,
    private _CertificateTemplatesService: CertificateTemplatesService,
    private _MatDialog : MatDialog,
    private _MatSnackBar : MatSnackBar,
    private _fuseProgressBarService : FuseProgressBarService,
    private _CommonService : CommonService
  ) {
    this.setToolBarTitle();
    this.allTemplates = this._CertificateTemplatesService.allCertificate;
  }

  setToolBarTitle() {
    // for title
    this._ToolbarService._ToolBarTitle.next("Certificate Templates");
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  ngOnInit(): void {
  }

  

  clearSearch() {
    this.searchFilter = '';
    this.filterData()
  }

  onDelete(id){
    const dialog = this._MatDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this Certificate Template?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Certificate Template?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result: any) => {
      if (result) {
        this._CommonService.onDeleteCertificate({id : String(id)}).then((res: any) => {
          if (res.status == 200) {
            this._MatSnackBar.open(res.message, 'OKAY', { duration: 2000 })
            this.filterData();
          }else{
            this._MatSnackBar.open(res.message, 'OKAY', { duration: 2000 })
          }
        })
      }
    })
  }

  filterData(){
    const payload = {
      search : this.searchFilter,
    }
    this._CertificateTemplatesService.onGetAllCertificateList(payload).then((res: any) => {
      if (res.status == 200) {
        this.allTemplates = res.data;
        console.log("allTemplates", this.allTemplates);
        
      }  
      else {
        this.allTemplates = []
      }
    })
  }
 
}
