import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCardModule} from '@angular/material/card';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { DistributorsListService } from './distributors-list/distributors-list.service';
import { CustomersListComponent } from './distributors-list/distributors-list.component';
import { AddUserComponent } from './add-distributors/add-distributor.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatRadioModule} from '@angular/material/radio';
import {AddUserService} from "./add-distributors/add-distributor.service";
import { AssignEventComponent } from './assign-event/assign-event.component';
import { AssignEventService } from './assign-event/assign-event.service';

import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { TransactionHistoryComponent } from './add-distributors/transaction-history/transaction-history.component';
import { AsignedEventsComponent } from './add-distributors/asigned-events/asigned-events.component';
import { TicketsSaleHistoryComponent } from './add-distributors/tickets-sale-history/tickets-sale-history.component';
import { TicketsSaleHistoryService } from './add-distributors/tickets-sale-history/tickets-sale-history.service';
import { TotalSaleHistoryComponent } from './add-distributors/total-sale-history/total-sale-history.component';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material'
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { LyButtonModule } from '@alyle/ui/button';
import { LyIconModule } from '@alyle/ui/icon';
import { LyDialogModule } from '@alyle/ui/dialog';

const routes: Routes = [
    {
        path     : '',
        component: CustomersListComponent,
        resolve  : {
            data: DistributorsListService
        },
        
    },
    {
        path     : 'edit/:id',
        component: AddUserComponent,
        resolve  : {
            data: AddUserService
        }
    },
    {
        path     : 'event/:id',
        component: AssignEventComponent,
        resolve  : {
            data: AssignEventService
        },
        canActivate:[RoleAuthGuardService]
    },
    {
        path     : 'add',
        component: AddUserComponent,
        resolve  : {
            data: AddUserService
        },
        canActivate:[RoleAuthGuardService]
    },
    {
        path     : 'ticketSaleHistory',
        component: TicketsSaleHistoryComponent,
        resolve  : {
            data: TicketsSaleHistoryService
        },
        // canActivate:[RoleAuthGuardService]
    },
   
];

@NgModule({
    declarations: [
        CustomersListComponent,
        AddUserComponent,
        AssignEventComponent,
        TransactionHistoryComponent,
        AsignedEventsComponent,
        TicketsSaleHistoryComponent,
        TotalSaleHistoryComponent
    ],
    imports: [
        RouterModule.forChild(routes),
        MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        LyImageCropperModule,
        LySliderModule,
        LyButtonModule,
        LyIconModule,
        LyDialogModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatDialogModule,
        MatMenuModule,
        MatGridListModule,
        MatRadioModule,
        NgxDaterangepickerMd.forRoot(),
        NgxFileDropModule,
        ReactiveFormsModule,
        // BsDropdownModule.forRoot(),
        NgxIntlTelInputModule,
    ],
    providers: [
        DistributorsListService,
        AddUserService,
    ],
})
export class DistributorsModule
{
}