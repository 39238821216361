import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SurveyListService {
  tableData: any;

  constructor(private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
    private _httpClient: HttpClient) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this._fuseProgressBarService.show(),
        this.getSurveysList(1, 10, '')
      ]).then(
        () => {
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );
    });
  }

  public getSurveysList(pageIndex, size, searchtxt): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + `admin/survey/get?page=${pageIndex}&size=${size}&searchtxt=${searchtxt}`, this._commonService.getHeader())
        .subscribe((response: any) => {
          this.tableData = response.data;
          resolve(response);
        }, reject);
    });
  }


  public deleteSurvey(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(environment.apiURL + `admin/survey/delete/${id}`, this._commonService.getHeader())
        .subscribe((response: any) => {
          this.tableData = response.data;
          resolve(response);
        }, reject);
    });
  }
}
