import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-add-new-dynamic-variable',
  templateUrl: './add-new-dynamic-variable.component.html',
  styleUrls: ['./add-new-dynamic-variable.component.scss']
})
export class AddNewDynamicVariableComponent implements OnInit {
  form : UntypedFormGroup;
  fieldTypes = ['text', 'number']

  constructor(
    private _fb : UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public _data,
    private _commonService : CommonService,
    public _matDialogRef: MatDialogRef<AddNewDynamicVariableComponent>,
    public _matSnackBar : MatSnackBar,
  ) { 
    console.log("_data", this._data.fields);
  }

  ngOnInit(): void {
    this.form = this.createForm();
  }

  createForm(){
    let names = [];
    // this._data?.fields?.forEach(element =>{
    //   names.push(element.name)
    // });
    return this._fb.group({
      fieldName : ['', [Validators.required, this.disallowedStringsValidator(names)]],
      useAsDynamicVariable : [0]
      // fieldType : ['', Validators.required],
    })
  }

  onSubmit(){
    const payload = {
      "name" : "{{" + this.form.get('fieldName').value.replace(/\s+/g, '').toLowerCase() + "}}",
      "createField": this.form.get('fieldName').value.replace(/\s+/g, ''),
      "dynamicVariable" : this.form.get('useAsDynamicVariable').value ? 1 : 0,
      "isDeletable" : 1
    }
    // console.log("this is payload", payload);
    this._commonService.addNewDynamicField(payload).then((result:any) => {
      if(result.status == 200){
        this._matDialogRef.close(true);
      }
      this._matSnackBar.open(result.message, "OKAY", {duration : 2000})
    })
  }
  
  disallowedStringsValidator(disallowedStrings: string[]): ValidatorFn {
    return (control: UntypedFormControl): { [key: string]: any } | null => {
      const forbidden = disallowedStrings.some(str => control.value && control.value == str);
      return forbidden ? { 'disallowedStrings': { value: control.value } } : null;
    };
  }
  
}

