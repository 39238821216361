import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VerifiersListComponent } from './verifiers-list/verifiers-list.component';
import { AddVerifierComponent } from './add-verifier/add-verifier.component';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { VerifiersListService } from './verifiers.service';
import {MatRadioModule} from '@angular/material/radio';
import { AddVerifierService } from './add-verifier/add-verifier.service';
import { EventListDialogComponent } from './add-verifier/event-list-dialog/event-list-dialog.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { SacnHistoryListComponent } from './add-verifier/sacn-history-list/sacn-history-list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LyDialogModule } from '@alyle/ui/dialog';
import { VerifiersScanLocationListComponent } from './verifiers-scan-location/verifiers-scan-location-list.component';
import { VerifiersScanLocationService } from './verifiers-scan-location/verifiers-scan-location.service';
import { ClipboardModule } from '@angular/cdk/clipboard';

const routes:Routes=[
  {path:'',component:VerifiersListComponent, resolve  : {data: VerifiersListService}},
  {path:'add',component:AddVerifierComponent,resolve:{data:AddVerifierService},canActivate:[RoleAuthGuardService]},
  {path:'edit',component:AddVerifierComponent,resolve:{data:AddVerifierService}},
  {path:'scan-history', component:SacnHistoryListComponent},
  {path:'verifiers-scan-location', component : VerifiersScanLocationListComponent, resolve : {data : VerifiersScanLocationService}}
]

@NgModule({
    declarations: [VerifiersListComponent, AddVerifierComponent, EventListDialogComponent,VerifiersScanLocationListComponent, SacnHistoryListComponent],
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatRippleModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatSelectModule,
        ClipboardModule,
        LyDialogModule,
        MatDatepickerModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        MatRadioModule,
        MatToolbarModule,
        MatDialogModule,
        NgxDaterangepickerMd.forRoot(),
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        NgxFileDropModule,
    ]
})
export class VerifiersModule { }
