import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
// import { ApiEndPointUrl, ReqHeader } from '../../../../@fuse/utils/systemEnums';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'app/main/pages/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: "root"
})
export class DistributorsListService implements Resolve<any>
{
    distributors: any[] = [];
    maxAvailableRecordsInTable
    permissions: any = {}
    reqHeadersWithToken = new HttpHeaders({
        'Authorization': localStorage.getItem('userToken'),
        'Content-Type': 'application/json'
    });
    verifiers: any;

    constructor(
        private _httpClient: HttpClient,    
        private _matDialog: MatDialog,
        private _fuseProgressBarService: FuseProgressBarService,
        private _authService: AuthService,
        private _commonService: CommonService,
    ) {
        
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.permissions = this._authService.getPermissionsForCurrentPage(route);

        console.log('permissions', this.permissions)
        return new Promise((resolve, reject) => {

            Promise.all([
                this._fuseProgressBarService.show(),
                this.getDistributorList()
            ]).then(
                () => {
                    resolve('');
                    this._fuseProgressBarService.hide();
                },
                reject
            ).catch(() => {
                this._fuseProgressBarService.hide();
            });
        });
    }

    getDistributorList(payload?): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getalldistributors', payload ? payload : {}, this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.distributors = response.data.data;
                        this.maxAvailableRecordsInTable = response.data.totalRecords
                    }else if(response.status == 400){
                        this.distributors = []
                        this.maxAvailableRecordsInTable = 0;
                    }
                     else {
                        this.distributors = []
                        this.maxAvailableRecordsInTable = 0;
                    }
                    resolve(response);
                }, reject);
        });
    }
    
    getVerifierList(payload?): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'verifiers/getallverifiers', payload ? payload : {}, this._commonService.getHeader())
                .subscribe((response: any) => {
                    if (response.status == 200) {
                        this.verifiers = response.data.data;
                        this.maxAvailableRecordsInTable = response.data.totalRecords
                    }else if(response.status == 400){
                        this.verifiers = [];
                        this.maxAvailableRecordsInTable = 0;
                    }
                     else {
                        this.verifiers = []
                        this.maxAvailableRecordsInTable = 0;
                    }
                    resolve(response);
                }, reject);
        });
    }

   

    deleteDistributor(id) {
        const httpOptions:any = {
            headers: this.reqHeadersWithToken,
            body: {
                distributorId: id
            }
        }
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiURL + 'admin/distributor/deletedistributor', httpOptions)
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });

    }

    /**
    * Adds customer name
    * 
    * @returns {Promise<any>}
    */



}

