import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { CommonService } from "@fuse/services/common.service";
import { FaqService } from "app/main/faq/faq.service";
import { EventCategoryService } from "app/main/masters/event-category/event-category.service";
import { GuestListService } from "app/main/masters/guest/guest-list/guest-list.service";
import { OrganiserListService } from "app/main/masters/organiser/organiser-list/organiser-list.service";
import { SponsorService } from "app/main/masters/sponsor/sponsor.service";
import { VenueListService } from "app/main/masters/venue/list/venue-list.service";
import { AuthService } from "app/main/pages/auth.service";
import { ResourcesService } from "app/main/resources/resources.service";
import { HostGroupService } from "app/main/speaker/host-group/host-group.service";
import { TaxesService } from "app/main/tax/taxes.service";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable, Subscription } from "rxjs";

@Injectable()
export class AddEditCommonDataProviderService implements Resolve<any> {
  eventTypes = [];
  eventCategory = [];
  _allGuest: BehaviorSubject<[]> =  new BehaviorSubject([]);
  _allOrganisers: BehaviorSubject<[]> =  new BehaviorSubject([]);
  _allVenues: BehaviorSubject<[]> =  new BehaviorSubject([]);
  _speakersList: BehaviorSubject<[]> = new BehaviorSubject([]);
  _allSponsorsGroup: BehaviorSubject<[]> = new BehaviorSubject([]);
  _allHostGroupList: BehaviorSubject<[]> = new BehaviorSubject([]);
  _faqListData: BehaviorSubject<[]> = new BehaviorSubject([]);
  _resourseList: BehaviorSubject<[]> = new BehaviorSubject([]);
  _TaxListData: BehaviorSubject<[]> = new BehaviorSubject([]);

  _currentCase: BehaviorSubject<string> = new BehaviorSubject('');

  eventId:number;
  public subscription: Subscription;
  permissions;
  eventData:any;
  ticketData;

  constructor(
    private _commonService: CommonService,
    private _HostGroupService: HostGroupService,
    private _authService: AuthService,
    private _eventCategoryService : EventCategoryService,
    private _resourcesService :  ResourcesService,
    private _taxesService : TaxesService,
    private _httpClient: HttpClient,
    private _sponsorService : SponsorService,
    private _venueListService: VenueListService,
    private _faqService : FaqService, 
    private _organiserListService: OrganiserListService,
    private _guestListService: GuestListService,
  ) { 
    console.log("new service call==================->>>>>>>>>>><<<<<<<<<<<<<");
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    console.log("route.url[0].path", route.url[0].path);
    if(route.url[0].path == 'add'){
      return new Promise((resolve, reject) => { 
        Promise.all([
          this.callApiForGetData(),
        ]).then(()=>{
          this._currentCase.next('add');
          resolve('')
        }, reject)
      })
    }else{
      return new Promise((resolve, reject) => {
        Promise.all([
          this.getEventDetail(route.params.id),
          this.callApiForGetData()
        ]).then(()=>{
          console.log("EDIT CASE RESOVLE");
          this._currentCase.next('edit');
          resolve('')
        }, reject)
      })
    }

  }


  callApiForGetData(){ 
    // get Event Type.
    this.getEventTypes();

    // get Event Categories
    this.getEventCategories();

    // get all guest 
    this.getAllGuest();

    // get all org.
    this.getAllOrg();

    // get all Venues
    this.getAllVenues();

    // get all hosts.
    this.getAllSpeaker();

    // get all sponsor group.
    this.getAllSponsorGroup();

    // get all host group.
    this.getAllHostGroup();

    // get all FAQ data
    this.getAllFAQs();

    // get all Resources 
    this.getAllResources();

    // get All taxes list
    this.getAllTaxes()
  }

  getAllTaxes(){
    this._taxesService.getTaxlist({pageIndex : 0, pageSize : 0}).then((result:any)=> {
      if(result?.status == 200){
        this._TaxListData.next(result.data.data);
      }else{
        this._TaxListData.next([]);
      }
    })
  }

  getTicketByid(payload?) {
    // this._fuseProgressBarService.show();
    const data = {
      "eventId": this.eventId,
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/getall/eventTickets', payload ? payload : data, this._commonService.getHeader()).subscribe((response: any) => {
          if(response.status == 200){
            // this._fuseProgressBarService.hide();
            this.ticketData = response.data.EventListing;
          }else{
            this.ticketData = [];
          }
          resolve(response);
        }, reject);
    });
  }

  getEventDetail(id,isCallTicket?){
    const payload = {
      "eventId": id
    }
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/geteventbyid', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this.eventData = response.data;
          if(isCallTicket && response.data.isTicketGenerated){
            // this.getAllTickets();
          } 
          resolve(response)
        })
      })
  }
  

  getAllResources(){
    return new Promise((resolve, reject) => {
    this._resourcesService.getAllResources({pageSize : 0, pageIndex: 0, search: ''}).then((res:any)=>{
      if(res.status == 200){
        this._resourseList.next(res.data.resourseList);
        resolve(true)
      }else{
        this._resourseList.next([]);
      }
    }, reject)
    })
  }
  

  getAllFAQs(){
    return new Promise((resolve, reject) => { 
      this._faqService.getFAQListData({pageIndex : '0', pageSize : '0'}).then((result:any)=> {
        if(result.status == 200){
          this._faqListData.next(result.data.getFaqList);
          resolve(true)
        }else{
          this._faqListData.next([]);
        }
      }, reject)
    })
  }

  getAllHostGroup(){
    return new Promise((resolve, reject) => { 
      this._HostGroupService.getAllHostGroup({ "pageIndex": 0, "pageSize": 0, 'search': '' }).then((result: any) => {
          if (result.status == 200) {
            this._allHostGroupList.next(result.data.getAllHostGroup)
            resolve(true)
          } else {
            this._allHostGroupList.next([])
          }
      }, reject)
    })
  }

  getAllSponsorGroup(){
    return new Promise((resolve, reject) => { 
      this._sponsorService.getAllSponsorsGroups({"search": "","pageIndex": 0,"pageSize": 0}).then((result:any) => {
        if(result.status == 200){
          this._allSponsorsGroup.next(result.data.getAllSponsorGroup);
          resolve(true)
        }else{
          this._allSponsorsGroup.next([]);
        }
      }, reject);
    })
  }
  
  getAllSpeaker(){
    return new Promise((resolve, reject) => { 
      this._commonService.getAllSpeaker({pageIndex : 0, pageSize : 0}).then((result:any) => {
        if(result.status == 200){
          this._speakersList.next(result.data.data);
          resolve(true)
        }else{
          this._speakersList.next([]);
        }
      }, reject)
    })
  }

  getAllVenues(){
    return new Promise((resolve, reject) => { 
      this._venueListService.getAllVenues({ pageSize: "0", pageIndex: "0" }).then((result: any) => {
        if (result.status == 200) {
            this._allVenues.next(result.data.data);
        } else {
          this._allVenues.next([])
        }
        resolve(true)
      }, reject);
    })
  }


  getEventTypes(){
    return new Promise((resolve, reject) => { 
      this._commonService.getAllEventTypes({ pageIndex: '0', pageSize: '0' }).then((result:any) => {
        if(result){
          this.eventTypes = result?.filter((item)=> item.isApprove);
        }else{
          this.eventTypes = [];
        }
        resolve(true)
      }, reject)
    })
  }


  getEventCategories(){
    return new Promise((resolve, reject) => { 
      this._eventCategoryService.getAllEventCategory({ pageIndex: '0', pageSize: '0' }).then((result: any) => {
        if(result?.status == 200){
          this.eventCategory = result?.data?.data?.filter((item)=> item.isApprove);
        }else{
          this.eventCategory = [];
        }
        resolve(true)
      }, reject);
    })
  }


  getAllGuest(){
    return new Promise((resolve, reject) => { 
      this._guestListService.getAllGuest({ pageIndex: '0', pageSize: '0' }).then((result: any) => {
        if(result.status == 200){
          this._allGuest.next(result.data.data);
        }else{
          this._allGuest.next([]);
        }
        resolve(true)
      }, reject)
    })
  }

  getAllOrg(){
    return new Promise((resolve, reject) => { 
      this._organiserListService.getAllOrganisers({ pageIndex: '0', pageSize: '0' }).then((result: any) => {
        if (result.status == 200) {
          this._allOrganisers.next(result.data.data);
        }else{
          this._allOrganisers.next([])
        }
        resolve(true)
      }, reject)
    })
  }

  

}
