import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class TaxesService implements Resolve<any>{
  taxList: any = new BehaviorSubject([]);
  totalTaxes:number;

  constructor(
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
  ) {

  }
  reqHeadersWithToken = new HttpHeaders({
    'Authorization': localStorage.getItem('userToken'),
    'Content-Type': 'application/json'
  });


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

    return new Promise((resolve, reject) => {
      Promise.all([
        this._fuseProgressBarService.show(),
        this.getTaxlist({ pageSize: 10, pageIndex: 1 }),
      ]).then(
        () => {
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );
    });

  }


  // get all taxes list
  getTaxlist(body) {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getAllTaxs', body, this._commonService.getHeader()).subscribe((response: any) => {
        if (response.status == 200) {
          this.taxList.next(response.data.data);
          this.totalTaxes = response.data.totalRecords;
          resolve(response);
        }
        else if (response.status == 400) {
          this.taxList.next([]);
          this.totalTaxes = 0;
          resolve([])
        }
        else if(response.status == 201){
          this.taxList.next([]);
          this.totalTaxes = 0;
          resolve([])
        }
        else {
          this.taxList.next([]);
          this.totalTaxes = 0;
          resolve([])
        }
      }, reject)
    })
  }


  // add new tax function
  public addTax(payload) {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/addTax', payload, this._commonService.getHeader()).subscribe((response: any) => {
        if (response.status == 200) {
          this._fuseProgressBarService.hide();
          this._matSnackBar.open(response.message, 'OK', {
            verticalPosition: "bottom",
            duration: 2000
          })
          resolve(response);
        } else {
          this._matSnackBar.open(response.message, 'OK', {
            verticalPosition: "bottom",
            duration: 2000
          })
        }
      }, reject)
    })
  }



  public editTax(payload) {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/updateTax', payload, this._commonService.getHeader()).subscribe((response: any) => {
        if (response.status == 200) {
          this._fuseProgressBarService.hide();
          this.getTaxlist({pageSize: 10, pageIndex:1});
          this._matSnackBar.open(response.message, 'OK', {
            verticalPosition: "bottom",
            duration: 2000
          })
          resolve(response);
        } else {
          this._matSnackBar.open(response.message, 'OK', {
            verticalPosition: "bottom",
            duration: 2000
          })
        }
      }, reject)
    })
  }



  public deleteTax(payload) {
    const options: any = {
      headers: this.reqHeadersWithToken,
      body: {
        id: payload
      }
    }
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.delete(environment.apiURL + 'admin/deleteTax', options).subscribe((response: any) => {
        if (response.status == 200) {
          this.getTaxlist({pageSize: 10, pageIndex:1});
        }
        this._fuseProgressBarService.hide();
        resolve(response)
      }, reject)
    })
  }


}
