import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from '../order.service';

@Component({
  selector: 'app-select-event-dialog',
  templateUrl: './select-event-dialog.component.html',
  styleUrls: ['./select-event-dialog.component.scss']
})
export class SelectEventDialogComponent implements OnInit {
  dataSource
  displayedColumns: string[] = ['name'];
  constructor(
    public matDialogRef: MatDialogRef<SelectEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _orderService: OrderService
  ) {
    this.dataSource = new MatTableDataSource(this._orderService.upcommingPublishedEvents);
  }

  ngOnInit() {
  }

  onEventSelect(id) {
    const selectedIndex = this._orderService.upcommingPublishedEvents.findIndex(event => event.id == id) 
    
    this.matDialogRef.close({ index: selectedIndex })
  }

}
