import { Injectable } from '@angular/core';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/main/pages/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class VenueListService implements Resolve<any>{
  allVenues;
  maxAvailableRecordsInTable;
  permissions: any;

  constructor(
    private _httpClient: HttpClient,
    private _matSnackBar: MatSnackBar,
    private _fuseProgressBarService: FuseProgressBarService,
    private _authService: AuthService,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getAllVenues()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    });
  }

  getAllVenues(filter?): Promise<any> {
    const myPayload = {
      pageSize:10, 
      pageIndex:1
    }
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/getall/venue', filter ? filter : myPayload, this._commonService.getHeader())
        .subscribe((response: any) => {
          if (response.status == 200) {
            this.allVenues = response.data.data
            this.maxAvailableRecordsInTable = response.data.totalRecords
            resolve(response);
          }else if(response.status == 400){
            this.allVenues = [];
            this.maxAvailableRecordsInTable = 0;
            resolve(response);
          }else {
            this.allVenues = [];
            this.maxAvailableRecordsInTable = 0;
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
            reject(response)
          }
          this._fuseProgressBarService.hide();
        }, reject);
    });
  }

}
