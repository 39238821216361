import { Component, OnInit, Inject, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AddTicketTypeService } from './add-ticket-type.service';
import { LyDialog } from '@alyle/ui/dialog';
import { CropperComponent } from '../cropper/cropper.component';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({ 
  selector: 'app-add-dialog',
  templateUrl: './add-ticket-type-dialog.component.html',
  styleUrls: ['./add-ticket-type-dialog.component.scss']
})
export class AddTicketTypeDialogComponent implements OnInit {

  previewUrl;
  isImageValid = true;
  form: UntypedFormGroup;
  imageFile;
  croppedImageBase64;
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef

  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddTicketTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _cd : ChangeDetectorRef,
    private _dialog: LyDialog,
    private _snackbar : MatSnackBar,
    private _commonService : CommonService,
    private _addTicketTypeService:AddTicketTypeService
  ) { }

  ngOnInit() {
    if (this._data.ticketType) {
      this.createFrom(this._data.ticketType)
    } else {
      this.createFrom()
    }
  }

   onSubmit() {
   
    if (this._data.ticketType)
      this._addTicketTypeService.editTicketType(this.form.value, this._data.ticketType.id).then(() => {
        this.matDialogRef.close(true)
      })
    else
      this._addTicketTypeService.addTicketType(this.form.value).then(() => {
        this.matDialogRef.close(true)
      })
  }

  createFrom(data?) {
    this.form = this._fb.group({
      "typeName": [data ? data.title : '', Validators.required],
      "icon": [data ? data.icon : ''],
      "desc": [data ? data.description : '']
    })
    if(data)
    this.previewUrl = data.icon;
    this.croppedImageBase64 = data.icon;
  }

  removeImage() {
    this.fileUpload.nativeElement.value = '';
    this.previewUrl = '';
    this.form.get('icon').setValue('');
  }


  openCropperForBg(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
        data: {
            fileEvent: event,
            myConfig: {
                width: 250,
                height: 250,
                round : false,
                type: 'image/png',
                keepAspectRatio: true,
                responsiveArea: true,
                output: {
                    width: 320,
                    height: 320
                },
                resizableArea: true
            }
        },
        width: 520,
        height : 520,
        disableClose: true
      }).afterClosed.subscribe((result:any) => {
        if (result) {
            console.log("result", result);
            console.log("this is pre event", event); 
            this.croppedImageBase64 = result.file.dataURL;
            this._cd.markForCheck();
            this.onUploadImage(result.file, 'bg', result.blobFile)
        }
      });
  }


    // on upload Image presignedURL API
    onUploadImage(fileObj, type, blobFile) {

      const payload = {
          "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
          "fileName": 'icon_',
          "folderName": 'ticket/type', // it is fix set by backend.
          'mimeType': fileObj?.type
      }

      console.log("payload", payload);


      return new Promise((resolve, reject) => {
          this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
              if (result.status == 200) { 
                  this.form.get('icon').setValue(result.data.imageUploadUrl);  
                  // payload for get Image upload status
                  const payload = {
                      'id': result.data.id,
                      'status': 1 // fix by backend.
                  }
                  this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
                  this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                      if (uploadStatusResponse.status == 200) {
                          console.log("uploadStatusResponse", uploadStatusResponse);
                          this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                          resolve(result.data);
                      } else {
                          this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                          reject();
                      }
                  })
              }
          })
      })

    }


  readUrl(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        this.isImageValid = true;
      } else {
        this.isImageValid = false;
      }
      if (this.isImageValid) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrl = reader.result;
        }
        this.form.get('icon').setValue(file)
      }
    }
  }

}
