import { Component, OnInit, ViewChild, NgModule  } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ReportedEventsService } from "./reported-events-service.ts.service";
import { ToolbarService } from "app/layout/components/toolbar/toolbar.service";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";


@Component({
  selector: "app-reported-events",
  templateUrl: "./reported-events.component.html",
  styleUrls: ["./reported-events.component.scss"],
  animations : fuseAnimations
})
export class ReportedEventsComponent implements OnInit {
  

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;
    //   @ViewChild("search", { static: true })
    //   search;
    

    customerSubList;
    allEventList;

    reasonId;
    eventId;

    maxAvailableRecordsInTable;
    tableData = []
    dataSource;
    displayedColumns = ['eventName','email','createdDate','reason','message'];
    
    range = new UntypedFormGroup({
        start: new UntypedFormControl(null),
        end: new UntypedFormControl(null),
    });
    

    constructor(
        private _reportedEvents: ReportedEventsService,
        private _ToolbarService : ToolbarService,
    ) {
        this.setToolBarTitle();
        this.customerSubList = this._reportedEvents.customerSubjectList;
        this.allEventList = this._reportedEvents.allEventList;
        this.maxAvailableRecordsInTable = this._reportedEvents.maxAvailableRecordsInTable;
        
    }

    ngOnInit() {
        this.tableData = this._reportedEvents.allReportedEvents;
        this.dataSource = new MatTableDataSource(this.tableData);
    }

    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next('Reported Events');
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }

    onDateChange(){
        if(this.range.controls['start'].value && this.range.controls['end'].value)
            this.onReportedEvents();
    }

    // ----------------------- filters section -----------------------
    dateRangeFilterVal;
    filter = {
        dateRangeFilter: {
            startDate: 0,
            endDate: 0
        },
        allEvent: "",
        subjects: "",
        pageSize: 10,
        pageIndex: 1,
        reasonId: 0,
        eventId: 0,
    }
   
    clearFilter() {
        this.paginator.pageIndex = 0;
        this.filter.dateRangeFilter = {
            startDate: 0,
            endDate: 0
        };
        this.range.get('start').reset();
        this.range.get('end').reset();
        this.filter.allEvent = '0';
        this.filter.subjects = '0';
        this.dateRangeFilterVal = "";
        this.reasonId = 0,
        this.eventId = 0,
        this.onReportedEvents();
    }
    
    onSubjectsSelect($event) {
        this.reasonId = $event.value;
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1;
        this.onReportedEvents();
    }

    onEventSelect($event){
        this.eventId = $event.value;
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1;
        this.onReportedEvents();
    }
    

    onReportedEvents(){
        let payload = {
            "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
            "endDate" : this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
            "eventId" : this.eventId ? this.eventId :0,
            "reason" :  this.reasonId ? this.reasonId: 0,
            "pageIndex":  this.filter.pageIndex,
            "pageSize": this.filter.pageSize,
        }
        this._reportedEvents.getAllReport(payload).then((result: any) =>{
            if (result.status == 200) {
                console.log("all getting data is here", result);
                this.dataSource.data = result.data.data;
                console.log("2 total records", this.dataSource.data);
                
                this.maxAvailableRecordsInTable = result.data.totalRecords;
                console.log("total records", this.maxAvailableRecordsInTable);
            }else if (result.status == 400) {
                console.log("no records found");
                this.dataSource.data = [];
                this.maxAvailableRecordsInTable = 0;
            }
            else {
                this.dataSource.data = [];
                this.maxAvailableRecordsInTable = 0;
            }
        })
    }

    getCustomerSubListById(id){
        let subject;
        this.customerSubList.forEach((item)=> {
            if(item.id == id){
                subject = item.subjects;
            }
        })
        return subject
    }

    onDateFilterChanges(e) {
        if (e.endDate != null && e.startDate != null) {
            this.paginator.pageIndex = 0;
            this.filter.dateRangeFilter.endDate = +e.endDate
            this.filter.dateRangeFilter.startDate = +e.startDate
            this.onReportedEvents()
        }
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.onReportedEvents()
    }

}
