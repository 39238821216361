import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { AuthService } from 'app/main/pages/auth.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketsSaleHistoryService implements Resolve<any>{
  [x: string]: any;
  permissions: any;
  allTicketsSaleHistory: any;
  maxAvailableRecordsInTable: any;
  eventId: any;
  distributorId: any;
  allTicketTypes: any;
  constructor(
    private _httpClient: HttpClient,
    private _matDialog: MatDialog,
    private _fuseProgressBarService: FuseProgressBarService,
    private _authService: AuthService,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    this.eventId = route.params.eventId
    this.distributorId = route.params.distributorId
    
    console.log('permissions', this.permissions)
    return new Promise((resolve, reject) => {

        Promise.all([
          this._fuseProgressBarService.show(),
          //  this.getTicketsHistoryByEvents(),
           this.getAllTicketTypeTicketHistory(),
           this.getAllEventList(),
        ]).then(
            () => {
                resolve('');
                this._fuseProgressBarService.hide();
            },
            reject
        );
    });
}





getTicketsHistoryByEvents(payload?){

  const filter = {
      "distributorId": this.distributorId,
      "eventId":this.eventId,
      "pageIndex":1,
      "pageSize":10,
      "search":"",
      "startDate":0,
      "endDate":0,
      "type":0
    }
    
  return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/distributor/getTicketsHistoryByEvents', payload ? payload : filter , this._commonService.getHeader())
          .subscribe((response: any) => {
              if (response.status == 200) {
                  this.allTicketsSaleHistory = response.data.ticketHistory;
                  this.maxAvailableRecordsInTable = response.data.totalRecords
              }else if(response.status == 201){
                  this.allTicketsSaleHistory = [];
                  this.maxAvailableRecordsInTable = 0;
              }else if(response.status == 400){
                  this.allTicketsSaleHistory = [];
                  this.maxAvailableRecordsInTable = 0;
              }
              else {
                  this.allTicketsSaleHistory = [];
                  this.maxAvailableRecordsInTable = 0;
              }
              resolve(response);
          }, reject);
  });
}


getAllTicketTypeTicketHistory(payload?) {
  return new Promise((resolve, reject) => {
    // this._fuseProgressBarService.show()
    this._httpClient.post(environment.apiURL + 'admin/tickets/getall/type',payload?payload:{pageSize:10,pageIndex:1}, this._commonService.getHeader())
      .subscribe((result: any) => {
        if (result.status == 200) {
          this.allTicketTypes = result.data;
          this.allTicketTypeForFilter = result.data.ticketTypes
          resolve(result.data)
        }
        else if(result.status == 400){
          this.allTicketTypes = []
          // this.maxAvailableRecordsInTable = 0;
          resolve(result.data)
        }
        // this._fuseProgressBarService.hide()
      }, reject)
  })
}



getAllEventList() {
  return new Promise((resolve, reject) => {
    this._httpClient.post(environment.apiURL + 'admin/events/getallevents', {}, this._commonService.getHeader())
      .subscribe((result: any) => {
        if (result.status == 200) {
          console.log("for filter ", result.data);
          resolve(result.data)
        }
        else if(result.status == 400){
          resolve(result.data)
        }
      }, reject)
  })
}

}