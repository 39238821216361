import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl, DefaultFilter } from '../../../../@fuse/utils/systemEnums';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { EventTypeService } from 'app/main/masters/event-type/event-type.service';
import { AuthService } from 'app/main/pages/auth.service';
import { TicketTypeService } from 'app/main/masters/ticket-type/ticket-type.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { DefaultFilter } from '@fuse/utils/systemEnums';


@Injectable({
  providedIn: 'root'
})
export class EventListService implements Resolve<any> {
  events;
  totalEvents
  allEventTypes
  permissions: any;
  typeQueryParmas: any;
  allTicketTypes: any;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _eventTypeService: EventTypeService,
    private _authService:AuthService,
    private _ticketTypeService: TicketTypeService,
    private _commonService: CommonService,
  ) { 
    this._eventTypeService.getAllEventTypes().then((result:any) => {
      
      this.allEventTypes = result
      // console.log('success',this.allEventTypes)
    })
  }
  reqHeadersWithToken = new HttpHeaders({
    'Authorization': localStorage.getItem('userToken'),
    'Content-Type': 'application/json'
  });

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    
    
    this.typeQueryParmas = route.queryParams.type
    return new Promise((resolve, reject) => {

      Promise.all([
        this._fuseProgressBarService.show(),
        this.getEventsList(DefaultFilter),
        this._ticketTypeService.getAllTicketType({pageSize:'0',pageIndex:'0'}).then((result: any) => {
          if(result.status == 200){
            this.allTicketTypes = result.data.ticketTypes;
          }else{
            this.allTicketTypes = '';
          }
        }),
      ]).then(
        () => {
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );
    });
  }

  getEventsList(filter?): Promise<any> {
    // this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/events/getallevents', filter ? filter : {}, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide();
          if (response.status == 200) {
            this.events = response.data.data;
            this.totalEvents = response.data.totalRecords
          }else if(response.status == 400){
            this.events = []
            this.totalEvents = 0;
            resolve(response)
          }
          resolve(response);
        }, reject);
    });
  }

  deleteEvent(id) {
    const payload = {
      "eventId": id
    }

    const options:any = {
      headers: this.reqHeadersWithToken,
      body: payload
    }

    return new Promise((resolve, reject) => {
      this._httpClient.delete(environment.apiURL + 'admin/events/deleteevent', options)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  publishEvent(id) {
    // this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/publish', { eventId: id, isPublish: 1 }, this._commonService.getHeader())
        .subscribe((response: any) => {
          // this._fuseProgressBarService.hide()
          if (response.status == 200) {
            this.getEventsList({pageIndex:1,pageSize:10}).then((result) => {
              resolve(result);
            });
          }
          this._matSnackBar.open(response.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
        }, reject);
    });
  }

  // unPublishEvent(id) {
  //   this._fuseProgressBarService.show()
  //   return new Promise((resolve, reject) => {
  //     this._httpClient.post(environment.apiURL + 'admin/event/publish', { eventId: id, isPublish: 0 }, this._commonService.getHeader())
  //       .subscribe((response: any) => {
  //         this._fuseProgressBarService.hide()
  //         if (response.status == 200) {
  //           this.getEventsList().then(() => {
  //             resolve(response);
  //           });

  //         }
  //         this._matSnackBar.open(response.message, 'OK', {
  //           duration: 2000,
  //           verticalPosition: "bottom"
  //         })
  //       }, reject);
  //   });
  // }

}

