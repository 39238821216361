import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-add-ticket-guidelines',
  templateUrl: './add-ticket-guidelines.component.html',
  styleUrls: ['./add-ticket-guidelines.component.scss']
})
export class AddTicketGuidelinesComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AddTicketGuidelinesComponent>,) { }

  ngOnInit() {
  }

}
