import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';


@Component({
  selector: 'app-create-sms',
  templateUrl: './create-sms.component.html',
  styleUrls: ['./create-sms.component.scss']
})
export class CreateSmsComponent implements OnInit {

  tableData = [
    { id: 1, message: 'dummy msg', date: '10 june 2020' }
  ]

  maxAvailableRecordsInTable:any=1
  dataSource:any;
  displayedColumns = ['id', 'message', 'date', 'delete'];
  createSmsForm: UntypedFormGroup;

  @ViewChild('fileUpload', { static: true })
  fileUpload: ElementRef;

  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  sort: MatSort;


  // disable: boolean = false;
  // dialogRef: any;
  private _unsubscribeAll: Subject<any>;

  isImageValid = true;

  imageUrl = ''

  @ViewChild('search', { static: true }) search: ElementRef;
  private _matDialog: any;

  deleteNotification(id) {
    console.log(id + ' is deleted')
    // this._matDialog.open(ConfirmationDialogComponent, {
    //   height: '130px',
    // })
  }
  async onSendSms() {
  }
  readUrl(event: any) {
  }
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _ToolbarService : ToolbarService,
    private fb: UntypedFormBuilder,
  ) {
    this._unsubscribeAll = new Subject();
    this.setToolBarTitle();

  }

  ngOnInit() {

    this.createSmsForm = this.fb.group({
      title: [''],
      desc: [''],
    })

    this.dataSource = new MatTableDataSource(this.tableData)
  }


  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Select Plan for SMS Which you want');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  // ------------------------- filter -------------------------
  filter = {
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  onSearch(val) {
    this.search.nativeElement.focus()
    if (val) {
      this.filter.pageIndex = 1
      this.filter.searchFilter = val
      this.filterData();
    }
  }


  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter
    }
    // this._distributorsListService.getDistributorList(payload).then((result: any) => {
    //   if (result.status == 200) {
    //     this.dataSource.data = result.data.data
    //     this.maxAvailableRecordsInTable = result.data.totalRecords
    //   }
    //   else {
    //     this.dataSource.data = []
    //   }
    // })
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

}
