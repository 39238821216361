import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { UploadTicketCodesComponent } from './upload-ticket-codes/upload-ticket-codes.component';


// import { AgmCoreModule } from '@agm/core';
const routes: Routes = [
  // {
  //   path: 'currency',
  //   loadChildren:'./currency/currency.module#CurrencyModule',
  // },
  {
    path: 'venue',
    loadChildren:() => import('./venue/venue.module').then(m => m.VenueModule),
    canActivate: [RoleAuthGuardService]
  },
  {
    path: 'event-type',
    loadChildren: () => import('./event-type/event-type.module').then(m => m.EventTypeModule),
    canActivate: [RoleAuthGuardService]
  },
  {
    path: 'ticket-type',
    loadChildren: () => import('./ticket-type/ticket-type.module').then(m => m.TicketTypeModule),
    canActivate: [RoleAuthGuardService]
  },
  {
    path: 'organiser',
    loadChildren: () => import('./organiser/organiser.module').then(m => m.OrganiserModule),
    canActivate: [RoleAuthGuardService]
  },
  {
    path: 'guest',
    loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule),
    canActivate: [RoleAuthGuardService]
  },
  {
    path: 'event-category',
    loadChildren: () => import('./event-category/event-category.module').then(m => m.EventCategoryModule),
    canActivate: [RoleAuthGuardService]
  },
  // {
  //   path: 'sponsors',
  //   loadChildren: () => import('./sponsor/sponsor.module').then(m => m.SponsorModule),
  //   // canActivate: [RoleAuthGuardService]
  // },
  {
    path: 'upload-tickets-codes',
    loadChildren: () => import('./upload-ticket-codes/upload-ticket-codes.module').then(m => m.UploadTicketCodesModule),
    // canActivate: [RoleAuthGuardService]
  }, 
]


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
   
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyDosbgI6M3Iw9abM1cQTvFpOeJI3qpfIEs',
    //   libraries: ['places']
    // })
  ]
})
export class MastersModule { }
