import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CertificateUpdateService implements Resolve<any> {
  templateDetails;
 
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _commonService: CommonService,
    private _MatSnackBar : MatSnackBar,
  ) { 

  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    
    console.log("route route", route);
    
    return new Promise((resolve, reject) => {
      Promise.all([
        this.onGetCertificateById({id : route.params.id}),
      ]).then((res)=>{
        resolve(res)
      }, reject)
    })
  }

   // certificate/getCertificateById
   onGetCertificateById(body){
    console.log("body", body);
      this._fuseProgressBarService.show();
      return new Promise((resolve, reject) => {
          this._httpClient.post(environment.apiURL + 'admin/certificate/getCertificateById', body , this._commonService.getHeader()).subscribe((response:any) => {
            this._fuseProgressBarService.hide(); 
            if(response.status == 200){
              this.templateDetails = response.data;
              this.templateDetails['layout'] = JSON.parse(this.templateDetails.layout);
              resolve(response);
            }else{
              this.templateDetails = null;
              this._MatSnackBar.open(response.message, 'OKAY', {duration : 3000});
              resolve(response);
            }
          }, reject)
      })
  }

}
