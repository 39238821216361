import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from '@fuse/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class AddCustomerService implements Resolve<any> {
  customerId
  allCountries:any=''
  constructor(
    private _commonService: CommonService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.customerId = route.params.id
    return new Promise((resolve, reject) => {
      if (this.customerId) {
        Promise.all([
          this._commonService.getAllCountries().then((result: any) => {
            if(result.length){
              this.allCountries = result;
            }else{
              this.allCountries = [];
            }
          })


        ]).then(
          () => {
            resolve('');
          },
          reject
        );
      } else {
        this._commonService.getAllCountries().then((result: any) => {
          if(result.length){
            this.allCountries = result;
          }else{
            this.allCountries = [];
          }
          resolve('')
        })
      }
    });
  }

}
