import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyListComponent } from './survey-list/survey-list.component';
import { SurveyComponent } from './survey/survey.component';
import { RouterModule, Routes } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SurveyListService } from './survey-list/survey-list.service';
import { SurveyService } from './survey/survey.service';
import { MatRippleModule } from '@angular/material/core';

const routes: Routes = [
  {
    path: '', component: SurveyListComponent,
    resolve: { data: SurveyListService }
  },
  {
    path: ':type', component: SurveyComponent,
    resolve: {
      data: SurveyService
    }
  },
  {
    path: ':type/:id', component: SurveyComponent,
    resolve: {
      data: SurveyService
    }
  },
]

@NgModule({
  declarations: [
    SurveyListComponent,
    SurveyComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    MatDividerModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatRippleModule
  ]
})
export class SurveysModule { }
