import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';

type modeType = 'add' | 'update' | 'view';
@Component({
  selector: 'app-add-update-meeting-location',
  templateUrl: './add-update-meeting-location.component.html',
  styleUrls: ['./add-update-meeting-location.component.scss']
})
export class AddUpdateMeetingLocationComponent implements OnInit {

  // meeting location title
  // title: FormControl = new FormControl('', Validators.required);
  form : FormGroup;

  mode : modeType;
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddUpdateMeetingLocationComponent>,
    public _commonService: CommonService,
    private _fb : FormBuilder
  ) { 
    // this.mode = this._data.mode;

    if (this._data.mode == 'add') {
      this.mode = 'add';
      this.createForm();
    }else if(this._data.mode == 'view'){
      this.mode = 'view';
      this.createForm(this._data.meeting);
    }else if(this._data.mode == 'update'){
      this.mode = 'update';
      this.createForm(this._data.meeting);
    }
  }

  createForm(data?){
    this.form  = this._fb.group({
      title : [data?.name || '', Validators.required]
    })
  }

  ngOnInit(): void {
  }

  onEdit(){
    this.mode = 'update';
  }

}
