import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmspackageService  implements Resolve<any> {
  smsPackage: any;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getSmsPackage()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    })
  }

  constructor(
    private _httpClient:HttpClient,
    private _commonService: CommonService,
    ) { }

  getSmsPackage() {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + 'admin/getall/sms',this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200) {
            this.smsPackage = result.data.stats
            console.log(this.smsPackage);            
            resolve(result)
          }else{
            reject(result)
          }
        }, reject)
    })
  }
}
