import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl,DefaultFilter } from '@fuse/utils/systemEnums';
import { AuthService } from 'app/main/pages/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root'
})
export class GuestListService implements Resolve<any>{
  allGuest = []
  maxAvailableRecordsInTable
  permissions: any;
  constructor(
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _matSnackBar: MatSnackBar,
    private _authService:AuthService,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {
      Promise.all([
        this.getAllGuest()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    })
  }

  getAllGuest(filter?) {
    const payload = {
      pageSize:10,
      pageIndex:1
    }
    return new Promise((resolve, reject) => {
      // this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/event/getall/guest',filter?filter: payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200) {
            this.allGuest = result.data.data;
            this.maxAvailableRecordsInTable = result.data.totalRecords;
          }else if (result.status == 400) {
            this.allGuest = [];
            this.maxAvailableRecordsInTable = 0;
          }
          resolve(result)
          // this._fuseProgressBarService.hide()
        }, reject)
    })
  }

  deleteGuest(id) {
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/event/delete/guest', { guestId: id }, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200) {
            resolve('')
          }
          this._fuseProgressBarService.hide()
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 2000
          });
        }, reject)
    });
  }

}
