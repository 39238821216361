import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import { 
    HAMMER_GESTURE_CONFIG,
    HammerModule
  } from '@angular/platform-browser';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { FakeDbService } from 'app/fake-db/fake-db.service';
import { AppComponent } from 'app/app.component';
import { AppStoreModule } from 'app/store/store.module';
import { LayoutModule } from 'app/layout/layout.module';

import { AuthGuardService } from './auth-guard.service';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { AgmCoreModule } from '@agm/core';
import { CookieService } from 'ngx-cookie-service';
import { AppHttpInterceptor } from '@fuse/services/interceptor.service';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LyButtonModule } from '@alyle/ui/button';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LY_THEME, LY_THEME_NAME, LyHammerGestureConfig, LyTheme2, StyleRenderer } from '@alyle/ui';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { DataDecryptionIntercaptorService } from '@fuse/services/data-decryption-intercaptor.service';
import { EncryptioninterceptorService } from '@fuse/services/encryptioninterceptor.service';
import { InvitationsModule } from './main/invitations/invitations.module';
// import { environment } from 'environments/environment';

const appRoutes: Routes = [
    // {
    //     path: 'apps',
    //     loadChildren: './main/apps/apps.module#AppsModule'
    // },
    {
        path: 'survey',
        loadChildren: () => import('./main/surveys/surveys.module').then(m => m.SurveysModule),
        // canActivate: [AuthGuardService]
        // canLoad: [AuthGuardService],
    },
    {
        path: 'polls',
        loadChildren: () => import('./main/polls/polls.module').then(m => m.PollsModule),
        // canActivate: [AuthGuardService]
        // canLoad: [AuthGuardService],
    },
    {
        path: 'home',
        loadChildren: () => import('./main/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuardService],
        // canLoad: [AuthGuardService],
    },

    {
        path: 'dashboard',
        loadChildren: () => import('./main/dashboard/project/project.module').then(m => m.ProjectDashboardModule),
        canActivate: [AuthGuardService]
        // canLoad: [AuthGuardService],
    },

    {
        path: 'referral',
        loadChildren: () => import('./main/referral/referral.module').then(m => m.ReferralModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'auth',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },

    {
        path: 'distributors',
        loadChildren: () => import('./main/distributors/distributors.module').then(m => m.DistributorsModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'events',
        loadChildren: () => import('./main/events/events.module').then(m => m.EventsModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'tickets',
        loadChildren: () => import('./main/tickets/tickets.module').then(m => m.TicketsModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'Invitation',
        loadChildren: () => import('./main/invitations/invitations.module').then(m => m.InvitationsModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'group',
        loadChildren: () => import('./main/groups/groups.module').then(m => m.GroupsModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'smspack',
        loadChildren: () => import('./main/smspack/smspack.module').then(m => m.SmspackModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'verifiers',
        loadChildren: () => import('./main/verifiers/verifiers.module').then(m => m.VerifiersModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'notification',
        loadChildren: () => import('./main/notification/notification.module').then(m => m.NotificationModule),
        // canActivate: [AuthGuardService]
    },

    {
        path: 'customers',
        loadChildren: () => import('./main/customers/customers.module').then(m => m.CustomersModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'settings',
        loadChildren: () => import('./main/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuardService]

    },

    {
        path: 'webhooks',
        loadChildren: () => import('./main/webhooks/webhooks.module').then(m => m.WebhooksModule),
        canActivate: [AuthGuardService]

    },

    {
        path: 'masters',
        loadChildren: () => import('./main/masters/masters.module').then(m => m.MastersModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'faq',
        loadChildren: () => import('./main/faq/faq.module').then(m => m.FaqModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'speaker',
        loadChildren: () => import('./main/speaker/speaker.module').then(m => m.SpeakerModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'roles',
        loadChildren: () => import('./main/roles/roles.module').then(m => m.RolesModule),
        canActivate: [AuthGuardService]
    },
    {
        path: 'print-tickets',
        loadChildren: () => import('./main/print-ticket/print-ticket.module').then(m => m.PrintTicketModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'orders',
        loadChildren: () => import('./main/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'subuser',
        loadChildren: () => import('./main/sub-user/sub-user.module').then(m => m.SubUserModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'reported',
        loadChildren: () => import('./main/reported-events/reported-events.module').then(m => m.ReportedEventsModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'meetingLocation',
        loadChildren: () => import('./main/meeting-locations/meeting-locations.module').then(m => m.MeetingLocationsModule),
        canActivate: [AuthGuardService]
    },

    

    {
        path: 'feedback',
        loadChildren: () => import('./main/feedback/feedback.module').then(m => m.FeedbackModule),
        canActivate: [AuthGuardService]
    },

    {
        path: 'notification-list',
        loadChildren: () => import('./main/notification-lists/notification-lists.module').then(m => m.NotificationListsModule),
        // canActivate: [AuthGuardService]
    },

    {
        path: 'taxes',
        loadChildren: () => import('./main/tax/taxes.module').then(m => m.TaxesModule),
        // canActivate: [AuthGuardService]
    },

    {
        path: 'resources',
        loadChildren: () => import('./main/resources/resources.module').then(m => m.ResourcesModule),
        // canActivate: [AuthGuardService]
    },

    {
        path: 'sponsors',
        loadChildren: () => import('./main/masters/sponsor/sponsor.module').then(m => m.SponsorModule),
    },

    {
        path : 'certificate',
        loadChildren: () => import('./main/certificate/certificate.module').then(m => m.CertificateModule)
    },

    {
        path : 'badge',
        loadChildren: () => import('./main/badge-design/badge-design.module').then(m => m.BadgeDesignModule)
    },
    


    // {
    //     path: 'ui',
    //     loadChildren: './main/ui/ui.module#UIModule'
    // },


    // Main Routes
    { path: '', redirectTo: 'auth', pathMatch: 'full' },
    // { path: 'send-admin-forgot-link', redirectTo: 'auth/forgot-password', pathMatch: 'full' },forgot-password
    { path: 'forgot-password/:token', redirectTo: 'auth/forgot-password/:token', pathMatch: 'full'},
    // {path: 'lock',redirectTo: 'auth/lock',pathMatch: 'full'},
    { path: 'reset-password', redirectTo: 'auth/reset-password', pathMatch: 'full' },
    {
        path: '**',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        GooglePlaceModule,
        BrowserModule,
        NgxMaterialTimepickerModule,
        RecaptchaModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        TranslateModule.forRoot(),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        LyButtonModule,
        LyImageCropperModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,

        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDosbgI6M3Iw9abM1cQTvFpOeJI3qpfIEs',
            libraries: ['places']
        }),
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass: DataDecryptionIntercaptorService, multi: true
        },
        {
            provide: HTTP_INTERCEPTORS, useClass : EncryptioninterceptorService,  multi: true
        },
        [ LyTheme2 ],
        [ StyleRenderer ],
        // Theme that will be applied to this module
        { provide: LY_THEME_NAME, useValue: 'minima-light' },
        { provide: LY_THEME, useClass: MinimaLight, multi: true }, // name: `minima-light`
        { provide: LY_THEME, useClass: MinimaDark, multi: true }, // name: `minima-dark`
        // Gestures
        { provide: HAMMER_GESTURE_CONFIG, useClass: LyHammerGestureConfig }, // Required for <ly-carousel>
        
    ]
})

export class AppModule {
}
