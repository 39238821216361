import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CertificateToolService {
  dynamicFieldList:BehaviorSubject<[]> = new BehaviorSubject([]);  
  backgrounds: [] = [];  
  newDynamicElement = {
    contentEditable : false,
    isContentEditing: false,
    text: '',
    fieldName : '',
    fontSize: 20,
    width: '200',
    fontFamily: "Barlow",
    fontColor: '#000',
    display: true,
    bold: false,
    fontWeight : '500',
    underline: false,
    italic: false,
    align: 'center',
    textTransform: "capitalize",
    opacity: 1,
    rotate: 0,
    position: {
      top: 10,
      left: 20
    }
  }

  selectedField: EventEmitter<any> = new EventEmitter<any>();
 

  templateData = {
    backgroundImage : '',
    fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Bilbo+Swash+Caps&family=Dancing+Script:wght@400..700&family=Fleur+De+Leah&family=Inter:wght@100..900&family=Kaushan+Script&family=Playball&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Qwigley&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sail&family=Sansita+Swashed:wght@300..900&family=Sansita:ital,wght@0,400;0,700;0,800;0,900;1,400;1,700;1,800;1,900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
    dynamicElements : [],
    signature: {
      link: '',
      width: 100,
      display: true, 
      opacity: 1,
      rotate: 0,
      position: {
        top: 50,
        left: 50
      }
    },
    width : 1500,
    height : 1061,  
  }

  defaultTemplateFormat = {
    backgroundImage : '',
    dynamicElements : [],
    width : 1500,
    height : 1061, 
    signature: {
      link: '',
      width: 100,
      display: true,
      opacity: 1,
      rotate: 0,
      position: {
        top: 31.99,
        left: 90.5
      }
    },
  }

  constructor() {

    // get selected field
    // this.dynamicFieldList.subscribe((res)=>{
    //   if(res){
    //     console.log("res", res);
    //     if(res.some((item:any) => item.isSelected)){
    //       const temp = {...this.newDynamicElement}
    //       console.log("temp", temp);
    //       this.templateData.dynamicElements.push(this.newDynamicElement)
    //       console.log("this.templateData.dynamicElements", this.templateData.dynamicElements);
    //     }
    //   }
    // })


    this.selectedField.subscribe((res)=>{
      if(res.title)
      if(res.isSelected){
        const tempField = JSON.parse(JSON.stringify(this.newDynamicElement))
        tempField.text = res.title;
        tempField.fieldName = `{{${res.title}}}`;
        
        if(this.templateData.dynamicElements.length != 0)
          tempField.position.top = (this.templateData.dynamicElements.length * 4);
        else
          tempField.position.top = 5

        console.log("tempField", tempField);
        
        if(tempField.text)
        this.templateData.dynamicElements.push(tempField);
        
        console.log("Added", this.templateData.dynamicElements);
      }else{
        const index = this.templateData.dynamicElements.findIndex((item) => item.text == res.title);
        this.templateData.dynamicElements.splice(index, 1);
        console.log("Remove", this.templateData.dynamicElements);
      }
      
    })
    
  }


  emitData(data: any): void {
    this.selectedField.emit(data);
  }
  
}
