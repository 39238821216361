import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MultipleDatepickerComponent } from '../event-multiple-datepicker-dialog/multiple-datepicker/multiple-datepicker.component';
import { SpeakerService } from 'app/main/speaker/speaker.service';
import { Enums, darkTheme } from '@fuse/utils/systemEnums';
import { AddPerformerComponent } from '../add-performer/add-performer.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { AddEditCommonDataProviderService } from 'app/main/events/create-event/add-edit-common-data-provider.service';

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss']
})
export class AddActivityComponent implements OnInit {
  darkTheme = darkTheme; 
  form : UntypedFormGroup;
  allSpeakersList = [];
  enums = Enums;
  preActivities;
  selectedTime: string = ''; 

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _fb: UntypedFormBuilder,
    public _addeditcommondataproviderservice : AddEditCommonDataProviderService,
    public _matDialogRef: MatDialogRef<MultipleDatepickerComponent>,
    private _matDialog: MatDialog,
    private _matsnakebar : MatSnackBar,
    private _SpeakerService : SpeakerService,
    private _CommonService : CommonService,
    private _addEditEventService : AddEditEventService,
    private _progressbar : FuseProgressBarService,
    private _cd: ChangeDetectorRef
  ) {

    this._addeditcommondataproviderservice._speakersList.subscribe((dataArr) =>{
      if(dataArr?.length){
        this.allSpeakersList = dataArr;
      }else{
        this.allSpeakersList = [];
      }
    })
    
    // this._addEditEventService
    console.log("this data dialog", this._data);

    if(_data?.isEdit){
      this.form = this.createFrom();
      
      if(this.allSpeakersList?.length){ 

        this.preActivities = this._data.preActivities; 
        const currentItem = this._data.preActivities.filter((item) => item.slotDate == this._data.selectedDate)
        console.log("currentItem", currentItem);
        if(currentItem?.length){
            if(currentItem[0].activitesList.length){
              console.log("currentItem[0].activitesList", currentItem[0].activitesList);
              // this.form = this.createFrom(currentItem[0].activitesList, this._data.selectedDate);
                currentItem[0].activitesList.forEach((schedule) => {
                  this.addRow(schedule)
                  this.form.updateValueAndValidity();
                })   
            }
        }
        console.log("this form edit case", this.form);
      }
    }else{
      this.form = this.createFrom();
      this.addRow();
    }
   }

  ngOnInit(): void {
    console.log("this is form status", this.form);
  }


  // FOR FORM ARRAY LOOP IN UI
  get scheduleData(): UntypedFormArray {
    return this.form.controls["scheduleData"] as UntypedFormArray;
  }

  
  createFrom(preData?, date?){
    return this._fb.group({
      date : [date ? date : this._data.selectedDate, Validators.required],
      scheduleData : this._fb.array([])
    })
  }

  // on Remove Schedule
  onRemoveSchedule(j){ 
      const dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data : {
                message : `Are you sure you want to delete this activity?`,
                image : '',
                focus : 'right',
                deleteItem : 'delete activity ?',
                confirmButtonText : 'Yes',
                cancelButtonText : 'No'
            } 
        }) 

        dialog.afterClosed().subscribe((res)=>{
            if(res){
              if(this.scheduleData.controls[j].value.id){
                const payload = {
                  eventId : this._data.preActivities[0].eventId,
                  activityId : this.scheduleData.controls[j].value.id
                }
                this._CommonService.onDeleteActivity(payload).then((res:any)=>{
                  if(res.status == 200){
                      (<FormArray>this.form.get('scheduleData')).removeAt(j);
                      this._matsnakebar.open(res.message, 'OKAY', {duration : 2000})
                  }else{
                      this._matsnakebar.open(res.message, 'OKAY', {duration : 2000})
                  }
                })
              }else{
                (<FormArray>this.form.get('scheduleData')).removeAt(j); 
              }
            }
        })
    
  } 

  
  // onAddScheduleObjInFrom(schedule?){
  //   (<UntypedFormArray>this.form.get('scheduleData')['controls'].push(this.addRow()))
  // }


  // FUNCTION THAT RETURN SCHEDULE FIELDS OBJECT
  // createSchules(schedule?){
  //   console.log("schedule", schedule);
  //     let performerIds:any = [];
  //     if(schedule?.performerList.length){
  //       schedule?.performerList.forEach(item => performerIds.push(String(item?.id || item)));
  //     }
 
  //     return this._fb.group({
  //       title           : [schedule?.title ? schedule?.title : '', Validators.required],
  //       description     : [schedule?.description ? schedule?.description :''],
  //       startTime       : [schedule?.startTime ? Number(schedule?.startTime) : ''],
  //       startTimeFormat : [schedule?.startTime ? new Date(Number(schedule?.startTime)).getHours() + ':' + new Date(Number(schedule?.startTime)).getMinutes() :'', Validators.required ],
  //       endTime         : [schedule?.endTime ? Number(schedule?.endTime) : ''],
  //       endTimeFormat   : [schedule?.endTime ? new Date(Number(schedule?.endTime)).getHours() + ':' + new Date(Number(schedule?.endTime)).getMinutes() : '', Validators.required],
  //       performerList   : [schedule?.performerList?.length ? performerIds : ''],
  //       performerLabel  : [schedule?.performerLabel ? schedule?.performerLabel: '']
  //     })
  // }

  public addRow(schedule?): void {
    console.log("this is the option", schedule);
    
    if (schedule) {
      // options?.forEach(schedule => {

        let performerIds:any = [];
        if(schedule?.performerList?.length){
          schedule?.performerList.forEach(item => performerIds.push(String(item?.id || item)));
          console.log("performerIds edit", performerIds);
        }
        
        console.log(schedule);
        (<FormArray>this.form.get("scheduleData")).push(
          this._fb.group({
            id              : [schedule?.id ? schedule?.id : 0],
            title           : [schedule?.title ? schedule?.title : '', Validators.required],
            description     : [schedule?.description ? schedule?.description :''],
            startTime       : [schedule?.startTime ? Number(schedule?.startTime) : ''],
            startTimeFormat : [schedule?.startTime ? new Date(Number(schedule?.startTime)).getHours() + ':' + new Date(Number(schedule?.startTime)).getMinutes() :'', Validators.required ],
            endTime         : [schedule?.endTime ? Number(schedule?.endTime) : ''],
            endTimeFormat   : [schedule?.endTime ? new Date(Number(schedule?.endTime)).getHours() + ':' + new Date(Number(schedule?.endTime)).getMinutes() : '', Validators.required],
            performerList   : [schedule?.performerList?.length ? performerIds : ''],
            performerLabel  : [schedule?.performerLabel ? schedule?.performerLabel: '']
          })
        );

      // });
    } else {
      (<FormArray>this.form.get("scheduleData")).push(
        this._fb.group({
          id              : [0],
          title           : ['', Validators.required],
          description     : [''],
          startTime       : [''],
          startTimeFormat : ['', Validators.required ],
          endTime         : [''],
          endTimeFormat   : ['', Validators.required],
          performerList   : [''],
          performerLabel  : ['']
        })
      );

    }
  }


  
  getAllSpackerList(){
    // this._CommonService.getAllSpeaker({"pageIndex":0,"pageSize":0}).then((result:any)=> {
    //   if(result.status == 200){
    //     this.allSpeakersList = result.data.data;
    //     this._matsnakebar.open('New Performer Added', 'OKAY', {duration : 2000})
    //   }else{
    //     this.allSpeakersList = [];
    //     this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
    //     this._progressbar.hide();
    //   }
    // })
  }

  onStartTimeSelected(event: any, j) {
    // event is the time in 24-hour format (e.g., '16:30')
    // You can convert it to a timestamp using JavaScript's Date object
    const [hours, minutes] = event.split(':').map(Number);
    const selectedTime = new Date();
    selectedTime.setHours(hours);
    selectedTime.setMinutes(minutes);
    selectedTime.setSeconds(0); // You may want to set seconds and milliseconds to zero
    selectedTime.setFullYear(new Date(this._data.selectedDate).getFullYear());
    selectedTime.setMonth(new Date(this._data.selectedDate).getMonth());
    selectedTime.setDate(new Date(this._data.selectedDate).getDate());
    const timestamp = selectedTime.getTime(); // Get the timestamp
    this.scheduleData.controls[j].get('startTime').setValue(timestamp)
    console.log("final ", timestamp);
    console.log("this.scheduleData[j]", this.scheduleData.controls[j]);
  }

  onEndTimeSelected(event: any, j) {
    // event is the time in 24-hour format (e.g., '16:30')
    // You can convert it to a timestamp using JavaScript's Date object
    const [hours, minutes] = event.split(':').map(Number);
    const selectedTime = new Date();
    selectedTime.setHours(hours);
    selectedTime.setMinutes(minutes);
    selectedTime.setSeconds(0); // You may want to set seconds and milliseconds to zero
    selectedTime.setFullYear(new Date(this._data.selectedDate).getFullYear());
    selectedTime.setMonth(new Date(this._data.selectedDate).getMonth());
    selectedTime.setDate(new Date(this._data.selectedDate).getDate());
    const timestamp = selectedTime.getTime(); // Get the timestamp
    this.scheduleData.controls[j].get('endTime').setValue(timestamp)
    console.log("final ", timestamp);
    console.log("this.scheduleData[j]", this.scheduleData.controls[j]);
  }

  onSubmit(){
    console.log("this is form status", this.form);
    if(this.form.valid){
      const activityPayload = {}
      const activitesList =[]
      activityPayload['slotDate'] = this.form.controls.date.value;
      if(this._data?.isEdit){
        console.log("this.preActivities", this.preActivities);
        console.log("activityPayload['slotDate']", activityPayload['slotDate']);
        
        this.preActivities.forEach((day)=> {
          console.log("day", day);
          if(day.slotDate == activityPayload['slotDate']){
            day.activitesList = []
            this.scheduleData.controls.forEach(item => {
              console.log("item", item);
              day.activitesList.push(item.value)
            })
          }
        })
        console.log("Activites In Edit Case", this.preActivities); 
        this._matDialogRef.close(this.preActivities); 
      }else{
        this.scheduleData.controls.forEach(item => { 
          activitesList.push(item.value)
        })
        activityPayload['activitesList'] = activitesList;
        console.log("Activites In Add Case", activityPayload);
        this._matDialogRef.close(activityPayload);
      }

    }

  }
  

   

  openAddPerformerDialog(){
    this._matDialog.open(AddPerformerComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      autoFocus : false,
      width: '700px',
      data : {
        isDialog : true
      }
    }).afterClosed().subscribe((result:any) => {
      if(result){
        this._addeditcommondataproviderservice.getAllSpeaker();
        // this.getAllSpackerList();
      }
    })
  }

}
