import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';

@Component({
  selector: 'app-add-template-dialog',
  templateUrl: './add-template-dialog.component.html',
  styleUrls: ['./add-template-dialog.component.scss']
})
export class AddTemplateDialogComponent implements OnInit {

  previewUrl;
  isImageValid = true;
  form: UntypedFormGroup
  imageFile
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef

  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) { }

  ngOnInit() {
    this.form = this._fb.group({
      "title": ['', Validators.required],
      "thumb": ['']
    })
  }
  removeImage() {
    this.fileUpload.nativeElement.value = ''
    this.previewUrl = ''
    this.form.get('thumb').setValue('')
  }

  readUrl(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('image type is ', file.type)
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg') {
        this.isImageValid = true;
      } else {
        this.isImageValid = false;
      }
      if (this.isImageValid) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrl = reader.result;
        }
        this.form.get('thumb').setValue(file)
      }
    }
  }

  onSubmit() {
    this.matDialogRef.close(this.form.value);
  }

}
