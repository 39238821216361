import { Component, OnInit, ViewChild } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { CancelledBookingService } from './cancelled-booking.service';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { CommonService } from '@fuse/services/common.service';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-cancelled-orders',
  templateUrl: './cancelled-orders.component.html',
  styleUrls: ['./cancelled-orders.component.scss'],
  animations : fuseAnimations
})
export class CancelledOrdersComponent implements OnInit {

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  
  displayedColumns: string[] = ['orderId', 'customerName', 'customerEmail', 'cancelledOn', 'eventName','ticketType'];
  dataSource;
  maxAvailableRecordsInTable;

  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  dateRangeFilterVal


  slotData;
  ticketTypeList;
  filter = {
    dateRangeFilter: {
      startDate: 0,
      endDate: 0
    },
    eventFilter: '',
    ticketType: '',
    searchFilter: '',
    slotFilter: '',
    pageSize: 10,
    pageIndex: 1
  }
  eventId
  
  constructor(
    private _ToolbarService : ToolbarService,
    public _commonservice : CommonService,
    private _fuseProgressBarService: FuseProgressBarService,
    public _CancelledBookingService : CancelledBookingService
  ) { 
    this.setToolBarTitle()
    console.log("this._CancelledBookingService.allCancelledOrderList", this._CancelledBookingService.allCancelledOrderList);
    this.dataSource = new MatTableDataSource(this._CancelledBookingService.allCancelledOrderList);
    this.maxAvailableRecordsInTable = this._CancelledBookingService.maxAvailableRecordsInTable;
  }

  ngOnInit(): void {
    this.getAllEventType()
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Cancelled Booking');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }


  filterData() {
    let payload = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventId": this.filter.eventFilter,
      "ticketType": this.filter.ticketType,
      "slotId": this.filter.slotFilter,
    }
    this._fuseProgressBarService.show()
    this._CancelledBookingService.getAllCancelledOrders(payload).then((result: any) => {
      this._fuseProgressBarService.hide()
      if (result.status == 200) {
        this.dataSource.data = result.data.data
        this.maxAvailableRecordsInTable = result.data.totalRecords
      } else if (result.status == 400) {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0;
      }
      else {
        this.dataSource.data = []
        this.maxAvailableRecordsInTable = 0
      }
    })
  }

  
  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.searchFilter = ''
    this.search.nativeElement.focus()
    this.filterData()
  }

  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.filterData();
  }

  onEventFilterSelect(val) {
    this.eventId = val.value;
    const payload = {
      "eventId": val.value
    }

    this._CancelledBookingService.getTicketCreatedEventsList(payload).then((result:any) =>{
      this.slotData = result.data[0].schedule;
      if(this.slotData.length == 1){
        this.filter.slotFilter = result.data[0].schedule[0].slotId;
      }else{
        this.filter.slotFilter = '';
      }
    })
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.eventFilter = val.value;
    this.filterData();
  }

  
  onSlotFilter(val){
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.eventFilter = this.eventId;
    this.filter.slotFilter = val.value;
    this.filterData();
  }

  onTicketTypeFilterSelect(){
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filterData();
  }

  getAllEventType(){
    this._commonservice.getAllTicketTypes().then((result:any) =>{
        console.log("getAllEventTypes ----->", result);
        this.ticketTypeList = result;
    })
  }
  
  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value)
        this.filterData();
  }

  clearFilter() {
    this.paginator.pageIndex = 0;
    this.range.get('start').reset();
    this.range.get('end').reset();
    this.filter.eventFilter = '';
    this.filter.searchFilter = '';
    this.filter.slotFilter = '';
    this.filter.ticketType = '';
    this.dateRangeFilterVal = '';
    this.filterData()
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1
    this.filter.pageSize = event.pageSize
    this.filterData()
  }

}
