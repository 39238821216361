import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateSmsComponent } from './create-sms/create-sms.component';
import { SelectReceiverComponent } from './select-receiver/select-receiver.component';
import { Routes, RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { SmspackageComponent } from './smspackage/smspackage.component';
import { SmspackageService } from './smspackage/smspackage.service';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes:Routes=[
  {path:'',component:CreateSmsComponent},
  {path:'select',component:SelectReceiverComponent},
  {path:'smspackage',component:SmspackageComponent,
  resolve: {
  data: SmspackageService 
  }
}
]


@NgModule({
  declarations: [CreateSmsComponent, SelectReceiverComponent, SmspackageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatSnackBarModule,
    MatTableModule,
    MatTabsModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatTooltipModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatDialogModule,
    MatMenuModule,
    MatGridListModule,
    MatChipsModule
  ]
})
export class SmspackModule { }
