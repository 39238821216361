import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-event-publish-dialog',
  templateUrl: './event-publish-dialog.component.html',
  styleUrls: ['./event-publish-dialog.component.scss']
})
export class EventPublishDialogComponent implements OnInit {

  constructor(
    private _commonServices : CommonService,
    private _matDialog: MatDialog,
    private _matSnackBar:MatSnackBar,
    public matDialogRef: MatDialogRef<EventPublishDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onDoItLater(response){
    this.matDialogRef.close({inDraft: response})
  }

  onCreateTicket(response){
    this.matDialogRef.close({createTicket: response})
  }
}
