import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService implements Resolve<any>{
  resourcesList = [];
  maxAvailableRecordsInTable;
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
        Promise.all([
          this._fuseProgressBarService.show(),
          this.getAllResources({ "searchData": "", "pageIndex": 1, "pageSize": 10 })
        ]).then(
          () => {
            resolve('');
            this._fuseProgressBarService.hide();
          },
        ).catch(() => {

        });
    })
  }

  getAllResources(payload) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getAllResourse', payload ? payload : {}, this._commonService.getHeader()).subscribe((response: any) => {
        if (response.status == 200) {
            console.log("getAllResources-->", response);
            this.resourcesList = response.data.resourseList;
            this.maxAvailableRecordsInTable = response.data.totalRecords
        }
        else if(response.status == 201){
          this.resourcesList = [];
          this.maxAvailableRecordsInTable = 0;
        }
        else if(response.status == 400){
            this.resourcesList = [];
            this.maxAvailableRecordsInTable = 0;
        }
        else {
            this.resourcesList = [];
            this.maxAvailableRecordsInTable = 0;
        } 
        resolve(response)
      }, reject)
    })
  }

}
