import { Injectable } from '@angular/core';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { environment } from 'environments/environment';
import { reject } from 'lodash';
import { timezones } from '@fuse/utils/timezones';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CommonService {
    maxAvailableRecordsInTable: any;
    selectionList: unknown[];
    allTranList;
    countryCode;
    defaultCountryCode = 91;
    bgColorPalette: any;
    allCountries: any;
    timeZones = timezones;
    // public currecntTimeZone;
    // public _locationTimeZone : BehaviorSubject<any> = new BehaviorSubject(null);
    public _selectedTimeZone : BehaviorSubject<any> = new BehaviorSubject(null);



    constructor(
        private _httpClient: HttpClient,
        private location: Location,
        private _fuseProgressBarService: FuseProgressBarService,
    ) {
        const timeZone:any = new Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        const currecntTimeZone = this.timeZones.filter((zone) => zone.utc.find((item) => item == String(timeZone)))[0]; 
        localStorage.setItem('locationTimeZone', JSON.stringify(currecntTimeZone));
        this._selectedTimeZone.subscribe((res)=>{
            if(res){
                console.log("res", res);
                // this.currecntTimeZone = res;
            }
        })
    }

    getAllCountries() {
        return new Promise  ((resolve, reject) => {
            console.log("getAllCountries")
            this._httpClient.get(environment.apiURL + 'admin/getall/countries', {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe((result: any) => {
                if (result.status == 200) {
                    this.countryCode = result.data.countries;
                    this.allCountries = result.data.countries;
                    resolve(result.data.countries)
                }else{
                    this.countryCode = []
                    this.allCountries = [];
                    resolve([])
                }
            }, reject)
        })
    }

    getTicketTypeColorPalette() {

        return new Promise((resole, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/tickets/getall/ticketTypeFonts', this.getHeader()).subscribe((result: any) => {
                if (result.status == 200) {
                    this.bgColorPalette = result.data;
                    resole(result)
                } else {
                    this.bgColorPalette = [];
                }
            }, reject)
        })
    }

    getStates(countryId) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/getall/state', { countryId: countryId }, {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            }).subscribe((result: any) => {
                if (result.status == 200) {
                    resolve(result);
                } else
                    reject(result)
            }, reject)
        })
    }

    /* for update transaction */
    addUpdateTrans(payload) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/addDistributorTransaction', payload, this.getHeader()).subscribe((result: any) => {
                if (result.status == 200) {
                    resolve(result);
                } else {
                    reject(result)
                }
            }, reject)
        })
    }
    // getAllEventList(payload){
    //   return new Promise((resolve, reject) =>{
    //     this._httpClient.post(environment.apiURL + 'admin/distributor/getAllEvents', payload, this.getHeader())
    //     .subscribe((result:any)=>{
    //       if(result.status == 200){
    //         resolve(result);
    //       }else{
    //         reject(result)
    //       }
    //     }, reject)
    //   })
    // }

    getAllCurrencies() {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })

        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/getall/currencies', { headers }).subscribe((result: any) => {
                if (result.status == 200) {
                    resolve(result.data.currencies);
                }else{
                    resolve([]);
                }
            }, reject)
        })
    }

    getAllEventTypes(filter?) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/event/getall/eventtype', filter ? filter : {}, this.getHeader()).subscribe((result: any) => {
                if (result.status == 200) {
                    resolve(result.data.data)
                } else if (result.status == 400) {
                    resolve(false)
                }else{
                    resolve(false)
                }
            }, reject)
        })
    }

    getAllTicketTypes() {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/tickets/getall/type', {}, this.getHeader()).subscribe((result: any) => {
                if (result.status == 200) {
                    resolve(result.data.ticketTypes)
                }
            }, reject)
        })
    }


    goBack() {
        this.location.back();
    }

    getAllVenues(payload?) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/event/getall/venue', payload ? payload : {}, this.getHeader())
                .subscribe((result: any) => {
                    if (result.status == 200) {
                        resolve(result.data.data);
                    } else if (result.status == 400) {
                        resolve(result.data);
                    } else {
                        resolve(result.data);
                    }
                }, reject)
        })
    }




    /* for reset password */
    getresetpass(data?) {
        alert('in the commonservvice function')
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/customer/resetPassword', data ? data : {}, this.getHeader()).subscribe((result: any) => {
                if (result.status == 200) {
                    alert('api called successfully')
                    resolve(result.data.data)
                }
            }, reject)
        })
    }

    updateAssignStatus(data) {
        const payload = {
            "distributorId": data.distributorId,
            "eventId": data.eventId,
            "isAssign": data.isAssigned ? data.isAssigned : 0
        }
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/distributor/getbyid/editassignedevent', payload ? payload : {}, this.getHeader())
                .subscribe((response: any) => {
                    resolve(response);
                }, reject);
        });
    }

    pass(payload, tokan) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/customer/resetPassword/' + tokan, payload, this.getHeaderWithoutToken()).subscribe((response: any) => {
                console.log("response", response);
                if (response.status == 200) {
                    resolve(response);
                } else {
                    reject(response)
                }
            }, reject);
        });
    }

    
    adminpass(payload, tokan) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/ResetPassword/' + tokan, payload, this.getHeaderWithoutToken()).subscribe((response: any) => {
                console.log("response", response);
                if (response.status == 200) {
                    resolve(response);
                } else {
                    reject(response)
                }
            }, reject);
        });
    }

    getEventsList() {
        // this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/events/getallevents', {}, this.getHeader())
                .subscribe((response: any) => {
                    // this._fuseProgressBarService.hide();
                    resolve(response);
                }, reject);
        });
    }




    // uploadImage(imageFile) {
    //     const formData = new FormData();
    //     formData.append("image", imageFile)

    //     return new Promise((resolve, reject) => {
    //         this._httpClient.post(environment.apiURL + 'admin/upload/image', formData, this.getHeader('formData'))
    //             .subscribe((result: any) => {
    //                 if (result.status == 200) {
    //                     console.log("'admin/upload/image'", result);
    //                     resolve(result)
    //                 }
    //             }, reject)
    //     })
    // }

    public getCurrencySymbol() {
        return localStorage.getItem("symbol")
    }

    getHeader(headerType?: 'formData') {
        if (headerType === 'formData') {
            return {
                headers: new HttpHeaders({
                    'Authorization': localStorage.getItem('userToken')
                })
            }
        } else
            return {
                headers: new HttpHeaders({
                    'Authorization': localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null,
                    'Content-Type': 'application/json'
                })
            }
    }

    getHeaderWithoutToken() {
        return {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }
    }

    getProfile(id) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/get-profile', {id:id}, this.getHeader()).subscribe((response: any) => {
                resolve(response)
            }, reject);
        })
    }

    editProfile(body) {
        console.log("newFindDate", body);
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/edit-profile', body, this.getHeader()).subscribe((response: any) => {
                resolve(response);
            }, reject);
        })
    }

    stripConnect(body) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'payment/create-account', body, this.getHeader()).subscribe((response: any) => {
                resolve(response);
            }, reject);
        })
    }

    importContact(body) {
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/uploadContact', body, this.getHeader()).subscribe((response: any) => {
                resolve(response);
            }, reject);
        })
    }

    getPresignedUrlAPI(body) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })

        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/common/presignedUrl', body, { headers }).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateInPresignedUrlAPI(url, contenttype, file) {
        this._fuseProgressBarService.show();
        const headers = new HttpHeaders({
            'Content-Type': contenttype,
        });
        return new Promise((resolve, reject) => {
            console.log("file ==== >",url, file);
            this._httpClient.put(url, file, { headers, reportProgress: true, observe: 'events' }).subscribe((response: any) => {
                if (response.type === HttpEventType.UploadProgress) {
                    // File upload completed
                    const progress = Math.round((response.loaded / response.total) * 100);
                    console.log("uploading progress ", progress);
                    
                    if (progress == 100){
                        // Handle the response from the server if needed
                        resolve(true);
                    }

                }
                this._fuseProgressBarService.hide();
            }, reject);
        })
    }

    getFileUploadStatus(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/common/updateFileStatus', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getDynamicFieldList(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/dynamicFieldList', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    addNewDynamicField(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/dynamicField', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    onUploadBuilkUser(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/addMultiContact', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    addResourse(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/addResourse', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateResourse(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/updateResourse', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    deleteResoures(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/deleteResourse', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    getHomePage() {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/getHomePage', {}, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    addSponsor(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/sponsor/add', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateSponsor(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.put(environment.apiURL + 'admin/sponsor/edit', body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    deleteSponsor(id) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.delete(environment.apiURL + `admin/sponsor/delete/${id}`, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getAllBackgroundImage() {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/tickets/getAllbackgroundImage`, {}, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    uploadTicketBackground(url) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/tickets/AddBackgroundImage`, { backgroundImage: url, isAllowToDelete: 1 }, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    onPublishEvent(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/EventPublish`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    onUnPublishEvent(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/unpublishEvent`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }



    // on delete ticket background image
    deleteBgImage(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/tickets/deleteBackgroundImage`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // update order member details.
    updateOrderMember(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/editOrder`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // delete Single activity API
    onDeleteActivity(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/events/deleteEventActivity`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    importQRANDBarCodeForTicket(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/tickets/importQrCodes`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // import qr and bar code for created orders
    importQrcodesForOrders(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/tickets/importQrcodesForOrders`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    addNewVerifiersLocation(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/verifier/addScanLocation`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    addHostType(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/addHost`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateHostType(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/updateHost`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    deleteHost(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/deleteHost`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    addSponsorGroup(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/addSponsorGroup`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getSponsorGroupById(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/getSponsorGroupById`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateSponsorGroup(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/updateSponsorGroup`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    deleteSponsorGroup(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/deleteSponsorGroup`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    addHostGroup(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/addHostGroup`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getHostGroupById(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/getHostGroupById`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    updateHostGroup(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/updateHostGroup`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    deleteHostGroup(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/deleteHostGroup`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getAllPollSurvey(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/getAllPollSurvey`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome User API'S
    // Dome connected User List 
    connectedUserList(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/connectedUserList`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome User Info for profile information
    domeUserInfo(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/domeUserInfo`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome booking Disable
    bookingDisable(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/bookingDisable`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome User List to show according to event
    domeUserList(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/domeUserList`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome Profile List to get profile viewer list
    userProfileList(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/userProfileList`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome User to get Contact Exchange list
    contactExchangeUser(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/contactExchangeUser`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome Scanned User
    contactScannedUser(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/contactScannedUser`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // Dome Request Sent Users connection
    connectionRequestSentUsers(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/connectionRequestSentUsers`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    ticketsCount(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/tickets/ticketsCount`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // admin/connectStripeAccount
    connectStripeAccount(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/connectStripeAccount`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // stripeconnect
    stripeconnect(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `stripeconnect`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    validateRecapchaToken(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/recaptcha`, body,
                { headers: { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' } }).subscribe((response: any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response);
                }, reject);
        })
    }

    cancelOrder(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/cancelOrder`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getAllDesignation(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/getAllHost`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    getAllSpeaker(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/getAllSpeaker`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    assignEventToVarifier(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/event/assign/verifier`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    getUpcommingPublishedEvents(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/events/getallpublishedevents`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // tracks apis.
    getAllTrackByEvent(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/event/getAllEventTrack', body, this.getHeader()).subscribe((response:any) =>{
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject)
        })
    }

    // rearrange EventTrack by drag & drop
        // event/rearrangeEventTrack
        onRearrangeEventTrack(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/event/rearrangeEventTrack', body, this.getHeader()).subscribe((response:any) =>{
                    this._fuseProgressBarService.hide();
                    resolve(response);
                }, reject)
            })
        }

        // add event track
        addEventTrack(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/event/addEventTrack', body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response);
                }, reject)
            })
        }

        // update event track
        updateEventTrack(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/event/updateEventTrack', body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response);
                }, reject)
            })
        }


        // detele track
        deleteTrack(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/event/deleteEventTrack', body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

        // admin/updateApprovalBookingStatus

        updateApprovalBooking(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/updateApprovalBookingStatus', body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

    // download invitation codes.
    getInvitationCodes(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/event/exportsReferralCode', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // send Notification 
    sendNotification(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/sendUserNotification', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // cancel notification
    cancelNotification(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/cancelNotification', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // meeting locations apis.
    addNewMeetingLocation(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/meeting/addMeetingLocation', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // meeting/updateMeetingLocations
    updateMeetingLocations(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/meeting/updateMeetingLocations', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // delete meeting location 
    // meeting/deleteMeetingLocations
    deleteMeetingLocations(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/meeting/deleteMeetingLocations', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // connect meeting location by event.
    // meeting/addEventMeetingLocation
    addEventMeetingLocation(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) =>{
            this._httpClient.post(environment.apiURL + 'admin/meeting/addEventMeetingLocation', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // delete event meeting specific location.
    // meeting/deleteEventMeetingLocation
    deleteEventMeetingLocation(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/meeting/deleteEventMeetingLocation', body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }
    




    /* 
        VERIFIER LOCATION NAME FOR FILTER
    */
    getAllLocationName(){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + 'admin/scanLocationList', this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }



    /* 
        Certificate APIs
    */

        // certificate/addCertificate
        onAddCertificate(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/certificate/addCertificate',body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

       

        // certificate/deleteCertificate
        onDeleteCertificate(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/certificate/deleteCertificate',body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

        // certificate/updateCertificate
        onUpdateCertificate(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/certificate/updateCertificate',body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

    /* 
        Generate Certificate
    */

        getEventById(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/events/geteventbyid',body, this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

        getEventAsignedVerifierList(data){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/event/eventAsignedVerifierList', data,  this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

        
        regenerateKeys(){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/regenerateKeys', {},  this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }


        addCertificateImage(body){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/certificate/addCertificateImage', body,  this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }

        getAllCertificateBackgroundImage(){
            this._fuseProgressBarService.show();
            return new Promise((resolve, reject) => {
                this._httpClient.post(environment.apiURL + 'admin/certificate/getAllCertificateImage', {},  this.getHeader()).subscribe((response:any) => {
                    this._fuseProgressBarService.hide();
                    resolve(response)
                }, reject)
            })
        }


            // on delete ticket background image
    certificateDeleteBgImage(body) {
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/certificate/deleteCertificateImage`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    // certificate/generateCertificate
    onGenerateCertificate(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/certificate/generateCertificate`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }


    // email varification api. 
    // https://api-stage.eventlocal.io/api/v2/verifyAdminEmail/
    onAdminEmailVarification(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.get(environment.apiURL + `admin/verifyAdminEmail/` + body, {}).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    //admin/validateAdminEmail
    sendVarificationRequest(){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/validateAdminEmail`,{}, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }
    

    // socialShare/addSocialShareImage
    
    getAllSocialBackgroundsByEvent(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/socialShare/getAllSocialBackgroundsByEvent',body,  this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // admin/socialShare/addSocialBgImage
    onAddSocialBgImage(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/socialShare/addSocialBgImage', body,  this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // admin/socialShare/deleteBgImage
    onDeleteBgImage(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/socialShare/deleteBgImage`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // socialShare/addEventSocialTemplate
    onAddEventSocialTemplate(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/socialShare/addEventSocialTemplate`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // admin/socialShare/getSocialPostByEvent
    onGetSocialPostByEvent(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/socialShare/getSocialPostByEvent`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // admin/socialShare/updateSocialPostById
    onUpdateSocialPostById(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/socialShare/updateSocialPostById`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // /admin/badge/addBadgeImage
    onAddBadgeImage(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/badge/addBadgeImage`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }
    
    // admin/badge/getAllBadgeImage
    getAllBadgeImage(){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/badge/getAllBadgeImage`, {}, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                console.log("response====>", response)
                resolve(response);
            }, reject);
        })
    } 

     // admin/badge/deleteBadgeImage
     deleteBadgeImage(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/badge/deleteBadgeImage`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    // admin/badge/addBadge

    addBadge(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/badge/addBadge`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }
    
     // certificate/deleteCertificate
    deleteBadge(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/badge/deleteBadge',body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

    // certificate/generateCertificate
    onGenerateLabel(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + `admin/badge/generateBadge`, body, this.getHeader()).subscribe((response: any) => {
                this._fuseProgressBarService.hide();
                resolve(response);
            }, reject);
        })
    }

    onUpdateLabel(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/badge/updateBadge',body, this.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide();
                resolve(response)
            }, reject)
        })
    }

}
