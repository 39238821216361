import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { RolesService } from './roles.service';
import { NewRoleDialogComponent } from './new-role-dialog/new-role-dialog.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RolesComponent implements OnInit {
  displayedColumns: string[] = ['pageName', 'permission'];
  dataSource
  dialogRef
  newRoleName = ''
  newRoleObjectPermissions = []
  selectedRole
  selectedRoleId
  constructor(
    public _rolesService: RolesService,
    private _matDialog: MatDialog,
    private _ToolbarService : ToolbarService,
    private _fuseProgressBarService: FuseProgressBarService
  ) { 
    this.setToolBarTitle();

  }
  ngOnInit() {
    this.setNewRoleObject();
  }

  setToolBarTitle(){
    if(this.newRoleName){
      this._ToolbarService._ToolBarTitle.next('Edit Role');
      // for btn
      this._ToolbarService._isBackBtnShow.next(false);
    }else{
      
    }
  }


  permissionList = [
    {
      page: 'home',
      url: '/home',
      permission: {
        view: true,
      }
    },
    {
      page: 'Dashboard',
      url: '/dashboard',
      permission: {
        view: true,
      }
    },
    {
      page: 'Distributors',
      url: '/distributors',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true,
        assignEvent: true,
        editAssignedEvent: true,
        ticketSaleHistory:true,
      }
    },
    {
      page: 'Events',
      url: '/events',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true,
        publish: true,
        assignDistributor: true,
        assignVerifier: true
      }
    },
    {
      page: 'Tickets',
      url: '/tickets',
      permission: {
        add: true,
        update: true,
        view: true

      }
    },
    {
      page: 'SMS Pack',
      url: '/smspack',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Verifiers',
      url: '/verifiers',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true,
        assignEvent: true,
        unAssignEvent: true
      }
    },
    {
      page: 'Notification',
      url: '/notification',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Customers',
      url: '/customers',
      permission: {
        update: true,
        view: true,
      }
    },
    {
      page: 'Setting',
      url: '/settings',
      permission: {
        view: true
      }
    },
    {
      page: 'Manage Venue',
      url: '/masters/venue',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Subuser',
      url: '/subuser',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Manage Event Type',
      url: '/masters/event-type',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Manage Event Categories',
      url: '/masters/event-category',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Manage Ticket Types',
      url: '/masters/ticket-type',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Manage Organisers',
      url: '/masters/organiser',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Manage Guests',
      url: '/masters/guest',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Roles',
      url: '/roles',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Print Tickets',
      url: '/print-tickets',
      permission: {
        add: true,
        update: true,
        view: true,
        delete: true
      }
    },
    {
      page: 'Orders',
      url: '/orders',
      permission: {
        view: true,
        add: true,
        bulkTicketCreate: true,
        printTicket: true
      }
    },
  ];

  // permissionList = [
  //   {
  //     page: 'home',
  //     url: '/home',
  //     permission: {
  //       view: true,
  //     }
  //   },
  //   {
  //     page: 'Dashboard',
  //     url: '/dashboard',
  //     permission: {
  //       view: true,
  //     }
  //   },
  //   {
  //     page: 'Events',
  //     url: '/events',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       list : true,
  //       delete: true,
  //       publish: true,
  //       assignDistributor: true,
  //       assignVerifier: true
  //     }
  //   },
  //   {
  //     page: 'Booking',
  //     url: '/order',
  //     permission: {
  //       list : true,
  //       view: true,
  //       add: true,
  //       update: true,
  //       addBulk : true,
  //       exportBooking : true,
  //       printTickets: true,
  //       viewCustomerInfo : true,
  //       updateCustomerInfo : false,
  //     }
  //   },
  //   {
  //     page: 'Reported Event',
  //     url: '/reported',
  //     permission: {
  //       view: true,
  //     }
  //   },
  //   {
  //     page: 'Event Types',
  //     url: '/masters/event-type',
  //     permission: {
  //       addRequest: true,
  //     }
  //   },
  //   {
  //     page: 'Event Categories',
  //     url: '/masters/event-category',
  //     permission: {
  //       addRequest: true,
  //     }
  //   },
  //   {
  //     page: 'Additional Resources',
  //     url: '/resources',
  //     permission: {
  //       add : true,
  //       view : true, 
  //       update : true, 
  //       delete : true,
  //     }
  //   }, 
  //   {
  //     page: 'Tickets',
  //     url: '/tickets',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       extendsLimit : true,
  //       list : true
  //     }
  //   },

  //   {
  //     page: 'Distributors',
  //     url: '/distributors',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true,
  //       assignEvent: true,
  //       editAssignedEvent: true,
  //       ticketSaleHistory:true,
  //     }
  //   },
  //   {
  //     page: 'SMS Pack',
  //     url: '/smspack',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Verifiers',
  //     url: '/verifiers',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true,
  //       assignEvent: true,
  //       unAssignEvent: true
  //     }
  //   },
  //   {
  //     page: 'Notification',
  //     url: '/notification',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Customers',
  //     url: '/customers',
  //     permission: {
  //       update: true,
  //       view: true,
  //     }
  //   },
  //   {
  //     page: 'Setting',
  //     url: '/settings',
  //     permission: {
  //       view: true
  //     }
  //   },
  //   {
  //     page: 'Manage Venue',
  //     url: '/masters/venue',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Subuser',
  //     url: '/subuser',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Manage Event Type',
  //     url: '/masters/event-type',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Manage Event Categories',
  //     url: '/masters/event-category',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Manage Ticket Types',
  //     url: '/masters/ticket-type',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Manage Organisers',
  //     url: '/masters/organiser',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Manage Guests',
  //     url: '/masters/guest',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Roles',
  //     url: '/roles',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Print Tickets',
  //     url: '/print-tickets',
  //     permission: {
  //       add: true,
  //       update: true,
  //       view: true,
  //       delete: true
  //     }
  //   },
  //   {
  //     page: 'Orders',
  //     url: '/orders',
  //     permission: {
  //       view: true,
  //       add: true,
  //       bulkTicketCreate: true,
  //       printTicket: true
  //     }
  //   },
  // ];

  addNewRole() {
    this.dialogRef = this._matDialog.open(NewRoleDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '400px',
      autoFocus : false,
    });

    this.dialogRef.afterClosed().subscribe(response => {
      if (response) {
        this.newRoleName = response;
        this.selectedRoleId = 0;
        this.setNewRoleObject()
      } else {
        return;
      }
    });
  }

  setNewRoleObject() {
    this.newRoleObjectPermissions = [...this.permissionList]
    this.dataSource = new MatTableDataSource(this.newRoleObjectPermissions)
  }

  onAddNewRoleSubmit() {
    console.log(this.newRoleObjectPermissions)
    console.log(this.newRoleName)

    this._rolesService.addNewRole(this.newRoleName, this.newRoleObjectPermissions).then((result) => {
      this._rolesService.getAllPermission()
      console.log('*****', result)
    })
  }

  onfilterChange(event) {
    this.newRoleName = ''
    this._rolesService.getPermissionById(event.value).then((result: any) => {
      if (result.status == 200) {
        this.selectedRole = result.data.role
        this.dataSource = new MatTableDataSource(this.selectedRole.permission);
        console.log("dataSource", this.dataSource); 
      } else {
        this.selectedRole = []
      }
    })

    console.log('test', event)
  }

  editPermissions() {
    console.log(this.selectedRole)
    this._rolesService.editPermissions(this.selectedRole.id, this.selectedRole.permission).then((result) => {

    })
  }

}
