import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { Enums } from '@fuse/utils/systemEnums';
import { Subject } from 'rxjs';
import { VerifiersListService } from '../verifiers.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector: 'app-verifiers-list',
    templateUrl: './verifiers-list.component.html',
    styleUrls: ['./verifiers-list.component.scss']
})

export class VerifiersListComponent implements OnInit {

    enum = Enums;
    maxAvailableRecordsInTable
    filterApplied = "1"
    tableData = []
    dataSource;
    displayedColumns = ['proImage', 'name', 'email', 'mobile', 'totalEvents', 'totalScans'];

    @ViewChild(MatPaginator, { static: true })
    paginator: MatPaginator;

    private _unsubscribeAll: Subject<any>;

    constructor(
        public _verifiersListService: VerifiersListService,
        private _ToolbarService : ToolbarService,
    ) {
        this._unsubscribeAll = new Subject();
        this.maxAvailableRecordsInTable = this._verifiersListService.maxAvailableRecordsInTable;
        
        this.setToolBarTitle();
    }

    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next('Verifiers');
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }

    ngOnInit() {
        this.tableData = this._verifiersListService.verifiersList
        this.dataSource = new MatTableDataSource(this.tableData);
    }

    updateTable() {
        this._verifiersListService.getAllVerifiers(this.filter).then(() => {
            this.tableData = this._verifiersListService.verifiersList
            this.dataSource.data = this.tableData
        })
    }

    // ----------------------- filters section -----------------------

    dateRangeFilterVal
    filter = {
        dateRangeFilter: {
            startDate: 0,
            endDate: 0
        },
        typeFilter: '',
        searchFilter: '',
        pageSize: 10,
        pageIndex: 1
    }

    onSearch(val) {
        this.filter.pageIndex = 1
        this.filter.searchFilter = val
        this.filterData();
    }

    filterData() {
        let payload = {
            "pageIndex": this.filter.pageIndex,
            "pageSize": this.filter.pageSize,
            "search": this.filter.searchFilter
        }
        this._verifiersListService.getAllVerifiers(payload).then((result: any) => {
            if (result.status == 200) {
                this.dataSource.data = result.data.data;
                this.maxAvailableRecordsInTable = result.data.totalRecords;
            }
            else {
                this.dataSource.data = []
            }
        })
    }
    
    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.filterData()
    }

    @ViewChild('search', { static: true }) search;
    clearSearch() {
      this.filter.searchFilter = ''
      this.search.nativeElement.focus()
      this.filterData()
    }
}

