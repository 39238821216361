import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaxesService } from 'app/main/tax/taxes.service';

@Component({
  selector: 'app-add-tax-dialog',
  templateUrl: './add-tax-dialog.component.html',
  styleUrls: ['./add-tax-dialog.component.scss']
})
export class AddTaxDialogComponent implements OnInit {

  form:FormGroup;
  editData: any;

  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddTaxDialogComponent>,
    public _taxesService : TaxesService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.editData = _data ? _data : ''; 
    
    this.form = this.createForm();
    if(this.editData){
      console.log("edit case");
      this.editCase();    
    }

    
  }

  // form 
  createForm(){
    return this._fb.group({
      title: ['', Validators.required],
      taxRate: ['', Validators.required]
    });
  }


  // setting defult value in edit case
  editCase(){
    console.log("this.editData--->", this.editData);
    this.form.get('title').setValue(this.editData['title']);
    this.form.get('taxRate').setValue(this.editData['amount']);
  }


  ngOnInit(): void {
    
  }

  onSubmit(){
    if(this.editData){
      console.log("edit case");
      let editPayload = {
        id: this.editData['id'],
        title : this.form.get('title').value,
        amount: this.form.get('taxRate').value,
      }
      console.log("edit ok");
      this.edit(editPayload);
    }else{
      console.log("submit ok");
      let payload = {
        title : this.form.get('title').value,
        amount: this.form.get('taxRate').value
      }
      this._taxesService.addTax(payload).then((result:any) => {
        if(result.status == 200){
          this.matDialogRef.close(result.data);
          this._taxesService.getTaxlist({pageSize: 10, pageIndex:1});
        }else{
          this.matDialogRef.close();
        }
      })
    }
    }

  edit(editPayload){
    this._taxesService.editTax(editPayload).then((result:any) =>{
      if(result.status == 200){
        this.matDialogRef.close(result.data);
      }else{
        this.matDialogRef.close();
      }
    })
  }
}
