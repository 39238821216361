import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentGatewayConnectedComponent } from '@fuse/Dialog/payment-gateway-connected/payment-gateway-connected.component';
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations : fuseAnimations
})
export class HomeComponent implements OnInit{
  isDataLoaded = false;
  homeData: any;
  constructor(private _ToolbarService: ToolbarService,
    private _commonService: CommonService,
    private router: Router,
    private _matDialog : MatDialog,
    private _route  : ActivatedRoute,
    private _snackBar: MatSnackBar) {
    this.setToolBarTitle();
    console.log("home component")

    this._commonService.getHomePage().then((result: any) => {
      if (result.status === 200) {
        this.isDataLoaded = true;
        this.homeData = result.data;
      } else {
        this._snackBar.open(result.message, 'Okay', { duration: 2000 });
        this.isDataLoaded = false;
      }
    })


  }

  ngOnInit(): void {
    this.openStripeDialog();
  }

  openStripeDialog(){
    console.log("openStripeDialog",Number(localStorage.getItem('isStripeConnected')));
    if(String(this.router.url).includes('payment-gateway-connected')){
      if(!Number(localStorage.getItem('isStripeConnected'))){
        let token;
        this._route.params.subscribe((res)=>{
          if(res){
            token = res.token
          }
        })
        // const acctId = 
        const dialog =  this._matDialog.open(PaymentGatewayConnectedComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          width : '540px',
          disableClose: true,
          data : {
            acctId : token
          }
        })
      }else{
        this.router.navigate(['/home'])
      }
    }
  }
  

  setToolBarTitle() {
    // for title
    this._ToolbarService._ToolBarTitle.next('Home');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }
}
