import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AddNewDynamicVariableComponent } from '../add-new-dynamic-variable/add-new-dynamic-variable.component';
import { CommonService } from '@fuse/services/common.service';

@Component({
  selector: 'app-add-more-fields',
  templateUrl: './add-more-fields.component.html',
  styleUrls: ['./add-more-fields.component.scss']
})
export class AddMoreFieldsComponent implements OnInit {

  form:UntypedFormGroup;
  exField = `{{userName}}`
  displayedColumns: string[] = ['sr', 'name','fieldName', 'action'];
  dataSource
  dataLength
  constructor(
   
    public matSnackBar : MatSnackBar,
    private _matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddMoreFieldsComponent>,
    private _commonService : CommonService,
  ) {
    
    
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._data.fields);
    // this.dataLength = this._data?.fields
    console.log("dataSource", this._data?.fields);
  }


  

  onSubmit(){
    this._matDialogRef.close(this.form.value);
  }

  addNewField(){
    this._matDialog.open(AddNewDynamicVariableComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '420px',
      autoFocus : false,
      data : {
        fields : this._data.fields
      } 
    }).afterClosed().subscribe((fieldData) => {
          if(fieldData){
            this.updateTableList();
          }
      })
  }

  updateTableList(){
    this._commonService.getDynamicFieldList({}).then((result:any)=> {
      if(result.status == 200){
        this.dataSource.data = result.data;
        this._data.fields = result.data;
      }
    })
  }

}
