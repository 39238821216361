import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './orders.component';
import { RouterModule, Routes } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { OrderService } from './order.service';
import {MatDividerModule} from '@angular/material/divider';
import {MatMenuModule} from '@angular/material/menu';

import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { NgxMatDatetimePickerModule,NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { AddOrderComponent } from './add-order/add-order.component';
import { AddOrderService } from './add-order/add-order.service';
import { MatRadioModule } from '@angular/material/radio';
import { BulkOrderComponent } from './bulk-order/bulk-order.component';
import { BulkOrderService } from './bulk-order/bulk-order.service';
import { RoleAuthGuardService } from 'app/role-auth-guard.service';
import { SelectEventDialogComponent } from './select-event-dialog/select-event-dialog.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import { OrderMembersComponent } from '@fuse/Dialog/order-members-details-dialog/order-members/order-members.component'; 
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CreateOrderService } from './create-order/create-order.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { MatProgressBar, MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CancelledOrdersComponent } from './cancelled-orders/cancelled-orders.component';
import { CancelledBookingService } from './cancelled-orders/cancelled-booking.service';

const routes: Routes = [
  {
    path: '', component: OrderComponent,
    resolve: {
      data: OrderService
    }
  },
  {
    path: 'add', component: AddOrderComponent,
    resolve: {
      data: AddOrderService
    },
    canActivate: [RoleAuthGuardService]
  },
  // {
  //   path: 'bulk-order', component: BulkOrderComponent,
  //   resolve: {
  //     data: BulkOrderService
  //   },
  //   canActivate: [RoleAuthGuardService]
  // },
  {
    path: 'order-members', component: OrderMembersComponent,
    canActivate: [RoleAuthGuardService]
  },
  {
    path: 'create-order', component: CreateOrderComponent,
    resolve: {
      data: CreateOrderService
    },
    // canActivate: [RoleAuthGuardService]
  },
  {
    path: 'bulk-order', component: CreateOrderComponent,
    resolve: {
      data: CreateOrderService
    },
    // canActivate: [RoleAuthGuardService]
  },

  {
    path: 'cancelled', component: CancelledOrdersComponent,
    resolve: {
      data: CancelledBookingService
    },
    // canActivate: [RoleAuthGuardService]
  }

]

@NgModule({
    declarations: [OrderComponent, AddOrderComponent, BulkOrderComponent, SelectEventDialogComponent, CreateOrderComponent, CancelledOrdersComponent],
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MatCardModule,
        MatButtonModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatProgressBarModule,
        ClipboardModule,
        MatMenuModule,
        FuseDirectivesModule,
        MatIconModule,
        MatInputModule,
        MatPaginatorModule,
        MatCheckboxModule,
        MatRippleModule,
        MatDividerModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatSelectModule,
        MatDatepickerModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        FuseSharedModule,
        FuseWidgetModule,
        MatGridListModule,
        NgxDaterangepickerMd.forRoot(),
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        // NgxMatTimepickerModule,
        MatRadioModule,
        MatSlideToggleModule,
        MatDialogModule,
    ]
})
export class OrdersModule { }
