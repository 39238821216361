import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TracksComponent } from './tracks/tracks.component';
import { AddEditTrackComponent } from './add-edit-track/add-edit-track.component';
import { RouterModule, Routes } from '@angular/router';
import { TrackLayoutComponent } from './track-layout.component';
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { DragDropModule } from '@angular/cdk/drag-drop'; 
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AddUpdateTrackService } from './add-edit-track/add-update-track.service';
import {MatExpansionModule} from '@angular/material/expansion';
import { TrackDetailComponent } from './track-detail/track-detail.component';

const routes: Routes = [
  {
    path : '',
    component : TracksComponent
  },
  {
    path : 'add',
    component : AddEditTrackComponent,
    // resolve : {
    //   data : AddUpdateTrackService
    // }
  },
  {
    path : 'update/:id',
    component : AddEditTrackComponent,
    resolve : {
      data : AddUpdateTrackService
    }
  },
  {
    path : 'detail/:id',
    component : TrackDetailComponent,
    resolve : {
      data : AddUpdateTrackService
    }
  }
]

@NgModule({
  declarations: [
    TracksComponent,
    AddEditTrackComponent,
    TrackLayoutComponent,
    TrackDetailComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    NgxShimmerLoadingModule,
    MatButtonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatToolbarModule,
    DragDropModule,
    MatExpansionModule,
    MatIconModule,
    FuseSharedModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatRippleModule,
    FusePipesModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    MatToolbarModule,
    MatDialogModule,
  ],
  entryComponents:[TrackDetailComponent]
})
export class EventTrackModule { }
