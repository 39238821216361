import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { SponsorService } from '../../sponsor.service';
import { AddEditSponsorGroupComponent } from '@fuse/Dialog/add-edit-sponsor-group/add-edit-sponsor-group.component';
import { MatTableDataSource } from '@angular/material/table';
import { fuseAnimations } from '@fuse/animations';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

interface Filter {
  search: string,
  pageSize : number,
  pageIndex : number
}

@Component({
  selector: 'app-sponsor-groups',
  templateUrl: './sponsor-groups.component.html',
  styleUrls: ['./sponsor-groups.component.scss'],
  animations : fuseAnimations
})
export class SponsorGroupsComponent implements OnInit {

  filter:Filter = {
    search: '',
    pageSize: 10,
    pageIndex: 1
  }

  maxAvailableRecordsInTable:number;
  dataSource;
  displayedColumns: string[] = ['sponsorType', 'memberCount', 'action'];
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  
  getDetailAPIPending : boolean;

  constructor(
    private _ToolbarService : ToolbarService,
    private _matdialog : MatDialog,
    private _commonservice : CommonService,
    private _matsnakebar : MatSnackBar,
    private _sponsorService : SponsorService
  ) {
    this.setToolBarTitle();
   }

  setToolBarTitle(){
    this._ToolbarService._ToolBarTitle.next('Sponsor Groups');
  }
  
  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._sponsorService.sponsorsGroups);
    this.maxAvailableRecordsInTable =  this._sponsorService.maxAvailableRecordsInTableSG;
  }
 
  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1;
    this.filter.pageSize = event.pageSize;
    this.updateListing();
  }
 
  updateListing(){
    this._sponsorService.getAllSponsorsGroups(this.filter).then((result:any) => {
      if(result.status == 200){
        this.dataSource.data = result.data.getAllSponsorGroup;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else if(result.status == 400){
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }

  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.updateListing();
  }

  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.search = val;
    this.updateListing()
  }


  // add edit group
  openSponsorGroupDialog(mode, groupData?){
    if(mode == 'add'){
      const dialog = this._matdialog.open(AddEditSponsorGroupComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        width : '500px',
        autoFocus : false,
        data : {
          mode : mode,
          hostType : groupData || undefined,
          sponsors : this._sponsorService.allSponsors
        }
      })
      dialog.afterClosed().subscribe((res) => {
        if(res?.formData){
          if(res?.mode == 'add'){ 
            const payload = {
              name : res?.formData?.title,
              sponsorList : []
            }
            res.selectedSponsors.forEach((item) => {
              payload.sponsorList.push({sponsorId : item.id, sponsorRanking : item.rank})
            }) 
            this._commonservice.addSponsorGroup(payload).then((result:any) => {
              if(result.status == 200){
                this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
                this.updateListing(); 
              }
            }) 
          }
        }
      })
    }else{
      this.getDetailAPIPending = true;
      // edit and view
      this._commonservice.getSponsorGroupById({id:groupData.id}).then((result:any) => {
        if(result.status == 200){
          this.getDetailAPIPending = false;
          const dialog = this._matdialog.open(AddEditSponsorGroupComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width : '500px',
            autoFocus : false,
            data : {
              mode : mode,
              hostType : result.data || undefined,
              sponsors : this._sponsorService.allSponsors
            }
          })
          // for only edit
          dialog.afterClosed().subscribe((res) => {
            if(res?.formData){
              if(res?.mode == 'edit'){ 
                const payload = {
                  id: groupData.id,
                  name : res?.formData?.title,
                  sponsorList : []
                }
                res.selectedSponsors.forEach((item) => {
                  payload.sponsorList.push({sponsorId : item.id, sponsorRanking : item.rank})
                }) 
                this._commonservice.updateSponsorGroup(payload).then((result:any) => {
                  if(result.status == 200){
                    this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
                    this.updateListing(); 
                  }
                }) 
              }
            }
          })

        }else{
          this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
        }
      })
    }
  }

  onDelete(item){
    console.log("item", item);
    let dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to remove this Sponsor Group?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Sponsor Group?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    })

    dialog.afterClosed().subscribe((result)=> {
      if (result) {
        if(item?.isDeletable){
          this.callDeleteApi(item)
        }else{
          this.showWarningMessage(item)
        }
      }
    })
    
  }

  showWarningMessage(item){
    const dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      disableClose: false,
      data : {
        message : `This sponsor group is part of an event. Deleting it will also remove the sponsor group from those events. Are you sure you want to delete?`,
        image : '/assets/animated_icon/alarm.gif',
        imageSize : '150px',
        focus : 'right',
        deleteItem : 'Warning',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        this.callDeleteApi(item)
      }
    }) 
  }
  
  callDeleteApi(item){
    this._commonservice.deleteSponsorGroup({ "id": item.id }).then((result: any) => {
      if (result) {
        this._matsnakebar.open(result.message, 'OKAY',{duration: 2000})
        this.updateListing();
      }
    })
  }

}
