import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CryptoService } from './crypto.service';


@Injectable({
  providedIn: 'root'
})
export class DataDecryptionIntercaptorService implements HttpInterceptor {

  constructor(
    private _CryptoService : CryptoService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
 
    // Clone the request and manipulate it if needed
    const modifiedRequest = request.clone({
      // Example: Adding a custom header
      setHeaders: {
        'X-Custom-Header': 'custom-value'
      }
    });

    return next.handle(modifiedRequest).pipe(
      map((event: HttpEvent<any>) => {
        // Manipulate response data here if needed
        // For example, modifying response body

        if (event instanceof HttpResponse) {
          // Modify the response body here 
            if(event?.body){
              if(event?.body?.obfuscated){
                this._CryptoService.secretKey = this._CryptoService.getKey(event?.body?.obfuscated);
                const data = this._CryptoService.getData(event?.body?.obfuscated);
                console.log("url ===>",event.url);
                console.log("res====>", JSON.parse(JSON.parse(this._CryptoService.decryptData(data, this._CryptoService.secretKey))));
                return event.clone({body: JSON.parse(JSON.parse(this._CryptoService.decryptData(data, this._CryptoService.secretKey)))});
              }
            }
            // Example: Adding a property to each item in the response array 
        }

        return event;
      })
    );
  }
}
