import { Component, OnInit, Inject, Input, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonService } from '@fuse/services/common.service';
import { AddEventTypeService } from './add-event-type.service';
import { DomSanitizer } from "@angular/platform-browser";
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from "ngx-file-drop";
import { CropperComponent } from '../cropper/cropper.component';
import { LyDialog } from '@alyle/ui/dialog';
@Component({
  selector: 'app-add-dialog',
  templateUrl: './add-event-type-dialog.component.html',
  styleUrls: ['./add-event-type-dialog.component.scss']
})
export class AddEventTypeDialogComponent implements OnInit {
  previewUrl;
  previewUrlBg;
  imageFile;
  imageFileBg;
  isImageValid = true;
  isImageValidBg = true;
  form: UntypedFormGroup;
  croppedCateIconBase64: any = null;
  croppedCateBgBase64: any = null;
  heading: string;
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef
  @ViewChild('fileUploadBg', { static: true }) fileUploadBg: ElementRef

  constructor(
    private _fb: UntypedFormBuilder,

    public matDialogRef: MatDialogRef<AddEventTypeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _commonService: CommonService,
    private _addEventTypeService: AddEventTypeService,
    private _snackbar: MatSnackBar,
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private _matDialog: MatDialog
  ) {
    if (this._data.heading)
      this.heading = this._data.heading;
    // console.log("this.heading",this.heading);

  }
  ngOnInit() {
    if (this._data.eventType) {
      this.createForm(this._data.eventType)
      this.heading = 'Edit new event type'
    }
    else
      this.createForm()
  }

  createForm(data?) {
    this.form = this._fb.group({
      "typeName": [data ? data.title : '', Validators.required],
      "icon": [data ? data.icon : '',  Validators.required],
      "background": [data ? data.background : '', Validators.required]
    })
    this.previewUrl = this.form.value.icon;
  }

  onSubmit() {
    console.log("this is form", this.form); 
    if (this.form.valid) {
      this._addEventTypeService.addEventType(this.form.value).then(() => {
        this.matDialogRef.close(true)
      })
    }
  }

  //file drop
  // public dropped(files: NgxFileDropEntry[], dropperFor: "icon" | "bg") {
  //   // this.files = files;
  //   for (const droppedFile of files) {
  //     // Is it a file?
  //     if (droppedFile.fileEntry.isFile) {
  //       const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //       console.log("fileEntry", fileEntry);
  //       fileEntry.file((file: File) => {
  //         // Here you can access the real file
  //         if (dropperFor == "icon")
  //           this.openCropperForIcon(file);
  //         else if (dropperFor == "bg")
  //           this.openCropperForBg(file);
  //       });
  //     } else {
  //       // It was a directory (empty directories are added, otherwise only files)
  //       const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
  //     }
  //   }
  // }


  onRemoveThumb(type) {
    if (type == 'icon') {
        this.croppedCateIconBase64 = null;
        this.form.get('icon').setValue('');
    }
    else {
        this.croppedCateBgBase64 = null;
        this.form.get('background').setValue('');
    }
  }


  openCropperForIcon(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 250,
              height: 250,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 320,
                  height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedCateIconBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.onUploadImage(result.file, 'icon', result.blobFile)
      }
    });
  }

  openCropperForBg(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 250,
              height: 250,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 320,
                  height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedCateBgBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.onUploadImage(result.file, 'bg', result.blobFile)
      }
    });
  }

  // on upload Image presignedURL API
  onUploadImage(fileObj, type, blobFile) {

    const payload = {
      "fileExt":  '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": type == 'icon' ? 'icon_' : 'backgroundImage_',
      "folderName": 'event/type', // it is fix set by backend.
      'mimeType': fileObj?.type
    }

    return new Promise((resolve, reject) => {
      this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) {
          if (type == 'icon') {
            this.form.get('icon').setValue(result.data.imageUploadUrl);
            console.log("icon form status", this.form);
          } else {
            this.form.get('background').setValue(result.data.imageUploadUrl);
            console.log("background form status", this.form);
          } 
          // payload for get Image upload status
          const payload = {
            'id': result.data.id,
            'status': 1 // fix by backend.
          }
          this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
          this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
            if (uploadStatusResponse.status == 200) {
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              resolve(result.data);
            } else {
              this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              reject();
            }
          })
        }
      })
    })

  }

  removeImage() {
    this.fileUpload.nativeElement.value = ''
    this.previewUrl = ''
    this.form.get('icon').setValue('')
  }

  readUrlIcon(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('image type is ', file)
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/svg+xml' || file.type == 'image/vnd.microsoft.icon') {
        this.isImageValid = true;
      } else {
        this.isImageValid = false;
      }
      if (this.isImageValid) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrl = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString())
          console.log(this.previewUrl)
        }
        this.imageFile = file
        this.form.get('icon').setValue(file)
      }
    }
    console.log("form status", this.form);

  }

  readUrlBackground(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file.type == 'image/png' || file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/svg+xml' || file.type == 'image/vnd.microsoft.icon') {
        this.isImageValidBg = true;
      } else {
        this.isImageValidBg = false;
      }
      if (this.isImageValidBg) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
          this.previewUrlBg = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result.toString())
          console.log(this.previewUrlBg)
        }
        this.imageFileBg = file
        this.form.get('background').setValue(file)
      }
    }
    console.log("form status", this.form);
  }

  removeImageBg() {
    this.fileUploadBg.nativeElement.value = '';
    this.previewUrlBg = '';
    this.form.get('background').setValue('');
  }

}
