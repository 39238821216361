import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Enums } from '@fuse/utils/systemEnums';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { Validators } from 'ngx-editor';
import { CreateBadgeDesignService } from './create-badge-design.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LyDialog } from '@alyle/ui/dialog';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { CommonService } from '@fuse/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { CommonMessageSubmitComponent } from '@fuse/Dialog/common-message-submit/common-message-submit.component';
import { Router } from '@angular/router';
import { CertificateUpdateService } from 'app/main/certificate/create-certificate/certificate-update.service';
import { UpdateBadgeService } from './update-badge.service';

@Component({
    selector: 'app-create-badge-design',
    templateUrl: './create-badge-design.component.html',
    styleUrls: ['./create-badge-design.component.scss'],
    animations: [
        trigger('slideInOut', [
            state('in', style({
                right: 0,
            })),
            state('out', style({
                right: '-250px',
            })),
            transition('in => out', animate('300ms ease-out')),
            transition('out => in', animate('300ms ease-in')),
        ]),

        trigger('showHide', [
            transition(':enter',
                [
                    style({ transform: 'translateY(-20px)', opacity: 0 }),
                    animate(200, style({ transform: 'translateY(0px)', opacity: 1 })),
                ]),
            transition(':leave',
                [
                    animate(200, style({ transform: 'translateY(-20px)', opacity: 0 })),
                ]),
        ]),
    ]
})
export class CreateBadgeDesignComponent implements OnInit {
    showHiddenElement: boolean = false;
    isViewMode: boolean = false;
    form: FormGroup;
    BADGESIZES = Enums.BADGESIZES;
    DPIOPTIONS = Enums.DPIOPTIONS;
    selectedSize;
    clickTimer;
    selectedEleId = null;
    parentElement: string = '';
    public menuState = 'out';

    constructor(
        private _ToolbarService: ToolbarService,
        private _MatSnackBar: MatSnackBar,
        private _dialog: LyDialog,
        private _matDialog: MatDialog,
        private _router : Router,
        private _commonService: CommonService,
        private _fb: FormBuilder,
        public _CreateBadgeDesignService: CreateBadgeDesignService,
        private _UpdateBadgeService : UpdateBadgeService,
    ) {
        console.log("this._UpdateBadgeService.templateDetails", this._UpdateBadgeService.templateDetails);
        
        if(this._UpdateBadgeService.templateDetails){
            this.setToolBarTitle(true);
            this.createForm(this._UpdateBadgeService.templateDetails);
            this._CreateBadgeDesignService.templateData = this._UpdateBadgeService.templateDetails.layout;
            // console.log("this._UpdateBadgeService.templateDetails.layout", this._UpdateBadgeService.templateDetails.layout);
            // console.log("this._certificateToolService.templateData", this._certificateToolService.templateData);
            
        }else{
            this.setToolBarTitle(false);
            this.createForm();
        }
        // this.getAllBackgroundImages()
        // this.setToolBarTitle(false);
    }

    ngOnInit(): void {
        this.getAllBadgeImage();

        // set dynamic DPI
        this.form.get('size').valueChanges.subscribe((sizeId) => {
            if (sizeId) {
                this.selectedSize = this.BADGESIZES.filter((item) => item.id == sizeId)[0];
                this._CreateBadgeDesignService.templateData.width = Number(this.selectedSize?.actualSize?.width);
                this._CreateBadgeDesignService.templateData.height = Number(this.selectedSize?.actualSize?.height);
                this.form.get('DPI').setValue(this.BADGESIZES.filter((item) => item.id == sizeId)[0]?.DPI || '')
            } else if (sizeId == 0) {
                this.form.get('DPI').setValue('300')
            }
        })
        this.setFirstTimeSize();

    }

    @HostListener('document:keydown', ['$event']) onClickEvent(event: any) {
        let keys = event.keyCode;
        if (this.selectedEleId) {
          switch (keys) {
            case 37:
              this.leftClick()
              break;
            case 38:
              this.upClick()
              break;
            case 39:
              this.rightClick()
              break;
            case 40:
              this.downClick()
              break;
          }
        }
    }

    setToolBarTitle(edit) {
        if (edit) {
            // for title
            this._ToolbarService._ToolBarTitle.next("Edit Label");
            // for btn
            this._ToolbarService._isBackBtnShow.next(true);
        } else {
            // for title
            this._ToolbarService._ToolBarTitle.next("Add Label");
            // for btn
            this._ToolbarService._isBackBtnShow.next(false);
        }
    }

    setFirstTimeSize(id?){
        const selectedId =  id || 1;
        console.log("selectedId", selectedId);
        this.selectedSize = this.BADGESIZES.filter((item) => item.id == selectedId)[0];
        this.form.get('size').setValue(selectedId);
        this._CreateBadgeDesignService.templateData.width = Number(this.selectedSize?.actualSize?.width);
        this._CreateBadgeDesignService.templateData.height = Number(this.selectedSize?.actualSize?.height);
        this.form.get('DPI').setValue(this.BADGESIZES.filter((item) => item.id == selectedId)[0]?.DPI || '')
        this.selectedEleId = 'certificate';
    }

    createForm(data?) {
        console.log("form here with value", data);
        this.form = this._fb.group({
            size: [String(data?.badgeSizeType) || '', Validators.required],
            length: [data?.length || ''],
            height: [data?.height || ''],
            thumbnailImage : [''],
            DPI: [data?.dpi || '', Validators.required],
            labelName: [data?.name || '']
        })
        if(data)
        this.setFirstTimeSize(data?.badgeSizeType);
        console.log("form here with value", this.form);
    }

    calculatePixles(size, dpi) {
        return size * dpi;
    }

    saveCustomizeSize() {
        console.log("form", this.form);
        if (!this.form.get('length').value) {
            this._MatSnackBar.open('Please enter length', 'OKAY', { duration: 2000 })
        } else if (!this.form.get('height').value) {
            this._MatSnackBar.open('Please enter height', 'OKAY', { duration: 2000 })
        } else if (!this.form.get('DPI').value) {
            this._MatSnackBar.open('Please enter DPI', 'OKAY', { duration: 2000 })
        } else {
            if (this.form.get('size').value == 0) {

                const actualWidth = this.form.get('length').value * 96;
                const actualheight = this.form.get('height').value * 96;

                const width = this.calculatePixles(this.form.get('length').value, this.form.get('DPI').value);
                const height = this.calculatePixles(this.form.get('height').value, this.form.get('DPI').value);

                this.selectedSize['actualSize'].width = String(actualWidth);
                this.selectedSize['actualSize'].height = String(actualheight);

                this.selectedSize['size'].width = String(width);
                this.selectedSize['size'].height = String(height);
                this.selectedSize['DPI'] = this.form.get('DPI').value;

                console.log("=====>>>", this.selectedSize);
            }

        }
    }

    dynamicScale(value) {
        return 100 - value.toFixed(2);
    }

    getXScale() {
        if (this.selectedSize.id == 0) {
            return this.form.get('length')?.value + '"' || '';
        } else {
            return String(this.selectedSize.title).split('x')[0] + '"';
        }
    }

    getYScale() {
        if (this.selectedSize.id == 0) {
            return this.form.get('height')?.value + '"' || '';
        } else {
            return String(this.selectedSize.title).split('x')[1] + '"';
        }
    }

    onElementClick(event, eleType?, item?) {
        console.log("item", item);
        console.log("eleType", eleType);
        if (eleType) {
            this.parentElement = eleType;
        } else {
            this.parentElement = '';
        }
        this.selectedEleId = '';
        this.selectedEleId = event.target.id;
        console.log("event.target.id", event.target.id);
        console.log("parentElement", this.parentElement);

        // if(event.target.id != 'socialPost' && (!item?.isContentEditing || item?.link)){
        //     // this.dragElement(document.getElementById(event.target.id), item)
        // }
    }

    openCropperForBg(event, isFor?) {
        this._dialog.open<CropperComponent, any>(CropperComponent, {
            data: {
                fileEvent: event,
                myConfig: {
                    width: 868,
                    height: 608,
                    round: false,
                    type: "image/png",
                    keepAspectRatio: false,
                    responsiveArea: true,
                    output: {
                        // width: 868,
                        height: 608,
                    },
                    resizableArea: true,
                },
            },
            width: 520,
            height: 520,
            disableClose: true,
        })
            .afterClosed.subscribe((result: any) => {
                if (result) {
                    console.log("result", result);
                    this.onFileUpload(result.file, result.blobFile, isFor)
                }
            });
    }

    onFileUpload(fileObj, blobFile, isFor) {
        const payload = {
            "fileExt": `.${String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1)}`,
            "fileName": `${isFor == 'thumbnail' ? 'thumbnail_' :'background_'}`,
            "folderName": `${isFor == 'thumbnail' ? 'badge/thumbnail/image': 'badge/layout/backgroundImage'}`, // it is fix set by backend.
            'mimeType': `${fileObj?.type}`
        }
        this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
            if (result.status == 200) {

                // payload for get Image upload status
                const payload = {
                    'id': result.data.id,
                    'status': 1 // fix by backend. 
                }
                this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile).then((res) => {
                    if (res) {

                        if(isFor == 'signature'){
                            // this._CreateBadgeDesignService.templateData.signature['link'] = result.data.imageUploadUrl;
                        }
                        else if(isFor == 'thumbnail'){
                            this.form.get('thumbnailImage').setValue(result.data.imageUploadUrl);
                            console.log("this.form", this.form);
                            
                        }
                        else if(isFor == 'bg'){
                            this._CreateBadgeDesignService.templateData.backgroundImage = result.data.imageUploadUrl;
                            this.onAddSocialBgImage(result.data.imageUploadUrl);
                        }

                    }
                })

                this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                    if (uploadStatusResponse.status == 200) {
                        this._MatSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    } else {
                        this._MatSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                    }
                })
            }
        })
    }

    onAddSocialBgImage(url) {
        const payload = {
            imageUrl: url
        }
        this._commonService.onAddBadgeImage(payload).then((res: any) => {
            if (res.status == 200) {
                this.getAllBadgeImage();
            }
        })
    }

    getAllBadgeImage() {
        this._commonService.getAllBadgeImage().then((res: any) => {
            console.log("resgetAllBadgeImage", res);
            if (res.status == 200) {
                this._CreateBadgeDesignService.backgrounds = res.data;
            } else {
                this._CreateBadgeDesignService.backgrounds = [];
            }
        })
    }

    onDeleteBG(image) {
        let dialog = this._matDialog.open(ConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: false,
            data: {
                message: 'Are you sure you want to remove this ?',
                image: '/assets/ui/delete.png',
                focus: 'right',
                deleteItem: 'Remove Background?',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
            }
        })
        // handle after close
        dialog.afterClosed().subscribe((res) => {
            if (res) {
                this._commonService.deleteBadgeImage({ id: image.id }).then((result: any) => {
                    if (result.status == 200) {
                        this.getAllBadgeImage();
                        // check if deleteable image is selected then change the selected image
                        if (image.isSelected) {
                            this._CreateBadgeDesignService.templateData.backgroundImage = '';
                        }
                    }
                    this._MatSnackBar.open(result.message, "OKAY", { duration: 2000 })
                })
            }
        })

    }

    onSelectBg(i) {
        this._CreateBadgeDesignService.backgrounds.forEach((item: any, j) => {
            if (j == i) {
                item.isSelected = 1;
                this._CreateBadgeDesignService.templateData.backgroundImage = item.imageUrl;
                // this.setImageDimensions(item.image);
            } else {
                item.isSelected = 0;
            }
        })

    }


    onAddElement() {
        const tempField = JSON.parse(JSON.stringify(this._CreateBadgeDesignService.newDynamicElement))
        tempField.text = 'Enter Text Here';
        tempField.fieldName = '';
        tempField.contentEditable = true;

        if (this._CreateBadgeDesignService.templateData.dynamicElements.length != 0)
            tempField.position.top = (this._CreateBadgeDesignService.templateData.dynamicElements.length * 8);
        else
            tempField.position.top = 5

        this._CreateBadgeDesignService.templateData.dynamicElements.push(tempField);
        console.log("this._certificateToolService.templateData.dynamicElements", this._CreateBadgeDesignService.templateData.dynamicElements);
    }

    onEditContent(item) {
        console.log("item --- ", item);
        this.openTextDailog(item)
    }

    openTextDailog(item) {
        const dialog = this._matDialog.open(CommonMessageSubmitComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            disableClose: true,
            width: '380px',
            autoFocus: true,
            data: {
                title: `Add Custom Text`,
                label: 'Text',
                confirmButtonText: 'Save',
                errorMsg: 'Text is required.',
                text: item.text
            }
        })
        dialog.afterClosed().subscribe((res) => {
            if (res)
                item.text = res;
        })
    }

    onDeleteElement(item) {
        const index = this._CreateBadgeDesignService.templateData.dynamicElements.findIndex(element => element == item);
        this._CreateBadgeDesignService.templateData.dynamicElements.splice(index, 1);
        this.selectedEleId = '';
        this.parentElement = '';
    }

    toggleMenu() {
        this.menuState = this.menuState === 'out' ? 'in' : 'out';
    }
 
    onShowHideEle(isShow) {
        if (this.parentElement == '')
            this._CreateBadgeDesignService.templateData[this.selectedEleId].display = isShow
        else
            this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].display = isShow
    }

    globalAlign(val) {
        let selectedElement
        if (this.parentElement == '') {
            selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId]
        }
        else {
            selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)]
        }

        if (val == 'hl') {
            selectedElement.position.left = 0;
        }
        else if (val == 'hc') {
            selectedElement.position.left = 50;
        }
        else if (val == 'hr') {
            selectedElement.position.left = 100;
        }

        else if (val == 'vt') {
            selectedElement.position.top = 0;
        }
        else if (val == 'vc') {
            selectedElement.position.top = 50;
        }
        else if (val == 'vb') {
            selectedElement.position.top = 100;
        }
    }

    changeFont(opreators) {
        if (this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].fontSize > 8 && opreators === '-') {
            this.parentElement == '' ? this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].fontSize-- : this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].fontSize--;
        } else if (this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].fontSize < 150 && opreators === '+') {
            this.parentElement == '' ? this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].fontSize++ : this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].fontSize++;
        }
    }
 
    mouseup() {
        clearInterval(this.clickTimer);
    }

    mousedown(key) {
        if (key == 'lc') {
            this.clickTimer = setInterval(() => {
                this.leftClick()
            }, 125)
        }
        else if (key == 'rc') {
            this.clickTimer = setInterval(() => {
                this.rightClick()
            }, 125)
        }
        else if (key == 'uc') {
            this.clickTimer = setInterval(() => {
                this.upClick()
            }, 125)
        }
        else if (key == 'dc') {
            this.clickTimer = setInterval(() => {
                this.downClick()
            }, 125)
        }
        else if (key == 'rr') {
            this.clickTimer = setInterval(() => {
                this.rotateRight()
            }, 125)
        }
        else if (key == 'rl') {
            this.clickTimer = setInterval(() => {
                this.rotateLeft()
            }, 125)
        }
    }
 
    rotateRight() {
        this.clickTimer = setInterval(() => {
            let selectedElement
            if (this.parentElement == '') {
                selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId]
            }
            else {
                selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)]
            }
            selectedElement.rotate++
        }, 1)
    }

    rotateLeft() {
        this.clickTimer = setInterval(() => {
            let selectedElement
            if (this.parentElement == '') {
                selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId];
            }
            else {
                selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)];
            }
            selectedElement.rotate--
        }, 1)
    }


    leftClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement;
            if (this.parentElement == '') { selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId] }
            else { selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
            if (selectedElement?.position?.left > 0) selectedElement.position.left -= 0.5;
        }
    }

    rightClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement
            if (this.parentElement == '') { selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId] }
            else { selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
            if (selectedElement.position.left < Number(this._CreateBadgeDesignService.defaultTemplateFormat.width - selectedElement.width))
                selectedElement.position.left += 0.5;
        }
    }

    upClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement
            if (this.parentElement == '') { selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId] }
            else { selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
            if (selectedElement.position.top > 0) selectedElement.position.top -= 0.5;
        }
    }

    downClick() {
        if (!(this.selectedEleId == 'ticket')) {
            let selectedElement
            if (this.parentElement == '') { selectedElement = this._CreateBadgeDesignService.templateData[this.selectedEleId] }
            else { selectedElement = this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)] }
            if (selectedElement?.position?.top < 310)
                selectedElement.position.top += 0.5;
        }
    }

    setFontColor(color) {
        this.parentElement == '' ? (this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].fontColor = color) : (this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].fontColor = color)
    }

    onItalicClick() {
        if (this.parentElement == '') {
            this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].italic = !this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].italic
        } else
            this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].italic = !this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].italic
    }

    onUnderlineClick() {
        if (this.parentElement == '') {
            this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].underline = !this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].underline
        } else
            this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].underline = !this._CreateBadgeDesignService.templateData[this.parentElement][this.selectedEleId].underline
    }

    localAlign(value) {
        if (this.parentElement == '') {
            this._CreateBadgeDesignService.templateData.dynamicElements[this.selectedEleId].align = value
        }
        else {
            this._CreateBadgeDesignService.templateData[this.parentElement][parseInt(this.selectedEleId)].align = value
        }
    }

    onSavePost() {

        this._CreateBadgeDesignService.templateData.dynamicElements.map((element) => {
            if (element.position.top) {
                if (String(element.position.top).endsWith('px')) {
                    element.position.top = String(element.position.top).slice(0, String(element.position.top).length - 2)
                }
            }
            if (element.position.left) {
                if (String(element.position.left).endsWith('px')) {
                    element.position.left = String(element.position.left).slice(0, String(element.position.left).length - 2)
                }
            }
        })

        if (this._CreateBadgeDesignService.templateData?.profile?.position?.top) {
            if (String(this._CreateBadgeDesignService.templateData.profile.position.top).endsWith('px')) {
                this._CreateBadgeDesignService.templateData.profile.position.top = Number(String(this._CreateBadgeDesignService.templateData.profile.position.top).slice(0, String(this._CreateBadgeDesignService.templateData.profile.position.top).length - 2))
            }
        }

        if (this._CreateBadgeDesignService.templateData?.profile?.position?.left) {
            if (String(this._CreateBadgeDesignService.templateData.profile.position.left).endsWith('px')) {
                this._CreateBadgeDesignService.templateData.profile.position.left = Number(String(this._CreateBadgeDesignService.templateData.profile.position.left).slice(0, String(this._CreateBadgeDesignService.templateData.profile.position.left).length - 2))
            }
        }
 
        if(this._UpdateBadgeService.templateDetails){
            
            const payload = {
                id : this._UpdateBadgeService?.templateDetails?.id,
                name : this.form.get('labelName').value,
                backgroundImage : this._CreateBadgeDesignService.templateData.backgroundImage,
                layout : this._CreateBadgeDesignService.templateData,
                thumbnailImage : this.form.value?.thumbnailImage || '',
            }

            this._commonService.onUpdateLabel(payload).then((res:any)=>{
                if(res.status == 200){
                    this._MatSnackBar.open(res.message, 'OKAY', {duration : 2000});
                    this._router.navigate(['/badge/badge-templates'])
                }else{
                    this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000});
                }
            })
 
        }else{
            const payload = {
                thumbnailImage:this.form.value?.thumbnailImage || '',
                layout : this._CreateBadgeDesignService.templateData,
                backgroundImage : this._CreateBadgeDesignService.templateData.backgroundImage,
                name : this.form.value.labelName,
                badgeSizeType : this.form.value.size
            }
            console.log("add final payload", payload);
            this._commonService.addBadge(payload).then((res:any)=>{
                if(res.status == 200){
                    this._router.navigate(['/badge/badge-templates'])
                }else{
                    this._MatSnackBar.open(res.message, 'OKAY', {duration : 3000})
                }
            })
        }



    }

    ngOnDestroy(): void {
        this._CreateBadgeDesignService.templateData = {
            backgroundImage : '',
            dynamicElements : [],
            fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
            profile: {
                link: 'assets/ui/profile_dummy.png',
                width: 100,
                display: true,
                opacity: 1,
                rotate: 0,
                position: {
                    top: 31.99,
                    left: 90.5
                },
                height: 0,
                borderRadius: 0,
                borderColor: '',
                border: 0,
                aspectRatio: ''
            },
            qrCode: {
                link: 'assets/images/qrSample.png',
                width: 100,
                height: 100,
                borderRadius : 0,
                borderColor  : '',
                border : 0,
                display: false,
                opacity: 1,
                aspectRatio : '1/1',
                rotate: 0,
                position: {
                  top: 60.99,
                  left: 40
                }
            },
            width : 868,
            height : 608,  
        }
        this._CreateBadgeDesignService.defaultTemplateFormat = {
            backgroundImage : '',
            dynamicElements : [],
            width : 868,
            height : 0,
            fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap'
        } 
        this._UpdateBadgeService.templateDetails = null;
    }
}