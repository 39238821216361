import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { Enums } from '@fuse/utils/systemEnums';
import * as moment from 'moment';
import { Location } from '@angular/common';
import { GroupsService } from '../groups.service';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { MatDialog } from '@angular/material/dialog';
import { AddMoreFieldsComponent } from '@fuse/Dialog/add-more-fields/add-more-fields.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.scss'],
  providers: [DatePipe]
})
export class AddContactComponent implements OnInit {

  form: UntypedFormGroup
  enumsData:any;
  currentDate = new Date()
  labelShown = false
  editId: any;
  groupId: string;
  countryCode: any;
  selectedCountryCode: number;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,  
    CountryISO.UnitedKingdom,
  ];
  defCountryCode:any = 'IN';
  defCountryCodewh:any = 'IN';

  constructor(
    private _fb: UntypedFormBuilder,
    public _commonService: CommonService,
    private location: Location,
    private _groupsService: GroupsService,
    private route: Router,
    private _ToolbarService : ToolbarService,
    private _activatedRoute: ActivatedRoute,
    private datePipe: DatePipe,
    private _matDialog : MatDialog
  ) { 
    this.enumsData = Enums;
    this.setToolBarTitle();

  }

  ngOnInit(): void {
    this.getcountryCode()
    if(this._groupsService.contactDetail){
      
    }else{
      this.createForm();
    }
    this._activatedRoute.queryParams.subscribe((queryParamsData) => {
      if(queryParamsData?.groupId){
        console.log("queryParamsData", atob(queryParamsData.groupId));
        this.groupId = atob(queryParamsData.groupId)
      }
    })

    this._activatedRoute.params.subscribe((params) => {
      console.log("params", params);
      
      if(params.editId){
        this.editId = params.editId;
        this.createForm(this._groupsService.contactDetail);
      }else{
        this.editId = undefined;
        this.createForm();
        this._groupsService.contactDetail = '';
        
      }
    });

    this.form.get('contact').valueChanges.subscribe((result) => {
      console.log("result", result);
      if((result?.countryCode === 'CN')){
        if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
            this.form.get('contact').setErrors(null);
        }else{
            this.form.get('contact').setErrors({validatePhoneNumber : {valid : false}});
        }
      }
    })

    
    this.form.get('whatsApp').valueChanges.subscribe((result) => {
      console.log("result", result);
      if((result?.countryCode === 'CN')){
        if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
            this.form.get('whatsApp').setErrors(null);
        }else{
            this.form.get('whatsApp').setErrors({validatePhoneNumber : {valid : false}});
        }
      }
    })
  }


  setToolBarTitle(){  
    if(this.editId){
      // for title
      this._ToolbarService._ToolBarTitle.next('Edit Contact');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    }else{
      // for title
      this._ToolbarService._ToolBarTitle.next('Add Contact');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    }
  }


  createForm(data?){
    if(data?.countryCode){
      this.defCountryCode = data.countryCode
    }
    if(data?.countryCodeWh){
      this.defCountryCodewh = data.countryCodeWh
    }
    this.form = this._fb.group({
      title : [ data ? data.title : '',Validators.required],
      firstName : [data ? data.firstName : '',Validators.required],
      countryCode : [data ? data.countryCode : ''],
      countryCodeWh : [data ? data.countryCode : ''],
      lastName : [data ? data.lastName : '',Validators.required],
      contact : [data ? data.mobileNo : '',[Validators.required]],
      whatsApp : [data ? data.whatsAppNo : ''],
      email : [data ? data.email : '', [Validators.required, Validators.email]],  // [Validators.required, Validators.email]
      dob :[data ? moment(new Date(data.dob)) : ''],
      profession :[data ? data.profession : ''],
      significantDate : [data?.significantdate ? moment(new Date(data.significantdate)) : ''],
      label : [data ? data.label : ''],
      // dynamicFields : this._fb.array([])
      // group:[''],
    })
  }

  submit(){
    if(this.editId){
      const payload = {
        "id": this.editId,
        "title": this.form.get('title').value,
        "firstName": this.form.get('firstName').value,
        "lastName": this.form.get('lastName').value,

        "mobileNo":  this.form.get('contact').value?.number.split(" ").join(""),
        "phoneCode" : this.form.get('contact').value?.dialCode,
        "countryCode" : this.form.get('contact').value?.countryCode,

        "whatsAppNo" : this.form.get('whatsApp').value?.number.split(" ").join(""),
        "phoneCodeWh" : this.form.get('whatsApp').value?.dialCode,
        "countryCodeWh" : this.form.get('whatsApp').value?.countryCode,

        "email": this.form.get('email').value,
        "dob": this.form.get('dob').value ? (this.form.get('dob').value).format('x') : '',
        "profession": this.form.get('profession').value,
        "significantDate": this.form.get('significantDate').value ? Number((this.form.get('significantDate').value).format('x')) : '',
        "label": this.form.get('label').value,
        "groupIds" : this.groupId || ''
      }
      console.log("payload =======> ", payload);
      this._groupsService.updateContact(payload).then((result:any) => {
        if(result){
          this.location.back();
        }
      })
    }else{ 
      const payload = {
        "title": this.form.get('title').value,
        "firstName": this.form.get('firstName').value,
        "lastName": this.form.get('lastName').value,

        "mobileNo":  this.form.get('contact').value?.number.split(" ").join(""),
        "phoneCode" : this.form.get('contact').value?.dialCode,
        "countryCode" : this.form.get('contact').value?.countryCode,

        "whatsAppNo" : this.form.get('whatsApp').value?.number.split(" ").join(""),
        "phoneCodeWh" : this.form.get('whatsApp').value?.dialCode,
        "countryCodeWh" : this.form.get('whatsApp').value?.countryCode,
        
        "email": this.form.get('email').value,
        "dob": this.form.get('dob').value ? (this.form.get('dob').value).format('x') : '',
        "profession": this.form.get('profession').value,
        "significantDate": this.form.get('significantDate').value ? Number((this.form.get('significantDate').value).format('x')) : '',
        "label": this.form.get('label').value,
        "groupIds" : this.groupId || ''
      }
      this._groupsService.addContact(payload).then((result:any) => {
        if(result){
          this.location.back();
        }
      });
    }
  }

  selectcountryCode(event){
    this.selectedCountryCode = parseInt(event.value);
  }

  getcountryCode(){
    this._commonService.getAllCountries().then((result: any) => {
        if(result.length)
        this.countryCode = result;   
        else
        this.countryCode = [];
    })
  }

  /* MAKE FORM CONTROLS */
  // get dynamicFields(): FormArray {
  //   return this.form.controls["dynamicFields"] as FormArray;
  // }
  
  // addCustomField(){
  //   this._matDialog.open(AddMoreFieldsComponent, {
  //     minWidth : '320px',
  //     autoFocus : false,
  //     panelClass: "no-padding-dialog",
  //   }).afterClosed().subscribe((fieldData) => {
  //     if(fieldData){
  //       let object = {}
  //       object[fieldData.fieldName] = '';
  //       (<FormArray>this.form.get("dynamicFields")).push(
  //         this._fb.group(object)
  //       );
  //       console.log("form value", this.form);
  //     }
  //   })
  // }

}
