import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; 
import { RouterModule, Routes } from '@angular/router';
import { EventDomUsersListComponent } from './event-dome-users-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { DomeUserProfileComponent } from './dome-user-profile/dome-user-profile.component';
import { DomeProfileLayoutComponent } from './dome-profile-layout/dome-profile-layout.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module'; 
import { NgxShimmerLoadingModule } from 'ngx-shimmer-loading';
import { DomeUsersListComponent } from './dome-user-profile/tabs/dome-users-list/dome-users-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

const routes: Routes = [
  {
    path: 'network',
    // pathMatch: 'full',
    redirectTo: 'network/list'
  },
  {
    path : 'list',
    component : EventDomUsersListComponent
  },
  {
    path : 'profile',
    component : DomeUserProfileComponent,
    resolve : {
      // data : DomeUserProfileService
    }
  },
]


@NgModule({
  declarations: [
    EventDomUsersListComponent, DomeUserProfileComponent, DomeProfileLayoutComponent,DomeUsersListComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    MatPaginatorModule,
    MatButtonModule,
    NgxShimmerLoadingModule,
    MatProgressBarModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatFormFieldModule,
    MatIconModule,
    FuseSharedModule,
    MatInputModule,
    MatRippleModule,
    FusePipesModule,
    MatSortModule,
    MatTableModule,
    MatSelectModule,
    NgxMaterialTimepickerModule,
    NgxPaginationModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    MatToolbarModule,
    MatDialogModule,
  ],
})
export class DomeUserProfileModule { }
