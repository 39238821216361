import { SmspackageService } from './smspackage.service';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '@fuse/services/common.service';
import {MatCardModule} from '@angular/material/card';

@Component({
  selector: 'app-smspackage',
  templateUrl: './smspackage.component.html',
  styleUrls: ['./smspackage.component.scss']
})
export class SmspackageComponent implements OnInit {

  constructor(
    public _commonService: CommonService,
    public _smspackage: SmspackageService
  ) { 
    
  }

  ngOnInit() {
  }

  getColor(i){
    switch (i) {
      case 0:
        return '#FF6565';
      case 1:
        return '#3B86FF';
      case 2:
        return '#43425D';
      case 3:
        return '#FF6565';
      case 4:
        return '#3B86FF';
      case 5  :
        return '#43425D';
    }
  }
}
