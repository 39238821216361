import { Injectable } from '@angular/core';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddOrganiserService {
  organiser
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _commonService:CommonService
  ) { }

  addOrganiser(data) {
    // let formData = new FormData();
    // formData.append("name", data.name)
    // formData.append("profilePic", data.profileImage)
    // formData.append("webSite", data.website)
    // formData.append("description", data.description)
    // formData.append("mobile", data.mobile?.number.split(" ").join(""))
    // formData.append("countryCode", data.mobile?.countryCode)
    // formData.append("phoneCode", data.mobile?.dialCode) 

    const payload = {
      name: data.name,
      profilePic: data.profileImage,
      webSite: data.website,
      description: data.description,
      mobile: data.mobile?.number.split(" ").join(""),
      countryCode: data.mobile?.countryCode,
      phoneCode: data.mobile?.dialCode,
    }

    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/event/add/organiser', payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200)
            resolve(result)
          this._matSnackBar.open(result.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
          this._fuseProgressBarService.hide()
        }, reject)
    })
  }

  getOrganiserById(id) {
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/getbyid/organiser', { organiserId: id }, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200) {
            this.organiser = result.data
            resolve(result)
          }else if(result.status == 400) {
            this.organiser = []
            resolve(result)
          }
        }, reject)
    })
  }

  editOrganiser(data, id) {
    console.log("in edit data", data.website == '' ? '' : data.website == 'https://' ? '' : data.website);
    // let formData = new FormData();
    // formData.append("name", data.name)
    // formData.append("profilePic", data.profileImage)
    // formData.append("webSite", data.website == '' ? '' : data.website == 'https://' ? '' : data.website)
    // formData.append("description", data.description)
    // formData.append("mobile", data.mobile?.number.split(" ").join(""))
    // formData.append("countryCode", data.mobile?.countryCode)
    // formData.append("phoneCode", data.mobile?.dialCode)
    // formData.append("organiserId", id)

    const payload = {
      name: data.name,
      profilePic: data.profileImage,
      webSite: data.website,
      description: data.description,
      mobile: data.mobile?.number.split(" ").join(""),
      countryCode: data.mobile?.countryCode,
      phoneCode: data.mobile?.dialCode,
      organiserId : id
    }

    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/event/edit/organiser', payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          if (result.status == 200)
            resolve(result)
          this._matSnackBar.open(result.message, 'OK', {
            duration: 2000,
            verticalPosition: "bottom"
          })
          this._fuseProgressBarService.hide()
        }, reject)
    })
  }

}
