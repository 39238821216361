import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-dome-profile-layout',
  template: '<router-outlet></router-outlet>',
  styles: [],
  animations : fuseAnimations
})
export class DomeProfileLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
