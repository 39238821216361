import { Component, OnInit, ViewChild, ElementRef, Inject, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AddOrganiserService } from './add-organiser.service';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxFileDropEntry,FileSystemFileEntry,FileSystemDirectoryEntry} from "ngx-file-drop";
import { LyDialog } from '@alyle/ui/dialog';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
@Component({
  selector: 'app-add-organiser-dialog',
  templateUrl: './add-organiser-dialog.component.html',
  styleUrls: ['./add-organiser-dialog.component.scss']
})
export class AddOrganiserDialogComponent implements OnInit {
  imageFile;
  isImageValid = true;
  croppedProfileImgBase64 = null;
  form: UntypedFormGroup
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef
  profileImageAsFile: any;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  defCountryCode: String = 'IN';


  constructor(
    private _fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddOrganiserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any,
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private _matDialog: MatDialog,
    private _snackbar : MatSnackBar,
    private _addOrganiserService:AddOrganiserService,
    private _commonService : CommonService
  ) {
    if (this._data?.organiser)
      this.createForm(this._data?.organiser)
    else
      this.createForm()
  }

  ngOnInit() {

    this.removeHTTPS();


    this.form.get('mobile').valueChanges.subscribe((result) => {
      console.log("result", result);
      if((result?.countryCode === 'CN')){
        if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
            this.form.get('mobile').setErrors(null);
        }else{
            this.form.get('mobile').setErrors({validatePhoneNumber : {valid : false}});
        }
      }
    })


  }

  createForm(data?) {
    if(data?.profileImage){
      this.croppedProfileImgBase64 = data.profileImage;
    }
    if(data?.countryCode){
      this.defCountryCode = data.countryCode
    }
    this.form = this._fb.group({
      "name": [data ? data.name : '', Validators.required],
      "website": [data ? data?.webSite : 'https://'],
      "mobile": [data ? data.mobile : '', [Validators.required, Validators.pattern]],
      "description": [data ? data.description : ''],
      "profileImage": [data ? data.profileImage : '']
    })
  }

  async onSubmit() {
    return new Promise((resolve, reject) =>{ 
      Promise.all([
      ]).then(() => {
        if (this._data.organiser)
              this._addOrganiserService.editOrganiser(this.form.value, this._data.organiser.id).then(() => {
                this.matDialogRef.close(true)
                resolve('')
            })
        else
          this._addOrganiserService.addOrganiser(this.form.value).then(() => {
          this.matDialogRef.close(true)
        })
      }, reject)
    })
  }
  
  removeHTTPS() {
    this.form.controls.website.valueChanges.subscribe((res) => {
        if (res)
            if (String(res).startsWith('https://https://')) {
                let trimStr = String(this.form.controls.website.value).slice(8, String(this.form.controls.website.value)?.length);
                this.form.controls.website.setValue(trimStr);
            }
    })
  }

  
  removeImage() {
    this.fileUpload.nativeElement.value = ''
    this.croppedProfileImgBase64 = null;
    this.form.get('profileImage').setValue('')
  }
 

  openCropper(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 250,
              height: 250,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 320,
                  height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedProfileImgBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.onUploadImage(result.file, result.blobFile)
      }
    });
  }


  onUploadImage(fileObj, blobFile){
      const payload = {
        "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
        "fileName" : 'organiser_',
        "folderName": 'event/organiser',// it is fix set by backend.
        'mimeType': fileObj?.type
      }
      return new Promise((resolve, reject) => {
      this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) {
          this.form.get('profileImage').setValue(result.data.imageUploadUrl);
          // payload for get Image upload status
          const payload = {
            'id': result.data.id,
            'status': 1 // fix by backend.
          }
          this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile)
          this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
            if (uploadStatusResponse.status == 200) {
                resolve('')
                this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
            } else {
                reject
                this._snackbar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
            }
          })
        }
      })
      })
  }

  onRemoveThumb(){
    this.croppedProfileImgBase64 = null;
    this.form.get('profileImage').setValue('');
  }

}
