import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-publish-poll',
  templateUrl: './publish-poll.component.html',
  styleUrls: ['./publish-poll.component.scss']
})
export class PublishPollComponent implements OnInit {
  form: FormGroup;
  hours = Array.from({ length: 25 }, (_, index) => index);
  days = Array.from({ length: 31 }, (_, index) => index);
  minutes = Array.from({ length: 61 }, (_, index) => index);
  showEventEndDate: boolean;
  isDateTimeSelect: boolean;
  // private _unsubscribeAll: Subject<unknown>;
  constructor(public matDialogRef: MatDialogRef<PublishPollComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: any, private fb: FormBuilder) {

    console.log("_data ===>", _data)
    this.form = this.fb.group({
      publishType: ['1', Validators.required],
      startDate: ['',],
      days: ['', Validators.required],
      hours: ['', Validators.required],
      minutes: ['', Validators.required],
    })

  }

  ngOnInit(): void {
    this.form.get('publishType').valueChanges.subscribe((val) => {
      const startDate = this.form.get('startDate')
      if (val === '1') {

        startDate.setValue("");
        startDate.setValidators(null);
      } else if (val === '2') {
        startDate.setValue("");
        startDate.setValidators([Validators.required]);
      } else {
        startDate.setValue("");
        startDate.setValidators(null);
      }

      startDate.updateValueAndValidity();
    })
  }


  public onSubmit(): void {
    if (this.form.valid) {
      this.matDialogRef.close(this.form.value);
    } else {
      this.form.markAllAsTouched()
    }
  }


}
