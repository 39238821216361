import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonService } from '@fuse/services/common.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddSubUserService } from './add-sub-user.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { MatDialog } from '@angular/material/dialog';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LyDialog } from '@alyle/ui/dialog';


@Component({
  selector: 'app-add-sub-user',
  templateUrl: './add-sub-user.component.html',
  styleUrls: ['./add-sub-user.component.scss']
})
export class AddSubUserComponent implements OnInit {
  form: UntypedFormGroup
  croppedProfileBase64 = null;
  todayDate  = new Date();
  eightteenYearAgoFormToday;
  constructor(
    public _commonService: CommonService,
    private _ToolbarService : ToolbarService,
    private _matSnackBar : MatSnackBar,
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private _fb: UntypedFormBuilder,
    public _addSubUserService: AddSubUserService,
    private _matDialog: MatDialog
  ) {
      /* date of brith validation */
      this.eightteenYearAgoFormToday = this.todayDate.setFullYear(this.todayDate.getFullYear()-18);
      this.eightteenYearAgoFormToday = new Date(this.eightteenYearAgoFormToday)

      if (this._addSubUserService.subUserId) {
        this.createForm(this._addSubUserService.subUser)
        this.form.disable()
        this.setToolBarTitle(true);
      }else{
        this.createForm()
        this.setToolBarTitle(false);
      }
      
   }
    uuidv4() {
    return 'xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 12 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(12);
    });
  }
  ngOnInit() {
  
  }


  setToolBarTitle(res){
    if(res){
      // for title
      this._ToolbarService._ToolBarTitle.next('Edit Staff');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    }else{
      // for title
      this._ToolbarService._ToolBarTitle.next('Add New');
      // for btn
      this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  createForm(user?) {
    console.log("user", user);
    if (user) {
      this.croppedProfileBase64 = user?.profileImage
    }
    this.form = this._fb.group({
      name: [user ? user.name : '', Validators.required],
      email: [user ? user.email : '', [Validators.required, Validators.email]],
      mobile: [user ? user.mobile : '', [Validators.required, Validators.pattern]],
      password: [user ? user.password :'', Validators.required],
      birthDate: [user ? new Date(user.birthDate) : ''],
      gender: [user ? user.gender.toString() : ''],
      country: [user ? user.country : ''],
      state: [user ? user.state : ''],
      city: [user ? user.city : ''],
      permissionId: [user ? user.userType : '', Validators.required],    //role id
      profile: [user ? user.profileImage : ''],    //role id
    });

    
  }

  onSelectCountry(e) {
    this._commonService.getStates(e.value).then((result: any) => {
      if (result.status == 200)
        this._addSubUserService.states = result.data
    })
  }
 

  openCropperForProfile(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 250,
              height: 250,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 320,
                  height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedProfileBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.uploadProfileOnPresignedURL(result.file, result.blobFile)
      }
    });
  }

  uploadProfileOnPresignedURL(fileObj, blobFile) {
    const payload = {
        "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
        "fileName": 'profile_',
        "folderName": 'event/staff', // it is fix set by backend.
        'mimeType': fileObj?.type
    }
    
    this._commonService.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) {
            this.form.get('profile').setValue(result.data.imageUploadUrl);
            // payload for get Image upload status
            const payload = {
                'id': result.data.id,
                'status': 1 // fix by backend. 
            }
            this._commonService.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj.type, blobFile);
            this._commonService.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
                if (uploadStatusResponse.status == 200) {
                    this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                } else {
                    this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
                }
            })
        }
    })
}

  onRemoveProfile() {
    this.form.get('profile').setValue('');
    this.croppedProfileBase64 = null;
  }
  
 generatepasss(){
    const a=this.uuidv4()
    console.log(a);
    this.form.patchValue({password:a})
    
  }   
}
  



