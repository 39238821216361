import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { DistributorsListService } from 'app/main/distributors/distributors-list/distributors-list.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';

@Injectable({
  providedIn: 'root'
})
export class AssignDistributorService implements Resolve<any>{
  eventId
  eventList
  maxAvailableRecordsInTable
  eventTypeList: any;
  constructor(
    private _httpClient: HttpClient,
    private _distributorsListService: DistributorsListService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.eventId = route.params.eveId
    return new Promise((resolve, reject) => {
      Promise.all([
        this._distributorsListService.getDistributorList({ forAssign: 1,eventId:this.eventId }).then((result: any) => {
          if (result.status == 200) {
            this.maxAvailableRecordsInTable = result.data.totalRecords;
            this.eventTypeList = result.data.data;
            this.eventList = result.data.data;
          }
        })
      ]).then(
        () => {
          // this.getAllDistributorList();
          resolve('');
        },
        reject
      );

    });
  }

  // getAllDistributorList(){
  //   const payload ={
  //     eventId: "1",
  //     forAssign: 1
  //   } 
  //   return new Promise((resolve, reject) =>{
  //     this._httpClient.post(environment.apiURL + 'admin/distributor/getalldistributors',payload, this.getHeader())
  //     .subscribe((result:any) =>{
  //       if (result.status == 200) {
  //         console.log("your api data is here", result);
          
  //       }
  //     })
  //   })
  // }


  
  getHeader(headerType?:'formData') {
    if (headerType === 'formData') {
      return {
        headers: new HttpHeaders({
          'Authorization': localStorage.getItem('userToken')
        })
      }
    }else
    return {
      headers: new HttpHeaders({
        'Authorization': localStorage.getItem('userToken'),
        'Content-Type': 'application/json'
      })
    }

  }


  
}
