import { Component, Input, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-dome-users-list',
  templateUrl: './dome-users-list.component.html',
  styleUrls: ['./dome-users-list.component.scss'],
  animations : fuseAnimations
})
export class DomeUsersListComponent implements OnInit, OnChanges {

  @Input('filteredData') filterData:any;
  @Input('currentTabData') currentTab:any;
  @Output('filterOut') filterOut = new EventEmitter();
  
  filter = {
    customerId : null,
    search : '',
    // pageIndex : 1,
    currentPage : 1,
    totalItems: 0,
    // pageSize : 10,
    itemsPerPage : 9,
  }

  defaultImages = [
    'assets/images/profileImage/01.png',
    'assets/images/profileImage/02.png',
    'assets/images/profileImage/03.png',
    'assets/images/profileImage/04.png',
    'assets/images/profileImage/05.png',
    'assets/images/profileImage/06.png',
    'assets/images/profileImage/07.png',
    'assets/images/profileImage/08.png',
    'assets/images/profileImage/01.png',
    'assets/images/profileImage/02.png',
    'assets/images/profileImage/03.png',
    'assets/images/profileImage/04.png',
    'assets/images/profileImage/05.png',
  ]

  constructor(    private _router : Router,
    private _activatedRoute : ActivatedRoute) {
  }

  ngOnInit(): void {

  }

  ngOnChanges(){
    this.filter.currentPage = this.filterData?.pageIndex;
    this.filter.totalItems = this.filterData?.totalRecords;
  }

  navigateToProfile(customerId){
    this._router.navigate([], { relativeTo: this._activatedRoute , queryParams : {customer : customerId}, queryParamsHandling: 'merge'});
  }

  onPageChange(event){
    this.filter.currentPage = event;
    this.filterOut.emit(this.filter);
  }

  onSearch(val){
    this.filter.search = val;
    this.filterOut.emit(this.filter);
  }

  clearSearch(){
    this.filter = {
      customerId : null,
      search : '',
      currentPage : 1,
      totalItems: 0,
      itemsPerPage : 9,
    }
    this.filterOut.emit(this.filter);
  }

}