import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgxFileDropEntry} from 'ngx-file-drop';
import { CreateTicketService } from './create-ticket.service';
import { CommonService } from '@fuse/services/common.service';
import { HttpClient } from '@angular/common/http';
import { TicketListService } from '../ticket-list/ticket-list.service';
import { AddTicketGuidelinesComponent } from '@fuse/Dialog/add-ticket-guidelines/add-ticket-guidelines/add-ticket-guidelines.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PublishEventConfirmationDialogComponent } from '@fuse/Dialog/publish-event-confirmation-dialog/publish-event-confirmation-dialog.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { fuseAnimations } from '@fuse/animations';
import { Location } from '@angular/common'
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { Enums } from '@fuse/utils/systemEnums';
import { LABEL_CODE, SEATNUMBERGENERATETYPE, TICKETNUMBERLENGTH, TICKETSEATFORMATTYPE } from './createTicketType';
import { ExtendTicketLimitDialogComponent } from '@fuse/Dialog/extend-ticket-limit-dialog/extend-ticket-limit-dialog.component';

@Component({
  selector: 'app-create-ticket',
  templateUrl: './create-ticket.component.html',
  styleUrls: ['./create-ticket.component.scss'],
  animations: fuseAnimations
})
export class CreateTicketComponent implements OnInit, OnDestroy {
  LABEL_CODE:LABEL_CODE[] = Enums.LABEL_CODE;
  SEATNUMBERGENERATETYPE:SEATNUMBERGENERATETYPE[] = Enums.SEATNUMBERGENERATETYPE;
  TICKETSEATFORMATTYPE:TICKETSEATFORMATTYPE[] = Enums.TICKETSEATFORMATTYPE;
  TICKETNUMBERLENGTH:TICKETNUMBERLENGTH[] = Enums.TICKETNUMBERLENGTH;
  createTicketForm: UntypedFormGroup;
  previewUrl: any = "";
  templateBackgroundColor;
  addSelectedItem;
  public files: NgxFileDropEntry[] = [];
  // croppedImageFile
  croppedImageBase64 = ''
  // templateBackgroundColor = 'rgba(0, 0, 0, 0.6)'
  dialogRef: any;
  eveStartDate: any;
  eveEndDate: any;
  freeWithNoSeatEvent: any;
  allEvents: any;
  hideFormField: boolean = false;
  hideFormFieldOnlyFreeCase: boolean = false;
  editEvent: any;
  // guestLabel: any;
  preBuildTemplate: any;
  selectedEvent: any;
  templateApply: boolean = false;
  isFree: any;
  noSeatEvent: any;
  currentUrl;
  eventEnums: number = 0;
  selectedEventType: any;
  ticketTypeStyleId: any;
  currencyId: any;
  eventStartDateConverted: string;
  eventEndDateConverted: string;
  totalLeftSeat: any;
  autoSelectedEventId: any;
  dialogRefAskForPublishEvent: any;
  eventDetails: any;
  showDetails: boolean = false;
  categoriesList: any;
  ticketCreated: boolean = false;
  isFormDisabled = false;
  customizeTicket: boolean = false;
  maxGeneratableTicketCount: number | boolean;
  ticketNumberStartFromMaxLength;
  ticketFormate: String;
  randomTicketNumber = '';
  createdTicketData: any = '';
  ticketId: Number;
  allTicketType:[] = []
  previousUrl: any;

  constructor(
    private fb: UntypedFormBuilder,
    private _matDialog: MatDialog,
    public _createTicketService: CreateTicketService,
    public _commonService: CommonService,
    public _ToolbarService: ToolbarService,
    private _location : Location,
    private _AddEditEventService: AddEditEventService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _httpClient: HttpClient,
    private _ticketListService: TicketListService,
    private _ActivatedRoute: ActivatedRoute,
    private _matSnackBar: MatSnackBar,
    private _router: Router
  ) {

    console.log("this.ticketCreated in constructor", this.ticketCreated);
    console.log("this.customizeTicket in constructor", this.customizeTicket);
    console.log("this.mode in constructor", this._createTicketService.mode);
    // this.showCustomizeTicketCard();
    this.createForm();
    this.setToolBarTitle(false);
    this.allTicketType = this._createTicketService?.allTicketTypes?.data?.ticketTypes;
    this.currencyId = localStorage.currencyId;
    this._createTicketService.initiateTemplateData();

    /* TICKET EDIT CASE */
    if (this._createTicketService.mode == 'editTicket') {
      this.setToolBarTitle(true);
      this.createForm(this._createTicketService.ticketData);
      this.onEventSelect(this._createTicketService.eventId);
      this.ticketTypeStyleId = this._createTicketService.ticketData.ticketTypeStyleId;
      const ticketTypeIdInEdit = this._createTicketService.ticketData.ticketTypeId;
      this.selectedEventType = this._createTicketService.allTicketTypes.data.ticketTypes.filter(item => item.id == ticketTypeIdInEdit);
      
      /* ADD TICKETNUMBERPREFIX LABEL INTO TICKET TEMPLATE IN EDIT CASE */
      this.createTicketForm.get('ticketNumberPrefix').valueChanges.subscribe((val) => {
        this._createTicketService.ticketPrefix = val
      })

      /* ADD PRICE INTO TICKET LAYOUT IN EDIT CASE */
      this.createTicketForm.get('price').valueChanges.subscribe((val) => {
        console.log("this._createTicketService.templateData.price.text", this._createTicketService.templateData);
        this._createTicketService.templateData.price.text = val
      })

      /* ADD TICET NUMBER INTO TICKET LAYOUT IN EDIT CASE */
      // this.createTicketForm.get('ticketNumberFrom').valueChanges.subscribe((val) => {
      //   if (!isNaN(val))
      //     this._createTicketService.ticketNumStart = val
      //   })

      /* ADD ORG. LABEL NAME IN EDIT CASE */
      // this.createTicketForm.get('orgLabel').valueChanges.subscribe((val)=>{
      //   this._createTicketService.templateData.orgName.text = val + " " + this._createTicketService.ticketToEdit.orgName;
      // })

      /* GUEST LABEL IN EDIT CASE */

      /* GUEST LABEL PROPRETIES ADD INTO LEYOUT IN EDIT CASE */
      // this.createTicketForm.get('guestLabel').valueChanges.subscribe((val) => {
      //   this.guestLabel = val;
      //   let newGuest:any = JSON.parse(JSON.stringify(this._createTicketService.newDynamicElement))
      //   newGuest.text =  guestName.length == 1 ? this.guestLabel + " " + guestName: this.guestLabel + " " + guestName.join(", ", ' ');
      //   this._createTicketService.templateData.guest = [];
      //   newGuest.position.top = guestPositionTop;
      //   newGuest.position.left = guestPositionleft;
      //   newGuest.fontFamily = guestFontFamily;
      //   newGuest.fontColor = guesFontColor;
      //   newGuest.fontSize = guestfontSize;
      //   newGuest.display = guestDisplay;
      //   newGuest.bold = guestBole;
      //   newGuest.opacity = guestOpacity;
      //   newGuest.rotate = guestRotate;
      //   newGuest.underline = guestUnderLine;
      //   newGuest.width = guestWidth;
      //   newGuest.italic = guestItalic;
      //   this._createTicketService.templateData.guest.push(newGuest);
      // })
      // this.showCustomizeTicketCard();
    }
    else{
      if (this._createTicketService.mode == 'createTicketFormEvent') {
        this.createForm();
        this.onEventSelect(this._createTicketService.eventId);
        this.createTicketForm.get('selectedEvent').setValue(String(this._createTicketService.eventId));
        this.createTicketForm.get('selectedEvent').disable();
        this.freeWithNoSeatEvent = Number(this._createTicketService.eventId);
      }

      /* INPUT PRICE SET INTO LAYOUT */
      this.createTicketForm.get('price')?.valueChanges.subscribe((val) => {
        this._createTicketService.templateData.price.text = val
      })

      /* TICKET PREFIX LABEL SET INTO LAYOUT */
      this.createTicketForm.get('ticketNumberPrefix')?.valueChanges.subscribe((val) => {
        this._createTicketService.ticketPrefix = val
      })

      /* TICKET NUMBER START FROM SET INTO LAYOUT */
      // this.createTicketForm.get('ticketNumberFrom')?.valueChanges.subscribe((val) => {
      //   if (!isNaN(val))
      //     this._createTicketService.ticketNumStart = val
      // })
    }
  }
 

  ngOnInit() {

    this._router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      console.log("previousUrl -->", this.previousUrl);
      console.log("event -->", event);
      this.currentUrl = event.url;
    })

    this.createTicketForm.get('seatNumberGenrateType').valueChanges.subscribe((res) => {
      if (res)
        if (res == 1) {
          this.createTicketForm.get('ticketNumberFrom').setValidators(Validators.required);
          this.createTicketForm.get('ticketNumberFrom').setValue('');
          this.createTicketForm.get('ticketSeatFormat').setValidators(null);
          this.createTicketForm.get('ticketSeatFormat').setValue('');
          this.createTicketForm.get('ticketNumberFrom').setErrors(null);
          this.ticketFormate = undefined;
        } else {
          this.createTicketForm.get('ticketNumberFrom').setValidators(null);
          this.createTicketForm.get('ticketNumberFrom').setValue(null);
          this.createTicketForm.get('ticketNumberFrom').setErrors(null);
          this.createTicketForm.get('ticketSeatFormat').setValidators(Validators.required);
          this.createTicketForm.get('ticketSeatFormat').setValue('');
        }
    })

    this.createTicketForm.get('ticketSeatFormat').valueChanges.subscribe((res) => {
      if (res)
        if (res != 2) {
          this.createTicketForm.get('ticketNumberFrom').setValidators(null);
          this.createTicketForm.get('ticketNumberFrom').setValue(null);
          this.createTicketForm.get('ticketNumberFrom').setErrors(null);
        } else {
          this.createTicketForm.get('ticketNumberFrom').setValidators(Validators.required);
          this.createTicketForm.get('ticketNumberFrom').setValue('');
        }
    })

    this.createTicketForm.get('ticketNumberLength').valueChanges.subscribe((res) => {
      if (res && this.createTicketForm.get('ticketSeatFormat').value) {
        this.maxGeneratableTicketCount = this.ticketNumberGen(this.createTicketForm.get('ticketSeatFormat').value, res);
        this.createTicketNumberFormate(this.createTicketForm.get('ticketSeatFormat').value, res, this.createTicketForm.get('ticketNumberPrefix')?.value);
      }
    })

    this.createTicketForm.get('ticketSeatFormat').valueChanges.subscribe((res) => {
      if (res && this.createTicketForm.get('ticketNumberLength').value) {
        this.maxGeneratableTicketCount = this.ticketNumberGen(res, this.createTicketForm.get('ticketNumberLength').value);
        console.log("this.maxGeneratableTicketCount", this.maxGeneratableTicketCount);
        this.createTicketNumberFormate(res, this.createTicketForm.get('ticketNumberLength').value, this.createTicketForm.get('ticketNumberPrefix')?.value);
      }
    })

    this.createTicketForm.get('ticketNumberPrefix').valueChanges.subscribe((res) => {
      this.createTicketNumberFormate(this.createTicketForm.get('ticketSeatFormat')?.value, this.createTicketForm.get('ticketNumberLength').value, res);
    })

    this.createTicketForm.get('ticketNumberFrom').valueChanges.subscribe((res) => {
      if (res > 0 && this.createTicketForm.get('ticketNumberFrom').status == 'VALID')
        this.createTicketNumberFormate(this.createTicketForm.get('ticketSeatFormat')?.value, this.createTicketForm.get('ticketNumberLength').value, this.createTicketForm.get('ticketNumberPrefix').value, res);
      else
        this.createTicketNumberFormate(this.createTicketForm.get('ticketSeatFormat')?.value, this.createTicketForm.get('ticketNumberLength').value, this.createTicketForm.get('ticketNumberPrefix').value, '');
    })


  }



  createTicketNumberFormate(type, numlength, preFix?, startForm?) {

    console.log("preFix", type, numlength, preFix, startForm);

    if (type) {
      let formate: String;
      switch (Number(type)) {
        case 1:

          formate = preFix ? preFix + String('ABCDEFGHIJKLMNOPQRSTUVWXYZ').slice(0, numlength) : String('ABCDEFGHIJKLMNOPQRSTUVWXYZ').slice(0, numlength);
          this.ticketFormate = formate;
          break;

        case 2:

          formate = String('0000000000').slice(startForm ? startForm > 1 ? String(startForm).length - 1 : 0 : 0, numlength).concat(startForm ? startForm : '1');
          formate = preFix ? preFix + formate.slice(1, formate.length) : formate.slice(1, formate.length);
          this.ticketNumberStartFromMaxLength = numlength;
          this.ticketFormate = formate;
          break;

        case 3:
          if (!this.ticketFormate) {
            this.ticketFormate = this.genRandomTicketNumber(numlength)
          } else {
            this.ticketFormate = preFix + this.randomTicketNumber;
            this.createTicketForm.controls.ticketNumberLength.valueChanges.subscribe((res) => {
              this.randomTicketNumber = '';
              this.ticketFormate = preFix + this.genRandomTicketNumber(res);
            })
          }
          break;
      }
    }
  }

  genRandomTicketNumber(numlength) {
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < numlength; i++) {
      this.randomTicketNumber += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return this.randomTicketNumber
  }

  ticketNumberGen(type, res) {
    const n: any = type == 1 ? Number(26) : type == 2 ? Number(9) : type == 3 ? Number(36) : Number(0);
    const r = Number(res);
    if (n < r) {
      return false;
    }
    const nFactorial = this.factorial(n);
    const nMinusRFactorial = this.factorial(n - r);
    const result = nFactorial / (nMinusRFactorial);
    return result;
  }

  factorial(num) {
    if (num === 0 || num === 1) {
      return 1;
    } else {
      return num * this.factorial(num - 1);
    }
  }

  showCustomizeTicketCard() {
    // for btn
    this.customizeTicket = true;
    this._ToolbarService._ToolBarTitle.next('Customize Ticket');
    this._ToolbarService._isBackBtnShow.next(true);  
  }


  setToolBarTitle(edit) {
    if (edit) {
      // for title
      this._ToolbarService._ToolBarTitle.next('Edit A Ticket');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    } else {
      // for title
      this._ToolbarService._ToolBarTitle.next('Create A Ticket');
      // for btn
      this._ToolbarService._isBackBtnShow.next(true);
    }
  }

  

  // ON SUBMIT 
  onSubmit(formData) {
    console.log("form status", this.createTicketForm);
    // IF EDIT CREATED TICKETS
    if (this._createTicketService?.mode == 'editTicket') {

      if (!this._createTicketService?.ticketData?.isPublish) { 

        this.dialogRefAskForPublishEvent = this._matDialog.open(PublishEventConfirmationDialogComponent, {
          panelClass: ['commonDialogWrapper'],
          backdropClass: 'dialogBackdrop',
          data: {
            isEdit: true
          }
        });

        this.dialogRefAskForPublishEvent.afterClosed().subscribe((response: any) => {
          console.log("response", response);
          if (response == true) {
            // update with publish
            this._createTicketService.updateTicket(formData, this._createTicketService?.ticketId, this.ticketTypeStyleId, this.currencyId).then((data: any) => {
              if (data.status == 200) {
                this._createTicketService.publishEvent(this._createTicketService?.ticketData?.eventId, 1).then((result: any) => {
                  if (result.status == 200) {
                    this.createdTicketData = data.data;
                    this.showCustomizeTicketCard();
                  } else if(data.status == 400) {
                    this.showCustomizeTicketCard();
                  } else{
                    this._router.navigate(['/tickets'])
                  }
                })
              }
            });

          }

          else if (response == false) {
            // if just click on update ticket
            this._createTicketService.updateTicket(formData, this._createTicketService?.ticketId, this.ticketTypeStyleId, this.currencyId).then((data: any) => {
              if (data.status == 200) {
                console.log("after update data", data);
                this.createdTicketData = data.data;
                this.showCustomizeTicketCard();
              } else if(data.status == 400) {
                this.showCustomizeTicketCard();
              } else{
                this._router.navigate(['/tickets'])
              }
            })

          } 
        })
      }
      else {
        // if event is published in edit case
        this._createTicketService.updateTicket(formData, this._createTicketService?.ticketId, this.ticketTypeStyleId, this.currencyId).then((data: any) => {
          if (data.status == 200) { 
            this.createdTicketData = data.data;
            this.showCustomizeTicketCard();
          } else if(data.status == 400) {
            this.showCustomizeTicketCard();
          } else{
            this._router.navigate(['/tickets'])
          }
        })

      }

    } 
    else {
       

        // add case
        if (!this.selectedEvent.isPublish) {

          this.dialogRefAskForPublishEvent = this._matDialog.open(PublishEventConfirmationDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            data: {
              isEdit: false
            }
          })

          this.dialogRefAskForPublishEvent.afterClosed().subscribe((response: any) => {
            if (response == true) {
              // create with publish
              this._createTicketService.createTicket(formData, this.ticketTypeStyleId, this.currencyId, this._createTicketService.eventId).then((data: any) => {
                if (data.status == 200) {
                  this.createdTicketData = data.data.ticket;
                  this.ticketId = data?.ticket?.id;
                  this._createTicketService.publishEvent(this.selectedEvent.id, 1).then((result: any) => {
                    if (result.status == 200) {
                      this.ticketCreated = true;
                      this.isFormDisabled = true;
                    } else if(data.status == 400) {
                      this.ticketCreated = true;
                      this.isFormDisabled = true;
                    } else{
                      this.redirectOnPreRoute()
                    }
                  })
                }
              });
            }
            
            // just create ticket
            else if (response == false) {
              // if just click on create ticket
              this._createTicketService.createTicket(formData, this.ticketTypeStyleId, this.currencyId, this._createTicketService.eventId).then((data: any) => {
                if (data.status == 200) {
                  this.createdTicketData = data.data.ticket; 
                  this.ticketId = data?.ticket?.id;
                  this.ticketCreated = true;
                  this.isFormDisabled = true;
                } else if(data.status == 400) {
                  this.ticketCreated = true;
                  this.isFormDisabled = true;
                } else{
                  this.redirectOnPreRoute()
                }
              })
            }
            
          })
        } 
        else {

          // if event is published in edit case
          this._createTicketService.createTicket(formData, this.ticketTypeStyleId, this.currencyId, this.selectedEvent.id).then((data: any) => {
            if (data.status == 200) {
              console.log("data", data);
              this.createdTicketData = data.data.ticket;
              this.ticketCreated = true;
              this.isFormDisabled = true;
            } else if(data.status == 400) {
              this.ticketCreated = true;
              this.isFormDisabled = true;
            } else{
              this.redirectOnPreRoute()
            }
          })
          
        } 

    }
  }

  updateTicketLayout() {
    this._fuseProgressBarService.show();
    // console.log("this._createTicketService.templateData['ticketId']", this._createTicketService.templateData['ticketId']);
    // console.log("this._createTicketService.ticketId", this._createTicketService.ticketId);
    // console.log("this.ticketId;",this.ticketId);
    // this._createTicketService.templateData['ticketId'] = this._createTicketService.ticketId || this.createdTicketData.id;
    this._createTicketService.updateTicketLayout(this._createTicketService.templateData, this._createTicketService.ticketId || this.createdTicketData.id).then((res: any) => {
      if (res.status == 200) {
        this._fuseProgressBarService.hide();
        this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 });
        if (this._createTicketService?.mode == 'editTicket') {
          this._router.navigate(['/tickets']);
        } else {
          this.redirectOnPreRoute();
        }
      }
    })
  }

  onPrint(){
    console.log("layout", this._createTicketService.templateData);
    console.log("ticket id", this._createTicketService.ticketId);
    console.log("Print", this.createdTicketData);
    console.log("id", this.ticketId);
  }

  redirectOnPreRoute(){
    console.log("redirectOnPreRoute called", this._createTicketService.mode);
    console.log("this._createTicketService.preRoute", this._createTicketService.preRoute);
    if(this._createTicketService.mode == 'createTicketFormEvent'){
      if(this._createTicketService.preRoute == 'addevent'){
        this._router.navigate(['/events/edit/' + this._createTicketService.eventId], {
          queryParams: {
              step: 5
          }
        })
      }else if(this._createTicketService.preRoute == 'view'){
        this._router.navigate(['/events/view/' +  this._createTicketService.eventId + '/info']);
      }
    }
    else if(this._createTicketService.mode == 'createTicket'){
      this._router.navigate(['/tickets']);
    }
    else{
      this._router.navigate(['/tickets']);
    }
  }

  //making clone of older selected ticket
  // openChooseTicketDialog() {
  //   this._createTicketService.getAllEventTitle().then((result: any) => {
  //     if (result.status == 200) {
  //       this.dialogRef = this._matDialog.open(ChooseTicketComponent, {
  //         width: '400px',
  //         data: {
  //           tickets: result.data
  //         }
  //       });
  //       this.dialogRef.afterClosed()
  //         .subscribe((response) => {
  //           if (response) {
  //             console.log("--result choose template-->", response);
  //             let layout = JSON.parse(response.ticketData.layout)
  //             this._createTicketService.applyTicketFormat(layout)
  //           } else
  //             return;
  //         });
  //     }
  //   })
  // }

  createForm(data?) {
    console.log("this is ticket data -->", data);
    this.createTicketForm = this.fb.group({
      selectedEvent: [data ? data?.eventId.toString() : '', Validators.required],
      ticketTitle: [data ?  data.title : '', Validators.required],
      label_code_type : [data ? data.labelCode : ''],
      ticketType: [data ?  data.ticketTypeId : '', Validators.required],
      totalTickets: [{value :data ? data.totalCount : '', disabled : this._createTicketService.mode == 'editTicket'? true : false}, [Validators.required, Validators.min(1), Validators.max(this.totalLeftSeat)]],
      price: [data ? data.price : '', [Validators.required,Validators.min(0)]],
      maxMember: [data ?  data.memberPerTicket : '1', Validators.required], 
      seatNumberGenrateType: [data ? String(data.codeFormatType) : '', Validators.required],
      ticketNumberFrom: [data ? String(data?.ticketStartNum) : '', Validators.required],
      ticketSeatFormat: [data ? String(data?.ticketCodeType) : ''],
      ticketNumberLength: [data ? String(data?.ticketCodeLength) : ''],
      ticketNumberPrefix: [data ? String(data.ticketPreText) : '', Validators.required]
    });
    console.log("create from totaltickets", this.createTicketForm.get('totalTickets'));
    if (data) {
      this.isFormDisabled = true;
      console.log("Data is here", data.layout);
      this._createTicketService.templateData = JSON.parse(JSON.stringify((data.layout)))
    } else {
      this.isFormDisabled = false;
    }

  }

  ticketGuidelines() {
    this.dialogRef = this._matDialog.open(AddTicketGuidelinesComponent, {
    })
  }

  /* CLICK ON EDIT */
  enableForm() {
    this.isFormDisabled = false
    this.createTicketForm.get('ticketTitle').enable();
    this.createTicketForm.get('ticketType').enable();
    // this.createTicketForm.get('totalTickets').enable()
    // this.createTicketForm.get('totalTickets').disable();
    this.createTicketForm.get('price').enable();
    this.createTicketForm.get('ticketNumberPrefix').enable();
    // this.createTicketForm.get('ticketNumberFrom').enable();
    this.createTicketForm.get('maxMember').enable();
    // this.createTicketForm.get('guestLabel').enable();
    // this.createTicketForm.get('orgLabel').enable();
    this.createTicketForm?.get('appBgColor')?.enable();
  }

  // editTicket() {
  //   this._createTicketService.updateTicket(this.createTicketForm.value, this._createTicketService.ticketData.id, this.ticketTypeStyleId, this.currencyId)
  // }

  
  /* FILLING ALL VALUE AND MAKING LAYOUT INTO EVENT SELECATION */
  onEventSelect(value) { 
    this._fuseProgressBarService.show();
    this._AddEditEventService.getEvent(value).then((res: any) => {
      if (res.status == 200) {
        // console.log("this.allTicketType", this.allTicketType);
        if(this.allTicketType?.length){
          this.allTicketType.forEach((type:any)=>{
            type['isDisable'] = 0;
          })
          this.allTicketType.forEach((type:any) => {
            res.data.ticketType.forEach((item) => {
              if(type.id == item.ticketType)
                type['isDisable'] = 1;
              // else 
              //   type['isDisable'] = 0;
            })
          })
        }
        // this.allTicketType = this._createTicketService?.allTicketTypes?.data?.ticketTypes.filter(type => res.data.ticketType.forEach(item => type.id === item.ticketType))
      
        
        this._fuseProgressBarService.hide();
        this.showDetails = true;
        this.editEvent = res.data;
        this.selectedEvent = res.data;
        this.eventDetails = res.data;
        let eventCate = [];
        this.eventDetails.categoriesList.forEach((item) => eventCate.push(item.title));
        this.categoriesList = eventCate.join(', ');
        console.log("this._createTicketService?.ticketId", this._createTicketService?.ticketId);
        if (!this._createTicketService?.ticketId) {
          this.afterGetDetails(res.data);
        } else {
          if (res.data.guests?.length) {
            let guestName = res.data.guests.map(item => item.name + ' ' + '(' + item.position + ')');
            let guestPosition = this._createTicketService.templateData.guest.map(item => item);
            let guestPositionTop = guestPosition[0].position.top;
            let guestPositionleft = guestPosition[0].position.left;
            let guestFontFamily = guestPosition[0].fontFamily;
            let guesFontColor = guestPosition[0].fontColor;
            let guestfontSize = guestPosition[0].fontSize;
            let guestDisplay = guestPosition[0].display;
            let guestBole = guestPosition[0].bold;
            let guestOpacity = guestPosition[0].opacity;
            let guestRotate = guestPosition[0].rotate;
            let guestUnderLine = guestPosition[0].underline;
            let guestWidth = guestPosition[0].width;
            let guestItalic = guestPosition[0].italic;
          }

          let isFreeEdit = res.data.isFree;
          console.log("isFreeEdit", isFreeEdit);
          
          let noSeatEventEdit = res.data.noSeatEvent;
          console.log("noSeatEventEdit", noSeatEventEdit);
          

          // /* PAID WITH NOSEAT */
          if (!isFreeEdit && noSeatEventEdit) {
            this.createTicketForm.get('ticketNumberPrefix').setValue('');
            this.createTicketForm.get('ticketNumberPrefix').setValidators(null);
            this.createTicketForm.get('ticketNumberPrefix').setErrors(null);

            this.createTicketForm.get('ticketNumberFrom').setValue('');  
            this.createTicketForm.get('ticketNumberFrom').setValidators(null);
            this.createTicketForm.get('ticketNumberFrom').setErrors(null);

            console.log("Your Selected Event is Paid And No Seat event");
            this._createTicketService.templateData.ticketNum.display = false;
            this._createTicketService.templateData.ticketNumber.display = false;
            this.eventEnums = 1;
            console.log("this is form valid", this.createTicketForm);
            // this.createTicketNumberFormate()
          }
          /* free With Noseat */
          if (isFreeEdit && noSeatEventEdit) {
            this.createTicketForm.get('price').setValue('');
            this.createTicketForm.get('price').setValidators(null);
            console.log("Your Selected Event is free And No Seat event");
            this.createTicketForm.get('ticketNumberPrefix').setValue('');
            this.createTicketForm.get('ticketNumberPrefix').setErrors(null);
            this.createTicketForm.get('ticketNumberPrefix').setValidators(null);
            // this.createTicketForm.get('ticketNumberFrom').setValue('');
            // this.createTicketForm.get('ticketNumberFrom').setValidators(null);
            this._createTicketService.templateData.ticketNum.display = false;
            this._createTicketService.templateData.ticketNumber.display = false;
            this.eventEnums = 2;
          }
          /* free With Seat */
          if (isFreeEdit && !noSeatEventEdit) {
            this.createTicketForm.get('price').setValue('');
            this.createTicketForm.get('price').setValidators(null);
            this._createTicketService.templateData.ticketNum.display = true;
            this._createTicketService.templateData.ticketNumber.display = true;
            console.log("Your Selected Event is free And Seat event");
            this.eventEnums = 3;
          }
          /* PAID WITH SEAT */
          if (!isFreeEdit && !noSeatEventEdit) {
            this.createTicketForm.get('ticketNumberPrefix').setValidators([Validators.required]);
            // this.createTicketForm.get('ticketNumberFrom').setValidators([Validators.required]);
            this._createTicketService.templateData.ticketNum.display = true;
            this._createTicketService.templateData.ticketNumber.display = true;
            this.createTicketForm.get('price').setValidators([Validators.required]);
            console.log("Your Selected Event is Paid And Seat event");
            this.eventEnums = 4;
            this.createTicketNumberFormate(this.createTicketForm.get('ticketSeatFormat').value, this.createTicketForm.get('ticketNumberLength').value, this.createTicketForm.get('ticketNumberPrefix').value, this.createTicketForm.get('ticketNumberFrom').value != 0 ? this.createTicketForm.get('ticketNumberFrom').value : '')
            console.log("this is form value", this.createTicketForm);
            console.log("this.ticketFormate", this.ticketFormate);
          }

        }
      } else {
        this._matSnackBar.open(res.message, 'OKAY', { duration: 2000 })
      }
    });
  }

  afterGetDetails(selectedEvent) {
    console.log("called afterGetDetails"); 
    this.totalLeftSeat = selectedEvent?.totalLeftSeats;
    this.createTicketForm.get('ticketTitle').setValue('');
    this.createTicketForm.get('ticketType').setValue('');
    this.createTicketForm.get('totalTickets').setValidators([Validators.required, Validators.max(this.totalLeftSeat)]);
    this.createTicketForm.get('totalTickets').setValue(this.totalLeftSeat)
    this.createTicketForm.get('price').setValue(null);
    this.createTicketForm.get('ticketNumberPrefix').setValue('');
    this.createTicketForm.get('ticketNumberPrefix').setValidators(null);
    this.createTicketForm.get('ticketNumberPrefix').setErrors(null);
    // this.createTicketForm.get('ticketNumberFrom').setValue('0');
    this.createTicketForm.get('maxMember').setValue('');
    // this.createTicketForm.get('guestLabel').setValue('');
    // this.createTicketForm.get('orgLabel').setValue('');
    this.createTicketForm.updateValueAndValidity();


    this.isFree = selectedEvent?.isFree;
    this.noSeatEvent = selectedEvent?.noSeatEvent;

    console.log("with price in add case", this.isFree);
    console.log("seat is not org in add case", this.noSeatEvent);
    

    /* PAID WITH NOSEAT */
    if (!this.isFree && this.noSeatEvent) {
      this.createTicketForm.get('ticketNumberPrefix')?.setValidators(null);
      this.createTicketForm.get('ticketNumberPrefix')?.setErrors(null);
      this.createTicketForm.get('ticketNumberPrefix')?.setValue('');
      this._createTicketService.templateData.ticketNum.display = false;

      this.createTicketForm.get('ticketNumberFrom')?.setValidators(null);
      this.createTicketForm.get('ticketNumberFrom')?.setValue('0');
      this._createTicketService.templateData.ticketNumber.display = false;


      console.log("Your Selected Event is Paid And No Seat event");
      this.eventEnums = 1;

      this.createTicketForm.get('seatNumberGenrateType')?.setValue('');
      this.createTicketForm.get('seatNumberGenrateType')?.setValidators(null);
      this.createTicketForm.get('seatNumberGenrateType')?.setErrors(null);
      this.createTicketForm.get('ticketSeatFormat').setValue('');
      this.createTicketForm.get('ticketSeatFormat').setValidators(null);
      this.createTicketForm.get('ticketSeatFormat').setErrors(null);
      this.createTicketForm.get('ticketNumberLength').setValue('');
      this.createTicketForm.get('ticketNumberLength').setValidators(null);
      
      this.createTicketForm.get('ticketNumberLength').setErrors(null);
      this.createTicketForm.get('ticketNumberPrefix').setValidators(null);
      this.createTicketForm.get('ticketNumberPrefix').setErrors(null);
      this.createTicketForm.get('ticketNumberPrefix').setValue('');

      console.log("createTicketForm --> ", this.createTicketForm);

    }

    /* free With Noseat */
    if (this.isFree && this.noSeatEvent) {
      this.createTicketForm.get('price').setValidators(null);
      this.createTicketForm.get('price').setValue('');

      this.createTicketForm.get('ticketNumberPrefix').setValue('');
      this.createTicketForm.get('ticketNumberPrefix').setValidators(null);
      this.createTicketForm.get('ticketNumberPrefix').setErrors(null);
      this._createTicketService.templateData.ticketNum.display = false;

      this.createTicketForm.get('ticketNumberFrom').setValidators(null);
      this.createTicketForm.get('ticketNumberFrom').setValue('');
      this._createTicketService.templateData.ticketNumber.display = false;

      this.eventEnums = 2;

      this.createTicketForm.get('seatNumberGenrateType')?.setValue('');
      this.createTicketForm.get('seatNumberGenrateType')?.setValidators(null);
      this.createTicketForm.get('seatNumberGenrateType')?.setErrors(null);
      this.createTicketForm.get('ticketSeatFormat').setValue('');
      this.createTicketForm.get('ticketSeatFormat').setValidators(null);
      this.createTicketForm.get('ticketSeatFormat').setErrors(null);
      this.createTicketForm.get('ticketNumberLength').setValue('');
      this.createTicketForm.get('ticketNumberLength').setValidators(null);
      this.createTicketForm.get('ticketNumberLength').setErrors(null);
      this.createTicketForm.get('ticketNumberPrefix').setValue('')
    }

    /* free With Seat */
    if (this.isFree && !this.noSeatEvent) {
      this.createTicketForm.get('price').setValidators(null);
      this.createTicketForm.get('price').setValue('');
      this.createTicketForm.get('ticketNumberPrefix').setValidators([Validators.required]);
      // this.createTicketForm.get('ticketNumberFrom').setValidators([Validators.required]);
      this.createTicketForm.get('ticketNumberPrefix').setValue('');
      // this.createTicketForm.get('ticketNumberFrom').setValue('0');
      this._createTicketService.templateData.ticketNum.display = true;
      this._createTicketService.templateData.ticketNumber.display = true;
      console.log("Your Selected Event is free And Seat event");
      console.log("this._createTicketService.templateData.", this._createTicketService.templateData.price);

      this.eventEnums = 3;
    }

    /* PAID WITH SEAT */
    if (!this.isFree && !this.noSeatEvent) {
      this.createTicketForm.get('ticketNumberPrefix').setValidators([Validators.required]);
      this.createTicketForm.get('ticketNumberPrefix').setValue('');
      this._createTicketService.templateData.ticketNum.display = true;

      // this.createTicketForm.get('ticketNumberFrom').setValidators([Validators.required]);
      // this.createTicketForm.get('ticketNumberFrom').setValue('0');
      this._createTicketService.templateData.ticketNumber.display = true;

      this.createTicketForm.get('price').setValidators([Validators.required]);
      this.createTicketForm.get('price').setValue('');
      this.eventEnums = 4;
    }
    this.eventStartDateConverted = this.formatAMPM(selectedEvent?.eventStartDate);
    this.eventEndDateConverted = this.formatAMPM(selectedEvent?.eventEndDate);

    this.setTicketDetails(selectedEvent)
  }

  formatAMPM(date) {
    let convretedDate = new Date(date);
    let hours = convretedDate.getHours();
    let minutes: any = convretedDate.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let day = convretedDate.getDate();
    let months = convretedDate.getMonth();
    let year = convretedDate.getFullYear();
    let strTime = months + '/' + day + '/' + year + ', ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  updateTicketLimit(){
    const dialog = this._matDialog.open(ExtendTicketLimitDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      autoFocus : false,
      width : '380px',
      data : {
        ticketData : this._createTicketService.ticketData
      }
    })
    
    dialog.afterClosed().subscribe((res) => {
      if(res){
        this._createTicketService.getTicketById(this._createTicketService.ticketId).then((result: any) => {
          if (result.status == 200) {
            this.createTicketForm.get('totalTickets').setValue(result.data.totalCount);
          }
        })
      }
    })
    
  }

  //store in ticket template temporarly
  setTicketDetails(selectedEvent) {
    this.ticketTypeStyleId = '';
    this._createTicketService.templateData.guest = [];

    const sdate = new Date(selectedEvent?.eventStartDate)
    const ldate = new Date(selectedEvent?.eventEndDate)

    if (selectedEvent?.name) {
      this._createTicketService.templateData.eventName.text = selectedEvent?.name;
    }

    const isMultiple = selectedEvent?.isMultiple;

    this.eveStartDate = this._createTicketService.formatDate(sdate);
    this.eveEndDate = this._createTicketService.formatDate(ldate);

    /* date and time saparate */
    this.eveStartDate = this.eveStartDate.split(' @ ');
    this.eveEndDate = this.eveEndDate.split(' @ ');
    /* condition for multipleslot */
    if (isMultiple) {
      //if event is multiple slot event & date is not equal.
      if (this.eveStartDate[0] != this.eveEndDate[0]) {
        this._createTicketService.templateData.commanDate.text = this.eveStartDate[0] + ' @ ' + this.eveStartDate[1] + ' To ' + this.eveEndDate[1];
        this._createTicketService.templateData.eveStartDate.text = '';
        this._createTicketService.templateData.eveEndDate.text = '';
      }
      else {
        //if event is multiple slot event & date is equal.
        this._createTicketService.templateData.commanDate.text = this.eveStartDate[0] + ' @ ' + this.eveStartDate[1] + ' To ' + this.eveEndDate[1];
        this._createTicketService.templateData.eveStartDate.text = '';
        this._createTicketService.templateData.eveEndDate.text = '';
      }
    }//condition for SingleSlot
    else {
      //if evnet is single slot event & date is equal. 
      if (this.eveStartDate[0] == this.eveEndDate[0]) {
        this._createTicketService.templateData.commanDate.text = this.eveStartDate[0] + ' @ ' + this.eveStartDate[1] + ' To ' + this.eveEndDate[1];
        this._createTicketService.templateData.eveStartDate.text = '';
        this._createTicketService.templateData.eveEndDate.text = '';
      }
      //if event is single slot event & date is not equal
      else {
        this._createTicketService.templateData.commanDate.text = '';
        this._createTicketService.templateData.eveStartDate.text = this._createTicketService.formatDate(sdate)
        this._createTicketService.templateData.eveEndDate.text = this._createTicketService.formatDate(ldate)
      }
    }





    // SET GUEST NAME WITH LABEL
    if (!this.templateApply) {
      /* WITH ONE DYNAMICELEMENT */
      this.selectedEvent = selectedEvent;
      // let guestName =  selectedEvent?.guests.map(item => item.name + ' ' + '(' + item.position + ')');

      // this.createTicketForm.get('guestLabel').valueChanges.subscribe((val) => {  
      //   this._createTicketService.templateData.guest = [];
      //   this.guestLabel = val;
      //   let newGuest:any = JSON.parse(JSON.stringify(this._createTicketService.newDynamicElement))
      //   newGuest.text = selectedEvent?.guests.length == 1 ? this.guestLabel + " " + guestName: this.guestLabel + " " + guestName?.join(", ", ' ');
      //   newGuest.position.top = 60;
      //   newGuest.position.left = 0;
      //   newGuest.fontFamily = 'cursive';
      //   newGuest.fontColor = '#7ed6f8';
      //   this._createTicketService.templateData.guest.push(newGuest);
      // }) 
      //   let newGuest:any = JSON.parse(JSON.stringify(this._createTicketService.newDynamicElement))
      //     newGuest.text = selectedEvent?.guests.length == 1 ? guestName : guestName?.join(", ");
      //     newGuest.position.top = 60;
      //     newGuest.position.left = 0;
      //     newGuest.fontFamily = 'cursive';
      //     newGuest.fontColor = '#7ed6f8';
      //     this._createTicketService.templateData.guest.push(newGuest);
      // }

      if (selectedEvent?.eventOrganiser)
        this._httpClient.post(environment.apiURL + 'admin/event/getbyid/organiser', { organiserId: selectedEvent.eventOrganiser }, this._commonService.getHeader()).subscribe((result: any) => {
          if (result.status == 200) {
            this._createTicketService.templateData.orgName.text = result.data.name;
            this._createTicketService.templateData.orgWebsite.text = result.data.webSite;
            this._createTicketService.templateData.helpLine.text = result.data.mobile;
          }
        })
      if (selectedEvent?.venueId)
        this._httpClient.post(environment.apiURL + 'admin/event/getbyid/venue', { venueId: selectedEvent.venueId }, this._commonService.getHeader()).subscribe((result: any) => {
          if (result.status == 200)
            this._createTicketService.templateData.venue.text = result.data.fullAddress
        })
    }
  }

  /* APPLING PREBUILD TEMPLATE SET GUEST NAME'S LABEL AND STYLE */
  parentFunction(event) {
    this.preBuildTemplate = event;
    this.templateApply = true;
    console.log("your selected event", this.selectedEvent);
    if (this.preBuildTemplate && this.selectedEvent) {
      let guestName = this.selectedEvent.guests.map(item => item.name + ' ' + '(' + item.position + ')');
      console.log("APPLING PREBUILD TEMPLATE guestName ", guestName);
      let guestPosition = this.preBuildTemplate.guest.map(item => item);
      let guestPositionTop = guestPosition[0].position.top;
      let guestPositionleft = guestPosition[0].position.left;
      let guestFontFamily = guestPosition[0].fontFamily;
      let guesFontColor = guestPosition[0].fontColor;
      let guestfontSize = guestPosition[0].fontSize;
      let guestDisplay = guestPosition[0].display;
      let guestBole = guestPosition[0].bold;
      let guestOpacity = guestPosition[0].opacity;
      let guestRotate = guestPosition[0].rotate;
      let guestUnderLine = guestPosition[0].underline;
      let guestWidth = guestPosition[0].width;
      let guestItalic = guestPosition[0].italic;

    }

    /* IN TICKET EDIT CASE */
    if (!this.selectedEvent && this.preBuildTemplate) {
      let guestName = this.editEvent[0].guests.map(item => item.name + ' ' + '(' + item.position + ')');
      let guestPosition = this.preBuildTemplate.guest.map(item => item);
      let guestPositionTop = guestPosition[0].position.top;
      let guestPositionleft = guestPosition[0].position.left;
      let guestFontFamily = guestPosition[0].fontFamily;
      let guesFontColor = guestPosition[0].fontColor;
      let guestfontSize = guestPosition[0].fontSize;
      let guestDisplay = guestPosition[0].display;
      let guestBole = guestPosition[0].bold;
      let guestOpacity = guestPosition[0].opacity;
      let guestRotate = guestPosition[0].rotate;
      let guestUnderLine = guestPosition[0].underline;
      let guestWidth = guestPosition[0].width;
      let guestItalic = guestPosition[0].italic;
    }
  }

  onTicketTypeChange(event) {
    this.selectedEventType = this._createTicketService.allTicketTypes.data.ticketTypes.filter((type) => {
      return type.id == event.value
    })
    this._createTicketService.addSelectedItem;
    this._createTicketService.addSelectedItem.subscribe((result: any) => {
      this.createTicketForm.get('ticketType').setValue(result)
      this.addSelectedItem = result;
    });
    this._createTicketService.templateData.ticketType.text = this.selectedEventType[0].title
  }
  
  // Destroy
  ngOnDestroy(): void {
    console.log("TICKET COMPONENT DESTROYED");
    // this._createTicketService.mode = '';
    // this._createTicketService.eventId = '';
    // this._createTicketService.ticketId = '';
  }
  
}
