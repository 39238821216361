import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './main/pages/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {


  constructor(
    private router: Router,
    private _matSnackBar: MatSnackBar,
    private _authService: AuthService,
    private _router: Router,
    private _matDialog: MatDialog
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | any {
    
    const userToken = localStorage.getItem('userToken')

    if (!userToken) {
      this.router.navigate(['/auth/login'])
      return true
    } else {

      return new Promise(async (resolve, reject) => {
        if (!this._authService.userPermissions) {
          await this._authService.getPermissionsOnRefresh()
        }
        // console.log("route.url[0].path",route.url[0].path);


        if (route.url[0].path == 'masters')
          resolve(true)
        else {
          //check view permissions
          const permissions = this._authService.getPermissions()

          if (!permissions) {
            if (this._router.url == '/auth/login') {
              resolve(true)
            }
          }
          let index = permissions.findIndex((item) => {
            return item.url == '/' + route.url[0].path
          })

          if (index >= 0 && permissions[index].permission.view) {
            resolve(true);
          }
          else {
            this._matSnackBar.open('You are not permitted to use this page', 'OK', {
              duration: 4000
            });
            resolve(this._authService.noPermissionMessage());
          }
        }


      })


    }
  }
}


