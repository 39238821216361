import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HostTypesService implements Resolve<any>  {
  hostTypesList;
  maxAvailableRecordsInTable;
  constructor(
    private _httpClient: HttpClient,
    private _commanService: CommonService,
    private _fuseProgressBarService: FuseProgressBarService,
  ) { }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllHostTypeList()
      ]).then(()=>{
        resolve('')
      }, reject)
    })
  }

  getAllHostTypeList(body?){
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/getAllHost', body ? body : {'pageSize':10,'pageIndex':1}, this._commanService.getHeader()).subscribe((response: any) => {
        this._fuseProgressBarService.hide()
        if(response.status == 200){
          this.hostTypesList = response.data.getAllHost;
          this.maxAvailableRecordsInTable = response.data.totalRecords;
        }else{
          this.hostTypesList = []
          this.maxAvailableRecordsInTable = 0;
        }
        resolve(response);
      }, reject)
    })
  }


}
