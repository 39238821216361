import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { CommonService } from '@fuse/services/common.service';
import { CertificateToolService } from '../certificate-tool.service';
import { CertificateUpdateService } from '../certificate-update.service';
import { SocialSharePostService } from 'app/main/events/view-event/social-share-post/social-share-post.service';
import { AddEditEventService } from 'app/main/events/edit-event/add-edit-event.service';
import { CreateBadgeDesignService } from 'app/main/badge-design/create-badge-design/create-badge-design.service';

@Component({
  selector: 'app-right-side-nav',
  templateUrl: './right-side-nav.component.html',
  styleUrls: ['./right-side-nav.component.scss']
})
export class RightSideNavComponent implements OnInit {
  @Output() dropClose = new EventEmitter();
  dynamicFieldList:[] = [];
  @Input('isForName') isForName: string;

  constructor(
    private el:ElementRef, 
    private _CommonService : CommonService,
    private _SocialSharePostService : SocialSharePostService,
    private _CertificateToolService:  CertificateToolService,
    private _CreateBadgeDesignService : CreateBadgeDesignService,
    private _CertificateUpdateService : CertificateUpdateService,
    private  _AddEditEventService : AddEditEventService,
    private renderer:Renderer2) {

     

      this._CertificateToolService.dynamicFieldList.subscribe((res)=>{
        if(res?.length){

          this.dynamicFieldList = res;

          // for selected field in update case.
          console.log("=============>", this._CertificateUpdateService.templateDetails);
          console.log("dynamicFieldList==========>", this.dynamicFieldList);
          
          if(this._CertificateUpdateService.templateDetails){
            if(this._CertificateUpdateService.templateDetails?.layout?.dynamicElements?.length)
            this._CertificateUpdateService.templateDetails.layout.dynamicElements.forEach(element => {
              this.dynamicFieldList.forEach((ele:any) => {
                if(element.text == ele.title){
                  ele.isSelected = 1;
                }
              })
            });
          } 

        }else{
          this.dynamicFieldList = [];
        }
      })

     }

  ngOnInit(): void {
    console.log("isFor", this.isForName);
    this.getdynamicFields()

   
  }


  getdynamicFields(){
    this._CertificateToolService.dynamicFieldList;
    const tempArr:any = this._AddEditEventService.dynamicFields;
    tempArr.map((item:any)=> item['isSelected'] = 0);
    console.log("tempArr", tempArr);
    this._CertificateToolService.dynamicFieldList.next(tempArr); 
    
  }

  onSelectField(field){
    console.log("field", field);
    console.log("this.isForName", this.isForName);
    
    field.isSelected = field.isSelected == 0 ? 1 : 0; 
    if(this.isForName == 'socialPost'){
      this._SocialSharePostService.selectedField.emit(field);
      this._SocialSharePostService.dynamicFieldList.next(this.dynamicFieldList)
    }
    else if(this.isForName  == 'badgeDesign'){
      this._CreateBadgeDesignService.selectedField.emit(field);
      this._CreateBadgeDesignService.dynamicFieldList.next(this.dynamicFieldList)
    }
    else{
      this._CertificateToolService.selectedField.emit(field);
      this._CertificateToolService.dynamicFieldList.next(this.dynamicFieldList)
    }
    console.log("this.dynamicFieldList", this.dynamicFieldList);
    
  }


  close(){
    this.dropClose.emit();
  }

}
