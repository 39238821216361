import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
import { DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import { fuseAnimations } from '@fuse/animations';
import { FuseUtils } from '@fuse/utils';
import { takeUntil } from 'rxjs/internal/operators';
import { DistributorsListService } from './distributors-list.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { Enums } from '@fuse/utils/systemEnums';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { Router } from '@angular/router';
import { CommonService } from '@fuse/services/common.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector: 'customers-list',
    templateUrl: './distributors-list.component.html',
    styleUrls: ['./distributors-list.component.scss'],
    animations: fuseAnimations,
    // encapsulation: ViewEncapsulation.None
})
export class CustomersListComponent implements OnInit {

    enum = Enums;
    tableData = [];
    maxAvailableRecordsInTable;
    dataSource;
    displayedColumns = ['proImage', 'name', 'businessName', 'mobile', 'totalEvents', 'totalEarning', 'due',/*'amountDue'*/'status'];
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild('search', { static: true }) search;
    disabled = false;
    // Private   
    private _unsubscribeAll: Subject<any>;

    constructor(
        public _distributorsListService: DistributorsListService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _matSnackBar: MatSnackBar,
        private _matDialog: MatDialog,
        private _ToolbarService : ToolbarService,
        private _router: Router,
        public commonService:CommonService

    ) {
        this.setToolBarTitle();
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this.maxAvailableRecordsInTable = _distributorsListService.maxAvailableRecordsInTable
    }


    ngOnInit(): void {
        this.tableData = this._distributorsListService.distributors;
        this.dataSource = new MatTableDataSource(this.tableData)
    }

    

    // onDelete(id) {
    //     this._matDialog.open(ConfirmationDialogComponent, {
    //         height: '130px',
    //     }).afterClosed().subscribe((row: any) => {
    //         if (row) {
    //             this._distributorsListService.deleteDistributor(id).then((response: any) => {
    //                 this._matSnackBar.open(response.message, 'OK', {
    //                     verticalPosition: 'bottom',
    //                     duration: 2000
    //                 });
    //                 if (response.status == 200) {
    //                     this.updateTable();
    //                     this._router.navigate(['/distributors'])
    //                 }
    //             }).catch(err => {
    //                 this._matSnackBar.open(err.message, 'OK', {
    //                     verticalPosition: 'bottom',
    //                     duration: 2000
    //                 });
    //             })
    //         }
    //     });
    // }

    // updateTable() {
    //     this._distributorsListService.getDistributorList().then(() => {
    //         this.tableData = this._distributorsListService.distributors;
    //         this.dataSource.data = this.tableData
    //     })
    // }

    // ----------------------- filters section -----------------------


    filter = {
        search: '',
        pageSize: 10,
        pageIndex: 1
    }

    onSearch(val) {
        
        this.search.nativeElement.focus()
        if (val) {
            this.filter.pageIndex = 1
            this.filter.search = val
            this.fetchDataInTable();
        }
    }

    setToolBarTitle(){
        // for title
        this._ToolbarService._ToolBarTitle.next('Distributors');
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }


    // searching(event){
    //     console.log("hello there",event.target.value)
    // }

    fetchDataInTable() {
        this._fuseProgressBarService.show()

        this._distributorsListService.getDistributorList(this.filter).then((result: any) => {
            if (result.status == 200) {
                this.dataSource.data = result.data.data
                console.log("to ");
                
                // element.sendTo = element.sendTo.split( ' ,');
                this.maxAvailableRecordsInTable = result.data.totalRecords
                this._fuseProgressBarService.hide()
            }
            else if(result.status == 400){
                this.dataSource.data = []
                this._fuseProgressBarService.hide()
                this.maxAvailableRecordsInTable = 0;
            }
            else {
                this.dataSource.data = []
                this._fuseProgressBarService.hide()
                this.maxAvailableRecordsInTable = 0;
            }
        })
    }

    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.fetchDataInTable()
    }

    clearSearch() {
        this.filter.search = '';
        this.search.nativeElement.focus();
        this.fetchDataInTable()
    }

}


