import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocialSharePostService {
  templateDetails;

  dynamicFieldList:BehaviorSubject<[]> = new BehaviorSubject([]);
  selectedField: EventEmitter<any> = new EventEmitter<any>();
  newDynamicElement = {
    contentEditable : false,
    isContentEditing: false,
    text: '',
    fieldName : '',
    fontSize: 20,
    width: '200',
    fontFamily: "Barlow",
    fontColor: '#000',
    display: true,
    bold: false,
    fontWeight : '500',
    underline: false,
    italic: false,
    align: 'center',
    textTransform: "capitalize",
    opacity: 1,
    rotate: 0,
    position: {
      top: 10,
      left: 20
    }
  }

  
  defaultTemplateFormat = {
    backgroundImage : '',
    dynamicElements : [],
    width : 868,
    height : 0,
    fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
    profile: {
      link: 'https://event-project-gts.s3.amazonaws.com/common/image/image_1714548997454.png',
      width: 100,
      display: true,
      opacity: 1,
      aspectRatio : '1/1',
      rotate: 0,
      position: {
        top: 31.99,
        left: 90.5
      }
    },
  }


  templateData = {
    backgroundImage : '',
    dynamicElements : [],
    width : 868,
    height : 0,
    fontFamilyCdn:'https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&family=Bilbo&family=Dancing+Script:wght@400;500;600;700&family=Fleur+De+Leah&family=Kaushan+Script&family=Playball&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Qwigley&family=Roboto:wght@100;300;400;500;700;900&family=Sail&family=Sansita+Swashed:wght@300;500;700;900&family=Six+Caps&family=Tangerine:wght@400;700&display=swap',
    profile: {
      link: 'https://event-project-gts.s3.amazonaws.com/common/image/image_1714548997454.png',
      width: 100,
      display: true,
      opacity: 1,
      aspectRatio : '1/1',
      rotate: 0,
      position: {
        top: 31.99,
        left: 90.5
      }
    },
  }

  backgrounds: [] = [];

  constructor() { 
    this.selectedField.subscribe((res)=>{
      if(res.isSelected){
        const tempField = JSON.parse(JSON.stringify(this.newDynamicElement))
        tempField.text = res.title;
        tempField.fieldName = `{{${res.title}}}`;

        if(this.templateData.dynamicElements.length != 0)
          tempField.position.top = (this.templateData.dynamicElements.length * 4);
        else
          tempField.position.top = 5
 
        if(tempField.text)
          this.templateData.dynamicElements.push(tempField);
 
      }else{
        const index = this.templateData.dynamicElements.findIndex((item) => item.fieldName == res.fieldName);
        this.templateData.dynamicElements.splice(index, 1);
      }
    })
  }

  emitData(data: any): void {
    this.selectedField.emit(data);
  }
  

}