import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '@fuse/services/common.service'; 
import { addRanking } from '@fuse/utils/systemEnums';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-add-edit-host-group',
  templateUrl: './add-edit-host-group.component.html',
  styleUrls: ['./add-edit-host-group.component.scss']
})
export class AddEditHostGroupComponent implements OnInit {
  form:FormGroup
  mode:string;
  selectedHost:any = [];
  addRanking = addRanking;

  selectedValues = []; 
  
  @ViewChild('search') searchTextBox: ElementRef;

  selectFormControl = new FormControl();
  filteredOptions: Observable<any[]>;
  searchTextboxControl = new FormControl();


  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<AddEditHostGroupComponent>,
    private _fb: UntypedFormBuilder,
    public _commonService: CommonService
  ) { 
    console.log("dialog data", this._data?.hosts);
    this.mode = this._data.mode;
  }
  

  ngOnInit(): void {

    this.filteredOptions = this.searchTextboxControl.valueChanges
    .pipe(
      startWith<string>(''),
      map(name => this._filter(name))
    );


    if(this.mode == 'add'){
      this.createForm()
    }else if(this.mode == 'view'){
      this.createForm(this._data.hostType)
      this.form.disable();
    }else{
      this.createForm(this._data.hostType)
    }
  }

  private _filter(name: string): String[] {
    const filterValue = name.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValues();
    this.selectFormControl.patchValue(this.selectedValues);
    let filteredList = this._data.hosts.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

  setSelectedValues() {
    console.log('selectFormControl', this.selectFormControl.value);
    if (this.selectFormControl.value && this.selectFormControl.value.length > 0) {
      this.selectFormControl.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }

  createForm(data?){
    const membersId = []
    if(data){
      this.selectedHost = data.members;
      data.members.forEach((item) => membersId.push(String(item.id)));
    }
    this.form = this._fb.group({
      id : [data ? data.id : ''],
      title : [data ? data.groupName : '', Validators.required],
      hosts : [data ? data.members.length ? membersId : '' : '', Validators.required]
    })
  }

  onSelectHost(event){
    console.log("event", event.value);    
    console.log("this._data.hosts", this._data.hosts);
    this.selectedHost = [];
        if(this.form.get('hosts').value)
            this._data.hosts.forEach(item =>{
              this.form.get('hosts').value.forEach((spId) => {
                  if(Number(item.id) === Number(spId)){
                    this.selectedHost.push(item)
                    console.log("item.id", item.id);
                  }
              })
          })
        this.addRanking(this.selectedHost)
        console.log("this.selectedSpeakers ===-->", this.selectedHost);
  }

  dropSponsor(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedHost, event.previousIndex, event.currentIndex);
    this.addRanking(this.selectedHost);
  }

  onSubmit(){
    this._matDialogRef.close({formData : this.form.value, selectedSponsors : this.selectedHost, mode : this.mode})
  }

  
  openedChange(e) {
    // Set search textbox value as empty while opening selectbox 
    this.searchTextboxControl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    if (e == true) {
      this.searchTextBox.nativeElement.focus();
    }
  }

  selectionChange(event){
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
    }
  }

  /**
   * Clearing search textbox value 
   */
  clearSearch(event) {
    event.stopPropagation();
    this.searchTextboxControl.patchValue('');
  }
  
}
