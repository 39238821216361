import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { RegisterComponent } from 'app/main/pages/authentication/register/register.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LyButtonModule } from '@alyle/ui/button';
import { LyDialogModule } from '@alyle/ui/dialog';
import { LyIconModule } from '@alyle/ui/icon';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { LySliderModule } from '@alyle/ui/slider';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { environment } from 'environments/environment';

@NgModule({
    declarations: [
        RegisterComponent
    ],
    imports: [
        LyImageCropperModule,
        RecaptchaModule,
        LySliderModule,
        LyButtonModule,
        LyIconModule,
        LyDialogModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FuseSharedModule,
        MatTooltipModule,
        MatRippleModule,
        FormsModule,
        RecaptchaFormsModule,
        MatSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NgxIntlTelInputModule,
        RouterModule, NgxFileDropModule,
    ],
    providers : [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
              siteKey: environment.recaptcha.siteKey,
            } as RecaptchaSettings,
        },
    ]
})
export class RegisterModule {
}
