import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SpeakerService } from '../speaker.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/main/pages/auth.service';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { CommonService } from '@fuse/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class HostGroupService {

  allHostList:[] = [];
  hostGroups:[] = [];
  maxAvailableRecordsInTable:number;
  constructor(
    private _speakerService : SpeakerService,
    private _fuseProgressBarService:FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _authService:AuthService,
    private _commonService: CommonService,
    private _httpClient:HttpClient,
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([ 
        this._speakerService.getSpeakersListData({"pageIndex":0,"pageSize":0}).then((res:any)=>{
          if(res.status == 200){
            this.allHostList = res.data.data;
          }else{
            this.allHostList = []
          }
        }),
        this.getAllHostGroup({pageSize:10, pageIndex:1, search:''})
      ]).then(()=>{
        resolve('')
      }, reject)
    })
  }


  getAllHostGroup(body){
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show();
      this._httpClient.post(environment.apiURL+`admin/getAllHostGroup`, body, this._commonService.getHeader()).subscribe((result: any) => {
          this._fuseProgressBarService.hide();
          if(result.status == 200){
            this.hostGroups = result.data.getAllHostGroup;
            this.maxAvailableRecordsInTable = result.data.totalRecords;
          }else{
            this.hostGroups = [];
            this.maxAvailableRecordsInTable = 0;
          }
          resolve(result)
        }, reject)
      })
  }

}
