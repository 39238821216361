import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../pages/auth.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { OrganiserListService } from '../masters/organiser/organiser-list/organiser-list.service';
import { environment } from 'environments/environment';
import { HostTypesService } from './host-types/host-types.service';

@Injectable({
  providedIn: 'root'
})
export class SpeakerService {
    
    permissions: any;
    speakersList: any;
    maxAvailableRecordsInTable: number;
    speakerId : Number;
    speakerDetail: any;
    allOrganisers: any;
    
    hostTypesList:[] = []
  constructor(
    private _commanService: CommonService,
    private _authService: AuthService,
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _organiserListService : OrganiserListService,
    private _matSnackBar: MatSnackBar,
    private _hostTypesService: HostTypesService
  ) {
   }


    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.permissions = this._authService.getPermissionsForCurrentPage(route);
        return new Promise((resolve, reject) => {
            if(route?.params?.id){
                Promise.all([
                    this.speakerId = Number(route.params.id),
                    this.getHostTypes(),
                    this.getSpeakerById({ "speakerId": route?.params?.id })
                ]).then(() => {
                    resolve('')
                },
                    reject
                )
            }else if(route?.routeConfig.path == 'add'){
                Promise.all([
                    this._fuseProgressBarService.show(),
                    this.getHostTypes(),
                    // this._organiserListService.getAllOrganisers({ pageIndex: '0', pageSize: '0' }).then((result: any) => {
                    //     this.allOrganisers = result?.data?.data;
                    //     this._fuseProgressBarService.hide()
                    // }) 
                ]).then(() => {
                    resolve('')
                },
                    reject
                )
                
            }else{
                Promise.all([
                    this._fuseProgressBarService.show(),
                    this.getSpeakersListData({"pageIndex":1,"pageSize":10})
                ]).then(() => {
                    resolve('')
                },
                    reject
                )
            }
        })
    }

    getHostTypes(){
        return new Promise((resolve, reject)=>{
            this._hostTypesService.getAllHostTypeList({pageSize : 0, pageIndex : 0}).then((res:any)=>{
                if(res.status == 200){
                    this.hostTypesList = res.data.getAllHost;
                }else{
                    this.hostTypesList = [];
                }
                resolve(res)
            }, reject)
        })
    }

    
    getSpeakersListData(body){
        return new Promise((resolve, reject)=>{
            this._httpClient.post(environment.apiURL + 'admin/getAllSpeaker',body, this._commanService.getHeader()).subscribe((result:any) => {
                if(result.status == 200){
                    this.speakersList = result.data.data; 
                    this.maxAvailableRecordsInTable = result.data.totalRecords;
                    resolve(result)
                }
                else if(result.status == 201){
                    this.speakersList = []; 
                    this.maxAvailableRecordsInTable = 0;
                    resolve(result)
                }
                else if(result.status == 400){
                    this.speakersList = []; 
                    this.maxAvailableRecordsInTable = 0;
                    resolve(result)
                }else{
                    this.speakersList = []; 
                    this.maxAvailableRecordsInTable = 0;
                    resolve(result)
                }
                this._fuseProgressBarService.hide();
            }, reject)
        })
    }

    
    onAddSpeaker(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/addSpeaker', body, this._commanService.getHeader()).subscribe((response: any) => {
            if(response.status == 200){
                this._fuseProgressBarService.hide()
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
                resolve(response);
            }else{
                this._fuseProgressBarService.hide();
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            }
            }, reject);
        });
    }


    getSpeakerById(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/getSpeakerById', body, this._commanService.getHeader()).subscribe((response: any) => {
            this._fuseProgressBarService.hide()
            if(response.status == 200){
                this.speakerDetail = response.data;
                resolve(response);
            }else{
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            }
            }, reject);
        });
    }

    updateSpeaker(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
        this._httpClient.post(environment.apiURL + 'admin/updateSpeaker', body, this._commanService.getHeader()).subscribe((response: any) => {
            this._fuseProgressBarService.hide()
            if(response.status == 200){
                resolve(response);
            }else{
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
                resolve(response);
            }
            }, reject);
        });
    }

    ondeleteFaqQuestion(body){
        this._fuseProgressBarService.show();
        return new Promise((resolve, reject) => {
            this._httpClient.post(environment.apiURL + 'admin/deleteSpeaker', body, this._commanService.getHeader()).subscribe((response:any) => {
                this._fuseProgressBarService.hide()
                if(response.status == 200){
                    resolve(response)
                }
                this._matSnackBar.open(response.message, 'OK', {duration: 2000,verticalPosition: "bottom"})
            }, reject);
        })
    }

}
