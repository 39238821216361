import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from 'app/main/pages/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '@fuse/services/common.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrganiserListService implements Resolve<any>{
allOrganisers
maxAvailableRecordsInTable
  permissions: any;
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    return new Promise((resolve, reject) => {

      Promise.all([
        this.getAllOrganisers()
      ]).then(
        () => {
          resolve('');
        },
        reject
      );
    })
  }

  constructor(
    private _httpClient:HttpClient,
    private _fuseProgressBarService:FuseProgressBarService,
    private _matSnackBar:MatSnackBar,
    private _authService:AuthService,
    private _commonService: CommonService,
  ) { }

  getAllOrganisers(filter?){
    const payload = {pageSize:10, pageIndex:1}
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/event/getall/organiser',filter?filter:payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          this._fuseProgressBarService.hide()
          if (result.status == 200) {
            this.maxAvailableRecordsInTable = result.data.totalRecords
            this.allOrganisers = result.data.data
            resolve(result)
          }
          else if(result.status == 400){
            this.maxAvailableRecordsInTable = 0;
            this.allOrganisers = [];
            resolve(result)
          }
          else{
            reject(result)
          }
          // this._fuseProgressBarService.hide()
        }, reject)
    })
  }

  deleteOrganisers(id){
    return new Promise((resolve, reject) => {
      this._fuseProgressBarService.show()
      this._httpClient.post(environment.apiURL + 'admin/event/delete/organiser',{organiserId:id}, this._commonService.getHeader())
      .subscribe((result: any) => {
        if(result.status==200){
          resolve('')
        }
        this._fuseProgressBarService.hide()
        this._matSnackBar.open(result.message, 'OK', {
          verticalPosition: 'bottom',
          duration: 2000
        });
      },reject)
  });
  }

 

}
