import { CommonService } from '@fuse/services/common.service';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Resolve, Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from 'app/main/pages/auth.service';
import { Console } from 'console';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AddVenueService implements Resolve<any> {
  venueId;
  venue;
  permissions: any;

  constructor(private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _router: Router,
    private _authService:AuthService,
    private _commonService:CommonService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    this.permissions = this._authService.getPermissionsForCurrentPage(route);
    this.venueId = route.queryParams.id
    return new Promise((resolve, reject) => {
     
      if (!!this.venueId) {
        this.getVenueById(this.venueId).then((result: any) => {
          console.log('result id', result);
          
          if (result.status == 200) {
            this.venue = result.data
            console.log(this.venue)
            resolve('')
          }
        }).catch((err) => {
    
            
          reject('')})
      } else {
        resolve('');
      }
    });
  }

  getVenueById(id) {
    this._fuseProgressBarService.show();
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/getbyid/venue', { "venueId": id }, this._commonService.getHeader()).subscribe((response: any) => {
          console.log('FROM GET VENUE BY ID 2');
          this._fuseProgressBarService.hide();
          if (response.status == 200) {
            resolve(response)
          } else {
            this._matSnackBar.open(response.message, 'OK', {
              verticalPosition: "bottom",
              duration: 2000
            })
          }

        })
    })

  }

  addVenue(data) {

    const payload = {
      "name": data.title,
      "fullAddress": data.completeAdd,
      "city": data.city,
      "state": data.state,
      "country": data.country,
      "zipCode": data.zipcode,
      "landMark": data.landMark,
      "lattitude": data.lat,
      "longitude": data.long
    }

    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/add/venue', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          console.log('response :', response);
          resolve(response);
        }, reject);
    });
  }

  updateVenue(data, id) {
    const payload = {
      "venueId": id,
      "name": data.title,
      "fullAddress": data.completeAdd,
      "city": data.city,
      "state": data.state,
      "country": data.country,
      "zipCode": data.zipcode,
      "landMark": data.landMark,
      "lattitude": data.lat,
      "longitude": data.long,
    }
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.put(environment.apiURL + 'admin/event/update/venue', payload, this._commonService.getHeader())
        .subscribe((result: any) => {
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: "bottom",
            duration: 4000
          })
          if (result.status == 200) {
            resolve(result.data)
          }
        }, (err) => {
          this._matSnackBar.open(err.message, 'OK', {
            verticalPosition: "bottom",
            duration: 4000
          })
          reject()
        })
    })
  }

  delete(id) {
    let dialog =  this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
        message : 'Are you sure you want to delete this venue?',
        image : '/assets/ui/delete.png',
        focus : 'right',
        deleteItem : 'Delete Venue?',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    
    dialog.afterClosed().subscribe((row: any) => {
      // return new Promise((resolve,reject)=>{ 
      if (row) {
        this._fuseProgressBarService.show();
        this._httpClient.post(environment.apiURL + 'admin/event/delete/venue', { 'venueId': id }, this._commonService.getHeader())
          .subscribe((result: any) => {
            this._fuseProgressBarService.show();
            if (result.status == 200) {
              this._router.navigate(['/masters', 'venue'])
              this._matSnackBar.open(result.message, 'OK', {
                verticalPosition: "bottom",
                duration: 2000
              })
            }

          }, err => {
            this._fuseProgressBarService.show();
            this._matSnackBar.open(err.message, 'OK', {
              verticalPosition: "bottom",
              duration: 4000
            })
          })

      }

    // });
    });
  }
}


