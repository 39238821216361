import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  public secretKey:string; // Replace with your secret key
  public postKey:string;
  public base64;
  public randomKey : string;

  constructor() { 
    
    // console.log("CryptoJS.AES", 
    // CryptoJS.AES.encrypt(JSON.stringify({"fileExt":".png","fileName":"background_","folderName":"ceritificate/layout/backgroundImage","mimeType":"image/png"}), 'abcdefghijklmopqrstuwwxyz', { iv: null }).toString());

    // console.log("DryptoJS.AES",  JSON.parse(CryptoJS.AES.decrypt('U2FsdGVkX19X3uVMfSeK+h73gD6ZITvhIsOBCGUzuGU6/+cwxVHIZG3XmbrnAgklkYY3JxndT5N6p6hBEECCM9htt5Hx2aoAisW22Lr6sLeilWRac5ir7NAPgIXNTboyPLz5Fh/TVi98l4FRpjR50ooz+vM0j45PFM8mQDb7wm46fGADfe60pYpOd7GBvuZf', 'abcdefghijklmopqrstuwwxyz', { iv: null }).toString(CryptoJS.enc.Utf8)));

  }
 

  public encryptData(data, key, iv){
    console.log("data", data);
    const encryptedData = CryptoJS.AES.encrypt(data, key, { iv: iv || null }).toString();
    return encryptedData;
  };
  // Function to decrypt data without IV
  public decryptData(encryptedData, key, iv?){
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, key, { iv: iv || null }).toString(CryptoJS.enc.Utf8);
      return decryptedData;
  }


  public getKey(data){
    let starting:string = '';
    let ending:string = '';
    if(data?.length >= 50){
      starting = data.slice(0, 50);
      ending = data.slice(-50);
    }
    return starting + ending;
  }

  public getData(data:string){
    let leftData:string;
    leftData = data.slice(50, -50);
    return leftData;
  }

  public genrateKey(){
    const token = localStorage.getItem('userToken');
    this.base64 = String(btoa(token));
    const key = this.base64.slice(0 , 50);
    return key;
  }


  public genrateRandomKeys(length = 100){
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let key = '';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * charactersLength);
        key += characters[randomIndex];
    }

    return key;
  }

}