import { Component, OnInit, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { CommonService } from '@fuse/services/common.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-event-list-dialog',
  templateUrl: './event-list-dialog.component.html',
  styleUrls: ['./event-list-dialog.component.scss']
})
export class EventListDialogComponent implements OnInit {
  dataSource
  displayedColumns: string[] = ['name', 'action'];

  constructor(
    private _httpClient: HttpClient,
    public matDialogRef: MatDialogRef<EventListDialogComponent>,
    private matSnackBar: MatSnackBar,
    private _commonService:CommonService,
    @Inject(MAT_DIALOG_DATA) private _data: any,
  ) {
    this.dataSource = new MatTableDataSource(this._data.events);
  }

  ngOnInit() {
    console.log(this._data.events)
  }

  onAssignEvent(eveId) {
    const payload = {
      eventId: eveId,
      verifierId: this._data.verifierId
    }
    this._httpClient.post(environment.apiURL + 'admin/event/assign/verifier', payload, this._commonService.getHeader()).subscribe((result: any) => {
      this.matSnackBar.open(result.message, 'OK', {
        verticalPosition: 'bottom',
        duration: 4000
      })
      if (result.status == 200) {
        this.matDialogRef.close(true);
      }
    })
  }

}
