import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { FuseProgressBarComponent } from '@fuse/components/progress-bar/progress-bar.component';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AuthService } from '../pages/auth.service';
import { CommonService } from '@fuse/services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesService implements Resolve<any>{
  allPermissions
  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _matSnackBar: MatSnackBar,
    private _authService: AuthService,
    private _commonService:CommonService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {

      Promise.all([
        this._fuseProgressBarService.show(),
        this.getAllPermission()
      ]).then(
        () => {
          resolve('');
          this._fuseProgressBarService.hide();
        },
        reject
      );
    });
  }

  getAllPermission() {
    return new Promise((resolve, reject) => {
      this._httpClient.get(environment.apiURL + 'admin/role/getall', this._commonService.getHeader())
        .subscribe((response: any) => {
          if (response.status == 200) {
            this.allPermissions = response.data.roles;
          } 
          else if(response.status == 201){
            this.allPermissions = [];
          }
          else {
            this.allPermissions = []
          }
          resolve(response);
        }, reject);
    });
  }
  getPermissionById(id) {
    // this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/role/getbyid', { roleId: id }, this._commonService.getHeader()).subscribe((response: any) => {
          // this._fuseProgressBarService.hide()
          resolve(response);
        }, reject);
    });
  }

  editPermissions(roleId, permission) {
    let payload = {
      roleId,
      permission
    }
    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/role/edit', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200) {
            resolve(response);
            if (response.data.role.id == 1)
              // this._authService.setPermissions(JSON.stringify(response.data.role.permission))
              this._authService.setPermissions(response.data.role.permission)
          }
          else
            reject(response)

          this._matSnackBar.open(response.message, 'OK', {
            duration: 4000,
            verticalPosition: 'bottom'
          })
        }, reject);
    });
  }

  addNewRole(title, permission) {
    let payload = {
      title,
      permission
    }


    this._fuseProgressBarService.show()
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/role/add', payload, this._commonService.getHeader())
        .subscribe((response: any) => {
          this._fuseProgressBarService.hide()
          if (response.status == 200)
            resolve(response);
          else
            reject(response)

          this._matSnackBar.open(response.message, 'OK', {
            duration: 4000,
            verticalPosition: 'bottom'
          })
        }, reject);
    });


  }
}
