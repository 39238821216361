import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';

import { ActivatedRoute, Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { AssignEventDialogComponent } from '@fuse/Dialog/assign-event-dialog/assign-event-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { Enums } from '@fuse/utils/systemEnums';
import { result } from 'lodash';
import { AddUserService } from '../add-distributor.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector: 'app-asigned-events',
    templateUrl: './asigned-events.component.html',
    styleUrls: ['./asigned-events.component.scss']
})
export class AsignedEventsComponent implements OnInit {

    displayedColumns;
    dataSource;
    maxAvailableRecordsInTable;
    Enums = Enums;
    dialogRef

    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    isReadMode: boolean;
    @ViewChild('search', { static: false }) search;
    eventId: any;


    constructor(public _addUserService: AddUserService,
        private _activatedRoute: ActivatedRoute,
        public _ToolbarService : ToolbarService,
        private _matDialog: MatDialog,
        private _fuseProgressBarService: FuseProgressBarService,
        private _commonservice:CommonService,
        private snackBar:MatSnackBar,
        private _router: Router,
    ) {
        this._activatedRoute.params.subscribe((params) => {
            this.isReadMode = params['id'] ? true : false;
        })
    }

    ngOnInit() {
        this.displayedColumns = ['name', 'eventStartDate', 'eventEndDate', 'availableTickets', 'soldTickets', 'action'];
        this.maxAvailableRecordsInTable = this._addUserService.maxAvailableRecordsInTable;
        this.dataSource = new MatTableDataSource(this._addUserService.assignedEvents);
    }


    dateRangeFilterVal
    filter = {
        dateRangeFilter: {
            startDate: 0,
            endDate: 0
        },
        typeFilter: 0,
        searchFilter: '',
        pageSize: 10,
        pageIndex: 1
    }

    viewTicketHistory(data) {
        console.log("ticket history", data);
    }

    editAssgin(eventId){
        console.log("edit event", eventId);
    }

    clearFilter() {
        this.filter.dateRangeFilter = {
            startDate: 0,
            endDate: 0
        };
        this.filter.typeFilter = 0
        this.filter.searchFilter = ''
        this.dateRangeFilterVal = ''
        this.filterData()
        this.paginator.pageIndex = 0;
    }
    onTypeFilterSelect(val) {
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1
        this.filter.typeFilter = val.value;
        this.filterData();
    }
    onSearch(val) {
        this.paginator.pageIndex = 0;
        this.filter.pageIndex = 1
        this.filter.searchFilter = val
        this.filterData();
    }

    viewSaleHistory(eventId){
        // if (eventId && distId) {
            this._router.navigate(['/distributors/ticketSaleHistory'], {queryParams: {eventId: eventId}});
        // }
    }

    editTicketTypeInfo(eventId) {
        console.log("editTicketTypeInfo", eventId);
        this._addUserService.getAssignedEventDetails(eventId).then((result: any) => {
            if (result.status == 200) {
                console.log("---->", result);
                this.openTicketTypeInfoDialog(this._addUserService.distributorId, result.data[0])
            }
        })
    }

    openTicketTypeInfoDialog(distributorId, eventData) {
        console.log("this._addUserService.distributorCommission  ", eventData);
        
        this.dialogRef = this._matDialog.open(AssignEventDialogComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            autoFocus: false,
            data: {
                eventId: eventData.eventId,
                ticketType: eventData.availableTicketTypes,
                distributorId: distributorId,
                assignedTicketTypes: eventData.ticketInfo,
                //   commission:this._addUserService.distributorCommission         //if it has value, it meanse we are going to edit record
            }
        });

        this.dialogRef.afterClosed()
            .subscribe(response => {
                if (response) {
                    this._addUserService.getAssignedEvents({ "distributorId": this._addUserService.distributorId }).then((result: any) => {
                        if (result.status == 200) {
                            this.dataSource.data = result.data.data
                            this.maxAvailableRecordsInTable = result.data.totalRecords
                            console.log("3. dialogRef this.maxAvailableRecordsInTable->", this.maxAvailableRecordsInTable);
                        }else if(result.status == 400){
                            this.dataSource.data = []
                            // this.maxAvailableRecordsInTable = 0
                        }
                        else {
                            this.dataSource.data = []
                            // this.maxAvailableRecordsInTable = 0
                        }
                    })
                } else
                    return;

            });
    }


    filterData() {
        this._fuseProgressBarService.show()
        let payload = {
            "distributorId": this._addUserService.distributorId,
            "pageIndex": this.filter.pageIndex,
            "pageSize": this.filter.pageSize,
            "search": this.filter.searchFilter,
            "startDate": this.filter.dateRangeFilter.startDate ? this.filter.dateRangeFilter.startDate : 0,
            "endDate": this.filter.dateRangeFilter.endDate ? this.filter.dateRangeFilter.endDate : 0,
            "eventStatus": this.filter.typeFilter
        }
        this._addUserService.getAssignedEvents(payload).then((result: any) => {
            if (result.status == 200) {
                this._fuseProgressBarService.hide();
                this.dataSource.data = result.data.data;
                this.maxAvailableRecordsInTable = result.data.totalrecords;
                console.log("filterData this.maxAvailableRecordsInTable", this.maxAvailableRecordsInTable);
            }else if(result.status == 400){
                this.dataSource.data = [];
                this._fuseProgressBarService.hide();
                this.maxAvailableRecordsInTable = 0;
            }
            else {
                this.dataSource.data = [];
                this._fuseProgressBarService.hide();
                this.maxAvailableRecordsInTable = 0;
            }
        })
    }
    onDateFilterChanges(e) {
        if (e.endDate != null && e.startDate != null) {
            this.filter.dateRangeFilter.endDate = +e.endDate
            this.filter.dateRangeFilter.startDate = +e.startDate
            this.filterData()
            this.paginator.pageIndex = 0;
        }
    }
    onPageChange(event) {
        this.filter.pageIndex = event.pageIndex + 1
        this.filter.pageSize = event.pageSize
        this.filterData()
    }
    clearSearch() {
        this.filter.searchFilter = ''
        this.search.nativeElement.focus()
        this.filterData()
    }

    updateAssignStatus(eventId){
        const payload = {
            distributorId:this._addUserService.distributorId,
            eventId:eventId,
        }
        console.log('hello there button clicked');
        
        this._commonservice.updateAssignStatus(payload).then((result:any)=>{
            if(result.status == 200){
                this.snackBar.open(result.message,"Okay",{duration:2000})
                this.filterData()
            }
        })


    }

    
}