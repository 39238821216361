import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
// import { ApiEndPointUrl } from '@fuse/utils/systemEnums';
import { timeStamp } from 'console';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventDomUsersService implements Resolve<any> {
  eventId: number;
  domUsers = [];
  totalRecords: number;

  constructor(
    private _httpClient: HttpClient,
    private _fuseProgressBarService: FuseProgressBarService,
    private _commonService: CommonService,
  ) { }

  // resolver
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    console.log("state ===>", state);
    console.log("route---==>", route);
    this.eventId = route.params.id;
    return new Promise((resolve, reject) => {
      Promise.all([
        this._fuseProgressBarService.show(),
        // this.getDomUsers(),
      ]).then(() => {
        this._fuseProgressBarService.hide();
        resolve('')
      })
      reject
    })
  }


  // get all dom users listing. 
  getDomUsers(payload = {eventId : this.eventId, pageIndex : 1, pageSize : 10}) { 
    return new Promise((resolve, reject) => {
      this._httpClient.post(environment.apiURL + 'admin/event/domeUserList', payload, this._commonService.getHeader()).subscribe((response: any) => {
        if (response.status == 200) {
          this.domUsers = response.data.data;
          console.log("getDomUsers", this.domUsers);
          this.totalRecords = response.data.totalRecords;
          resolve(response);
        } else {
          this.domUsers = [];
          this.totalRecords = 0;
          resolve(response);
        }
      }, reject)
    })
  }

  
}
