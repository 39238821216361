import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import { fuseAnimations } from '@fuse/animations';
import { CommonService } from '@fuse/services/common.service';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { HostGroupService } from './host-group.service';
import { AddEditHostGroupComponent } from '@fuse/Dialog/add-edit-host-group/add-edit-host-group.component';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-host-group',
  templateUrl: './host-group.component.html',
  styleUrls: ['./host-group.component.scss'],
  animations : fuseAnimations
})
export class HostGroupComponent implements OnInit {

  filter = {
    search: '',
    pageSize: 10,
    pageIndex: 1
  }
  maxAvailableRecordsInTable:number;
  dataSource;
  displayedColumns: string[] = ['groupName', 'memberCount', 'action'];
  getDetailAPIPending:boolean = false;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  
  constructor(
    private _ToolbarService : ToolbarService,
    private _matdialog : MatDialog,
    private _commonservice : CommonService,
    private _matsnakebar : MatSnackBar,
    private _hostGroupService : HostGroupService
  ) { 
    this.setToolBarTitle();
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this._hostGroupService.hostGroups);
    this.maxAvailableRecordsInTable =  this._hostGroupService.maxAvailableRecordsInTable;
  }

  setToolBarTitle(){
    this._ToolbarService._ToolBarTitle.next('Host Groups');
    this._ToolbarService._isBackBtnShow.next(false);
  }

  @ViewChild('search', { static: true }) search;
  clearSearch() {
    this.filter.search = '';
    this.search.nativeElement.focus();
    this.updateListing();
  }

  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1;
    this.filter.search = val;
    this.updateListing()
  }

  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1;
    this.filter.pageSize = event.pageSize;
    this.updateListing();
  }

  updateListing(){
    this._hostGroupService.getAllHostGroup(this.filter).then((result:any) => {
      if(result.status == 200){
        this.dataSource.data = result.data.getAllHostGroup;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }else if(result.status == 400){
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }
  

  openHostGroupDialog(mode, groupData?){
    if(mode == 'add'){
      const dialog = this._matdialog.open(AddEditHostGroupComponent, {
        panelClass: ['commonDialogWrapper'],
        backdropClass: 'dialogBackdrop',
        width : '500px',
        autoFocus : false,
        data : {
          mode : mode,
          hostType : groupData || undefined,
          hosts : this._hostGroupService.allHostList
        }
      })
      dialog.afterClosed().subscribe((res) => {
        if(res?.formData){
          if(res?.mode == 'add'){
            const payload = {
              name : res?.formData?.title,
              speakerList : []
            }
            res.selectedSponsors.forEach((item) => {
              payload.speakerList.push({speakerId : item.id, speakerRanking : item.rank})
            }) 
            this._commonservice.addHostGroup(payload).then((result:any) => {
              if(result.status == 200){
                this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
                this.updateListing();
              }
            })
          }
        }
      })
    }
    else{
      this.getDetailAPIPending = true;
      // edit and view
      this._commonservice.getHostGroupById({id:groupData.id}).then((result:any) => {
        if(result.status == 200){
          this.getDetailAPIPending = false;
          const dialog = this._matdialog.open(AddEditHostGroupComponent, {
            panelClass: ['commonDialogWrapper'],
            backdropClass: 'dialogBackdrop',
            width : '500px',
            autoFocus : false,
            data : {
              mode : mode,
              hostType : result.data || undefined,
              hosts : this._hostGroupService.allHostList
            }
          })
          // for only edit
          dialog.afterClosed().subscribe((res) => {
            if(res?.formData){
              if(res?.mode == 'edit'){ 
                const payload = {
                  id: groupData.id,
                  name : res?.formData?.title,
                  speakerList : []
                }
                res.selectedSponsors.forEach((item) => {
                  payload.speakerList.push({speakerId : item.id, speakerRanking : item.rank})
                }) 
                this._commonservice.updateHostGroup(payload).then((result:any) => {
                  if(result.status == 200){
                    this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
                    this.updateListing(); 
                  }
                })
              }
            }
          })

        }else{
          this._matsnakebar.open(result.message, 'OKAY', {duration : 2000})
        }
      })
    }
  }


  onDelete(item){
    console.log("item",item);
    let dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to remove this host Group?',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Host Group?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    }) 
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        if(!item?.isDeletable){
          this.callDeleteApi(item)
        }else{
          this.showWarningMessage(item)
        }
      }
    })
    
  }

  showWarningMessage(item){
    const dialog = this._matdialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      width : '450px',
      disableClose: false,
      data : {
        message : ` This host group is part of an event. Deleting it will also remove the host group from those events. Are you sure you want to delete?`,
        image : '/assets/animated_icon/alarm.gif',
        imageSize : '150px',
        focus : 'right',
        deleteItem : 'Warning',
        confirmButtonText : 'Yes',
        cancelButtonText : 'No'
      }
    })
    dialog.afterClosed().subscribe((result)=> {
      if(result){
        this.callDeleteApi(item)
      }
    })
  }
   
  callDeleteApi(item){
    this._commonservice.deleteHostGroup({ "id": item.id }).then((result: any) => {
      if (result) {
        this._matsnakebar.open(result.message, 'OKAY',{duration: 2000})
        this.updateListing();
      }
    })
  }


}
