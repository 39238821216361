import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { CommonService } from '@fuse/services/common.service';
import { MeetingLocationService } from 'app/main/meeting-locations/meeting-locations-list/meeting-location.service';

@Component({
  selector: 'app-add-edit-event-meeting-location',
  templateUrl: './add-edit-event-meeting-location.component.html',
  styleUrls: ['./add-edit-event-meeting-location.component.scss'], 
})
export class AddEditEventMeetingLocationComponent implements OnInit {
  form: any;
  dataSource = new MatTableDataSource([]);
  displayedColumns = ['select','name'];
  selection = new SelectionModel<any>(true, []);
  searchVal:string = '';
  apiInProcess: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    private _MeetingLocationService: MeetingLocationService,
    private _MatSnackBar: MatSnackBar,
    private _CommonService : CommonService,
    public _matDialogRef: MatDialogRef<AddEditEventMeetingLocationComponent>,
  ) { 

  
    this._MeetingLocationService.getAllMeetingLocations({pageSize : 0, pageIndex : 0, search : ''})
    this._MeetingLocationService.meetingLocations.subscribe((res)=>{
      if(res.length){
        this.dataSource = new MatTableDataSource(res);

        if(this._data.selectedIds?.length){
          const selectedData = [];
          res.filter((item) => {
            this._data.selectedIds.forEach((ele) => {
              if(item.id === ele.meetingLocationId){
                item.isLocationUsed = ele.isLocationUsed;
                selectedData.push(item)
              }
            })
          })
          this.selection.setSelection(...selectedData);
        }

      }else{
        this.dataSource = new MatTableDataSource([]);
      }
    })



  }

  ngOnInit(): void {
  }

  checkIsUsed(){
    return this.dataSource.data.some((item) => item.isLocationUsed == 0);
  }


  onSearchUser(value) {
    // this.filtered.search = value;
    // this.filterData();
  }

  clearSearch() {
    // this.form.get('search').setValue('');
    // this.filtered.search = '';
    // this.filterData();
  }

  onSearch(value){
    console.log("value", value);
    this.dataSource.filter = value.trim().toLowerCase();
  }

  // checkIsUsed(){
  //   return this.dataSource.data.every((item)=> item.isUsed == 1);
  // }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  onSave(){
    if(this.selection.selected.length == 0) return;
    
    const selelctedIds = [];
    this.selection.selected.forEach((item) => selelctedIds.push(item.id));

    const payload = {
      "locationIds" : selelctedIds.join(','),
      "eventId" : this._data.eventId
    }

    this._CommonService.addEventMeetingLocation(payload).then((res:any)=>{
      if(res.status == 200){
        this._MatSnackBar.open(res.message, 'OKAY', {duration: 3000})
        this.selection.clear();
        this.apiInProcess = false;
        this._matDialogRef.close(true);
      }else{
        this._MatSnackBar.open(res.message, 'OKAY', {duration: 3000})
        this.apiInProcess = false;
      }
    })

  }
  
}
