import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvitationsComponent } from './invitations/invitations.component';
import { RouterModule, Routes } from '@angular/router';
import { InvitationsService } from './invitations.service';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE, MatRippleModule } from '@angular/material/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components';
import { MatGridListModule } from '@angular/material/grid-list';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SendInvitationComponent } from './send-invitation/send-invitation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { MdInputModule } from '@angular/material';
import { NGX_MAT_DATE_FORMATS, NgxMatDateAdapter, NgxMatDateFormats, NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule  } from '@angular-material-components/datetime-picker';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { QuillModule } from 'ngx-quill';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AgmCoreModule } from '@agm/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxEditorModule } from "ngx-editor";
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { CustomNgxDatetimeAdapter } from '../events/edit-event/customFormat';
import { NoEditDirective } from './send-invitation/no-edit.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { DataDecryptionIntercaptorService } from '@fuse/services/data-decryption-intercaptor.service';
import { EncryptioninterceptorService } from '@fuse/services/encryptioninterceptor.service';

interface CustomNgxMatDateFormats extends NgxMatDateFormats {
  dayLabel?: string;
  dayOfWeek?: string;
}

// export const MOMENT_DATETIME_WITH_SECONDS_FORMAT = 'DD-MMM-YYYY hh:mm';

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS'
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm A',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  }
};

const routes: Routes = [
  {
      path: '',
      component: InvitationsComponent,
      resolve: {
          data: InvitationsService
      }
  },
  {
    path: 'create-new-invitation',
    component: SendInvitationComponent,
    resolve: {
        data: InvitationsService
    }
  },
  {
    path: 'edit-invitation/:id',
    component: SendInvitationComponent,
    resolve: {
        data: InvitationsService
    }
  },
];

@NgModule({
  
  declarations: [InvitationsComponent, SendInvitationComponent,NoEditDirective],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    HttpClientModule,
    AngularEditorModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    MatPaginatorModule,
    NgxMaterialTimepickerModule,
    MatRippleModule,
    AgmCoreModule,
    MatSortModule,
    MatSnackBarModule,
    NgxFileDropModule,
    MatSlideToggleModule,
    MatTableModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTooltipModule,
    FuseSharedModule,
    FuseWidgetModule,
    MatGridListModule,
    GooglePlaceModule,
    MatDialogModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMatSelectSearchModule,
    QuillModule,
    QuillModule.forRoot(),
    NgxEditorModule.forRoot({
      locals: {
        // menu
        bold: 'Bold',
        italic: 'Italic',
        code: 'Code',
        blockquote: 'Blockquote',
        underline: 'Underline',
        strike: 'Strike',
        bullet_list: 'Bullet List',
        ordered_list: 'Ordered List',
        heading: 'Heading',
        h1: 'Header 1',
        h2: 'Header 2',
        h3: 'Header 3',
        h4: 'Header 4',
        h5: 'Header 5',
        h6: 'Header 6',
        align_left: 'Left Align',
        align_center: 'Center Align',
        align_right: 'Right Align',
        align_justify: 'Justify',
        text_color: 'Text Color',
        background_color: 'Background Color',

        // popups, forms, others...
        url: 'URL',
        text: 'Text',
        openInNewTab: 'Open in new tab',
        insert: 'Insert',
        altText: 'Alt Text',
        title: 'Title',
        remove: 'Remove',
      },
    }),
  ],
  providers: [
    InvitationsService,
    {
      provide: NgxMatDateAdapter,
      useClass: CustomNgxDatetimeAdapter,
      deps: [MAT_DATE_LOCALE, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    {
      provide: HTTP_INTERCEPTORS, useClass: DataDecryptionIntercaptorService, multi: true
    },
    {
        provide: HTTP_INTERCEPTORS, useClass : EncryptioninterceptorService,  multi: true
    },
  ],
})
export class InvitationsModule {
  }