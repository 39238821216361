import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CryptoService } from './crypto.service'; 

@Injectable({
  providedIn: 'root'
})
export class EncryptioninterceptorService {

  constructor(
    private _CryptoService : CryptoService,
  ) {  
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      
      if(request.headers?.get("authorization")){ 
        // Intercept only POST requests
          if (request.body) {
              console.log("we are in body case");
              console.log("request.body", request.body);
              this._CryptoService.postKey = this._CryptoService.genrateKey();
              const encryptedBody = this._CryptoService.encryptData(JSON.stringify(request.body), this._CryptoService.postKey, this._CryptoService.base64);
              const decryptData = this._CryptoService.decryptData(encryptedBody, this._CryptoService.postKey, this._CryptoService.base64);
              console.log("encryptedBody", String(encryptedBody).length);
              console.log("decryptData", decryptData);
              request = request.clone({
                body: { request : encryptedBody }
              }); 
          } 
    }
    else{

      if (request.body) {
        console.log("request", request);
          if(!request.url?.includes('AWSAccessKeyId')){
            const str = this._CryptoService.genrateRandomKeys(100);
            const encryptedBody = this._CryptoService.encryptData(JSON.stringify(request.body), str, null);
            const part1 = str.slice(0, str.length / 2);
            const part2 = str.slice(str.length / 2);
            request = request.clone({
              body: { request : part1 + encryptedBody + part2 }
            }); 
          }
      }

    }
   
    // Proceed with the modified request
    return next.handle(request);
  }
 
  
}
