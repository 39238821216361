import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { CommonService } from '@fuse/services/common.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-forgot-password-admin',
  templateUrl: './forgot-password-admin.component.html',
  styleUrls: ['./forgot-password-admin.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations   : fuseAnimations
})
export class ForgotPasswordAdminComponent implements OnInit {
  showError;
  resetPasswordForm: UntypedFormGroup;
  isPassVisible : boolean = false;
  isConPassVisible : boolean = false;
  paramsId:any;
  isPasswordChanged : boolean = false;
  private _unsubscribeAll: Subject<any>;
  appLink:string = '/auth/login'
  constructor(
    private _fuseConfigService: FuseConfigService,
        private _formBuilder: UntypedFormBuilder,
        private _fuseProgressBarService: FuseProgressBarService,
        private _router: Router,
        private _activatedRoute: ActivatedRoute,
        private HttpClient: HttpClient,
        private _commonservice: CommonService,
        private snackBar:MatSnackBar,
  ) {

    this._activatedRoute.params.subscribe((params) => {
      console.log("params", params);
      if(params)
      this.paramsId = params.token
    })

        // Configure the layout
        this._fuseConfigService.config = {
          layout: {
              navbar   : {
                  hidden: true
              },
              toolbar  : {
                  hidden: true
              },
              footer   : {
                  hidden: true
              },
              sidepanel: {
                  hidden: true
              }
          }
      };

      // / Set the private defaults
        this._unsubscribeAll = new Subject();
   }

  ngOnInit(): void {

    // this.route.paramMap.switchMap((params: ParamMap) => {
        //     let user_id = params.get('id');
    
        //     return this.userService.get(user_id);
        // })
        // .subscribe(res => this.user = res); // assuming user is being returned from this.userService.get()
        this.resetPasswordForm = this._formBuilder.group({
          // name           : ['', Validators.required],
          // email          : ['', [Validators.required, Validators.email]],
          password: ['', Validators.required],
          passwordConfirm: ['', [Validators.required, confirmPasswordValidator]]
      });
      
      // Update the validity of the 'passwordConfirm' field
      // when the 'password' field changes
      this.resetPasswordForm.get('password').valueChanges
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(() => {
              this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
          });
  }


  onSubmit(){
    const payload = {
        password:this.resetPasswordForm.get('password').value,
        // id:this.paramsId
    }
    this.resetPassword(payload, this.paramsId)
  }

  resetPassword(payload,tokan){
    this._fuseProgressBarService.show();
    this._commonservice.adminpass(payload, tokan).then((result:any)=>{ 
        if(result.status == 200){
            this.snackBar.open(result.message,"Okay",{duration:2000})
            this.isPasswordChanged = true;
            console.log("res", );
            
        }else{
            this.snackBar.open(result.message,"Okay",{duration:2000})
            this.isPasswordChanged = false;
        }
        this._fuseProgressBarService.hide();
    }).catch((err) => {
        console.log('err',err);
        this._fuseProgressBarService.hide();
    })
    
  };


  /**
       * On destroy
       */
  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
  }


}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  if ( !control.parent || !control )
  {
      return null;
  }
  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');
  if ( !password || !passwordConfirm )
  {
      return null;
  }
  if ( passwordConfirm.value === '' )
  {
      return null;
  }
  if ( password.value === passwordConfirm.value )
  {
      return null;
  }
  return {passwordsNotMatching: true};
};