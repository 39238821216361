import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Enums } from '@fuse/utils/systemEnums';
import { AddVerifierService } from './add-verifier.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ConfirmationDialogComponent } from '@fuse/Dialog/confirmation-dialog/confirmation-dialog.component';
import { EventListDialogComponent } from './event-list-dialog/event-list-dialog.component';
import { CommonService } from '@fuse/services/common.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchCountryField, CountryISO, PhoneNumberFormat } from 'ngx-intl-tel-input';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { CropperComponent } from '@fuse/Dialog/cropper/cropper.component';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';
import { fuseAnimations } from '@fuse/animations';
import { LyDialog } from '@alyle/ui/dialog';
import { VerifierAssigningDialogComponent } from '@fuse/Dialog/verifier-assigning-dialog/verifier-assigning-dialog.component';

@Component({
  selector: 'app-add-verifier',
  templateUrl: './add-verifier.component.html',
  styleUrls: ['./add-verifier.component.scss'],
  animations : fuseAnimations
})

export class AddVerifierComponent implements OnInit {
  dialogRef;
  addVerifierForm: UntypedFormGroup
  enum = Enums;
  verifierId;
  displayedColumns: string[] = ['name', 'eventStartDate', 'eventEndDate', 'totalScan', 'scHistory'];
  dataSource;
  maxAvailableRecordsInTable;
  todayDate = new Date();
  eightteenYearAgoFormToday;
  selectedCountryCode;
  defCountryCode: String = 'IN';
  eventId: any;

  croppedProfileBase64: any = null;

  separateDialCode = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];

  range = new UntypedFormGroup({
    start: new UntypedFormControl(null),
    end: new UntypedFormControl(null),
  });

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  dob: number;

  constructor(
    private _dialog: LyDialog,
    private _cd : ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    public _addVerifierService: AddVerifierService,
    private _matSnackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _ToolbarService : ToolbarService,
    private _fuseProgressBarService: FuseProgressBarService,
    private _router: Router,
    private _matDialog: MatDialog,
    public _commonservice: CommonService
  ) {
    if (_addVerifierService.permissions.unAssignEvent)
      this.eightteenYearAgoFormToday = this.todayDate.setFullYear(this.todayDate.getFullYear() - 18);
      this.eightteenYearAgoFormToday = new Date(this.eightteenYearAgoFormToday)
      this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'actionUnassign');

      this._activatedRoute.queryParams.subscribe((params) => {
        this.verifierId = params['id']
        if (params['id']) {
          this.generateForm(this._addVerifierService.verifier);
          this.addVerifierForm.disable()
          this.setToolBarTitle(true);
        } else {
          this.generateForm();
          this.setToolBarTitle(false); 
        }
      })
  }

  ngOnInit() {
    this.maxAvailableRecordsInTable = this._addVerifierService.maxAvailableRecordsInTable;
    this.dataSource = new MatTableDataSource(this._addVerifierService.assignedEvents);
    this._activatedRoute.queryParams.subscribe((params) => {
      this.verifierId = params['id']
      if (params['id']) {
        this.generateForm(this._addVerifierService.verifier);
        this.addVerifierForm.disable()
      } else {
        this.generateForm();
      }
    })

    this.addVerifierForm.get('dob').valueChanges.subscribe((result) => {
      console.log("result", new Date(result).getTime());
      this.dob = new Date(result).getTime();
    })

    
    this.addVerifierForm.get('mobile').valueChanges.subscribe((result) => {
      console.log("result", result);
      if((result?.countryCode === 'CN')){
        if((String(result?.e164Number).length == 14 || String(result?.e164Number).length == 13)){
            this.addVerifierForm.get('mobile').setErrors(null);
        }else{
            this.addVerifierForm.get('mobile').setErrors({validatePhoneNumber : {valid : false}});
        }
      }
    })

  }

  onDateChange(){
    if(this.range.controls['start'].value && this.range.controls['end'].value)
        this.filterData();
  }

  
  setToolBarTitle(edit){
    if(edit){
        // for title
        this._ToolbarService._ToolBarTitle.next('Verifier, ' + this._addVerifierService?.verifier?.name);
        // for btn
        this._ToolbarService._isBackBtnShow.next(true);
    }else{
        // for title
        this._ToolbarService._ToolBarTitle.next('Add Verifier');
        // for btn
        this._ToolbarService._isBackBtnShow.next(false);
    }
  }

  // ----------------------- filters section -----------------------
  dateRangeFilterVal
  filter = { 
    typeFilter: '',
    searchFilter: '',
    pageSize: 10,
    pageIndex: 1
  }

  clearFilter() {
    this.paginator.pageIndex = 0;
    this.range.get('start').reset();
    this.range.get('end').reset();
    this.filter.typeFilter = ''
    this.filter.searchFilter = ''
    this.dateRangeFilterVal = ''
    this.filterData()
  }
  onTypeFilterSelect() {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filterData();
  }
  onSearch(val) {
    this.paginator.pageIndex = 0;
    this.filter.pageIndex = 1
    this.filter.searchFilter = val
    this.filterData();
  }

  filterData() {
    let filter = {
      "pageIndex": this.filter.pageIndex,
      "pageSize": this.filter.pageSize,
      "search": this.filter.searchFilter,
      "startDate": this.range.controls['start'].value ? new Date(this.range.controls['start'].value).getTime() : 0,
      "endDate": this.range.controls['end'].value ? new Date(this.range.controls['end'].value).getTime() : 0,
      "eventStatus": this.filter.typeFilter
    }
    this._addVerifierService.getAssignedEvents(this.verifierId, filter).then((result: any) => {
      if (result.status == 200) {
        this.dataSource.data = result.data.data;
        this.maxAvailableRecordsInTable = result.data.totalRecords;
      }
      else {
        this.dataSource.data = [];
        this.maxAvailableRecordsInTable = 0;
      }
    })
  }
  
  onPageChange(event) {
    this.filter.pageIndex = event.pageIndex + 1;
    this.filter.pageSize = event.pageSize;
    this.filterData()
  }
  
  @ViewChild('search', { static: false })
  search: ElementRef;

  clearSearch() {
    this.filter.searchFilter = '';
    this.search.nativeElement.focus();
    this.filterData();
  }

  scanHistoryList(eventId) {
    if (eventId) {
      this.eventId = eventId;
      this._router.navigate(['/verifiers/scan-history'], { queryParams: { id: this.verifierId, eventId: eventId } });
    }
  }

  selectcountryCode(e) {
    this.selectedCountryCode = parseInt(e.value);
  }

  unassignEvent(eventId) {
    this._addVerifierService.unassignEvent(eventId, this.verifierId).then(() => {
      this._addVerifierService.getAssignedEvents(this.verifierId).then(() => {
        this.dataSource.data = this._addVerifierService.assignedEvents;
      })
    })
  }

  onCountryChange(e) {
    this._commonservice.getStates(e.value).then((result: any) => {
      if (result.status == 200)
        this._addVerifierService.states = result.data;
    })
  }

  showListOfUnassignedEvents() {
    this._addVerifierService.getAllUnasignedEvents(this.verifierId).then((result: any) => {
      if (result.status == 200) {
        this.openDialog(result.data);
      } else {
        this._matSnackBar.open(result.message, 'OK', {
          duration: 4000,
          verticalPosition: "bottom"
        })
      }
    })
  }

  openDialog(events) {
    // this.dialogRef = this._matDialog.open(EventListDialogComponent, {
    //   panelClass: ['commonDialogWrapper'],
    //   backdropClass: 'dialogBackdrop',
    //   width: '400px',
    //   autoFocus : false,
    //   data: {
    //     events: events,
    //     verifierId: this.verifierId
    //   }
    // });
    // this.dialogRef.afterClosed()
    //   .subscribe(response => {
    //     if (response) {
    //       this._addVerifierService.getAssignedEvents(this.verifierId).then(() => {
    //         this.dataSource.data = this._addVerifierService.assignedEvents;
    //       })
    //     } else
    //       return;
    //   });
    const dialog = this._matDialog.open(VerifierAssigningDialogComponent, {
      panelClass: ["commonDialogWrapper"],
      backdropClass: "dialogBackdrop",
      width: "450px",
      autoFocus : false,
      data: {
          by : 'verifierDetails',
          mode : 'add',
          verifierData : this._addVerifierService.verifier,
          unasignedEvents : events,
          eventId : null,
      },
  });
  }

  onDeleteVerifier(id) {
    let dailog =  this._matDialog.open(ConfirmationDialogComponent, {
      panelClass: ['commonDialogWrapper'],
      backdropClass: 'dialogBackdrop',
      disableClose: false,
      data : {
          message : 'Are you sure you want to delete this verifier',
          image : '/assets/ui/delete.png',
          focus : 'right',
          deleteItem : 'Delete Verifier?',
          confirmButtonText : 'Yes',
          cancelButtonText : 'No'
      }
    })
    
    dailog.afterClosed().subscribe((row: any) => {
      if (row) {
        this._fuseProgressBarService.show();
        this._addVerifierService.deleteVerifier(id).then((result: any) => {
          this._fuseProgressBarService.hide();
          this._matSnackBar.open(result.message, 'OK', {
            verticalPosition: 'bottom',
            duration: 2000
          });
          if (result.status == 200) {
            this._router.navigate(['/verifiers']);
          }
        }).catch(err => {
        })
      }
    });
  }

  generateForm(verifier?) {
    console.log("verifier here", verifier);
    if (verifier?.countryCode){
      this.defCountryCode = verifier.countryCode
    }
    if (verifier?.profileImage){
      this.croppedProfileBase64 = verifier.profileImage;
    }
    if (verifier?.birthDate){
      this.dob = verifier.birthDate;
    }

    this.addVerifierForm = this.fb.group({
      fullName: [verifier ? verifier.name : '', [Validators.required]],
      // countryCode: [verifier ? this._addVerifierService.toSeparatCode: this.defCountryCode, Validators.required],
      mobile: [verifier ? verifier.mobile : null, Validators.required,],
      // category: [event? event.eventCategory? event.eventCategory.split(","): null : null, Validators.required, ],
      email: [verifier ? verifier.email : '', [Validators.required, Validators.email]],
      gender: [verifier ? verifier.gender.toString() : '', [Validators.required]],
      city: [verifier ? verifier.city : ''],
      dob: [verifier ? new Date(verifier.birthDate) : ''],
      state: [verifier ? verifier.state : ''],
      country: [verifier ? verifier.country : ''],
      image: [verifier ? verifier.image : '']
    });
  }

  onSubmitAddVerifier() {
    console.log("this is from value", this.addVerifierForm);
    let password = '';
    for (let i = 0; i < 10; i++)
      password = password + Math.floor(Math.random() * 10);
    // this._fuseProgressBarService.show()
    const payload = {
      "name": this.addVerifierForm.get('fullName').value,
      "mobile": this.addVerifierForm.get('mobile').value?.number.split(" ").join(""),
      "countryCode": this.addVerifierForm.get('mobile').value?.countryCode,
      "phoneCode": this.addVerifierForm.get('mobile').value?.dialCode,
      "email": this.addVerifierForm.get('email').value,
      "city": this.addVerifierForm.get('city').value,
      "gender": this.addVerifierForm.get('gender').value,
      "birthDate": this.dob,
      "state": this.addVerifierForm.get('state').value,
      "country": this.addVerifierForm.get('country').value,
      "image": this.addVerifierForm.get('image').value,
      "password": password
    }

    this._addVerifierService.addVerifier(payload).then((result: any) => {
      this._fuseProgressBarService.hide()
      this._matSnackBar.open(result.message, 'OK', {
        verticalPosition: 'bottom',
        duration: 2000
      });
      if (result.status == 200) {
        this._router.navigate(['/verifiers']);
      }
    }).catch((err) => {
      this._matSnackBar.open('Unable to add verifier', 'OK', {
        verticalPosition: 'bottom',
        duration: 4000
      });
    })
  }

  onEditVerifier(id) {
    this.addVerifierForm.value.id = id
    const payload = {
      "verifierId": id,
      "name": this.addVerifierForm.get('fullName').value,
      "mobile": this.addVerifierForm.get('mobile').value?.number?.split(" ").join(""),
      "countryCode": this.addVerifierForm.get('mobile').value?.countryCode,
      "phoneCode": this.addVerifierForm.get('mobile').value?.dialCode,
      "email": this.addVerifierForm.get('email').value,
      "city": this.addVerifierForm.get('city').value,
      "gender": this.addVerifierForm.get('gender').value,
      "birthDate": this.dob,
      "state": this.addVerifierForm.get('state').value,
      "country": this.addVerifierForm.get('country').value,
      "image": this.addVerifierForm.get('image').value,
    }
    console.log("payload", payload);
    this._fuseProgressBarService.show();
    this._addVerifierService.editVerifier(payload).then((result: any) => {
      this._fuseProgressBarService.hide();
      this._matSnackBar.open(result.message, 'OK', {verticalPosition: 'bottom',duration: 2000});
      if (result.status == 200) {
        this._router.navigate(['/verifiers']);
      }
    }).catch((err) => {
      this._matSnackBar.open('Unable to edit verifier', 'OK', {verticalPosition: 'bottom',duration: 4000});
    })
  }

  // public dropped(files: NgxFileDropEntry[], dropperFor: 'thumb' | 'banner') {
  //   for (const droppedFile of files) {
  //     // Is it a file?
  //     if (droppedFile.fileEntry.isFile) {
  //       const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
  //       fileEntry.file((file: File) => {
  //         this.openCropperForProfile(file);
  //       });
  //     } else {
  //       // It was a directory (empty directories are added, otherwise only files)
  //       const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
  //       console.log(droppedFile.relativePath, fileEntry);
  //     }
  //   }
  // }

  openCropperForProfile(event) {
    this._dialog.open<CropperComponent, any>(CropperComponent, {
      data: {
          fileEvent: event,
          myConfig: {
              width: 250,
              height: 250,
              round : false,
              type: 'image/png',
              keepAspectRatio: true,
              responsiveArea: true,
              output: {
                  width: 320,
                  height: 320
              },
              resizableArea: true
          }
      },
      width: 520,
      height : 520,
      disableClose: true
    }).afterClosed.subscribe((result:any) => {
      if (result) {
          console.log("result", result);
          console.log("this is pre event", event); 
          this.croppedProfileBase64 = result.file.dataURL;
          this._cd.markForCheck();
          this.onUploadImage(result.file, result.blobFile)
      }
    }); 
  }

  // on upload Image presignedURL API 
  onUploadImage(fileObj, blobFile) {

    const payload = {
      "fileExt": '.' + String(fileObj?.type).slice(String(fileObj.type).indexOf('/') + 1),
      "fileName": 'verifier_',
      "folderName": 'verifier/profile', // it is fix set by backend. 
      'mimeType': fileObj?.type
    }

    return new Promise((resolve, reject) => {
      this._commonservice.getPresignedUrlAPI(payload).then((result: any) => {
        if (result.status == 200) {
          this.addVerifierForm.get('image').setValue(result.data.imageUploadUrl);
          // payload for get Image upload status 
          const payload = {
            'id': result.data.id,
            'status': 1 // fix by backend. 
          }
          this._commonservice.updateInPresignedUrlAPI(result.data.presignedUrl, fileObj?.type, blobFile);
          this._commonservice.getFileUploadStatus(payload).then((uploadStatusResponse: any) => {
            if (uploadStatusResponse.status == 200) {
              this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              resolve(true);
            } else {
              this._matSnackBar.open(uploadStatusResponse.message, 'OKAY', { duration: 2000 })
              reject();
            }
          })
        }
      })
    })

  }

  onRemoveProfile() {
    this.croppedProfileBase64=null
    this.addVerifierForm.get('image').setValue(null)
  }

}