import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, Injector } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { VenueListService } from './venue-list.service';
import { Router } from '@angular/router';
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';


@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'title', 'fullAddress'];
  dataSource;
  maxAvailableRecordsInTable

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  picked: any;
  // _routerService: Router;

  constructor(
    public _venueListService: VenueListService,
    private _ToolbarService : ToolbarService,
    private _router: Router,
    private injector: Injector
  ) { 
    // this._routerService = this.injector.get(Router);
    this.maxAvailableRecordsInTable = this._venueListService.maxAvailableRecordsInTable;
    this.setToolBarTitle();
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this._venueListService.allVenues);
    
  }

  setToolBarTitle(){
    // for title
    this._ToolbarService._ToolBarTitle.next('Venue');
    // for btn
    this._ToolbarService._isBackBtnShow.next(false);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onEditClick(id) {

    console.log('test success',id);
    
  
     this._router.navigate(['/masters/venue/add'], { queryParams: { id} })
    //this._routerService.navigate(['/masters/venue/add'], { queryParams: { id: id } })
  
  }
  public highlightSelectedRow(row): void
{
    this.picked = row.rowId; 

    // i can able get values from here
    //do i need to add below line here.?
    //this.router.navigate(['/show-this-user-page']);
}


   // ----------------------- filters section -----------------------

   filter = {
       searchFilter: '',
       pageSize: 10,
       pageIndex: 1
   }

   onSearch(val) {
       this.filter.pageIndex = 1
       this.filter.searchFilter = val
       this.paginator.pageIndex = 0;
       this.filterData();
   }

   filterData() {
       let payload = {
           "pageIndex": this.filter.pageIndex,
           "pageSize": this.filter.pageSize,
           "search": this.filter.searchFilter
       }
       this._venueListService.getAllVenues(payload).then((result: any) => {
           if (result.status == 200) {
               this.dataSource.data = result.data.data
               this.maxAvailableRecordsInTable = result.data.totalRecords
           }else if (result.status == 400) {
            this.dataSource.data = []
            this.maxAvailableRecordsInTable = 0;
        }
           else {
               this.dataSource.data = []
               this.maxAvailableRecordsInTable = 0;
           }
       }).catch(()=>{
        this.dataSource.data = []
       })
   }

   onPageChange(event) {
       this.filter.pageIndex = event.pageIndex + 1
       this.filter.pageSize = event.pageSize
       this.filterData()
   }

   @ViewChild('search', { static: true }) search;
   clearSearch() {
     this.filter.searchFilter = ''
     this.search.nativeElement.focus()
     this.filterData()
   }

}
