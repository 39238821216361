import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cancel-event-dialog',
  templateUrl: './cancel-event-dialog.component.html',
  styleUrls: ['./cancel-event-dialog.component.scss']
})
export class CancelEventDialogComponent implements OnInit {

  form : UntypedFormGroup
  constructor(
    @Inject(MAT_DIALOG_DATA) public _data,
    public _matDialogRef: MatDialogRef<CancelEventDialogComponent>,
    private _fb : UntypedFormBuilder
  ) {
      this.createForm()
   }

  ngOnInit(): void {
    this.handleValidation();
  }

  handleValidation(){
    this.form.get('checkMark1').valueChanges.subscribe((result)=>{
      if(result){
        this.form.get('checkMark1').setValidators(null)
        this.form.get('checkMark1').setErrors(null);
      }else{
        this.form.get('checkMark1').setValidators([Validators.required]);
        this.form.get('checkMark1').setErrors([Validators.required]);
      }
    })

    this.form.get('checkMark2').valueChanges.subscribe((result)=>{
      if(result){
        this.form.get('checkMark2').setValidators(null);
        this.form.get('checkMark2').setErrors(null);
      }else{
        this.form.get('checkMark2').setValidators([Validators.required])
        this.form.get('checkMark2').setErrors([Validators.required]);
      }
    })

    // this.form.get('checkMark3').valueChanges.subscribe((result)=>{
    //   if(result){
    //     this.form.get('checkMark3').setValidators(null);
    //     this.form.get('checkMark3').setErrors(null);
    //   }else{
    //     this.form.get('checkMark3').setValidators([Validators.required])
    //     this.form.get('checkMark3').setErrors([Validators.required]);
    //   }
    // })
  }

  createForm(){
    this.form = this._fb.group({
      checkMark1 : ['', Validators.required],
      checkMark2 : ['', Validators.required],
      checkMark3 : ['', Validators.required],
      reason     : ['', Validators.required]
    })
  }

  onSubmit(){
    if(this.form.valid){
      this._matDialogRef.close(this.form.value);
    }
  }

}
