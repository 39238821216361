import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'sameDate'})
export class mySameDate implements PipeTransform
{
    transform(endDate: any, startDate: any, zone): any{
        console.log("zone", zone);
        
        let startdate = new Date(startDate).getDay() + ' ' + new Date(startDate).getMonth() + ' ' + new Date(startDate).getFullYear();
        let enddate = new Date(endDate).getDay() + ' ' + new Date(endDate).getMonth() + ' ' + new Date(endDate).getFullYear();
        
        /* SETTING AM/PM */
        let date = new Date(endDate);
        let hours = date.getHours();
        let minutes:any = date.getMinutes();
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        
        /* RETRUN FOR DATE FORMATE */
        let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const myDate = new Date(endDate);
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
       
        const day = myDate.getDate();
        const fullYear = myDate.getFullYear();
        
        /* FOR ADD TIME */
        const myTime = new Date(endDate);
        let myhours = date.getHours();
        let myminutes:any = date.getMinutes();
        let myampm = myhours >= 12 ? 'PM' : 'AM';
        myhours = myhours % 12;
        myhours = myhours ? myhours : 12; // the hour '0' should be '12'
        myminutes = myminutes < 10 ? '0'+ myminutes : myminutes;
        let mystrTime = myhours + ':' + myminutes + ' ' + myampm;


        let endDateReturn = months[myDate.getMonth()] + " " + day + " " + fullYear +" " + " " + mystrTime;

        if (startdate == enddate) {
            console.log("same data", strTime);
            return strTime;
        }else{
            console.log("uniqe date", endDateReturn);
            return endDateReturn;
        }

        // return startdate == enddate ? endtime : endDate;
        // return startDate + 'start' + endDate + 'end';
        // return value ? String(value).replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) : '';
    }
}
